import React, {memo} from 'react';

import { CandleClassicBody } from './CandleClassicBody/CandleClassicBody';
import { CandleClassicColumnBackground } from './CandleClassicColumnBackground/CandleClassicColumnBackground';
import {ICandleClassic} from "../../../Modules/Trading/models";

import "./CandleClassic.scss";

/**
 * Классическая свеча OHLC-графика.
 *
 * @prop pricePixelRate Пропорции для отрисовки столбца.
 * @prop topPosition Позиция свечи.
 */
interface IProps extends ICandleClassic{
    pricePixelRate: number;
    topPosition: number;
}

export const CandleClassic: React.FC<IProps> = memo(({pricePixelRate, _id, open, close, low, high, topPosition}: IProps) => (
    <div className="candleClassicColumn">
        <div className="candleClassicContainer" style={{top: `${Math.abs(topPosition)}px`}}>
            <CandleClassicBody
                id={_id}
                high={high}
                open={open}
                low={low}
                close={close}
                pricePixelRate={pricePixelRate}
            />
        </div>
        <CandleClassicColumnBackground />
    </div>
))

CandleClassic.displayName = 'CandleClassic';
