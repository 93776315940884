import React from 'react'
import {EChartTypes} from "../../../Modules/Trading/enums";
import {CandleClassic} from "../../../Common/Components/CandleClassic/CandleClassic";
import {Cluster} from "../../../Common/Components/Cluster/Cluster";
import {ICandleClassic, IColumnCluster, TCandleCurrentType} from "../../../Modules/Trading/models";

/**
 * Прокси для определения вида столбца для отрисовки.
 *
 * @prop .
 */
interface IProps {
    chartType: EChartTypes;
    chartItemData: ICandleClassic & IColumnCluster;
    pricePixelRate: number;
    topPosition: number;
}

export const ChartColumnProxy: React.FC<IProps> = ({chartType, chartItemData, pricePixelRate, topPosition}:IProps) => {
    switch (chartType) {
        case EChartTypes.CANDLE_CLASSIC:
            return <CandleClassic
                _id={chartItemData._id}
                high={chartItemData.high}
                open={chartItemData.open}
                low={chartItemData.low}
                close={chartItemData.close}
                pricePixelRate={pricePixelRate}
                topPosition={topPosition}
            />
        case EChartTypes.CLUSTER:
            return <Cluster
                data={chartItemData}
                pricePixelRate={pricePixelRate}
                topPosition={topPosition}
            />
    }
}

ChartColumnProxy.displayName = 'ChartColumnProxy'
