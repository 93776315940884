// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#indicatorContainer .line {
  fill: none;
  stroke-width: 2px;
  stroke: #49b6ff;
}
#indicatorContainer .point {
  filter: drop-shadow(0px 4px 7.6px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 8px #49b6ff);
  stroke: #49b6ff;
}
#indicatorContainer .line-gradient {
  fill: url(#verticalGradientDown);
}`, "",{"version":3,"sources":["webpack://./src/Modules/Demo/Indicators/IndicatorList.scss"],"names":[],"mappings":"AACI;EACI,UAAA;EACA,iBAAA;EACA,eAAA;AAAR;AAGI;EACI,uFAAA;EACA,eAAA;AADR;AAII;EACI,gCAAA;AAFR","sourcesContent":["#indicatorContainer {\n    .line {\n        fill: none;\n        stroke-width: 2px;\n        stroke: #49b6ff;\n    }\n\n    .point {\n        filter: drop-shadow(0px 4px 7.6px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 8px #49b6ff);\n        stroke: #49b6ff;\n    }\n\n    .line-gradient {\n        fill: url(#verticalGradientDown);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
