import React, { useEffect } from "react";
import "./IndicatorList.scss";
import { CandleSize, Indicator, IndicatorName, UCandle } from "../../../CandlesClient/models/Candle";
import { CANVAS_HEIGHT, CANVAS_WIDTH, INDICATOR_HEIGHT } from "../../Candles/constants";
import { getLineIndicator } from "../../Candles/Helpers/indicator.helper";

export interface IndicatorProps {
    candles: UCandle[];
    indicators: Indicator[];
    candleSize: CandleSize;
    scaleIndex: number;
    spaceGap: number;
}

export const IndicatorList = (props: IndicatorProps) => {
    const { candles, indicators, candleSize, scaleIndex, spaceGap } = props;

    useEffect(() => {
        buildIndicators(indicators, candles, candleSize, scaleIndex, spaceGap);
    }, [candles]);

    return (
        <svg width={CANVAS_WIDTH} height={INDICATOR_HEIGHT}>
            <linearGradient id="verticalClasterGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#FEE825" />
                <stop offset="60%" stopColor="#3BB478" />
                <stop offset="100%" stopColor="#472A79" />
            </linearGradient>
            <linearGradient id="verticalGradientDown" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="rgba(73, 182, 255, 0.525)" />
                <stop offset="100%" stopColor=" rgba(73, 182, 255, 0)" />
            </linearGradient>
            <pattern id="patt1" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
                <rect height="100" width="100" fill="url(#verticalClasterGradient)" />
            </pattern>
            {indicators?.map((indicator) => {
                return <React.Fragment key={indicator.id}>{renderIndicator(indicator, candles, candleSize)}</React.Fragment>
            })}
        </svg>
    )
}

const buildIndicators = (indicators: Indicator[], candles: UCandle[], candleSize: CandleSize, scaleIndex: number, spaceGap: number) => {
    indicators.forEach(indicator => {
        if (indicator.name === IndicatorName.lineChart || indicator.name === IndicatorName.lineGradientChart) {
            indicator = getLineIndicator(candles, indicator, scaleIndex, spaceGap);
        }
    })
}

const renderIndicator = (indicator: Indicator, candles: UCandle[], candleSize: CandleSize) => {
    switch (indicator.name) {
        case IndicatorName.histogram:
            return renderHistogramIndicator(indicator, candles, candleSize);
        case IndicatorName.lineChart:
            return renderLineIndicator(indicator);
        case IndicatorName.lineGradientChart:
            return renderLineGradientIndicator(indicator);

    }
}

const renderHistogramIndicator = (indicator: Indicator, candles: UCandle[], candleSize: CandleSize) => {
    if (!indicator.turned) {
        return <></>
    }

    return (
        <>
            {candles?.map((candle, index) => {
                const candleIndex = index;

                if (candle == null || candle.id === 0) {
                    return <React.Fragment key={candleIndex + 'indicator'}></React.Fragment>
                }

                return (
                    <rect key={candleIndex + 'indicator'}
                        fill="url(#patt1)"
                        className={`test-indicator`}
                        style={{ width: `${candleSize.candleWidth}px` }}
                        x={candle.candleX}
                        y={candle.indicatorY}
                        height={candle.indicatorHeight} />
                )
            })}
        </>
    )
}

const renderLineIndicator = (indicator: Indicator) => {
    if (!indicator.turned) {
        return <></>
    }

    return (
        <>
            {indicator.linePath && <path d={indicator.linePath} className="line" />}
            {indicator.points?.map((point, index) => {
                return (
                    <rect className="point" key={index} x={point.x} y={point.y} />
                )
            })}
        </>
    )
}

const renderLineGradientIndicator = (indicator: Indicator) => {
    if (!indicator.turned) {
        return <></>
    }

    return (
        <>
            {indicator.linePath && <path d={indicator.linePath} className="line" />}
            {indicator.gradientPath && <polygon className='line-gradient' points={indicator.gradientPath}></polygon>}
            {/* {indicator.gradientPath && <path d={indicator.gradientPath} className='line-gradient' />} */}
        </>
    )
}

