import React, {memo} from 'react'
import {IOrder} from "../../../../Modules/Trading/models";

import "./ClusterPriceBar.scss";
import {CLUSTER_COLUMN_PRICE_BAR_WIDTH} from "../../../../Modules/Trading/consts";

/**
 * .
 *
 * @prop .
 */
interface IProps {
    orderList: IOrder;
    biggestOrder: number;
}

export const ClusterPriceBar: React.FC<IProps> = memo(({orderList, biggestOrder}:IProps) => {
    const barRate = orderList.ask > orderList.bid ? orderList.ask / biggestOrder : orderList.bid / biggestOrder;

    const barWidth = barRate * CLUSTER_COLUMN_PRICE_BAR_WIDTH;

    const bgColor = () => {
        if (orderList.ask > orderList.bid) {
            return barRate * 100 > 90 ? '#FF3636' : barRate * 100 > 50 ? '#8C1E1E' : '#4C1010'
        }
        return barRate * 100 > 90 ? '#3690FF' : barRate * 100 > 50 ? '#1E4F8C' : '#102B4C'
    }

    return (
        <div className="cluster-price-bar-container">
            <span className="cluster-price-bar-title">
                {`${orderList.ask} x ${orderList.bid}`}
            </span>
            <div className="cluster-price-bar" style={{width: `${barWidth}px`, backgroundColor: bgColor()}}/>
        </div>
    )
})

ClusterPriceBar.displayName = 'ClusterPriceBar'
