import { API_ENDPOINTS } from "../Helpers/app-helper";

export class CandleService {
    static async getCandles(date: string, candlesCount: number, secondsCount: number) {
        console.log(date, candlesCount);
        const insrumentId = 1; //ToDo: get from state
        const sql = API_ENDPOINTS.GRAFANA_PAST_CANDLES
            .replace('{{secondsCount}}', secondsCount.toString())
            .replace('{{insrumentId}}', insrumentId.toString())
            .replace('{{date}}', date)
            .replace('{{candlesCount}}', candlesCount.toString());
        return fetch('https://analytics.markets-platform.com/api/ds/query?ds_type=grafana-postgresql-datasource&requestId=Q108', {
            method: 'POST',
            headers: {
                "Authorization": "Basic VGFyYXNGRTpHcmFwaFRyYWRpbmc=",
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                queries: [
                    {
                        "refId": "A",
                        "datasource": {
                            "type": "grafana-postgresql-datasource",
                            "uid": "fdnoduya5u7swa"
                        },
                        "rawSql": sql,
                        "format": "table",
                        "datasourceId": 11,
                        "intervalMs": 300000,
                        "maxDataPoints": 1209
                    }
                ]
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
    }
}
