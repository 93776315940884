// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cluster-price-bar-container {
  display: flex;
  margin-left: -36px;
  height: 14px;
}

.cluster-price-bar-title {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 9px;
  text-align: right;
}

.cluster-price-bar {
  display: inline-block;
  height: 13px;
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/Cluster/ClusterPriceBar/ClusterPriceBar.scss","webpack://./src/Common/Styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,kBAAA;EACA,YAAA;AAFJ;;AAKA;EACI,aAAA;EACA,mBAAA;EAEA,cCiBU;EDhBV,cAAA;EACA,iBAAA;AAHJ;;AAMA;EACI,qBAAA;EACA,YAAA;AAHJ","sourcesContent":["@import '../../../../Common/Styles/colors.scss';\n@import '../../../../Common/Styles/metrics.scss';\n\n.cluster-price-bar-container {\n    display: flex;\n    margin-left: -36px;\n    height: 14px;\n}\n\n.cluster-price-bar-title {\n    display: flex;\n    align-items: center;\n\n    color: $color-snowy;\n    font-size: 9px;\n    text-align: right;\n}\n\n.cluster-price-bar {\n    display: inline-block;\n    height: 13px;\n}\n","$color-blue-01: #8FBCFB;\n$color-blue-02: #001c36;\n$color-blue-03: #49B6FF;\n$accent: #49B6FF;\n$color-blue-03_075: rgba(73, 182, 255, 0.75);\n$color-blue-04: #AED4FF;\n$color-blue-05: #01283c;\n$color-blue-06: #0F3A5D;\n$accent-bg: #0F3A5D;\n\n$blue-button-fill: #3D93CC;\n$color-blue-07: #0C202E;\n$color-blue-08: #E7F5FF;\n\n$color-gray-01: rgba(157, 172, 202, 0.5);\n$color-gray-02: #20232F;\n\n$ratio-color: #FDE725;\n$color-yellow-01: #D49A04;\n$color-yellow-02: #FDCB48;\n$accent-active: #FDCB48;\n$color-yellow-03: #AC7F09;\n$accent-active-bg: #2E2819;\n\n$color-coral-01: #FF6868;\n\n$color-red-01: #FF0000;\n\n$color-green-01: #39AB1D;\n\n$color-snowy: #ffffff;\n\n$color-black: #000000;\n\n$btn-green-light: #24B232;\n$btn-green-dark: #1A8024;\n$btn-green-shadow: #197F24;\n$btn-green-hr: #14661D;\n\n$btn-red-light: #B22424;\n$btn-red-dark: #7F1919;\n$btn-red-shadow: #7F1919;\n$btn-red-hr: #661414;\n\n$color-passive: #92AEC1;\n\n$light-block-bg: #123248;\n$dark-block-bg: #0A1F2E;\n$dark-block-shadow: #0C2334;\n\n$panel-border-color: #3387C2;\n$panel-light-bg: #153851;\n$panel-bg: #0D273A;\n\n\n$red-candle-color: #ff0000;\n$green-candle-color: #39ab1d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
