import React, {memo, useState} from 'react';
import cn from "classnames";

import "./CandleClassicBody.scss";


/**
 * Свеча для свечного графика.
 */
interface IProp {
    id: any;
    low: any;
    high: any;
    close: any;
    open: any;
    pricePixelRate: number;
}

export const CandleClassicBody: React.FC<IProp> = memo(({high, open, close, low, pricePixelRate}: IProp) => {
    const [template, setTemplate] =useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10>(1)

    const dealsCandleClassNames = cn({
        candleClassicBody: true,
        flatCandleClassic: open === close,
        [`greenCandleClassic__${template}`]: open > close,
        [`redCandleClassic__${template}`]: open < close,
    })

    const highCandleClassNames = cn({
        highLowCandleClassic: true,
        highCandleClassic: true,
        [`highCandleClassic__${template}`]: true,
    })

    const lowCandleClassNames = cn({
        highLowCandleClassic: true,
        lowCandleClassic: true,
        [`lowCandleClassic__${template}`]: true,
    })

    const calcHighOpenHeight = (high - open) * pricePixelRate;
    const calcCloseLowHeight = (close - low) * pricePixelRate;
    const candleDealsHeight = Math.abs((open - close) * pricePixelRate)

    return (
        <div className="candleClassic">
            <div className={highCandleClassNames} style={{height: `${calcHighOpenHeight}px`}} />
            <div className={dealsCandleClassNames} style={{height: `${candleDealsHeight}px`}} />
            <div className={lowCandleClassNames} style={{height: `${calcCloseLowHeight}px`}} />
        </div>

    );
})

CandleClassicBody.displayName = 'CandleClassicBody';
