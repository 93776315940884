import React from 'react'

import {IColumnCluster} from "../../../Modules/Trading/models";
import {ClusterBar} from "../../../Common/Components/Cluster/ClusterBar/ClusterBar";
import {ClusterPriceBarGroup} from "../../../Common/Components/Cluster/ClusterPriceBarGroup/ClusterPriceBarGroup";

import "./Cluster.scss";

/**
 * Столбец кластерного графика.
 *
 * @prop data Данные столбца кластерного графика.
 * @prop topPosition Данные столбца кластерного графика.
 * @prop pricePixelRate Данные столбца кластерного графика.
 */
interface IProps {
    data: IColumnCluster;
    topPosition: number;
    pricePixelRate: number;
}

export const Cluster: React.FC<IProps> = ({data: {open, high, low, close, priceList}, topPosition, pricePixelRate}:IProps) => (
    <div className="cluster-container" style={{top: `${Math.abs(topPosition)}px`}}>
        <ClusterBar open={open} high={high} low={low} close={close} pricePixelRate={pricePixelRate} />
        <ClusterPriceBarGroup priceList={priceList}/>
    </div>
)

Cluster.displayName = 'Cluster'
