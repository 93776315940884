import React, { useState } from "react";
import "./SettingsMenu.scss";
import { CandleSize, UCandle } from "../../../CandlesClient/models/Candle";
import useComponentVisible from "../../../Hooks/useComponentVisible";
import Settings from "../../../Common/Components/Icons/Settings.svg";
import SettingsActive from "../../../Common/Components/Icons/SettingsActive.svg";
import { useTranslation } from "react-i18next";
import { TextField, MenuItem, Menu, Button } from '@mui/material';
import { RootState, SettingsValue } from "../../../Store/models";
import { useTypedSelector } from "../../../Hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { changeClusterSetting, changePriceSetting, changeWheelSetting } from "../../../Store/action-creators";

export interface SnakeCandleChartProps {
    candles: UCandle[];
    candleSize: CandleSize;
}

export const SettingsMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const settingsMenuOpen = Boolean(anchorEl);
    const settingsIcon = settingsMenuOpen ? SettingsActive : Settings;
    const chartSettings = useTypedSelector((state: RootState) => state.app.mainChart.settings);
    const [wheelSetting, setWheelSetting] = useState<SettingsValue>(chartSettings.wheel);
    const [priceScaleSetting, setPriceScaleSetting] = useState<SettingsValue>(chartSettings.priceScale);
    const [clusterPriceSetting, setClusterPriceSetting] = useState<SettingsValue>(chartSettings.clusterPrice);

    const handleWheelChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as SettingsValue;
        dispatch(changeWheelSetting(value));
        setWheelSetting(value);
    }

    const handleClusterPriceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as SettingsValue;
        dispatch(changeClusterSetting(value));
        setPriceScaleSetting(value);
    }

    const handlePriceScaleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value as SettingsValue;
        dispatch(changePriceSetting(value));
        setPriceScaleSetting(value);
    }

    const handleCommonChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        console.log(event.target.value);
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                className='chart-settings-togler'
                id="settings-button"
                aria-controls={settingsMenuOpen ? 'settings-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={settingsMenuOpen ? 'true' : undefined}
                onClick={handleClick}
            >
                <img src={settingsIcon} alt="settings" />
            </Button>
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                open={settingsMenuOpen}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: 250,
                    },
                }}
            >
                <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.Wheel')}</span>
                        <TextField select
                            value={wheelSetting}
                            onChange={handleWheelChange}>
                            <MenuItem className="menu-option" value={SettingsValue.Zoom}>{t('Components:Components.CursorSettings.WheelSettings.Zoom')}</MenuItem>
                            <MenuItem className="menu-option" value={SettingsValue.Scroll}>{t('Components:Components.CursorSettings.WheelSettings.Scroll')}</MenuItem>
                        </TextField>
                    </div>
                </MenuItem>
                <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.PriceScale')}</span>
                        <TextField select
                            value={priceScaleSetting}
                            onChange={handlePriceScaleChange}>
                            <MenuItem className="menu-option" value={SettingsValue.PriceDefault}>{t('Components:Components.CursorSettings.PriceScaleSettings.Default')}</MenuItem>
                            <MenuItem className="menu-option" value={SettingsValue.MidlePrice}>{t('Components:Components.CursorSettings.PriceScaleSettings.MidlePrice')}</MenuItem>
                            <MenuItem className="menu-option" value={SettingsValue.LastPrice}>{t('Components:Components.CursorSettings.PriceScaleSettings.LastPrice')}</MenuItem>
                        </TextField>
                    </div>
                </MenuItem>
                {/* <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.ClusterPrice')}</span>
                        <TextField select
                            value={clusterPriceSetting}
                            onChange={handleClusterPriceChange}>
                            <MenuItem className="menu-option" value={SettingsValue.Visible}>{t('Components:Components.CursorSettings.ClusterPriceSettings.Visible')}</MenuItem>
                            <MenuItem className="menu-option" value={SettingsValue.Hidden}>{t('Components:Components.CursorSettings.ClusterPriceSettings.Hidden')}</MenuItem>
                            <MenuItem className="menu-option" value={SettingsValue.OnHover}>{t('Components:Components.CursorSettings.ClusterPriceSettings.OnHover')}</MenuItem>
                        </TextField>
                    </div>
                </MenuItem> */}
                {/* <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.Color')}</span>
                        <TextField select
                            onChange={handleCommonChange}>
                            <MenuItem className="menu-option" value={SettingsValue.ColorDefault}>{t('Components:Components.CursorSettings.ColorSettings.Default')}</MenuItem>
                        </TextField>
                    </div>
                </MenuItem>
                <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.LineWidth')}</span>
                        <TextField select
                            onChange={handleCommonChange}>
                            <MenuItem className="menu-option" value={SettingsValue.Medium}>2px</MenuItem>
                        </TextField>
                    </div>
                </MenuItem>
                <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.Style')}</span>
                        <TextField select
                            onChange={handleCommonChange}>
                            <MenuItem className="menu-option" value={SettingsValue.Cross}>{t('Components:Components.CursorSettings.StyleSettings.Cross')}</MenuItem>
                        </TextField>
                    </div>
                </MenuItem>
                <MenuItem>
                    <div className="row-container">
                        <span className="description">{t('Components:Components.CursorSettings.Lines')}</span>
                        <TextField select
                            onChange={handleCommonChange}>
                            <MenuItem className="menu-option" value={SettingsValue.Dashed}>{t('Components:Components.CursorSettings.LinesSettings.Dashed')}</MenuItem>
                        </TextField>
                    </div>
                </MenuItem> */}
            </Menu>
        </>
    )
}
