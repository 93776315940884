import { Extremums, Indicator, LineChartData, Point, UCandle } from "../../../CandlesClient/models/Candle";
import { CANDLE_OFFSET, CANVAS_HEIGHT, CANVAS_PADDING, LINE_POINT_RADIUS } from "../constants";

export const getLineIndicator = (candles: UCandle[], indicator: Indicator, scaleIndex: number, spaceGap: number): Indicator => {
    indicator.gradientPath = ''
    indicator.linePath = "M";
    indicator.points = [];
    let lastOpenX: number = 0;
    
    candles.forEach((candle, i) => {
        if(candle.id === 0) {
            return;
        }
        const openY = candle.indicatorY;
        const openX = i * (CANDLE_OFFSET * scaleIndex) + CANVAS_PADDING + spaceGap;

        indicator.gradientPath += openX + "," + openY + " ";
        indicator.linePath += openX + "," + openY + " ";
        lastOpenX = openX;
        //@ts-ignore
        indicator.points.push({ x: openX - LINE_POINT_RADIUS, y: (openY || 0) - LINE_POINT_RADIUS });
    });

    indicator.gradientPath = 0 + "," + CANVAS_HEIGHT + " " + indicator.gradientPath + lastOpenX + "," + CANVAS_HEIGHT;

    return indicator;
}