import React, {memo} from 'react'
import {TCandleHistoryType} from "../../../../Modules/Trading/models";
import {
    calcCandlesTopPosition,
} from "../../../../Modules/Trading/utils";
import {EChartTypes} from "../../enums";
import {ChartColumnProxy} from "../../../../Common/Components/ChartColumnProxy/ChartColumnProxy";

/**
 * Столбцы с историческими данными.
 *
 * @prop historyData Исторические данные.
 * @prop maxHighPrice Максимальная цена для отображаемого набора столбцов.
 * @prop pricePixelRate Пропорции для отрисовки столбца.
 */
interface IProps {
    historyData: TCandleHistoryType;
    maxHighPrice: number;
    pricePixelRate: number;
    chartType: EChartTypes;
}

export const HistoricalColumns: React.FC<IProps> = memo(({historyData, maxHighPrice, pricePixelRate, chartType}: IProps) => {

    const renderContent = () => historyData.map(candleBarData => {
        const topPosition = calcCandlesTopPosition(pricePixelRate, maxHighPrice, candleBarData.high)

        return (
            <ChartColumnProxy
                key={candleBarData._id}
                chartType={chartType}
                //@ts-ignore
                chartItemData={candleBarData}
                pricePixelRate={pricePixelRate}
                topPosition={topPosition}
            />
        )
    })

    return (
        <>
            {renderContent()}
        </>
    )
})

HistoricalColumns.displayName = 'HistoricalColumns'
