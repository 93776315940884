import { Extremums, Indicator, IndicatorName } from "../../CandlesClient/models/Candle";

export const defaultExtremums: Extremums = {
    lowestPrice: 0,
    highestPrice: 0,
    highestVolume: 0,
    lowestPoint: 0,
    highestPoint: 0,
    scrollTop: 0
}

export const defaultIndicators: Indicator[] = [
    {
        id: 1,
        turned: false,
        pin: false,
        name: IndicatorName.histogram
    },
    {
        id: 2,
        turned: false,
        pin: false,
        name: IndicatorName.lineChart
    },
    {
        id: 3,
        turned: false,
        pin: false,
        name: IndicatorName.lineGradientChart
    },
    {
        id: 4,
        turned: false,
        pin: false,
        name: IndicatorName.background
        
    }
]