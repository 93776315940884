import React, {memo} from 'react';
import "./CandleClassicColumnBackground.scss";


/* Свеча для свечного графика. */
export const CandleClassicColumnBackground: React.FC = memo(() => {

    const bgColor = () => {
        const randomBg = Math.floor(Math.random() * 10);
        switch (randomBg) {
            case 1:
                return 'bg1'
            case 2:
                return 'bg2'
            case 3:
                return 'bg3'
            case 4:
                return 'bg4'
            case 5:
                return 'bg5'
            default:
                return 'bg6'
        }
    }

    const candlesClassicColumnBGClassnames = bgColor()

    return (
        <div className={candlesClassicColumnBGClassnames} />
    );
})

CandleClassicColumnBackground.displayName = 'CandleClassicColumnBackground';
