import React, { useEffect, useState } from "react";

import { Terminal } from "./Components/Terminal/Terminal";
import { clusterColumnMock } from "../../__mocks__/candles";
import { createSignalRConnection } from "../../Common/utils";
import { InstrumentsManager } from "../../Components/InstrumentsManager/InstrumentsManager";
import useComponentVisible from "../../Hooks/useComponentVisible";
import { ICandleClassic, TCandleHistoryType } from "./models";
import { randomIntFromInterval } from "./utils";
import { useTypedSelector } from "../../Hooks/useTypedSelector";
import { RootState } from "../../Store/models";
import { useDispatch } from "react-redux";
import { openCloseInstrumentManager } from "../../Store/action-creators";
import { useLocation } from 'react-router-dom'
const TradingPage: React.FC = () => {

    const location = useLocation();

    const [graphData, setGraphData] = useState<TCandleHistoryType>([]);
    const [currentCandle, setCurrentCandle] = useState<ICandleClassic | null>(null)
    const [currentCandle2, setCurrentCandle2] = useState<ICandleClassic | null>(null)
    const connection = createSignalRConnection("http://193.124.128.85:80/chart");

    const { instrumentManagerIsOpen } = useTypedSelector((state: RootState) => state.instrumentManager);

    const dispatch = useDispatch();

    const handleCloseInstrumentManager = () => {
        dispatch(openCloseInstrumentManager(false))
    }
    const { componentRef, setIsComponentVisible } = useComponentVisible(instrumentManagerIsOpen)

    useEffect(() => {
        setIsComponentVisible(instrumentManagerIsOpen)
    }, [instrumentManagerIsOpen])

    const getUpdateCandleData = async (stockName: string) => {
        await connection.start()
        await connection.invoke("OpenMarket", stockName, 1)
        connection.on("updateCandle", (candle: any) => {

            const cl: ICandleClassic = { date: candle.date, open: candle.open, high: candle.high, low: candle.low, close: candle.close, _id: candle.candleid };
            setCurrentCandle(prev => ({
                ...prev,
                ...cl
            }));
            setCurrentCandle2(prev => ({
                ...prev,
                ...cl
            }));
        });
    }

    useEffect(() => {

        setTimeout(() => {
            setGraphData(clusterColumnMock)
        }, 1000)

        getUpdateCandleData("IBM");

        return () => {
            connection.stop()
        }
    }, [])

    useEffect(() => {
        const arrayLength = randomIntFromInterval(5, 15)
        const priceList = new Array(arrayLength).fill(5).map((item, idx) => (
            {
                ask: randomIntFromInterval(0, 100),
                bid: randomIntFromInterval(0, 100),
                price: randomIntFromInterval(12700, 13000),
            }
        ))

        if (currentCandle && currentCandle._id !== currentCandle2?._id) {
            const newCandleData = {
                ...currentCandle,
                priceList: priceList,
                priceCount: arrayLength
            }
            setGraphData(prev => [...prev, newCandleData])
        }
    }, [currentCandle])

    const renderContent = () => {
        if (graphData.length && currentCandle) {
            const arrayLength = randomIntFromInterval(5, 15)
            const priceList = new Array(arrayLength).fill(5).map((item, idx) => (
                {
                    ask: randomIntFromInterval(0, 100),
                    bid: randomIntFromInterval(0, 100),
                    price: randomIntFromInterval(12700, 13000),
                }
            ))
            const newCandleData = {
                ...currentCandle,
                priceList: priceList,
                priceCount: arrayLength
            }
            return <Terminal history={graphData} currentCandle={newCandleData} />
        }
        return <div className="test">
            <div className="invert">
                <div className="test-gradient-1"></div>
                <div className="test-gradient-2"></div>
                <div className="test-gradient-3"></div>
                <div className="test-gradient-4"></div>
                <div className="test-gradient-5"></div>
                <div className="test-gradient-6"></div>
                <div className="test-gradient-7"></div>
                <div className="test-gradient-8"></div>
                <div className="test-gradient-9"></div>
                <div className="test-gradient-10"></div>
            </div>
            <div className="rotate">
                <div className="test-gradient-1"></div>
                <div className="test-gradient-2"></div>
                <div className="test-gradient-3"></div>
                <div className="test-gradient-4"></div>
                <div className="test-gradient-5"></div>
                <div className="test-gradient-6"></div>
                <div className="test-gradient-7"></div>
                <div className="test-gradient-8"></div>
                <div className="test-gradient-9"></div>
                <div className="test-gradient-10"></div>
            </div>
        </div>
    }

    return (
        <>
            {instrumentManagerIsOpen && (
                <div ref={componentRef} style={{ position: 'absolute', zIndex: 10, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <InstrumentsManager onClick={handleCloseInstrumentManager} />
                </div>
            )}
            {renderContent()}
        </>
    )
}

export default TradingPage;
