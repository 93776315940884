import React, { useEffect, useState, useRef } from "react";

import StaticModeIcon from "./img/StaticModeIcon.png";
import MaintenanceIcon from '../../Common/Components/Icons/Maintenance.svg';

import "./MainChart.scss";
import { useDispatch } from "react-redux";
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!../Candles/webWorker.ts";
import {
    CANVAS_HEIGHT,
    CANDLE_OFFSET,
    MIN_SCALE,
    SCALE_STEP,
    ChartEvent,
    CANVAS_PADDING,
    WHEEL_SCROLL_STEP,
    MAX_SCALE,
    DEFAULT_CANDLE_SIZE,
    CANVAS_WIDTH,
    DATE_FORMAT,
    WINDOW_COUNT,
    TIME_VALUE_Y,
    TIME_LABEL_WIDTH,
    PRICE_SCALE_STEP,
    INDICATOR_HEIGHT
} from "../Candles/constants";
import {
    ChangeModePayload,
    ChangeModeSuccessPayload,
    ChangeZoomPayload,
    ChangeZoomSuccessPayload,
    InitCandlesPayload,
    InitCandlesSuccessPayload,
    LineChartData,
    NewExtremumsPayload,
    NewExtremumsSuccessPayload,
    PriceValue,
    ScaleSuccessPayload,
    Scroll,
    UCandle,
    TimeValue,
    CandleSize,
    UpdateClustersSuccessPayload,
    AddNewCandlesPayload,
    AddNewCandlesSuccessPayload,
    Indicator,
    Extremums,
    IndicatorName
} from "../../CandlesClient/models/Candle";
import { JapaneeseCandleChart } from "./JapaneeseCandleChart/JapaneeseCandleChart";
import { LineChart } from "./LineChart/LineChart";
import { ClusterChart } from "./ClusterChart/ClusterChart";
import { useTypedSelector } from "../../Hooks/useTypedSelector";
import { RootState, SettingsValue } from "../../Store/models";
import { changeChartScale } from "../../Store/action-creators";
import { isClusterChartVisible, isJapaneeseChartVisible, isLineChartVisible, isSnakeChartVisible } from "../../Store/reducers/helper";
import { checkAvailableExtremums } from "../Candles/Helpers/extremums.helper";
import { SnakeCandleChart } from "./SnakeChart/SnakeChart";
import moment from "moment";
import { calculateCandleY, calculateY, copyCandle, debounce, generateEmptyRrows, getCandleSize, getCountByDirection, getParsedResponseCandleRows, getScrolledCandlesCount } from "../Candles/helper";
import { getDateStringByCandlesCount, getDateStringByDirection, getSecondsCount } from "../Candles/Helpers/date.helper";
import { CandleService } from "../../services/candleService";
import { SettingsMenu } from "./SettingsMenu";
import { IndicatorsMenu } from "./IndicatorsMenu";
import { defaultExtremums, defaultIndicators } from "./default-chart.state";
import { IndicatorList } from "./Indicators/IndicatorList";

const worker = new Worker();

export const MainChart: React.FC = () => {
    const dispatch = useDispatch();

    const [staticModeActive, setStaticModeActive] = useState(false);

    const [canvasWidth, setCanvasWidth] = useState<number>(0);
    const [canvasHeight, setCanvasHeight] = useState<number>(0);

    const [spaceGap, setSpaceGap] = useState<number>(0);
    const [extremums, setExtremums] = useState<Extremums>(defaultExtremums);
    const [timeValues, setTimeValues] = useState<TimeValue[]>([]);
    const [priceValues, setPriceValues] = useState<PriceValue[]>([]);
    const [latestPrice, setLatestPrice] = useState<number>(0);
    const [latestPricePosition, setLatestPricePosition] = useState<number>(0);
    const [closePrice, setClosePrice] = useState<number>(0);
    const [closePricePosition, setClosePricePosition] = useState<number>(0);
    const [hoveredCandleTime, setHoveredCandleTime] = useState<string>('');
    const [hoveredCandlePosition, setHoveredCandlePosition] = useState<number>(0);
    const [scaleIndex, setScaleIndex] = useState<number>(1);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const [candlesOnViewPort, setCandlesOnViewPort] = useState<number>(0);
    const [candles, setCandles] = useState<UCandle[]>([]);
    const [currentCandle, setCurrentCandle] = useState<UCandle>({} as UCandle)
    const [candleSize, setCandleSize] = useState<CandleSize>(DEFAULT_CANDLE_SIZE);
    const [lineChartData, setLineChartData] = useState<LineChartData>({});
    const [indicators, setIndicators] = useState<Indicator[]>(defaultIndicators);
    const [showBackgroundIndicator , setShowBackgroundIndicator] = useState<boolean>(false);

    const mainChart = useRef<SVGSVGElement>({} as SVGSVGElement);
    const chartContainer = useRef<HTMLDivElement>({} as HTMLDivElement);
    const indicatorContainer = useRef<HTMLDivElement>({} as HTMLDivElement);
    const priceContainer = useRef<HTMLDivElement>({} as HTMLDivElement);
    const timeContainer = useRef<HTMLDivElement>({} as HTMLDivElement);

    const [viewPortDataDefined, setViewPortDataDefined] = useState<boolean>(false);
    const [scrollPosition, setScrollPosition] = useState<Scroll>({ top: 0, left: 0, x: 0, y: 0 });
    const [workerSubscribed, setWorkerSubscribed] = useState<boolean>(false);
    const [maintenance, setMaintenance] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [priceMouseDown, setPriceMouseDown] = useState<boolean>(false);
    const [timeMouseDown, setTimeMouseDown] = useState<boolean>(false);
    const [chartMouseDown, setChartMouseDown] = useState<boolean>(false);

    const [cursorPositionX, setCursorPositionX] = useState<number>(0);
    const [cursorPositionY, setCursorPositionY] = useState<number>(0);

    const scale = useTypedSelector((state: RootState) => state.app.mainChart.scaleIndex);
    const chartStyle = useTypedSelector((state: RootState) => state.app.mainChart.activeChartStyle);
    const timeCount = useTypedSelector((state: RootState) => state.app.mainChart.timeCount);
    const timeFrame = useTypedSelector((state: RootState) => state.app.mainChart.timeFrame);
    const customScale = useTypedSelector((state: RootState) => state.app.mainChart.customScale);
    const selectedChartFrame = useTypedSelector((state: RootState) => state.app.selectedChartFrame);
    const chartSettings = useTypedSelector((state: RootState) => state.app.mainChart.settings);

    const headerTitle = "Binance BTCUSD " + selectedChartFrame;

    let scrolledCandlesCount = 0;

    const resize_ob = new ResizeObserver(() => {
        defineViewPortData();
    });

    const defineViewPortData = (): number => {
        const leftMenuWidth = document.querySelector<HTMLElement>('.navigation-menu-container')?.offsetWidth || 0;
        const rightMenuWidth = document.querySelector<HTMLElement>('.right-menu-container')?.offsetWidth || 0;
        const headerMenuHeight = document.querySelector<HTMLElement>('.header-menu-container')?.offsetHeight || 0;
        const bottomPanelHeight = document.querySelector<HTMLElement>('.bottom-panel ')?.offsetHeight || 0;

        const canvasWidth = window.innerWidth - (leftMenuWidth + rightMenuWidth);
        const canvasHeight = window.innerHeight - (headerMenuHeight + bottomPanelHeight);

        setCanvasHeight(canvasHeight);
        setCanvasWidth(canvasWidth);
        setViewPortDataDefined(true);

        return canvasWidth;
    }

    const updateIndicators = (data: Indicator[]): void => {
        const visibility = data.find(indicator => indicator.name === IndicatorName.background)?.turned || false;
        setShowBackgroundIndicator(visibility);
        setIndicators(data);
    }

    const mousewheelChartHandler = (e: any) => {
        if (chartSettings.wheel === SettingsValue.Scroll) {
            handleChartScroll(e);
        } else {
            handleChartScale(e);
        }
    }

    const mousewheelPriceHandler = (e: any) => {
        if (staticModeActive) {
            toggleStaticMode();
            return;
        }

        const direction = e.deltaY < 0;

        const payload: ChangeZoomPayload = {
            type: ChartEvent.HandleChartZoom,
            candles,
            scaleIndex,
            direction,
            scaleSetting: chartSettings.priceScale,
            //scrollTop: (chartContainer.current as HTMLElement).scrollTop,
            spaceGap,
            canvasHeight,
            extremums,
            chartStyle
        }

        worker.postMessage(payload);
    }

    const handleChartScroll = (e: any) => {
        const direction = e.deltaY > 0 ? 1 : -1;
        let scrollLeft = (chartContainer.current as HTMLElement).scrollLeft + (WHEEL_SCROLL_STEP * direction);

        if (scrollLeft < 0) {
            scrollLeft = 0;
        }

        if (scrollLeft > CANVAS_WIDTH) {
            scrollLeft = CANVAS_WIDTH;
        }

        setScrollLeft(scrollLeft);

        if (staticModeActive) {
            const payload: NewExtremumsPayload = {
                type: ChartEvent.HandleNewExtremums,
                candles,
                scaleIndex,
                canvasWidth,
                scrollLeft,
                spaceGap,
                canvasHeight,
                chartStyle
            }

            worker.postMessage(payload);
        } else {

        }
    }

    const scrollToEnd = () => {
        const scrollLeft = CANVAS_WIDTH - canvasWidth;
        setScrollLeft(scrollLeft);

    }

    const toggleStaticMode = () => {
        const payload: ChangeModePayload = {
            type: ChartEvent.HandleModeChange,
            candles,
            scaleIndex,
            canvasWidth,
            scrollLeft,
            spaceGap,
            staticModeActive,
            canvasHeight,
            chartStyle
        }

        worker.postMessage(payload);
    }

    const handleChartMouseMove = (e: any) => {
        const count = Math.floor((e.nativeEvent.layerX - CANVAS_PADDING - spaceGap) / (CANDLE_OFFSET * scaleIndex));

        const hoveredCandle = candles[count];
        const candleTime = moment.unix(hoveredCandle?.timeClose).format(DATE_FORMAT) || '';
        const hoveredCandlePosition = (hoveredCandle?.bodyyX || 0) - TIME_LABEL_WIDTH / 2;
        setHoveredCandleTime(candleTime);
        setHoveredCandlePosition(hoveredCandlePosition);

        setCursorPositionX(e.nativeEvent.layerX);
        setCursorPositionY(e.nativeEvent.layerY);
    }

    const handleChartScale = (e: any) => {
        let scaleValue;

        if (e.deltaY < 0 && scaleIndex < MAX_SCALE) {
            scaleValue = scaleIndex + SCALE_STEP;
        } else if (e.deltaY > 0 && scaleIndex > MIN_SCALE) {
            scaleValue = scaleIndex - SCALE_STEP;
        } else {
            return;
        }

        const nextScaleIndex = +scaleValue.toFixed(2);

        dispatch(changeChartScale(nextScaleIndex));
    }

    const priceMouseDownHandler = (e: any) => {
        if (priceContainer.current == null) {
            return;
        }

        setScrollPosition({ ...scrollPosition, y: e.clientY });
        setPriceMouseDown(true);
    }

    const timeMouseDownHandler = (e: any) => {
        if (timeContainer.current == null) {
            return;
        }

        setScrollPosition({ ...scrollPosition, y: e.clientY });
        setTimeMouseDown(true);
    }

    const canvasMouseMoveHandler = (e: any) => {
        if (chartMouseDown) {
            chartMouseMoveHandler(e);
        } else if (priceMouseDown) {
            priceMouseMoveHandler(e);
        } else if (timeMouseDown) {
            timeMouseMoveHandler(e);
        }
    }

    const timeMouseMoveHandler = (e: any) => {
        if (!timeMouseDown) {
            return;
        }

        const scrolledPixels = scrollPosition.y - e.clientY;
        const direction = scrolledPixels > 0 ? 1 : -1;

        if (scrolledPixels * direction > 25) {
            setScrollPosition({ ...scrollPosition, y: e.clientY });

            let scaleValue;

            if (direction < 0 && scaleIndex < MAX_SCALE) {
                scaleValue = scaleIndex + SCALE_STEP;
            } else if (direction > 0 && scaleIndex > MIN_SCALE) {
                scaleValue = scaleIndex - SCALE_STEP;
            } else {
                return;
            }

            const nextScaleIndex = +scaleValue.toFixed(2);

            dispatch(changeChartScale(nextScaleIndex));
        }
    }

    const priceMouseMoveHandler = (e: any) => {
        if (!priceMouseDown) {
            return;
        }

        if (staticModeActive) {
            toggleStaticMode();
            return;
        }

        const scrolledPixels = scrollPosition.y - e.clientY;
        const direction = scrolledPixels > 0 ? 1 : -1;

        if (scrolledPixels * direction > PRICE_SCALE_STEP) {
            setScrollPosition({ ...scrollPosition, y: e.clientY });

            const payload: ChangeZoomPayload = {
                type: ChartEvent.HandleChartZoom,
                candles,
                scaleIndex,
                canvasHeight,
                direction: direction > 0,
                spaceGap,
                extremums,
                //scrollTop: (chartContainer.current as HTMLElement).scrollTop,
                scaleSetting: chartSettings.priceScale,
                chartStyle
            }

            worker.postMessage(payload);
        }
    }

    const priceMouseUpHandler = () => {
        setPriceMouseDown(false);
    }

    const timeMouseUpHandler = () => {
        setTimeMouseDown(false);
    }

    const chartMouseDownHandler = (e: any) => { // MouseEvent
        if (chartContainer.current == null) {
            return;
        }

        setChartMouseDown(true);

        const allowVerticalScroll = !staticModeActive;
        const position: Scroll = {
            ...scrollPosition,
            left: (chartContainer.current as HTMLElement).scrollLeft,
            top: (chartContainer.current as HTMLElement).scrollTop,
            x: e.clientX,
            y: allowVerticalScroll ? e.clientY : scrollPosition.y,
        }

        setScrollPosition(position);
    }

    const canvasMouseUpHandler = (e: any) => {
        if (chartMouseDown) {
            chartMouseUpHandler(e);
        } else if (priceMouseDown) {
            priceMouseUpHandler();
        } else if (timeMouseDown) {
            timeMouseUpHandler();
        }
    }

    const updateClosePosition = (count?: number): void => {
        const scrolledCount = count || Math.floor((scrollLeft - CANVAS_PADDING - spaceGap) / (CANDLE_OFFSET * scaleIndex));
        const closePrice = candles[scrolledCount + candlesOnViewPort]?.close || 0;
        const closePosition = calculateY(closePrice, extremums);

        setClosePrice(closePrice);
        setClosePricePosition(closePosition);
    }

    const chartMouseMoveHandler = (e: any) => { // MouseEvent
        if (!chartMouseDown) {
            return;
        }

        const allowVerticalScroll = !staticModeActive;

        const dx = scrollPosition.left - (e.clientX - scrollPosition.x);
        const dy = allowVerticalScroll ? scrollPosition.top - (e.clientY - scrollPosition.y) : scrollPosition.top;

        (chartContainer.current as HTMLElement).scrollLeft = dx;
        (chartContainer.current as HTMLElement).scrollTop = dy;
        (priceContainer.current as HTMLElement).scrollTop = dy;
        (timeContainer.current as HTMLElement).scrollLeft = dx;
        (indicatorContainer.current as HTMLElement).scrollLeft = dx;

        let scrolledCandles = Math.floor((dx - CANVAS_PADDING - spaceGap) / (CANDLE_OFFSET * scaleIndex));

        if (scrolledCandles < 0) {
            scrolledCandles = 0;
        }

        updateClosePosition(scrolledCandles);

        if (staticModeActive && dx > 0 && dx < CANVAS_WIDTH) {
            checkNewExtremums(scrolledCandles, scrolledCandles > scrolledCandlesCount)

        }
    };

    const chartMouseUpHandler = (e: any) => { //MouseEvent
        if (!chartMouseDown) {
            return;
        }

        setChartMouseDown(false);

        if (chartContainer.current == null) {
            return;
        }

        const allowVerticalScroll = !staticModeActive;

        const dy = allowVerticalScroll ? scrollPosition.top - (e.clientY - scrollPosition.y) : scrollPosition.top;

        let scrolledPixels = dy - scrollPosition.top;

        if (scrolledPixels < 0) {
            scrolledPixels = scrolledPixels * -1;
        }

        const scrollValue = (chartContainer.current as HTMLElement).scrollLeft;
        const direction: boolean = scrollValue - scrollLeft > 0;
        const scrolledCandlesCount = getScrolledCandlesCount(scaleIndex, scrollLeft, scrollValue);
        setScrollLeft(scrollValue);
        getShiftedCandles(scrolledCandlesCount, direction);

        if (staticModeActive) {
            return;
        }
    };

    const checkNewExtremums = (scrolledCandles: number, direction: boolean): void => {
        if (scrolledCandles === scrolledCandlesCount) {
            return;
        }

        scrolledCandlesCount = scrolledCandles;
        let startIndex = scrolledCandles;
        let endIndex = Math.floor(scrolledCandles + candlesOnViewPort);

        if (startIndex < 0) {
            startIndex = 0;
        }

        let candleIn = direction ? candles[endIndex + 1] : candles[startIndex];
        let candleOut = direction ? candles[startIndex - 1] : candles[endIndex];

        const hasNewExtremums = checkAvailableExtremums(candleIn, candleOut, extremums.highestPrice, extremums.lowestPrice);

        if (hasNewExtremums) {
            const payload: NewExtremumsPayload = {
                type: ChartEvent.HandleNewExtremums,
                candles,
                scaleIndex,
                canvasWidth,
                scrollLeft: (chartContainer.current as HTMLElement).scrollLeft,
                spaceGap,
                canvasHeight,
                chartStyle
            }

            worker.postMessage(payload);
        }
    }

    const addNewCandles = (data: AddNewCandlesSuccessPayload) => {
        setCandles(data.candles);
        setSpaceGap(data.spaceGap);
        setExtremums(data.extremums);
        setTimeValues(data.timeValues);
        setCandleSize(data.candleSize);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setInitalCandlesData = (data: InitCandlesSuccessPayload) => {
        setCandles(data.candles);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setExtremums(data.extremums);
        setCandleSize(data.candleSize);
        setSpaceGap(data.spaceGap);
        setScrollLeft(data.scrollLeft);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setScaleResults = (data: ScaleSuccessPayload) => {
        setCandles(data.candles);
        setTimeValues(data.timeValues);
        setSpaceGap(data.spaceGap);
        setCandleSize(data.candleSize);
        setScaleIndex(data.scaleIndex);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setModeChangeResults = (data: ChangeModeSuccessPayload) => {
        setCandles(data.candles);
        setExtremums(data.extremums);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setStaticModeActive(data.staticModeActive);
        setCandleSize(data.candleSize);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setZoomChangeResults = (data: ChangeZoomSuccessPayload) => {
        setCandles(data.candles);
        setExtremums(data.extremums);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setCandleSize(data.candleSize);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setNewExtremumsResults = (data: NewExtremumsSuccessPayload) => {
        setCandles(data.candles);
        setExtremums(data.extremums);
        setTimeValues(data.timeValues);
        setCandleSize(data.candleSize);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const updateClusterTiles = (data: UpdateClustersSuccessPayload) => {
        setCandles(data.candles);
    }

    const fetchCandles = (dateToUtc: string, candlesCount: number, direction?: boolean): void => {
        const secondsCount = getSecondsCount(timeFrame, timeCount);
        const countToFetch = getCountByDirection(candlesCount, direction);

        if (dateToUtc == null || dateToUtc === "") {
            const rows = generateEmptyRrows(candlesCount, []);

            const payload: AddNewCandlesPayload = {
                type: ChartEvent.AddNewCandles,
                rows,
                scaleIndex,
                candles,
                direction,
                spaceGap,
                chartStyle,
                extremums,
                canvasHeight
            }

            worker.postMessage(payload);

            return;
        }

        CandleService.getCandles(dateToUtc, countToFetch, secondsCount)
            .then(response => {
                setLoading(false);
                const data = response?.results?.A?.frames[0]?.data?.values as number[][];
                const rows = data == null || data[0].length == 0 || data[0].length != candlesCount ? generateEmptyRrows(candlesCount, data) : data;

                const payload: AddNewCandlesPayload = {
                    type: ChartEvent.AddNewCandles,
                    rows,
                    scaleIndex,
                    candles,
                    direction,
                    spaceGap,
                    extremums,
                    chartStyle,

                    canvasHeight
                }

                worker.postMessage(payload);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });


        setLoading(true);
    }

    const initCandles = (): void => {
        if (mainChart.current?.scrollHeight !== CANVAS_HEIGHT || customScale) {
            return;
        }

        const candlesOnView = Math.floor(canvasWidth / (CANDLE_OFFSET * scaleIndex));

        setCandlesOnViewPort(candlesOnView);

        if (candlesOnView == 0) {
            return;
        }

        setLoading(true);
        buildCurrentCandle();

        const secondsCount = getSecondsCount(timeFrame, timeCount);
        const dateTo = moment().utc().format(DATE_FORMAT);
        const count = candlesOnView * -1 * (WINDOW_COUNT / 2);

        CandleService.getCandles(dateTo, count, secondsCount)
            .then(response => {
                const data = response?.results?.A?.frames[0]?.data?.values;
                const rows = generateEmptyRrows(candlesOnView * WINDOW_COUNT, data);
                if (data == null || data.length == 0) {
                    setMaintenance(true);
                    return;
                }

                const payload: InitCandlesPayload = {
                    type: ChartEvent.InitCandlesData,
                    rows,
                    scaleIndex,
                    canvasWidth,
                    canvasHeight,
                    chartStyle
                }

                worker.postMessage(payload);

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    const buildCurrentCandle = (): void => {
        const secondsCount = getSecondsCount(timeFrame, timeCount);
        setInterval(() => {
            const dateTo = moment().utc().format(DATE_FORMAT);

            CandleService.getCandles(dateTo, -1, secondsCount)
                .then(response => {
                    const data = response?.results?.A?.frames[0]?.data?.values;

                    const ucandle = getParsedResponseCandleRows(data)[0];
                    setCurrentCandle(ucandle);
                })
                .catch((error) => {
                    console.error(error);
                });
        }, 1000)
    }

    const getScaledCandles = (candlesCount: number): void => {
        if (candlesCount === 0) {
            return;
        }

        const dateToUtc = getDateStringByCandlesCount(candles.length, candlesCount, candles[0].timeClose, timeFrame);

        fetchCandles(dateToUtc, candlesCount);
    }

    const getShiftedCandles = (candlesCount: number, direction: boolean) => {
        if (candlesCount === 0) {
            return;
        }

        const dateToUtc = getDateStringByDirection(direction, candles);

        fetchCandles(dateToUtc, candlesCount, direction);
    }

    useEffect(() => {
        if (canvasWidth == 0 || !customScale) {
            return;
        }

        const count = Math.ceil(canvasWidth / (CANDLE_OFFSET * scaleIndex));

        setCandlesOnViewPort(count);

        debounce(() => { getScaledCandles(count * WINDOW_COUNT) });
    }, [scaleIndex]);

    const updateCurrentCandle = () => {
        let candleInList: UCandle | undefined = candles.find(candle => candle.id === currentCandle.id) || candles.find(candle => candle.id === 0);

        if (candleInList == null) {
            return;
        }

        const c: UCandle = calculateCandleY(currentCandle, extremums);
        candleInList = copyCandle(candleInList, c);
    }

    useEffect(() => {
        if (currentCandle.close == null) {
            return;
        }
        const price = currentCandle.close;
        const position = calculateY(price, extremums);
        setLatestPrice(price);
        setLatestPricePosition(position);

        updateCurrentCandle();
    }, [currentCandle]);

    useEffect(() => {
        initCandles();
    }, [mainChart.current, timeCount, timeFrame, scaleIndex]);

    useEffect(() => {
        if (viewPortDataDefined) {
            return;
        }

        defineViewPortData();
        resize_ob.observe(document.querySelector<HTMLElement>('.right-menu-container') as Element);
        resize_ob.observe(document.querySelector<HTMLElement>('.navigation-menu-container') as Element);

    }, []);

    window.onresize = () => {
        debounce(() => {
            const canvasWidth = defineViewPortData();

            const count = Math.ceil(canvasWidth / (CANDLE_OFFSET * scaleIndex));
            setCandlesOnViewPort(count);

            getScaledCandles(count * WINDOW_COUNT);
        });
    };

    useEffect(() => {
        (chartContainer.current as HTMLElement).scrollLeft = scrollLeft;
        (timeContainer.current as HTMLElement).scrollLeft = scrollLeft;
        (indicatorContainer.current as HTMLElement).scrollLeft = scrollLeft;
        updateClosePosition();
    }, [scrollLeft]);

    useEffect(() => {
        (priceContainer.current as HTMLElement).scrollTop = extremums.scrollTop;
        (chartContainer.current as HTMLElement).scrollTop = extremums.scrollTop;
    }, [extremums.scrollTop]);

    useEffect(() => {
        if (scale === scaleIndex) {
            return;
        }

        setScaleIndex(scale);
    }, [scale])

    useEffect(() => {
        const candleSize = getCandleSize(scaleIndex, chartStyle, extremums);
        setCandleSize(candleSize);
    }, [chartStyle])

    useEffect(() => {
        if (workerSubscribed) {
            return;
        }

        setWorkerSubscribed(true);

        worker.onmessage = (event: any) => {
            const data = event.data;
            console.log('SUCCESS EVENT: ', data.type);

            if (data.type === ChartEvent.InitCandlesDataSuccess) {
                setInitalCandlesData(data);
            }

            if (data.type === ChartEvent.AddNewCandlesSuccess) {
                addNewCandles(data)
            }

            else if (data.type === ChartEvent.HandleChartScaleSuccess) {
                setScaleResults(data);
            }

            else if (data.type === ChartEvent.HandleModeChangeSuccess) {
                setModeChangeResults(data)
            }

            else if (data.type === ChartEvent.HandleNewExtremumsSuccess) {
                setNewExtremumsResults(data)
            }

            else if (data.type === ChartEvent.HandleChartZoomSuccess) {
                setZoomChangeResults(data)
            }

            else if (data.type === ChartEvent.UpdateClustersSuccess) {
                updateClusterTiles(data)
            }
        }
    }, [workerSubscribed]);

    const renderContent = () => {
        return (
            <>
                <svg id='mainChart'
                    className={`${chartStyle} cluster-chart`}
                    width={CANVAS_WIDTH}
                    height={CANVAS_HEIGHT}
                    ref={mainChart}
                    onMouseMove={handleChartMouseMove}>
                    {[
                        [cursorPositionX, 0, cursorPositionX, '100%'],
                        [0, cursorPositionY, '100%', cursorPositionY]
                    ].map(([x1, y1, x2, y2], index) => <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="cornflowerblue" key={index} strokeDasharray="4"></line>)}

                    {isJapaneeseChartVisible(chartStyle) &&
                        <JapaneeseCandleChart
                            candles={candles}
                            candleSize={candleSize}
                            showBackgroundIndicator={showBackgroundIndicator}></JapaneeseCandleChart>
                    }
                    {isSnakeChartVisible(chartStyle) &&
                        <SnakeCandleChart
                            candleSize={candleSize}
                            candles={candles}></SnakeCandleChart>
                    }
                    {isLineChartVisible(chartStyle) &&
                        <LineChart lineChartData={lineChartData}></LineChart>
                    }
                    {isClusterChartVisible(chartStyle) &&
                        <ClusterChart candles={candles}
                            scaleIndex={scaleIndex}
                            candleSize={candleSize}></ClusterChart>
                    }
                </svg>

            </>
        )
    }

    const renderTimeScale = () => {
        return (
            <svg className="time-wrapper" width={CANVAS_WIDTH} >
                {timeValues.map((time, index) => {
                    return (
                        <text key={index}
                            x={time.positionX}
                            y={TIME_VALUE_Y}
                            className="time-value">
                            {time.value}
                        </text>
                    )
                })}

                <svg x={hoveredCandlePosition} width="100" height="20">
                    <rect x="0" y="0" width="100" height="20" fill="#49B6FF" rx="3px" />
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="time-value hovered">{hoveredCandleTime}</text>
                </svg>
            </svg>
        )
    }

    const renderPriceScale = () => {
        return (
            <svg className="price-wrapper" style={{ height: CANVAS_HEIGHT + 'px' }}>
                <defs>
                    <filter x="0" y="0" width="1" height="1" id="solid">
                        <feFlood floodColor="red" result="bg" />
                        <feMerge>
                            <feMergeNode in="bg" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>

                {priceValues.map((price, index) => {
                    return (
                        <text key={index}
                            y={price.positionY}
                            x='50%'
                            className="price-value">
                            {price.value}
                        </text>
                    )
                })}
                <svg y={closePricePosition} className="price-container" width="70" height="20">
                    <rect className="price-indicator" x="0" y="0" />
                    <text x="50%" y="45%" dominantBaseline="central" textAnchor="middle" className="price-value">{closePrice}</text>
                </svg>
                <svg y={latestPricePosition} className="price-container" width="70" height="20">
                    <rect className="price-indicator latest" x="0" y="0" />
                    <text x="50%" y="45%" dominantBaseline="central" textAnchor="middle" className="price-value">{latestPrice}</text>
                </svg>
            </svg>
        )
    }

    const renderMaintenance = () => {
        return (
            <>
                {maintenance &&
                    <div className="maintenance-container">
                        <div className="maintenance-message">Temporarily Down for Maintenance</div>
                        <img src={MaintenanceIcon} alt="maintenance" />
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {maintenance && renderMaintenance()}
            {!maintenance &&
                <>
                    <div className={"main-container " + (loading ? 'loading' : '')}
                        onMouseMove={canvasMouseMoveHandler}
                        onMouseUp={canvasMouseUpHandler}
                        onMouseLeave={canvasMouseUpHandler}>
                        <div className='loader'></div>
                        <div className="top-bar">{headerTitle}</div>
                        <div className={"static-mode-togler " + (staticModeActive ? 'active' : '')} onClick={toggleStaticMode}>
                            <img src={StaticModeIcon} alt="staticMode" />
                        </div>
                        <div className="bottom-panel">
                            <div id="timeContainer"
                                className="time-container"
                                ref={timeContainer}
                                onWheel={mousewheelChartHandler}
                                onMouseDown={timeMouseDownHandler}>
                                {renderTimeScale()}
                            </div>

                            <IndicatorsMenu indicators={indicators} updateIndicators={updateIndicators}></IndicatorsMenu>
                        </div>

                        <div className="price-container"
                            ref={priceContainer}
                            style={{ height: canvasHeight + 'px' }}
                            onWheel={mousewheelPriceHandler}
                            onMouseDown={priceMouseDownHandler}
                            id="priceContainer">
                            {renderPriceScale()}
                        </div>
                        <div className={`main-chart-container ${chartSettings.clusterPrice}`}
                            ref={chartContainer}
                            id="mainChartContainer"
                            style={{ width: canvasWidth + 'px', height: canvasHeight + 'px' }}
                            onWheel={mousewheelChartHandler}
                            onMouseDown={chartMouseDownHandler}>
                            {renderContent()}
                        </div>
                        <div id='indicatorContainer' ref={indicatorContainer}>
                            <IndicatorList 
                            candles={candles} 
                            indicators={indicators} 
                            candleSize={candleSize}
                            spaceGap={spaceGap}
                            scaleIndex={scaleIndex}></IndicatorList>
                        </div>
                    </div>

                    <SettingsMenu></SettingsMenu>
                </>
            }
        </>
    )
}