export const candlesMock = [
    {
        "_id": "627deb0dd6b205ae80b0179e",
        "ask": 53,
        "bid": 58,
        "high": 12999.6665,
        "open": 12871.3536,
        "close": 12818.0135,
        "low": 12742.7702
    },
    {
        "_id": "627deb0d45197e33b1fc3905",
        "ask": 19,
        "bid": 86,
        "high": 12901.4575,
        "open": 12863.4902,
        "close": 12808.702,
        "low": 12778.7376
    },
    {
        "_id": "627deb0d43f7fe871b94ef85",
        "ask": 57,
        "bid": 99,
        "high": 12914.779,
        "open": 12866.9493,
        "close": 12814.7024,
        "low": 12728.2033
    },
    {
        "_id": "627deb0daf5c1de04adf9903",
        "ask": 82,
        "bid": 63,
        "high": 12934.9362,
        "open": 12898.0761,
        "close": 12802.9593,
        "low": 12713.0469
    },
    {
        "_id": "627deb0de18549023e6de426",
        "ask": 50,
        "bid": 11,
        "high": 12964.9427,
        "open": 12897.1907,
        "close": 12804.4956,
        "low": 12789.2108
    },
    {
        "_id": "627deb0d2549e1456ad76a04",
        "ask": 63,
        "bid": 82,
        "high": 12920.3782,
        "open": 12884.8462,
        "close": 12823.0655,
        "low": 12726.1476
    },
    {
        "_id": "627deb0d718c43ebf1e40717",
        "ask": 63,
        "bid": 49,
        "high": 12926.3886,
        "open": 12891.074,
        "close": 12849.1658,
        "low": 12758.9503
    },
    {
        "_id": "627deb0dff6bb1ad21c38edf",
        "ask": 17,
        "bid": 66,
        "high": 12947.5286,
        "open": 12856.9977,
        "close": 12828.8167,
        "low": 12792.0818
    },
    {
        "_id": "627deb0d7a70a6b33b2a228b",
        "ask": 62,
        "bid": 71,
        "high": 12987.8991,
        "open": 12867.2764,
        "close": 12845.7295,
        "low": 12744.0958
    },
    {
        "_id": "627deb0d2d6eccb5d8c35710",
        "ask": 75,
        "bid": 78,
        "high": 12982.325,
        "open": 12865.8688,
        "close": 12836.8608,
        "low": 12718.3569
    },
    {
        "_id": "627deb0db2e21553482f1148",
        "ask": 31,
        "bid": 70,
        "high": 12942.8359,
        "open": 12886.243,
        "close": 12826.278,
        "low": 12792.0865
    },
    {
        "_id": "627deb0d746dee918fec28c5",
        "ask": 39,
        "bid": 55,
        "high": 12982.5253,
        "open": 12852.028,
        "close": 12849.7871,
        "low": 12725.2522
    },
    {
        "_id": "627deb0dfd37cc4e8f9caeba",
        "ask": 56,
        "bid": 40,
        "high": 12962.1009,
        "open": 12868.03,
        "close": 12807.6688,
        "low": 12747.067
    },
    {
        "_id": "627deb0dc5b412e14360fa10",
        "ask": 56,
        "bid": 47,
        "high": 12994.758,
        "open": 12897.3599,
        "close": 12847.518,
        "low": 12751.9203
    },
    {
        "_id": "627deb0d008d04df4c29f0e8",
        "ask": 84,
        "bid": 57,
        "high": 12918.9201,
        "open": 12898.6328,
        "close": 12846.0103,
        "low": 12753.1424
    },
    {
        "_id": "627deb0d8d6aa7926238432c",
        "ask": 50,
        "bid": 71,
        "high": 12910.5176,
        "open": 12858.2069,
        "close": 12807.4611,
        "low": 12706.5623
    },
    {
        "_id": "627deb0dfc92b20006d21edf",
        "ask": 40,
        "bid": 29,
        "high": 12911.8145,
        "open": 12888.1188,
        "close": 12804.7714,
        "low": 12710.5621
    },
    {
        "_id": "627deb0d635f12d805eb1e9e",
        "ask": 81,
        "bid": 30,
        "high": 12906.9988,
        "open": 12894.8389,
        "close": 12832.7338,
        "low": 12732.4994
    },
    {
        "_id": "627deb0de2f9f80db3aebae4",
        "ask": 71,
        "bid": 94,
        "high": 12904.7355,
        "open": 12880.2465,
        "close": 12812.9287,
        "low": 12782.2404
    },
    {
        "_id": "627deb0de7af8bfa189bf7a8",
        "ask": 35,
        "bid": 71,
        "high": 12963.6503,
        "open": 12868.5008,
        "close": 12803.243,
        "low": 12747.9129
    },
    {
        "_id": "627deb0db1d79af444b98c0c",
        "ask": 16,
        "bid": 57,
        "high": 12907.6743,
        "open": 12880.198,
        "close": 12830.382,
        "low": 12781.0155
    },
    {
        "_id": "627deb0d96ae39e00732df93",
        "ask": 33,
        "bid": 77,
        "high": 12999.18,
        "open": 12871.4151,
        "close": 12804.9825,
        "low": 12731.7808
    },
    {
        "_id": "627deb0de8d33f65a07cf3fe",
        "ask": 62,
        "bid": 96,
        "high": 12983.1148,
        "open": 12885.6086,
        "close": 12814.9041,
        "low": 12746.3127
    },
    {
        "_id": "627deb0db05b7b42d18fee1e",
        "ask": 61,
        "bid": 14,
        "high": 12913.8419,
        "open": 12857.4246,
        "close": 12833.0701,
        "low": 12746.4388
    },
    {
        "_id": "627deb0dc5c90216cf4cd472",
        "ask": 90,
        "bid": 26,
        "high": 12950.6914,
        "open": 12887.2505,
        "close": 12816.0232,
        "low": 12704.0771
    },
    {
        "_id": "627deb0d6c57ec7aad80ede3",
        "ask": 17,
        "bid": 3,
        "high": 12946.4555,
        "open": 12896.156,
        "close": 12816.3659,
        "low": 12716.2051
    },
    {
        "_id": "627deb0dd4eed176a70d54dd",
        "ask": 24,
        "bid": 49,
        "high": 12971.0771,
        "open": 12890.6874,
        "close": 12800.4601,
        "low": 12790.8671
    },
    {
        "_id": "627deb0dc16c6decbb8a8133",
        "ask": 11,
        "bid": 6,
        "high": 12928.1251,
        "open": 12883.9188,
        "close": 12820.1475,
        "low": 12787.9713
    },
    {
        "_id": "627deb0d54b1d18971ae4fea",
        "ask": 73,
        "bid": 56,
        "high": 12961.8439,
        "open": 12852.4805,
        "close": 12836.5746,
        "low": 12779.9927
    },
    {
        "_id": "627deb0d4386db89301b9f77",
        "ask": 72,
        "bid": 42,
        "high": 12951.0498,
        "open": 12865.8219,
        "close": 12840.649,
        "low": 12718.297
    },
    {
        "_id": "627deb0d343d4a71bb34bfb7",
        "ask": 32,
        "bid": 11,
        "high": 12949.0321,
        "open": 12878.2389,
        "close": 12808.5591,
        "low": 12785.3437
    },
    {
        "_id": "627deb0d5fa082b130639c9e",
        "ask": 86,
        "bid": 88,
        "high": 12962.8753,
        "open": 12875.9603,
        "close": 12837.4124,
        "low": 12780.7322
    },
    {
        "_id": "627deb0daa3c156b03cbf5f4",
        "ask": 3,
        "bid": 17,
        "high": 12913.6822,
        "open": 12856.5795,
        "close": 12827.109,
        "low": 12725.6498
    },
    {
        "_id": "627deb0db2854798b501c6f4",
        "ask": 64,
        "bid": 75,
        "high": 12900.6925,
        "open": 12859.3322,
        "close": 12801.6178,
        "low": 12747.1828
    },
    {
        "_id": "627deb0de7889b0076d21e9b",
        "ask": 8,
        "bid": 55,
        "high": 12979.3849,
        "open": 12898.5166,
        "close": 12849.0164,
        "low": 12774.8573
    },
    {
        "_id": "627deb0d0592597357b7fc92",
        "ask": 14,
        "bid": 1,
        "high": 12905.3283,
        "open": 12899.9784,
        "close": 12848.2422,
        "low": 12741.0568
    },
    {
        "_id": "627deb0dce04054e30702d9a",
        "ask": 3,
        "bid": 91,
        "high": 12917.3419,
        "open": 12869.7253,
        "close": 12813.7892,
        "low": 12767.2077
    },
    {
        "_id": "627deb0d652d9e5f052b20a4",
        "ask": 42,
        "bid": 81,
        "high": 12965.4225,
        "open": 12863.2425,
        "close": 12810.2483,
        "low": 12762.7696
    },
    {
        "_id": "627deb0d49ee730e2937b604",
        "ask": 54,
        "bid": 57,
        "high": 12970.1299,
        "open": 12897.8343,
        "close": 12842.1069,
        "low": 12741.0811
    },
    {
        "_id": "627deb0d48342f4ddbe23e3e",
        "ask": 59,
        "bid": 66,
        "high": 12970.3974,
        "open": 12868.5308,
        "close": 12833.5759,
        "low": 12711.9008
    },
    {
        "_id": "627deb0d3d180dd22ef3533c",
        "ask": 31,
        "bid": 63,
        "high": 12997.5374,
        "open": 12875.5405,
        "close": 12831.6977,
        "low": 12730.0542
    },
    {
        "_id": "627deb0d812f41f6493583bd",
        "ask": 39,
        "bid": 65,
        "high": 12927.82,
        "open": 12877.9068,
        "close": 12811.2614,
        "low": 12753.8956
    },
    {
        "_id": "627deb0d3f036980a1c3326f",
        "ask": 100,
        "bid": 9,
        "high": 12938.9394,
        "open": 12885.0648,
        "close": 12823.5316,
        "low": 12741.0323
    },
    {
        "_id": "627deb0d0eda1bd9f0f2ee6c",
        "ask": 35,
        "bid": 63,
        "high": 12917.001,
        "open": 12873.2719,
        "close": 12842.2682,
        "low": 12758.3881
    },
    {
        "_id": "627deb0d5e170c7fe917d172",
        "ask": 72,
        "bid": 56,
        "high": 12932.388,
        "open": 12873.6078,
        "close": 12838.1605,
        "low": 12786.4544
    },
    {
        "_id": "627deb0d6c18e179421897f7",
        "ask": 98,
        "bid": 21,
        "high": 12968.669,
        "open": 12893.4539,
        "close": 12802.4131,
        "low": 12740.4729
    },
    {
        "_id": "627deb0dd26c996071fbb369",
        "ask": 18,
        "bid": 100,
        "high": 12943.2541,
        "open": 12892.9321,
        "close": 12840.0363,
        "low": 12717.8462
    },
    {
        "_id": "627deb0df2d1052ad7746028",
        "ask": 32,
        "bid": 58,
        "high": 12947.8586,
        "open": 12892.45,
        "close": 12833.1555,
        "low": 12798.3601
    },
    {
        "_id": "627deb0dd05ed573932f26db",
        "ask": 58,
        "bid": 13,
        "high": 12948.586,
        "open": 12890.7205,
        "close": 12836.4125,
        "low": 12766.3453
    },
    {
        "_id": "627deb0de32bd55ffe41ac54",
        "ask": 36,
        "bid": 26,
        "high": 12943.1272,
        "open": 12856.5907,
        "close": 12807.0089,
        "low": 12718.4273
    },
    {
        "_id": "627deb0dae3fa1d4f21d24d3",
        "ask": 71,
        "bid": 24,
        "high": 12911.3495,
        "open": 12897.2774,
        "close": 12825.6936,
        "low": 12716.9428
    },
    {
        "_id": "627deb0dd82a9e1c3b748b9d",
        "ask": 49,
        "bid": 36,
        "high": 12969.6421,
        "open": 12854.1667,
        "close": 12829.3628,
        "low": 12775.2623
    },
    {
        "_id": "627deb0dc2373a166ccfd194",
        "ask": 94,
        "bid": 58,
        "high": 12974.9911,
        "open": 12850.2164,
        "close": 12815.8819,
        "low": 12738.203
    },
    {
        "_id": "627deb0da3851d07cb202bce",
        "ask": 91,
        "bid": 79,
        "high": 12980.5079,
        "open": 12885.9493,
        "close": 12809.7977,
        "low": 12719.453
    },
    {
        "_id": "627deb0dcb7bd44865505b4a",
        "ask": 41,
        "bid": 3,
        "high": 12941.3531,
        "open": 12874.2815,
        "close": 12838.2841,
        "low": 12759.0587
    },
    {
        "_id": "627deb0de8da3b46c28ad5bc",
        "ask": 34,
        "bid": 78,
        "high": 12969.8236,
        "open": 12889.9233,
        "close": 12807.512,
        "low": 12786.9109
    },
    {
        "_id": "627deb0dee87356a896a686f",
        "ask": 74,
        "bid": 35,
        "high": 12931.9344,
        "open": 12860.4245,
        "close": 12837.3592,
        "low": 12796.8676
    },
    {
        "_id": "627deb0d38570364caad302b",
        "ask": 48,
        "bid": 100,
        "high": 12932.3946,
        "open": 12858.881,
        "close": 12838.9217,
        "low": 12735.5452
    },
    {
        "_id": "627deb0dd4adeb6378168376",
        "ask": 17,
        "bid": 42,
        "high": 12983.7881,
        "open": 12859.7931,
        "close": 12844.0409,
        "low": 12779.1135
    },
    {
        "_id": "627deb0d43fd45f9258753db",
        "ask": 28,
        "bid": 70,
        "high": 12921.7329,
        "open": 12898.4162,
        "close": 12805.3705,
        "low": 12779.8879
    },
    {
        "_id": "627deb0d87acb8c3b0279463",
        "ask": 80,
        "bid": 87,
        "high": 12932.2259,
        "open": 12894.827,
        "close": 12801.1845,
        "low": 12716.3481
    },
    {
        "_id": "627deb0d66a89365727edbb3",
        "ask": 81,
        "bid": 57,
        "high": 12921.3358,
        "open": 12858.762,
        "close": 12823.924,
        "low": 12749.9993
    },
    {
        "_id": "627deb0d5d78196035d3d755",
        "ask": 52,
        "bid": 64,
        "high": 12937.1563,
        "open": 12858.5086,
        "close": 12805.7367,
        "low": 12763.3636
    },
    {
        "_id": "627deb0dfdd6c6e5219d83b4",
        "ask": 49,
        "bid": 5,
        "high": 12922.2027,
        "open": 12895.2953,
        "close": 12823.6435,
        "low": 12716.8529
    },
    {
        "_id": "627deb0d0dc59c5809e4d586",
        "ask": 7,
        "bid": 11,
        "high": 12927.471,
        "open": 12883.6258,
        "close": 12848.3871,
        "low": 12786.6741
    },
    {
        "_id": "627deb0d2e65dbb8e4c08972",
        "ask": 74,
        "bid": 70,
        "high": 12976.315,
        "open": 12867.4028,
        "close": 12818.8855,
        "low": 12751.3788
    },
    {
        "_id": "627deb0da212e54515df5091",
        "ask": 97,
        "bid": 27,
        "high": 12987.1879,
        "open": 12877.7301,
        "close": 12818.5666,
        "low": 12715.6231
    },
    {
        "_id": "627deb0dbf214fa0e181649f",
        "ask": 52,
        "bid": 15,
        "high": 12971.1216,
        "open": 12869.7229,
        "close": 12834.7959,
        "low": 12725.879
    },
    {
        "_id": "627deb0d8b8eebb7e24d06fc",
        "ask": 19,
        "bid": 33,
        "high": 12919.0772,
        "open": 12860.4716,
        "close": 12813.777,
        "low": 12782.844
    },
    {
        "_id": "627deb0d9bde534bf6c23da4",
        "ask": 72,
        "bid": 44,
        "high": 12943.1098,
        "open": 12859.5654,
        "close": 12824.9101,
        "low": 12702.0954
    },
    {
        "_id": "627deb0d22cb9df5b21ee3bd",
        "ask": 10,
        "bid": 84,
        "high": 12986.2212,
        "open": 12867.3769,
        "close": 12835.9572,
        "low": 12735.154
    },
    {
        "_id": "627deb0d99ccb3851bbe48dd",
        "ask": 27,
        "bid": 89,
        "high": 12948.3124,
        "open": 12877.3917,
        "close": 12826.461,
        "low": 12770.2041
    },
    {
        "_id": "627deb0d03d0cf72b212c9ae",
        "ask": 47,
        "bid": 74,
        "high": 12964.9201,
        "open": 12854.3323,
        "close": 12842.5352,
        "low": 12707.5787
    },
    {
        "_id": "627deb0d8a02049467ad83ca",
        "ask": 42,
        "bid": 18,
        "high": 12957.7284,
        "open": 12869.7159,
        "close": 12804.8132,
        "low": 12784.5397
    },
    {
        "_id": "627deb0dea6b4e915559d14a",
        "ask": 18,
        "bid": 18,
        "high": 12981.5579,
        "open": 12893.3632,
        "close": 12821.8789,
        "low": 12782.5657
    },
    {
        "_id": "627deb0de404307cb363f22a",
        "ask": 57,
        "bid": 66,
        "high": 12953.4173,
        "open": 12871.5474,
        "close": 12838.34,
        "low": 12752.7671
    },
    {
        "_id": "627deb0d8d0d11b5461848d4",
        "ask": 30,
        "bid": 49,
        "high": 12979.7605,
        "open": 12874.5537,
        "close": 12829.151,
        "low": 12754.9533
    },
    {
        "_id": "627deb0de4a7bf0546b2bfe8",
        "ask": 46,
        "bid": 30,
        "high": 12982.1195,
        "open": 12874.7088,
        "close": 12816.2449,
        "low": 12741.9513
    },
    {
        "_id": "627deb0dab0feb699d762f4e",
        "ask": 2,
        "bid": 55,
        "high": 12922.7928,
        "open": 12875.0374,
        "close": 12808.6234,
        "low": 12775.4213
    },
    {
        "_id": "627deb0d416a0122edae09b5",
        "ask": 87,
        "bid": 87,
        "high": 12960.9597,
        "open": 12861.3425,
        "close": 12804.6555,
        "low": 12794.742
    },
    {
        "_id": "627deb0d945642f5b69e9534",
        "ask": 42,
        "bid": 36,
        "high": 12998.2637,
        "open": 12873.1025,
        "close": 12802.023,
        "low": 12781.5068
    },
    {
        "_id": "627deb0da385a63121a86c46",
        "ask": 30,
        "bid": 35,
        "high": 12976.2313,
        "open": 12878.6868,
        "close": 12828.5188,
        "low": 12702.6725
    },
    {
        "_id": "627deb0d88930e62746810bd",
        "ask": 25,
        "bid": 82,
        "high": 12978.442,
        "open": 12894.072,
        "close": 12812.2206,
        "low": 12745.3161
    },
    {
        "_id": "627deb0dd940d4e53dc3968d",
        "ask": 86,
        "bid": 90,
        "high": 12976.7978,
        "open": 12864.3177,
        "close": 12824.0051,
        "low": 12735.4907
    },
    {
        "_id": "627deb0dff9f2af661de758e",
        "ask": 31,
        "bid": 3,
        "high": 12921.0572,
        "open": 12868.528,
        "close": 12847.4864,
        "low": 12739.5629
    },
    {
        "_id": "627deb0d6aa0761af5467f84",
        "ask": 53,
        "bid": 57,
        "high": 12933.9705,
        "open": 12874.1569,
        "close": 12835.6698,
        "low": 12742.1
    },
    {
        "_id": "627deb0dd85a8aca696ce0e3",
        "ask": 81,
        "bid": 16,
        "high": 12952.2115,
        "open": 12852.8314,
        "close": 12837.7217,
        "low": 12750.0138
    },
    {
        "_id": "627deb0d71f96e6c5f60d701",
        "ask": 13,
        "bid": 65,
        "high": 12985.4806,
        "open": 12868.658,
        "close": 12807.1208,
        "low": 12751.1181
    },
    {
        "_id": "627deb0d7e02bea18de50def",
        "ask": 52,
        "bid": 80,
        "high": 12967.8056,
        "open": 12883.406,
        "close": 12813.0369,
        "low": 12709.7554
    },
    {
        "_id": "627deb0d19536656bef3b328",
        "ask": 0,
        "bid": 21,
        "high": 12972.2441,
        "open": 12854.1285,
        "close": 12819.8782,
        "low": 12725.8614
    },
    {
        "_id": "627deb0d90fa1342f7233443",
        "ask": 40,
        "bid": 29,
        "high": 12971.2179,
        "open": 12886.763,
        "close": 12806.8784,
        "low": 12761.8238
    },
    {
        "_id": "627deb0d6ec238cedb628c35",
        "ask": 31,
        "bid": 4,
        "high": 12906.2788,
        "open": 12866.67,
        "close": 12847.2526,
        "low": 12714.4656
    },
    {
        "_id": "627deb0d8a1da189a246eed5",
        "ask": 31,
        "bid": 100,
        "high": 12950.2878,
        "open": 12868.4016,
        "close": 12806.9302,
        "low": 12755.4819
    },
    {
        "_id": "627deb0d84c20e598eb4ecbd",
        "ask": 1,
        "bid": 47,
        "high": 12950.1601,
        "open": 12857.2266,
        "close": 12834.6655,
        "low": 12704.9523
    },
    {
        "_id": "627deb0dc737702998f22e2f",
        "ask": 42,
        "bid": 90,
        "high": 12952.3905,
        "open": 12886.7011,
        "close": 12846.9364,
        "low": 12725.2762
    },
    {
        "_id": "627deb0d8a2089bfc29921b1",
        "ask": 84,
        "bid": 18,
        "high": 12904.2934,
        "open": 12877.5758,
        "close": 12849.5123,
        "low": 12795.5255
    },
    {
        "_id": "627deb0db22859d7a555d42f",
        "ask": 2,
        "bid": 99,
        "high": 12945.7748,
        "open": 12882.4507,
        "close": 12846.4368,
        "low": 12778.445
    },
    {
        "_id": "627deb0df4200d219edc13b3",
        "ask": 93,
        "bid": 84,
        "high": 12935.8097,
        "open": 12871.1099,
        "close": 12820.3396,
        "low": 12725.44
    },
    {
        "_id": "627deb0dccd2e9df32393695",
        "ask": 46,
        "bid": 93,
        "high": 12940.9389,
        "open": 12861.2048,
        "close": 12807.4152,
        "low": 12785.1742
    },
    {
        "_id": "627deb0dabc32ea6b7f1f7cd",
        "ask": 94,
        "bid": 28,
        "high": 12983.5907,
        "open": 12890.0268,
        "close": 12807.0372,
        "low": 12789.9214
    },
    {
        "_id": "627deb0d08e5d59c13b3cf12",
        "ask": 94,
        "bid": 55,
        "high": 12959.6529,
        "open": 12867.9447,
        "close": 12815.7742,
        "low": 12736.8878
    },
    {
        "_id": "627deb0d61ffd60ad8daed52",
        "ask": 63,
        "bid": 20,
        "high": 12922.8796,
        "open": 12879.7095,
        "close": 12816.844,
        "low": 12778.4369
    },
    {
        "_id": "627deb0ddd22eb2f3b17f9c6",
        "ask": 14,
        "bid": 86,
        "high": 12924.2039,
        "open": 12896.4135,
        "close": 12802.9543,
        "low": 12786.4459
    },
    {
        "_id": "627deb0db422a2800a4c8e56",
        "ask": 9,
        "bid": 26,
        "high": 12954.992,
        "open": 12894.3722,
        "close": 12838.9898,
        "low": 12722.2681
    },
    {
        "_id": "627deb0dfdbdd0b48c045ab0",
        "ask": 86,
        "bid": 56,
        "high": 12977.1868,
        "open": 12895.1616,
        "close": 12808.8705,
        "low": 12794.1383
    },
    {
        "_id": "627deb0d1cf608012c601490",
        "ask": 51,
        "bid": 97,
        "high": 12914.7539,
        "open": 12874.4883,
        "close": 12817.4542,
        "low": 12771.943
    },
    {
        "_id": "627deb0dccb6b89850c012fe",
        "ask": 91,
        "bid": 76,
        "high": 12994.4009,
        "open": 12894.5552,
        "close": 12812.981,
        "low": 12710.5634
    },
    {
        "_id": "627deb0da9b9aa60812f7025",
        "ask": 27,
        "bid": 50,
        "high": 12959.9382,
        "open": 12867.7028,
        "close": 12848.4028,
        "low": 12735.9843
    },
    {
        "_id": "627deb0dc7f58d2eafa7e6c1",
        "ask": 10,
        "bid": 68,
        "high": 12935.6538,
        "open": 12866.2831,
        "close": 12828.7444,
        "low": 12713.6664
    },
    {
        "_id": "627deb0dc766d5d196226893",
        "ask": 50,
        "bid": 52,
        "high": 12902.7171,
        "open": 12880.8829,
        "close": 12846.5065,
        "low": 12749.3635
    },
    {
        "_id": "627deb0dac2e7faa65f3a3d7",
        "ask": 58,
        "bid": 62,
        "high": 12939.1998,
        "open": 12852.4416,
        "close": 12834.1118,
        "low": 12759.3413
    },
    {
        "_id": "627deb0d934660f8ef85ee84",
        "ask": 31,
        "bid": 11,
        "high": 12923.6247,
        "open": 12875.2417,
        "close": 12829.2622,
        "low": 12703.4449
    },
    {
        "_id": "627deb0d8afa8d0ce6e66e3c",
        "ask": 9,
        "bid": 51,
        "high": 12986.7412,
        "open": 12894.2923,
        "close": 12808.4161,
        "low": 12717.1484
    },
    {
        "_id": "627deb0d564145e5a0c46a90",
        "ask": 3,
        "bid": 88,
        "high": 12939.6527,
        "open": 12880.1193,
        "close": 12815.261,
        "low": 12768.6578
    },
    {
        "_id": "627deb0dfc787968e0003749",
        "ask": 51,
        "bid": 1,
        "high": 12972.7865,
        "open": 12894.071,
        "close": 12806.3219,
        "low": 12739.471
    },
    {
        "_id": "627deb0d52ec3a664d50f71d",
        "ask": 43,
        "bid": 100,
        "high": 12995.3284,
        "open": 12878.6455,
        "close": 12835.9805,
        "low": 12723.1975
    },
    {
        "_id": "627deb0dee990b06d9b96f74",
        "ask": 23,
        "bid": 2,
        "high": 12903.5229,
        "open": 12868.9657,
        "close": 12803.581,
        "low": 12738.3236
    },
    {
        "_id": "627deb0d6bbbf69916d32984",
        "ask": 44,
        "bid": 78,
        "high": 12979.3879,
        "open": 12875.9285,
        "close": 12838.4644,
        "low": 12746.4446
    },
    {
        "_id": "627deb0d51bb4210b4571044",
        "ask": 61,
        "bid": 26,
        "high": 12913.346,
        "open": 12897.7182,
        "close": 12814.563,
        "low": 12724.116
    },
    {
        "_id": "627deb0dbed54778bc3d65b5",
        "ask": 34,
        "bid": 34,
        "high": 12917.1749,
        "open": 12871.7475,
        "close": 12836.1186,
        "low": 12789.2806
    },
    {
        "_id": "627deb0db8fff24da0cf81d0",
        "ask": 71,
        "bid": 82,
        "high": 12905.0673,
        "open": 12858.2785,
        "close": 12807.5474,
        "low": 12745.9759
    },
    {
        "_id": "627deb0ddb9bbe2d2ae97a71",
        "ask": 88,
        "bid": 30,
        "high": 12924.8037,
        "open": 12882.9787,
        "close": 12832.1608,
        "low": 12761.2516
    },
    {
        "_id": "627deb0d4c5a65ad7981b82e",
        "ask": 49,
        "bid": 41,
        "high": 12936.1733,
        "open": 12895.4129,
        "close": 12847.2932,
        "low": 12793.9394
    },
    {
        "_id": "627deb0d2789473d88047c8c",
        "ask": 88,
        "bid": 59,
        "high": 12964.6741,
        "open": 12896.8686,
        "close": 12836.7694,
        "low": 12720.2074
    },
    {
        "_id": "627deb0d4ae8cae840e65fa3",
        "ask": 74,
        "bid": 51,
        "high": 12909.0948,
        "open": 12869.4204,
        "close": 12831.6117,
        "low": 12783.0176
    },
    {
        "_id": "627deb0d691ec2697244184d",
        "ask": 8,
        "bid": 22,
        "high": 12961.3978,
        "open": 12882.9857,
        "close": 12847.3005,
        "low": 12730.3366
    },
    {
        "_id": "627deb0daee17cf09af3f7f0",
        "ask": 44,
        "bid": 2,
        "high": 12931.7841,
        "open": 12880.9426,
        "close": 12814.4401,
        "low": 12763.7131
    },
    {
        "_id": "627deb0d541299b2a1f8551d",
        "ask": 58,
        "bid": 63,
        "high": 12945.6747,
        "open": 12867.2317,
        "close": 12815.8699,
        "low": 12711.8896
    },
    {
        "_id": "627deb0dbaf97d2a5376d0f7",
        "ask": 37,
        "bid": 88,
        "high": 12961.8798,
        "open": 12855.9472,
        "close": 12827.4274,
        "low": 12749.3624
    },
    {
        "_id": "627deb0dd62138e03a5d3ccb",
        "ask": 47,
        "bid": 37,
        "high": 12970.5831,
        "open": 12874.0486,
        "close": 12844.5553,
        "low": 12781.6985
    },
    {
        "_id": "627deb0dcef20542f5e592ff",
        "ask": 58,
        "bid": 14,
        "high": 12984.0215,
        "open": 12865.5545,
        "close": 12827.8684,
        "low": 12701.4766
    },
    {
        "_id": "627deb0da948e21cffd2cf2a",
        "ask": 20,
        "bid": 32,
        "high": 12926.9491,
        "open": 12852.5022,
        "close": 12806.0311,
        "low": 12786.7287
    },
    {
        "_id": "627deb0df54a32b0abc3b3f8",
        "ask": 95,
        "bid": 56,
        "high": 12906.188,
        "open": 12890.8249,
        "close": 12813.655,
        "low": 12758.7851
    },
    {
        "_id": "627deb0d69c707bc0bd6cc6d",
        "ask": 2,
        "bid": 2,
        "high": 12974.1785,
        "open": 12871.7515,
        "close": 12805.9544,
        "low": 12727.8909
    },
    {
        "_id": "627deb0ddd62b3bdd32a865c",
        "ask": 47,
        "bid": 65,
        "high": 12903.634,
        "open": 12872.8858,
        "close": 12816.0443,
        "low": 12752.4831
    },
    {
        "_id": "627deb0d135da46a76a72a79",
        "ask": 88,
        "bid": 36,
        "high": 12993.8154,
        "open": 12891.1755,
        "close": 12832.5067,
        "low": 12719.8105
    },
    {
        "_id": "627deb0d2b1b597fd864aa52",
        "ask": 64,
        "bid": 35,
        "high": 12902.8285,
        "open": 12898.9378,
        "close": 12820.9536,
        "low": 12776.8905
    },
    {
        "_id": "627deb0d85795fd78942ac62",
        "ask": 58,
        "bid": 16,
        "high": 12994.0935,
        "open": 12886.9934,
        "close": 12833.8811,
        "low": 12741.0751
    },
    {
        "_id": "627deb0d457ebf7c285e75ad",
        "ask": 4,
        "bid": 2,
        "high": 12972.6961,
        "open": 12889.9205,
        "close": 12842.2991,
        "low": 12734.7034
    },
    {
        "_id": "627deb0d0324f8946f7adb7c",
        "ask": 76,
        "bid": 36,
        "high": 12975.6264,
        "open": 12863.7783,
        "close": 12845.9047,
        "low": 12717.1011
    },
    {
        "_id": "627deb0db18f4c4c17d30eb1",
        "ask": 46,
        "bid": 24,
        "high": 12969.3899,
        "open": 12876.306,
        "close": 12802.5202,
        "low": 12783.6751
    },
    {
        "_id": "627deb0dcfe795f0956e47b8",
        "ask": 76,
        "bid": 29,
        "high": 12913.9198,
        "open": 12852.0936,
        "close": 12844.3292,
        "low": 12727.9415
    },
    {
        "_id": "627deb0dfa0606a0d8272691",
        "ask": 11,
        "bid": 62,
        "high": 12925.1984,
        "open": 12890.9064,
        "close": 12839.4529,
        "low": 12799.5941
    },
    {
        "_id": "627deb0dbcaf7671086454ef",
        "ask": 65,
        "bid": 18,
        "high": 12988.0028,
        "open": 12853.1748,
        "close": 12801.1807,
        "low": 12729.8352
    },
    {
        "_id": "627deb0d8d0fba2f63996f2d",
        "ask": 45,
        "bid": 41,
        "high": 12984.1472,
        "open": 12894.6241,
        "close": 12845.441,
        "low": 12792.863
    },
    {
        "_id": "627deb0d3e6391fb2e572e6f",
        "ask": 68,
        "bid": 19,
        "high": 12932.1557,
        "open": 12890.9676,
        "close": 12828.2692,
        "low": 12717.463
    },
    {
        "_id": "627deb0d038c777354550de7",
        "ask": 27,
        "bid": 85,
        "high": 12924.8533,
        "open": 12868.5527,
        "close": 12813.1401,
        "low": 12738.2682
    },
    {
        "_id": "627deb0d4b627f1bdc0cd233",
        "ask": 89,
        "bid": 68,
        "high": 12924.7221,
        "open": 12863.8282,
        "close": 12814.6044,
        "low": 12735.5408
    },
    {
        "_id": "627deb0dae3a99d24af9bb67",
        "ask": 43,
        "bid": 65,
        "high": 12958.0791,
        "open": 12863.8544,
        "close": 12804.475,
        "low": 12767.0376
    },
    {
        "_id": "627deb0df33563de2064deea",
        "ask": 34,
        "bid": 40,
        "high": 12906.8466,
        "open": 12858.3551,
        "close": 12805.8094,
        "low": 12705.2194
    },
    {
        "_id": "627deb0dfebcfcb765952238",
        "ask": 67,
        "bid": 68,
        "high": 12973.5967,
        "open": 12897.7594,
        "close": 12830.8005,
        "low": 12749.023
    },
    {
        "_id": "627deb0d172e6af154b9380a",
        "ask": 60,
        "bid": 69,
        "high": 12947.7795,
        "open": 12887.7554,
        "close": 12801.8528,
        "low": 12741.1467
    },
    {
        "_id": "627deb0debfbb4d04f5d3a76",
        "ask": 92,
        "bid": 24,
        "high": 12929.9991,
        "open": 12876.0938,
        "close": 12807.5106,
        "low": 12780.6434
    },
    {
        "_id": "627deb0d2db11252ef82bed9",
        "ask": 30,
        "bid": 84,
        "high": 12957.1243,
        "open": 12887.6351,
        "close": 12810.1418,
        "low": 12748.549
    },
    {
        "_id": "627deb0dc8a360b942b93eec",
        "ask": 76,
        "bid": 15,
        "high": 12987.6349,
        "open": 12893.7101,
        "close": 12847.7208,
        "low": 12739.6645
    },
    {
        "_id": "627deb0d308622b3ac641125",
        "ask": 55,
        "bid": 38,
        "high": 12927.5751,
        "open": 12864.0253,
        "close": 12836.1122,
        "low": 12758.6337
    },
    {
        "_id": "627deb0dad6f93a8da0f8d70",
        "ask": 54,
        "bid": 24,
        "high": 12968.926,
        "open": 12870.2026,
        "close": 12848.2813,
        "low": 12745.7528
    },
    {
        "_id": "627deb0dff7049aa00223106",
        "ask": 43,
        "bid": 91,
        "high": 12956.6889,
        "open": 12859.2294,
        "close": 12804.3757,
        "low": 12749.5982
    },
    {
        "_id": "627deb0dd6a37cb5e62d9625",
        "ask": 45,
        "bid": 60,
        "high": 12959.2215,
        "open": 12870.1477,
        "close": 12807.3729,
        "low": 12729.9196
    },
    {
        "_id": "627deb0d56407756f8ee8e59",
        "ask": 67,
        "bid": 37,
        "high": 12981.3898,
        "open": 12882.1233,
        "close": 12814.1733,
        "low": 12758.6684
    },
    {
        "_id": "627deb0d42e75f1a1a4d79fe",
        "ask": 78,
        "bid": 7,
        "high": 12939.7963,
        "open": 12878.2837,
        "close": 12801.9059,
        "low": 12784.7049
    },
    {
        "_id": "627deb0ddad079d77c1d6c8b",
        "ask": 41,
        "bid": 4,
        "high": 12916.4849,
        "open": 12885.3999,
        "close": 12819.4272,
        "low": 12776.054
    },
    {
        "_id": "627deb0d104cb56c1cd71034",
        "ask": 19,
        "bid": 68,
        "high": 12961.1058,
        "open": 12897.5015,
        "close": 12801.9146,
        "low": 12793.1333
    },
    {
        "_id": "627deb0df32f70f7ff698437",
        "ask": 97,
        "bid": 62,
        "high": 12923.6694,
        "open": 12881.9426,
        "close": 12827.9504,
        "low": 12750.8782
    },
    {
        "_id": "627deb0d1a0e824deef83608",
        "ask": 1,
        "bid": 73,
        "high": 12942.1936,
        "open": 12869.3879,
        "close": 12819.1658,
        "low": 12746.4912
    },
    {
        "_id": "627deb0db4248bdd83bc376f",
        "ask": 31,
        "bid": 1,
        "high": 12943.3008,
        "open": 12856.1011,
        "close": 12832.3129,
        "low": 12705.0771
    },
    {
        "_id": "627deb0dd6dca730adfc988a",
        "ask": 10,
        "bid": 8,
        "high": 12923.5062,
        "open": 12893.2087,
        "close": 12805.8153,
        "low": 12760.6978
    },
    {
        "_id": "627deb0d5af1cd96c3903c3d",
        "ask": 47,
        "bid": 80,
        "high": 12950.0442,
        "open": 12879.2702,
        "close": 12815.8035,
        "low": 12735.7127
    },
    {
        "_id": "627deb0d2045beb7d9bfacb1",
        "ask": 8,
        "bid": 77,
        "high": 12972.0348,
        "open": 12876.2443,
        "close": 12817.9599,
        "low": 12721.6157
    },
    {
        "_id": "627deb0d558ad7a2226cf753",
        "ask": 60,
        "bid": 56,
        "high": 12932.8221,
        "open": 12878.8434,
        "close": 12832.354,
        "low": 12781.9453
    },
    {
        "_id": "627deb0d87cba3abbae1d5d5",
        "ask": 78,
        "bid": 59,
        "high": 12975.5712,
        "open": 12875.2095,
        "close": 12835.6603,
        "low": 12733.1006
    },
    {
        "_id": "627deb0d8d82e517c5bc36d3",
        "ask": 74,
        "bid": 66,
        "high": 12908.965,
        "open": 12876.8096,
        "close": 12828.0455,
        "low": 12763.477
    },
    {
        "_id": "627deb0df59adb6a3dec8db5",
        "ask": 91,
        "bid": 45,
        "high": 12907.9327,
        "open": 12855.1911,
        "close": 12810.2639,
        "low": 12755.7751
    },
    {
        "_id": "627deb0de244f424baace2fd",
        "ask": 14,
        "bid": 21,
        "high": 12907.3624,
        "open": 12854.2731,
        "close": 12803.9344,
        "low": 12798.0546
    },
    {
        "_id": "627deb0dc5210143d5119f16",
        "ask": 98,
        "bid": 51,
        "high": 12965.1058,
        "open": 12852.85,
        "close": 12839.342,
        "low": 12729.392
    },
    {
        "_id": "627deb0d2324c4c3c3adc59d",
        "ask": 71,
        "bid": 82,
        "high": 12940.6454,
        "open": 12898.3162,
        "close": 12848.1333,
        "low": 12731.8252
    },
    {
        "_id": "627deb0d4c4294f240f07e60",
        "ask": 86,
        "bid": 99,
        "high": 12985.5885,
        "open": 12898.8258,
        "close": 12818.5164,
        "low": 12796.2456
    },
    {
        "_id": "627deb0d3f33d108f5c7c5ad",
        "ask": 78,
        "bid": 30,
        "high": 12946.3364,
        "open": 12853.9226,
        "close": 12844.7683,
        "low": 12706.5953
    },
    {
        "_id": "627deb0d62d6779ab0140c05",
        "ask": 89,
        "bid": 3,
        "high": 12901.097,
        "open": 12853.5015,
        "close": 12803.7872,
        "low": 12764.6485
    },
    {
        "_id": "627deb0d6e0d96755a7b988f",
        "ask": 45,
        "bid": 72,
        "high": 12938.0314,
        "open": 12857.7532,
        "close": 12841.4166,
        "low": 12753.9171
    },
    {
        "_id": "627deb0d56f3a389ef583098",
        "ask": 37,
        "bid": 3,
        "high": 12965.3424,
        "open": 12853.7199,
        "close": 12826.3668,
        "low": 12767.8402
    },
    {
        "_id": "627deb0da99e3b89a1e801f8",
        "ask": 61,
        "bid": 24,
        "high": 12980.0175,
        "open": 12880.4451,
        "close": 12822.3667,
        "low": 12795.921
    },
    {
        "_id": "627deb0dc02402c5c76fbe5b",
        "ask": 69,
        "bid": 26,
        "high": 12907.0701,
        "open": 12850.2123,
        "close": 12831.1798,
        "low": 12775.217
    },
    {
        "_id": "627deb0d46ab80109e36e2af",
        "ask": 16,
        "bid": 9,
        "high": 12975.0607,
        "open": 12875.5203,
        "close": 12835.6052,
        "low": 12705.6161
    },
    {
        "_id": "627deb0dad7a24350f404b3b",
        "ask": 9,
        "bid": 8,
        "high": 12978.6582,
        "open": 12888.0118,
        "close": 12834.2864,
        "low": 12746.1248
    },
    {
        "_id": "627deb0df3cd672fc3914192",
        "ask": 32,
        "bid": 54,
        "high": 12909.1665,
        "open": 12872.7437,
        "close": 12829.1801,
        "low": 12761.8863
    },
    {
        "_id": "627deb0df35497c60130e3ed",
        "ask": 29,
        "bid": 31,
        "high": 12994.8166,
        "open": 12867.5107,
        "close": 12849.078,
        "low": 12742.5597
    },
    {
        "_id": "627deb0d3d9fc36fc555b757",
        "ask": 26,
        "bid": 77,
        "high": 12944.9587,
        "open": 12885.7281,
        "close": 12828.7759,
        "low": 12733.0291
    },
    {
        "_id": "627deb0d6f23fcce7adb962c",
        "ask": 32,
        "bid": 91,
        "high": 12907.754,
        "open": 12889.8666,
        "close": 12821.8286,
        "low": 12795.1454
    },
    {
        "_id": "627deb0dcbc45bb53c1b2c2b",
        "ask": 22,
        "bid": 75,
        "high": 12920.7243,
        "open": 12874.9014,
        "close": 12824.8558,
        "low": 12787.8106
    },
    {
        "_id": "627deb0d7d2a490c97b0df55",
        "ask": 64,
        "bid": 13,
        "high": 12913.6695,
        "open": 12897.9144,
        "close": 12849.929,
        "low": 12723.2794
    },
    {
        "_id": "627deb0dbf82687b53808bad",
        "ask": 16,
        "bid": 86,
        "high": 12981.7428,
        "open": 12876.6772,
        "close": 12808.0738,
        "low": 12729.7143
    },
    {
        "_id": "627deb0d09b5edaf415b8d3b",
        "ask": 87,
        "bid": 0,
        "high": 12916.1349,
        "open": 12889.4089,
        "close": 12802.0742,
        "low": 12786.6085
    },
    {
        "_id": "627deb0d93b2027255ce1616",
        "ask": 80,
        "bid": 4,
        "high": 12906.7317,
        "open": 12896.6291,
        "close": 12808.905,
        "low": 12706.9038
    },
    {
        "_id": "627deb0d634fa6cd3e33bea0",
        "ask": 23,
        "bid": 88,
        "high": 12913.6322,
        "open": 12882.3835,
        "close": 12804.7969,
        "low": 12755.6752
    },
    {
        "_id": "627deb0d68e9abe253f4c509",
        "ask": 77,
        "bid": 56,
        "high": 12931.5551,
        "open": 12863.0434,
        "close": 12828.9281,
        "low": 12735.5647
    },
    {
        "_id": "627deb0d770cfbc86bc0abf9",
        "ask": 77,
        "bid": 53,
        "high": 12999.6859,
        "open": 12854.5591,
        "close": 12847.9347,
        "low": 12763.7764
    },
    {
        "_id": "627deb0d26e54e35c3a1be85",
        "ask": 39,
        "bid": 28,
        "high": 12985.3209,
        "open": 12871.622,
        "close": 12834.7053,
        "low": 12710.628
    },
    {
        "_id": "627deb0d177d1a41803a67a0",
        "ask": 27,
        "bid": 19,
        "high": 12949.2739,
        "open": 12863.9877,
        "close": 12820.9564,
        "low": 12771.5341
    },
    {
        "_id": "627deb0dc4c422b31955c00f",
        "ask": 1,
        "bid": 43,
        "high": 12973.3327,
        "open": 12881.3535,
        "close": 12817.8849,
        "low": 12732.7836
    },
    {
        "_id": "627deb0d37e305d6f509a0b4",
        "ask": 52,
        "bid": 14,
        "high": 12988.5519,
        "open": 12853.9256,
        "close": 12838.7384,
        "low": 12796.7175
    },
    {
        "_id": "627deb0d80aedf88c03ea827",
        "ask": 66,
        "bid": 73,
        "high": 12982.9053,
        "open": 12863.4881,
        "close": 12803.3545,
        "low": 12738.9056
    },
    {
        "_id": "627deb0d78921366897a2d5f",
        "ask": 15,
        "bid": 20,
        "high": 12961.4481,
        "open": 12896.3575,
        "close": 12845.0242,
        "low": 12713.4407
    },
    {
        "_id": "627deb0d46fb98d6dc114cb6",
        "ask": 99,
        "bid": 12,
        "high": 12935.9465,
        "open": 12887.0019,
        "close": 12823.9331,
        "low": 12743.1351
    },
    {
        "_id": "627deb0df34169aea3434d66",
        "ask": 26,
        "bid": 66,
        "high": 12980.0349,
        "open": 12878.4749,
        "close": 12802.2528,
        "low": 12720.0149
    },
    {
        "_id": "627deb0dd593f893a048ba77",
        "ask": 76,
        "bid": 28,
        "high": 12935.9777,
        "open": 12893.2482,
        "close": 12848.2982,
        "low": 12722.3696
    },
    {
        "_id": "627deb0d572f3cf333380000",
        "ask": 15,
        "bid": 52,
        "high": 12933.2768,
        "open": 12879.5073,
        "close": 12834.5447,
        "low": 12761.4461
    },
    {
        "_id": "627deb0dd8d2fe0b924745ce",
        "ask": 24,
        "bid": 37,
        "high": 12912.2196,
        "open": 12895.5802,
        "close": 12804.3456,
        "low": 12758.2333
    },
    {
        "_id": "627deb0d5da79170e892c3cd",
        "ask": 48,
        "bid": 51,
        "high": 12997.1481,
        "open": 12876.2766,
        "close": 12828.582,
        "low": 12740.414
    },
    {
        "_id": "627deb0d3cdb3572123227ea",
        "ask": 98,
        "bid": 36,
        "high": 12988.2159,
        "open": 12888.0886,
        "close": 12844.3905,
        "low": 12704.2378
    },
    {
        "_id": "627deb0dde14a90ca733933b",
        "ask": 74,
        "bid": 32,
        "high": 12971.657,
        "open": 12858.5411,
        "close": 12835.2599,
        "low": 12735.3913
    },
    {
        "_id": "627deb0d1bc954f8b95dbf86",
        "ask": 33,
        "bid": 15,
        "high": 12986.7389,
        "open": 12877.5326,
        "close": 12814.6775,
        "low": 12738.1922
    },
    {
        "_id": "627deb0d8be477da2d11d265",
        "ask": 4,
        "bid": 87,
        "high": 12949.3892,
        "open": 12894.205,
        "close": 12806.2527,
        "low": 12786.9551
    },
    {
        "_id": "627deb0dab37a41ca3558cd1",
        "ask": 94,
        "bid": 74,
        "high": 12915.4107,
        "open": 12855.9195,
        "close": 12842.4624,
        "low": 12737.453
    },
    {
        "_id": "627deb0d84b396f88e0c1cb9",
        "ask": 44,
        "bid": 19,
        "high": 12936.7024,
        "open": 12859.4742,
        "close": 12822.1471,
        "low": 12779.6257
    },
    {
        "_id": "627deb0d5fbd0b5d5e240fce",
        "ask": 71,
        "bid": 48,
        "high": 12914.4599,
        "open": 12861.767,
        "close": 12810.0596,
        "low": 12719.7704
    },
    {
        "_id": "627deb0d0c32c7a73c304150",
        "ask": 62,
        "bid": 26,
        "high": 12965.8487,
        "open": 12864.5549,
        "close": 12842.0256,
        "low": 12787.6443
    },
    {
        "_id": "627deb0d62545d57fbd67420",
        "ask": 9,
        "bid": 39,
        "high": 12996.5878,
        "open": 12861.3706,
        "close": 12806.7076,
        "low": 12796.0015
    },
    {
        "_id": "627deb0d527a1bb8272f5351",
        "ask": 84,
        "bid": 37,
        "high": 12990.1537,
        "open": 12871.1095,
        "close": 12801.9968,
        "low": 12763.1533
    },
    {
        "_id": "627deb0d7dab23ea7d68d749",
        "ask": 42,
        "bid": 98,
        "high": 12943.4595,
        "open": 12882.6052,
        "close": 12836.5408,
        "low": 12748.7836
    },
    {
        "_id": "627deb0daeba73739bf6dc15",
        "ask": 3,
        "bid": 61,
        "high": 12978.5693,
        "open": 12881.19,
        "close": 12812.1092,
        "low": 12751.979
    },
    {
        "_id": "627deb0d7cdc814bf5541601",
        "ask": 94,
        "bid": 81,
        "high": 12910.9373,
        "open": 12854.935,
        "close": 12803.4101,
        "low": 12754.9479
    },
    {
        "_id": "627deb0dc8c17a1d3daee759",
        "ask": 29,
        "bid": 77,
        "high": 12982.3374,
        "open": 12883.2766,
        "close": 12805.0201,
        "low": 12760.391
    },
    {
        "_id": "627deb0dd14adec259932aa8",
        "ask": 95,
        "bid": 31,
        "high": 12974.6049,
        "open": 12894.5752,
        "close": 12808.0272,
        "low": 12742.0901
    },
    {
        "_id": "627deb0d188b7fd553c861dc",
        "ask": 60,
        "bid": 68,
        "high": 12942.7301,
        "open": 12866.6834,
        "close": 12800.0989,
        "low": 12772.2775
    },
    {
        "_id": "627deb0dd77eeb923e350c6e",
        "ask": 11,
        "bid": 3,
        "high": 12942.1576,
        "open": 12891.2617,
        "close": 12802.6875,
        "low": 12722.7295
    },
    {
        "_id": "627deb0d30aa3d49779764e1",
        "ask": 35,
        "bid": 2,
        "high": 12966.9503,
        "open": 12882.9275,
        "close": 12834.9131,
        "low": 12772.9318
    },
    {
        "_id": "627deb0d890b3e206ae78575",
        "ask": 58,
        "bid": 83,
        "high": 12953.3475,
        "open": 12875.4187,
        "close": 12803.9263,
        "low": 12747.3703
    },
    {
        "_id": "627deb0d199f507e69832b1e",
        "ask": 47,
        "bid": 97,
        "high": 12953.207,
        "open": 12895.0751,
        "close": 12801.7856,
        "low": 12794.7316
    },
    {
        "_id": "627deb0d94edb434d45f52a3",
        "ask": 17,
        "bid": 75,
        "high": 12956.5427,
        "open": 12898.5327,
        "close": 12839.2898,
        "low": 12799.8417
    },
    {
        "_id": "627deb0dddfe49ee3ac7651a",
        "ask": 74,
        "bid": 27,
        "high": 12944.2861,
        "open": 12890.2041,
        "close": 12820.934,
        "low": 12706.584
    },
    {
        "_id": "627deb0dc41ec0199941644a",
        "ask": 82,
        "bid": 25,
        "high": 12990.1241,
        "open": 12883.843,
        "close": 12815.7376,
        "low": 12747.7
    },
    {
        "_id": "627deb0d41d77f4be6dca12f",
        "ask": 43,
        "bid": 67,
        "high": 12996.8008,
        "open": 12857.1842,
        "close": 12814.6151,
        "low": 12710.4133
    },
    {
        "_id": "627deb0d274257f320c7ae97",
        "ask": 62,
        "bid": 56,
        "high": 12946.1846,
        "open": 12898.124,
        "close": 12845.1243,
        "low": 12737.9747
    },
    {
        "_id": "627deb0d2677c57a61f7b496",
        "ask": 58,
        "bid": 40,
        "high": 12915.4292,
        "open": 12854.0707,
        "close": 12809.0923,
        "low": 12725.8057
    },
    {
        "_id": "627deb0d8856a0e32bc7323a",
        "ask": 39,
        "bid": 84,
        "high": 12974.7113,
        "open": 12856.4695,
        "close": 12837.0219,
        "low": 12755.1352
    },
    {
        "_id": "627deb0d3580b9c2481b8fc5",
        "ask": 83,
        "bid": 78,
        "high": 12911.2393,
        "open": 12869.8371,
        "close": 12820.5694,
        "low": 12711.1827
    },
    {
        "_id": "627deb0d8a3656c7112933de",
        "ask": 12,
        "bid": 35,
        "high": 12961.708,
        "open": 12891.8591,
        "close": 12810.3597,
        "low": 12757.9997
    },
    {
        "_id": "627deb0d58c583c41eb40e49",
        "ask": 29,
        "bid": 58,
        "high": 12933.7413,
        "open": 12865.4663,
        "close": 12830.9649,
        "low": 12789.8351
    },
    {
        "_id": "627deb0d1e67c0a080ff768f",
        "ask": 100,
        "bid": 100,
        "high": 12918.6827,
        "open": 12893.355,
        "close": 12820.0943,
        "low": 12773.9925
    },
    {
        "_id": "627deb0d37812831bc8209ef",
        "ask": 22,
        "bid": 75,
        "high": 12963.8323,
        "open": 12860.0102,
        "close": 12836.5629,
        "low": 12718.8194
    },
    {
        "_id": "627deb0d8adb14c82aa42b3d",
        "ask": 68,
        "bid": 99,
        "high": 12953.8907,
        "open": 12873.5661,
        "close": 12834.9532,
        "low": 12792.0049
    },
    {
        "_id": "627deb0d82a52064e5175267",
        "ask": 76,
        "bid": 4,
        "high": 12966.5134,
        "open": 12873.8771,
        "close": 12840.6779,
        "low": 12724.5145
    },
    {
        "_id": "627deb0d8a3fbfe9e7692c9f",
        "ask": 50,
        "bid": 3,
        "high": 12972.8992,
        "open": 12890.3518,
        "close": 12810.0696,
        "low": 12724.4147
    },
    {
        "_id": "627deb0da3980987ef76330b",
        "ask": 27,
        "bid": 70,
        "high": 12909.198,
        "open": 12894.0311,
        "close": 12827.4458,
        "low": 12745.5318
    },
    {
        "_id": "627deb0d4f8ca450b0087f59",
        "ask": 18,
        "bid": 3,
        "high": 12992.0262,
        "open": 12867.6854,
        "close": 12846.12,
        "low": 12761.1975
    },
    {
        "_id": "627deb0d0c120a96c43fe545",
        "ask": 12,
        "bid": 79,
        "high": 12912.223,
        "open": 12858.1198,
        "close": 12847.6067,
        "low": 12708.2286
    },
    {
        "_id": "627deb0d4cb1b9e163063f4b",
        "ask": 77,
        "bid": 57,
        "high": 12961.4963,
        "open": 12869.906,
        "close": 12841.9596,
        "low": 12752.7043
    },
    {
        "_id": "627deb0d74db1759d4a667b1",
        "ask": 56,
        "bid": 99,
        "high": 12915.6949,
        "open": 12880.6885,
        "close": 12811.0466,
        "low": 12708.919
    },
    {
        "_id": "627deb0d0b8b2b1c3d30b753",
        "ask": 89,
        "bid": 36,
        "high": 12939.8023,
        "open": 12877.7358,
        "close": 12817.4468,
        "low": 12765.7549
    },
    {
        "_id": "627deb0df4c9c18633384206",
        "ask": 81,
        "bid": 14,
        "high": 12982.8439,
        "open": 12894.319,
        "close": 12840.5,
        "low": 12748.6644
    },
    {
        "_id": "627deb0d93bec606630e39e3",
        "ask": 36,
        "bid": 84,
        "high": 12981.6893,
        "open": 12858.1416,
        "close": 12817.5551,
        "low": 12770.8714
    },
    {
        "_id": "627deb0d009918aa57348b6c",
        "ask": 58,
        "bid": 22,
        "high": 12973.6844,
        "open": 12856.852,
        "close": 12820.8488,
        "low": 12726.7948
    },
    {
        "_id": "627deb0d43ff6e5620c2c6e4",
        "ask": 84,
        "bid": 22,
        "high": 12955.8907,
        "open": 12886.3649,
        "close": 12805.0311,
        "low": 12750.0059
    },
    {
        "_id": "627deb0d0c96295d67fa0685",
        "ask": 30,
        "bid": 87,
        "high": 12997.2464,
        "open": 12876.3377,
        "close": 12836.1443,
        "low": 12726.0041
    },
    {
        "_id": "627deb0dcba572a2ad788adb",
        "ask": 42,
        "bid": 49,
        "high": 12994.0288,
        "open": 12879.1282,
        "close": 12806.8838,
        "low": 12770.9451
    },
    {
        "_id": "627deb0d9a8ec80b55eac7f1",
        "ask": 100,
        "bid": 14,
        "high": 12923.8638,
        "open": 12859.1867,
        "close": 12835.0354,
        "low": 12794.918
    },
    {
        "_id": "627deb0dba7cc79a39c06544",
        "ask": 26,
        "bid": 19,
        "high": 12984.0217,
        "open": 12870.5167,
        "close": 12839.2588,
        "low": 12735.2872
    },
    {
        "_id": "627deb0d7ca817a495b11eac",
        "ask": 16,
        "bid": 94,
        "high": 12975.4745,
        "open": 12853.9987,
        "close": 12807.4087,
        "low": 12733.5387
    },
    {
        "_id": "627deb0d75944e759df85cad",
        "ask": 61,
        "bid": 11,
        "high": 12996.88,
        "open": 12869.781,
        "close": 12846.7332,
        "low": 12745.8417
    },
    {
        "_id": "627deb0d3cbc394edbc0e5c4",
        "ask": 73,
        "bid": 15,
        "high": 12968.7915,
        "open": 12864.5381,
        "close": 12809.9862,
        "low": 12780.7694
    },
    {
        "_id": "627deb0dfe69c5d6142de75d",
        "ask": 78,
        "bid": 40,
        "high": 12955.4311,
        "open": 12878.8225,
        "close": 12817.7266,
        "low": 12794.6012
    },
    {
        "_id": "627deb0d84212528337fff0f",
        "ask": 2,
        "bid": 4,
        "high": 12934.3187,
        "open": 12853.3201,
        "close": 12809.3068,
        "low": 12730.385
    },
    {
        "_id": "627deb0d0e896f8eb8e03670",
        "ask": 31,
        "bid": 75,
        "high": 12916.6015,
        "open": 12856.8479,
        "close": 12801.9523,
        "low": 12764.8652
    },
    {
        "_id": "627deb0d8636f58f3331bdf8",
        "ask": 29,
        "bid": 41,
        "high": 12992.1603,
        "open": 12890.7522,
        "close": 12834.1756,
        "low": 12743.9398
    },
    {
        "_id": "627deb0d6933a42c6edcbfb7",
        "ask": 71,
        "bid": 23,
        "high": 12947.3379,
        "open": 12892.6944,
        "close": 12838.5703,
        "low": 12723.5218
    },
    {
        "_id": "627deb0d550c6a0832347f89",
        "ask": 42,
        "bid": 48,
        "high": 12916.4102,
        "open": 12861.6579,
        "close": 12840.393,
        "low": 12724.9854
    },
    {
        "_id": "627deb0df818082b5bea8433",
        "ask": 81,
        "bid": 81,
        "high": 12982.4777,
        "open": 12853.1852,
        "close": 12811.8219,
        "low": 12727.4257
    },
    {
        "_id": "627deb0de38f2849b959d2e2",
        "ask": 100,
        "bid": 66,
        "high": 12972.073,
        "open": 12861.6855,
        "close": 12805.9202,
        "low": 12710.0881
    },
    {
        "_id": "627deb0d719308738e129787",
        "ask": 24,
        "bid": 21,
        "high": 12995.0764,
        "open": 12887.0672,
        "close": 12802.5507,
        "low": 12716.1774
    },
    {
        "_id": "627deb0d8ceb7897e10b372c",
        "ask": 94,
        "bid": 99,
        "high": 12959.7545,
        "open": 12852.8321,
        "close": 12807.5188,
        "low": 12771.8094
    },
    {
        "_id": "627deb0dc0f95050570e8b73",
        "ask": 23,
        "bid": 36,
        "high": 12972.6015,
        "open": 12882.4144,
        "close": 12841.6656,
        "low": 12798.5092
    },
    {
        "_id": "627deb0d0579eb12195869e4",
        "ask": 28,
        "bid": 5,
        "high": 12985.523,
        "open": 12887.143,
        "close": 12814.78,
        "low": 12788.1648
    },
    {
        "_id": "627deb0d2102a5a9d223d5be",
        "ask": 99,
        "bid": 88,
        "high": 12926.8847,
        "open": 12859.1102,
        "close": 12828.4757,
        "low": 12769.4726
    },
    {
        "_id": "627deb0d8447aeb51bd87259",
        "ask": 95,
        "bid": 71,
        "high": 12979.3864,
        "open": 12869.5582,
        "close": 12825.1525,
        "low": 12728.5886
    },
    {
        "_id": "627deb0d791d20246ecdd6ab",
        "ask": 44,
        "bid": 98,
        "high": 12988.3248,
        "open": 12853.0928,
        "close": 12840.3245,
        "low": 12728.4946
    },
    {
        "_id": "627deb0d2b833143caa1fc71",
        "ask": 34,
        "bid": 38,
        "high": 12969.7631,
        "open": 12856.1573,
        "close": 12809.986,
        "low": 12732.5884
    },
    {
        "_id": "627deb0d1bead3940e5a8f49",
        "ask": 21,
        "bid": 1,
        "high": 12985.943,
        "open": 12878.7415,
        "close": 12849.7126,
        "low": 12758.5061
    },
    {
        "_id": "627deb0d0dd4813b9a075f96",
        "ask": 58,
        "bid": 0,
        "high": 12945.5868,
        "open": 12850.7203,
        "close": 12849.5158,
        "low": 12755.0998
    },
    {
        "_id": "627deb0d2a4763306815e6ee",
        "ask": 28,
        "bid": 12,
        "high": 12900.2643,
        "open": 12874.475,
        "close": 12843.0557,
        "low": 12711.9505
    },
    {
        "_id": "627deb0d9087d071848f19c2",
        "ask": 93,
        "bid": 38,
        "high": 12937.817,
        "open": 12874.7874,
        "close": 12824.2754,
        "low": 12732.3511
    },
    {
        "_id": "627deb0d7e177d88bd86cae9",
        "ask": 51,
        "bid": 69,
        "high": 12995.7799,
        "open": 12874.0216,
        "close": 12841.555,
        "low": 12791.7533
    },
    {
        "_id": "627deb0d67e308e0b65a7b24",
        "ask": 0,
        "bid": 72,
        "high": 12977.8547,
        "open": 12887.898,
        "close": 12834.5878,
        "low": 12739.9414
    },
    {
        "_id": "627deb0dca2b45cc6a7b5f62",
        "ask": 12,
        "bid": 97,
        "high": 12912.0366,
        "open": 12868.7892,
        "close": 12804.0628,
        "low": 12757.2508
    },
    {
        "_id": "627deb0d0c980621b9242f47",
        "ask": 63,
        "bid": 15,
        "high": 12913.1748,
        "open": 12863.8134,
        "close": 12818.4674,
        "low": 12781.9298
    },
    {
        "_id": "627deb0d18ff22a7f0afe5d6",
        "ask": 82,
        "bid": 41,
        "high": 12925.847,
        "open": 12896.7219,
        "close": 12821.3364,
        "low": 12740.243
    },
    {
        "_id": "627deb0dd405623bbb352b47",
        "ask": 67,
        "bid": 69,
        "high": 12957.9557,
        "open": 12861.2043,
        "close": 12816.9332,
        "low": 12711.9476
    },
    {
        "_id": "627deb0d376d070660bf3a82",
        "ask": 34,
        "bid": 22,
        "high": 12922.0935,
        "open": 12893.0235,
        "close": 12838.2354,
        "low": 12737.2694
    },
    {
        "_id": "627deb0dcae375dbcd9a40d5",
        "ask": 12,
        "bid": 44,
        "high": 12908.2065,
        "open": 12865.8788,
        "close": 12839.6911,
        "low": 12783.0195
    },
    {
        "_id": "627deb0d4dc39c11ab36973c",
        "ask": 8,
        "bid": 34,
        "high": 12946.6586,
        "open": 12855.0447,
        "close": 12824.186,
        "low": 12721.8683
    },
    {
        "_id": "627deb0dd836e4f7b1746b61",
        "ask": 86,
        "bid": 74,
        "high": 12985.2936,
        "open": 12853.7075,
        "close": 12839.7874,
        "low": 12795.1806
    },
    {
        "_id": "627deb0d22507b79c7279a45",
        "ask": 57,
        "bid": 91,
        "high": 12903.8478,
        "open": 12885.7768,
        "close": 12835.6522,
        "low": 12745.2424
    },
    {
        "_id": "627deb0d2bd98d18261f0f47",
        "ask": 38,
        "bid": 84,
        "high": 12969.3931,
        "open": 12893.5919,
        "close": 12842.0547,
        "low": 12724.8697
    },
    {
        "_id": "627deb0d1bdbc3b72467ccb1",
        "ask": 91,
        "bid": 49,
        "high": 12993.6304,
        "open": 12886.662,
        "close": 12816.903,
        "low": 12798.3187
    },
    {
        "_id": "627deb0d32639c3e557e5f17",
        "ask": 92,
        "bid": 90,
        "high": 12970.738,
        "open": 12888.3117,
        "close": 12818.6333,
        "low": 12718.6413
    },
    {
        "_id": "627deb0d6cff0d7b6d97fee0",
        "ask": 89,
        "bid": 49,
        "high": 12949.1679,
        "open": 12898.999,
        "close": 12825.55,
        "low": 12709.8751
    },
    {
        "_id": "627deb0d8515f6fcaf847dc3",
        "ask": 56,
        "bid": 6,
        "high": 12958.1882,
        "open": 12865.8294,
        "close": 12845.5806,
        "low": 12788.9043
    },
    {
        "_id": "627deb0dcbfa82efc107cf7e",
        "ask": 81,
        "bid": 30,
        "high": 12967.1527,
        "open": 12899.2452,
        "close": 12824.5554,
        "low": 12729.3556
    },
    {
        "_id": "627deb0d166a13387e3435d0",
        "ask": 44,
        "bid": 22,
        "high": 12985.8753,
        "open": 12890.7526,
        "close": 12805.4966,
        "low": 12771.0009
    },
    {
        "_id": "627deb0da78e0934baa4fca3",
        "ask": 84,
        "bid": 87,
        "high": 12963.4003,
        "open": 12853.2958,
        "close": 12811.3145,
        "low": 12702.933
    },
    {
        "_id": "627deb0d0f3f9becf76551b5",
        "ask": 7,
        "bid": 76,
        "high": 12905.7831,
        "open": 12894.9684,
        "close": 12828.9373,
        "low": 12737.0368
    },
    {
        "_id": "627deb0db0f052dfce55b488",
        "ask": 12,
        "bid": 86,
        "high": 12944.4201,
        "open": 12858.6118,
        "close": 12836.8608,
        "low": 12772.478
    },
    {
        "_id": "627deb0d1e222250a458c8ed",
        "ask": 52,
        "bid": 75,
        "high": 12989.17,
        "open": 12874.7767,
        "close": 12849.9193,
        "low": 12715.3741
    },
    {
        "_id": "627deb0d656af0bc1bd6b107",
        "ask": 56,
        "bid": 43,
        "high": 12991.1015,
        "open": 12885.119,
        "close": 12820.9254,
        "low": 12750.9706
    },
    {
        "_id": "627deb0d544abf2ae98a29a6",
        "ask": 52,
        "bid": 66,
        "high": 12949.6179,
        "open": 12893.7211,
        "close": 12836.2256,
        "low": 12797.5489
    },
    {
        "_id": "627deb0d690cf2ab2befa240",
        "ask": 36,
        "bid": 56,
        "high": 12956.7255,
        "open": 12898.4899,
        "close": 12819.0035,
        "low": 12712.5197
    },
    {
        "_id": "627deb0d89be0b60cfad41d0",
        "ask": 8,
        "bid": 76,
        "high": 12995.2486,
        "open": 12883.6685,
        "close": 12835.9132,
        "low": 12787.9097
    },
    {
        "_id": "627deb0d46483f98b01b5880",
        "ask": 17,
        "bid": 97,
        "high": 12981.7192,
        "open": 12887.1109,
        "close": 12823.1009,
        "low": 12789.3937
    },
    {
        "_id": "627deb0d50d24441afe6ff72",
        "ask": 17,
        "bid": 18,
        "high": 12907.8132,
        "open": 12850.7992,
        "close": 12825.818,
        "low": 12792.5298
    },
    {
        "_id": "627deb0d60fabb3200ed158e",
        "ask": 9,
        "bid": 35,
        "high": 12993.6003,
        "open": 12883.1655,
        "close": 12820.9417,
        "low": 12723.3726
    },
    {
        "_id": "627deb0d32c576e4dee040ef",
        "ask": 6,
        "bid": 13,
        "high": 12989.9476,
        "open": 12884.6479,
        "close": 12809.4053,
        "low": 12705.3485
    },
    {
        "_id": "627deb0d9af6015545352235",
        "ask": 61,
        "bid": 28,
        "high": 12981.0957,
        "open": 12889.1008,
        "close": 12802.3989,
        "low": 12727.7256
    },
    {
        "_id": "627deb0d43908d612a9d6c07",
        "ask": 34,
        "bid": 91,
        "high": 12982.2615,
        "open": 12888.8612,
        "close": 12836.8478,
        "low": 12782.0279
    },
    {
        "_id": "627deb0dd9b6266b7f657840",
        "ask": 37,
        "bid": 74,
        "high": 12961.5785,
        "open": 12880.9846,
        "close": 12806.0224,
        "low": 12794.5708
    },
    {
        "_id": "627deb0df8f63e3130c6daf1",
        "ask": 23,
        "bid": 79,
        "high": 12953.2662,
        "open": 12856.3398,
        "close": 12810.8665,
        "low": 12762.1932
    },
    {
        "_id": "627deb0db55303d40cd12b4b",
        "ask": 50,
        "bid": 79,
        "high": 12998.9677,
        "open": 12871.8013,
        "close": 12804.9828,
        "low": 12756.2169
    },
    {
        "_id": "627deb0df79cca15860ce2e6",
        "ask": 100,
        "bid": 69,
        "high": 12908.0724,
        "open": 12869.8815,
        "close": 12842.8034,
        "low": 12704.4457
    },
    {
        "_id": "627deb0daa15c6d3e00a343d",
        "ask": 4,
        "bid": 60,
        "high": 12910.0852,
        "open": 12889.9102,
        "close": 12812.8398,
        "low": 12735.4716
    },
    {
        "_id": "627deb0d4421b4c3e5000087",
        "ask": 78,
        "bid": 48,
        "high": 12930.8245,
        "open": 12897.3019,
        "close": 12812.8971,
        "low": 12756.4006
    },
    {
        "_id": "627deb0db4114404cfcdd64b",
        "ask": 57,
        "bid": 57,
        "high": 12909.2808,
        "open": 12892.3422,
        "close": 12815.9678,
        "low": 12731.2875
    },
    {
        "_id": "627deb0d1eb19e0e3531d5d0",
        "ask": 46,
        "bid": 0,
        "high": 12903.2341,
        "open": 12897.9756,
        "close": 12804.9049,
        "low": 12735.7889
    },
    {
        "_id": "627deb0d58cc4642f95d6032",
        "ask": 69,
        "bid": 62,
        "high": 12965.9368,
        "open": 12898.6781,
        "close": 12814.8615,
        "low": 12739.6685
    },
    {
        "_id": "627deb0d644aba7dc8844582",
        "ask": 22,
        "bid": 2,
        "high": 12901.3348,
        "open": 12879.5233,
        "close": 12801.5454,
        "low": 12720.3999
    },
    {
        "_id": "627deb0d9cf5fa96897821de",
        "ask": 82,
        "bid": 12,
        "high": 12904.6524,
        "open": 12874.4608,
        "close": 12843.4795,
        "low": 12728.5505
    },
    {
        "_id": "627deb0d574687cbe6c1a565",
        "ask": 26,
        "bid": 47,
        "high": 12963.2648,
        "open": 12860.8177,
        "close": 12802.3109,
        "low": 12769.7956
    },
    {
        "_id": "627deb0dda2056379ed98df3",
        "ask": 12,
        "bid": 35,
        "high": 12988.8389,
        "open": 12889.9959,
        "close": 12841.2751,
        "low": 12701.5648
    },
    {
        "_id": "627deb0d272e82bda3dcf4d0",
        "ask": 77,
        "bid": 94,
        "high": 12962.9369,
        "open": 12855.575,
        "close": 12803.2944,
        "low": 12706.0396
    },
    {
        "_id": "627deb0dac1c4886218e7047",
        "ask": 92,
        "bid": 73,
        "high": 12995.3094,
        "open": 12887.3798,
        "close": 12815.7164,
        "low": 12750.9981
    },
    {
        "_id": "627deb0da629369247605984",
        "ask": 38,
        "bid": 0,
        "high": 12971.8046,
        "open": 12860.9374,
        "close": 12813.2706,
        "low": 12759.5758
    },
    {
        "_id": "627deb0d9f520a356cc965d6",
        "ask": 32,
        "bid": 32,
        "high": 12939.3426,
        "open": 12886.0062,
        "close": 12809.4304,
        "low": 12784.8317
    },
    {
        "_id": "627deb0dba8dfd23401fd896",
        "ask": 0,
        "bid": 92,
        "high": 12943.0954,
        "open": 12858.5442,
        "close": 12821.6027,
        "low": 12796.644
    },
    {
        "_id": "627deb0d59469de3b5ea9d48",
        "ask": 43,
        "bid": 84,
        "high": 12919.4762,
        "open": 12897.9609,
        "close": 12825.1829,
        "low": 12710.9775
    },
    {
        "_id": "627deb0d6dffb5895a1885ef",
        "ask": 44,
        "bid": 42,
        "high": 12909.4188,
        "open": 12887.895,
        "close": 12836.9989,
        "low": 12751.7624
    },
    {
        "_id": "627deb0db8f87c8da9260e1e",
        "ask": 20,
        "bid": 78,
        "high": 12990.2165,
        "open": 12880.2912,
        "close": 12825.084,
        "low": 12728.0347
    },
    {
        "_id": "627deb0dd44e90902326bde6",
        "ask": 22,
        "bid": 0,
        "high": 12900.9035,
        "open": 12874.5888,
        "close": 12819.6154,
        "low": 12704.871
    },
    {
        "_id": "627deb0da142c41708d19f87",
        "ask": 5,
        "bid": 96,
        "high": 12985.3362,
        "open": 12864.1683,
        "close": 12828.8295,
        "low": 12731.5374
    },
    {
        "_id": "627deb0dbbcb426d6ce9f66a",
        "ask": 18,
        "bid": 29,
        "high": 12904.664,
        "open": 12870.6371,
        "close": 12835.9474,
        "low": 12764.1845
    },
    {
        "_id": "627deb0d1ff8fc13f79c8497",
        "ask": 29,
        "bid": 12,
        "high": 12988.2408,
        "open": 12852.52,
        "close": 12813.135,
        "low": 12758.545
    },
    {
        "_id": "627deb0dfbec3b80caa62843",
        "ask": 60,
        "bid": 99,
        "high": 12925.2685,
        "open": 12854.6721,
        "close": 12848.4505,
        "low": 12776.1285
    },
    {
        "_id": "627deb0debc5208f2f6d42c0",
        "ask": 88,
        "bid": 19,
        "high": 12921.6604,
        "open": 12868.7303,
        "close": 12800.37,
        "low": 12764.5989
    },
    {
        "_id": "627deb0daf5b84a2b61a0956",
        "ask": 41,
        "bid": 91,
        "high": 12921.9331,
        "open": 12869.1866,
        "close": 12834.1667,
        "low": 12791.9551
    },
    {
        "_id": "627deb0d8214bb08a9ad6d7d",
        "ask": 31,
        "bid": 41,
        "high": 12911.7652,
        "open": 12895.392,
        "close": 12818.7457,
        "low": 12716.0039
    },
    {
        "_id": "627deb0d69da5525706a5b46",
        "ask": 67,
        "bid": 91,
        "high": 12956.6951,
        "open": 12854.2675,
        "close": 12843.5142,
        "low": 12715.4335
    },
    {
        "_id": "627deb0d29714933ca74850c",
        "ask": 32,
        "bid": 85,
        "high": 12917.0715,
        "open": 12856.1048,
        "close": 12847.9852,
        "low": 12791.9125
    },
    {
        "_id": "627deb0d6c2dacdc61171704",
        "ask": 6,
        "bid": 67,
        "high": 12958.6932,
        "open": 12884.4613,
        "close": 12831.3772,
        "low": 12732.3284
    },
    {
        "_id": "627deb0dcda109e16af12f6f",
        "ask": 85,
        "bid": 70,
        "high": 12948.8084,
        "open": 12867.785,
        "close": 12807.2055,
        "low": 12703.8627
    },
    {
        "_id": "627deb0d4dac3bae343a296b",
        "ask": 77,
        "bid": 93,
        "high": 12931.3055,
        "open": 12892.8164,
        "close": 12849.5189,
        "low": 12751.7384
    },
    {
        "_id": "627deb0d5561441727dc9cc5",
        "ask": 39,
        "bid": 94,
        "high": 12937.7183,
        "open": 12882.6093,
        "close": 12809.6816,
        "low": 12795.4614
    },
    {
        "_id": "627deb0d3b2938f916a8d2a1",
        "ask": 44,
        "bid": 28,
        "high": 12995.1672,
        "open": 12863.0264,
        "close": 12816.6991,
        "low": 12701.3951
    },
    {
        "_id": "627deb0d54e8c2eb168065ad",
        "ask": 38,
        "bid": 69,
        "high": 12977.5191,
        "open": 12860.2162,
        "close": 12829.0581,
        "low": 12794.2993
    },
    {
        "_id": "627deb0d19889407ddf7838d",
        "ask": 78,
        "bid": 52,
        "high": 12905.5837,
        "open": 12854.2038,
        "close": 12811.8298,
        "low": 12757.3387
    },
    {
        "_id": "627deb0dd23c25962033441e",
        "ask": 6,
        "bid": 2,
        "high": 12941.0069,
        "open": 12861.8426,
        "close": 12831.4845,
        "low": 12759.8073
    },
    {
        "_id": "627deb0d4f4fdd4085c6e186",
        "ask": 96,
        "bid": 47,
        "high": 12947.0216,
        "open": 12893.0055,
        "close": 12815.0877,
        "low": 12710.2389
    },
    {
        "_id": "627deb0d815c65e93162f05c",
        "ask": 14,
        "bid": 97,
        "high": 12951.1529,
        "open": 12897.6117,
        "close": 12827.9679,
        "low": 12703.7879
    },
    {
        "_id": "627deb0d867a6a533e59f3ef",
        "ask": 7,
        "bid": 67,
        "high": 12940.8315,
        "open": 12870.4827,
        "close": 12815.7157,
        "low": 12780.3865
    },
    {
        "_id": "627deb0d36dddb25d2d3f00d",
        "ask": 68,
        "bid": 99,
        "high": 12907.2766,
        "open": 12889.9063,
        "close": 12803.9918,
        "low": 12740.5138
    },
    {
        "_id": "627deb0d3f36a66b153e0774",
        "ask": 12,
        "bid": 20,
        "high": 12969.0274,
        "open": 12880.6857,
        "close": 12820.8138,
        "low": 12760.6978
    },
    {
        "_id": "627deb0ddda873292b9299c5",
        "ask": 30,
        "bid": 34,
        "high": 12905.8753,
        "open": 12890.8561,
        "close": 12849.8028,
        "low": 12714.7485
    },
    {
        "_id": "627deb0da5c7a4c0321052a0",
        "ask": 37,
        "bid": 14,
        "high": 12905.7553,
        "open": 12851.0912,
        "close": 12831.1138,
        "low": 12711.2457
    },
    {
        "_id": "627deb0dec79867f48a57213",
        "ask": 63,
        "bid": 49,
        "high": 12906.155,
        "open": 12852.0861,
        "close": 12810.0189,
        "low": 12781.5197
    },
    {
        "_id": "627deb0d4444447b667bcdcc",
        "ask": 74,
        "bid": 60,
        "high": 12933.4013,
        "open": 12861.0089,
        "close": 12821.3835,
        "low": 12705.2537
    },
    {
        "_id": "627deb0db2e12f131511b7bf",
        "ask": 57,
        "bid": 46,
        "high": 12932.0002,
        "open": 12891.3163,
        "close": 12808.4767,
        "low": 12706.1472
    },
    {
        "_id": "627deb0da130c57c35d04ede",
        "ask": 39,
        "bid": 5,
        "high": 12938.073,
        "open": 12869.0734,
        "close": 12836.0865,
        "low": 12711.8878
    },
    {
        "_id": "627deb0dba07371052eca235",
        "ask": 42,
        "bid": 34,
        "high": 12997.5371,
        "open": 12882.8125,
        "close": 12832.8555,
        "low": 12759.2035
    },
    {
        "_id": "627deb0da3d8335e520a7d22",
        "ask": 86,
        "bid": 14,
        "high": 12909.9753,
        "open": 12895.431,
        "close": 12825.7364,
        "low": 12796.8806
    },
    {
        "_id": "627deb0de454037c3f0d9826",
        "ask": 47,
        "bid": 97,
        "high": 12959.5978,
        "open": 12881.9727,
        "close": 12844.1131,
        "low": 12728.0148
    },
    {
        "_id": "627deb0dd1547b858746a64b",
        "ask": 0,
        "bid": 20,
        "high": 12980.5021,
        "open": 12882.3064,
        "close": 12823.0183,
        "low": 12712.6894
    },
    {
        "_id": "627deb0d79d764b0fd5545e7",
        "ask": 94,
        "bid": 57,
        "high": 12977.3043,
        "open": 12880.7628,
        "close": 12839.9072,
        "low": 12725.3403
    },
    {
        "_id": "627deb0dfa782b345f8eafe5",
        "ask": 61,
        "bid": 54,
        "high": 12913.3602,
        "open": 12889.004,
        "close": 12802.5364,
        "low": 12758.2586
    },
    {
        "_id": "627deb0dc854a850f2bae733",
        "ask": 54,
        "bid": 76,
        "high": 12909.1898,
        "open": 12858.5184,
        "close": 12815.2908,
        "low": 12744.8209
    },
    {
        "_id": "627deb0d4b72b11a41b1e623",
        "ask": 47,
        "bid": 13,
        "high": 12946.6266,
        "open": 12859.8122,
        "close": 12829.4411,
        "low": 12746.1381
    },
    {
        "_id": "627deb0d1fb8a45367dacea6",
        "ask": 66,
        "bid": 88,
        "high": 12955.4757,
        "open": 12873.0478,
        "close": 12806.2248,
        "low": 12756.8596
    },
    {
        "_id": "627deb0d3d9714b5919664f9",
        "ask": 64,
        "bid": 20,
        "high": 12939.5278,
        "open": 12851.1508,
        "close": 12812.1807,
        "low": 12729.2073
    },
    {
        "_id": "627deb0d2ac3a993c3ddc64e",
        "ask": 58,
        "bid": 81,
        "high": 12948.867,
        "open": 12854.8765,
        "close": 12833.3102,
        "low": 12702.0335
    },
    {
        "_id": "627deb0d6c811e20b85388a8",
        "ask": 8,
        "bid": 26,
        "high": 12931.0985,
        "open": 12861.9821,
        "close": 12843.9403,
        "low": 12734.4342
    },
    {
        "_id": "627deb0d8772aa7444e95284",
        "ask": 19,
        "bid": 38,
        "high": 12977.6832,
        "open": 12857.4184,
        "close": 12847.8681,
        "low": 12704.5177
    },
    {
        "_id": "627deb0d10b77a2cf9feb9b0",
        "ask": 45,
        "bid": 74,
        "high": 12924.7754,
        "open": 12853.8095,
        "close": 12836.6472,
        "low": 12787.0434
    },
    {
        "_id": "627deb0d5b0c544d25330a04",
        "ask": 83,
        "bid": 89,
        "high": 12918.7827,
        "open": 12877.1553,
        "close": 12848.6506,
        "low": 12709.1558
    },
    {
        "_id": "627deb0d0298afc4b67cc783",
        "ask": 3,
        "bid": 2,
        "high": 12970.7116,
        "open": 12894.6464,
        "close": 12840.1025,
        "low": 12710.3733
    },
    {
        "_id": "627deb0d575b2647396c4791",
        "ask": 95,
        "bid": 23,
        "high": 12976.927,
        "open": 12867.8707,
        "close": 12823.9067,
        "low": 12774.1001
    },
    {
        "_id": "627deb0d592f78cfd4f0cff7",
        "ask": 92,
        "bid": 0,
        "high": 12928.6778,
        "open": 12880.0951,
        "close": 12800.1007,
        "low": 12794.1061
    },
    {
        "_id": "627deb0d346bd6e56be45da3",
        "ask": 69,
        "bid": 65,
        "high": 12969.1771,
        "open": 12861.4019,
        "close": 12813.8335,
        "low": 12775.6479
    },
    {
        "_id": "627deb0d6cf58c394a1d8491",
        "ask": 6,
        "bid": 14,
        "high": 12938.2412,
        "open": 12883.6398,
        "close": 12818.4786,
        "low": 12715.4036
    },
    {
        "_id": "627deb0d6710857992df7018",
        "ask": 84,
        "bid": 74,
        "high": 12984.4397,
        "open": 12888.5194,
        "close": 12819.1098,
        "low": 12735.3038
    },
    {
        "_id": "627deb0d98ba976ede49e2fd",
        "ask": 50,
        "bid": 26,
        "high": 12988.7704,
        "open": 12887.2294,
        "close": 12841.8431,
        "low": 12714.6852
    },
    {
        "_id": "627deb0d90e7b2d5159ace0a",
        "ask": 86,
        "bid": 14,
        "high": 12974.7915,
        "open": 12896.0604,
        "close": 12808.1945,
        "low": 12703.0077
    },
    {
        "_id": "627deb0dbbcc533dd21fba14",
        "ask": 11,
        "bid": 98,
        "high": 12977.4815,
        "open": 12896.962,
        "close": 12831.6938,
        "low": 12740.154
    },
    {
        "_id": "627deb0d8fda2851ac41059e",
        "ask": 47,
        "bid": 92,
        "high": 12926.3438,
        "open": 12890.1022,
        "close": 12842.7858,
        "low": 12782.0362
    },
    {
        "_id": "627deb0de465a1074fb5c329",
        "ask": 26,
        "bid": 58,
        "high": 12942.8052,
        "open": 12875.9278,
        "close": 12826.3768,
        "low": 12700.5024
    },
    {
        "_id": "627deb0dab8953cf0874d82a",
        "ask": 45,
        "bid": 16,
        "high": 12979.2556,
        "open": 12899.2926,
        "close": 12810.3367,
        "low": 12790.4052
    },
    {
        "_id": "627deb0d6893274d0f369ddd",
        "ask": 85,
        "bid": 47,
        "high": 12947.6779,
        "open": 12891.4035,
        "close": 12835.9536,
        "low": 12772.0607
    },
    {
        "_id": "627deb0d8d1ae1e18d9ee343",
        "ask": 95,
        "bid": 42,
        "high": 12919.5399,
        "open": 12873.8188,
        "close": 12838.8352,
        "low": 12756.1124
    },
    {
        "_id": "627deb0df9607cb12f8e9512",
        "ask": 50,
        "bid": 79,
        "high": 12949.1677,
        "open": 12888.4387,
        "close": 12828.796,
        "low": 12760.6295
    },
    {
        "_id": "627deb0dc1a3bd6d7c05166b",
        "ask": 94,
        "bid": 8,
        "high": 12919.5028,
        "open": 12883.6873,
        "close": 12830.72,
        "low": 12786.5297
    },
    {
        "_id": "627deb0d2e5d518ff62b5d40",
        "ask": 55,
        "bid": 16,
        "high": 12985.3029,
        "open": 12862.3508,
        "close": 12825.1728,
        "low": 12796.3105
    },
    {
        "_id": "627deb0def1594591640da7f",
        "ask": 3,
        "bid": 41,
        "high": 12934.8368,
        "open": 12880.2778,
        "close": 12803.7355,
        "low": 12718.9022
    },
    {
        "_id": "627deb0db89963b20c14ec03",
        "ask": 49,
        "bid": 27,
        "high": 12940.2694,
        "open": 12881.221,
        "close": 12849.498,
        "low": 12727.0264
    },
    {
        "_id": "627deb0dfb64014d14cf0121",
        "ask": 17,
        "bid": 96,
        "high": 12920.0225,
        "open": 12879.9791,
        "close": 12820.8284,
        "low": 12720.9854
    },
    {
        "_id": "627deb0df40c916d2ffbc75d",
        "ask": 39,
        "bid": 62,
        "high": 12942.0827,
        "open": 12879.2396,
        "close": 12822.7385,
        "low": 12721.9443
    },
    {
        "_id": "627deb0d5cd5557b18c78fce",
        "ask": 44,
        "bid": 35,
        "high": 12926.6341,
        "open": 12888.0655,
        "close": 12811.3528,
        "low": 12768.6376
    },
    {
        "_id": "627deb0d3d18c768b0d5e9ae",
        "ask": 77,
        "bid": 6,
        "high": 12986.7999,
        "open": 12857.7268,
        "close": 12828.0189,
        "low": 12770.5284
    },
    {
        "_id": "627deb0db26ba24938777d1a",
        "ask": 17,
        "bid": 18,
        "high": 12907.7049,
        "open": 12866.5664,
        "close": 12802.7422,
        "low": 12784.7838
    },
    {
        "_id": "627deb0d5585ddda90c2e086",
        "ask": 42,
        "bid": 24,
        "high": 12934.3896,
        "open": 12871.1001,
        "close": 12819.6291,
        "low": 12761.518
    },
    {
        "_id": "627deb0de5485adbf5a71f43",
        "ask": 60,
        "bid": 78,
        "high": 12956.0007,
        "open": 12862.5981,
        "close": 12824.6672,
        "low": 12747.0518
    },
    {
        "_id": "627deb0d8f1ea11066665abd",
        "ask": 92,
        "bid": 38,
        "high": 12951.6437,
        "open": 12882.3371,
        "close": 12803.1901,
        "low": 12759.001
    },
    {
        "_id": "627deb0db95286ed5f8cd7f6",
        "ask": 2,
        "bid": 29,
        "high": 12930.119,
        "open": 12870.1318,
        "close": 12846.9433,
        "low": 12798.3386
    },
    {
        "_id": "627deb0dfc9952caad460839",
        "ask": 0,
        "bid": 96,
        "high": 12935.6742,
        "open": 12852.269,
        "close": 12805.0208,
        "low": 12797.4255
    },
    {
        "_id": "627deb0dcb3693c449218acd",
        "ask": 85,
        "bid": 51,
        "high": 12983.6021,
        "open": 12869.5782,
        "close": 12810.0779,
        "low": 12771.3001
    },
    {
        "_id": "627deb0d3765a5634c7edf11",
        "ask": 9,
        "bid": 46,
        "high": 12955.2324,
        "open": 12861.246,
        "close": 12800.0531,
        "low": 12767.7497
    },
    {
        "_id": "627deb0db87201be91fdc045",
        "ask": 71,
        "bid": 2,
        "high": 12927.6609,
        "open": 12898.8441,
        "close": 12845.6539,
        "low": 12761.5491
    },
    {
        "_id": "627deb0d4c1a20ed7810599b",
        "ask": 66,
        "bid": 47,
        "high": 12932.1217,
        "open": 12880.5269,
        "close": 12828.1743,
        "low": 12733.1645
    },
    {
        "_id": "627deb0d31acd6307924506a",
        "ask": 1,
        "bid": 80,
        "high": 12964.9141,
        "open": 12861.8658,
        "close": 12848.677,
        "low": 12728.1561
    },
    {
        "_id": "627deb0d0ebefb1a22fb887f",
        "ask": 81,
        "bid": 68,
        "high": 12968.6637,
        "open": 12882.8492,
        "close": 12816.6908,
        "low": 12792.8538
    },
    {
        "_id": "627deb0de6c1c515a093355b",
        "ask": 8,
        "bid": 74,
        "high": 12996.5664,
        "open": 12888.9606,
        "close": 12829.8786,
        "low": 12726.2205
    },
    {
        "_id": "627deb0dc06ea10629478f9d",
        "ask": 87,
        "bid": 81,
        "high": 12902.6567,
        "open": 12887.462,
        "close": 12836.5889,
        "low": 12774.9642
    },
    {
        "_id": "627deb0d32e43fa8ab77db19",
        "ask": 21,
        "bid": 50,
        "high": 12965.2194,
        "open": 12885.0257,
        "close": 12833.2725,
        "low": 12734.3976
    },
    {
        "_id": "627deb0dbae46ffbc7646ca6",
        "ask": 85,
        "bid": 81,
        "high": 12936.682,
        "open": 12850.8504,
        "close": 12841.3573,
        "low": 12781.8183
    },
    {
        "_id": "627deb0ddd5cb6c792a25168",
        "ask": 40,
        "bid": 91,
        "high": 12966.8248,
        "open": 12863.3306,
        "close": 12826.9282,
        "low": 12796.8163
    },
    {
        "_id": "627deb0d41acfa0a188b1ec3",
        "ask": 13,
        "bid": 87,
        "high": 12963.1435,
        "open": 12886.7305,
        "close": 12822.5071,
        "low": 12716.3885
    },
    {
        "_id": "627deb0d28eac2f5aec17b9e",
        "ask": 3,
        "bid": 82,
        "high": 12996.3838,
        "open": 12868.865,
        "close": 12813.2874,
        "low": 12797.631
    },
    {
        "_id": "627deb0dba5b6bc01c9b55cc",
        "ask": 67,
        "bid": 37,
        "high": 12907.2871,
        "open": 12863.2945,
        "close": 12805.3107,
        "low": 12714.99
    },
    {
        "_id": "627deb0d34f2207950c1093f",
        "ask": 93,
        "bid": 17,
        "high": 12964.8343,
        "open": 12853.457,
        "close": 12847.2684,
        "low": 12736.2385
    },
    {
        "_id": "627deb0d040273583d4c4c09",
        "ask": 96,
        "bid": 44,
        "high": 12911.0888,
        "open": 12887.6972,
        "close": 12804.2671,
        "low": 12723.8883
    },
    {
        "_id": "627deb0df0bcaf387b55cc88",
        "ask": 68,
        "bid": 5,
        "high": 12950.8886,
        "open": 12888.3389,
        "close": 12842.1796,
        "low": 12767.8456
    },
    {
        "_id": "627deb0d65fa6c5924344457",
        "ask": 91,
        "bid": 60,
        "high": 12923.4045,
        "open": 12856.0969,
        "close": 12825.4315,
        "low": 12795.4051
    },
    {
        "_id": "627deb0d6f53b4b9b707d20b",
        "ask": 50,
        "bid": 74,
        "high": 12921.6726,
        "open": 12889.6867,
        "close": 12807.0637,
        "low": 12746.9375
    },
    {
        "_id": "627deb0d31a9b1470d1a5e7f",
        "ask": 66,
        "bid": 8,
        "high": 12930.661,
        "open": 12859.6459,
        "close": 12830.9415,
        "low": 12794.6113
    },
    {
        "_id": "627deb0db02ca872211e4fa4",
        "ask": 39,
        "bid": 14,
        "high": 12989.4394,
        "open": 12867.7525,
        "close": 12815.3501,
        "low": 12788.4932
    },
    {
        "_id": "627deb0d1e87179decf29636",
        "ask": 40,
        "bid": 46,
        "high": 12914.8791,
        "open": 12879.6733,
        "close": 12838.6409,
        "low": 12775.2606
    },
    {
        "_id": "627deb0db1df460c939e7781",
        "ask": 51,
        "bid": 6,
        "high": 12934.413,
        "open": 12872.2167,
        "close": 12838.2675,
        "low": 12706.1351
    },
    {
        "_id": "627deb0dfbc53e064fb22d2b",
        "ask": 16,
        "bid": 86,
        "high": 12966.0748,
        "open": 12895.7253,
        "close": 12815.204,
        "low": 12754.5013
    },
    {
        "_id": "627deb0dfc509612723c61b7",
        "ask": 69,
        "bid": 42,
        "high": 12925.9824,
        "open": 12861.6149,
        "close": 12813.2781,
        "low": 12704.9903
    },
    {
        "_id": "627deb0d0dbee89df311fd76",
        "ask": 36,
        "bid": 41,
        "high": 12996.3639,
        "open": 12888.3654,
        "close": 12816.4469,
        "low": 12768.478
    },
    {
        "_id": "627deb0dcdf8b2570e4fc531",
        "ask": 69,
        "bid": 70,
        "high": 12984.991,
        "open": 12865.8147,
        "close": 12849.9732,
        "low": 12708.8399
    },
    {
        "_id": "627deb0d4a6300b39744db5c",
        "ask": 60,
        "bid": 30,
        "high": 12937.8861,
        "open": 12853.0773,
        "close": 12826.552,
        "low": 12797.8786
    },
    {
        "_id": "627deb0d06081f6be74e2ce2",
        "ask": 31,
        "bid": 82,
        "high": 12972.1303,
        "open": 12869.7798,
        "close": 12830.2764,
        "low": 12715.3048
    },
    {
        "_id": "627deb0dead090452debf2de",
        "ask": 80,
        "bid": 29,
        "high": 12953.5404,
        "open": 12873.3598,
        "close": 12824.8436,
        "low": 12740.8988
    },
    {
        "_id": "627deb0d2f69924eb33f4d5f",
        "ask": 46,
        "bid": 17,
        "high": 12984.6691,
        "open": 12862.5091,
        "close": 12821.3124,
        "low": 12731.7573
    },
    {
        "_id": "627deb0dc475a7e1a09b61b9",
        "ask": 64,
        "bid": 27,
        "high": 12902.3431,
        "open": 12869.1211,
        "close": 12843.0386,
        "low": 12768.9924
    },
    {
        "_id": "627deb0dc7e1d47b8660478d",
        "ask": 7,
        "bid": 94,
        "high": 12978.1476,
        "open": 12876.7697,
        "close": 12816.3441,
        "low": 12709.8573
    },
    {
        "_id": "627deb0dd82bb07d1803c0b8",
        "ask": 87,
        "bid": 81,
        "high": 12905.9071,
        "open": 12899.2162,
        "close": 12840.9165,
        "low": 12775.4612
    },
    {
        "_id": "627deb0df544d855494ed389",
        "ask": 72,
        "bid": 67,
        "high": 12917.4741,
        "open": 12859.5149,
        "close": 12834.1064,
        "low": 12758.3268
    },
    {
        "_id": "627deb0dffd869188129f713",
        "ask": 100,
        "bid": 83,
        "high": 12922.4644,
        "open": 12867.3126,
        "close": 12815.0672,
        "low": 12798.2928
    },
    {
        "_id": "627deb0d45d563137cbe1638",
        "ask": 96,
        "bid": 11,
        "high": 12994.7733,
        "open": 12878.1655,
        "close": 12826.073,
        "low": 12744.4307
    },
    {
        "_id": "627deb0dae5eab506db556c8",
        "ask": 45,
        "bid": 85,
        "high": 12926.5929,
        "open": 12856.122,
        "close": 12819.7265,
        "low": 12766.9723
    },
    {
        "_id": "627deb0dde170d50483bf3da",
        "ask": 70,
        "bid": 50,
        "high": 12964.8678,
        "open": 12867.1272,
        "close": 12841.5955,
        "low": 12759.3275
    },
    {
        "_id": "627deb0de0cf42a0447e13ac",
        "ask": 60,
        "bid": 71,
        "high": 12944.2403,
        "open": 12850.1956,
        "close": 12801.7913,
        "low": 12719.5794
    },
    {
        "_id": "627deb0da05e6c4dd03fb9ab",
        "ask": 81,
        "bid": 17,
        "high": 12983.4411,
        "open": 12881.7997,
        "close": 12815.9685,
        "low": 12785.9862
    },
    {
        "_id": "627deb0d40348c601a32533d",
        "ask": 61,
        "bid": 36,
        "high": 12927.5033,
        "open": 12892.4783,
        "close": 12822.6084,
        "low": 12702.3438
    },
    {
        "_id": "627deb0ddff58ace4db22994",
        "ask": 82,
        "bid": 29,
        "high": 12979.3655,
        "open": 12858.6758,
        "close": 12808.8944,
        "low": 12773.0766
    },
    {
        "_id": "627deb0dba6432954c7c27f4",
        "ask": 38,
        "bid": 4,
        "high": 12930.8585,
        "open": 12887.6121,
        "close": 12819.1551,
        "low": 12731.933
    },
    {
        "_id": "627deb0dab77b301cd18d65f",
        "ask": 76,
        "bid": 22,
        "high": 12940.9512,
        "open": 12888.5202,
        "close": 12841.5197,
        "low": 12782.7677
    },
    {
        "_id": "627deb0d3de1dfcd145ed349",
        "ask": 94,
        "bid": 81,
        "high": 12919.236,
        "open": 12856.0875,
        "close": 12821.0737,
        "low": 12786.3095
    },
    {
        "_id": "627deb0dacdf772b7259e84d",
        "ask": 30,
        "bid": 28,
        "high": 12924.0399,
        "open": 12889.7089,
        "close": 12847.8321,
        "low": 12728.6715
    },
    {
        "_id": "627deb0dea1914a148587aa9",
        "ask": 30,
        "bid": 39,
        "high": 12926.7912,
        "open": 12899.2928,
        "close": 12813.1246,
        "low": 12786.7831
    },
    {
        "_id": "627deb0d9417462fd81ddfc0",
        "ask": 47,
        "bid": 57,
        "high": 12953.9532,
        "open": 12857.0777,
        "close": 12811.9857,
        "low": 12732.0206
    },
    {
        "_id": "627deb0dbff30654d94259b8",
        "ask": 34,
        "bid": 75,
        "high": 12941.8447,
        "open": 12865.1041,
        "close": 12833.1846,
        "low": 12711.1947
    },
    {
        "_id": "627deb0d82585944be7d617a",
        "ask": 81,
        "bid": 100,
        "high": 12935.6741,
        "open": 12885.7663,
        "close": 12828.1091,
        "low": 12714.7154
    },
    {
        "_id": "627deb0d67ce6ab378948c03",
        "ask": 22,
        "bid": 61,
        "high": 12940.8251,
        "open": 12850.5823,
        "close": 12818.2589,
        "low": 12713.3631
    },
    {
        "_id": "627deb0d6e09dab13faed252",
        "ask": 13,
        "bid": 91,
        "high": 12945.6343,
        "open": 12877.8858,
        "close": 12827.8015,
        "low": 12705.184
    },
    {
        "_id": "627deb0d5bb1c12286e13074",
        "ask": 51,
        "bid": 83,
        "high": 12954.3142,
        "open": 12875.9489,
        "close": 12840.4061,
        "low": 12786.5017
    },
    {
        "_id": "627deb0dc12614189ec50b9c",
        "ask": 61,
        "bid": 83,
        "high": 12933.7726,
        "open": 12865.4557,
        "close": 12808.4766,
        "low": 12761.2603
    },
    {
        "_id": "627deb0dae87cb24f57a30a8",
        "ask": 63,
        "bid": 96,
        "high": 12958,
        "open": 12859.9728,
        "close": 12807.5718,
        "low": 12730.5584
    },
    {
        "_id": "627deb0d62f60d1b489c7c61",
        "ask": 66,
        "bid": 74,
        "high": 12943.0175,
        "open": 12878.4778,
        "close": 12832.6299,
        "low": 12716.7032
    },
    {
        "_id": "627deb0d56a503fd85c9224a",
        "ask": 32,
        "bid": 35,
        "high": 12974.3764,
        "open": 12894.5484,
        "close": 12825.6695,
        "low": 12753.1976
    },
    {
        "_id": "627deb0d83388ea5841fecfa",
        "ask": 87,
        "bid": 82,
        "high": 12986.0161,
        "open": 12850.1912,
        "close": 12837.3822,
        "low": 12733.4939
    },
    {
        "_id": "627deb0deb63eb5010f63c16",
        "ask": 92,
        "bid": 78,
        "high": 12950.6514,
        "open": 12899.5792,
        "close": 12832.1289,
        "low": 12705.0268
    },
    {
        "_id": "627deb0da661dfa30bd67e15",
        "ask": 26,
        "bid": 26,
        "high": 12923.2072,
        "open": 12851.1552,
        "close": 12830.1064,
        "low": 12718.8606
    },
    {
        "_id": "627deb0da96a7fc5c911c9a0",
        "ask": 1,
        "bid": 42,
        "high": 12950.2052,
        "open": 12874.7704,
        "close": 12812.1382,
        "low": 12779.3805
    },
    {
        "_id": "627deb0d580e9521264c1a75",
        "ask": 95,
        "bid": 4,
        "high": 12927.9093,
        "open": 12879.0374,
        "close": 12822.5988,
        "low": 12780.3162
    },
    {
        "_id": "627deb0de0c7041e398a0f4b",
        "ask": 6,
        "bid": 57,
        "high": 12933.987,
        "open": 12860.8757,
        "close": 12812.9645,
        "low": 12746.4461
    },
    {
        "_id": "627deb0db4ffccdc96d9ca80",
        "ask": 3,
        "bid": 71,
        "high": 12946.7532,
        "open": 12852.0751,
        "close": 12823.8423,
        "low": 12703.2354
    },
    {
        "_id": "627deb0dd3918f400ab4eb7d",
        "ask": 92,
        "bid": 78,
        "high": 12921.4509,
        "open": 12859.3472,
        "close": 12816.3677,
        "low": 12777.9358
    },
    {
        "_id": "627deb0d054759c995e9e9bd",
        "ask": 29,
        "bid": 78,
        "high": 12965.4314,
        "open": 12891.5212,
        "close": 12814.4553,
        "low": 12737.8551
    },
    {
        "_id": "627deb0d3d426fe8eb6a044d",
        "ask": 90,
        "bid": 29,
        "high": 12902.9059,
        "open": 12888.1447,
        "close": 12832.8341,
        "low": 12774.1028
    },
    {
        "_id": "627deb0d0a8bc41f5ae02d03",
        "ask": 56,
        "bid": 21,
        "high": 12997.475,
        "open": 12887.2728,
        "close": 12825.4426,
        "low": 12740.2988
    },
    {
        "_id": "627deb0d1276b5854d15e2b3",
        "ask": 5,
        "bid": 25,
        "high": 12921.3222,
        "open": 12861.0661,
        "close": 12846.2293,
        "low": 12722.2065
    },
    {
        "_id": "627deb0d0c144365e90372fc",
        "ask": 35,
        "bid": 18,
        "high": 12935.367,
        "open": 12872.4255,
        "close": 12814.0529,
        "low": 12761.3945
    },
    {
        "_id": "627deb0dfb45f99f186a51fe",
        "ask": 82,
        "bid": 98,
        "high": 12973.089,
        "open": 12862.6129,
        "close": 12849.782,
        "low": 12790.735
    },
    {
        "_id": "627deb0d8b0f90b3a6c115fe",
        "ask": 95,
        "bid": 86,
        "high": 12916.2975,
        "open": 12895.7644,
        "close": 12843.3073,
        "low": 12785.6927
    },
    {
        "_id": "627deb0dc7e2ec1184aa192f",
        "ask": 92,
        "bid": 2,
        "high": 12955.4478,
        "open": 12850.2159,
        "close": 12827.8972,
        "low": 12703.1412
    },
    {
        "_id": "627deb0df526ebf8e2801f5c",
        "ask": 69,
        "bid": 6,
        "high": 12995.4163,
        "open": 12857.7001,
        "close": 12813.7526,
        "low": 12735.9845
    },
    {
        "_id": "627deb0df35ab004d5e8d340",
        "ask": 39,
        "bid": 87,
        "high": 12924.6871,
        "open": 12897.9523,
        "close": 12842.7303,
        "low": 12721.5869
    },
    {
        "_id": "627deb0defae73462843c691",
        "ask": 71,
        "bid": 50,
        "high": 12968.1489,
        "open": 12892.908,
        "close": 12800.8399,
        "low": 12763.9961
    },
    {
        "_id": "627deb0d7f14391430f85846",
        "ask": 48,
        "bid": 7,
        "high": 12997.1775,
        "open": 12853.978,
        "close": 12831.3089,
        "low": 12796.4692
    },
    {
        "_id": "627deb0d0faba151086e7295",
        "ask": 82,
        "bid": 52,
        "high": 12993.8663,
        "open": 12892.5451,
        "close": 12829.7148,
        "low": 12747.7845
    },
    {
        "_id": "627deb0d5e551860c0471a4e",
        "ask": 69,
        "bid": 39,
        "high": 12914.9869,
        "open": 12870.6477,
        "close": 12845.0502,
        "low": 12746.6173
    },
    {
        "_id": "627deb0d2d109551d13435dc",
        "ask": 86,
        "bid": 12,
        "high": 12956.9465,
        "open": 12862.8108,
        "close": 12829.9198,
        "low": 12775.4786
    },
    {
        "_id": "627deb0db1154f7d8f973ec0",
        "ask": 38,
        "bid": 100,
        "high": 12918.0348,
        "open": 12862.5553,
        "close": 12807.8733,
        "low": 12781.7736
    },
    {
        "_id": "627deb0d27f41a7fa1f463b0",
        "ask": 72,
        "bid": 94,
        "high": 12927.3265,
        "open": 12890.2658,
        "close": 12813.6762,
        "low": 12713.186
    },
    {
        "_id": "627deb0d8d90e2dc14333589",
        "ask": 46,
        "bid": 31,
        "high": 12929.873,
        "open": 12881.6568,
        "close": 12800.1559,
        "low": 12732.468
    },
    {
        "_id": "627deb0dd911db6c052d2719",
        "ask": 84,
        "bid": 44,
        "high": 12934.9477,
        "open": 12878.7654,
        "close": 12830.9909,
        "low": 12730.9532
    },
    {
        "_id": "627deb0d1e92a29b20ccdc5c",
        "ask": 66,
        "bid": 18,
        "high": 12984.2844,
        "open": 12875.5296,
        "close": 12817.013,
        "low": 12715.528
    },
    {
        "_id": "627deb0d3ddc810ff01ebd24",
        "ask": 82,
        "bid": 88,
        "high": 12927.0136,
        "open": 12874.0066,
        "close": 12820.7547,
        "low": 12789.3
    },
    {
        "_id": "627deb0d6094220003345428",
        "ask": 92,
        "bid": 8,
        "high": 12917.6998,
        "open": 12886.357,
        "close": 12829.6163,
        "low": 12761.1768
    },
    {
        "_id": "627deb0df9f8e2d08ea67d20",
        "ask": 14,
        "bid": 26,
        "high": 12900.7013,
        "open": 12855.336,
        "close": 12837.0584,
        "low": 12765.2791
    },
    {
        "_id": "627deb0d2190db68d2ea7eea",
        "ask": 16,
        "bid": 92,
        "high": 12911.8532,
        "open": 12880.7322,
        "close": 12846.3922,
        "low": 12761.53
    },
    {
        "_id": "627deb0dd327ee967490d6b9",
        "ask": 12,
        "bid": 70,
        "high": 12916.8887,
        "open": 12883.5153,
        "close": 12816.5792,
        "low": 12757.5066
    },
    {
        "_id": "627deb0d21ba6780dc1c52a7",
        "ask": 8,
        "bid": 42,
        "high": 12925.3733,
        "open": 12870.2771,
        "close": 12841.1897,
        "low": 12724.5056
    },
    {
        "_id": "627deb0df9fe08c1391b9563",
        "ask": 89,
        "bid": 42,
        "high": 12927.5044,
        "open": 12896.5637,
        "close": 12818.8063,
        "low": 12769.7937
    },
    {
        "_id": "627deb0d09ae50a93e109bf9",
        "ask": 71,
        "bid": 84,
        "high": 12994.89,
        "open": 12897.7112,
        "close": 12840.1504,
        "low": 12785.6003
    },
    {
        "_id": "627deb0d6573054d37cb9609",
        "ask": 10,
        "bid": 85,
        "high": 12978.3457,
        "open": 12869.2953,
        "close": 12843.8202,
        "low": 12720.1519
    },
    {
        "_id": "627deb0dc41ad653a1165e5b",
        "ask": 5,
        "bid": 54,
        "high": 12940.3223,
        "open": 12891.3809,
        "close": 12804.8016,
        "low": 12785.5264
    },
    {
        "_id": "627deb0d254d36eb90800d4d",
        "ask": 99,
        "bid": 47,
        "high": 12951.6227,
        "open": 12890.8141,
        "close": 12828.6212,
        "low": 12794.691
    },
    {
        "_id": "627deb0d5b162166208ec03c",
        "ask": 24,
        "bid": 10,
        "high": 12976.7591,
        "open": 12866.0302,
        "close": 12846.7058,
        "low": 12783.1332
    },
    {
        "_id": "627deb0d95c1bca02b8b48e0",
        "ask": 35,
        "bid": 26,
        "high": 12960.8271,
        "open": 12895.1901,
        "close": 12830.2001,
        "low": 12791.6386
    },
    {
        "_id": "627deb0df10832d58d178de5",
        "ask": 4,
        "bid": 93,
        "high": 12950.9346,
        "open": 12862.9086,
        "close": 12848.375,
        "low": 12760.7499
    },
    {
        "_id": "627deb0dc47b31e851bb4e9a",
        "ask": 18,
        "bid": 20,
        "high": 12937.2339,
        "open": 12890.554,
        "close": 12805.5944,
        "low": 12713.7132
    },
    {
        "_id": "627deb0d74c08ea4e6655614",
        "ask": 75,
        "bid": 65,
        "high": 12947.2912,
        "open": 12868.5408,
        "close": 12827.173,
        "low": 12760.1979
    },
    {
        "_id": "627deb0d6ed70bc61363e8f7",
        "ask": 49,
        "bid": 44,
        "high": 12951.5409,
        "open": 12854.9971,
        "close": 12815.0441,
        "low": 12762.1662
    },
    {
        "_id": "627deb0d26f7ca2a66565062",
        "ask": 17,
        "bid": 35,
        "high": 12936.2219,
        "open": 12899.6045,
        "close": 12829.7996,
        "low": 12783.3311
    },
    {
        "_id": "627deb0d6c23895da639ea99",
        "ask": 94,
        "bid": 22,
        "high": 12964.0524,
        "open": 12883.7718,
        "close": 12816.8422,
        "low": 12788.2626
    },
    {
        "_id": "627deb0da8381b584ba6a742",
        "ask": 25,
        "bid": 3,
        "high": 12941.0325,
        "open": 12888.4017,
        "close": 12832.8197,
        "low": 12748.3535
    },
    {
        "_id": "627deb0d625c6507e0c6b0ce",
        "ask": 19,
        "bid": 36,
        "high": 12985.0803,
        "open": 12859.6084,
        "close": 12842.375,
        "low": 12798.8557
    },
    {
        "_id": "627deb0d987c952e7d8558b8",
        "ask": 76,
        "bid": 93,
        "high": 12936.8466,
        "open": 12871.3844,
        "close": 12812.9848,
        "low": 12708.3378
    },
    {
        "_id": "627deb0dafe1b47d818e7b84",
        "ask": 2,
        "bid": 59,
        "high": 12903.6308,
        "open": 12873.3512,
        "close": 12807.8201,
        "low": 12760.6517
    },
    {
        "_id": "627deb0d5989f0133551c2f3",
        "ask": 55,
        "bid": 67,
        "high": 12955.815,
        "open": 12898.7094,
        "close": 12834.9991,
        "low": 12732.9254
    },
    {
        "_id": "627deb0dc56f84683d2f5a3f",
        "ask": 24,
        "bid": 91,
        "high": 12948.8506,
        "open": 12881.662,
        "close": 12809.81,
        "low": 12799.484
    },
    {
        "_id": "627deb0d06727abd511609fe",
        "ask": 31,
        "bid": 10,
        "high": 12926.0418,
        "open": 12878.8426,
        "close": 12820.406,
        "low": 12736.8101
    },
    {
        "_id": "627deb0d6fe7df8abd19d876",
        "ask": 59,
        "bid": 29,
        "high": 12907.4411,
        "open": 12870.4867,
        "close": 12824.3188,
        "low": 12714.0558
    },
    {
        "_id": "627deb0dcb3313c7a2cf540a",
        "ask": 79,
        "bid": 21,
        "high": 12940.7762,
        "open": 12871.7844,
        "close": 12808.4681,
        "low": 12709.7924
    },
    {
        "_id": "627deb0d80f435cabf4e033a",
        "ask": 26,
        "bid": 63,
        "high": 12992.5957,
        "open": 12879.2325,
        "close": 12840.233,
        "low": 12794.3906
    },
    {
        "_id": "627deb0dfcc688c08f56cd80",
        "ask": 24,
        "bid": 53,
        "high": 12965.427,
        "open": 12861.6707,
        "close": 12848.1475,
        "low": 12770.2833
    },
    {
        "_id": "627deb0d7c68ba32e16a42cc",
        "ask": 62,
        "bid": 16,
        "high": 12959.901,
        "open": 12855.0681,
        "close": 12842.0955,
        "low": 12787.1782
    },
    {
        "_id": "627deb0daa9b4d334612b08c",
        "ask": 56,
        "bid": 82,
        "high": 12980.9838,
        "open": 12884.0232,
        "close": 12829.2494,
        "low": 12706.232
    },
    {
        "_id": "627deb0d54ec395949b6cdba",
        "ask": 94,
        "bid": 60,
        "high": 12955.9949,
        "open": 12891.9774,
        "close": 12801.4016,
        "low": 12707.9591
    },
    {
        "_id": "627deb0d52f110d60632cb98",
        "ask": 12,
        "bid": 9,
        "high": 12964.5185,
        "open": 12899.9905,
        "close": 12823.793,
        "low": 12781.7825
    },
    {
        "_id": "627deb0dc491faf81df37f57",
        "ask": 0,
        "bid": 77,
        "high": 12906.082,
        "open": 12870.1145,
        "close": 12800.5805,
        "low": 12728.0865
    },
    {
        "_id": "627deb0d9c68190136f067c7",
        "ask": 28,
        "bid": 54,
        "high": 12954.6058,
        "open": 12887.3951,
        "close": 12829.2657,
        "low": 12758.8603
    },
    {
        "_id": "627deb0dcfd8f596089427da",
        "ask": 13,
        "bid": 39,
        "high": 12902.733,
        "open": 12895.782,
        "close": 12806.918,
        "low": 12781.3314
    },
    {
        "_id": "627deb0d1165c5e0a6e0148c",
        "ask": 16,
        "bid": 56,
        "high": 12990.2902,
        "open": 12880.4821,
        "close": 12815.8304,
        "low": 12708.4461
    },
    {
        "_id": "627deb0df3f1f180c2e2735c",
        "ask": 68,
        "bid": 17,
        "high": 12957.8974,
        "open": 12857.0227,
        "close": 12842.0155,
        "low": 12709.1578
    },
    {
        "_id": "627deb0d3480d07734b741dc",
        "ask": 78,
        "bid": 19,
        "high": 12962.2593,
        "open": 12874.8791,
        "close": 12835.3688,
        "low": 12757.8503
    },
    {
        "_id": "627deb0d97837f33908e5495",
        "ask": 73,
        "bid": 75,
        "high": 12916.5772,
        "open": 12894.1339,
        "close": 12836.1566,
        "low": 12762.787
    },
    {
        "_id": "627deb0d1d07a7698b2c9746",
        "ask": 45,
        "bid": 25,
        "high": 12908.4191,
        "open": 12886.2366,
        "close": 12811.4364,
        "low": 12775.0421
    },
    {
        "_id": "627deb0d816c5cb456eecc3a",
        "ask": 54,
        "bid": 18,
        "high": 12992.9692,
        "open": 12893.8048,
        "close": 12802.8765,
        "low": 12752.7269
    },
    {
        "_id": "627deb0d69adef7fa0224435",
        "ask": 5,
        "bid": 40,
        "high": 12988.8701,
        "open": 12887.4293,
        "close": 12803.7566,
        "low": 12754.6441
    },
    {
        "_id": "627deb0d7becec88c303aafd",
        "ask": 48,
        "bid": 5,
        "high": 12961.6859,
        "open": 12865.2812,
        "close": 12801.2739,
        "low": 12750.0196
    },
    {
        "_id": "627deb0d676732cd91169069",
        "ask": 77,
        "bid": 96,
        "high": 12952.9615,
        "open": 12861.6415,
        "close": 12807.0835,
        "low": 12733.9305
    },
    {
        "_id": "627deb0d0205b670c0ca9d40",
        "ask": 76,
        "bid": 29,
        "high": 12985.9389,
        "open": 12879.5112,
        "close": 12807.6991,
        "low": 12776.3932
    },
    {
        "_id": "627deb0d2072902a05452fb2",
        "ask": 71,
        "bid": 64,
        "high": 12957.5246,
        "open": 12895.4287,
        "close": 12800.4782,
        "low": 12749.9286
    },
    {
        "_id": "627deb0de5355f1c892c7349",
        "ask": 81,
        "bid": 77,
        "high": 12936.3227,
        "open": 12871.2697,
        "close": 12805.8991,
        "low": 12729.0286
    },
    {
        "_id": "627deb0d92467ad1f90a03c3",
        "ask": 69,
        "bid": 96,
        "high": 12949.5927,
        "open": 12871.5411,
        "close": 12838.1842,
        "low": 12761.7816
    },
    {
        "_id": "627deb0d25b62a549ed4b8da",
        "ask": 60,
        "bid": 100,
        "high": 12968.9539,
        "open": 12858.2112,
        "close": 12819.9283,
        "low": 12723.9218
    },
    {
        "_id": "627deb0daf8efad658c42589",
        "ask": 98,
        "bid": 69,
        "high": 12974.154,
        "open": 12853.5705,
        "close": 12840.2236,
        "low": 12707.4666
    },
    {
        "_id": "627deb0d7be3575d399b240d",
        "ask": 88,
        "bid": 20,
        "high": 12927.9,
        "open": 12860.2994,
        "close": 12838.6459,
        "low": 12731.2535
    },
    {
        "_id": "627deb0dca7e41a34dbe0567",
        "ask": 70,
        "bid": 66,
        "high": 12913.2877,
        "open": 12873.0319,
        "close": 12806.8569,
        "low": 12765.2087
    },
    {
        "_id": "627deb0d9f9cded8a7dd988a",
        "ask": 92,
        "bid": 34,
        "high": 12948.7729,
        "open": 12870.2168,
        "close": 12841.5735,
        "low": 12772.6156
    },
    {
        "_id": "627deb0d448e2e0eee65230d",
        "ask": 25,
        "bid": 54,
        "high": 12970.4468,
        "open": 12885.605,
        "close": 12838.1271,
        "low": 12749.9807
    },
    {
        "_id": "627deb0de00432e41a1784e4",
        "ask": 92,
        "bid": 92,
        "high": 12957.1472,
        "open": 12858.5936,
        "close": 12828.2071,
        "low": 12713.5766
    },
    {
        "_id": "627deb0df167a398cd1d86c3",
        "ask": 66,
        "bid": 12,
        "high": 12971.7988,
        "open": 12860.1252,
        "close": 12839.6473,
        "low": 12777.867
    },
    {
        "_id": "627deb0d2b16f28b8639bd5e",
        "ask": 76,
        "bid": 58,
        "high": 12919.7742,
        "open": 12887.887,
        "close": 12838.6754,
        "low": 12747.998
    },
    {
        "_id": "627deb0d641238865faa984c",
        "ask": 79,
        "bid": 26,
        "high": 12992.6077,
        "open": 12871.857,
        "close": 12820.3226,
        "low": 12749.0511
    },
    {
        "_id": "627deb0d212ea95d52219771",
        "ask": 21,
        "bid": 49,
        "high": 12978.3713,
        "open": 12897.5383,
        "close": 12837.0346,
        "low": 12784.8109
    },
    {
        "_id": "627deb0dea9c3b9906eb1959",
        "ask": 18,
        "bid": 57,
        "high": 12931.1343,
        "open": 12876.2859,
        "close": 12803.3572,
        "low": 12785.3117
    },
    {
        "_id": "627deb0d05593ca71ad60803",
        "ask": 87,
        "bid": 45,
        "high": 12931.8705,
        "open": 12854.1717,
        "close": 12833.4884,
        "low": 12715.5894
    },
    {
        "_id": "627deb0dd2204edca6b92f17",
        "ask": 36,
        "bid": 53,
        "high": 12986.3526,
        "open": 12865.6066,
        "close": 12823.7235,
        "low": 12773.4092
    },
    {
        "_id": "627deb0d6b653cd2b47fa902",
        "ask": 88,
        "bid": 90,
        "high": 12913.4601,
        "open": 12875.97,
        "close": 12802.0685,
        "low": 12719.1905
    },
    {
        "_id": "627deb0d4af095ef89288607",
        "ask": 28,
        "bid": 68,
        "high": 12971.8523,
        "open": 12854.7746,
        "close": 12835.0945,
        "low": 12799.9745
    },
    {
        "_id": "627deb0db4e7c4993aa307a7",
        "ask": 29,
        "bid": 11,
        "high": 12900.5252,
        "open": 12873.6407,
        "close": 12848.8502,
        "low": 12743.9573
    },
    {
        "_id": "627deb0d077905e1dd791ffb",
        "ask": 52,
        "bid": 88,
        "high": 12950.846,
        "open": 12893.4422,
        "close": 12848.0379,
        "low": 12789.1365
    },
    {
        "_id": "627deb0d213e215cf0ae11b7",
        "ask": 67,
        "bid": 43,
        "high": 12991.4334,
        "open": 12872.3335,
        "close": 12841.6258,
        "low": 12784.382
    },
    {
        "_id": "627deb0dfc4be15e4cb3f1b5",
        "ask": 37,
        "bid": 97,
        "high": 12964.0495,
        "open": 12888.0555,
        "close": 12830.8178,
        "low": 12718.5814
    },
    {
        "_id": "627deb0d558cd747629a9ffa",
        "ask": 19,
        "bid": 89,
        "high": 12940.9857,
        "open": 12879.1366,
        "close": 12848.804,
        "low": 12799.8264
    },
    {
        "_id": "627deb0d676fd47d9e4e00ff",
        "ask": 31,
        "bid": 0,
        "high": 12990.5759,
        "open": 12855.2609,
        "close": 12845.7737,
        "low": 12733.568
    },
    {
        "_id": "627deb0dd00208797830cc83",
        "ask": 81,
        "bid": 52,
        "high": 12982.2518,
        "open": 12897.9778,
        "close": 12836.453,
        "low": 12767.6521
    },
    {
        "_id": "627deb0d993f75fb16812dde",
        "ask": 16,
        "bid": 32,
        "high": 12987.093,
        "open": 12881.2828,
        "close": 12803.7897,
        "low": 12716.2021
    },
    {
        "_id": "627deb0d89395b5b4a9af231",
        "ask": 49,
        "bid": 6,
        "high": 12924.478,
        "open": 12886.5401,
        "close": 12823.2663,
        "low": 12724.1811
    },
    {
        "_id": "627deb0dc833a5e628e62ba2",
        "ask": 40,
        "bid": 59,
        "high": 12981.216,
        "open": 12895.798,
        "close": 12836.3756,
        "low": 12776.5868
    },
    {
        "_id": "627deb0df418b4fd36a5adb0",
        "ask": 97,
        "bid": 1,
        "high": 12943.3948,
        "open": 12880.8648,
        "close": 12836.684,
        "low": 12776.7447
    },
    {
        "_id": "627deb0d19e7a6ac70246581",
        "ask": 13,
        "bid": 16,
        "high": 12936.252,
        "open": 12854.9719,
        "close": 12815.4836,
        "low": 12744.6403
    },
    {
        "_id": "627deb0de260b1f1b3e886b4",
        "ask": 81,
        "bid": 48,
        "high": 12995.3746,
        "open": 12882.9827,
        "close": 12820.7653,
        "low": 12768.1626
    },
    {
        "_id": "627deb0d6bbc805d7e707eb8",
        "ask": 52,
        "bid": 43,
        "high": 12900.3516,
        "open": 12863.5177,
        "close": 12845.8076,
        "low": 12728.4064
    },
    {
        "_id": "627deb0d4055a929ea53ef69",
        "ask": 53,
        "bid": 21,
        "high": 12912.9911,
        "open": 12881.495,
        "close": 12821.6485,
        "low": 12737.7496
    },
    {
        "_id": "627deb0ddfe15ed49731d59a",
        "ask": 29,
        "bid": 8,
        "high": 12935.3592,
        "open": 12879.4009,
        "close": 12819.2655,
        "low": 12784.3647
    },
    {
        "_id": "627deb0d4573780f87f2fecf",
        "ask": 38,
        "bid": 66,
        "high": 12903.7969,
        "open": 12854.483,
        "close": 12847.3414,
        "low": 12751.4513
    },
    {
        "_id": "627deb0d8e945535fe861cba",
        "ask": 15,
        "bid": 50,
        "high": 12966.9689,
        "open": 12857.3063,
        "close": 12816.0498,
        "low": 12743.5809
    },
    {
        "_id": "627deb0d8610ab819c24b2ac",
        "ask": 60,
        "bid": 56,
        "high": 12943.0092,
        "open": 12857.2018,
        "close": 12811.3118,
        "low": 12794.565
    },
    {
        "_id": "627deb0d9ff6a7a9c36b4be0",
        "ask": 31,
        "bid": 8,
        "high": 12923.7968,
        "open": 12899.3982,
        "close": 12813.5314,
        "low": 12774.0894
    },
    {
        "_id": "627deb0db492484853d47c58",
        "ask": 11,
        "bid": 49,
        "high": 12906.5173,
        "open": 12866.8213,
        "close": 12823.8088,
        "low": 12749.4396
    },
    {
        "_id": "627deb0d5d4dcb4d31c8b091",
        "ask": 59,
        "bid": 33,
        "high": 12902.243,
        "open": 12871.5171,
        "close": 12807.5513,
        "low": 12778.8767
    },
    {
        "_id": "627deb0d75f36caafbe3c591",
        "ask": 64,
        "bid": 48,
        "high": 12961.7501,
        "open": 12890.1543,
        "close": 12848.8294,
        "low": 12719.7211
    },
    {
        "_id": "627deb0d82da58f08091699a",
        "ask": 39,
        "bid": 38,
        "high": 12990.3337,
        "open": 12882.7498,
        "close": 12812.9693,
        "low": 12794.4884
    },
    {
        "_id": "627deb0d7ad9669389462b56",
        "ask": 74,
        "bid": 27,
        "high": 12994.7014,
        "open": 12872.4233,
        "close": 12843.9621,
        "low": 12757.7467
    },
    {
        "_id": "627deb0d9348baab462e8b9f",
        "ask": 34,
        "bid": 81,
        "high": 12918.2073,
        "open": 12869.574,
        "close": 12839.5258,
        "low": 12713.5374
    },
    {
        "_id": "627deb0d807de35aede3cd18",
        "ask": 21,
        "bid": 49,
        "high": 12925.621,
        "open": 12862.0669,
        "close": 12827.6216,
        "low": 12794.1832
    },
    {
        "_id": "627deb0db7b15681539a1ed4",
        "ask": 35,
        "bid": 10,
        "high": 12913.3249,
        "open": 12878.7438,
        "close": 12818.5355,
        "low": 12713.6396
    },
    {
        "_id": "627deb0d485ea52767dadf76",
        "ask": 44,
        "bid": 14,
        "high": 12958.8408,
        "open": 12851.4738,
        "close": 12814.0342,
        "low": 12742.8071
    },
    {
        "_id": "627deb0d157c8d7609aa493c",
        "ask": 12,
        "bid": 34,
        "high": 12956.0197,
        "open": 12872.3805,
        "close": 12817.3204,
        "low": 12717.1192
    },
    {
        "_id": "627deb0d96e77c97dc9fab43",
        "ask": 10,
        "bid": 91,
        "high": 12908.7652,
        "open": 12893.9177,
        "close": 12831.547,
        "low": 12758.1311
    },
    {
        "_id": "627deb0d3947cd06ac7c325a",
        "ask": 56,
        "bid": 17,
        "high": 12921.1665,
        "open": 12867.7208,
        "close": 12812.4636,
        "low": 12714.3393
    },
    {
        "_id": "627deb0d03ffcbfff81d6fd7",
        "ask": 57,
        "bid": 99,
        "high": 12981.1415,
        "open": 12896.6352,
        "close": 12823.7622,
        "low": 12720.9293
    },
    {
        "_id": "627deb0d48d134aa5bb01533",
        "ask": 25,
        "bid": 66,
        "high": 12959.2087,
        "open": 12873.5241,
        "close": 12800.8336,
        "low": 12721.4186
    },
    {
        "_id": "627deb0d304e0e47ea00e27c",
        "ask": 3,
        "bid": 13,
        "high": 12978.7315,
        "open": 12884.9464,
        "close": 12844.6887,
        "low": 12702.1956
    },
    {
        "_id": "627deb0d147c099fd9c8b2bd",
        "ask": 30,
        "bid": 24,
        "high": 12907.4244,
        "open": 12865.0292,
        "close": 12843.5504,
        "low": 12747.855
    },
    {
        "_id": "627deb0d0fd7ea42a0987d07",
        "ask": 61,
        "bid": 99,
        "high": 12945.1581,
        "open": 12894.8686,
        "close": 12833.2995,
        "low": 12715.7551
    },
    {
        "_id": "627deb0df0da586c5b5e7cef",
        "ask": 95,
        "bid": 66,
        "high": 12928.526,
        "open": 12893.1008,
        "close": 12839.5052,
        "low": 12734.8134
    },
    {
        "_id": "627deb0da1b082538f609211",
        "ask": 55,
        "bid": 94,
        "high": 12913.4263,
        "open": 12860.2063,
        "close": 12848.6336,
        "low": 12755.9578
    },
    {
        "_id": "627deb0db762be79d2e334e6",
        "ask": 54,
        "bid": 46,
        "high": 12908.3594,
        "open": 12887.62,
        "close": 12825.5064,
        "low": 12778.554
    },
    {
        "_id": "627deb0db498b6ad01e8ab33",
        "ask": 23,
        "bid": 64,
        "high": 12906.0711,
        "open": 12892.7243,
        "close": 12809.6729,
        "low": 12740.4301
    },
    {
        "_id": "627deb0d9ed0aa5b072510be",
        "ask": 33,
        "bid": 34,
        "high": 12935.9404,
        "open": 12872.8905,
        "close": 12823.134,
        "low": 12731.587
    },
    {
        "_id": "627deb0d75a810da2168aae1",
        "ask": 98,
        "bid": 43,
        "high": 12978.582,
        "open": 12877.2992,
        "close": 12819.3067,
        "low": 12797.1601
    },
    {
        "_id": "627deb0d974b2049f8a891b5",
        "ask": 13,
        "bid": 50,
        "high": 12910.0425,
        "open": 12888.701,
        "close": 12810.6505,
        "low": 12761.7301
    },
    {
        "_id": "627deb0d18670c5b92cf0719",
        "ask": 68,
        "bid": 36,
        "high": 12956.792,
        "open": 12871.5977,
        "close": 12831.0798,
        "low": 12755.1524
    },
    {
        "_id": "627deb0df8d077e7e722c904",
        "ask": 97,
        "bid": 95,
        "high": 12924.0077,
        "open": 12880.8308,
        "close": 12835.0481,
        "low": 12762.9063
    },
    {
        "_id": "627deb0df809a06b5a7a1b9d",
        "ask": 46,
        "bid": 63,
        "high": 12923.2761,
        "open": 12862.0563,
        "close": 12849.349,
        "low": 12705.4692
    },
    {
        "_id": "627deb0d35d539e431e25209",
        "ask": 34,
        "bid": 31,
        "high": 12980.4893,
        "open": 12875.4362,
        "close": 12823.0577,
        "low": 12721.3923
    },
    {
        "_id": "627deb0d5e38c2d6f2271fc1",
        "ask": 23,
        "bid": 7,
        "high": 12995.7443,
        "open": 12858.966,
        "close": 12829.6363,
        "low": 12737.5588
    },
    {
        "_id": "627deb0d40c4634da5c2c054",
        "ask": 62,
        "bid": 41,
        "high": 12935.4685,
        "open": 12878.2471,
        "close": 12812.2739,
        "low": 12704.1779
    },
    {
        "_id": "627deb0da28c6cf966b310df",
        "ask": 74,
        "bid": 24,
        "high": 12951.3749,
        "open": 12854.4397,
        "close": 12812.2342,
        "low": 12789.4367
    },
    {
        "_id": "627deb0d713e6109570abf94",
        "ask": 48,
        "bid": 8,
        "high": 12987.3732,
        "open": 12878.2175,
        "close": 12819.1883,
        "low": 12750.5489
    },
    {
        "_id": "627deb0d04b5f4694b131a7b",
        "ask": 92,
        "bid": 35,
        "high": 12930.9285,
        "open": 12884.938,
        "close": 12810.4757,
        "low": 12770.6904
    },
    {
        "_id": "627deb0dcc56ea0e87412055",
        "ask": 24,
        "bid": 89,
        "high": 12919.5536,
        "open": 12863.1804,
        "close": 12826.9199,
        "low": 12740.6209
    },
    {
        "_id": "627deb0d97784807111fff9b",
        "ask": 26,
        "bid": 42,
        "high": 12920.3987,
        "open": 12890.4425,
        "close": 12834.9763,
        "low": 12792.0836
    },
    {
        "_id": "627deb0d0f27101fd9866b0d",
        "ask": 45,
        "bid": 28,
        "high": 12909.0689,
        "open": 12872.8476,
        "close": 12828.6045,
        "low": 12794.5312
    },
    {
        "_id": "627deb0dacf0bf77af91e9d3",
        "ask": 72,
        "bid": 28,
        "high": 12955.3124,
        "open": 12890.4138,
        "close": 12803.457,
        "low": 12749.9468
    },
    {
        "_id": "627deb0d9c94125c98dfe4b5",
        "ask": 27,
        "bid": 87,
        "high": 12903.4077,
        "open": 12885.7547,
        "close": 12846.5723,
        "low": 12799.2846
    },
    {
        "_id": "627deb0d693ce8cabf3ffd35",
        "ask": 50,
        "bid": 92,
        "high": 12941.9095,
        "open": 12851.1591,
        "close": 12838.9735,
        "low": 12788.5414
    },
    {
        "_id": "627deb0d2cc59221f1338490",
        "ask": 52,
        "bid": 97,
        "high": 12958.8637,
        "open": 12856.1845,
        "close": 12813.0125,
        "low": 12759.3141
    },
    {
        "_id": "627deb0d188bde0261d1e588",
        "ask": 84,
        "bid": 6,
        "high": 12927.7291,
        "open": 12865.4637,
        "close": 12810.5778,
        "low": 12783.6922
    },
    {
        "_id": "627deb0d3191a5204eb973b8",
        "ask": 67,
        "bid": 98,
        "high": 12979.4584,
        "open": 12874.8708,
        "close": 12805.6789,
        "low": 12714.5535
    },
    {
        "_id": "627deb0d4022a56e1ff7b69c",
        "ask": 61,
        "bid": 18,
        "high": 12932.5086,
        "open": 12874.4971,
        "close": 12812.7192,
        "low": 12772.1434
    },
    {
        "_id": "627deb0d38d6aeb43cd691e6",
        "ask": 15,
        "bid": 8,
        "high": 12995.5589,
        "open": 12860.3022,
        "close": 12839.2964,
        "low": 12703.0668
    },
    {
        "_id": "627deb0da713f77ed16302f7",
        "ask": 4,
        "bid": 2,
        "high": 12907.2624,
        "open": 12883.2329,
        "close": 12842.6647,
        "low": 12791.1573
    },
    {
        "_id": "627deb0d9b5bd62268edafd9",
        "ask": 68,
        "bid": 47,
        "high": 12967.2362,
        "open": 12865.195,
        "close": 12813.4468,
        "low": 12709.3654
    },
    {
        "_id": "627deb0d24ef56a7c4a0f91d",
        "ask": 72,
        "bid": 39,
        "high": 12980.2238,
        "open": 12882.4084,
        "close": 12811.0345,
        "low": 12766.8874
    },
    {
        "_id": "627deb0d614026a294b86a69",
        "ask": 63,
        "bid": 12,
        "high": 12983.1329,
        "open": 12871.0324,
        "close": 12809.9797,
        "low": 12700.3272
    },
    {
        "_id": "627deb0d5d74d3de29ef4fb4",
        "ask": 76,
        "bid": 26,
        "high": 12974.1657,
        "open": 12869.2579,
        "close": 12832.9486,
        "low": 12751.428
    },
    {
        "_id": "627deb0d63a1ec3bfec334d6",
        "ask": 8,
        "bid": 59,
        "high": 12969.2484,
        "open": 12886.549,
        "close": 12810.6465,
        "low": 12709.8519
    },
    {
        "_id": "627deb0dc0a91ac833d63cb2",
        "ask": 77,
        "bid": 56,
        "high": 12914.0933,
        "open": 12863.1547,
        "close": 12833.4374,
        "low": 12790.7096
    },
    {
        "_id": "627deb0d7913467563b1d6cb",
        "ask": 89,
        "bid": 83,
        "high": 12966.5778,
        "open": 12852.1123,
        "close": 12842.3847,
        "low": 12702.5466
    },
    {
        "_id": "627deb0d25aa08574849aa64",
        "ask": 68,
        "bid": 60,
        "high": 12924.9844,
        "open": 12850.8893,
        "close": 12849.385,
        "low": 12703.9725
    },
    {
        "_id": "627deb0d2832364935d95c27",
        "ask": 2,
        "bid": 19,
        "high": 12950.2864,
        "open": 12896.9405,
        "close": 12844.3722,
        "low": 12740.5793
    },
    {
        "_id": "627deb0dac22e5759ed19a1a",
        "ask": 1,
        "bid": 64,
        "high": 12918.4343,
        "open": 12875.2214,
        "close": 12807.6485,
        "low": 12777.284
    },
    {
        "_id": "627deb0ddc048dd70e97b99b",
        "ask": 29,
        "bid": 70,
        "high": 12966.3857,
        "open": 12884.3193,
        "close": 12821.2355,
        "low": 12779.4317
    },
    {
        "_id": "627deb0dd2b0eae12bc348e2",
        "ask": 24,
        "bid": 67,
        "high": 12932.9,
        "open": 12884.5525,
        "close": 12826.3378,
        "low": 12775.6129
    },
    {
        "_id": "627deb0d81044df6171acac4",
        "ask": 8,
        "bid": 25,
        "high": 12949.446,
        "open": 12887.4956,
        "close": 12841.9516,
        "low": 12730.0334
    },
    {
        "_id": "627deb0dbfb4ed0408696c80",
        "ask": 56,
        "bid": 81,
        "high": 12999.6764,
        "open": 12872.246,
        "close": 12814.0836,
        "low": 12726.7744
    },
    {
        "_id": "627deb0dc5143bc121f6108c",
        "ask": 100,
        "bid": 31,
        "high": 12972.293,
        "open": 12886.1934,
        "close": 12829.7817,
        "low": 12759.7875
    },
    {
        "_id": "627deb0d26ece21aba0b9b5d",
        "ask": 88,
        "bid": 3,
        "high": 12994.4344,
        "open": 12865.2418,
        "close": 12814.7211,
        "low": 12759.1825
    },
    {
        "_id": "627deb0dc08c285a864a753b",
        "ask": 74,
        "bid": 22,
        "high": 12982.4908,
        "open": 12887.1109,
        "close": 12814.3034,
        "low": 12726.2209
    },
    {
        "_id": "627deb0d7a8dfeb72e0051e2",
        "ask": 27,
        "bid": 47,
        "high": 12993.2781,
        "open": 12873.0417,
        "close": 12843.421,
        "low": 12733.7327
    },
    {
        "_id": "627deb0d7635672e2e2a2a55",
        "ask": 6,
        "bid": 27,
        "high": 12909.0823,
        "open": 12858.5812,
        "close": 12844.9172,
        "low": 12742.7576
    },
    {
        "_id": "627deb0d242763025036ad64",
        "ask": 20,
        "bid": 47,
        "high": 12929.4322,
        "open": 12870.8406,
        "close": 12838.0483,
        "low": 12717.2208
    },
    {
        "_id": "627deb0df7935ee9a2c75fc7",
        "ask": 2,
        "bid": 67,
        "high": 12992.2029,
        "open": 12884.0083,
        "close": 12841.6122,
        "low": 12715.4963
    },
    {
        "_id": "627deb0d728c9d03ac77382f",
        "ask": 73,
        "bid": 55,
        "high": 12930.9113,
        "open": 12866.0195,
        "close": 12820.0066,
        "low": 12733.1442
    },
    {
        "_id": "627deb0dfb377e655f7b00e2",
        "ask": 19,
        "bid": 42,
        "high": 12960.3098,
        "open": 12889.2268,
        "close": 12815.8298,
        "low": 12737.6901
    },
    {
        "_id": "627deb0d58bbaab70a16696c",
        "ask": 65,
        "bid": 11,
        "high": 12972.4984,
        "open": 12882.1184,
        "close": 12824.0693,
        "low": 12746.8516
    },
    {
        "_id": "627deb0d33bb0c1d2488a32d",
        "ask": 81,
        "bid": 3,
        "high": 12954.242,
        "open": 12885.0495,
        "close": 12847.5074,
        "low": 12732.0066
    },
    {
        "_id": "627deb0d3740deccc063877d",
        "ask": 38,
        "bid": 98,
        "high": 12971.0776,
        "open": 12889.4862,
        "close": 12833.6868,
        "low": 12751.1445
    },
    {
        "_id": "627deb0d13f0072c4acb3375",
        "ask": 15,
        "bid": 91,
        "high": 12905.723,
        "open": 12866.3809,
        "close": 12827.7525,
        "low": 12793.9546
    },
    {
        "_id": "627deb0dfd828ea31e78094c",
        "ask": 69,
        "bid": 60,
        "high": 12924.3663,
        "open": 12862.6705,
        "close": 12826.3731,
        "low": 12786.6203
    },
    {
        "_id": "627deb0d3a3876f5e73882ee",
        "ask": 77,
        "bid": 41,
        "high": 12998.5243,
        "open": 12896.5795,
        "close": 12805.1401,
        "low": 12764.7104
    },
    {
        "_id": "627deb0d9ead146dd8fe796a",
        "ask": 70,
        "bid": 45,
        "high": 12950.4937,
        "open": 12889.8683,
        "close": 12801.507,
        "low": 12734.7258
    },
    {
        "_id": "627deb0d596877422870835b",
        "ask": 29,
        "bid": 67,
        "high": 12974.6073,
        "open": 12883.1314,
        "close": 12829.9742,
        "low": 12712.2834
    },
    {
        "_id": "627deb0d5d9a7fe90b0437fb",
        "ask": 35,
        "bid": 12,
        "high": 12935.8856,
        "open": 12892.811,
        "close": 12822.7688,
        "low": 12712.6939
    },
    {
        "_id": "627deb0d788e5a6c953b86bc",
        "ask": 66,
        "bid": 87,
        "high": 12979.0665,
        "open": 12899.4129,
        "close": 12803.0491,
        "low": 12797.684
    },
    {
        "_id": "627deb0d083ce609b7baa1c4",
        "ask": 6,
        "bid": 14,
        "high": 12934.0155,
        "open": 12895.1353,
        "close": 12800.0791,
        "low": 12775.1509
    },
    {
        "_id": "627deb0de93d1b0aa3fad25f",
        "ask": 62,
        "bid": 47,
        "high": 12903.8211,
        "open": 12865.4541,
        "close": 12809.1564,
        "low": 12765.6089
    },
    {
        "_id": "627deb0df2dd5494510ea6a5",
        "ask": 12,
        "bid": 48,
        "high": 12974.8678,
        "open": 12864.3873,
        "close": 12845.084,
        "low": 12747.9152
    },
    {
        "_id": "627deb0d155016085db3c5e7",
        "ask": 4,
        "bid": 70,
        "high": 12995.5155,
        "open": 12860.0588,
        "close": 12812.1304,
        "low": 12758.6584
    },
    {
        "_id": "627deb0d10e2cfe23bb85821",
        "ask": 60,
        "bid": 57,
        "high": 12951.5573,
        "open": 12877.3488,
        "close": 12823.0918,
        "low": 12728.0827
    },
    {
        "_id": "627deb0dbe55682cb5fa4ef3",
        "ask": 33,
        "bid": 9,
        "high": 12942.1879,
        "open": 12871.5224,
        "close": 12822.9331,
        "low": 12741.4558
    },
    {
        "_id": "627deb0db19bb8089d4c5907",
        "ask": 26,
        "bid": 11,
        "high": 12912.6928,
        "open": 12895.7425,
        "close": 12800.8787,
        "low": 12734.7625
    },
    {
        "_id": "627deb0d607b34e41c1a9784",
        "ask": 64,
        "bid": 26,
        "high": 12904.999,
        "open": 12874.4367,
        "close": 12800.3455,
        "low": 12741.315
    },
    {
        "_id": "627deb0d2bb855a95514b04d",
        "ask": 95,
        "bid": 14,
        "high": 12947.8819,
        "open": 12884.9413,
        "close": 12802.1631,
        "low": 12749.4736
    },
    {
        "_id": "627deb0d01c416d0c1db038c",
        "ask": 4,
        "bid": 87,
        "high": 12907.2658,
        "open": 12851.0882,
        "close": 12807.2892,
        "low": 12706.9381
    },
    {
        "_id": "627deb0dc0142294ef94d9aa",
        "ask": 14,
        "bid": 79,
        "high": 12998.685,
        "open": 12880.4514,
        "close": 12819.2599,
        "low": 12721.4389
    },
    {
        "_id": "627deb0d9e61ceed8cc3ab8f",
        "ask": 58,
        "bid": 32,
        "high": 12903.1103,
        "open": 12892.7835,
        "close": 12804.8603,
        "low": 12705.6592
    },
    {
        "_id": "627deb0ded6c6cd124fe9dbb",
        "ask": 32,
        "bid": 64,
        "high": 12929.1979,
        "open": 12893.9015,
        "close": 12803.7583,
        "low": 12778.5311
    },
    {
        "_id": "627deb0d1eff1eed9c6474ec",
        "ask": 100,
        "bid": 78,
        "high": 12915.6692,
        "open": 12874.3712,
        "close": 12839.6979,
        "low": 12749.6341
    },
    {
        "_id": "627deb0d8f8e1f7da9ea5dcf",
        "ask": 2,
        "bid": 30,
        "high": 12906.2689,
        "open": 12892.4446,
        "close": 12802.5961,
        "low": 12775.8579
    },
    {
        "_id": "627deb0dcb67c4f85a9dae71",
        "ask": 1,
        "bid": 64,
        "high": 12939.376,
        "open": 12884.5947,
        "close": 12843.4968,
        "low": 12730.9075
    },
    {
        "_id": "627deb0d5214b015407e8c12",
        "ask": 44,
        "bid": 73,
        "high": 12927.6008,
        "open": 12865.1932,
        "close": 12844.1686,
        "low": 12717.481
    },
    {
        "_id": "627deb0dcc7e6c004c6e031a",
        "ask": 31,
        "bid": 98,
        "high": 12978.59,
        "open": 12859.0008,
        "close": 12819.6837,
        "low": 12738.0964
    },
    {
        "_id": "627deb0da14286a019453cdd",
        "ask": 24,
        "bid": 57,
        "high": 12990.3885,
        "open": 12855.2596,
        "close": 12809.9747,
        "low": 12746.4036
    },
    {
        "_id": "627deb0dca15724686deae97",
        "ask": 68,
        "bid": 40,
        "high": 12978.1118,
        "open": 12853.8893,
        "close": 12838.8094,
        "low": 12796.581
    },
    {
        "_id": "627deb0d25a06927701149d4",
        "ask": 81,
        "bid": 32,
        "high": 12962.4049,
        "open": 12895.8912,
        "close": 12801.8584,
        "low": 12717.0183
    },
    {
        "_id": "627deb0d614e72bcf125275d",
        "ask": 74,
        "bid": 64,
        "high": 12981.7708,
        "open": 12882.5179,
        "close": 12810.6478,
        "low": 12714.8609
    },
    {
        "_id": "627deb0d75f13376d4fb22fb",
        "ask": 79,
        "bid": 56,
        "high": 12988.1575,
        "open": 12869.8476,
        "close": 12840.1501,
        "low": 12712.7317
    },
    {
        "_id": "627deb0d83ec4235636e3c27",
        "ask": 12,
        "bid": 22,
        "high": 12970.3255,
        "open": 12875.9659,
        "close": 12828.5455,
        "low": 12732.4641
    },
    {
        "_id": "627deb0d0c507f15b835cc7f",
        "ask": 49,
        "bid": 20,
        "high": 12971.0602,
        "open": 12867.7881,
        "close": 12835.478,
        "low": 12739.3114
    },
    {
        "_id": "627deb0d745a01744c29dd33",
        "ask": 43,
        "bid": 90,
        "high": 12959.7348,
        "open": 12877.7598,
        "close": 12811.8131,
        "low": 12745.8624
    },
    {
        "_id": "627deb0de5599a8d2ba0ca37",
        "ask": 41,
        "bid": 33,
        "high": 12975.1659,
        "open": 12899.9939,
        "close": 12812.5093,
        "low": 12755.762
    },
    {
        "_id": "627deb0d38b57d76f6ea75a4",
        "ask": 56,
        "bid": 44,
        "high": 12976.7564,
        "open": 12876.3884,
        "close": 12845.1751,
        "low": 12731.9694
    },
    {
        "_id": "627deb0d88b5f41042885d33",
        "ask": 39,
        "bid": 1,
        "high": 12911.2348,
        "open": 12877.2733,
        "close": 12815.3267,
        "low": 12714.0762
    },
    {
        "_id": "627deb0dfbaae1235d8f5ce3",
        "ask": 67,
        "bid": 61,
        "high": 12901.2209,
        "open": 12893.1361,
        "close": 12809.2247,
        "low": 12788.0145
    },
    {
        "_id": "627deb0dee733c164fb06682",
        "ask": 66,
        "bid": 1,
        "high": 12952.5306,
        "open": 12887.1976,
        "close": 12830.9109,
        "low": 12780.3136
    },
    {
        "_id": "627deb0d018ebb5cb049d7cb",
        "ask": 9,
        "bid": 80,
        "high": 12911.8715,
        "open": 12877.5369,
        "close": 12838.6319,
        "low": 12759.2698
    },
    {
        "_id": "627deb0d9e7914e6490548f3",
        "ask": 78,
        "bid": 4,
        "high": 12979.7745,
        "open": 12895.5861,
        "close": 12821.8408,
        "low": 12794.4785
    },
    {
        "_id": "627deb0d782ae4dad5fd9f3d",
        "ask": 54,
        "bid": 1,
        "high": 12942.8667,
        "open": 12896.9546,
        "close": 12845.035,
        "low": 12755.2522
    },
    {
        "_id": "627deb0d06bd4a22d3ff9f16",
        "ask": 48,
        "bid": 57,
        "high": 12922.6779,
        "open": 12894.426,
        "close": 12820.3008,
        "low": 12779.8583
    },
    {
        "_id": "627deb0d73820d4345f1c504",
        "ask": 98,
        "bid": 50,
        "high": 12989.819,
        "open": 12853.6154,
        "close": 12818.516,
        "low": 12714.1373
    },
    {
        "_id": "627deb0da0690fa1d810633a",
        "ask": 100,
        "bid": 72,
        "high": 12939.3453,
        "open": 12866.4704,
        "close": 12820.9232,
        "low": 12755.1597
    },
    {
        "_id": "627deb0d2f8a9423f762b0ac",
        "ask": 31,
        "bid": 97,
        "high": 12920.6584,
        "open": 12851.2776,
        "close": 12812.5715,
        "low": 12719.0415
    },
    {
        "_id": "627deb0d94aeda4109dbb9e1",
        "ask": 13,
        "bid": 56,
        "high": 12945.5832,
        "open": 12858.1228,
        "close": 12806.7984,
        "low": 12798.6045
    },
    {
        "_id": "627deb0da59089c3f8bba7c2",
        "ask": 10,
        "bid": 89,
        "high": 12905.0197,
        "open": 12890.3588,
        "close": 12831.6616,
        "low": 12717.529
    },
    {
        "_id": "627deb0d9393555f5386098c",
        "ask": 37,
        "bid": 34,
        "high": 12907.5469,
        "open": 12879.3002,
        "close": 12839.4404,
        "low": 12735.1527
    },
    {
        "_id": "627deb0dc66fed145e8d59db",
        "ask": 72,
        "bid": 2,
        "high": 12954.8504,
        "open": 12877.1774,
        "close": 12807.3603,
        "low": 12780.7713
    },
    {
        "_id": "627deb0d76fd74c3daa66b76",
        "ask": 53,
        "bid": 11,
        "high": 12914.3239,
        "open": 12866.179,
        "close": 12814.7754,
        "low": 12767.0439
    },
    {
        "_id": "627deb0deef7392dab508d03",
        "ask": 32,
        "bid": 89,
        "high": 12981.1818,
        "open": 12851.123,
        "close": 12813.865,
        "low": 12780.2359
    },
    {
        "_id": "627deb0d945abb2026fde027",
        "ask": 39,
        "bid": 66,
        "high": 12927.095,
        "open": 12877.7138,
        "close": 12830.3278,
        "low": 12744.4209
    },
    {
        "_id": "627deb0d5f257374581b61d0",
        "ask": 10,
        "bid": 71,
        "high": 12919.4585,
        "open": 12894.3367,
        "close": 12822.9859,
        "low": 12722.7378
    },
    {
        "_id": "627deb0db378eed0f10693b6",
        "ask": 38,
        "bid": 77,
        "high": 12985.3551,
        "open": 12899.598,
        "close": 12814.4889,
        "low": 12716.2351
    },
    {
        "_id": "627deb0dc9a93b5f375354d6",
        "ask": 8,
        "bid": 66,
        "high": 12981.9776,
        "open": 12858.3672,
        "close": 12805.9938,
        "low": 12797.9413
    },
    {
        "_id": "627deb0dbeea1c93cf17287f",
        "ask": 92,
        "bid": 4,
        "high": 12966.4879,
        "open": 12878.4822,
        "close": 12831.6049,
        "low": 12730.5038
    },
    {
        "_id": "627deb0d2b5f99f71523a4b0",
        "ask": 48,
        "bid": 60,
        "high": 12920.5126,
        "open": 12885.9339,
        "close": 12819.7941,
        "low": 12721.2973
    },
    {
        "_id": "627deb0d0b880d7d0eff9bba",
        "ask": 13,
        "bid": 70,
        "high": 12920.3879,
        "open": 12898.8875,
        "close": 12833.5958,
        "low": 12779.51
    },
    {
        "_id": "627deb0dcfb19d116da3a779",
        "ask": 93,
        "bid": 33,
        "high": 12934.9945,
        "open": 12884.8929,
        "close": 12848.4514,
        "low": 12799.1762
    },
    {
        "_id": "627deb0db36d2abbf425b4e1",
        "ask": 15,
        "bid": 95,
        "high": 12979.6776,
        "open": 12896.716,
        "close": 12816.5752,
        "low": 12778.7516
    },
    {
        "_id": "627deb0d07ea0c5ac8d6e11d",
        "ask": 54,
        "bid": 70,
        "high": 12939.0293,
        "open": 12860.7782,
        "close": 12814.0706,
        "low": 12778.4929
    },
    {
        "_id": "627deb0df280da1377c65d4e",
        "ask": 66,
        "bid": 26,
        "high": 12941.818,
        "open": 12873.8103,
        "close": 12814.633,
        "low": 12742.105
    },
    {
        "_id": "627deb0dce0a9914d6ddc4ab",
        "ask": 69,
        "bid": 56,
        "high": 12911.9478,
        "open": 12856.7096,
        "close": 12825.4515,
        "low": 12768.7959
    },
    {
        "_id": "627deb0d18874e740ae76e36",
        "ask": 84,
        "bid": 40,
        "high": 12988.5096,
        "open": 12877.1248,
        "close": 12805.7217,
        "low": 12719.8275
    },
    {
        "_id": "627deb0d6cf3fcfc1a1531fc",
        "ask": 37,
        "bid": 6,
        "high": 12980.9708,
        "open": 12861.9166,
        "close": 12843.5117,
        "low": 12773.2551
    },
    {
        "_id": "627deb0d39190970903cc022",
        "ask": 100,
        "bid": 65,
        "high": 12955.9663,
        "open": 12893.2583,
        "close": 12802.1421,
        "low": 12764.7717
    },
    {
        "_id": "627deb0df5ee84824a7bf3a6",
        "ask": 54,
        "bid": 70,
        "high": 12992.1624,
        "open": 12897.6905,
        "close": 12817.9981,
        "low": 12760.6502
    },
    {
        "_id": "627deb0d9e331a58bce4e1f8",
        "ask": 7,
        "bid": 95,
        "high": 12994.4459,
        "open": 12886.569,
        "close": 12805.8401,
        "low": 12704.6287
    },
    {
        "_id": "627deb0db0e01170fdc6389f",
        "ask": 52,
        "bid": 97,
        "high": 12905.1568,
        "open": 12870.4776,
        "close": 12843.8237,
        "low": 12711.7971
    },
    {
        "_id": "627deb0ded69d3c20d8bf30c",
        "ask": 55,
        "bid": 0,
        "high": 12940.0918,
        "open": 12859.2871,
        "close": 12805.3486,
        "low": 12725.5859
    },
    {
        "_id": "627deb0ddce21266bfadcb41",
        "ask": 1,
        "bid": 77,
        "high": 12985.7285,
        "open": 12886.2799,
        "close": 12823.3414,
        "low": 12779.8217
    },
    {
        "_id": "627deb0dadd824eb16196592",
        "ask": 53,
        "bid": 23,
        "high": 12951.5652,
        "open": 12861.5916,
        "close": 12827.2916,
        "low": 12746.2843
    },
    {
        "_id": "627deb0d79c7c2f5851f1595",
        "ask": 37,
        "bid": 68,
        "high": 12909.5915,
        "open": 12892.6181,
        "close": 12824.2451,
        "low": 12788.0126
    },
    {
        "_id": "627deb0de226920ced13b1bb",
        "ask": 82,
        "bid": 27,
        "high": 12912.0237,
        "open": 12860.8558,
        "close": 12830.7868,
        "low": 12703.0021
    },
    {
        "_id": "627deb0defcb8a06d3e796e5",
        "ask": 82,
        "bid": 31,
        "high": 12949.8314,
        "open": 12897.8758,
        "close": 12814.8949,
        "low": 12721.7381
    },
    {
        "_id": "627deb0df022c06996dc51ea",
        "ask": 52,
        "bid": 0,
        "high": 12950.6554,
        "open": 12860.9308,
        "close": 12800.8755,
        "low": 12733.0889
    },
    {
        "_id": "627deb0d296b1aff4f5006c8",
        "ask": 27,
        "bid": 27,
        "high": 12918.9488,
        "open": 12886.1172,
        "close": 12802.9096,
        "low": 12764.8383
    },
    {
        "_id": "627deb0d6b3d4f142da8a2dd",
        "ask": 33,
        "bid": 52,
        "high": 12992.7822,
        "open": 12891.2688,
        "close": 12846.196,
        "low": 12743.4979
    },
    {
        "_id": "627deb0d6df5a1a50384ac23",
        "ask": 91,
        "bid": 17,
        "high": 12979.9983,
        "open": 12874.1827,
        "close": 12815.3816,
        "low": 12706.2165
    },
    {
        "_id": "627deb0d4a1a49128bd19b16",
        "ask": 70,
        "bid": 81,
        "high": 12922.0754,
        "open": 12878.8202,
        "close": 12836.248,
        "low": 12768.7339
    },
    {
        "_id": "627deb0d09b155851b74818c",
        "ask": 22,
        "bid": 4,
        "high": 12949.9845,
        "open": 12890.3432,
        "close": 12843.789,
        "low": 12795.5735
    },
    {
        "_id": "627deb0d667b4ad3f8972ce7",
        "ask": 52,
        "bid": 21,
        "high": 12948.7165,
        "open": 12880.6299,
        "close": 12840.5629,
        "low": 12751.9086
    },
    {
        "_id": "627deb0d2c51b0298049cf80",
        "ask": 69,
        "bid": 56,
        "high": 12948.5876,
        "open": 12878.5867,
        "close": 12817.6512,
        "low": 12711.0833
    },
    {
        "_id": "627deb0d0f18f29e0bc2b2d9",
        "ask": 53,
        "bid": 30,
        "high": 12979.3032,
        "open": 12874.0405,
        "close": 12821.6944,
        "low": 12798.7907
    },
    {
        "_id": "627deb0dc99d562276ed1ee4",
        "ask": 10,
        "bid": 73,
        "high": 12934.7826,
        "open": 12867.7201,
        "close": 12812.6554,
        "low": 12757.9871
    },
    {
        "_id": "627deb0d3822c41faf578cd4",
        "ask": 76,
        "bid": 96,
        "high": 12985.9737,
        "open": 12894.0491,
        "close": 12820.6001,
        "low": 12766.2038
    },
    {
        "_id": "627deb0dacf5fc391cf69beb",
        "ask": 56,
        "bid": 25,
        "high": 12931.2861,
        "open": 12851.2226,
        "close": 12841.5221,
        "low": 12704.788
    },
    {
        "_id": "627deb0de28161b5398b9639",
        "ask": 10,
        "bid": 28,
        "high": 12944.3759,
        "open": 12859.4915,
        "close": 12848.5139,
        "low": 12767.5041
    },
    {
        "_id": "627deb0d9cd19dc6962f8d7e",
        "ask": 59,
        "bid": 95,
        "high": 12972.6497,
        "open": 12876.2945,
        "close": 12818.9876,
        "low": 12771.6732
    },
    {
        "_id": "627deb0d6ab01bc977785e7b",
        "ask": 94,
        "bid": 29,
        "high": 12943.6677,
        "open": 12884.8696,
        "close": 12807.9135,
        "low": 12728.3096
    },
    {
        "_id": "627deb0d998e6f473b3a244e",
        "ask": 41,
        "bid": 47,
        "high": 12993.6638,
        "open": 12860.0828,
        "close": 12816.1116,
        "low": 12701.1316
    },
    {
        "_id": "627deb0dd1f0d0985f82df8f",
        "ask": 81,
        "bid": 47,
        "high": 12974.2918,
        "open": 12853.5145,
        "close": 12813.6076,
        "low": 12783.029
    },
    {
        "_id": "627deb0d7f4cc71a7a28e848",
        "ask": 80,
        "bid": 13,
        "high": 12999.5153,
        "open": 12873.8641,
        "close": 12840.8413,
        "low": 12741.8027
    },
    {
        "_id": "627deb0d4bcd0061b1b7220a",
        "ask": 36,
        "bid": 44,
        "high": 12953.0397,
        "open": 12892.3208,
        "close": 12819.6316,
        "low": 12777.5265
    },
    {
        "_id": "627deb0d8a6e8cf597c70f3a",
        "ask": 66,
        "bid": 89,
        "high": 12907.912,
        "open": 12859.7004,
        "close": 12823.4591,
        "low": 12776.9098
    },
    {
        "_id": "627deb0d8298ef9dbd44dc8d",
        "ask": 32,
        "bid": 74,
        "high": 12996.6147,
        "open": 12881.8992,
        "close": 12800.591,
        "low": 12703.977
    },
    {
        "_id": "627deb0d3f60bed0d32f0261",
        "ask": 24,
        "bid": 47,
        "high": 12904.5992,
        "open": 12858.3546,
        "close": 12804.4522,
        "low": 12731.7733
    },
    {
        "_id": "627deb0da23e30227238a33c",
        "ask": 90,
        "bid": 90,
        "high": 12990.1175,
        "open": 12888.724,
        "close": 12830.9719,
        "low": 12749.5985
    },
    {
        "_id": "627deb0db5a990d3ceeb1993",
        "ask": 76,
        "bid": 65,
        "high": 12941.7269,
        "open": 12879.0052,
        "close": 12839.7346,
        "low": 12794.2515
    },
    {
        "_id": "627deb0d0e79dbdff3b755ba",
        "ask": 30,
        "bid": 41,
        "high": 12968.4888,
        "open": 12866.2809,
        "close": 12825.3157,
        "low": 12716.4134
    },
    {
        "_id": "627deb0d78ec71819dc8105b",
        "ask": 12,
        "bid": 79,
        "high": 12990.2775,
        "open": 12862.4426,
        "close": 12818.7663,
        "low": 12758.5648
    },
    {
        "_id": "627deb0d3639bbddac0aa141",
        "ask": 57,
        "bid": 69,
        "high": 12916.1977,
        "open": 12890.9495,
        "close": 12810.8591,
        "low": 12775.8469
    },
    {
        "_id": "627deb0da1cd2505dc89cd0f",
        "ask": 76,
        "bid": 30,
        "high": 12905.4913,
        "open": 12853.6549,
        "close": 12842.9505,
        "low": 12772.7217
    },
    {
        "_id": "627deb0dfacfa0f93a334755",
        "ask": 29,
        "bid": 52,
        "high": 12988.8105,
        "open": 12898.6886,
        "close": 12807.2401,
        "low": 12782.5376
    },
    {
        "_id": "627deb0d3ba8338cd3081670",
        "ask": 36,
        "bid": 26,
        "high": 12974.7025,
        "open": 12895.8075,
        "close": 12840.9207,
        "low": 12701.6657
    },
    {
        "_id": "627deb0d70344bc486df1a20",
        "ask": 50,
        "bid": 74,
        "high": 12968.5172,
        "open": 12857.3641,
        "close": 12807.3344,
        "low": 12757.948
    },
    {
        "_id": "627deb0d597ab664546e90d1",
        "ask": 88,
        "bid": 17,
        "high": 12923.6986,
        "open": 12859.933,
        "close": 12845.3682,
        "low": 12746.3818
    },
    {
        "_id": "627deb0d3bd85386166bf45b",
        "ask": 62,
        "bid": 43,
        "high": 12931.3115,
        "open": 12887.7691,
        "close": 12824.9816,
        "low": 12708.1676
    },
    {
        "_id": "627deb0d75761bd74a5e2327",
        "ask": 64,
        "bid": 47,
        "high": 12998.7354,
        "open": 12898.9813,
        "close": 12822.907,
        "low": 12784.764
    },
    {
        "_id": "627deb0dbbea6336651a2018",
        "ask": 3,
        "bid": 27,
        "high": 12999.0951,
        "open": 12859.3609,
        "close": 12816.0954,
        "low": 12738.9216
    },
    {
        "_id": "627deb0de8618f202fec1696",
        "ask": 86,
        "bid": 19,
        "high": 12972.536,
        "open": 12890.5794,
        "close": 12838.1688,
        "low": 12729.4683
    },
    {
        "_id": "627deb0d35f317ce75b63903",
        "ask": 28,
        "bid": 56,
        "high": 12917.0044,
        "open": 12851.8452,
        "close": 12823.6776,
        "low": 12776.8735
    },
    {
        "_id": "627deb0d439e5485e30bca8c",
        "ask": 9,
        "bid": 67,
        "high": 12988.1997,
        "open": 12872.6695,
        "close": 12826.3419,
        "low": 12772.3697
    },
    {
        "_id": "627deb0d9e7836ed35599ce6",
        "ask": 36,
        "bid": 90,
        "high": 12996.8678,
        "open": 12886.72,
        "close": 12844.4773,
        "low": 12741.2096
    },
    {
        "_id": "627deb0d2ec264a52f6d6d45",
        "ask": 47,
        "bid": 19,
        "high": 12903.5411,
        "open": 12885.5418,
        "close": 12831.6423,
        "low": 12701.0035
    },
    {
        "_id": "627deb0d39404ad6a7866223",
        "ask": 51,
        "bid": 45,
        "high": 12909.9052,
        "open": 12870.2039,
        "close": 12835.0713,
        "low": 12773.9027
    },
    {
        "_id": "627deb0dcfa064c3fd2a00bb",
        "ask": 40,
        "bid": 32,
        "high": 12954.7544,
        "open": 12895.3224,
        "close": 12818.9889,
        "low": 12728.9717
    },
    {
        "_id": "627deb0d73e524f3b0095554",
        "ask": 7,
        "bid": 63,
        "high": 12917.4253,
        "open": 12860.2437,
        "close": 12809.6987,
        "low": 12751.3747
    },
    {
        "_id": "627deb0d5262e592c7093d8c",
        "ask": 83,
        "bid": 85,
        "high": 12930.144,
        "open": 12893.2652,
        "close": 12822.8169,
        "low": 12768.1858
    },
    {
        "_id": "627deb0d04d4a0addc25ab69",
        "ask": 79,
        "bid": 62,
        "high": 12987.7556,
        "open": 12881.4137,
        "close": 12803.7666,
        "low": 12721.6507
    },
    {
        "_id": "627deb0d89bab67e2f947a64",
        "ask": 63,
        "bid": 79,
        "high": 12996.8661,
        "open": 12889.8683,
        "close": 12809.2496,
        "low": 12769.1722
    },
    {
        "_id": "627deb0d43d04a4965d0f827",
        "ask": 19,
        "bid": 89,
        "high": 12992.7155,
        "open": 12882.6184,
        "close": 12807.0226,
        "low": 12729.8752
    },
    {
        "_id": "627deb0d875f5d2bb6862679",
        "ask": 48,
        "bid": 96,
        "high": 12985.1011,
        "open": 12895.0048,
        "close": 12842.3651,
        "low": 12730.4204
    },
    {
        "_id": "627deb0d6460f2c3c836b62c",
        "ask": 47,
        "bid": 80,
        "high": 12966.1974,
        "open": 12879.1723,
        "close": 12827.452,
        "low": 12788.0875
    },
    {
        "_id": "627deb0d4926539f21585da4",
        "ask": 85,
        "bid": 91,
        "high": 12957.4991,
        "open": 12863.4946,
        "close": 12841.9532,
        "low": 12747.13
    },
    {
        "_id": "627deb0dddd6c4bff23f0385",
        "ask": 22,
        "bid": 9,
        "high": 12923.2083,
        "open": 12873.3644,
        "close": 12847.8248,
        "low": 12788.32
    },
    {
        "_id": "627deb0d198f824776becfdd",
        "ask": 36,
        "bid": 93,
        "high": 12911.9917,
        "open": 12896.7292,
        "close": 12842.9351,
        "low": 12729.7908
    },
    {
        "_id": "627deb0df48c6a6c4e357dc7",
        "ask": 29,
        "bid": 1,
        "high": 12907.1829,
        "open": 12854.9588,
        "close": 12838.111,
        "low": 12726.1452
    },
    {
        "_id": "627deb0d240f0c9281e6d199",
        "ask": 34,
        "bid": 35,
        "high": 12999.5667,
        "open": 12888.0572,
        "close": 12849.6401,
        "low": 12710.9621
    },
    {
        "_id": "627deb0d56eda34d8e218f46",
        "ask": 52,
        "bid": 0,
        "high": 12975.6083,
        "open": 12891.2378,
        "close": 12824.9727,
        "low": 12781.9251
    },
    {
        "_id": "627deb0d807737cc14f39983",
        "ask": 91,
        "bid": 69,
        "high": 12938.9339,
        "open": 12895.7729,
        "close": 12848.9607,
        "low": 12721.832
    },
    {
        "_id": "627deb0db2e1095507259c3e",
        "ask": 16,
        "bid": 92,
        "high": 12948.3328,
        "open": 12898.15,
        "close": 12849.5985,
        "low": 12738.0343
    },
    {
        "_id": "627deb0d25551d789238a4f7",
        "ask": 96,
        "bid": 12,
        "high": 12976.0633,
        "open": 12881.2165,
        "close": 12836.2098,
        "low": 12712.4439
    },
    {
        "_id": "627deb0d26c3f74901d06004",
        "ask": 75,
        "bid": 93,
        "high": 12951.9174,
        "open": 12865.4795,
        "close": 12800.8775,
        "low": 12740.2876
    },
    {
        "_id": "627deb0d0f347f7d61b19489",
        "ask": 36,
        "bid": 30,
        "high": 12955.3833,
        "open": 12882.4944,
        "close": 12844.4528,
        "low": 12784.4147
    },
    {
        "_id": "627deb0d22c759352057d4a5",
        "ask": 21,
        "bid": 93,
        "high": 12919.7877,
        "open": 12873.9403,
        "close": 12836.6435,
        "low": 12753.8828
    },
    {
        "_id": "627deb0dd604f69668e03f96",
        "ask": 66,
        "bid": 46,
        "high": 12944.125,
        "open": 12877.5384,
        "close": 12821.8158,
        "low": 12795.2104
    },
    {
        "_id": "627deb0d7d46ed5f9eb7823c",
        "ask": 19,
        "bid": 91,
        "high": 12941.7656,
        "open": 12861.1206,
        "close": 12841.4964,
        "low": 12754.4174
    },
    {
        "_id": "627deb0dd0501812a96d4aa3",
        "ask": 51,
        "bid": 11,
        "high": 12978.3563,
        "open": 12867.789,
        "close": 12811.5311,
        "low": 12773.8037
    },
    {
        "_id": "627deb0d25694666abc76e5f",
        "ask": 74,
        "bid": 19,
        "high": 12987.7781,
        "open": 12863.6807,
        "close": 12819.6399,
        "low": 12712.6224
    },
    {
        "_id": "627deb0dc862df0f4202d894",
        "ask": 84,
        "bid": 63,
        "high": 12977.4201,
        "open": 12868.0545,
        "close": 12831.8813,
        "low": 12722.2068
    },
    {
        "_id": "627deb0d7a72406a233134ce",
        "ask": 38,
        "bid": 16,
        "high": 12969.7867,
        "open": 12873.5917,
        "close": 12846.6398,
        "low": 12734.364
    },
    {
        "_id": "627deb0de9ccfe922f87c351",
        "ask": 38,
        "bid": 16,
        "high": 12918.752,
        "open": 12881.6038,
        "close": 12828.0296,
        "low": 12721.6706
    },
    {
        "_id": "627deb0d751e9d38cdb8f5d4",
        "ask": 27,
        "bid": 67,
        "high": 12944.6909,
        "open": 12882.5514,
        "close": 12849.453,
        "low": 12722.2071
    },
    {
        "_id": "627deb0d28999db15b93bc86",
        "ask": 60,
        "bid": 84,
        "high": 12976.9856,
        "open": 12866.9119,
        "close": 12807.9009,
        "low": 12704.9878
    },
    {
        "_id": "627deb0d828f1cef7fb27fa0",
        "ask": 44,
        "bid": 13,
        "high": 12933.5201,
        "open": 12851.4615,
        "close": 12833.6628,
        "low": 12768.4059
    },
    {
        "_id": "627deb0d260a0066218b1bbb",
        "ask": 99,
        "bid": 11,
        "high": 12946.3398,
        "open": 12856.2867,
        "close": 12833.2399,
        "low": 12789.8675
    },
    {
        "_id": "627deb0dec357ab203b4ee6a",
        "ask": 23,
        "bid": 69,
        "high": 12908.8826,
        "open": 12853.0232,
        "close": 12835.5693,
        "low": 12724.6641
    },
    {
        "_id": "627deb0d415b215d23303d6f",
        "ask": 73,
        "bid": 1,
        "high": 12994.7786,
        "open": 12854.0926,
        "close": 12806.897,
        "low": 12767.8191
    },
    {
        "_id": "627deb0ddcd73ae1bda04613",
        "ask": 76,
        "bid": 82,
        "high": 12976.8221,
        "open": 12889.0728,
        "close": 12832.6669,
        "low": 12763.204
    },
    {
        "_id": "627deb0d553e5c2cee06eaed",
        "ask": 20,
        "bid": 32,
        "high": 12902.1514,
        "open": 12856.0138,
        "close": 12827.135,
        "low": 12744.1531
    },
    {
        "_id": "627deb0da6f9d11a50877862",
        "ask": 71,
        "bid": 2,
        "high": 12920.4634,
        "open": 12877.6381,
        "close": 12803.2559,
        "low": 12772.7798
    },
    {
        "_id": "627deb0d8d0798950be59995",
        "ask": 82,
        "bid": 45,
        "high": 12930.1858,
        "open": 12875.415,
        "close": 12810.9266,
        "low": 12702.3751
    },
    {
        "_id": "627deb0d48fda94a454e534b",
        "ask": 0,
        "bid": 61,
        "high": 12917.1142,
        "open": 12893.071,
        "close": 12804.1828,
        "low": 12735.9299
    },
    {
        "_id": "627deb0d188cd2afd62b1110",
        "ask": 66,
        "bid": 55,
        "high": 12959.8662,
        "open": 12868.7354,
        "close": 12838.4486,
        "low": 12718.3514
    },
    {
        "_id": "627deb0d1698694d7ea907f2",
        "ask": 2,
        "bid": 82,
        "high": 12934.8574,
        "open": 12890.8486,
        "close": 12821.1974,
        "low": 12773.582
    },
    {
        "_id": "627deb0de66fe34ac5a32593",
        "ask": 89,
        "bid": 4,
        "high": 12945.925,
        "open": 12874.4564,
        "close": 12847.5963,
        "low": 12752.0029
    },
    {
        "_id": "627deb0dc413da4aba0c1f91",
        "ask": 71,
        "bid": 65,
        "high": 12988.5316,
        "open": 12858.9329,
        "close": 12825.9517,
        "low": 12771.8686
    },
    {
        "_id": "627deb0d1ce1cf6cfc5afd07",
        "ask": 0,
        "bid": 35,
        "high": 12904.2067,
        "open": 12854.9481,
        "close": 12800.0665,
        "low": 12734.4985
    },
    {
        "_id": "627deb0d09a7601f5c607a43",
        "ask": 72,
        "bid": 73,
        "high": 12908.8467,
        "open": 12868.864,
        "close": 12825.8941,
        "low": 12796.9781
    },
    {
        "_id": "627deb0ddc3fc30419af2886",
        "ask": 10,
        "bid": 25,
        "high": 12900.5494,
        "open": 12886.2478,
        "close": 12842.5335,
        "low": 12761.755
    },
    {
        "_id": "627deb0d6612b99cf7815fd1",
        "ask": 77,
        "bid": 90,
        "high": 12952.642,
        "open": 12882.1707,
        "close": 12805.7688,
        "low": 12774.3437
    },
    {
        "_id": "627deb0daace91a6ad4ab736",
        "ask": 17,
        "bid": 17,
        "high": 12904.1657,
        "open": 12856.8164,
        "close": 12800.7233,
        "low": 12710.7733
    },
    {
        "_id": "627deb0d690df415a43e4efe",
        "ask": 64,
        "bid": 48,
        "high": 12929.265,
        "open": 12875.1633,
        "close": 12816.7374,
        "low": 12760.3752
    },
    {
        "_id": "627deb0d8ce3b38c10702adb",
        "ask": 68,
        "bid": 50,
        "high": 12903.9451,
        "open": 12894.0609,
        "close": 12820.0502,
        "low": 12747.7027
    },
    {
        "_id": "627deb0d28a57971f9d5b009",
        "ask": 50,
        "bid": 70,
        "high": 12945.3008,
        "open": 12882.7429,
        "close": 12841.2162,
        "low": 12780.2979
    },
    {
        "_id": "627deb0d49614812afc2488b",
        "ask": 95,
        "bid": 28,
        "high": 12988.751,
        "open": 12850.446,
        "close": 12831.0694,
        "low": 12748.6375
    },
    {
        "_id": "627deb0d8bc81a7b219b567b",
        "ask": 16,
        "bid": 79,
        "high": 12977.0816,
        "open": 12876.061,
        "close": 12806.4852,
        "low": 12749.5991
    },
    {
        "_id": "627deb0df6095c0b5c2a1edc",
        "ask": 5,
        "bid": 39,
        "high": 12929.6475,
        "open": 12865.9505,
        "close": 12837.311,
        "low": 12741.4668
    },
    {
        "_id": "627deb0dc2095eacfa2c37ba",
        "ask": 88,
        "bid": 26,
        "high": 12976.6054,
        "open": 12889.5691,
        "close": 12811.0001,
        "low": 12790.249
    },
    {
        "_id": "627deb0dc1928d65768bea46",
        "ask": 86,
        "bid": 97,
        "high": 12991.1833,
        "open": 12866.8835,
        "close": 12829.4938,
        "low": 12716.9173
    },
    {
        "_id": "627deb0de2977a5d6b7779cc",
        "ask": 48,
        "bid": 24,
        "high": 12989.2775,
        "open": 12894.661,
        "close": 12826.7998,
        "low": 12756.6782
    },
    {
        "_id": "627deb0d73e74cc5143bf9a0",
        "ask": 75,
        "bid": 47,
        "high": 12986.5121,
        "open": 12891.3357,
        "close": 12820.4998,
        "low": 12738.0858
    },
    {
        "_id": "627deb0d934f1eb2ec76e92f",
        "ask": 4,
        "bid": 18,
        "high": 12997.9919,
        "open": 12887.3489,
        "close": 12818.4276,
        "low": 12751.3472
    },
    {
        "_id": "627deb0df6cc94ac312bc766",
        "ask": 83,
        "bid": 4,
        "high": 12956.5398,
        "open": 12886.2494,
        "close": 12811.2151,
        "low": 12739.4672
    },
    {
        "_id": "627deb0dfb43d31f564de2b8",
        "ask": 76,
        "bid": 21,
        "high": 12951.5529,
        "open": 12852.3249,
        "close": 12836.9119,
        "low": 12723.3721
    },
    {
        "_id": "627deb0d11dd9cdf17e6a440",
        "ask": 66,
        "bid": 6,
        "high": 12997.1831,
        "open": 12870.1059,
        "close": 12846.679,
        "low": 12779.8923
    },
    {
        "_id": "627deb0d0ed56c442abd16eb",
        "ask": 60,
        "bid": 43,
        "high": 12904.6279,
        "open": 12884.5361,
        "close": 12844.4191,
        "low": 12730.797
    },
    {
        "_id": "627deb0de33d4f8af4a79c8c",
        "ask": 42,
        "bid": 11,
        "high": 12988.524,
        "open": 12874.0029,
        "close": 12842.5367,
        "low": 12749.9082
    },
    {
        "_id": "627deb0daa8f3eb94f6bdc94",
        "ask": 58,
        "bid": 58,
        "high": 12974.3579,
        "open": 12859.7774,
        "close": 12801.9329,
        "low": 12763.9359
    },
    {
        "_id": "627deb0df1eff9e4684877dd",
        "ask": 63,
        "bid": 48,
        "high": 12928.3722,
        "open": 12866.4744,
        "close": 12817.0907,
        "low": 12722.1844
    },
    {
        "_id": "627deb0d839d4fb1c03a9ea1",
        "ask": 60,
        "bid": 38,
        "high": 12934.3441,
        "open": 12888.7476,
        "close": 12833.0585,
        "low": 12742.5065
    },
    {
        "_id": "627deb0de5d7e16d76ae285f",
        "ask": 72,
        "bid": 41,
        "high": 12931.3407,
        "open": 12873.1797,
        "close": 12805.4054,
        "low": 12752.1808
    },
    {
        "_id": "627deb0da572eb7e5ad5c593",
        "ask": 30,
        "bid": 42,
        "high": 12988.5204,
        "open": 12856.4173,
        "close": 12829.3109,
        "low": 12740.0975
    },
    {
        "_id": "627deb0d94d23b19f68c16e5",
        "ask": 28,
        "bid": 66,
        "high": 12906.9281,
        "open": 12852.2217,
        "close": 12809.3272,
        "low": 12713.8961
    },
    {
        "_id": "627deb0dcee44e5e9e308f69",
        "ask": 58,
        "bid": 54,
        "high": 12998.0568,
        "open": 12857.2936,
        "close": 12840.3316,
        "low": 12753.4614
    },
    {
        "_id": "627deb0d415d5651df98c551",
        "ask": 3,
        "bid": 18,
        "high": 12908.6901,
        "open": 12884.824,
        "close": 12843.6237,
        "low": 12703.8755
    },
    {
        "_id": "627deb0d0bfd83e7deba56aa",
        "ask": 5,
        "bid": 79,
        "high": 12925.0422,
        "open": 12898.6819,
        "close": 12814.7193,
        "low": 12797.7833
    },
    {
        "_id": "627deb0d212c47df0b92994f",
        "ask": 62,
        "bid": 32,
        "high": 12952.3761,
        "open": 12853.2996,
        "close": 12838.0379,
        "low": 12723.5621
    },
    {
        "_id": "627deb0df950ae2d6eea0dd1",
        "ask": 39,
        "bid": 95,
        "high": 12924.8213,
        "open": 12854.4384,
        "close": 12809.1287,
        "low": 12710.6137
    },
    {
        "_id": "627deb0d81b9f19e65bcf036",
        "ask": 12,
        "bid": 12,
        "high": 12983.386,
        "open": 12861.651,
        "close": 12810.2448,
        "low": 12769.335
    },
    {
        "_id": "627deb0d55d0d283aeadec3d",
        "ask": 58,
        "bid": 44,
        "high": 12930.7085,
        "open": 12898.3396,
        "close": 12831.0896,
        "low": 12716.7538
    },
    {
        "_id": "627deb0d29e56c0b5f6759d5",
        "ask": 30,
        "bid": 77,
        "high": 12960.7996,
        "open": 12854.2219,
        "close": 12837.3391,
        "low": 12742.4112
    },
    {
        "_id": "627deb0d0096658b7822b297",
        "ask": 43,
        "bid": 85,
        "high": 12963.156,
        "open": 12862.0981,
        "close": 12813.5704,
        "low": 12773.0905
    },
    {
        "_id": "627deb0d3fd05c552a7e6741",
        "ask": 61,
        "bid": 12,
        "high": 12946.3892,
        "open": 12870.2532,
        "close": 12837.816,
        "low": 12747.8364
    },
    {
        "_id": "627deb0d490e9c442511ee39",
        "ask": 90,
        "bid": 44,
        "high": 12995.9162,
        "open": 12866.7988,
        "close": 12840.6211,
        "low": 12711.7075
    },
    {
        "_id": "627deb0dd840730fc105f593",
        "ask": 58,
        "bid": 6,
        "high": 12964.8227,
        "open": 12895.0642,
        "close": 12800.5749,
        "low": 12712.8217
    },
    {
        "_id": "627deb0d97095f02afac4351",
        "ask": 7,
        "bid": 14,
        "high": 12976.5672,
        "open": 12864.121,
        "close": 12816.7679,
        "low": 12708.2042
    },
    {
        "_id": "627deb0d417d1f9339455ef7",
        "ask": 5,
        "bid": 62,
        "high": 12910.5601,
        "open": 12856.6302,
        "close": 12816.8481,
        "low": 12795.5835
    },
    {
        "_id": "627deb0d09154555342280c8",
        "ask": 20,
        "bid": 20,
        "high": 12943.3041,
        "open": 12864.0377,
        "close": 12845.2079,
        "low": 12735.465
    },
    {
        "_id": "627deb0da8de9ec1f03e05da",
        "ask": 87,
        "bid": 58,
        "high": 12931.1919,
        "open": 12872.5974,
        "close": 12849.7849,
        "low": 12702.8473
    },
    {
        "_id": "627deb0d704d96b3f904f6de",
        "ask": 0,
        "bid": 74,
        "high": 12987.9665,
        "open": 12855.0189,
        "close": 12800.5525,
        "low": 12746.4868
    },
    {
        "_id": "627deb0d9b7fcb33d5b43239",
        "ask": 88,
        "bid": 23,
        "high": 12946.1117,
        "open": 12885.5105,
        "close": 12836.1577,
        "low": 12781.7783
    },
    {
        "_id": "627deb0df9564eaed8e362de",
        "ask": 59,
        "bid": 59,
        "high": 12934.139,
        "open": 12861.296,
        "close": 12821.8432,
        "low": 12796.1442
    },
    {
        "_id": "627deb0d0f092f5a94028b2b",
        "ask": 59,
        "bid": 80,
        "high": 12991.0935,
        "open": 12863.6299,
        "close": 12830.3411,
        "low": 12707.0282
    },
    {
        "_id": "627deb0dad6238008eb4011d",
        "ask": 46,
        "bid": 37,
        "high": 12907.4611,
        "open": 12875.258,
        "close": 12814.9946,
        "low": 12703.0095
    },
    {
        "_id": "627deb0dc98183a543a3ee13",
        "ask": 78,
        "bid": 3,
        "high": 12973.0721,
        "open": 12896.0972,
        "close": 12826.5115,
        "low": 12773.483
    },
    {
        "_id": "627deb0dfb039b1ccde0cf75",
        "ask": 33,
        "bid": 54,
        "high": 12909.4349,
        "open": 12878.042,
        "close": 12812.2741,
        "low": 12793.8692
    },
    {
        "_id": "627deb0ddf04fbe203d862f3",
        "ask": 95,
        "bid": 97,
        "high": 12955.2744,
        "open": 12897.6877,
        "close": 12824.8733,
        "low": 12718.5195
    },
    {
        "_id": "627deb0ddcb284c0d46848cb",
        "ask": 15,
        "bid": 96,
        "high": 12955.4048,
        "open": 12877.2166,
        "close": 12841.3714,
        "low": 12742.5144
    },
    {
        "_id": "627deb0d1b957e2521d7763f",
        "ask": 84,
        "bid": 40,
        "high": 12939.2107,
        "open": 12850.7753,
        "close": 12832.4639,
        "low": 12740.5457
    },
    {
        "_id": "627deb0db9d7ca0cf679d508",
        "ask": 9,
        "bid": 42,
        "high": 12954.9969,
        "open": 12860.2019,
        "close": 12812.5652,
        "low": 12798.3193
    },
    {
        "_id": "627deb0d31c11c6c9f67fdf9",
        "ask": 0,
        "bid": 96,
        "high": 12983.4633,
        "open": 12895.8282,
        "close": 12811.5334,
        "low": 12706.0825
    },
    {
        "_id": "627deb0d1108a562fee0c28a",
        "ask": 3,
        "bid": 94,
        "high": 12930.5553,
        "open": 12857.8219,
        "close": 12820.782,
        "low": 12766.3215
    },
    {
        "_id": "627deb0ddb1d4b926b3589b8",
        "ask": 64,
        "bid": 1,
        "high": 12942.5252,
        "open": 12865.5189,
        "close": 12826.619,
        "low": 12796.2904
    },
    {
        "_id": "627deb0d6376975d0cb9be88",
        "ask": 53,
        "bid": 16,
        "high": 12988.2785,
        "open": 12861.682,
        "close": 12847.1916,
        "low": 12789.6211
    },
    {
        "_id": "627deb0d760e9a5970c61039",
        "ask": 68,
        "bid": 10,
        "high": 12938.8085,
        "open": 12897.1848,
        "close": 12843.0322,
        "low": 12749.1327
    },
    {
        "_id": "627deb0ddf994931e81f2211",
        "ask": 71,
        "bid": 70,
        "high": 12978.0521,
        "open": 12866.838,
        "close": 12837.7009,
        "low": 12788.3661
    },
    {
        "_id": "627deb0d44f4509ad14a2a98",
        "ask": 55,
        "bid": 15,
        "high": 12916.9721,
        "open": 12853.4622,
        "close": 12819.9489,
        "low": 12726.7139
    },
    {
        "_id": "627deb0dbfdd541aa3fec425",
        "ask": 34,
        "bid": 4,
        "high": 12939.0209,
        "open": 12899.436,
        "close": 12802.7696,
        "low": 12759.7711
    },
    {
        "_id": "627deb0d62d97a65bc66d419",
        "ask": 74,
        "bid": 20,
        "high": 12995.7456,
        "open": 12879.688,
        "close": 12849.8962,
        "low": 12706.238
    },
    {
        "_id": "627deb0d53961464bb7f75a8",
        "ask": 89,
        "bid": 48,
        "high": 12946.4375,
        "open": 12866.9561,
        "close": 12845.5086,
        "low": 12790.7618
    },
    {
        "_id": "627deb0d0698a4aaf9a6e8aa",
        "ask": 67,
        "bid": 19,
        "high": 12987.715,
        "open": 12894.2558,
        "close": 12845.6057,
        "low": 12743.5382
    },
    {
        "_id": "627deb0d58ae8a6ee34444b4",
        "ask": 71,
        "bid": 95,
        "high": 12909.5884,
        "open": 12896.1988,
        "close": 12814.1623,
        "low": 12745.1716
    },
    {
        "_id": "627deb0df4b82e280e8009cc",
        "ask": 45,
        "bid": 62,
        "high": 12949.8899,
        "open": 12875.6634,
        "close": 12845.8455,
        "low": 12766.9256
    },
    {
        "_id": "627deb0d3d5444f15f02f499",
        "ask": 79,
        "bid": 60,
        "high": 12922.3848,
        "open": 12899.3633,
        "close": 12830.5121,
        "low": 12768.6558
    },
    {
        "_id": "627deb0d97fcd393a77f5e09",
        "ask": 84,
        "bid": 60,
        "high": 12978.5874,
        "open": 12896.9608,
        "close": 12846.0941,
        "low": 12740.6608
    },
    {
        "_id": "627deb0d4a2ee67bd063a38b",
        "ask": 90,
        "bid": 16,
        "high": 12922.3854,
        "open": 12862.3897,
        "close": 12846.7143,
        "low": 12744.6514
    },
    {
        "_id": "627deb0d09b53783f5cb3598",
        "ask": 61,
        "bid": 12,
        "high": 12905.2478,
        "open": 12880.0534,
        "close": 12822.99,
        "low": 12767.5619
    },
    {
        "_id": "627deb0d125773ecb26e371c",
        "ask": 35,
        "bid": 79,
        "high": 12966.7101,
        "open": 12884.2458,
        "close": 12803.1799,
        "low": 12741.9436
    },
    {
        "_id": "627deb0d6a8231620c48827d",
        "ask": 47,
        "bid": 52,
        "high": 12949.852,
        "open": 12883.6643,
        "close": 12832.2867,
        "low": 12794.3705
    },
    {
        "_id": "627deb0de5b52e91bcc6105c",
        "ask": 58,
        "bid": 63,
        "high": 12978.1252,
        "open": 12869.4382,
        "close": 12825.4635,
        "low": 12772.6308
    },
    {
        "_id": "627deb0da030e7163020f022",
        "ask": 80,
        "bid": 11,
        "high": 12915.4108,
        "open": 12859.4183,
        "close": 12810.0047,
        "low": 12736.5033
    },
    {
        "_id": "627deb0d3aeaba29f26990a3",
        "ask": 95,
        "bid": 2,
        "high": 12937.1019,
        "open": 12855.2908,
        "close": 12803.0747,
        "low": 12712.6901
    },
    {
        "_id": "627deb0de42a3e3dc8e9499e",
        "ask": 16,
        "bid": 86,
        "high": 12944.5871,
        "open": 12879.9336,
        "close": 12848.3979,
        "low": 12799.1512
    },
    {
        "_id": "627deb0d89206dd25d9ca8bf",
        "ask": 19,
        "bid": 84,
        "high": 12934.0708,
        "open": 12888.0117,
        "close": 12802.7572,
        "low": 12781.5964
    },
    {
        "_id": "627deb0d6186d1439a09df00",
        "ask": 100,
        "bid": 42,
        "high": 12972.3124,
        "open": 12898.3555,
        "close": 12840.3698,
        "low": 12762.5145
    },
    {
        "_id": "627deb0d4153e2314aeedc6c",
        "ask": 98,
        "bid": 30,
        "high": 12913.3565,
        "open": 12871.1749,
        "close": 12822.1517,
        "low": 12700.7332
    },
    {
        "_id": "627deb0df73a75a2ba596c4e",
        "ask": 83,
        "bid": 4,
        "high": 12957.0407,
        "open": 12857.6085,
        "close": 12807.3258,
        "low": 12771.4102
    },
    {
        "_id": "627deb0d31287044f4d9b1e6",
        "ask": 17,
        "bid": 22,
        "high": 12922.5123,
        "open": 12867.3138,
        "close": 12819.588,
        "low": 12728.6479
    },
    {
        "_id": "627deb0dc40a29a5dcd37bd4",
        "ask": 50,
        "bid": 40,
        "high": 12926.9995,
        "open": 12884.0724,
        "close": 12824.119,
        "low": 12721.4207
    },
    {
        "_id": "627deb0da351dbc1123b7ed3",
        "ask": 95,
        "bid": 95,
        "high": 12925.7601,
        "open": 12893.1332,
        "close": 12824.9577,
        "low": 12764.5367
    },
    {
        "_id": "627deb0d1b45a4fb6e630325",
        "ask": 97,
        "bid": 45,
        "high": 12911.0596,
        "open": 12886.0717,
        "close": 12814.1661,
        "low": 12744.0166
    },
    {
        "_id": "627deb0d77ffc81662aad9fa",
        "ask": 19,
        "bid": 50,
        "high": 12919.0223,
        "open": 12889.3862,
        "close": 12818.7489,
        "low": 12728.1678
    },
    {
        "_id": "627deb0d7474ab9464fddbd2",
        "ask": 61,
        "bid": 13,
        "high": 12940.8654,
        "open": 12860.7883,
        "close": 12833.6638,
        "low": 12753.0314
    },
    {
        "_id": "627deb0d6c81c426a9e2a043",
        "ask": 50,
        "bid": 5,
        "high": 12947.7072,
        "open": 12889.29,
        "close": 12825.3384,
        "low": 12728.7738
    },
    {
        "_id": "627deb0d79d98063743cfc13",
        "ask": 48,
        "bid": 35,
        "high": 12971.1439,
        "open": 12895.2932,
        "close": 12803.0105,
        "low": 12757.2723
    },
    {
        "_id": "627deb0deb6a7d1c9b02d42a",
        "ask": 9,
        "bid": 33,
        "high": 12965.3007,
        "open": 12890.7443,
        "close": 12817.7837,
        "low": 12705.2433
    },
    {
        "_id": "627deb0d4dc2f60f4336ea29",
        "ask": 42,
        "bid": 32,
        "high": 12970.0642,
        "open": 12853.1625,
        "close": 12805.8233,
        "low": 12767.1378
    },
    {
        "_id": "627deb0dc6d853dd48cbfb7d",
        "ask": 6,
        "bid": 86,
        "high": 12965.3548,
        "open": 12896.0102,
        "close": 12825.1642,
        "low": 12764.7233
    },
    {
        "_id": "627deb0d18b4508343d665ad",
        "ask": 66,
        "bid": 65,
        "high": 12995.6162,
        "open": 12859.8159,
        "close": 12826.6372,
        "low": 12763.6622
    },
    {
        "_id": "627deb0de6cc7f0af7b99951",
        "ask": 55,
        "bid": 77,
        "high": 12927.4319,
        "open": 12878.3171,
        "close": 12842.7572,
        "low": 12730.7271
    },
    {
        "_id": "627deb0d3f77b1370ed4ea2d",
        "ask": 2,
        "bid": 10,
        "high": 12965.6013,
        "open": 12899.547,
        "close": 12847.8872,
        "low": 12726.0242
    },
    {
        "_id": "627deb0de292fbd7a3ec7acc",
        "ask": 61,
        "bid": 1,
        "high": 12993.2276,
        "open": 12899.7403,
        "close": 12827.8233,
        "low": 12795.6293
    },
    {
        "_id": "627deb0d4c4db2c5f6367b4c",
        "ask": 53,
        "bid": 73,
        "high": 12915.5093,
        "open": 12887.4367,
        "close": 12807.7735,
        "low": 12790.0686
    },
    {
        "_id": "627deb0d8f1fb833f4c664d8",
        "ask": 94,
        "bid": 28,
        "high": 12968.3777,
        "open": 12860.0809,
        "close": 12844.0362,
        "low": 12765.952
    },
    {
        "_id": "627deb0daa88c192a156da58",
        "ask": 34,
        "bid": 59,
        "high": 12997.6775,
        "open": 12888.6533,
        "close": 12831.6819,
        "low": 12763.3739
    },
    {
        "_id": "627deb0d14780967b8748927",
        "ask": 20,
        "bid": 71,
        "high": 12967.2332,
        "open": 12884.7897,
        "close": 12840.0782,
        "low": 12752.1982
    },
    {
        "_id": "627deb0dc925c244829be7d6",
        "ask": 71,
        "bid": 76,
        "high": 12974.9594,
        "open": 12896.3807,
        "close": 12813.621,
        "low": 12734.184
    },
    {
        "_id": "627deb0d40ce8c646a9e958a",
        "ask": 27,
        "bid": 57,
        "high": 12991.0821,
        "open": 12866.8921,
        "close": 12812.5592,
        "low": 12709.6099
    },
    {
        "_id": "627deb0d5b46b9611d5ba644",
        "ask": 10,
        "bid": 87,
        "high": 12938.1857,
        "open": 12879.6918,
        "close": 12845.335,
        "low": 12745.7979
    },
    {
        "_id": "627deb0d1565ee0551a59de9",
        "ask": 59,
        "bid": 66,
        "high": 12974.4132,
        "open": 12867.7932,
        "close": 12848.4221,
        "low": 12724.2566
    },
    {
        "_id": "627deb0d1c69c505a29aac9c",
        "ask": 7,
        "bid": 5,
        "high": 12983.6909,
        "open": 12852.2167,
        "close": 12831.6077,
        "low": 12795.2997
    },
    {
        "_id": "627deb0d0b7983bcde41be15",
        "ask": 56,
        "bid": 96,
        "high": 12988.205,
        "open": 12884.673,
        "close": 12842.2353,
        "low": 12705.4921
    },
    {
        "_id": "627deb0d8bc22bd454ceb0a6",
        "ask": 46,
        "bid": 77,
        "high": 12998.0705,
        "open": 12889.4299,
        "close": 12846.6873,
        "low": 12722.607
    },
    {
        "_id": "627deb0d49c3c327ed3c31d6",
        "ask": 96,
        "bid": 4,
        "high": 12929.3539,
        "open": 12857.8141,
        "close": 12812.6476,
        "low": 12790.6984
    },
    {
        "_id": "627deb0dcd4c7d83efedd8ed",
        "ask": 61,
        "bid": 4,
        "high": 12961.2225,
        "open": 12871.4014,
        "close": 12818.8145,
        "low": 12789.4519
    },
    {
        "_id": "627deb0de44709b7d87ec49e",
        "ask": 25,
        "bid": 92,
        "high": 12989.0762,
        "open": 12867.7559,
        "close": 12800.4678,
        "low": 12723.0037
    },
    {
        "_id": "627deb0db08c45ce2407c23a",
        "ask": 28,
        "bid": 75,
        "high": 12936.0421,
        "open": 12886.2724,
        "close": 12823.5194,
        "low": 12795.2686
    },
    {
        "_id": "627deb0d4830acbf770caa56",
        "ask": 63,
        "bid": 93,
        "high": 12976.2588,
        "open": 12873.6365,
        "close": 12807.873,
        "low": 12710.5197
    },
    {
        "_id": "627deb0d4a3d3fbfcedd358b",
        "ask": 87,
        "bid": 35,
        "high": 12993.5085,
        "open": 12863.5191,
        "close": 12802.0492,
        "low": 12776.4909
    },
    {
        "_id": "627deb0d919a51f53b6c2f6e",
        "ask": 95,
        "bid": 26,
        "high": 12999.3931,
        "open": 12877.149,
        "close": 12815.7288,
        "low": 12797.125
    },
    {
        "_id": "627deb0ddfe6678729c30e69",
        "ask": 96,
        "bid": 100,
        "high": 12963.2114,
        "open": 12863.5512,
        "close": 12843.2268,
        "low": 12789.9412
    },
    {
        "_id": "627deb0d0e9367f8d85706fd",
        "ask": 99,
        "bid": 49,
        "high": 12944.3544,
        "open": 12853.0011,
        "close": 12825.5861,
        "low": 12758.8477
    },
    {
        "_id": "627deb0d81e765554eaef66a",
        "ask": 65,
        "bid": 66,
        "high": 12975.4726,
        "open": 12861.3306,
        "close": 12815.3759,
        "low": 12747.5496
    },
    {
        "_id": "627deb0d7ba440760ce24922",
        "ask": 34,
        "bid": 14,
        "high": 12970.8294,
        "open": 12893.775,
        "close": 12800.0037,
        "low": 12789.8318
    },
    {
        "_id": "627deb0d5813fb4443d7bed3",
        "ask": 97,
        "bid": 97,
        "high": 12914.7161,
        "open": 12893.5835,
        "close": 12828.1112,
        "low": 12797.0008
    },
    {
        "_id": "627deb0d57a179dc643643a8",
        "ask": 24,
        "bid": 68,
        "high": 12980.3191,
        "open": 12854.0557,
        "close": 12843.1737,
        "low": 12747.5187
    },
    {
        "_id": "627deb0d487eed3ef960d298",
        "ask": 4,
        "bid": 57,
        "high": 12911.2347,
        "open": 12894.8922,
        "close": 12828.4175,
        "low": 12742.9454
    },
    {
        "_id": "627deb0da8099bbb5b09c20f",
        "ask": 70,
        "bid": 2,
        "high": 12989.4223,
        "open": 12878.4547,
        "close": 12830.9287,
        "low": 12789.7725
    },
    {
        "_id": "627deb0dbc34e6821e4e4f44",
        "ask": 29,
        "bid": 84,
        "high": 12913.1776,
        "open": 12899.652,
        "close": 12801.6977,
        "low": 12741.0092
    },
    {
        "_id": "627deb0d4ba320265674f2da",
        "ask": 82,
        "bid": 95,
        "high": 12907.2279,
        "open": 12872.748,
        "close": 12816.7718,
        "low": 12756.0766
    },
    {
        "_id": "627deb0d78e779432e9e6a47",
        "ask": 37,
        "bid": 69,
        "high": 12950.0617,
        "open": 12861.1424,
        "close": 12825.2355,
        "low": 12752.4264
    },
    {
        "_id": "627deb0db7d9ec0ce5ada0a0",
        "ask": 25,
        "bid": 49,
        "high": 12978.0649,
        "open": 12877.2941,
        "close": 12819.541,
        "low": 12753.049
    },
    {
        "_id": "627deb0d4086a4019100d843",
        "ask": 53,
        "bid": 29,
        "high": 12908.6851,
        "open": 12878.1831,
        "close": 12830.4573,
        "low": 12716.6487
    },
    {
        "_id": "627deb0d08d565f98ce0727f",
        "ask": 83,
        "bid": 44,
        "high": 12973.9605,
        "open": 12896.926,
        "close": 12809.9597,
        "low": 12749.4564
    },
    {
        "_id": "627deb0df387dcffefb8d06a",
        "ask": 42,
        "bid": 53,
        "high": 12934.0289,
        "open": 12858.3001,
        "close": 12836.7062,
        "low": 12757.7291
    },
    {
        "_id": "627deb0d067842292ae4d3f4",
        "ask": 35,
        "bid": 59,
        "high": 12979.6574,
        "open": 12881.9671,
        "close": 12839.8351,
        "low": 12722.1162
    },
    {
        "_id": "627deb0db06622c3a01ea6f0",
        "ask": 25,
        "bid": 33,
        "high": 12901.4637,
        "open": 12895.6183,
        "close": 12841.178,
        "low": 12778.0443
    },
    {
        "_id": "627deb0d71be6030be7fe415",
        "ask": 28,
        "bid": 11,
        "high": 12913.4407,
        "open": 12865.1793,
        "close": 12831.9788,
        "low": 12718.4558
    },
    {
        "_id": "627deb0d408a8e8eab8d1f04",
        "ask": 41,
        "bid": 86,
        "high": 12915.7744,
        "open": 12867.7881,
        "close": 12842.7718,
        "low": 12728.4768
    },
    {
        "_id": "627deb0dd98ab6ef2ee24e23",
        "ask": 68,
        "bid": 25,
        "high": 12985.6634,
        "open": 12885.3447,
        "close": 12809.3136,
        "low": 12753.7552
    },
    {
        "_id": "627deb0d3bf3f022cb832747",
        "ask": 17,
        "bid": 78,
        "high": 12985.5981,
        "open": 12857.7564,
        "close": 12817.5871,
        "low": 12756.4519
    },
    {
        "_id": "627deb0d6029276022914dd8",
        "ask": 5,
        "bid": 2,
        "high": 12909.3873,
        "open": 12877.4388,
        "close": 12810.8258,
        "low": 12725.2729
    },
    {
        "_id": "627deb0d5a641980d8971a44",
        "ask": 35,
        "bid": 92,
        "high": 12995.3692,
        "open": 12862.6835,
        "close": 12803.8949,
        "low": 12710.9063
    },
    {
        "_id": "627deb0d94e71f63a9384f5d",
        "ask": 22,
        "bid": 87,
        "high": 12913.8455,
        "open": 12895.0485,
        "close": 12826.1312,
        "low": 12736.0987
    },
    {
        "_id": "627deb0d25a6523e21c204f7",
        "ask": 84,
        "bid": 78,
        "high": 12998.5337,
        "open": 12871.609,
        "close": 12836.1156,
        "low": 12777.5311
    },
    {
        "_id": "627deb0d18466ece34c5241e",
        "ask": 30,
        "bid": 81,
        "high": 12964.8005,
        "open": 12899.7861,
        "close": 12830.3373,
        "low": 12702.0633
    },
    {
        "_id": "627deb0d8608e1359602a961",
        "ask": 84,
        "bid": 80,
        "high": 12941.2034,
        "open": 12882.5591,
        "close": 12832.2318,
        "low": 12721.7737
    },
    {
        "_id": "627deb0d2e26b8c81580f4b6",
        "ask": 26,
        "bid": 34,
        "high": 12936.5217,
        "open": 12878.2573,
        "close": 12813.512,
        "low": 12780.501
    },
    {
        "_id": "627deb0d3c998688a8cba4e1",
        "ask": 47,
        "bid": 5,
        "high": 12965.5794,
        "open": 12875.8618,
        "close": 12825.1615,
        "low": 12791.6576
    },
    {
        "_id": "627deb0dfc4d1cda57ad6455",
        "ask": 12,
        "bid": 13,
        "high": 12988.5057,
        "open": 12886.3701,
        "close": 12806.0431,
        "low": 12792.0613
    },
    {
        "_id": "627deb0dc3b410297f451d48",
        "ask": 47,
        "bid": 94,
        "high": 12993.2719,
        "open": 12884.6874,
        "close": 12825.926,
        "low": 12700.1475
    },
    {
        "_id": "627deb0d243241c4f86d143f",
        "ask": 84,
        "bid": 60,
        "high": 12972.079,
        "open": 12872.1567,
        "close": 12821.3616,
        "low": 12770.3347
    },
    {
        "_id": "627deb0d9b860ae536de160e",
        "ask": 6,
        "bid": 1,
        "high": 12992.0234,
        "open": 12899.6355,
        "close": 12818.9427,
        "low": 12725.0621
    },
    {
        "_id": "627deb0d9eefd731766b65b4",
        "ask": 92,
        "bid": 95,
        "high": 12950.1477,
        "open": 12898.8698,
        "close": 12820.9216,
        "low": 12773.3849
    },
    {
        "_id": "627deb0d3bf60711bb97f71e",
        "ask": 100,
        "bid": 76,
        "high": 12977.9472,
        "open": 12889.777,
        "close": 12815.9068,
        "low": 12722.785
    },
    {
        "_id": "627deb0dc6afeeb998a1611a",
        "ask": 50,
        "bid": 43,
        "high": 12948.8136,
        "open": 12883.1855,
        "close": 12810.1503,
        "low": 12734.9948
    },
    {
        "_id": "627deb0d13f168d2b54ac0e1",
        "ask": 39,
        "bid": 84,
        "high": 12942.6579,
        "open": 12888.2668,
        "close": 12832.9028,
        "low": 12776.4089
    },
    {
        "_id": "627deb0d7cd4873538437bf3",
        "ask": 12,
        "bid": 27,
        "high": 12920.6887,
        "open": 12857.7842,
        "close": 12842.4839,
        "low": 12710.7834
    },
    {
        "_id": "627deb0d92917b0bca0022ff",
        "ask": 75,
        "bid": 89,
        "high": 12964.3904,
        "open": 12880.4921,
        "close": 12804.413,
        "low": 12772.2694
    },
    {
        "_id": "627deb0d6ee570b39e7828f7",
        "ask": 35,
        "bid": 78,
        "high": 12987.1173,
        "open": 12889.5507,
        "close": 12811.7093,
        "low": 12773.3236
    },
    {
        "_id": "627deb0d33980e68349ca3e6",
        "ask": 72,
        "bid": 81,
        "high": 12970.1146,
        "open": 12896.793,
        "close": 12803.9388,
        "low": 12794.7323
    },
    {
        "_id": "627deb0dae7310e7d4de3023",
        "ask": 16,
        "bid": 27,
        "high": 12933.4724,
        "open": 12869.542,
        "close": 12837.2175,
        "low": 12759.8713
    },
    {
        "_id": "627deb0df74fe7e335327586",
        "ask": 8,
        "bid": 86,
        "high": 12953.4732,
        "open": 12862.4821,
        "close": 12816.6053,
        "low": 12729.3557
    },
    {
        "_id": "627deb0d857b34084dff72d1",
        "ask": 14,
        "bid": 5,
        "high": 12965.4261,
        "open": 12877.2718,
        "close": 12835.5861,
        "low": 12769.706
    },
    {
        "_id": "627deb0df3cca850dbf21892",
        "ask": 89,
        "bid": 52,
        "high": 12990.9273,
        "open": 12864.2378,
        "close": 12808.5609,
        "low": 12762.1006
    },
    {
        "_id": "627deb0dfbbe57a15107e0e6",
        "ask": 53,
        "bid": 17,
        "high": 12938.5395,
        "open": 12878.5884,
        "close": 12814.9042,
        "low": 12789.7423
    },
    {
        "_id": "627deb0d742dcf5c68866c1b",
        "ask": 37,
        "bid": 53,
        "high": 12929.1532,
        "open": 12893.6127,
        "close": 12832.6293,
        "low": 12772.8132
    },
    {
        "_id": "627deb0d7709d25f42760a45",
        "ask": 98,
        "bid": 63,
        "high": 12928.9165,
        "open": 12867.797,
        "close": 12847.1713,
        "low": 12771.3811
    },
    {
        "_id": "627deb0de88b6cffb5cb243b",
        "ask": 80,
        "bid": 99,
        "high": 12978.5342,
        "open": 12883.2431,
        "close": 12826.068,
        "low": 12740.6558
    },
    {
        "_id": "627deb0de2691ce26f5bd249",
        "ask": 46,
        "bid": 5,
        "high": 12949.3441,
        "open": 12891.9516,
        "close": 12830.7031,
        "low": 12707.8398
    },
    {
        "_id": "627deb0ddb64ae173fc00866",
        "ask": 96,
        "bid": 70,
        "high": 12936.6827,
        "open": 12893.0271,
        "close": 12838.5307,
        "low": 12775.2691
    },
    {
        "_id": "627deb0d9a2d09e1c59c7cbc",
        "ask": 86,
        "bid": 34,
        "high": 12996.3309,
        "open": 12855.5843,
        "close": 12804.8328,
        "low": 12762.874
    },
    {
        "_id": "627deb0d0eaed19c51294e65",
        "ask": 27,
        "bid": 56,
        "high": 12907.8348,
        "open": 12878.2761,
        "close": 12849.3403,
        "low": 12761.9909
    },
    {
        "_id": "627deb0dab888bdfe582d468",
        "ask": 20,
        "bid": 9,
        "high": 12907.3862,
        "open": 12895.6655,
        "close": 12848.0683,
        "low": 12768.2083
    },
    {
        "_id": "627deb0db6778cc6bbb0890e",
        "ask": 74,
        "bid": 8,
        "high": 12942.4007,
        "open": 12895.4549,
        "close": 12806.9978,
        "low": 12762.7027
    },
    {
        "_id": "627deb0dcb8e0c6d9214038a",
        "ask": 59,
        "bid": 61,
        "high": 12952.3546,
        "open": 12896.2489,
        "close": 12811.1857,
        "low": 12715.0605
    },
    {
        "_id": "627deb0da782b61ac295d5bb",
        "ask": 66,
        "bid": 43,
        "high": 12970.4108,
        "open": 12853.0791,
        "close": 12819.8039,
        "low": 12700.8302
    },
    {
        "_id": "627deb0d3acf6da0091babdf",
        "ask": 0,
        "bid": 16,
        "high": 12963.0139,
        "open": 12867.0257,
        "close": 12815.8178,
        "low": 12710.107
    },
    {
        "_id": "627deb0dd2a149c5887801e7",
        "ask": 14,
        "bid": 73,
        "high": 12948.1745,
        "open": 12869.1251,
        "close": 12839.0115,
        "low": 12771.7325
    },
    {
        "_id": "627deb0d017e2ff811675ea3",
        "ask": 64,
        "bid": 84,
        "high": 12987.6589,
        "open": 12876.6063,
        "close": 12824.7832,
        "low": 12711.1393
    },
    {
        "_id": "627deb0d7dcb81443e5540cb",
        "ask": 43,
        "bid": 61,
        "high": 12982.5827,
        "open": 12891.8057,
        "close": 12800.9601,
        "low": 12768.7918
    },
    {
        "_id": "627deb0d80304f9c7482b4ab",
        "ask": 86,
        "bid": 88,
        "high": 12948.4755,
        "open": 12894.3844,
        "close": 12804.7051,
        "low": 12792.9696
    },
    {
        "_id": "627deb0d73bad7ef31b651bc",
        "ask": 83,
        "bid": 31,
        "high": 12961.9279,
        "open": 12881.4906,
        "close": 12816.4067,
        "low": 12789.9967
    },
    {
        "_id": "627deb0d7981c7674a01de01",
        "ask": 56,
        "bid": 57,
        "high": 12907.1369,
        "open": 12892.3451,
        "close": 12813.4316,
        "low": 12769.1144
    },
    {
        "_id": "627deb0dec91cd3ecbc7930e",
        "ask": 15,
        "bid": 91,
        "high": 12903.3116,
        "open": 12860.6293,
        "close": 12821.8472,
        "low": 12753.3434
    },
    {
        "_id": "627deb0df1f4c4c7a1d61967",
        "ask": 40,
        "bid": 39,
        "high": 12924.4699,
        "open": 12882.0411,
        "close": 12801.6294,
        "low": 12787.6908
    },
    {
        "_id": "627deb0d413d473fec113eaf",
        "ask": 80,
        "bid": 3,
        "high": 12933.5814,
        "open": 12898.1894,
        "close": 12815.2127,
        "low": 12723.9815
    },
    {
        "_id": "627deb0d771b4c3e5a4d2481",
        "ask": 80,
        "bid": 7,
        "high": 12951.5443,
        "open": 12861.5423,
        "close": 12806.8486,
        "low": 12730.3028
    },
    {
        "_id": "627deb0ddbb84dd897483311",
        "ask": 12,
        "bid": 2,
        "high": 12920.5139,
        "open": 12864.4789,
        "close": 12847.9472,
        "low": 12770.2955
    },
    {
        "_id": "627deb0dbdd720c3c45b0f01",
        "ask": 96,
        "bid": 86,
        "high": 12996.6844,
        "open": 12880.3987,
        "close": 12847.3596,
        "low": 12751.6904
    },
    {
        "_id": "627deb0dfd1c3bee8076bb13",
        "ask": 8,
        "bid": 63,
        "high": 12916.2613,
        "open": 12895.402,
        "close": 12823.1293,
        "low": 12783.8879
    },
    {
        "_id": "627deb0dbe0b7b8ef1c05a04",
        "ask": 98,
        "bid": 53,
        "high": 12912.4153,
        "open": 12887.0478,
        "close": 12831.6638,
        "low": 12740.2135
    },
    {
        "_id": "627deb0ddfaa0760d16abd3d",
        "ask": 53,
        "bid": 96,
        "high": 12993.4369,
        "open": 12866.1824,
        "close": 12830.2843,
        "low": 12762.0678
    },
    {
        "_id": "627deb0dede7ae74ac5723e0",
        "ask": 46,
        "bid": 55,
        "high": 12964.0887,
        "open": 12898.3518,
        "close": 12813.9948,
        "low": 12713.2937
    },
    {
        "_id": "627deb0d8bd640b3415c82d2",
        "ask": 61,
        "bid": 3,
        "high": 12988.9638,
        "open": 12883.5832,
        "close": 12837.4564,
        "low": 12782.0229
    },
    {
        "_id": "627deb0de1354673dc5a2bad",
        "ask": 24,
        "bid": 0,
        "high": 12992.1904,
        "open": 12881.9546,
        "close": 12831.7896,
        "low": 12760.6711
    },
    {
        "_id": "627deb0db8e483a8f48cc237",
        "ask": 8,
        "bid": 83,
        "high": 12957.4103,
        "open": 12892.2295,
        "close": 12808.02,
        "low": 12738.149
    },
    {
        "_id": "627deb0d117d96022a903788",
        "ask": 42,
        "bid": 84,
        "high": 12901.1915,
        "open": 12866.3936,
        "close": 12813.3783,
        "low": 12780.1432
    }
]

export const clusterColumnMock = [
    {
        "_id": "62ddd3dc40b50883f11fa6be",
        "high": 12934.15,
        "open": 12879.29,
        "close": 12796.27,
        "low": 12991.46,
        "priceList": [
            {
                "price": 12769.82,
                "ask": 26,
                "bid": 51
            },
            {
                "price": 12991.51,
                "ask": 37,
                "bid": 33
            },
            {
                "price": 12920.81,
                "ask": 67,
                "bid": 13
            },
            {
                "price": 12733.84,
                "ask": 49,
                "bid": 48
            },
            {
                "price": 12992.77,
                "ask": 70,
                "bid": 76
            },
            {
                "price": 12883.19,
                "ask": 37,
                "bid": 46
            },
            {
                "price": 12960,
                "ask": 58,
                "bid": 0
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc63b53548413c3069",
        "high": 12920.17,
        "open": 12760.75,
        "close": 12907.1,
        "low": 12954.42,
        "priceList": [
            {
                "price": 12792.53,
                "ask": 27,
                "bid": 14
            },
            {
                "price": 12753.04,
                "ask": 93,
                "bid": 20
            },
            {
                "price": 12844.45,
                "ask": 48,
                "bid": 3
            },
            {
                "price": 12799.86,
                "ask": 1,
                "bid": 100
            },
            {
                "price": 12759.59,
                "ask": 38,
                "bid": 75
            },
            {
                "price": 12983.04,
                "ask": 90,
                "bid": 25
            },
            {
                "price": 12835.44,
                "ask": 65,
                "bid": 26
            },
            {
                "price": 12787.34,
                "ask": 38,
                "bid": 89
            },
            {
                "price": 12739.15,
                "ask": 37,
                "bid": 36
            },
            {
                "price": 12713.63,
                "ask": 5,
                "bid": 6
            },
            {
                "price": 12889.06,
                "ask": 67,
                "bid": 33
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dccb3f4e69f2063e9b",
        "high": 12815.74,
        "open": 12908.26,
        "close": 12967.94,
        "low": 12811.83,
        "priceList": [
            {
                "price": 12989.94,
                "ask": 41,
                "bid": 4
            },
            {
                "price": 12922.75,
                "ask": 94,
                "bid": 73
            },
            {
                "price": 12733.78,
                "ask": 92,
                "bid": 19
            },
            {
                "price": 12801.01,
                "ask": 20,
                "bid": 2
            },
            {
                "price": 12927.06,
                "ask": 91,
                "bid": 22
            },
            {
                "price": 12791.05,
                "ask": 71,
                "bid": 53
            },
            {
                "price": 12719.23,
                "ask": 40,
                "bid": 75
            },
            {
                "price": 12837.55,
                "ask": 34,
                "bid": 36
            },
            {
                "price": 12917.6,
                "ask": 12,
                "bid": 50
            },
            {
                "price": 12777.81,
                "ask": 90,
                "bid": 88
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc81118624dbe15273",
        "high": 12757.47,
        "open": 12798.79,
        "close": 12973.1,
        "low": 12972.97,
        "priceList": [
            {
                "price": 12991.02,
                "ask": 74,
                "bid": 31
            },
            {
                "price": 12791.58,
                "ask": 3,
                "bid": 65
            },
            {
                "price": 12861.73,
                "ask": 52,
                "bid": 17
            },
            {
                "price": 12807.21,
                "ask": 71,
                "bid": 6
            },
            {
                "price": 12784.85,
                "ask": 56,
                "bid": 2
            },
            {
                "price": 12774.56,
                "ask": 93,
                "bid": 33
            },
            {
                "price": 12795.21,
                "ask": 83,
                "bid": 16
            },
            {
                "price": 12966.57,
                "ask": 34,
                "bid": 85
            },
            {
                "price": 12927.95,
                "ask": 78,
                "bid": 44
            },
            {
                "price": 12984.7,
                "ask": 36,
                "bid": 42
            },
            {
                "price": 12716.62,
                "ask": 56,
                "bid": 51
            },
            {
                "price": 12813.08,
                "ask": 88,
                "bid": 43
            },
            {
                "price": 12912.17,
                "ask": 24,
                "bid": 42
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc655d720a231de53b",
        "high": 12819.66,
        "open": 12785.15,
        "close": 12869.73,
        "low": 12847.99,
        "priceList": [
            {
                "price": 12740.63,
                "ask": 15,
                "bid": 10
            },
            {
                "price": 12802.07,
                "ask": 24,
                "bid": 61
            },
            {
                "price": 12775.22,
                "ask": 82,
                "bid": 22
            },
            {
                "price": 12793.31,
                "ask": 46,
                "bid": 79
            },
            {
                "price": 12994.56,
                "ask": 93,
                "bid": 7
            },
            {
                "price": 12719.1,
                "ask": 10,
                "bid": 27
            },
            {
                "price": 12844.05,
                "ask": 55,
                "bid": 69
            },
            {
                "price": 12910.09,
                "ask": 100,
                "bid": 2
            },
            {
                "price": 12860.44,
                "ask": 92,
                "bid": 54
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc517e1b2d5a34a2f0",
        "high": 12792.48,
        "open": 12883.18,
        "close": 12908.4,
        "low": 12945.03,
        "priceList": [
            {
                "price": 12711.01,
                "ask": 40,
                "bid": 65
            },
            {
                "price": 12927.26,
                "ask": 24,
                "bid": 38
            },
            {
                "price": 12825.11,
                "ask": 46,
                "bid": 57
            },
            {
                "price": 12820.46,
                "ask": 8,
                "bid": 22
            },
            {
                "price": 12954.17,
                "ask": 89,
                "bid": 10
            },
            {
                "price": 12965.79,
                "ask": 29,
                "bid": 65
            },
            {
                "price": 12806.77,
                "ask": 44,
                "bid": 26
            },
            {
                "price": 12868.84,
                "ask": 4,
                "bid": 22
            },
            {
                "price": 12760.56,
                "ask": 18,
                "bid": 2
            },
            {
                "price": 12982.98,
                "ask": 51,
                "bid": 24
            },
            {
                "price": 12715.32,
                "ask": 82,
                "bid": 46
            },
            {
                "price": 12823.33,
                "ask": 81,
                "bid": 53
            },
            {
                "price": 12728.87,
                "ask": 15,
                "bid": 33
            },
            {
                "price": 12871.11,
                "ask": 7,
                "bid": 30
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dce60a0670139dc87f",
        "high": 12859.07,
        "open": 12799.82,
        "close": 12761.57,
        "low": 12822.85,
        "priceList": [
            {
                "price": 12748.63,
                "ask": 84,
                "bid": 56
            },
            {
                "price": 12716.04,
                "ask": 3,
                "bid": 66
            },
            {
                "price": 12818.13,
                "ask": 5,
                "bid": 58
            },
            {
                "price": 12864.11,
                "ask": 55,
                "bid": 25
            },
            {
                "price": 12714.15,
                "ask": 89,
                "bid": 37
            },
            {
                "price": 12932.42,
                "ask": 42,
                "bid": 11
            },
            {
                "price": 12996.63,
                "ask": 90,
                "bid": 14
            },
            {
                "price": 12760.67,
                "ask": 96,
                "bid": 68
            },
            {
                "price": 12894.95,
                "ask": 24,
                "bid": 10
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcbc065cbb66b85156",
        "high": 12867.56,
        "open": 12709.24,
        "close": 12866.75,
        "low": 12940.04,
        "priceList": [
            {
                "price": 12752.81,
                "ask": 79,
                "bid": 35
            },
            {
                "price": 12979.52,
                "ask": 60,
                "bid": 10
            },
            {
                "price": 12705.85,
                "ask": 49,
                "bid": 31
            },
            {
                "price": 12962.76,
                "ask": 78,
                "bid": 27
            },
            {
                "price": 12879.02,
                "ask": 80,
                "bid": 48
            },
            {
                "price": 12708.41,
                "ask": 75,
                "bid": 90
            },
            {
                "price": 12983.13,
                "ask": 66,
                "bid": 51
            },
            {
                "price": 12809.03,
                "ask": 91,
                "bid": 79
            },
            {
                "price": 12897.36,
                "ask": 86,
                "bid": 58
            },
            {
                "price": 12950.73,
                "ask": 84,
                "bid": 71
            },
            {
                "price": 12788.31,
                "ask": 16,
                "bid": 67
            },
            {
                "price": 12701.63,
                "ask": 24,
                "bid": 16
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc2f76d0cc4ef74132",
        "high": 12853.67,
        "open": 12975.05,
        "close": 12949.83,
        "low": 12815.16,
        "priceList": [
            {
                "price": 12875.21,
                "ask": 57,
                "bid": 98
            },
            {
                "price": 12810.35,
                "ask": 88,
                "bid": 33
            },
            {
                "price": 12828.96,
                "ask": 25,
                "bid": 48
            },
            {
                "price": 12803.34,
                "ask": 7,
                "bid": 0
            },
            {
                "price": 12971.39,
                "ask": 78,
                "bid": 87
            },
            {
                "price": 12989.27,
                "ask": 74,
                "bid": 58
            },
            {
                "price": 12976.16,
                "ask": 84,
                "bid": 74
            },
            {
                "price": 12858.38,
                "ask": 78,
                "bid": 27
            },
            {
                "price": 12725.33,
                "ask": 12,
                "bid": 38
            },
            {
                "price": 12785.53,
                "ask": 95,
                "bid": 6
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dccf4029401ba66f7e",
        "high": 12956.59,
        "open": 12799.44,
        "close": 12793.77,
        "low": 12933.8,
        "priceList": [
            {
                "price": 12908.4,
                "ask": 45,
                "bid": 21
            },
            {
                "price": 12896.53,
                "ask": 30,
                "bid": 66
            },
            {
                "price": 12967.48,
                "ask": 68,
                "bid": 36
            },
            {
                "price": 12733.92,
                "ask": 7,
                "bid": 6
            },
            {
                "price": 12903.27,
                "ask": 72,
                "bid": 88
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcffab067f0b28cf12",
        "high": 12745.62,
        "open": 12871.99,
        "close": 12844.28,
        "low": 12787.04,
        "priceList": [
            {
                "price": 12934.48,
                "ask": 89,
                "bid": 21
            },
            {
                "price": 12906,
                "ask": 58,
                "bid": 36
            },
            {
                "price": 12854.55,
                "ask": 87,
                "bid": 71
            },
            {
                "price": 12771.45,
                "ask": 56,
                "bid": 32
            },
            {
                "price": 12871.11,
                "ask": 56,
                "bid": 77
            },
            {
                "price": 12873.53,
                "ask": 55,
                "bid": 40
            },
            {
                "price": 12746.99,
                "ask": 93,
                "bid": 32
            },
            {
                "price": 12907.23,
                "ask": 17,
                "bid": 78
            },
            {
                "price": 12922.77,
                "ask": 30,
                "bid": 19
            },
            {
                "price": 12917.67,
                "ask": 63,
                "bid": 61
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcd486f31fb87cd41d",
        "high": 12922.58,
        "open": 12959.97,
        "close": 12997.88,
        "low": 12991.32,
        "priceList": [
            {
                "price": 12759.11,
                "ask": 95,
                "bid": 83
            },
            {
                "price": 12799.37,
                "ask": 20,
                "bid": 13
            },
            {
                "price": 12973.04,
                "ask": 91,
                "bid": 99
            },
            {
                "price": 12801.85,
                "ask": 51,
                "bid": 76
            },
            {
                "price": 12717.65,
                "ask": 24,
                "bid": 43
            },
            {
                "price": 12841.35,
                "ask": 92,
                "bid": 88
            },
            {
                "price": 12776.35,
                "ask": 14,
                "bid": 68
            },
            {
                "price": 12835.9,
                "ask": 63,
                "bid": 49
            },
            {
                "price": 12822.14,
                "ask": 31,
                "bid": 94
            },
            {
                "price": 12840.82,
                "ask": 58,
                "bid": 8
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc4ab44bf9a52a77ee",
        "high": 12742.64,
        "open": 12997.55,
        "close": 12795.83,
        "low": 12944.59,
        "priceList": [
            {
                "price": 12816.47,
                "ask": 47,
                "bid": 61
            },
            {
                "price": 12909.31,
                "ask": 99,
                "bid": 43
            },
            {
                "price": 12977.3,
                "ask": 5,
                "bid": 87
            },
            {
                "price": 12760.82,
                "ask": 68,
                "bid": 94
            },
            {
                "price": 12946.79,
                "ask": 10,
                "bid": 35
            },
            {
                "price": 12726.58,
                "ask": 3,
                "bid": 74
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc18e3965aa60c0900",
        "high": 12941.32,
        "open": 12828.43,
        "close": 12797.98,
        "low": 12964.53,
        "priceList": [
            {
                "price": 12894.77,
                "ask": 63,
                "bid": 45
            },
            {
                "price": 12741.96,
                "ask": 34,
                "bid": 20
            },
            {
                "price": 12826.7,
                "ask": 25,
                "bid": 13
            },
            {
                "price": 12804.77,
                "ask": 94,
                "bid": 19
            },
            {
                "price": 12805.71,
                "ask": 28,
                "bid": 32
            },
            {
                "price": 12944.89,
                "ask": 74,
                "bid": 18
            },
            {
                "price": 12906.01,
                "ask": 75,
                "bid": 58
            },
            {
                "price": 12899.09,
                "ask": 65,
                "bid": 16
            },
            {
                "price": 12903.6,
                "ask": 14,
                "bid": 12
            },
            {
                "price": 12933.83,
                "ask": 68,
                "bid": 44
            },
            {
                "price": 12748.3,
                "ask": 60,
                "bid": 44
            },
            {
                "price": 12819.39,
                "ask": 79,
                "bid": 99
            },
            {
                "price": 12862.3,
                "ask": 17,
                "bid": 86
            },
            {
                "price": 12848.42,
                "ask": 90,
                "bid": 13
            },
            {
                "price": 12990.77,
                "ask": 10,
                "bid": 12
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc79a03e165bd7881c",
        "high": 12941.28,
        "open": 12725.01,
        "close": 12986.59,
        "low": 12933.38,
        "priceList": [
            {
                "price": 12930.62,
                "ask": 44,
                "bid": 11
            },
            {
                "price": 12908.48,
                "ask": 66,
                "bid": 51
            },
            {
                "price": 12811.67,
                "ask": 87,
                "bid": 91
            },
            {
                "price": 12934.13,
                "ask": 9,
                "bid": 15
            },
            {
                "price": 12893.46,
                "ask": 9,
                "bid": 93
            },
            {
                "price": 12794.18,
                "ask": 27,
                "bid": 27
            },
            {
                "price": 12707.23,
                "ask": 88,
                "bid": 32
            },
            {
                "price": 12995.61,
                "ask": 47,
                "bid": 90
            },
            {
                "price": 12928.22,
                "ask": 65,
                "bid": 97
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dced36d89e99203d76",
        "high": 12865.43,
        "open": 12976.24,
        "close": 12941.43,
        "low": 12716.72,
        "priceList": [
            {
                "price": 12852.99,
                "ask": 11,
                "bid": 14
            },
            {
                "price": 12995.66,
                "ask": 60,
                "bid": 55
            },
            {
                "price": 12955.21,
                "ask": 95,
                "bid": 60
            },
            {
                "price": 12852.13,
                "ask": 25,
                "bid": 99
            },
            {
                "price": 12793.33,
                "ask": 20,
                "bid": 95
            },
            {
                "price": 12981.09,
                "ask": 16,
                "bid": 79
            },
            {
                "price": 12736.5,
                "ask": 36,
                "bid": 14
            },
            {
                "price": 12780.26,
                "ask": 88,
                "bid": 2
            },
            {
                "price": 12959.79,
                "ask": 69,
                "bid": 44
            },
            {
                "price": 12793.7,
                "ask": 100,
                "bid": 42
            },
            {
                "price": 12860.27,
                "ask": 72,
                "bid": 40
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcba17f75130163864",
        "high": 12930.2,
        "open": 12738.81,
        "close": 12749.4,
        "low": 12844.68,
        "priceList": [
            {
                "price": 12895.37,
                "ask": 77,
                "bid": 39
            },
            {
                "price": 12770.07,
                "ask": 1,
                "bid": 89
            },
            {
                "price": 12810.36,
                "ask": 37,
                "bid": 68
            },
            {
                "price": 12904.76,
                "ask": 3,
                "bid": 69
            },
            {
                "price": 12989.32,
                "ask": 39,
                "bid": 77
            },
            {
                "price": 12864.66,
                "ask": 74,
                "bid": 12
            },
            {
                "price": 12796.67,
                "ask": 87,
                "bid": 59
            },
            {
                "price": 12984.6,
                "ask": 30,
                "bid": 30
            },
            {
                "price": 12833.86,
                "ask": 88,
                "bid": 23
            },
            {
                "price": 12709.59,
                "ask": 40,
                "bid": 26
            },
            {
                "price": 12858.64,
                "ask": 24,
                "bid": 5
            },
            {
                "price": 12879.68,
                "ask": 93,
                "bid": 36
            },
            {
                "price": 12919.62,
                "ask": 95,
                "bid": 75
            },
            {
                "price": 12892.03,
                "ask": 3,
                "bid": 47
            },
            {
                "price": 12713.75,
                "ask": 84,
                "bid": 9
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc3a2791672a849fb3",
        "high": 12895.41,
        "open": 12847.71,
        "close": 12913.33,
        "low": 12778.41,
        "priceList": [
            {
                "price": 12845.87,
                "ask": 51,
                "bid": 87
            },
            {
                "price": 12709.29,
                "ask": 98,
                "bid": 72
            },
            {
                "price": 12715.8,
                "ask": 59,
                "bid": 19
            },
            {
                "price": 12825.37,
                "ask": 23,
                "bid": 15
            },
            {
                "price": 12913.84,
                "ask": 37,
                "bid": 57
            },
            {
                "price": 12941.31,
                "ask": 75,
                "bid": 18
            },
            {
                "price": 12730.35,
                "ask": 63,
                "bid": 79
            },
            {
                "price": 12744.8,
                "ask": 80,
                "bid": 74
            },
            {
                "price": 12860.85,
                "ask": 5,
                "bid": 4
            },
            {
                "price": 12951.67,
                "ask": 40,
                "bid": 91
            },
            {
                "price": 12885.82,
                "ask": 56,
                "bid": 61
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc711e2522847156db",
        "high": 12847.06,
        "open": 12761.34,
        "close": 12963.69,
        "low": 12872.21,
        "priceList": [
            {
                "price": 12778.16,
                "ask": 1,
                "bid": 55
            },
            {
                "price": 12928.31,
                "ask": 96,
                "bid": 54
            },
            {
                "price": 12814.89,
                "ask": 82,
                "bid": 100
            },
            {
                "price": 12988.28,
                "ask": 72,
                "bid": 73
            },
            {
                "price": 12729.92,
                "ask": 24,
                "bid": 34
            },
            {
                "price": 12749.23,
                "ask": 48,
                "bid": 82
            },
            {
                "price": 12849.66,
                "ask": 43,
                "bid": 80
            },
            {
                "price": 12736.96,
                "ask": 99,
                "bid": 87
            },
            {
                "price": 12896.75,
                "ask": 27,
                "bid": 83
            },
            {
                "price": 12825.52,
                "ask": 24,
                "bid": 8
            },
            {
                "price": 12764.18,
                "ask": 4,
                "bid": 93
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcdd7ede12ac41dfca",
        "high": 12913.06,
        "open": 12969.81,
        "close": 12982.16,
        "low": 12907.03,
        "priceList": [
            {
                "price": 12926.22,
                "ask": 74,
                "bid": 47
            },
            {
                "price": 12963.52,
                "ask": 49,
                "bid": 52
            },
            {
                "price": 12826.38,
                "ask": 58,
                "bid": 9
            },
            {
                "price": 12731.78,
                "ask": 71,
                "bid": 33
            },
            {
                "price": 12846.92,
                "ask": 45,
                "bid": 51
            },
            {
                "price": 12945.91,
                "ask": 2,
                "bid": 45
            },
            {
                "price": 12858.2,
                "ask": 36,
                "bid": 22
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc0b55fa20c1de55a7",
        "high": 12701.59,
        "open": 12786.75,
        "close": 12713.95,
        "low": 12968.93,
        "priceList": [
            {
                "price": 12961.71,
                "ask": 99,
                "bid": 87
            },
            {
                "price": 12911.47,
                "ask": 11,
                "bid": 4
            },
            {
                "price": 12738.91,
                "ask": 96,
                "bid": 67
            },
            {
                "price": 12831,
                "ask": 57,
                "bid": 59
            },
            {
                "price": 12800.19,
                "ask": 84,
                "bid": 19
            },
            {
                "price": 12839.53,
                "ask": 49,
                "bid": 70
            },
            {
                "price": 12957.88,
                "ask": 93,
                "bid": 47
            },
            {
                "price": 12763.48,
                "ask": 90,
                "bid": 15
            },
            {
                "price": 12758.28,
                "ask": 5,
                "bid": 13
            },
            {
                "price": 12735.52,
                "ask": 75,
                "bid": 69
            },
            {
                "price": 12756.08,
                "ask": 64,
                "bid": 75
            },
            {
                "price": 12808.62,
                "ask": 84,
                "bid": 100
            },
            {
                "price": 12807.48,
                "ask": 59,
                "bid": 96
            },
            {
                "price": 12965.91,
                "ask": 7,
                "bid": 65
            },
            {
                "price": 12976.02,
                "ask": 50,
                "bid": 24
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcc1021058486a0068",
        "high": 12702.9,
        "open": 12716.34,
        "close": 12819.89,
        "low": 12862.97,
        "priceList": [
            {
                "price": 12953.25,
                "ask": 54,
                "bid": 74
            },
            {
                "price": 12923.99,
                "ask": 4,
                "bid": 68
            },
            {
                "price": 12917.31,
                "ask": 4,
                "bid": 90
            },
            {
                "price": 12796.89,
                "ask": 5,
                "bid": 89
            },
            {
                "price": 12780.3,
                "ask": 77,
                "bid": 5
            },
            {
                "price": 12703.58,
                "ask": 10,
                "bid": 5
            },
            {
                "price": 12775.1,
                "ask": 47,
                "bid": 78
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc36706324904f8c55",
        "high": 12961.27,
        "open": 12862.19,
        "close": 12967.27,
        "low": 12831.14,
        "priceList": [
            {
                "price": 12914.52,
                "ask": 39,
                "bid": 52
            },
            {
                "price": 12786.88,
                "ask": 34,
                "bid": 3
            },
            {
                "price": 12858.97,
                "ask": 94,
                "bid": 78
            },
            {
                "price": 12987.42,
                "ask": 85,
                "bid": 53
            },
            {
                "price": 12829.85,
                "ask": 29,
                "bid": 57
            },
            {
                "price": 12867.08,
                "ask": 70,
                "bid": 1
            },
            {
                "price": 12901.28,
                "ask": 49,
                "bid": 40
            },
            {
                "price": 12841.2,
                "ask": 39,
                "bid": 96
            },
            {
                "price": 12997.21,
                "ask": 9,
                "bid": 1
            },
            {
                "price": 12876.31,
                "ask": 85,
                "bid": 46
            },
            {
                "price": 12918.42,
                "ask": 45,
                "bid": 7
            },
            {
                "price": 12984.19,
                "ask": 36,
                "bid": 67
            },
            {
                "price": 12814.85,
                "ask": 45,
                "bid": 10
            },
            {
                "price": 12877.4,
                "ask": 90,
                "bid": 89
            },
            {
                "price": 12822.12,
                "ask": 6,
                "bid": 23
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dce8a0566b871c88d2",
        "high": 12759.26,
        "open": 12905.79,
        "close": 12959.36,
        "low": 12886.48,
        "priceList": [
            {
                "price": 12759.38,
                "ask": 82,
                "bid": 97
            },
            {
                "price": 12838.87,
                "ask": 10,
                "bid": 50
            },
            {
                "price": 12992.66,
                "ask": 25,
                "bid": 26
            },
            {
                "price": 12922.96,
                "ask": 70,
                "bid": 19
            },
            {
                "price": 12876.72,
                "ask": 13,
                "bid": 23
            },
            {
                "price": 12965.09,
                "ask": 34,
                "bid": 60
            },
            {
                "price": 12866.47,
                "ask": 74,
                "bid": 86
            },
            {
                "price": 12992.72,
                "ask": 31,
                "bid": 75
            },
            {
                "price": 12772,
                "ask": 72,
                "bid": 29
            },
            {
                "price": 12941.48,
                "ask": 25,
                "bid": 20
            },
            {
                "price": 12799.77,
                "ask": 24,
                "bid": 63
            },
            {
                "price": 12813,
                "ask": 21,
                "bid": 72
            },
            {
                "price": 12876.47,
                "ask": 6,
                "bid": 54
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf5f2ed5982f815f1",
        "high": 12799.83,
        "open": 12737.04,
        "close": 12802.77,
        "low": 12742.42,
        "priceList": [
            {
                "price": 12925.84,
                "ask": 4,
                "bid": 48
            },
            {
                "price": 12919.41,
                "ask": 98,
                "bid": 84
            },
            {
                "price": 12719.89,
                "ask": 53,
                "bid": 9
            },
            {
                "price": 12754.17,
                "ask": 33,
                "bid": 88
            },
            {
                "price": 12882.06,
                "ask": 18,
                "bid": 40
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcdf6904213ba8dd0d",
        "high": 12714.45,
        "open": 12837.85,
        "close": 12957.07,
        "low": 12957.17,
        "priceList": [
            {
                "price": 12790.34,
                "ask": 91,
                "bid": 71
            },
            {
                "price": 12958.51,
                "ask": 96,
                "bid": 80
            },
            {
                "price": 12747.56,
                "ask": 17,
                "bid": 59
            },
            {
                "price": 12836.02,
                "ask": 21,
                "bid": 35
            },
            {
                "price": 12978.56,
                "ask": 59,
                "bid": 18
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcf3812ff30db9c72b",
        "high": 12712.22,
        "open": 12733.09,
        "close": 12724.84,
        "low": 12753.04,
        "priceList": [
            {
                "price": 12820.02,
                "ask": 51,
                "bid": 97
            },
            {
                "price": 12845.03,
                "ask": 97,
                "bid": 29
            },
            {
                "price": 12779.03,
                "ask": 81,
                "bid": 6
            },
            {
                "price": 12728.23,
                "ask": 73,
                "bid": 9
            },
            {
                "price": 12742.36,
                "ask": 47,
                "bid": 98
            },
            {
                "price": 12765.37,
                "ask": 24,
                "bid": 79
            },
            {
                "price": 12959.07,
                "ask": 100,
                "bid": 29
            },
            {
                "price": 12989.26,
                "ask": 79,
                "bid": 55
            },
            {
                "price": 12900.39,
                "ask": 19,
                "bid": 45
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc6ff3687d770ab7d4",
        "high": 12712.53,
        "open": 12716.33,
        "close": 12944.31,
        "low": 12863.19,
        "priceList": [
            {
                "price": 12988.38,
                "ask": 7,
                "bid": 48
            },
            {
                "price": 12702.24,
                "ask": 16,
                "bid": 14
            },
            {
                "price": 12777.79,
                "ask": 80,
                "bid": 75
            },
            {
                "price": 12964.39,
                "ask": 85,
                "bid": 38
            },
            {
                "price": 12800.76,
                "ask": 22,
                "bid": 80
            },
            {
                "price": 12798.02,
                "ask": 74,
                "bid": 69
            },
            {
                "price": 12795.02,
                "ask": 61,
                "bid": 33
            },
            {
                "price": 12839.72,
                "ask": 89,
                "bid": 28
            },
            {
                "price": 12967.44,
                "ask": 74,
                "bid": 1
            },
            {
                "price": 12770.35,
                "ask": 43,
                "bid": 62
            },
            {
                "price": 12865.63,
                "ask": 37,
                "bid": 94
            },
            {
                "price": 12856.94,
                "ask": 74,
                "bid": 3
            },
            {
                "price": 12880.92,
                "ask": 13,
                "bid": 28
            },
            {
                "price": 12813.23,
                "ask": 68,
                "bid": 96
            },
            {
                "price": 12774.03,
                "ask": 91,
                "bid": 75
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc876e8a52e3953d47",
        "high": 12981.08,
        "open": 12854.76,
        "close": 12717.77,
        "low": 12755.8,
        "priceList": [
            {
                "price": 12712.64,
                "ask": 30,
                "bid": 42
            },
            {
                "price": 12982.2,
                "ask": 38,
                "bid": 24
            },
            {
                "price": 12938.58,
                "ask": 12,
                "bid": 2
            },
            {
                "price": 12805.77,
                "ask": 68,
                "bid": 16
            },
            {
                "price": 12986.59,
                "ask": 54,
                "bid": 41
            },
            {
                "price": 12946.73,
                "ask": 49,
                "bid": 7
            },
            {
                "price": 12804.95,
                "ask": 82,
                "bid": 13
            },
            {
                "price": 12764.25,
                "ask": 7,
                "bid": 48
            },
            {
                "price": 12808.82,
                "ask": 89,
                "bid": 27
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc8baa4b220fb0bedf",
        "high": 12738.98,
        "open": 12789.08,
        "close": 12758.13,
        "low": 12977.09,
        "priceList": [
            {
                "price": 12900.46,
                "ask": 98,
                "bid": 23
            },
            {
                "price": 12903.17,
                "ask": 97,
                "bid": 62
            },
            {
                "price": 12849.99,
                "ask": 24,
                "bid": 49
            },
            {
                "price": 12997.91,
                "ask": 68,
                "bid": 54
            },
            {
                "price": 12994.44,
                "ask": 86,
                "bid": 90
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc7155ede2ffb0c57a",
        "high": 12931.98,
        "open": 12808.45,
        "close": 12980.47,
        "low": 12990.88,
        "priceList": [
            {
                "price": 12831.47,
                "ask": 23,
                "bid": 23
            },
            {
                "price": 12925.26,
                "ask": 42,
                "bid": 62
            },
            {
                "price": 12715.02,
                "ask": 34,
                "bid": 69
            },
            {
                "price": 12998.63,
                "ask": 46,
                "bid": 99
            },
            {
                "price": 12918.6,
                "ask": 37,
                "bid": 3
            },
            {
                "price": 12807.7,
                "ask": 76,
                "bid": 33
            },
            {
                "price": 12748.25,
                "ask": 16,
                "bid": 35
            },
            {
                "price": 12739.73,
                "ask": 69,
                "bid": 64
            },
            {
                "price": 12876.1,
                "ask": 76,
                "bid": 100
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcc90cde9b9d407ecf",
        "high": 12971.97,
        "open": 12842.91,
        "close": 12874.41,
        "low": 12918.31,
        "priceList": [
            {
                "price": 12821.7,
                "ask": 80,
                "bid": 58
            },
            {
                "price": 12706.03,
                "ask": 94,
                "bid": 18
            },
            {
                "price": 12916.58,
                "ask": 30,
                "bid": 60
            },
            {
                "price": 12905.69,
                "ask": 53,
                "bid": 91
            },
            {
                "price": 12980.7,
                "ask": 14,
                "bid": 21
            },
            {
                "price": 12875,
                "ask": 62,
                "bid": 6
            },
            {
                "price": 12766.84,
                "ask": 30,
                "bid": 13
            },
            {
                "price": 12994.81,
                "ask": 68,
                "bid": 90
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc63eede9fa565917d",
        "high": 12790.07,
        "open": 12775.15,
        "close": 12924.45,
        "low": 12813.22,
        "priceList": [
            {
                "price": 12825.67,
                "ask": 36,
                "bid": 75
            },
            {
                "price": 12772.22,
                "ask": 11,
                "bid": 11
            },
            {
                "price": 12799,
                "ask": 25,
                "bid": 26
            },
            {
                "price": 12988.96,
                "ask": 67,
                "bid": 52
            },
            {
                "price": 12894.26,
                "ask": 25,
                "bid": 20
            },
            {
                "price": 12879.58,
                "ask": 91,
                "bid": 20
            },
            {
                "price": 12976.84,
                "ask": 81,
                "bid": 57
            },
            {
                "price": 12817.51,
                "ask": 59,
                "bid": 9
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dca29146feda8eb884",
        "high": 12983.51,
        "open": 12759.63,
        "close": 12901.01,
        "low": 12710.14,
        "priceList": [
            {
                "price": 12825,
                "ask": 87,
                "bid": 79
            },
            {
                "price": 12890.06,
                "ask": 54,
                "bid": 36
            },
            {
                "price": 12751.49,
                "ask": 27,
                "bid": 67
            },
            {
                "price": 12794.45,
                "ask": 96,
                "bid": 53
            },
            {
                "price": 12799.12,
                "ask": 96,
                "bid": 59
            },
            {
                "price": 12846.68,
                "ask": 99,
                "bid": 39
            },
            {
                "price": 12701.79,
                "ask": 36,
                "bid": 98
            },
            {
                "price": 12839.31,
                "ask": 86,
                "bid": 80
            },
            {
                "price": 12858.9,
                "ask": 93,
                "bid": 59
            },
            {
                "price": 12902,
                "ask": 12,
                "bid": 0
            },
            {
                "price": 12809.29,
                "ask": 72,
                "bid": 47
            },
            {
                "price": 12800.14,
                "ask": 35,
                "bid": 72
            },
            {
                "price": 12922.34,
                "ask": 53,
                "bid": 36
            },
            {
                "price": 12723.25,
                "ask": 22,
                "bid": 95
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcd6ecde6a672ebaef",
        "high": 12989.78,
        "open": 12887.85,
        "close": 12879.25,
        "low": 12770.15,
        "priceList": [
            {
                "price": 12925.63,
                "ask": 61,
                "bid": 88
            },
            {
                "price": 12849.62,
                "ask": 88,
                "bid": 13
            },
            {
                "price": 12989.82,
                "ask": 18,
                "bid": 0
            },
            {
                "price": 12972.54,
                "ask": 5,
                "bid": 55
            },
            {
                "price": 12991.59,
                "ask": 51,
                "bid": 10
            },
            {
                "price": 12790.35,
                "ask": 86,
                "bid": 10
            },
            {
                "price": 12708.93,
                "ask": 22,
                "bid": 74
            },
            {
                "price": 12897.2,
                "ask": 43,
                "bid": 92
            },
            {
                "price": 12845.71,
                "ask": 16,
                "bid": 67
            },
            {
                "price": 12723.44,
                "ask": 99,
                "bid": 59
            },
            {
                "price": 12854.52,
                "ask": 63,
                "bid": 93
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcf6858b9a34b562da",
        "high": 12933.52,
        "open": 12865.59,
        "close": 12932.29,
        "low": 12859.08,
        "priceList": [
            {
                "price": 12769.4,
                "ask": 90,
                "bid": 37
            },
            {
                "price": 12749.24,
                "ask": 67,
                "bid": 45
            },
            {
                "price": 12869.73,
                "ask": 97,
                "bid": 14
            },
            {
                "price": 12735.92,
                "ask": 59,
                "bid": 35
            },
            {
                "price": 12853.39,
                "ask": 19,
                "bid": 44
            },
            {
                "price": 12980.43,
                "ask": 79,
                "bid": 96
            },
            {
                "price": 12756.66,
                "ask": 31,
                "bid": 50
            },
            {
                "price": 12988.66,
                "ask": 12,
                "bid": 61
            },
            {
                "price": 12837.92,
                "ask": 83,
                "bid": 82
            },
            {
                "price": 12726.7,
                "ask": 15,
                "bid": 6
            },
            {
                "price": 12834.34,
                "ask": 86,
                "bid": 18
            },
            {
                "price": 12929.93,
                "ask": 6,
                "bid": 13
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc3ce14d01d4f6f306",
        "high": 12987.44,
        "open": 12877.81,
        "close": 12883.73,
        "low": 12818.45,
        "priceList": [
            {
                "price": 12864.86,
                "ask": 3,
                "bid": 81
            },
            {
                "price": 12737.44,
                "ask": 61,
                "bid": 50
            },
            {
                "price": 12953.04,
                "ask": 100,
                "bid": 52
            },
            {
                "price": 12898.28,
                "ask": 78,
                "bid": 78
            },
            {
                "price": 12745.98,
                "ask": 26,
                "bid": 20
            },
            {
                "price": 12770.82,
                "ask": 5,
                "bid": 30
            },
            {
                "price": 12956.06,
                "ask": 3,
                "bid": 66
            },
            {
                "price": 12877.32,
                "ask": 84,
                "bid": 68
            },
            {
                "price": 12906,
                "ask": 62,
                "bid": 16
            },
            {
                "price": 12721.5,
                "ask": 86,
                "bid": 44
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc197fc5197ba4bfc5",
        "high": 12726.21,
        "open": 12835.2,
        "close": 12835.63,
        "low": 12819.38,
        "priceList": [
            {
                "price": 12727.96,
                "ask": 48,
                "bid": 68
            },
            {
                "price": 12999.23,
                "ask": 46,
                "bid": 61
            },
            {
                "price": 12796.28,
                "ask": 18,
                "bid": 89
            },
            {
                "price": 12960.1,
                "ask": 36,
                "bid": 75
            },
            {
                "price": 12780.64,
                "ask": 33,
                "bid": 97
            },
            {
                "price": 12764.68,
                "ask": 87,
                "bid": 83
            },
            {
                "price": 12856.85,
                "ask": 47,
                "bid": 66
            },
            {
                "price": 12803.68,
                "ask": 39,
                "bid": 57
            },
            {
                "price": 12781.57,
                "ask": 86,
                "bid": 70
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcf025bafac893b974",
        "high": 12907.61,
        "open": 12803.54,
        "close": 12883.8,
        "low": 12712.7,
        "priceList": [
            {
                "price": 12829.66,
                "ask": 82,
                "bid": 47
            },
            {
                "price": 12840.92,
                "ask": 54,
                "bid": 91
            },
            {
                "price": 12757.27,
                "ask": 38,
                "bid": 9
            },
            {
                "price": 12899.85,
                "ask": 27,
                "bid": 81
            },
            {
                "price": 12778.63,
                "ask": 23,
                "bid": 44
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcc3f38f620466ba44",
        "high": 12711.41,
        "open": 12714.79,
        "close": 12931.17,
        "low": 12989.87,
        "priceList": [
            {
                "price": 12827.71,
                "ask": 25,
                "bid": 76
            },
            {
                "price": 12821.89,
                "ask": 64,
                "bid": 74
            },
            {
                "price": 12957.7,
                "ask": 83,
                "bid": 22
            },
            {
                "price": 12908.73,
                "ask": 94,
                "bid": 39
            },
            {
                "price": 12847.26,
                "ask": 9,
                "bid": 14
            },
            {
                "price": 12969.79,
                "ask": 41,
                "bid": 54
            },
            {
                "price": 12769.9,
                "ask": 12,
                "bid": 89
            },
            {
                "price": 12732.28,
                "ask": 25,
                "bid": 59
            },
            {
                "price": 12903.89,
                "ask": 22,
                "bid": 6
            },
            {
                "price": 12703.13,
                "ask": 46,
                "bid": 2
            },
            {
                "price": 12899.85,
                "ask": 75,
                "bid": 18
            },
            {
                "price": 12922.17,
                "ask": 70,
                "bid": 97
            },
            {
                "price": 12966.51,
                "ask": 21,
                "bid": 17
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc13e1788c46b85bab",
        "high": 12863.17,
        "open": 12772.95,
        "close": 12876.02,
        "low": 12805.72,
        "priceList": [
            {
                "price": 12980.1,
                "ask": 7,
                "bid": 89
            },
            {
                "price": 12813.71,
                "ask": 82,
                "bid": 93
            },
            {
                "price": 12729.21,
                "ask": 97,
                "bid": 89
            },
            {
                "price": 12980.26,
                "ask": 45,
                "bid": 49
            },
            {
                "price": 12840.21,
                "ask": 80,
                "bid": 65
            },
            {
                "price": 12861.94,
                "ask": 58,
                "bid": 8
            },
            {
                "price": 12756.9,
                "ask": 68,
                "bid": 49
            },
            {
                "price": 12932.7,
                "ask": 65,
                "bid": 62
            },
            {
                "price": 12898.66,
                "ask": 68,
                "bid": 62
            },
            {
                "price": 12728.54,
                "ask": 33,
                "bid": 47
            },
            {
                "price": 12856.07,
                "ask": 18,
                "bid": 12
            },
            {
                "price": 12775.06,
                "ask": 47,
                "bid": 77
            },
            {
                "price": 12740.56,
                "ask": 61,
                "bid": 22
            },
            {
                "price": 12888.46,
                "ask": 32,
                "bid": 96
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc5ae2ca38f741e83d",
        "high": 12905.66,
        "open": 12723.67,
        "close": 12921.23,
        "low": 12887.25,
        "priceList": [
            {
                "price": 12762.65,
                "ask": 3,
                "bid": 47
            },
            {
                "price": 12762.67,
                "ask": 7,
                "bid": 18
            },
            {
                "price": 12962.97,
                "ask": 24,
                "bid": 89
            },
            {
                "price": 12736.5,
                "ask": 7,
                "bid": 78
            },
            {
                "price": 12829.25,
                "ask": 18,
                "bid": 85
            },
            {
                "price": 12765.29,
                "ask": 83,
                "bid": 34
            },
            {
                "price": 12870.99,
                "ask": 14,
                "bid": 99
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dca700f4a4161c0ce9",
        "high": 12904.7,
        "open": 12762,
        "close": 12865.77,
        "low": 12711.17,
        "priceList": [
            {
                "price": 12932.15,
                "ask": 10,
                "bid": 0
            },
            {
                "price": 12800.81,
                "ask": 22,
                "bid": 29
            },
            {
                "price": 12733.83,
                "ask": 47,
                "bid": 45
            },
            {
                "price": 12865.13,
                "ask": 96,
                "bid": 89
            },
            {
                "price": 12783.71,
                "ask": 73,
                "bid": 45
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc251065a08df12c94",
        "high": 12785.82,
        "open": 12909.94,
        "close": 12956.2,
        "low": 12872.85,
        "priceList": [
            {
                "price": 12720.44,
                "ask": 54,
                "bid": 39
            },
            {
                "price": 12918.53,
                "ask": 85,
                "bid": 92
            },
            {
                "price": 12706.48,
                "ask": 56,
                "bid": 12
            },
            {
                "price": 12747.82,
                "ask": 95,
                "bid": 7
            },
            {
                "price": 12947.37,
                "ask": 75,
                "bid": 34
            },
            {
                "price": 12802.48,
                "ask": 78,
                "bid": 70
            },
            {
                "price": 12871.6,
                "ask": 100,
                "bid": 84
            },
            {
                "price": 12877.21,
                "ask": 78,
                "bid": 1
            },
            {
                "price": 12956.25,
                "ask": 11,
                "bid": 42
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcc7f0146edd265fdc",
        "high": 12971.93,
        "open": 12712.09,
        "close": 12739.92,
        "low": 12861.44,
        "priceList": [
            {
                "price": 12957.77,
                "ask": 98,
                "bid": 1
            },
            {
                "price": 12876.1,
                "ask": 66,
                "bid": 24
            },
            {
                "price": 12810.3,
                "ask": 62,
                "bid": 4
            },
            {
                "price": 12793.3,
                "ask": 89,
                "bid": 50
            },
            {
                "price": 12760.04,
                "ask": 3,
                "bid": 59
            },
            {
                "price": 12906.02,
                "ask": 13,
                "bid": 21
            },
            {
                "price": 12910.23,
                "ask": 93,
                "bid": 20
            },
            {
                "price": 12711.99,
                "ask": 21,
                "bid": 91
            },
            {
                "price": 12820.03,
                "ask": 16,
                "bid": 37
            },
            {
                "price": 12787.18,
                "ask": 7,
                "bid": 93
            },
            {
                "price": 12786.07,
                "ask": 42,
                "bid": 18
            },
            {
                "price": 12966.01,
                "ask": 5,
                "bid": 42
            },
            {
                "price": 12769.26,
                "ask": 60,
                "bid": 64
            },
            {
                "price": 12859.59,
                "ask": 82,
                "bid": 22
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcef8c615c686134ac",
        "high": 12801.9,
        "open": 12904.89,
        "close": 12718.37,
        "low": 12742.81,
        "priceList": [
            {
                "price": 12812.6,
                "ask": 17,
                "bid": 21
            },
            {
                "price": 12790.95,
                "ask": 34,
                "bid": 20
            },
            {
                "price": 12860.05,
                "ask": 29,
                "bid": 59
            },
            {
                "price": 12918.8,
                "ask": 72,
                "bid": 29
            },
            {
                "price": 12911.8,
                "ask": 41,
                "bid": 78
            },
            {
                "price": 12958.25,
                "ask": 86,
                "bid": 0
            },
            {
                "price": 12917.34,
                "ask": 95,
                "bid": 74
            },
            {
                "price": 12714.72,
                "ask": 54,
                "bid": 46
            },
            {
                "price": 12953.11,
                "ask": 25,
                "bid": 76
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc8329ab80d50b7f59",
        "high": 12902.53,
        "open": 12849.8,
        "close": 12966.18,
        "low": 12814.04,
        "priceList": [
            {
                "price": 12906.05,
                "ask": 82,
                "bid": 94
            },
            {
                "price": 12712.63,
                "ask": 82,
                "bid": 0
            },
            {
                "price": 12883.32,
                "ask": 23,
                "bid": 19
            },
            {
                "price": 12799.37,
                "ask": 70,
                "bid": 5
            },
            {
                "price": 12978.68,
                "ask": 22,
                "bid": 15
            },
            {
                "price": 12770.1,
                "ask": 65,
                "bid": 57
            },
            {
                "price": 12929.52,
                "ask": 30,
                "bid": 1
            },
            {
                "price": 12743.03,
                "ask": 47,
                "bid": 57
            },
            {
                "price": 12768.1,
                "ask": 52,
                "bid": 46
            },
            {
                "price": 12793.64,
                "ask": 23,
                "bid": 19
            },
            {
                "price": 12944.88,
                "ask": 2,
                "bid": 83
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc1a1e30a82c0e4510",
        "high": 12811.31,
        "open": 12979.38,
        "close": 12859.85,
        "low": 12951.65,
        "priceList": [
            {
                "price": 12989.94,
                "ask": 0,
                "bid": 44
            },
            {
                "price": 12809.65,
                "ask": 39,
                "bid": 70
            },
            {
                "price": 12952.27,
                "ask": 54,
                "bid": 51
            },
            {
                "price": 12941.14,
                "ask": 25,
                "bid": 51
            },
            {
                "price": 12802.54,
                "ask": 59,
                "bid": 2
            },
            {
                "price": 12769.55,
                "ask": 94,
                "bid": 61
            },
            {
                "price": 12758.88,
                "ask": 79,
                "bid": 91
            },
            {
                "price": 12704.48,
                "ask": 85,
                "bid": 23
            },
            {
                "price": 12998.4,
                "ask": 45,
                "bid": 41
            },
            {
                "price": 12714.89,
                "ask": 43,
                "bid": 73
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc8b4b3ddfe5f5454a",
        "high": 12864.33,
        "open": 12852.96,
        "close": 12789.26,
        "low": 12868.84,
        "priceList": [
            {
                "price": 12754.98,
                "ask": 65,
                "bid": 71
            },
            {
                "price": 12938.6,
                "ask": 91,
                "bid": 43
            },
            {
                "price": 12764.61,
                "ask": 0,
                "bid": 42
            },
            {
                "price": 12936.06,
                "ask": 10,
                "bid": 5
            },
            {
                "price": 12926.78,
                "ask": 64,
                "bid": 33
            },
            {
                "price": 12758.55,
                "ask": 21,
                "bid": 73
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc4ba909af9c08c721",
        "high": 12731.05,
        "open": 12963.12,
        "close": 12845.98,
        "low": 12837.14,
        "priceList": [
            {
                "price": 12951.63,
                "ask": 97,
                "bid": 51
            },
            {
                "price": 12866.77,
                "ask": 98,
                "bid": 3
            },
            {
                "price": 12879.26,
                "ask": 37,
                "bid": 55
            },
            {
                "price": 12958.61,
                "ask": 2,
                "bid": 20
            },
            {
                "price": 12909.22,
                "ask": 0,
                "bid": 85
            },
            {
                "price": 12851.93,
                "ask": 85,
                "bid": 21
            },
            {
                "price": 12774.44,
                "ask": 40,
                "bid": 96
            },
            {
                "price": 12793.07,
                "ask": 32,
                "bid": 96
            },
            {
                "price": 12848.38,
                "ask": 58,
                "bid": 70
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcc49baa08ccaf18ab",
        "high": 12807.04,
        "open": 12740.22,
        "close": 12971.42,
        "low": 12944.15,
        "priceList": [
            {
                "price": 12994.92,
                "ask": 87,
                "bid": 1
            },
            {
                "price": 12719.62,
                "ask": 47,
                "bid": 47
            },
            {
                "price": 12760.33,
                "ask": 13,
                "bid": 64
            },
            {
                "price": 12935.55,
                "ask": 30,
                "bid": 97
            },
            {
                "price": 12925.22,
                "ask": 55,
                "bid": 66
            },
            {
                "price": 12914.02,
                "ask": 51,
                "bid": 2
            },
            {
                "price": 12989.69,
                "ask": 81,
                "bid": 33
            },
            {
                "price": 12728.47,
                "ask": 24,
                "bid": 48
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc72f0768ce4ea619b",
        "high": 12708.26,
        "open": 12753.02,
        "close": 12923.44,
        "low": 12838.02,
        "priceList": [
            {
                "price": 12829.06,
                "ask": 48,
                "bid": 85
            },
            {
                "price": 12943.74,
                "ask": 5,
                "bid": 90
            },
            {
                "price": 12765.81,
                "ask": 50,
                "bid": 49
            },
            {
                "price": 12937.22,
                "ask": 8,
                "bid": 32
            },
            {
                "price": 12955.69,
                "ask": 24,
                "bid": 50
            },
            {
                "price": 12727.31,
                "ask": 21,
                "bid": 14
            },
            {
                "price": 12951.03,
                "ask": 54,
                "bid": 86
            },
            {
                "price": 12808.38,
                "ask": 69,
                "bid": 88
            },
            {
                "price": 12973.76,
                "ask": 15,
                "bid": 13
            },
            {
                "price": 12961.47,
                "ask": 81,
                "bid": 37
            },
            {
                "price": 12871.95,
                "ask": 34,
                "bid": 54
            },
            {
                "price": 12857.72,
                "ask": 10,
                "bid": 53
            },
            {
                "price": 12789.52,
                "ask": 32,
                "bid": 74
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dce1f2303290c13d91",
        "high": 12999.56,
        "open": 12787.53,
        "close": 12881.94,
        "low": 12830.23,
        "priceList": [
            {
                "price": 12868.81,
                "ask": 61,
                "bid": 98
            },
            {
                "price": 12819.78,
                "ask": 44,
                "bid": 54
            },
            {
                "price": 12860.92,
                "ask": 29,
                "bid": 8
            },
            {
                "price": 12778.71,
                "ask": 83,
                "bid": 53
            },
            {
                "price": 12891.19,
                "ask": 34,
                "bid": 77
            },
            {
                "price": 12722.69,
                "ask": 49,
                "bid": 4
            },
            {
                "price": 12803.16,
                "ask": 46,
                "bid": 92
            },
            {
                "price": 12854.92,
                "ask": 55,
                "bid": 46
            },
            {
                "price": 12726.52,
                "ask": 79,
                "bid": 100
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc24cab836d34608b2",
        "high": 12749.97,
        "open": 12706.72,
        "close": 12824.53,
        "low": 12713.43,
        "priceList": [
            {
                "price": 12865.31,
                "ask": 50,
                "bid": 95
            },
            {
                "price": 12767.79,
                "ask": 45,
                "bid": 63
            },
            {
                "price": 12987.5,
                "ask": 40,
                "bid": 94
            },
            {
                "price": 12779.75,
                "ask": 95,
                "bid": 6
            },
            {
                "price": 12835.28,
                "ask": 92,
                "bid": 53
            },
            {
                "price": 12827.38,
                "ask": 0,
                "bid": 60
            },
            {
                "price": 12961.11,
                "ask": 49,
                "bid": 53
            },
            {
                "price": 12700.12,
                "ask": 79,
                "bid": 30
            },
            {
                "price": 12880.35,
                "ask": 29,
                "bid": 5
            },
            {
                "price": 12859.73,
                "ask": 80,
                "bid": 60
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcf66cbd1d0e0da80f",
        "high": 12783,
        "open": 12955.47,
        "close": 12824.15,
        "low": 12886.88,
        "priceList": [
            {
                "price": 12772.03,
                "ask": 74,
                "bid": 88
            },
            {
                "price": 12935.59,
                "ask": 69,
                "bid": 4
            },
            {
                "price": 12940,
                "ask": 27,
                "bid": 79
            },
            {
                "price": 12805.34,
                "ask": 63,
                "bid": 9
            },
            {
                "price": 12733.23,
                "ask": 15,
                "bid": 13
            },
            {
                "price": 12784.25,
                "ask": 93,
                "bid": 40
            },
            {
                "price": 12814.75,
                "ask": 100,
                "bid": 37
            },
            {
                "price": 12928.58,
                "ask": 45,
                "bid": 22
            },
            {
                "price": 12880.66,
                "ask": 48,
                "bid": 93
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc9b54e7238eaefb74",
        "high": 12868.15,
        "open": 12967.06,
        "close": 12971.56,
        "low": 12898.5,
        "priceList": [
            {
                "price": 12870.37,
                "ask": 19,
                "bid": 29
            },
            {
                "price": 12891.29,
                "ask": 48,
                "bid": 57
            },
            {
                "price": 12809.43,
                "ask": 22,
                "bid": 79
            },
            {
                "price": 12839.74,
                "ask": 95,
                "bid": 100
            },
            {
                "price": 12821.75,
                "ask": 54,
                "bid": 22
            },
            {
                "price": 12879.21,
                "ask": 38,
                "bid": 51
            },
            {
                "price": 12869.52,
                "ask": 100,
                "bid": 98
            },
            {
                "price": 12864.4,
                "ask": 65,
                "bid": 40
            },
            {
                "price": 12805.28,
                "ask": 34,
                "bid": 30
            },
            {
                "price": 12954.67,
                "ask": 43,
                "bid": 6
            },
            {
                "price": 12983.96,
                "ask": 98,
                "bid": 48
            },
            {
                "price": 12929.94,
                "ask": 49,
                "bid": 67
            },
            {
                "price": 12912.76,
                "ask": 71,
                "bid": 10
            },
            {
                "price": 12738.2,
                "ask": 72,
                "bid": 53
            },
            {
                "price": 12733.9,
                "ask": 45,
                "bid": 5
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc7648bf958c709b1d",
        "high": 12916.41,
        "open": 12823.83,
        "close": 12872.15,
        "low": 12996.52,
        "priceList": [
            {
                "price": 12925.42,
                "ask": 30,
                "bid": 30
            },
            {
                "price": 12848.05,
                "ask": 38,
                "bid": 56
            },
            {
                "price": 12715.02,
                "ask": 37,
                "bid": 82
            },
            {
                "price": 12880.2,
                "ask": 77,
                "bid": 100
            },
            {
                "price": 12823.32,
                "ask": 46,
                "bid": 71
            },
            {
                "price": 12773.25,
                "ask": 82,
                "bid": 85
            },
            {
                "price": 12767.03,
                "ask": 10,
                "bid": 83
            },
            {
                "price": 12996.75,
                "ask": 53,
                "bid": 28
            },
            {
                "price": 12912.91,
                "ask": 0,
                "bid": 29
            },
            {
                "price": 12884.61,
                "ask": 58,
                "bid": 6
            },
            {
                "price": 12952.9,
                "ask": 41,
                "bid": 49
            },
            {
                "price": 12784.98,
                "ask": 53,
                "bid": 8
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc505566b7b7f52c82",
        "high": 12720.43,
        "open": 12775.84,
        "close": 12899.59,
        "low": 12910.49,
        "priceList": [
            {
                "price": 12815.33,
                "ask": 80,
                "bid": 38
            },
            {
                "price": 12992.89,
                "ask": 47,
                "bid": 83
            },
            {
                "price": 12818.11,
                "ask": 53,
                "bid": 50
            },
            {
                "price": 12733.51,
                "ask": 46,
                "bid": 64
            },
            {
                "price": 12900.14,
                "ask": 20,
                "bid": 70
            },
            {
                "price": 12849.22,
                "ask": 30,
                "bid": 27
            },
            {
                "price": 12977.51,
                "ask": 19,
                "bid": 48
            },
            {
                "price": 12806.77,
                "ask": 94,
                "bid": 99
            },
            {
                "price": 12971.81,
                "ask": 37,
                "bid": 7
            },
            {
                "price": 12779.99,
                "ask": 51,
                "bid": 13
            },
            {
                "price": 12943.07,
                "ask": 63,
                "bid": 56
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcca22f0fd040a8891",
        "high": 12701.67,
        "open": 12873.19,
        "close": 12983.68,
        "low": 12773.39,
        "priceList": [
            {
                "price": 12931.14,
                "ask": 17,
                "bid": 37
            },
            {
                "price": 12973.29,
                "ask": 43,
                "bid": 28
            },
            {
                "price": 12896.39,
                "ask": 4,
                "bid": 96
            },
            {
                "price": 12823.35,
                "ask": 17,
                "bid": 44
            },
            {
                "price": 12935.41,
                "ask": 28,
                "bid": 66
            },
            {
                "price": 12975.64,
                "ask": 14,
                "bid": 83
            },
            {
                "price": 12921.25,
                "ask": 11,
                "bid": 86
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc1e78df0b698a001f",
        "high": 12726.89,
        "open": 12946.75,
        "close": 12997.76,
        "low": 12738.37,
        "priceList": [
            {
                "price": 12958.2,
                "ask": 15,
                "bid": 84
            },
            {
                "price": 12892.77,
                "ask": 17,
                "bid": 81
            },
            {
                "price": 12730.51,
                "ask": 14,
                "bid": 9
            },
            {
                "price": 12926.08,
                "ask": 41,
                "bid": 4
            },
            {
                "price": 12749.75,
                "ask": 55,
                "bid": 3
            },
            {
                "price": 12720.59,
                "ask": 77,
                "bid": 81
            },
            {
                "price": 12756.33,
                "ask": 14,
                "bid": 19
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc363ddb8f8c56f790",
        "high": 12891.57,
        "open": 12934.95,
        "close": 12863.61,
        "low": 12823.89,
        "priceList": [
            {
                "price": 12858.51,
                "ask": 52,
                "bid": 45
            },
            {
                "price": 12827.49,
                "ask": 22,
                "bid": 97
            },
            {
                "price": 12998.39,
                "ask": 77,
                "bid": 67
            },
            {
                "price": 12857.94,
                "ask": 82,
                "bid": 83
            },
            {
                "price": 12804.29,
                "ask": 39,
                "bid": 21
            },
            {
                "price": 12747.82,
                "ask": 99,
                "bid": 10
            },
            {
                "price": 12892.87,
                "ask": 85,
                "bid": 61
            },
            {
                "price": 12861.92,
                "ask": 55,
                "bid": 36
            },
            {
                "price": 12919.23,
                "ask": 28,
                "bid": 73
            },
            {
                "price": 12786.49,
                "ask": 92,
                "bid": 37
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcbc473c0aaa0c0830",
        "high": 12980.29,
        "open": 12917.68,
        "close": 12978.04,
        "low": 12745.62,
        "priceList": [
            {
                "price": 12912.32,
                "ask": 67,
                "bid": 25
            },
            {
                "price": 12781.09,
                "ask": 76,
                "bid": 72
            },
            {
                "price": 12813.86,
                "ask": 32,
                "bid": 5
            },
            {
                "price": 12856.14,
                "ask": 75,
                "bid": 5
            },
            {
                "price": 12759.18,
                "ask": 42,
                "bid": 96
            },
            {
                "price": 12980.55,
                "ask": 86,
                "bid": 1
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc5d269539b1b703cc",
        "high": 12733.44,
        "open": 12913.79,
        "close": 12859.44,
        "low": 12858.08,
        "priceList": [
            {
                "price": 12788.02,
                "ask": 31,
                "bid": 19
            },
            {
                "price": 12916.19,
                "ask": 28,
                "bid": 11
            },
            {
                "price": 12708.72,
                "ask": 25,
                "bid": 20
            },
            {
                "price": 12921.64,
                "ask": 56,
                "bid": 14
            },
            {
                "price": 12708.65,
                "ask": 73,
                "bid": 87
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcce71bd5a098013de",
        "high": 12931.54,
        "open": 12705.53,
        "close": 12714.7,
        "low": 12703.14,
        "priceList": [
            {
                "price": 12854.76,
                "ask": 94,
                "bid": 34
            },
            {
                "price": 12958.75,
                "ask": 23,
                "bid": 75
            },
            {
                "price": 12721.48,
                "ask": 48,
                "bid": 53
            },
            {
                "price": 12851.74,
                "ask": 89,
                "bid": 36
            },
            {
                "price": 12985.59,
                "ask": 100,
                "bid": 100
            },
            {
                "price": 12923.3,
                "ask": 32,
                "bid": 63
            },
            {
                "price": 12880.61,
                "ask": 23,
                "bid": 50
            },
            {
                "price": 12771.42,
                "ask": 36,
                "bid": 33
            },
            {
                "price": 12784.18,
                "ask": 94,
                "bid": 30
            },
            {
                "price": 12739.95,
                "ask": 9,
                "bid": 68
            },
            {
                "price": 12813.28,
                "ask": 94,
                "bid": 60
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dca0335196172fbbb2",
        "high": 12961.14,
        "open": 12835.83,
        "close": 12974.4,
        "low": 12785.21,
        "priceList": [
            {
                "price": 12927.44,
                "ask": 34,
                "bid": 42
            },
            {
                "price": 12738.38,
                "ask": 26,
                "bid": 9
            },
            {
                "price": 12856.15,
                "ask": 60,
                "bid": 13
            },
            {
                "price": 12871.03,
                "ask": 89,
                "bid": 69
            },
            {
                "price": 12940.9,
                "ask": 93,
                "bid": 45
            },
            {
                "price": 12898.98,
                "ask": 13,
                "bid": 52
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc9a976bfcc78492c1",
        "high": 12767.37,
        "open": 12860.76,
        "close": 12802.54,
        "low": 12875.42,
        "priceList": [
            {
                "price": 12711.85,
                "ask": 29,
                "bid": 22
            },
            {
                "price": 12785.43,
                "ask": 97,
                "bid": 14
            },
            {
                "price": 12899.6,
                "ask": 65,
                "bid": 78
            },
            {
                "price": 12803.42,
                "ask": 68,
                "bid": 81
            },
            {
                "price": 12967.34,
                "ask": 55,
                "bid": 44
            },
            {
                "price": 12702.69,
                "ask": 97,
                "bid": 100
            },
            {
                "price": 12958.76,
                "ask": 38,
                "bid": 58
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc8283710e22a0d38c",
        "high": 12881.42,
        "open": 12912.39,
        "close": 12973.92,
        "low": 12772.06,
        "priceList": [
            {
                "price": 12913.17,
                "ask": 17,
                "bid": 32
            },
            {
                "price": 12858.97,
                "ask": 30,
                "bid": 67
            },
            {
                "price": 12749.73,
                "ask": 70,
                "bid": 62
            },
            {
                "price": 12749,
                "ask": 68,
                "bid": 46
            },
            {
                "price": 12802.41,
                "ask": 41,
                "bid": 58
            },
            {
                "price": 12824.63,
                "ask": 46,
                "bid": 83
            },
            {
                "price": 12844.79,
                "ask": 54,
                "bid": 26
            },
            {
                "price": 12720.06,
                "ask": 61,
                "bid": 38
            },
            {
                "price": 12718.08,
                "ask": 78,
                "bid": 44
            },
            {
                "price": 12933.94,
                "ask": 13,
                "bid": 8
            },
            {
                "price": 12943.23,
                "ask": 67,
                "bid": 45
            },
            {
                "price": 12983.44,
                "ask": 74,
                "bid": 17
            },
            {
                "price": 12757.06,
                "ask": 22,
                "bid": 67
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf53de953f06c4feb",
        "high": 12919.51,
        "open": 12919.21,
        "close": 12776.41,
        "low": 12776.47,
        "priceList": [
            {
                "price": 12782.68,
                "ask": 83,
                "bid": 3
            },
            {
                "price": 12930.31,
                "ask": 98,
                "bid": 39
            },
            {
                "price": 12791.79,
                "ask": 99,
                "bid": 79
            },
            {
                "price": 12873.4,
                "ask": 62,
                "bid": 72
            },
            {
                "price": 12843.1,
                "ask": 91,
                "bid": 98
            },
            {
                "price": 12913.19,
                "ask": 14,
                "bid": 54
            },
            {
                "price": 12987.23,
                "ask": 52,
                "bid": 95
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcf64e46f04607a518",
        "high": 12727.15,
        "open": 12839.24,
        "close": 12875.6,
        "low": 12749.87,
        "priceList": [
            {
                "price": 12869.86,
                "ask": 98,
                "bid": 4
            },
            {
                "price": 12954,
                "ask": 81,
                "bid": 53
            },
            {
                "price": 12727.82,
                "ask": 9,
                "bid": 29
            },
            {
                "price": 12868.4,
                "ask": 91,
                "bid": 38
            },
            {
                "price": 12754.89,
                "ask": 94,
                "bid": 76
            },
            {
                "price": 12829.45,
                "ask": 16,
                "bid": 79
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc8629b3d9d0336950",
        "high": 12882.38,
        "open": 12920.3,
        "close": 12706.54,
        "low": 12746.03,
        "priceList": [
            {
                "price": 12993.08,
                "ask": 22,
                "bid": 96
            },
            {
                "price": 12727.62,
                "ask": 46,
                "bid": 78
            },
            {
                "price": 12985.19,
                "ask": 52,
                "bid": 25
            },
            {
                "price": 12751.16,
                "ask": 100,
                "bid": 50
            },
            {
                "price": 12797.96,
                "ask": 67,
                "bid": 18
            },
            {
                "price": 12876.69,
                "ask": 68,
                "bid": 38
            },
            {
                "price": 12774.6,
                "ask": 81,
                "bid": 4
            },
            {
                "price": 12892.46,
                "ask": 47,
                "bid": 2
            },
            {
                "price": 12900.4,
                "ask": 66,
                "bid": 6
            },
            {
                "price": 12985.7,
                "ask": 29,
                "bid": 94
            },
            {
                "price": 12913.6,
                "ask": 38,
                "bid": 28
            },
            {
                "price": 12878.83,
                "ask": 11,
                "bid": 59
            },
            {
                "price": 12746.09,
                "ask": 47,
                "bid": 21
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc838b20b2cf0dde5a",
        "high": 12926.7,
        "open": 12713.48,
        "close": 12906.9,
        "low": 12846.55,
        "priceList": [
            {
                "price": 12886.44,
                "ask": 77,
                "bid": 63
            },
            {
                "price": 12896.9,
                "ask": 100,
                "bid": 85
            },
            {
                "price": 12972.8,
                "ask": 9,
                "bid": 86
            },
            {
                "price": 12899.29,
                "ask": 43,
                "bid": 26
            },
            {
                "price": 12986.44,
                "ask": 31,
                "bid": 19
            },
            {
                "price": 12700.33,
                "ask": 34,
                "bid": 80
            },
            {
                "price": 12754.14,
                "ask": 63,
                "bid": 86
            },
            {
                "price": 12858.81,
                "ask": 90,
                "bid": 97
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dca1ade8e98fed4510",
        "high": 12747.88,
        "open": 12743.27,
        "close": 12776.68,
        "low": 12763.96,
        "priceList": [
            {
                "price": 12949.98,
                "ask": 100,
                "bid": 12
            },
            {
                "price": 12790.89,
                "ask": 9,
                "bid": 82
            },
            {
                "price": 12840.58,
                "ask": 50,
                "bid": 28
            },
            {
                "price": 12717.74,
                "ask": 90,
                "bid": 76
            },
            {
                "price": 12985.99,
                "ask": 83,
                "bid": 19
            },
            {
                "price": 12961.25,
                "ask": 9,
                "bid": 42
            },
            {
                "price": 12994.93,
                "ask": 58,
                "bid": 86
            },
            {
                "price": 12716.9,
                "ask": 89,
                "bid": 3
            },
            {
                "price": 12897.3,
                "ask": 28,
                "bid": 66
            },
            {
                "price": 12996.74,
                "ask": 31,
                "bid": 79
            },
            {
                "price": 12755.36,
                "ask": 60,
                "bid": 36
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcf8a1e9fe4740abcd",
        "high": 12784.76,
        "open": 12714,
        "close": 12801.76,
        "low": 12703.05,
        "priceList": [
            {
                "price": 12959.51,
                "ask": 4,
                "bid": 89
            },
            {
                "price": 12732.83,
                "ask": 86,
                "bid": 88
            },
            {
                "price": 12929.8,
                "ask": 63,
                "bid": 87
            },
            {
                "price": 12718.9,
                "ask": 58,
                "bid": 62
            },
            {
                "price": 12731.18,
                "ask": 40,
                "bid": 11
            },
            {
                "price": 12718.23,
                "ask": 74,
                "bid": 17
            },
            {
                "price": 12791.43,
                "ask": 99,
                "bid": 65
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc9c422177be4f363e",
        "high": 12858.74,
        "open": 12949.79,
        "close": 12833.32,
        "low": 12932.78,
        "priceList": [
            {
                "price": 12955.59,
                "ask": 7,
                "bid": 84
            },
            {
                "price": 12904.46,
                "ask": 7,
                "bid": 32
            },
            {
                "price": 12899.82,
                "ask": 85,
                "bid": 76
            },
            {
                "price": 12848.77,
                "ask": 81,
                "bid": 45
            },
            {
                "price": 12888.91,
                "ask": 52,
                "bid": 62
            },
            {
                "price": 12749.36,
                "ask": 11,
                "bid": 89
            },
            {
                "price": 12826.42,
                "ask": 2,
                "bid": 7
            },
            {
                "price": 12898,
                "ask": 34,
                "bid": 65
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dca843b9b481c09ec3",
        "high": 12786.6,
        "open": 12923.15,
        "close": 12869.66,
        "low": 12825.49,
        "priceList": [
            {
                "price": 12771.39,
                "ask": 68,
                "bid": 92
            },
            {
                "price": 12850.58,
                "ask": 87,
                "bid": 65
            },
            {
                "price": 12815.18,
                "ask": 92,
                "bid": 97
            },
            {
                "price": 12931.01,
                "ask": 35,
                "bid": 48
            },
            {
                "price": 12872.94,
                "ask": 91,
                "bid": 97
            },
            {
                "price": 12958.76,
                "ask": 10,
                "bid": 75
            },
            {
                "price": 12932.61,
                "ask": 38,
                "bid": 4
            },
            {
                "price": 12747.38,
                "ask": 65,
                "bid": 95
            },
            {
                "price": 12849.57,
                "ask": 85,
                "bid": 98
            },
            {
                "price": 12995.17,
                "ask": 73,
                "bid": 80
            },
            {
                "price": 12733.55,
                "ask": 49,
                "bid": 64
            },
            {
                "price": 12863.67,
                "ask": 61,
                "bid": 35
            },
            {
                "price": 12755.85,
                "ask": 59,
                "bid": 62
            },
            {
                "price": 12757.99,
                "ask": 0,
                "bid": 86
            },
            {
                "price": 12989.51,
                "ask": 44,
                "bid": 72
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dccb099bd2d00d94a5",
        "high": 12752.23,
        "open": 12799.79,
        "close": 12985.88,
        "low": 12911.16,
        "priceList": [
            {
                "price": 12940.22,
                "ask": 76,
                "bid": 13
            },
            {
                "price": 12909.26,
                "ask": 1,
                "bid": 34
            },
            {
                "price": 12784.42,
                "ask": 98,
                "bid": 28
            },
            {
                "price": 12953,
                "ask": 56,
                "bid": 94
            },
            {
                "price": 12899.18,
                "ask": 69,
                "bid": 99
            },
            {
                "price": 12967.01,
                "ask": 16,
                "bid": 31
            },
            {
                "price": 12807.03,
                "ask": 4,
                "bid": 90
            },
            {
                "price": 12972.64,
                "ask": 57,
                "bid": 14
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc01bf7a28289793db",
        "high": 12742.1,
        "open": 12791.25,
        "close": 12992,
        "low": 12761.88,
        "priceList": [
            {
                "price": 12900,
                "ask": 91,
                "bid": 87
            },
            {
                "price": 12929.34,
                "ask": 53,
                "bid": 55
            },
            {
                "price": 12982.97,
                "ask": 81,
                "bid": 89
            },
            {
                "price": 12809.14,
                "ask": 98,
                "bid": 12
            },
            {
                "price": 12922.61,
                "ask": 90,
                "bid": 95
            },
            {
                "price": 12757.56,
                "ask": 44,
                "bid": 63
            },
            {
                "price": 12770.51,
                "ask": 57,
                "bid": 13
            },
            {
                "price": 12812.37,
                "ask": 85,
                "bid": 73
            },
            {
                "price": 12921.63,
                "ask": 27,
                "bid": 12
            },
            {
                "price": 12789.55,
                "ask": 32,
                "bid": 71
            },
            {
                "price": 12979.73,
                "ask": 26,
                "bid": 64
            },
            {
                "price": 12737.76,
                "ask": 74,
                "bid": 53
            },
            {
                "price": 12700.22,
                "ask": 39,
                "bid": 76
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc5baa8d97d137be9d",
        "high": 12740.28,
        "open": 12841.5,
        "close": 12996.99,
        "low": 12721,
        "priceList": [
            {
                "price": 12734.03,
                "ask": 74,
                "bid": 77
            },
            {
                "price": 12993.25,
                "ask": 98,
                "bid": 34
            },
            {
                "price": 12839.5,
                "ask": 31,
                "bid": 44
            },
            {
                "price": 12998.94,
                "ask": 4,
                "bid": 25
            },
            {
                "price": 12814.48,
                "ask": 71,
                "bid": 1
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc027d169a866d4b2b",
        "high": 12885.52,
        "open": 12947.04,
        "close": 12780.18,
        "low": 12884.04,
        "priceList": [
            {
                "price": 12846.88,
                "ask": 64,
                "bid": 79
            },
            {
                "price": 12921.98,
                "ask": 24,
                "bid": 61
            },
            {
                "price": 12975.93,
                "ask": 19,
                "bid": 82
            },
            {
                "price": 12937.73,
                "ask": 37,
                "bid": 31
            },
            {
                "price": 12875.23,
                "ask": 32,
                "bid": 21
            },
            {
                "price": 12940.86,
                "ask": 7,
                "bid": 48
            },
            {
                "price": 12811.29,
                "ask": 79,
                "bid": 69
            },
            {
                "price": 12978.03,
                "ask": 12,
                "bid": 16
            },
            {
                "price": 12732.43,
                "ask": 93,
                "bid": 73
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dce7b431b3009c2009",
        "high": 12758.55,
        "open": 12773.39,
        "close": 12921.09,
        "low": 12872.96,
        "priceList": [
            {
                "price": 12762.47,
                "ask": 27,
                "bid": 28
            },
            {
                "price": 12918.62,
                "ask": 54,
                "bid": 93
            },
            {
                "price": 12798.01,
                "ask": 67,
                "bid": 15
            },
            {
                "price": 12719.63,
                "ask": 77,
                "bid": 82
            },
            {
                "price": 12927.38,
                "ask": 92,
                "bid": 79
            },
            {
                "price": 12800.27,
                "ask": 1,
                "bid": 52
            },
            {
                "price": 12822.26,
                "ask": 94,
                "bid": 40
            },
            {
                "price": 12819.5,
                "ask": 87,
                "bid": 60
            },
            {
                "price": 12826.14,
                "ask": 77,
                "bid": 39
            },
            {
                "price": 12965.27,
                "ask": 41,
                "bid": 34
            },
            {
                "price": 12750.44,
                "ask": 84,
                "bid": 85
            },
            {
                "price": 12848.94,
                "ask": 37,
                "bid": 69
            },
            {
                "price": 12759.71,
                "ask": 64,
                "bid": 13
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc686827f22629dbb7",
        "high": 12933.24,
        "open": 12892.51,
        "close": 12727.76,
        "low": 12797.13,
        "priceList": [
            {
                "price": 12823.02,
                "ask": 9,
                "bid": 100
            },
            {
                "price": 12856.45,
                "ask": 48,
                "bid": 31
            },
            {
                "price": 12774.48,
                "ask": 67,
                "bid": 92
            },
            {
                "price": 12985.87,
                "ask": 55,
                "bid": 41
            },
            {
                "price": 12922.89,
                "ask": 33,
                "bid": 36
            },
            {
                "price": 12992.46,
                "ask": 35,
                "bid": 34
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc648375512d9630b0",
        "high": 12886.65,
        "open": 12930.33,
        "close": 12820.96,
        "low": 12908.22,
        "priceList": [
            {
                "price": 12838.33,
                "ask": 43,
                "bid": 30
            },
            {
                "price": 12707.62,
                "ask": 8,
                "bid": 82
            },
            {
                "price": 12793.67,
                "ask": 90,
                "bid": 18
            },
            {
                "price": 12951.5,
                "ask": 27,
                "bid": 54
            },
            {
                "price": 12703.83,
                "ask": 95,
                "bid": 4
            },
            {
                "price": 12860.51,
                "ask": 9,
                "bid": 51
            },
            {
                "price": 12850.45,
                "ask": 68,
                "bid": 62
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dca0bbe5be259f11ba",
        "high": 12976.59,
        "open": 12849.45,
        "close": 12743.71,
        "low": 12774.98,
        "priceList": [
            {
                "price": 12999.17,
                "ask": 99,
                "bid": 31
            },
            {
                "price": 12954.82,
                "ask": 83,
                "bid": 35
            },
            {
                "price": 12906.13,
                "ask": 8,
                "bid": 92
            },
            {
                "price": 12915.52,
                "ask": 73,
                "bid": 50
            },
            {
                "price": 12971.95,
                "ask": 85,
                "bid": 99
            },
            {
                "price": 12854.87,
                "ask": 89,
                "bid": 2
            },
            {
                "price": 12933.41,
                "ask": 92,
                "bid": 84
            },
            {
                "price": 12930.46,
                "ask": 78,
                "bid": 1
            },
            {
                "price": 12997.39,
                "ask": 41,
                "bid": 0
            },
            {
                "price": 12852.25,
                "ask": 0,
                "bid": 8
            },
            {
                "price": 12825.53,
                "ask": 31,
                "bid": 69
            },
            {
                "price": 12842.05,
                "ask": 43,
                "bid": 39
            },
            {
                "price": 12976.1,
                "ask": 96,
                "bid": 50
            },
            {
                "price": 12805.95,
                "ask": 28,
                "bid": 21
            },
            {
                "price": 12832.16,
                "ask": 57,
                "bid": 95
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6bc188a9869420a2",
        "high": 12980.34,
        "open": 12892.18,
        "close": 12956.3,
        "low": 12863.66,
        "priceList": [
            {
                "price": 12974.38,
                "ask": 100,
                "bid": 8
            },
            {
                "price": 12846.72,
                "ask": 35,
                "bid": 5
            },
            {
                "price": 12998.66,
                "ask": 70,
                "bid": 73
            },
            {
                "price": 12953.53,
                "ask": 42,
                "bid": 83
            },
            {
                "price": 12772.74,
                "ask": 70,
                "bid": 12
            },
            {
                "price": 12973.06,
                "ask": 11,
                "bid": 52
            },
            {
                "price": 12920.89,
                "ask": 94,
                "bid": 47
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dce7cdd88585b94132",
        "high": 12705.83,
        "open": 12756.29,
        "close": 12899.07,
        "low": 12773.92,
        "priceList": [
            {
                "price": 12750.03,
                "ask": 45,
                "bid": 100
            },
            {
                "price": 12961.83,
                "ask": 69,
                "bid": 77
            },
            {
                "price": 12736.84,
                "ask": 45,
                "bid": 90
            },
            {
                "price": 12801.77,
                "ask": 84,
                "bid": 75
            },
            {
                "price": 12731.69,
                "ask": 51,
                "bid": 85
            },
            {
                "price": 12981.35,
                "ask": 92,
                "bid": 7
            },
            {
                "price": 12720.07,
                "ask": 11,
                "bid": 72
            },
            {
                "price": 12963.08,
                "ask": 72,
                "bid": 18
            },
            {
                "price": 12999.83,
                "ask": 66,
                "bid": 73
            },
            {
                "price": 12929.67,
                "ask": 67,
                "bid": 83
            },
            {
                "price": 12787.77,
                "ask": 23,
                "bid": 35
            },
            {
                "price": 12997.02,
                "ask": 92,
                "bid": 26
            },
            {
                "price": 12871.8,
                "ask": 33,
                "bid": 73
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc5038a0b45aa1baf2",
        "high": 12792.49,
        "open": 12829.8,
        "close": 12913.08,
        "low": 12911.74,
        "priceList": [
            {
                "price": 12812.07,
                "ask": 54,
                "bid": 6
            },
            {
                "price": 12924.15,
                "ask": 21,
                "bid": 9
            },
            {
                "price": 12850.82,
                "ask": 16,
                "bid": 36
            },
            {
                "price": 12951.96,
                "ask": 8,
                "bid": 21
            },
            {
                "price": 12940.24,
                "ask": 8,
                "bid": 95
            },
            {
                "price": 12834.89,
                "ask": 59,
                "bid": 1
            },
            {
                "price": 12855.77,
                "ask": 13,
                "bid": 43
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc0e30f971df172637",
        "high": 12900.76,
        "open": 12752.7,
        "close": 12797.76,
        "low": 12702.22,
        "priceList": [
            {
                "price": 12780.01,
                "ask": 25,
                "bid": 58
            },
            {
                "price": 12910.78,
                "ask": 15,
                "bid": 5
            },
            {
                "price": 12820.17,
                "ask": 4,
                "bid": 65
            },
            {
                "price": 12796.1,
                "ask": 92,
                "bid": 86
            },
            {
                "price": 12788.44,
                "ask": 63,
                "bid": 33
            },
            {
                "price": 12946.06,
                "ask": 83,
                "bid": 2
            },
            {
                "price": 12836,
                "ask": 32,
                "bid": 37
            },
            {
                "price": 12871.93,
                "ask": 63,
                "bid": 15
            },
            {
                "price": 12750.49,
                "ask": 78,
                "bid": 57
            },
            {
                "price": 12860.11,
                "ask": 77,
                "bid": 41
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcc1ff23566c6640b7",
        "high": 12781.57,
        "open": 12886.24,
        "close": 12836.28,
        "low": 12850.16,
        "priceList": [
            {
                "price": 12836.66,
                "ask": 89,
                "bid": 74
            },
            {
                "price": 12783.89,
                "ask": 98,
                "bid": 73
            },
            {
                "price": 12732.73,
                "ask": 38,
                "bid": 97
            },
            {
                "price": 12843.47,
                "ask": 5,
                "bid": 68
            },
            {
                "price": 12723.82,
                "ask": 1,
                "bid": 15
            },
            {
                "price": 12991.21,
                "ask": 74,
                "bid": 16
            },
            {
                "price": 12981.59,
                "ask": 93,
                "bid": 89
            },
            {
                "price": 12928.48,
                "ask": 50,
                "bid": 75
            },
            {
                "price": 12892.72,
                "ask": 44,
                "bid": 86
            },
            {
                "price": 12771.65,
                "ask": 28,
                "bid": 87
            },
            {
                "price": 12912.75,
                "ask": 37,
                "bid": 71
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc23c98ab6b0285a73",
        "high": 12891.23,
        "open": 12986.95,
        "close": 12996.85,
        "low": 12791.53,
        "priceList": [
            {
                "price": 12861.67,
                "ask": 13,
                "bid": 82
            },
            {
                "price": 12995.3,
                "ask": 18,
                "bid": 8
            },
            {
                "price": 12995.27,
                "ask": 26,
                "bid": 26
            },
            {
                "price": 12750.43,
                "ask": 69,
                "bid": 39
            },
            {
                "price": 12870.46,
                "ask": 51,
                "bid": 34
            },
            {
                "price": 12746.08,
                "ask": 9,
                "bid": 53
            },
            {
                "price": 12874.91,
                "ask": 85,
                "bid": 73
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc20ab3a83f3612b7c",
        "high": 12850.49,
        "open": 12711.98,
        "close": 12736.59,
        "low": 12847.79,
        "priceList": [
            {
                "price": 12857.77,
                "ask": 57,
                "bid": 27
            },
            {
                "price": 12865.03,
                "ask": 42,
                "bid": 20
            },
            {
                "price": 12738.7,
                "ask": 58,
                "bid": 59
            },
            {
                "price": 12705.15,
                "ask": 0,
                "bid": 63
            },
            {
                "price": 12762.44,
                "ask": 32,
                "bid": 98
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc3535f82829464a76",
        "high": 12809.91,
        "open": 12837.08,
        "close": 12941.85,
        "low": 12973.92,
        "priceList": [
            {
                "price": 12996.42,
                "ask": 55,
                "bid": 57
            },
            {
                "price": 12869.09,
                "ask": 81,
                "bid": 11
            },
            {
                "price": 12877.62,
                "ask": 5,
                "bid": 38
            },
            {
                "price": 12812.97,
                "ask": 62,
                "bid": 22
            },
            {
                "price": 12966.3,
                "ask": 61,
                "bid": 58
            },
            {
                "price": 12967.93,
                "ask": 77,
                "bid": 8
            },
            {
                "price": 12735.62,
                "ask": 11,
                "bid": 66
            },
            {
                "price": 12871.95,
                "ask": 54,
                "bid": 95
            },
            {
                "price": 12776.31,
                "ask": 75,
                "bid": 4
            },
            {
                "price": 12988.13,
                "ask": 49,
                "bid": 85
            },
            {
                "price": 12890.75,
                "ask": 37,
                "bid": 85
            },
            {
                "price": 12770.14,
                "ask": 43,
                "bid": 21
            },
            {
                "price": 12837.88,
                "ask": 71,
                "bid": 46
            },
            {
                "price": 12795.56,
                "ask": 14,
                "bid": 10
            },
            {
                "price": 12811.52,
                "ask": 19,
                "bid": 18
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcea1b6dad73b643fe",
        "high": 12906.29,
        "open": 12811.43,
        "close": 12827.01,
        "low": 12809.23,
        "priceList": [
            {
                "price": 12805.56,
                "ask": 61,
                "bid": 95
            },
            {
                "price": 12775.39,
                "ask": 23,
                "bid": 81
            },
            {
                "price": 12911.72,
                "ask": 74,
                "bid": 2
            },
            {
                "price": 12837.58,
                "ask": 67,
                "bid": 16
            },
            {
                "price": 12736.69,
                "ask": 79,
                "bid": 99
            },
            {
                "price": 12707.6,
                "ask": 6,
                "bid": 91
            },
            {
                "price": 12754.59,
                "ask": 46,
                "bid": 35
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc9eda9b51276cbc1b",
        "high": 12976.66,
        "open": 12939.76,
        "close": 12942.94,
        "low": 12736.34,
        "priceList": [
            {
                "price": 12932.28,
                "ask": 10,
                "bid": 32
            },
            {
                "price": 12960.23,
                "ask": 16,
                "bid": 11
            },
            {
                "price": 12823.14,
                "ask": 95,
                "bid": 45
            },
            {
                "price": 12738.52,
                "ask": 17,
                "bid": 21
            },
            {
                "price": 12793.92,
                "ask": 28,
                "bid": 43
            },
            {
                "price": 12763.28,
                "ask": 29,
                "bid": 84
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc8bb588e265949926",
        "high": 12810.57,
        "open": 12701.77,
        "close": 12831.21,
        "low": 12917.55,
        "priceList": [
            {
                "price": 12913.8,
                "ask": 19,
                "bid": 57
            },
            {
                "price": 12740.74,
                "ask": 52,
                "bid": 34
            },
            {
                "price": 12959.75,
                "ask": 13,
                "bid": 77
            },
            {
                "price": 12890.02,
                "ask": 11,
                "bid": 38
            },
            {
                "price": 12918.99,
                "ask": 98,
                "bid": 45
            },
            {
                "price": 12739.49,
                "ask": 82,
                "bid": 64
            },
            {
                "price": 12827.16,
                "ask": 24,
                "bid": 47
            },
            {
                "price": 12800.39,
                "ask": 4,
                "bid": 52
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc46f266f2f3f7e250",
        "high": 12987.96,
        "open": 12773.56,
        "close": 12716.61,
        "low": 12850.26,
        "priceList": [
            {
                "price": 12978.42,
                "ask": 35,
                "bid": 58
            },
            {
                "price": 12893.73,
                "ask": 35,
                "bid": 21
            },
            {
                "price": 12933.97,
                "ask": 69,
                "bid": 52
            },
            {
                "price": 12915.9,
                "ask": 34,
                "bid": 49
            },
            {
                "price": 12828.96,
                "ask": 37,
                "bid": 86
            },
            {
                "price": 12751.35,
                "ask": 95,
                "bid": 57
            },
            {
                "price": 12876.57,
                "ask": 77,
                "bid": 72
            },
            {
                "price": 12985.67,
                "ask": 40,
                "bid": 50
            },
            {
                "price": 12790.56,
                "ask": 23,
                "bid": 37
            },
            {
                "price": 12767.88,
                "ask": 61,
                "bid": 29
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc53ef4cbee1f8e33f",
        "high": 12822.95,
        "open": 12986.58,
        "close": 12816.96,
        "low": 12879.91,
        "priceList": [
            {
                "price": 12739.79,
                "ask": 87,
                "bid": 20
            },
            {
                "price": 12902.15,
                "ask": 45,
                "bid": 36
            },
            {
                "price": 12985.53,
                "ask": 5,
                "bid": 94
            },
            {
                "price": 12700.08,
                "ask": 28,
                "bid": 61
            },
            {
                "price": 12831.1,
                "ask": 13,
                "bid": 32
            },
            {
                "price": 12877.62,
                "ask": 29,
                "bid": 29
            },
            {
                "price": 12807.91,
                "ask": 87,
                "bid": 52
            },
            {
                "price": 12700.56,
                "ask": 78,
                "bid": 51
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc72d0ecd160092e78",
        "high": 12768.37,
        "open": 12915.44,
        "close": 12925.44,
        "low": 12735.29,
        "priceList": [
            {
                "price": 12941.75,
                "ask": 70,
                "bid": 72
            },
            {
                "price": 12951.18,
                "ask": 73,
                "bid": 2
            },
            {
                "price": 12964.3,
                "ask": 63,
                "bid": 63
            },
            {
                "price": 12834.17,
                "ask": 17,
                "bid": 41
            },
            {
                "price": 12819.38,
                "ask": 46,
                "bid": 58
            },
            {
                "price": 12832.47,
                "ask": 54,
                "bid": 12
            },
            {
                "price": 12783.19,
                "ask": 28,
                "bid": 3
            },
            {
                "price": 12835.76,
                "ask": 54,
                "bid": 46
            },
            {
                "price": 12934,
                "ask": 78,
                "bid": 21
            },
            {
                "price": 12760.31,
                "ask": 89,
                "bid": 45
            },
            {
                "price": 12763.46,
                "ask": 49,
                "bid": 32
            },
            {
                "price": 12952.2,
                "ask": 85,
                "bid": 25
            },
            {
                "price": 12800.03,
                "ask": 57,
                "bid": 57
            },
            {
                "price": 12999.67,
                "ask": 46,
                "bid": 18
            },
            {
                "price": 12722.43,
                "ask": 96,
                "bid": 40
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcac4409cda987c4cb",
        "high": 12867.77,
        "open": 12845.77,
        "close": 12831.76,
        "low": 12896.45,
        "priceList": [
            {
                "price": 12981.99,
                "ask": 75,
                "bid": 6
            },
            {
                "price": 12968.29,
                "ask": 41,
                "bid": 78
            },
            {
                "price": 12988.85,
                "ask": 75,
                "bid": 37
            },
            {
                "price": 12851.02,
                "ask": 77,
                "bid": 25
            },
            {
                "price": 12881.23,
                "ask": 90,
                "bid": 28
            },
            {
                "price": 12730.87,
                "ask": 18,
                "bid": 31
            },
            {
                "price": 12822.85,
                "ask": 6,
                "bid": 10
            },
            {
                "price": 12904.75,
                "ask": 55,
                "bid": 15
            },
            {
                "price": 12733.98,
                "ask": 42,
                "bid": 48
            },
            {
                "price": 12733.01,
                "ask": 84,
                "bid": 42
            },
            {
                "price": 12732.22,
                "ask": 19,
                "bid": 55
            },
            {
                "price": 12853.65,
                "ask": 80,
                "bid": 100
            },
            {
                "price": 12880.83,
                "ask": 97,
                "bid": 78
            },
            {
                "price": 12802.5,
                "ask": 1,
                "bid": 89
            },
            {
                "price": 12811.16,
                "ask": 42,
                "bid": 97
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcba09f2b766da75bb",
        "high": 12790.48,
        "open": 12977.13,
        "close": 12788.55,
        "low": 12975.66,
        "priceList": [
            {
                "price": 12966.06,
                "ask": 35,
                "bid": 47
            },
            {
                "price": 12757.34,
                "ask": 50,
                "bid": 76
            },
            {
                "price": 12716.21,
                "ask": 39,
                "bid": 27
            },
            {
                "price": 12733.2,
                "ask": 13,
                "bid": 92
            },
            {
                "price": 12917.64,
                "ask": 72,
                "bid": 34
            },
            {
                "price": 12839.53,
                "ask": 90,
                "bid": 56
            },
            {
                "price": 12768.34,
                "ask": 50,
                "bid": 90
            },
            {
                "price": 12978.67,
                "ask": 77,
                "bid": 29
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc628428267b0b7213",
        "high": 12940.32,
        "open": 12896.46,
        "close": 12887.66,
        "low": 12969.64,
        "priceList": [
            {
                "price": 12775.33,
                "ask": 22,
                "bid": 95
            },
            {
                "price": 12718.47,
                "ask": 14,
                "bid": 67
            },
            {
                "price": 12853.22,
                "ask": 88,
                "bid": 42
            },
            {
                "price": 12914.27,
                "ask": 52,
                "bid": 17
            },
            {
                "price": 12920.31,
                "ask": 76,
                "bid": 60
            },
            {
                "price": 12914.99,
                "ask": 66,
                "bid": 47
            },
            {
                "price": 12989.77,
                "ask": 94,
                "bid": 52
            },
            {
                "price": 12809.52,
                "ask": 88,
                "bid": 71
            },
            {
                "price": 12784.26,
                "ask": 14,
                "bid": 39
            },
            {
                "price": 12700.94,
                "ask": 20,
                "bid": 25
            },
            {
                "price": 12979.5,
                "ask": 63,
                "bid": 84
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc75afcd0d4e886a1b",
        "high": 12890.33,
        "open": 12832.78,
        "close": 12824.29,
        "low": 12928.94,
        "priceList": [
            {
                "price": 12968.48,
                "ask": 11,
                "bid": 96
            },
            {
                "price": 12828.35,
                "ask": 89,
                "bid": 16
            },
            {
                "price": 12917.02,
                "ask": 11,
                "bid": 73
            },
            {
                "price": 12875.1,
                "ask": 27,
                "bid": 49
            },
            {
                "price": 12829.93,
                "ask": 82,
                "bid": 66
            },
            {
                "price": 12738.5,
                "ask": 48,
                "bid": 76
            },
            {
                "price": 12854.88,
                "ask": 9,
                "bid": 28
            },
            {
                "price": 12975.47,
                "ask": 32,
                "bid": 20
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dce947f94dc323a4bd",
        "high": 12735.11,
        "open": 12921.09,
        "close": 12753.28,
        "low": 12721.05,
        "priceList": [
            {
                "price": 12839.17,
                "ask": 12,
                "bid": 0
            },
            {
                "price": 12893.11,
                "ask": 60,
                "bid": 62
            },
            {
                "price": 12890.81,
                "ask": 88,
                "bid": 16
            },
            {
                "price": 12707.15,
                "ask": 0,
                "bid": 40
            },
            {
                "price": 12745.55,
                "ask": 53,
                "bid": 100
            },
            {
                "price": 12834.14,
                "ask": 52,
                "bid": 1
            },
            {
                "price": 12729.05,
                "ask": 34,
                "bid": 92
            },
            {
                "price": 12892.89,
                "ask": 87,
                "bid": 87
            },
            {
                "price": 12894.35,
                "ask": 71,
                "bid": 40
            },
            {
                "price": 12760.82,
                "ask": 59,
                "bid": 55
            },
            {
                "price": 12707.39,
                "ask": 65,
                "bid": 35
            },
            {
                "price": 12920.29,
                "ask": 88,
                "bid": 53
            },
            {
                "price": 12711.98,
                "ask": 55,
                "bid": 36
            },
            {
                "price": 12949.83,
                "ask": 43,
                "bid": 49
            },
            {
                "price": 12877.92,
                "ask": 98,
                "bid": 26
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc3cd12d4130101e30",
        "high": 12894.63,
        "open": 12916.33,
        "close": 12736.17,
        "low": 12887.82,
        "priceList": [
            {
                "price": 12759.82,
                "ask": 57,
                "bid": 69
            },
            {
                "price": 12945.63,
                "ask": 32,
                "bid": 84
            },
            {
                "price": 12857.88,
                "ask": 99,
                "bid": 13
            },
            {
                "price": 12953.3,
                "ask": 72,
                "bid": 38
            },
            {
                "price": 12754.86,
                "ask": 91,
                "bid": 15
            },
            {
                "price": 12743.94,
                "ask": 60,
                "bid": 9
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc9891d4fbb983bb91",
        "high": 12753.84,
        "open": 12877.59,
        "close": 12823.33,
        "low": 12784.66,
        "priceList": [
            {
                "price": 12723.69,
                "ask": 49,
                "bid": 60
            },
            {
                "price": 12841.35,
                "ask": 71,
                "bid": 84
            },
            {
                "price": 12881.54,
                "ask": 94,
                "bid": 93
            },
            {
                "price": 12888.87,
                "ask": 24,
                "bid": 92
            },
            {
                "price": 12952.61,
                "ask": 41,
                "bid": 82
            },
            {
                "price": 12968.37,
                "ask": 8,
                "bid": 7
            },
            {
                "price": 12997.1,
                "ask": 57,
                "bid": 78
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcf21187ef27cefaf0",
        "high": 12945.98,
        "open": 12745.38,
        "close": 12732.98,
        "low": 12897.71,
        "priceList": [
            {
                "price": 12849.69,
                "ask": 61,
                "bid": 39
            },
            {
                "price": 12939.46,
                "ask": 87,
                "bid": 66
            },
            {
                "price": 12810,
                "ask": 0,
                "bid": 54
            },
            {
                "price": 12817.95,
                "ask": 97,
                "bid": 9
            },
            {
                "price": 12978.64,
                "ask": 41,
                "bid": 42
            },
            {
                "price": 12765.07,
                "ask": 39,
                "bid": 98
            },
            {
                "price": 12756.85,
                "ask": 25,
                "bid": 63
            },
            {
                "price": 12802.35,
                "ask": 59,
                "bid": 74
            },
            {
                "price": 12914.94,
                "ask": 47,
                "bid": 41
            },
            {
                "price": 12909.29,
                "ask": 9,
                "bid": 14
            },
            {
                "price": 12846.2,
                "ask": 35,
                "bid": 49
            },
            {
                "price": 12893.3,
                "ask": 93,
                "bid": 0
            },
            {
                "price": 12909.45,
                "ask": 10,
                "bid": 54
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcee7f98ffa77c4d57",
        "high": 12868.41,
        "open": 12751.3,
        "close": 12721.62,
        "low": 12814.48,
        "priceList": [
            {
                "price": 12932.13,
                "ask": 88,
                "bid": 93
            },
            {
                "price": 12882.7,
                "ask": 80,
                "bid": 80
            },
            {
                "price": 12815.31,
                "ask": 63,
                "bid": 14
            },
            {
                "price": 12778.08,
                "ask": 37,
                "bid": 33
            },
            {
                "price": 12923.09,
                "ask": 84,
                "bid": 48
            },
            {
                "price": 12976.66,
                "ask": 45,
                "bid": 70
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dccce071ddfad356f5",
        "high": 12779.12,
        "open": 12863.43,
        "close": 12724.49,
        "low": 12753.22,
        "priceList": [
            {
                "price": 12710.23,
                "ask": 18,
                "bid": 17
            },
            {
                "price": 12968.36,
                "ask": 35,
                "bid": 54
            },
            {
                "price": 12791.22,
                "ask": 96,
                "bid": 64
            },
            {
                "price": 12791,
                "ask": 42,
                "bid": 57
            },
            {
                "price": 12990.76,
                "ask": 4,
                "bid": 56
            },
            {
                "price": 12773.89,
                "ask": 6,
                "bid": 80
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc689d740449f8326d",
        "high": 12768.57,
        "open": 12818.49,
        "close": 12918.62,
        "low": 12833.78,
        "priceList": [
            {
                "price": 12957.3,
                "ask": 14,
                "bid": 91
            },
            {
                "price": 12827.38,
                "ask": 38,
                "bid": 89
            },
            {
                "price": 12847.91,
                "ask": 86,
                "bid": 89
            },
            {
                "price": 12911.27,
                "ask": 51,
                "bid": 100
            },
            {
                "price": 12767.66,
                "ask": 59,
                "bid": 84
            },
            {
                "price": 12927.57,
                "ask": 46,
                "bid": 10
            },
            {
                "price": 12700.87,
                "ask": 68,
                "bid": 16
            },
            {
                "price": 12714.36,
                "ask": 97,
                "bid": 24
            },
            {
                "price": 12916.84,
                "ask": 77,
                "bid": 40
            },
            {
                "price": 12999.88,
                "ask": 91,
                "bid": 64
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc0a2b4b2c8522ad4f",
        "high": 12749.65,
        "open": 12847.46,
        "close": 12902.29,
        "low": 12955.52,
        "priceList": [
            {
                "price": 12977.98,
                "ask": 76,
                "bid": 3
            },
            {
                "price": 12742.51,
                "ask": 40,
                "bid": 54
            },
            {
                "price": 12986.48,
                "ask": 8,
                "bid": 86
            },
            {
                "price": 12999.78,
                "ask": 74,
                "bid": 65
            },
            {
                "price": 12901.57,
                "ask": 11,
                "bid": 7
            },
            {
                "price": 12809.81,
                "ask": 98,
                "bid": 22
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc45e90f9d7b98c7ad",
        "high": 12878.29,
        "open": 12780.87,
        "close": 12843.69,
        "low": 12955.36,
        "priceList": [
            {
                "price": 12770.2,
                "ask": 13,
                "bid": 31
            },
            {
                "price": 12881.94,
                "ask": 42,
                "bid": 94
            },
            {
                "price": 12719.27,
                "ask": 56,
                "bid": 27
            },
            {
                "price": 12923.03,
                "ask": 33,
                "bid": 69
            },
            {
                "price": 12821.15,
                "ask": 0,
                "bid": 58
            },
            {
                "price": 12957.04,
                "ask": 59,
                "bid": 2
            },
            {
                "price": 12910.73,
                "ask": 29,
                "bid": 38
            },
            {
                "price": 12890.95,
                "ask": 75,
                "bid": 21
            },
            {
                "price": 12806.71,
                "ask": 23,
                "bid": 2
            },
            {
                "price": 12949.76,
                "ask": 99,
                "bid": 22
            },
            {
                "price": 12884.16,
                "ask": 2,
                "bid": 88
            },
            {
                "price": 12826.83,
                "ask": 52,
                "bid": 77
            },
            {
                "price": 12737.75,
                "ask": 99,
                "bid": 44
            },
            {
                "price": 12782.05,
                "ask": 96,
                "bid": 68
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc4e66fb315ebd2655",
        "high": 12917.05,
        "open": 12932.91,
        "close": 12977.43,
        "low": 12780.22,
        "priceList": [
            {
                "price": 12710.72,
                "ask": 86,
                "bid": 31
            },
            {
                "price": 12915.82,
                "ask": 14,
                "bid": 8
            },
            {
                "price": 12776.9,
                "ask": 5,
                "bid": 29
            },
            {
                "price": 12973.33,
                "ask": 71,
                "bid": 69
            },
            {
                "price": 12947.41,
                "ask": 68,
                "bid": 23
            },
            {
                "price": 12859.3,
                "ask": 76,
                "bid": 70
            },
            {
                "price": 12935.22,
                "ask": 96,
                "bid": 46
            },
            {
                "price": 12821.39,
                "ask": 25,
                "bid": 87
            },
            {
                "price": 12935.46,
                "ask": 34,
                "bid": 88
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc0795f2f27497ce79",
        "high": 12950.54,
        "open": 12722.63,
        "close": 12992.72,
        "low": 12974.66,
        "priceList": [
            {
                "price": 12992.31,
                "ask": 4,
                "bid": 34
            },
            {
                "price": 12861.85,
                "ask": 11,
                "bid": 37
            },
            {
                "price": 12960.16,
                "ask": 9,
                "bid": 27
            },
            {
                "price": 12705.16,
                "ask": 60,
                "bid": 76
            },
            {
                "price": 12775.02,
                "ask": 90,
                "bid": 55
            },
            {
                "price": 12868.51,
                "ask": 21,
                "bid": 68
            },
            {
                "price": 12889.51,
                "ask": 63,
                "bid": 59
            },
            {
                "price": 12890.3,
                "ask": 63,
                "bid": 71
            },
            {
                "price": 12921.84,
                "ask": 5,
                "bid": 24
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcf7fa33ad793e5b5c",
        "high": 12908.8,
        "open": 12857.05,
        "close": 12866.21,
        "low": 12822.05,
        "priceList": [
            {
                "price": 12709.94,
                "ask": 25,
                "bid": 27
            },
            {
                "price": 12793.97,
                "ask": 46,
                "bid": 27
            },
            {
                "price": 12910.29,
                "ask": 5,
                "bid": 30
            },
            {
                "price": 12787.41,
                "ask": 8,
                "bid": 55
            },
            {
                "price": 12743.37,
                "ask": 1,
                "bid": 22
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc1d64d9e6e71eef9d",
        "high": 12772.86,
        "open": 12927.96,
        "close": 12929.87,
        "low": 12888.96,
        "priceList": [
            {
                "price": 12928.08,
                "ask": 17,
                "bid": 53
            },
            {
                "price": 12750.12,
                "ask": 91,
                "bid": 84
            },
            {
                "price": 12802.51,
                "ask": 4,
                "bid": 85
            },
            {
                "price": 12896.88,
                "ask": 35,
                "bid": 33
            },
            {
                "price": 12709.3,
                "ask": 57,
                "bid": 60
            },
            {
                "price": 12877.58,
                "ask": 100,
                "bid": 95
            },
            {
                "price": 12873.93,
                "ask": 17,
                "bid": 30
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcc36a62015b025318",
        "high": 12760.81,
        "open": 12773.19,
        "close": 12841.73,
        "low": 12724.84,
        "priceList": [
            {
                "price": 12753.11,
                "ask": 51,
                "bid": 69
            },
            {
                "price": 12903.37,
                "ask": 65,
                "bid": 0
            },
            {
                "price": 12872.44,
                "ask": 1,
                "bid": 14
            },
            {
                "price": 12825.16,
                "ask": 51,
                "bid": 61
            },
            {
                "price": 12705.38,
                "ask": 84,
                "bid": 26
            },
            {
                "price": 12830.82,
                "ask": 61,
                "bid": 14
            },
            {
                "price": 12830.5,
                "ask": 31,
                "bid": 4
            },
            {
                "price": 12879.88,
                "ask": 70,
                "bid": 84
            },
            {
                "price": 12901.74,
                "ask": 50,
                "bid": 88
            },
            {
                "price": 12892.72,
                "ask": 67,
                "bid": 94
            },
            {
                "price": 12708.23,
                "ask": 14,
                "bid": 25
            },
            {
                "price": 12709.93,
                "ask": 98,
                "bid": 95
            },
            {
                "price": 12757.99,
                "ask": 44,
                "bid": 70
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc1e07dda6576f746f",
        "high": 12734.49,
        "open": 12906,
        "close": 12714.98,
        "low": 12983.55,
        "priceList": [
            {
                "price": 12757.92,
                "ask": 49,
                "bid": 87
            },
            {
                "price": 12864.91,
                "ask": 91,
                "bid": 55
            },
            {
                "price": 12914.68,
                "ask": 79,
                "bid": 77
            },
            {
                "price": 12890.42,
                "ask": 7,
                "bid": 12
            },
            {
                "price": 12761.45,
                "ask": 20,
                "bid": 53
            },
            {
                "price": 12870.76,
                "ask": 98,
                "bid": 1
            },
            {
                "price": 12845.54,
                "ask": 28,
                "bid": 50
            },
            {
                "price": 12831.71,
                "ask": 12,
                "bid": 87
            },
            {
                "price": 12858.38,
                "ask": 78,
                "bid": 91
            },
            {
                "price": 12971.86,
                "ask": 17,
                "bid": 48
            },
            {
                "price": 12970.73,
                "ask": 70,
                "bid": 90
            },
            {
                "price": 12716.61,
                "ask": 2,
                "bid": 81
            },
            {
                "price": 12829.97,
                "ask": 81,
                "bid": 9
            },
            {
                "price": 12900.18,
                "ask": 52,
                "bid": 61
            },
            {
                "price": 12865.64,
                "ask": 76,
                "bid": 76
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc15d96dee11977c83",
        "high": 12717.28,
        "open": 12718.68,
        "close": 12819.01,
        "low": 12841.34,
        "priceList": [
            {
                "price": 12850.31,
                "ask": 27,
                "bid": 78
            },
            {
                "price": 12772.73,
                "ask": 85,
                "bid": 93
            },
            {
                "price": 12832.09,
                "ask": 81,
                "bid": 13
            },
            {
                "price": 12993.54,
                "ask": 77,
                "bid": 75
            },
            {
                "price": 12951.53,
                "ask": 36,
                "bid": 19
            },
            {
                "price": 12750.62,
                "ask": 14,
                "bid": 35
            },
            {
                "price": 12941.72,
                "ask": 9,
                "bid": 58
            },
            {
                "price": 12953.4,
                "ask": 54,
                "bid": 38
            },
            {
                "price": 12838.04,
                "ask": 22,
                "bid": 30
            },
            {
                "price": 12813.34,
                "ask": 13,
                "bid": 12
            },
            {
                "price": 12895.58,
                "ask": 44,
                "bid": 94
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4a739faa722738e5",
        "high": 12832.67,
        "open": 12987.87,
        "close": 12771.6,
        "low": 12743.16,
        "priceList": [
            {
                "price": 12892.34,
                "ask": 100,
                "bid": 85
            },
            {
                "price": 12759.51,
                "ask": 40,
                "bid": 89
            },
            {
                "price": 12807.42,
                "ask": 8,
                "bid": 12
            },
            {
                "price": 12919.47,
                "ask": 46,
                "bid": 84
            },
            {
                "price": 12870.22,
                "ask": 76,
                "bid": 41
            },
            {
                "price": 12780.3,
                "ask": 92,
                "bid": 81
            },
            {
                "price": 12954.53,
                "ask": 0,
                "bid": 37
            },
            {
                "price": 12909.84,
                "ask": 20,
                "bid": 16
            },
            {
                "price": 12811.97,
                "ask": 63,
                "bid": 77
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dceb86eff546856c25",
        "high": 12758.42,
        "open": 12760.65,
        "close": 12709.94,
        "low": 12809.41,
        "priceList": [
            {
                "price": 12730.48,
                "ask": 83,
                "bid": 34
            },
            {
                "price": 12966.47,
                "ask": 63,
                "bid": 81
            },
            {
                "price": 12790.6,
                "ask": 27,
                "bid": 21
            },
            {
                "price": 12843.58,
                "ask": 31,
                "bid": 90
            },
            {
                "price": 12812.46,
                "ask": 26,
                "bid": 74
            },
            {
                "price": 12805.36,
                "ask": 21,
                "bid": 43
            },
            {
                "price": 12822.76,
                "ask": 59,
                "bid": 67
            },
            {
                "price": 12880.21,
                "ask": 19,
                "bid": 1
            },
            {
                "price": 12772.19,
                "ask": 64,
                "bid": 6
            },
            {
                "price": 12826.9,
                "ask": 63,
                "bid": 21
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcf1b737f6b59a79ba",
        "high": 12911.87,
        "open": 12893.08,
        "close": 12999.69,
        "low": 12930.42,
        "priceList": [
            {
                "price": 12977.23,
                "ask": 64,
                "bid": 20
            },
            {
                "price": 12780.92,
                "ask": 66,
                "bid": 0
            },
            {
                "price": 12817.67,
                "ask": 31,
                "bid": 78
            },
            {
                "price": 12986.65,
                "ask": 50,
                "bid": 74
            },
            {
                "price": 12924.18,
                "ask": 77,
                "bid": 41
            },
            {
                "price": 12753.9,
                "ask": 72,
                "bid": 72
            },
            {
                "price": 12899.31,
                "ask": 34,
                "bid": 30
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcce8e51603aeb56de",
        "high": 12871.7,
        "open": 12836.42,
        "close": 12734.98,
        "low": 12918.43,
        "priceList": [
            {
                "price": 12731.15,
                "ask": 25,
                "bid": 55
            },
            {
                "price": 12855.76,
                "ask": 74,
                "bid": 83
            },
            {
                "price": 12757.81,
                "ask": 99,
                "bid": 42
            },
            {
                "price": 12960.71,
                "ask": 98,
                "bid": 81
            },
            {
                "price": 12879.9,
                "ask": 82,
                "bid": 78
            },
            {
                "price": 12986.49,
                "ask": 49,
                "bid": 95
            },
            {
                "price": 12997.04,
                "ask": 25,
                "bid": 19
            },
            {
                "price": 12752.58,
                "ask": 25,
                "bid": 1
            },
            {
                "price": 12960.64,
                "ask": 59,
                "bid": 19
            },
            {
                "price": 12953.53,
                "ask": 33,
                "bid": 16
            },
            {
                "price": 12913.27,
                "ask": 100,
                "bid": 44
            },
            {
                "price": 12911.35,
                "ask": 42,
                "bid": 14
            },
            {
                "price": 12816.09,
                "ask": 6,
                "bid": 45
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcce66ccd8485681f5",
        "high": 12902.56,
        "open": 12991.4,
        "close": 12748.41,
        "low": 12730.81,
        "priceList": [
            {
                "price": 12716.21,
                "ask": 74,
                "bid": 3
            },
            {
                "price": 12917.02,
                "ask": 29,
                "bid": 18
            },
            {
                "price": 12799.31,
                "ask": 53,
                "bid": 74
            },
            {
                "price": 12842.54,
                "ask": 20,
                "bid": 64
            },
            {
                "price": 12947.27,
                "ask": 93,
                "bid": 11
            },
            {
                "price": 12955.44,
                "ask": 20,
                "bid": 80
            },
            {
                "price": 12906.6,
                "ask": 88,
                "bid": 2
            },
            {
                "price": 12863.52,
                "ask": 90,
                "bid": 80
            },
            {
                "price": 12920.95,
                "ask": 4,
                "bid": 94
            },
            {
                "price": 12835.64,
                "ask": 78,
                "bid": 85
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc7f1ada502260edf2",
        "high": 12783.59,
        "open": 12873.71,
        "close": 12728.59,
        "low": 12921.69,
        "priceList": [
            {
                "price": 12952.05,
                "ask": 77,
                "bid": 50
            },
            {
                "price": 12732.07,
                "ask": 27,
                "bid": 99
            },
            {
                "price": 12880.88,
                "ask": 29,
                "bid": 87
            },
            {
                "price": 12706.33,
                "ask": 20,
                "bid": 56
            },
            {
                "price": 12837.24,
                "ask": 77,
                "bid": 7
            },
            {
                "price": 12966.94,
                "ask": 30,
                "bid": 83
            },
            {
                "price": 12993.28,
                "ask": 8,
                "bid": 22
            },
            {
                "price": 12913.04,
                "ask": 46,
                "bid": 80
            },
            {
                "price": 12927.86,
                "ask": 44,
                "bid": 92
            },
            {
                "price": 12976.78,
                "ask": 87,
                "bid": 49
            },
            {
                "price": 12977.51,
                "ask": 83,
                "bid": 88
            },
            {
                "price": 12976.5,
                "ask": 33,
                "bid": 0
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dce70be5328d7ac03b",
        "high": 12845.07,
        "open": 12824.18,
        "close": 12988.62,
        "low": 12772.71,
        "priceList": [
            {
                "price": 12734.47,
                "ask": 82,
                "bid": 7
            },
            {
                "price": 12933.08,
                "ask": 15,
                "bid": 97
            },
            {
                "price": 12979.55,
                "ask": 90,
                "bid": 10
            },
            {
                "price": 12924.34,
                "ask": 68,
                "bid": 54
            },
            {
                "price": 12784.63,
                "ask": 16,
                "bid": 22
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc1107bb43cbb0bb42",
        "high": 12746.99,
        "open": 12893.94,
        "close": 12753.42,
        "low": 12977.12,
        "priceList": [
            {
                "price": 12901.34,
                "ask": 8,
                "bid": 79
            },
            {
                "price": 12936.6,
                "ask": 40,
                "bid": 70
            },
            {
                "price": 12744.73,
                "ask": 3,
                "bid": 86
            },
            {
                "price": 12804.78,
                "ask": 10,
                "bid": 72
            },
            {
                "price": 12851.32,
                "ask": 85,
                "bid": 74
            },
            {
                "price": 12775.49,
                "ask": 95,
                "bid": 22
            },
            {
                "price": 12709.8,
                "ask": 67,
                "bid": 36
            },
            {
                "price": 12861.75,
                "ask": 1,
                "bid": 96
            },
            {
                "price": 12876,
                "ask": 72,
                "bid": 54
            },
            {
                "price": 12928.39,
                "ask": 71,
                "bid": 55
            },
            {
                "price": 12856.61,
                "ask": 19,
                "bid": 2
            },
            {
                "price": 12981.4,
                "ask": 33,
                "bid": 10
            },
            {
                "price": 12992.33,
                "ask": 50,
                "bid": 80
            },
            {
                "price": 12884.83,
                "ask": 72,
                "bid": 71
            },
            {
                "price": 12986.76,
                "ask": 65,
                "bid": 13
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca95ee9461c679ae8",
        "high": 12972.88,
        "open": 12845.76,
        "close": 12863.51,
        "low": 12744.4,
        "priceList": [
            {
                "price": 12715.06,
                "ask": 15,
                "bid": 64
            },
            {
                "price": 12829.15,
                "ask": 74,
                "bid": 46
            },
            {
                "price": 12910.27,
                "ask": 55,
                "bid": 55
            },
            {
                "price": 12975,
                "ask": 47,
                "bid": 19
            },
            {
                "price": 12859.54,
                "ask": 4,
                "bid": 37
            },
            {
                "price": 12939.71,
                "ask": 27,
                "bid": 44
            },
            {
                "price": 12894.83,
                "ask": 100,
                "bid": 67
            },
            {
                "price": 12734.56,
                "ask": 56,
                "bid": 77
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc4a5f7be6059d4fb9",
        "high": 12889.81,
        "open": 12880.34,
        "close": 12950.99,
        "low": 12852.07,
        "priceList": [
            {
                "price": 12897.13,
                "ask": 59,
                "bid": 81
            },
            {
                "price": 12884.05,
                "ask": 64,
                "bid": 34
            },
            {
                "price": 12810.96,
                "ask": 46,
                "bid": 62
            },
            {
                "price": 12858.78,
                "ask": 4,
                "bid": 51
            },
            {
                "price": 12822.81,
                "ask": 37,
                "bid": 4
            },
            {
                "price": 12925.98,
                "ask": 4,
                "bid": 34
            },
            {
                "price": 12936.74,
                "ask": 82,
                "bid": 24
            },
            {
                "price": 12810.61,
                "ask": 75,
                "bid": 21
            },
            {
                "price": 12778.62,
                "ask": 31,
                "bid": 35
            },
            {
                "price": 12732.16,
                "ask": 6,
                "bid": 48
            },
            {
                "price": 12960.67,
                "ask": 34,
                "bid": 81
            },
            {
                "price": 12816.72,
                "ask": 86,
                "bid": 83
            },
            {
                "price": 12752.33,
                "ask": 11,
                "bid": 23
            },
            {
                "price": 12927.7,
                "ask": 58,
                "bid": 13
            },
            {
                "price": 12925.75,
                "ask": 63,
                "bid": 92
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc402ac8bd10a54c38",
        "high": 12908.35,
        "open": 12782.5,
        "close": 12844.03,
        "low": 12894.27,
        "priceList": [
            {
                "price": 12971.88,
                "ask": 62,
                "bid": 58
            },
            {
                "price": 12738.23,
                "ask": 53,
                "bid": 98
            },
            {
                "price": 12945.33,
                "ask": 7,
                "bid": 46
            },
            {
                "price": 12979.13,
                "ask": 88,
                "bid": 39
            },
            {
                "price": 12831.33,
                "ask": 63,
                "bid": 1
            },
            {
                "price": 12795.1,
                "ask": 49,
                "bid": 58
            },
            {
                "price": 12764.95,
                "ask": 6,
                "bid": 93
            },
            {
                "price": 12723.89,
                "ask": 94,
                "bid": 51
            },
            {
                "price": 12818.94,
                "ask": 34,
                "bid": 63
            },
            {
                "price": 12945.42,
                "ask": 6,
                "bid": 29
            },
            {
                "price": 12956.19,
                "ask": 70,
                "bid": 35
            },
            {
                "price": 12887.67,
                "ask": 72,
                "bid": 5
            },
            {
                "price": 12989.02,
                "ask": 83,
                "bid": 13
            },
            {
                "price": 12748.35,
                "ask": 71,
                "bid": 16
            },
            {
                "price": 12764.28,
                "ask": 78,
                "bid": 17
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcbc1c9016d8f66be8",
        "high": 12918.54,
        "open": 12997,
        "close": 12963.15,
        "low": 12903.28,
        "priceList": [
            {
                "price": 12837.95,
                "ask": 45,
                "bid": 48
            },
            {
                "price": 12787.98,
                "ask": 95,
                "bid": 73
            },
            {
                "price": 12809.01,
                "ask": 75,
                "bid": 24
            },
            {
                "price": 12730.05,
                "ask": 44,
                "bid": 78
            },
            {
                "price": 12754.95,
                "ask": 79,
                "bid": 33
            },
            {
                "price": 12869.9,
                "ask": 13,
                "bid": 46
            },
            {
                "price": 12987.85,
                "ask": 88,
                "bid": 81
            },
            {
                "price": 12878.68,
                "ask": 23,
                "bid": 83
            },
            {
                "price": 12927.67,
                "ask": 32,
                "bid": 31
            },
            {
                "price": 12994.12,
                "ask": 81,
                "bid": 49
            },
            {
                "price": 12858.78,
                "ask": 38,
                "bid": 15
            },
            {
                "price": 12813.24,
                "ask": 60,
                "bid": 67
            },
            {
                "price": 12913.9,
                "ask": 66,
                "bid": 46
            },
            {
                "price": 12826.35,
                "ask": 32,
                "bid": 66
            },
            {
                "price": 12742.54,
                "ask": 51,
                "bid": 17
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dceb04de4625ecd974",
        "high": 12896.79,
        "open": 12720.54,
        "close": 12823.26,
        "low": 12860.02,
        "priceList": [
            {
                "price": 12947.61,
                "ask": 32,
                "bid": 93
            },
            {
                "price": 12781.12,
                "ask": 16,
                "bid": 29
            },
            {
                "price": 12818.61,
                "ask": 83,
                "bid": 82
            },
            {
                "price": 12857.57,
                "ask": 51,
                "bid": 37
            },
            {
                "price": 12739.62,
                "ask": 55,
                "bid": 3
            },
            {
                "price": 12841.18,
                "ask": 84,
                "bid": 65
            },
            {
                "price": 12784.61,
                "ask": 13,
                "bid": 56
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc16e2a2baeca7a0ea",
        "high": 12728.39,
        "open": 12906.7,
        "close": 12738.68,
        "low": 12948.81,
        "priceList": [
            {
                "price": 12951.18,
                "ask": 25,
                "bid": 97
            },
            {
                "price": 12828.53,
                "ask": 37,
                "bid": 40
            },
            {
                "price": 12738.5,
                "ask": 5,
                "bid": 33
            },
            {
                "price": 12958.59,
                "ask": 65,
                "bid": 11
            },
            {
                "price": 12773.51,
                "ask": 65,
                "bid": 57
            },
            {
                "price": 12767.28,
                "ask": 79,
                "bid": 48
            },
            {
                "price": 12973.52,
                "ask": 73,
                "bid": 88
            },
            {
                "price": 12969.1,
                "ask": 7,
                "bid": 45
            },
            {
                "price": 12830.46,
                "ask": 39,
                "bid": 61
            },
            {
                "price": 12877.94,
                "ask": 0,
                "bid": 38
            },
            {
                "price": 12798.61,
                "ask": 32,
                "bid": 27
            },
            {
                "price": 12882.94,
                "ask": 98,
                "bid": 14
            },
            {
                "price": 12789.82,
                "ask": 23,
                "bid": 12
            },
            {
                "price": 12786.36,
                "ask": 20,
                "bid": 62
            },
            {
                "price": 12916.16,
                "ask": 96,
                "bid": 99
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc250a1b0f23f6b9e8",
        "high": 12790.91,
        "open": 12877.28,
        "close": 12915.37,
        "low": 12856.66,
        "priceList": [
            {
                "price": 12782.01,
                "ask": 34,
                "bid": 10
            },
            {
                "price": 12902.47,
                "ask": 76,
                "bid": 46
            },
            {
                "price": 12970.25,
                "ask": 44,
                "bid": 28
            },
            {
                "price": 12706.71,
                "ask": 86,
                "bid": 48
            },
            {
                "price": 12821.02,
                "ask": 16,
                "bid": 31
            },
            {
                "price": 12803.4,
                "ask": 34,
                "bid": 52
            },
            {
                "price": 12882.78,
                "ask": 63,
                "bid": 32
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcd1605f7813d78ffc",
        "high": 12983.17,
        "open": 12712.87,
        "close": 12853.46,
        "low": 12976.97,
        "priceList": [
            {
                "price": 12749.42,
                "ask": 12,
                "bid": 85
            },
            {
                "price": 12774.01,
                "ask": 15,
                "bid": 89
            },
            {
                "price": 12775.16,
                "ask": 29,
                "bid": 65
            },
            {
                "price": 12937,
                "ask": 85,
                "bid": 97
            },
            {
                "price": 12732.84,
                "ask": 72,
                "bid": 87
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc636eb4d067d2edf7",
        "high": 12741.13,
        "open": 12743.14,
        "close": 12807.88,
        "low": 12952.55,
        "priceList": [
            {
                "price": 12737.91,
                "ask": 47,
                "bid": 12
            },
            {
                "price": 12835.88,
                "ask": 50,
                "bid": 31
            },
            {
                "price": 12985.99,
                "ask": 37,
                "bid": 27
            },
            {
                "price": 12758.2,
                "ask": 96,
                "bid": 62
            },
            {
                "price": 12702.71,
                "ask": 88,
                "bid": 84
            },
            {
                "price": 12879.87,
                "ask": 88,
                "bid": 94
            },
            {
                "price": 12732.06,
                "ask": 26,
                "bid": 5
            },
            {
                "price": 12757.5,
                "ask": 61,
                "bid": 96
            },
            {
                "price": 12789.75,
                "ask": 100,
                "bid": 41
            },
            {
                "price": 12773.16,
                "ask": 1,
                "bid": 76
            },
            {
                "price": 12775.57,
                "ask": 4,
                "bid": 85
            },
            {
                "price": 12789.19,
                "ask": 62,
                "bid": 13
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc38e33cb7f4217889",
        "high": 12763.91,
        "open": 12920.98,
        "close": 12858.53,
        "low": 12912.11,
        "priceList": [
            {
                "price": 12979.24,
                "ask": 49,
                "bid": 29
            },
            {
                "price": 12930.22,
                "ask": 4,
                "bid": 0
            },
            {
                "price": 12839.94,
                "ask": 61,
                "bid": 1
            },
            {
                "price": 12789.32,
                "ask": 90,
                "bid": 6
            },
            {
                "price": 12980.02,
                "ask": 77,
                "bid": 15
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc7828e4e2f4254844",
        "high": 12963.18,
        "open": 12770.09,
        "close": 12792.09,
        "low": 12718.8,
        "priceList": [
            {
                "price": 12980.1,
                "ask": 81,
                "bid": 23
            },
            {
                "price": 12782.83,
                "ask": 6,
                "bid": 33
            },
            {
                "price": 12724.27,
                "ask": 44,
                "bid": 16
            },
            {
                "price": 12867.32,
                "ask": 60,
                "bid": 26
            },
            {
                "price": 12976.01,
                "ask": 65,
                "bid": 82
            },
            {
                "price": 12757.4,
                "ask": 12,
                "bid": 26
            },
            {
                "price": 12899.28,
                "ask": 86,
                "bid": 74
            },
            {
                "price": 12866.41,
                "ask": 71,
                "bid": 18
            },
            {
                "price": 12801.86,
                "ask": 41,
                "bid": 33
            },
            {
                "price": 12909.63,
                "ask": 10,
                "bid": 85
            },
            {
                "price": 12771.74,
                "ask": 73,
                "bid": 68
            },
            {
                "price": 12989.1,
                "ask": 54,
                "bid": 84
            },
            {
                "price": 12733.01,
                "ask": 58,
                "bid": 3
            },
            {
                "price": 12922.35,
                "ask": 63,
                "bid": 52
            },
            {
                "price": 12749.93,
                "ask": 67,
                "bid": 83
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc757a51ea803ef0a0",
        "high": 12770.44,
        "open": 12811.2,
        "close": 12907.97,
        "low": 12787,
        "priceList": [
            {
                "price": 12732.13,
                "ask": 34,
                "bid": 12
            },
            {
                "price": 12993.23,
                "ask": 66,
                "bid": 13
            },
            {
                "price": 12775.58,
                "ask": 53,
                "bid": 45
            },
            {
                "price": 12824.05,
                "ask": 75,
                "bid": 70
            },
            {
                "price": 12858.64,
                "ask": 54,
                "bid": 36
            },
            {
                "price": 12878.67,
                "ask": 17,
                "bid": 35
            },
            {
                "price": 12913.17,
                "ask": 96,
                "bid": 7
            },
            {
                "price": 12895.18,
                "ask": 67,
                "bid": 81
            },
            {
                "price": 12983.05,
                "ask": 1,
                "bid": 70
            },
            {
                "price": 12986.24,
                "ask": 64,
                "bid": 72
            },
            {
                "price": 12865.84,
                "ask": 17,
                "bid": 80
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc06a693729d4f5618",
        "high": 12995.63,
        "open": 12777.7,
        "close": 12887.3,
        "low": 12727.93,
        "priceList": [
            {
                "price": 12876.61,
                "ask": 13,
                "bid": 63
            },
            {
                "price": 12918.71,
                "ask": 42,
                "bid": 58
            },
            {
                "price": 12739.44,
                "ask": 28,
                "bid": 63
            },
            {
                "price": 12983.42,
                "ask": 40,
                "bid": 49
            },
            {
                "price": 12857.54,
                "ask": 49,
                "bid": 76
            },
            {
                "price": 12758.47,
                "ask": 76,
                "bid": 21
            },
            {
                "price": 12777.28,
                "ask": 65,
                "bid": 67
            },
            {
                "price": 12959.57,
                "ask": 40,
                "bid": 77
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc6cc749830553a4a4",
        "high": 12796.75,
        "open": 12815.87,
        "close": 12821.43,
        "low": 12848.09,
        "priceList": [
            {
                "price": 12833.25,
                "ask": 47,
                "bid": 18
            },
            {
                "price": 12773.04,
                "ask": 86,
                "bid": 95
            },
            {
                "price": 12866.9,
                "ask": 37,
                "bid": 67
            },
            {
                "price": 12769.95,
                "ask": 40,
                "bid": 73
            },
            {
                "price": 12979.03,
                "ask": 72,
                "bid": 2
            },
            {
                "price": 12834.43,
                "ask": 52,
                "bid": 88
            },
            {
                "price": 12837.23,
                "ask": 43,
                "bid": 7
            },
            {
                "price": 12995.67,
                "ask": 46,
                "bid": 10
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcc8ed162800483a37",
        "high": 12762.49,
        "open": 12899.13,
        "close": 12884.97,
        "low": 12927.52,
        "priceList": [
            {
                "price": 12890.89,
                "ask": 92,
                "bid": 25
            },
            {
                "price": 12760.75,
                "ask": 91,
                "bid": 64
            },
            {
                "price": 12911.59,
                "ask": 62,
                "bid": 2
            },
            {
                "price": 12785.62,
                "ask": 79,
                "bid": 37
            },
            {
                "price": 12816.66,
                "ask": 37,
                "bid": 77
            },
            {
                "price": 12840.13,
                "ask": 63,
                "bid": 1
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc0e017beb62d5703c",
        "high": 12845.59,
        "open": 12797.21,
        "close": 12963.85,
        "low": 12893.61,
        "priceList": [
            {
                "price": 12763.46,
                "ask": 56,
                "bid": 59
            },
            {
                "price": 12771.99,
                "ask": 23,
                "bid": 91
            },
            {
                "price": 12711.73,
                "ask": 89,
                "bid": 36
            },
            {
                "price": 12889.55,
                "ask": 57,
                "bid": 40
            },
            {
                "price": 12749.16,
                "ask": 85,
                "bid": 51
            },
            {
                "price": 12983.97,
                "ask": 99,
                "bid": 87
            },
            {
                "price": 12801.91,
                "ask": 16,
                "bid": 12
            },
            {
                "price": 12982,
                "ask": 39,
                "bid": 12
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc51c31145260e65ea",
        "high": 12783.82,
        "open": 12809.57,
        "close": 12720.98,
        "low": 12977.82,
        "priceList": [
            {
                "price": 12867.19,
                "ask": 42,
                "bid": 37
            },
            {
                "price": 12842.63,
                "ask": 90,
                "bid": 45
            },
            {
                "price": 12927.18,
                "ask": 74,
                "bid": 98
            },
            {
                "price": 12932.76,
                "ask": 59,
                "bid": 24
            },
            {
                "price": 12732.67,
                "ask": 83,
                "bid": 43
            },
            {
                "price": 12809.27,
                "ask": 13,
                "bid": 92
            },
            {
                "price": 12997.26,
                "ask": 77,
                "bid": 0
            },
            {
                "price": 12837.97,
                "ask": 91,
                "bid": 68
            },
            {
                "price": 12880.45,
                "ask": 91,
                "bid": 89
            },
            {
                "price": 12815.63,
                "ask": 93,
                "bid": 27
            },
            {
                "price": 12703.11,
                "ask": 74,
                "bid": 25
            },
            {
                "price": 12901.46,
                "ask": 21,
                "bid": 90
            },
            {
                "price": 12959.22,
                "ask": 85,
                "bid": 97
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc84aed1a8c224abc",
        "high": 12901.84,
        "open": 12895.69,
        "close": 12703.07,
        "low": 12955.91,
        "priceList": [
            {
                "price": 12993.27,
                "ask": 69,
                "bid": 16
            },
            {
                "price": 12869.75,
                "ask": 62,
                "bid": 25
            },
            {
                "price": 12941,
                "ask": 80,
                "bid": 89
            },
            {
                "price": 12935.24,
                "ask": 28,
                "bid": 49
            },
            {
                "price": 12939.76,
                "ask": 32,
                "bid": 49
            },
            {
                "price": 12818.03,
                "ask": 0,
                "bid": 85
            },
            {
                "price": 12884.6,
                "ask": 99,
                "bid": 89
            },
            {
                "price": 12917.07,
                "ask": 52,
                "bid": 53
            },
            {
                "price": 12947.8,
                "ask": 74,
                "bid": 7
            },
            {
                "price": 12779.37,
                "ask": 72,
                "bid": 84
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc23fde608fdf5183e",
        "high": 12972.61,
        "open": 12797.35,
        "close": 12814.95,
        "low": 12761.29,
        "priceList": [
            {
                "price": 12800.2,
                "ask": 37,
                "bid": 94
            },
            {
                "price": 12851.22,
                "ask": 85,
                "bid": 29
            },
            {
                "price": 12949.15,
                "ask": 74,
                "bid": 48
            },
            {
                "price": 12779.36,
                "ask": 73,
                "bid": 27
            },
            {
                "price": 12968.91,
                "ask": 46,
                "bid": 50
            },
            {
                "price": 12763.48,
                "ask": 71,
                "bid": 99
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc5420c3771bea5507",
        "high": 12858.71,
        "open": 12890.29,
        "close": 12789.33,
        "low": 12769.48,
        "priceList": [
            {
                "price": 12980.69,
                "ask": 24,
                "bid": 27
            },
            {
                "price": 12769.91,
                "ask": 24,
                "bid": 33
            },
            {
                "price": 12815.94,
                "ask": 4,
                "bid": 58
            },
            {
                "price": 12767.31,
                "ask": 34,
                "bid": 89
            },
            {
                "price": 12972.89,
                "ask": 33,
                "bid": 13
            },
            {
                "price": 12965.99,
                "ask": 22,
                "bid": 95
            },
            {
                "price": 12789.33,
                "ask": 69,
                "bid": 10
            },
            {
                "price": 12901.26,
                "ask": 34,
                "bid": 92
            },
            {
                "price": 12807.46,
                "ask": 17,
                "bid": 34
            },
            {
                "price": 12980.59,
                "ask": 86,
                "bid": 8
            },
            {
                "price": 12746,
                "ask": 11,
                "bid": 5
            },
            {
                "price": 12822.59,
                "ask": 88,
                "bid": 56
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc58c7df44dcb408db",
        "high": 12830.74,
        "open": 12821.62,
        "close": 12958.75,
        "low": 12818.07,
        "priceList": [
            {
                "price": 12778.24,
                "ask": 35,
                "bid": 42
            },
            {
                "price": 12962.22,
                "ask": 66,
                "bid": 22
            },
            {
                "price": 12941.32,
                "ask": 81,
                "bid": 82
            },
            {
                "price": 12989.37,
                "ask": 44,
                "bid": 73
            },
            {
                "price": 12705.03,
                "ask": 21,
                "bid": 34
            },
            {
                "price": 12716.9,
                "ask": 64,
                "bid": 96
            },
            {
                "price": 12705.27,
                "ask": 92,
                "bid": 71
            },
            {
                "price": 12722.4,
                "ask": 41,
                "bid": 53
            },
            {
                "price": 12975.3,
                "ask": 97,
                "bid": 91
            },
            {
                "price": 12864.38,
                "ask": 1,
                "bid": 18
            },
            {
                "price": 12760.71,
                "ask": 27,
                "bid": 19
            },
            {
                "price": 12781.06,
                "ask": 87,
                "bid": 1
            },
            {
                "price": 12919.14,
                "ask": 54,
                "bid": 32
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc0730ce95b3afc0e",
        "high": 12958.96,
        "open": 12782.65,
        "close": 12781.74,
        "low": 12951.06,
        "priceList": [
            {
                "price": 12774.91,
                "ask": 71,
                "bid": 13
            },
            {
                "price": 12807.31,
                "ask": 37,
                "bid": 72
            },
            {
                "price": 12889.76,
                "ask": 44,
                "bid": 80
            },
            {
                "price": 12868.99,
                "ask": 67,
                "bid": 69
            },
            {
                "price": 12935.57,
                "ask": 90,
                "bid": 80
            },
            {
                "price": 12958.81,
                "ask": 55,
                "bid": 8
            },
            {
                "price": 12999.76,
                "ask": 20,
                "bid": 65
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcaaac158c3fe31822",
        "high": 12816,
        "open": 12923.85,
        "close": 12863.95,
        "low": 12954.01,
        "priceList": [
            {
                "price": 12986.41,
                "ask": 15,
                "bid": 31
            },
            {
                "price": 12886.04,
                "ask": 89,
                "bid": 58
            },
            {
                "price": 12762.52,
                "ask": 68,
                "bid": 73
            },
            {
                "price": 12831.72,
                "ask": 95,
                "bid": 26
            },
            {
                "price": 12944.09,
                "ask": 95,
                "bid": 56
            },
            {
                "price": 12955.91,
                "ask": 47,
                "bid": 32
            },
            {
                "price": 12832.29,
                "ask": 4,
                "bid": 25
            },
            {
                "price": 12881.1,
                "ask": 3,
                "bid": 47
            },
            {
                "price": 12901.36,
                "ask": 56,
                "bid": 62
            },
            {
                "price": 12779.65,
                "ask": 68,
                "bid": 58
            },
            {
                "price": 12764.92,
                "ask": 19,
                "bid": 45
            },
            {
                "price": 12899.49,
                "ask": 91,
                "bid": 52
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc10ad118cd808667a",
        "high": 12947.2,
        "open": 12946.01,
        "close": 12881.45,
        "low": 12763.01,
        "priceList": [
            {
                "price": 12721.97,
                "ask": 23,
                "bid": 1
            },
            {
                "price": 12962.47,
                "ask": 60,
                "bid": 17
            },
            {
                "price": 12882.45,
                "ask": 92,
                "bid": 89
            },
            {
                "price": 12818.02,
                "ask": 2,
                "bid": 23
            },
            {
                "price": 12941.94,
                "ask": 36,
                "bid": 28
            },
            {
                "price": 12739.61,
                "ask": 72,
                "bid": 39
            },
            {
                "price": 12856.12,
                "ask": 28,
                "bid": 49
            },
            {
                "price": 12958.28,
                "ask": 4,
                "bid": 80
            },
            {
                "price": 12793.04,
                "ask": 75,
                "bid": 19
            },
            {
                "price": 12772.29,
                "ask": 28,
                "bid": 15
            },
            {
                "price": 12706.81,
                "ask": 32,
                "bid": 27
            },
            {
                "price": 12898.38,
                "ask": 67,
                "bid": 11
            },
            {
                "price": 12756.1,
                "ask": 73,
                "bid": 49
            },
            {
                "price": 12771.72,
                "ask": 99,
                "bid": 55
            },
            {
                "price": 12741.45,
                "ask": 52,
                "bid": 30
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc2f3007f2b5b3806d",
        "high": 12756.56,
        "open": 12922.52,
        "close": 12919.65,
        "low": 12852.1,
        "priceList": [
            {
                "price": 12882.19,
                "ask": 3,
                "bid": 49
            },
            {
                "price": 12784.24,
                "ask": 75,
                "bid": 39
            },
            {
                "price": 12863.49,
                "ask": 55,
                "bid": 32
            },
            {
                "price": 12815.59,
                "ask": 48,
                "bid": 23
            },
            {
                "price": 12922.77,
                "ask": 76,
                "bid": 3
            },
            {
                "price": 12992.42,
                "ask": 4,
                "bid": 78
            },
            {
                "price": 12748.86,
                "ask": 28,
                "bid": 41
            },
            {
                "price": 12930.9,
                "ask": 38,
                "bid": 14
            },
            {
                "price": 12940.98,
                "ask": 84,
                "bid": 17
            },
            {
                "price": 12712.29,
                "ask": 87,
                "bid": 76
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcb0c5ccf636ac8f81",
        "high": 12978.45,
        "open": 12935.52,
        "close": 12845.37,
        "low": 12955.99,
        "priceList": [
            {
                "price": 12852.17,
                "ask": 98,
                "bid": 70
            },
            {
                "price": 12845.29,
                "ask": 59,
                "bid": 72
            },
            {
                "price": 12952.69,
                "ask": 5,
                "bid": 78
            },
            {
                "price": 12950.97,
                "ask": 4,
                "bid": 0
            },
            {
                "price": 12893.25,
                "ask": 67,
                "bid": 39
            },
            {
                "price": 12885.86,
                "ask": 97,
                "bid": 18
            },
            {
                "price": 12901.33,
                "ask": 25,
                "bid": 57
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc6acdc987489bc1f5",
        "high": 12968.33,
        "open": 12868.68,
        "close": 12795.66,
        "low": 12921.24,
        "priceList": [
            {
                "price": 12830.65,
                "ask": 52,
                "bid": 83
            },
            {
                "price": 12882.69,
                "ask": 40,
                "bid": 59
            },
            {
                "price": 12999.83,
                "ask": 70,
                "bid": 69
            },
            {
                "price": 12959.5,
                "ask": 62,
                "bid": 40
            },
            {
                "price": 12704.25,
                "ask": 6,
                "bid": 1
            },
            {
                "price": 12933.01,
                "ask": 36,
                "bid": 81
            },
            {
                "price": 12981.31,
                "ask": 93,
                "bid": 91
            },
            {
                "price": 12973.91,
                "ask": 45,
                "bid": 92
            },
            {
                "price": 12822.53,
                "ask": 59,
                "bid": 89
            },
            {
                "price": 12907.32,
                "ask": 63,
                "bid": 43
            },
            {
                "price": 12853.76,
                "ask": 25,
                "bid": 61
            },
            {
                "price": 12872.7,
                "ask": 50,
                "bid": 7
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc82f21f9281c88ffc",
        "high": 12951.87,
        "open": 12746.37,
        "close": 12939.74,
        "low": 12923.61,
        "priceList": [
            {
                "price": 12813,
                "ask": 87,
                "bid": 45
            },
            {
                "price": 12735.11,
                "ask": 11,
                "bid": 16
            },
            {
                "price": 12894.27,
                "ask": 76,
                "bid": 77
            },
            {
                "price": 12782.36,
                "ask": 46,
                "bid": 26
            },
            {
                "price": 12782.78,
                "ask": 68,
                "bid": 76
            },
            {
                "price": 12920.43,
                "ask": 82,
                "bid": 92
            },
            {
                "price": 12780.45,
                "ask": 44,
                "bid": 91
            },
            {
                "price": 12967.27,
                "ask": 77,
                "bid": 50
            },
            {
                "price": 12916.37,
                "ask": 37,
                "bid": 74
            },
            {
                "price": 12970.17,
                "ask": 55,
                "bid": 88
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcd40644886c9769c8",
        "high": 12873.36,
        "open": 12913.55,
        "close": 12716.27,
        "low": 12850.62,
        "priceList": [
            {
                "price": 12752.27,
                "ask": 24,
                "bid": 1
            },
            {
                "price": 12956.52,
                "ask": 97,
                "bid": 14
            },
            {
                "price": 12808.43,
                "ask": 18,
                "bid": 29
            },
            {
                "price": 12871.37,
                "ask": 3,
                "bid": 77
            },
            {
                "price": 12945.56,
                "ask": 33,
                "bid": 41
            },
            {
                "price": 12756.28,
                "ask": 18,
                "bid": 13
            },
            {
                "price": 12840.76,
                "ask": 72,
                "bid": 84
            },
            {
                "price": 12859.26,
                "ask": 42,
                "bid": 53
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc58cf789d92169634",
        "high": 12968.34,
        "open": 12905.74,
        "close": 12804.5,
        "low": 12712.17,
        "priceList": [
            {
                "price": 12978.73,
                "ask": 73,
                "bid": 12
            },
            {
                "price": 12886.93,
                "ask": 91,
                "bid": 72
            },
            {
                "price": 12900.88,
                "ask": 49,
                "bid": 50
            },
            {
                "price": 12862.95,
                "ask": 51,
                "bid": 18
            },
            {
                "price": 12702.79,
                "ask": 87,
                "bid": 86
            },
            {
                "price": 12750.49,
                "ask": 74,
                "bid": 55
            },
            {
                "price": 12953.43,
                "ask": 62,
                "bid": 28
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dce19f047a03e07ed3",
        "high": 12961.65,
        "open": 12940.96,
        "close": 12816.56,
        "low": 12928.03,
        "priceList": [
            {
                "price": 12942.84,
                "ask": 52,
                "bid": 92
            },
            {
                "price": 12996.57,
                "ask": 51,
                "bid": 67
            },
            {
                "price": 12821.68,
                "ask": 96,
                "bid": 81
            },
            {
                "price": 12941.69,
                "ask": 23,
                "bid": 24
            },
            {
                "price": 12831.18,
                "ask": 56,
                "bid": 75
            },
            {
                "price": 12813.69,
                "ask": 18,
                "bid": 34
            },
            {
                "price": 12714.82,
                "ask": 47,
                "bid": 15
            },
            {
                "price": 12845.97,
                "ask": 53,
                "bid": 9
            },
            {
                "price": 12941.85,
                "ask": 50,
                "bid": 91
            },
            {
                "price": 12726.47,
                "ask": 25,
                "bid": 66
            },
            {
                "price": 12755.6,
                "ask": 24,
                "bid": 21
            },
            {
                "price": 12861.77,
                "ask": 26,
                "bid": 28
            },
            {
                "price": 12720.58,
                "ask": 14,
                "bid": 1
            },
            {
                "price": 12976.3,
                "ask": 10,
                "bid": 85
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc119c4c6999921a00",
        "high": 12959.09,
        "open": 12968.97,
        "close": 12874.14,
        "low": 12738.97,
        "priceList": [
            {
                "price": 12912.48,
                "ask": 84,
                "bid": 67
            },
            {
                "price": 12983.79,
                "ask": 22,
                "bid": 6
            },
            {
                "price": 12933.76,
                "ask": 51,
                "bid": 64
            },
            {
                "price": 12937.75,
                "ask": 87,
                "bid": 86
            },
            {
                "price": 12720.3,
                "ask": 39,
                "bid": 68
            },
            {
                "price": 12961.07,
                "ask": 55,
                "bid": 20
            },
            {
                "price": 12883.3,
                "ask": 98,
                "bid": 13
            },
            {
                "price": 12975.92,
                "ask": 56,
                "bid": 39
            },
            {
                "price": 12863.29,
                "ask": 38,
                "bid": 98
            },
            {
                "price": 12951.65,
                "ask": 29,
                "bid": 16
            },
            {
                "price": 12925.82,
                "ask": 10,
                "bid": 28
            },
            {
                "price": 12894.26,
                "ask": 81,
                "bid": 29
            },
            {
                "price": 12827.26,
                "ask": 20,
                "bid": 24
            },
            {
                "price": 12987.68,
                "ask": 49,
                "bid": 72
            },
            {
                "price": 12936.43,
                "ask": 7,
                "bid": 57
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc9c0cd92f835d5ea6",
        "high": 12990.56,
        "open": 12866.93,
        "close": 12875.01,
        "low": 12828.95,
        "priceList": [
            {
                "price": 12960.5,
                "ask": 59,
                "bid": 9
            },
            {
                "price": 12773.82,
                "ask": 28,
                "bid": 1
            },
            {
                "price": 12752.93,
                "ask": 17,
                "bid": 92
            },
            {
                "price": 12709.39,
                "ask": 17,
                "bid": 10
            },
            {
                "price": 12858.95,
                "ask": 67,
                "bid": 14
            },
            {
                "price": 12902.4,
                "ask": 21,
                "bid": 89
            },
            {
                "price": 12989.8,
                "ask": 24,
                "bid": 9
            },
            {
                "price": 12825.31,
                "ask": 85,
                "bid": 64
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc2076c2d7e22d017e",
        "high": 12991.23,
        "open": 12703.94,
        "close": 12957.24,
        "low": 12811.55,
        "priceList": [
            {
                "price": 12823.04,
                "ask": 33,
                "bid": 33
            },
            {
                "price": 12842.23,
                "ask": 44,
                "bid": 7
            },
            {
                "price": 12957.15,
                "ask": 77,
                "bid": 98
            },
            {
                "price": 12826.55,
                "ask": 54,
                "bid": 26
            },
            {
                "price": 12796.93,
                "ask": 60,
                "bid": 86
            },
            {
                "price": 12705.06,
                "ask": 91,
                "bid": 28
            },
            {
                "price": 12740.79,
                "ask": 62,
                "bid": 78
            },
            {
                "price": 12760.73,
                "ask": 85,
                "bid": 58
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc426e24f4baad37fc",
        "high": 12941.64,
        "open": 12927,
        "close": 12710.48,
        "low": 12903.74,
        "priceList": [
            {
                "price": 12795.54,
                "ask": 68,
                "bid": 18
            },
            {
                "price": 12873.39,
                "ask": 93,
                "bid": 97
            },
            {
                "price": 12975.49,
                "ask": 34,
                "bid": 86
            },
            {
                "price": 12762.33,
                "ask": 67,
                "bid": 36
            },
            {
                "price": 12757.63,
                "ask": 6,
                "bid": 16
            },
            {
                "price": 12790.49,
                "ask": 62,
                "bid": 22
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc695db176034f24c1",
        "high": 12820.66,
        "open": 12720.5,
        "close": 12760.28,
        "low": 12801.16,
        "priceList": [
            {
                "price": 12800.55,
                "ask": 35,
                "bid": 12
            },
            {
                "price": 12707.46,
                "ask": 0,
                "bid": 80
            },
            {
                "price": 12967.39,
                "ask": 7,
                "bid": 87
            },
            {
                "price": 12938.23,
                "ask": 59,
                "bid": 30
            },
            {
                "price": 12886.55,
                "ask": 92,
                "bid": 74
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc1bfd724f494623ce",
        "high": 12960.29,
        "open": 12928.54,
        "close": 12971.98,
        "low": 12895.19,
        "priceList": [
            {
                "price": 12818.66,
                "ask": 15,
                "bid": 56
            },
            {
                "price": 12747.25,
                "ask": 19,
                "bid": 62
            },
            {
                "price": 12852.25,
                "ask": 50,
                "bid": 49
            },
            {
                "price": 12961.64,
                "ask": 8,
                "bid": 47
            },
            {
                "price": 12954.29,
                "ask": 62,
                "bid": 40
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcd61220ecc9e5e314",
        "high": 12967.83,
        "open": 12712.57,
        "close": 12837.47,
        "low": 12930.26,
        "priceList": [
            {
                "price": 12762.76,
                "ask": 18,
                "bid": 48
            },
            {
                "price": 12806.96,
                "ask": 13,
                "bid": 90
            },
            {
                "price": 12960.81,
                "ask": 79,
                "bid": 54
            },
            {
                "price": 12958.1,
                "ask": 83,
                "bid": 70
            },
            {
                "price": 12815.45,
                "ask": 20,
                "bid": 6
            },
            {
                "price": 12925.16,
                "ask": 23,
                "bid": 73
            },
            {
                "price": 12944.15,
                "ask": 60,
                "bid": 9
            },
            {
                "price": 12812.74,
                "ask": 74,
                "bid": 98
            },
            {
                "price": 12789.35,
                "ask": 100,
                "bid": 28
            },
            {
                "price": 12756.48,
                "ask": 79,
                "bid": 12
            },
            {
                "price": 12914.69,
                "ask": 86,
                "bid": 70
            },
            {
                "price": 12985.67,
                "ask": 81,
                "bid": 24
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc6ae9a768232fa88d",
        "high": 12829.97,
        "open": 12998.74,
        "close": 12857.88,
        "low": 12895.41,
        "priceList": [
            {
                "price": 12840.38,
                "ask": 15,
                "bid": 65
            },
            {
                "price": 12981.71,
                "ask": 9,
                "bid": 38
            },
            {
                "price": 12856.11,
                "ask": 3,
                "bid": 78
            },
            {
                "price": 12765.54,
                "ask": 20,
                "bid": 36
            },
            {
                "price": 12825.59,
                "ask": 3,
                "bid": 21
            },
            {
                "price": 12971.53,
                "ask": 18,
                "bid": 34
            },
            {
                "price": 12933.13,
                "ask": 43,
                "bid": 77
            },
            {
                "price": 12782.6,
                "ask": 48,
                "bid": 50
            },
            {
                "price": 12710.57,
                "ask": 44,
                "bid": 36
            },
            {
                "price": 12884.22,
                "ask": 2,
                "bid": 80
            },
            {
                "price": 12700.02,
                "ask": 76,
                "bid": 64
            },
            {
                "price": 12874.6,
                "ask": 76,
                "bid": 26
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcf8535e2573c8e63c",
        "high": 12820.52,
        "open": 12727.23,
        "close": 12779.35,
        "low": 12795.42,
        "priceList": [
            {
                "price": 12935.17,
                "ask": 37,
                "bid": 74
            },
            {
                "price": 12772.01,
                "ask": 25,
                "bid": 85
            },
            {
                "price": 12984.75,
                "ask": 14,
                "bid": 18
            },
            {
                "price": 12937.79,
                "ask": 91,
                "bid": 2
            },
            {
                "price": 12925.58,
                "ask": 55,
                "bid": 91
            },
            {
                "price": 12760.16,
                "ask": 99,
                "bid": 3
            },
            {
                "price": 12726.68,
                "ask": 61,
                "bid": 25
            },
            {
                "price": 12734.35,
                "ask": 83,
                "bid": 98
            },
            {
                "price": 12845.49,
                "ask": 83,
                "bid": 75
            },
            {
                "price": 12742.44,
                "ask": 40,
                "bid": 0
            },
            {
                "price": 12813.04,
                "ask": 54,
                "bid": 59
            },
            {
                "price": 12845.25,
                "ask": 81,
                "bid": 79
            },
            {
                "price": 12793.89,
                "ask": 98,
                "bid": 100
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc54af44b0ac0b375b",
        "high": 12754.48,
        "open": 12716.03,
        "close": 12848.69,
        "low": 12772.18,
        "priceList": [
            {
                "price": 12944.74,
                "ask": 15,
                "bid": 10
            },
            {
                "price": 12712.24,
                "ask": 82,
                "bid": 99
            },
            {
                "price": 12924.33,
                "ask": 87,
                "bid": 20
            },
            {
                "price": 12991.73,
                "ask": 21,
                "bid": 55
            },
            {
                "price": 12745.16,
                "ask": 63,
                "bid": 96
            },
            {
                "price": 12902.37,
                "ask": 42,
                "bid": 72
            },
            {
                "price": 12814.9,
                "ask": 30,
                "bid": 98
            },
            {
                "price": 12859.26,
                "ask": 31,
                "bid": 72
            },
            {
                "price": 12907.51,
                "ask": 47,
                "bid": 36
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc1a84907571b2097b",
        "high": 12716,
        "open": 12822.38,
        "close": 12746.15,
        "low": 12885.72,
        "priceList": [
            {
                "price": 12718.08,
                "ask": 86,
                "bid": 3
            },
            {
                "price": 12810.91,
                "ask": 60,
                "bid": 37
            },
            {
                "price": 12975.02,
                "ask": 41,
                "bid": 84
            },
            {
                "price": 12749.03,
                "ask": 71,
                "bid": 24
            },
            {
                "price": 12814.08,
                "ask": 52,
                "bid": 100
            },
            {
                "price": 12894.58,
                "ask": 69,
                "bid": 15
            },
            {
                "price": 12845.25,
                "ask": 54,
                "bid": 50
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc00add7fdaccf5d1f",
        "high": 12897.71,
        "open": 12834.43,
        "close": 12856.62,
        "low": 12753.89,
        "priceList": [
            {
                "price": 12712.99,
                "ask": 51,
                "bid": 65
            },
            {
                "price": 12798.16,
                "ask": 18,
                "bid": 37
            },
            {
                "price": 12891.08,
                "ask": 64,
                "bid": 31
            },
            {
                "price": 12861.07,
                "ask": 94,
                "bid": 75
            },
            {
                "price": 12970.37,
                "ask": 45,
                "bid": 18
            },
            {
                "price": 12897.39,
                "ask": 79,
                "bid": 68
            },
            {
                "price": 12867.43,
                "ask": 83,
                "bid": 24
            },
            {
                "price": 12768.66,
                "ask": 25,
                "bid": 61
            },
            {
                "price": 12806.42,
                "ask": 68,
                "bid": 13
            },
            {
                "price": 12728.28,
                "ask": 75,
                "bid": 57
            },
            {
                "price": 12731.85,
                "ask": 83,
                "bid": 6
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc32a7a7f5d2849747",
        "high": 12720.4,
        "open": 12705.49,
        "close": 12912.98,
        "low": 12964.01,
        "priceList": [
            {
                "price": 12952.09,
                "ask": 55,
                "bid": 53
            },
            {
                "price": 12926.23,
                "ask": 4,
                "bid": 62
            },
            {
                "price": 12963.81,
                "ask": 49,
                "bid": 21
            },
            {
                "price": 12714.38,
                "ask": 49,
                "bid": 26
            },
            {
                "price": 12974.73,
                "ask": 90,
                "bid": 96
            },
            {
                "price": 12931.82,
                "ask": 6,
                "bid": 42
            },
            {
                "price": 12717.34,
                "ask": 64,
                "bid": 25
            },
            {
                "price": 12901.84,
                "ask": 50,
                "bid": 77
            },
            {
                "price": 12963.21,
                "ask": 99,
                "bid": 98
            },
            {
                "price": 12710.26,
                "ask": 95,
                "bid": 74
            },
            {
                "price": 12792.4,
                "ask": 40,
                "bid": 2
            },
            {
                "price": 12710.32,
                "ask": 50,
                "bid": 98
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc6dd4f96bad4b732a",
        "high": 12860.93,
        "open": 12942.08,
        "close": 12802.06,
        "low": 12959.9,
        "priceList": [
            {
                "price": 12859.95,
                "ask": 77,
                "bid": 72
            },
            {
                "price": 12862.33,
                "ask": 20,
                "bid": 4
            },
            {
                "price": 12851.83,
                "ask": 42,
                "bid": 44
            },
            {
                "price": 12933.58,
                "ask": 59,
                "bid": 81
            },
            {
                "price": 12882.19,
                "ask": 18,
                "bid": 28
            },
            {
                "price": 12916.43,
                "ask": 13,
                "bid": 15
            },
            {
                "price": 12870,
                "ask": 85,
                "bid": 43
            },
            {
                "price": 12884.09,
                "ask": 74,
                "bid": 99
            },
            {
                "price": 12856.12,
                "ask": 49,
                "bid": 97
            },
            {
                "price": 12934.3,
                "ask": 6,
                "bid": 39
            },
            {
                "price": 12865.31,
                "ask": 66,
                "bid": 16
            },
            {
                "price": 12791.85,
                "ask": 27,
                "bid": 84
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dca284f2e721f90e27",
        "high": 12821.71,
        "open": 12939.33,
        "close": 12710.86,
        "low": 12714.36,
        "priceList": [
            {
                "price": 12906.67,
                "ask": 100,
                "bid": 17
            },
            {
                "price": 12862.61,
                "ask": 74,
                "bid": 95
            },
            {
                "price": 12880.64,
                "ask": 30,
                "bid": 19
            },
            {
                "price": 12859.62,
                "ask": 85,
                "bid": 13
            },
            {
                "price": 12716.32,
                "ask": 34,
                "bid": 6
            },
            {
                "price": 12997.51,
                "ask": 26,
                "bid": 68
            },
            {
                "price": 12895.79,
                "ask": 73,
                "bid": 50
            },
            {
                "price": 12735.04,
                "ask": 81,
                "bid": 58
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dca9450a58410672c1",
        "high": 12848.71,
        "open": 12703.69,
        "close": 12987.54,
        "low": 12941.04,
        "priceList": [
            {
                "price": 12928.6,
                "ask": 90,
                "bid": 46
            },
            {
                "price": 12958.64,
                "ask": 70,
                "bid": 83
            },
            {
                "price": 12737.26,
                "ask": 69,
                "bid": 70
            },
            {
                "price": 12773.88,
                "ask": 25,
                "bid": 4
            },
            {
                "price": 12729.72,
                "ask": 77,
                "bid": 62
            },
            {
                "price": 12855.47,
                "ask": 31,
                "bid": 89
            },
            {
                "price": 12996.18,
                "ask": 71,
                "bid": 82
            },
            {
                "price": 12726.02,
                "ask": 76,
                "bid": 99
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc33e93c77d4b1c491",
        "high": 12727.44,
        "open": 12738.66,
        "close": 12833.2,
        "low": 12860.47,
        "priceList": [
            {
                "price": 12861.78,
                "ask": 31,
                "bid": 98
            },
            {
                "price": 12771.21,
                "ask": 40,
                "bid": 23
            },
            {
                "price": 12742.84,
                "ask": 10,
                "bid": 85
            },
            {
                "price": 12964.91,
                "ask": 80,
                "bid": 36
            },
            {
                "price": 12968.73,
                "ask": 17,
                "bid": 22
            },
            {
                "price": 12954.8,
                "ask": 63,
                "bid": 38
            },
            {
                "price": 12732.53,
                "ask": 11,
                "bid": 76
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dca0b6d093f7cf02d1",
        "high": 12895.03,
        "open": 12864.41,
        "close": 12915.74,
        "low": 12769.63,
        "priceList": [
            {
                "price": 12991.15,
                "ask": 14,
                "bid": 5
            },
            {
                "price": 12882.13,
                "ask": 74,
                "bid": 73
            },
            {
                "price": 12882.75,
                "ask": 78,
                "bid": 85
            },
            {
                "price": 12886.03,
                "ask": 30,
                "bid": 93
            },
            {
                "price": 12926.67,
                "ask": 91,
                "bid": 57
            },
            {
                "price": 12976.73,
                "ask": 52,
                "bid": 48
            },
            {
                "price": 12839.76,
                "ask": 7,
                "bid": 73
            },
            {
                "price": 12786.32,
                "ask": 7,
                "bid": 82
            },
            {
                "price": 12840.52,
                "ask": 68,
                "bid": 42
            },
            {
                "price": 12841.97,
                "ask": 78,
                "bid": 13
            },
            {
                "price": 12832.79,
                "ask": 0,
                "bid": 96
            },
            {
                "price": 12984.75,
                "ask": 66,
                "bid": 19
            },
            {
                "price": 12771.59,
                "ask": 84,
                "bid": 1
            },
            {
                "price": 12808.81,
                "ask": 54,
                "bid": 62
            },
            {
                "price": 12896.4,
                "ask": 89,
                "bid": 62
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc8ef7ce3cec04d374",
        "high": 12763.33,
        "open": 12880.75,
        "close": 12862.96,
        "low": 12843,
        "priceList": [
            {
                "price": 12985.4,
                "ask": 3,
                "bid": 11
            },
            {
                "price": 12823.52,
                "ask": 33,
                "bid": 46
            },
            {
                "price": 12815.7,
                "ask": 92,
                "bid": 26
            },
            {
                "price": 12895.01,
                "ask": 65,
                "bid": 77
            },
            {
                "price": 12876.89,
                "ask": 46,
                "bid": 55
            },
            {
                "price": 12777.88,
                "ask": 61,
                "bid": 11
            },
            {
                "price": 12979.38,
                "ask": 11,
                "bid": 91
            },
            {
                "price": 12924.89,
                "ask": 30,
                "bid": 42
            },
            {
                "price": 12889.33,
                "ask": 6,
                "bid": 37
            },
            {
                "price": 12746.73,
                "ask": 64,
                "bid": 67
            },
            {
                "price": 12983.44,
                "ask": 5,
                "bid": 71
            },
            {
                "price": 12967.22,
                "ask": 33,
                "bid": 17
            },
            {
                "price": 12823.14,
                "ask": 51,
                "bid": 21
            },
            {
                "price": 12792.81,
                "ask": 8,
                "bid": 89
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc8f9978275036be64",
        "high": 12759.57,
        "open": 12767.18,
        "close": 12798.28,
        "low": 12919.24,
        "priceList": [
            {
                "price": 12850.35,
                "ask": 88,
                "bid": 5
            },
            {
                "price": 12828.73,
                "ask": 41,
                "bid": 19
            },
            {
                "price": 12851.8,
                "ask": 45,
                "bid": 17
            },
            {
                "price": 12840.22,
                "ask": 64,
                "bid": 65
            },
            {
                "price": 12877.89,
                "ask": 48,
                "bid": 30
            },
            {
                "price": 12831.57,
                "ask": 8,
                "bid": 81
            },
            {
                "price": 12722.39,
                "ask": 29,
                "bid": 47
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcac49f530adeae440",
        "high": 12834.26,
        "open": 12893.28,
        "close": 12725.65,
        "low": 12997.57,
        "priceList": [
            {
                "price": 12990.89,
                "ask": 63,
                "bid": 17
            },
            {
                "price": 12964.74,
                "ask": 29,
                "bid": 70
            },
            {
                "price": 12936.44,
                "ask": 69,
                "bid": 0
            },
            {
                "price": 12889.46,
                "ask": 13,
                "bid": 9
            },
            {
                "price": 12963.8,
                "ask": 67,
                "bid": 91
            },
            {
                "price": 12701.88,
                "ask": 63,
                "bid": 26
            },
            {
                "price": 12770.76,
                "ask": 12,
                "bid": 39
            },
            {
                "price": 12877.89,
                "ask": 69,
                "bid": 97
            },
            {
                "price": 12817.82,
                "ask": 76,
                "bid": 77
            },
            {
                "price": 12717.19,
                "ask": 19,
                "bid": 52
            },
            {
                "price": 12742.51,
                "ask": 8,
                "bid": 55
            },
            {
                "price": 12920.41,
                "ask": 73,
                "bid": 48
            },
            {
                "price": 12995.26,
                "ask": 86,
                "bid": 71
            },
            {
                "price": 12731.39,
                "ask": 1,
                "bid": 37
            },
            {
                "price": 12978.97,
                "ask": 56,
                "bid": 2
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc604ef9da6e926874",
        "high": 12731.52,
        "open": 12712.78,
        "close": 12719.47,
        "low": 12889.97,
        "priceList": [
            {
                "price": 12921.53,
                "ask": 71,
                "bid": 59
            },
            {
                "price": 12749.76,
                "ask": 67,
                "bid": 63
            },
            {
                "price": 12741.02,
                "ask": 68,
                "bid": 15
            },
            {
                "price": 12904.45,
                "ask": 78,
                "bid": 96
            },
            {
                "price": 12830.71,
                "ask": 38,
                "bid": 52
            },
            {
                "price": 12900.91,
                "ask": 31,
                "bid": 93
            },
            {
                "price": 12976.9,
                "ask": 24,
                "bid": 30
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcf9b8b964fcff988b",
        "high": 12828.95,
        "open": 12929.96,
        "close": 12915.08,
        "low": 12750.78,
        "priceList": [
            {
                "price": 12726.34,
                "ask": 34,
                "bid": 64
            },
            {
                "price": 12798.8,
                "ask": 45,
                "bid": 11
            },
            {
                "price": 12781.1,
                "ask": 87,
                "bid": 100
            },
            {
                "price": 12944.08,
                "ask": 89,
                "bid": 4
            },
            {
                "price": 12734.29,
                "ask": 99,
                "bid": 19
            },
            {
                "price": 12995.08,
                "ask": 17,
                "bid": 73
            },
            {
                "price": 12879.13,
                "ask": 33,
                "bid": 58
            },
            {
                "price": 12716.82,
                "ask": 86,
                "bid": 64
            },
            {
                "price": 12841.43,
                "ask": 46,
                "bid": 35
            },
            {
                "price": 12882.49,
                "ask": 36,
                "bid": 80
            },
            {
                "price": 12852.65,
                "ask": 30,
                "bid": 100
            },
            {
                "price": 12748.88,
                "ask": 44,
                "bid": 1
            },
            {
                "price": 12750.01,
                "ask": 60,
                "bid": 47
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc3ca2a4d43135524",
        "high": 12936.89,
        "open": 12792.55,
        "close": 12765.63,
        "low": 12922.06,
        "priceList": [
            {
                "price": 12921.75,
                "ask": 19,
                "bid": 97
            },
            {
                "price": 12972.5,
                "ask": 50,
                "bid": 71
            },
            {
                "price": 12841.67,
                "ask": 46,
                "bid": 20
            },
            {
                "price": 12764.94,
                "ask": 95,
                "bid": 7
            },
            {
                "price": 12832.96,
                "ask": 5,
                "bid": 62
            },
            {
                "price": 12777.52,
                "ask": 37,
                "bid": 87
            },
            {
                "price": 12874.21,
                "ask": 60,
                "bid": 32
            },
            {
                "price": 12710.52,
                "ask": 18,
                "bid": 87
            },
            {
                "price": 12966.19,
                "ask": 5,
                "bid": 53
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc6dc05f4b77cf8ecd",
        "high": 12933.13,
        "open": 12921.12,
        "close": 12945.46,
        "low": 12775.62,
        "priceList": [
            {
                "price": 12887.91,
                "ask": 79,
                "bid": 62
            },
            {
                "price": 12957.23,
                "ask": 37,
                "bid": 41
            },
            {
                "price": 12709.39,
                "ask": 18,
                "bid": 70
            },
            {
                "price": 12809.34,
                "ask": 70,
                "bid": 66
            },
            {
                "price": 12802.42,
                "ask": 67,
                "bid": 96
            },
            {
                "price": 12944.38,
                "ask": 27,
                "bid": 48
            },
            {
                "price": 12996.8,
                "ask": 29,
                "bid": 98
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcd972d71daea8cefd",
        "high": 12928.13,
        "open": 12883.7,
        "close": 12986.97,
        "low": 12708.5,
        "priceList": [
            {
                "price": 12827.84,
                "ask": 76,
                "bid": 99
            },
            {
                "price": 12751.22,
                "ask": 28,
                "bid": 68
            },
            {
                "price": 12828.18,
                "ask": 79,
                "bid": 52
            },
            {
                "price": 12918.9,
                "ask": 22,
                "bid": 7
            },
            {
                "price": 12940.01,
                "ask": 87,
                "bid": 16
            },
            {
                "price": 12704.95,
                "ask": 9,
                "bid": 93
            },
            {
                "price": 12793.21,
                "ask": 54,
                "bid": 61
            },
            {
                "price": 12718.72,
                "ask": 37,
                "bid": 51
            },
            {
                "price": 12855.89,
                "ask": 25,
                "bid": 97
            },
            {
                "price": 12773.96,
                "ask": 59,
                "bid": 65
            },
            {
                "price": 12778.47,
                "ask": 34,
                "bid": 53
            },
            {
                "price": 12886.71,
                "ask": 10,
                "bid": 36
            },
            {
                "price": 12736.49,
                "ask": 38,
                "bid": 10
            },
            {
                "price": 12910.87,
                "ask": 54,
                "bid": 48
            },
            {
                "price": 12830.78,
                "ask": 88,
                "bid": 10
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc29ca5e8b58c78da6",
        "high": 12982.51,
        "open": 12863.15,
        "close": 12721.05,
        "low": 12867.92,
        "priceList": [
            {
                "price": 12729.19,
                "ask": 74,
                "bid": 40
            },
            {
                "price": 12780.86,
                "ask": 17,
                "bid": 14
            },
            {
                "price": 12797.49,
                "ask": 90,
                "bid": 41
            },
            {
                "price": 12788.86,
                "ask": 38,
                "bid": 99
            },
            {
                "price": 12705.09,
                "ask": 4,
                "bid": 96
            },
            {
                "price": 12931.23,
                "ask": 61,
                "bid": 9
            },
            {
                "price": 12967.22,
                "ask": 13,
                "bid": 70
            },
            {
                "price": 12971.83,
                "ask": 27,
                "bid": 19
            },
            {
                "price": 12810.36,
                "ask": 100,
                "bid": 66
            },
            {
                "price": 12778.47,
                "ask": 13,
                "bid": 67
            },
            {
                "price": 12881.32,
                "ask": 59,
                "bid": 37
            },
            {
                "price": 12701.86,
                "ask": 41,
                "bid": 43
            },
            {
                "price": 12914.39,
                "ask": 12,
                "bid": 26
            },
            {
                "price": 12806.11,
                "ask": 55,
                "bid": 18
            },
            {
                "price": 12734.84,
                "ask": 49,
                "bid": 55
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dce959086a846bc665",
        "high": 12953.17,
        "open": 12873.83,
        "close": 12803.02,
        "low": 12908.13,
        "priceList": [
            {
                "price": 12700.97,
                "ask": 42,
                "bid": 42
            },
            {
                "price": 12908.06,
                "ask": 19,
                "bid": 0
            },
            {
                "price": 12705.75,
                "ask": 81,
                "bid": 45
            },
            {
                "price": 12715.06,
                "ask": 6,
                "bid": 36
            },
            {
                "price": 12721.09,
                "ask": 85,
                "bid": 31
            },
            {
                "price": 12747.56,
                "ask": 18,
                "bid": 7
            },
            {
                "price": 12716.82,
                "ask": 72,
                "bid": 97
            },
            {
                "price": 12984.31,
                "ask": 59,
                "bid": 30
            },
            {
                "price": 12764.37,
                "ask": 43,
                "bid": 56
            },
            {
                "price": 12813.09,
                "ask": 18,
                "bid": 32
            },
            {
                "price": 12796.93,
                "ask": 71,
                "bid": 82
            },
            {
                "price": 12867.16,
                "ask": 26,
                "bid": 84
            },
            {
                "price": 12848.65,
                "ask": 34,
                "bid": 72
            },
            {
                "price": 12905.97,
                "ask": 92,
                "bid": 45
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc1a8b95d953c001ed",
        "high": 12927.22,
        "open": 12891.89,
        "close": 12941.17,
        "low": 12973.34,
        "priceList": [
            {
                "price": 12986.94,
                "ask": 7,
                "bid": 44
            },
            {
                "price": 12995.5,
                "ask": 67,
                "bid": 70
            },
            {
                "price": 12816.44,
                "ask": 98,
                "bid": 65
            },
            {
                "price": 12919.16,
                "ask": 53,
                "bid": 63
            },
            {
                "price": 12728.35,
                "ask": 40,
                "bid": 39
            },
            {
                "price": 12836.62,
                "ask": 93,
                "bid": 81
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc61b1b47211598916",
        "high": 12967.41,
        "open": 12759.32,
        "close": 12721.75,
        "low": 12829.3,
        "priceList": [
            {
                "price": 12882,
                "ask": 60,
                "bid": 19
            },
            {
                "price": 12826.19,
                "ask": 36,
                "bid": 9
            },
            {
                "price": 12877.27,
                "ask": 91,
                "bid": 39
            },
            {
                "price": 12793.91,
                "ask": 35,
                "bid": 90
            },
            {
                "price": 12976.14,
                "ask": 0,
                "bid": 5
            },
            {
                "price": 12854.33,
                "ask": 74,
                "bid": 49
            },
            {
                "price": 12825.1,
                "ask": 83,
                "bid": 66
            },
            {
                "price": 12777.75,
                "ask": 32,
                "bid": 36
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcf2f6c50c0c3f8ad3",
        "high": 12941.2,
        "open": 12724.97,
        "close": 12790.51,
        "low": 12914.53,
        "priceList": [
            {
                "price": 12923.85,
                "ask": 36,
                "bid": 2
            },
            {
                "price": 12888.8,
                "ask": 95,
                "bid": 46
            },
            {
                "price": 12716,
                "ask": 39,
                "bid": 44
            },
            {
                "price": 12767.61,
                "ask": 13,
                "bid": 99
            },
            {
                "price": 12750.86,
                "ask": 48,
                "bid": 11
            },
            {
                "price": 12786.37,
                "ask": 50,
                "bid": 68
            },
            {
                "price": 12957.06,
                "ask": 77,
                "bid": 74
            },
            {
                "price": 12852.43,
                "ask": 31,
                "bid": 97
            },
            {
                "price": 12733.12,
                "ask": 38,
                "bid": 16
            },
            {
                "price": 12839.61,
                "ask": 61,
                "bid": 69
            },
            {
                "price": 12751.31,
                "ask": 93,
                "bid": 58
            },
            {
                "price": 12766.32,
                "ask": 17,
                "bid": 1
            },
            {
                "price": 12818.84,
                "ask": 11,
                "bid": 57
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc356d6761734e9169",
        "high": 12893.1,
        "open": 12929.77,
        "close": 12956.3,
        "low": 12886.4,
        "priceList": [
            {
                "price": 12765.38,
                "ask": 67,
                "bid": 25
            },
            {
                "price": 12821.46,
                "ask": 36,
                "bid": 99
            },
            {
                "price": 12941.95,
                "ask": 21,
                "bid": 34
            },
            {
                "price": 12999.31,
                "ask": 77,
                "bid": 67
            },
            {
                "price": 12920.73,
                "ask": 48,
                "bid": 18
            },
            {
                "price": 12728.63,
                "ask": 32,
                "bid": 29
            },
            {
                "price": 12788.61,
                "ask": 32,
                "bid": 12
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc095b025f4568e1ed",
        "high": 12958.07,
        "open": 12710.47,
        "close": 12709.75,
        "low": 12752.28,
        "priceList": [
            {
                "price": 12850.69,
                "ask": 52,
                "bid": 39
            },
            {
                "price": 12843.3,
                "ask": 19,
                "bid": 16
            },
            {
                "price": 12946.38,
                "ask": 82,
                "bid": 47
            },
            {
                "price": 12720.38,
                "ask": 55,
                "bid": 0
            },
            {
                "price": 12959.16,
                "ask": 50,
                "bid": 58
            },
            {
                "price": 12721.89,
                "ask": 96,
                "bid": 15
            },
            {
                "price": 12956.29,
                "ask": 51,
                "bid": 57
            },
            {
                "price": 12927.1,
                "ask": 61,
                "bid": 2
            },
            {
                "price": 12874.2,
                "ask": 68,
                "bid": 91
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc43dcbc919231be9b",
        "high": 12943.27,
        "open": 12786.35,
        "close": 12923.12,
        "low": 12849.52,
        "priceList": [
            {
                "price": 12992.24,
                "ask": 0,
                "bid": 58
            },
            {
                "price": 12962.29,
                "ask": 57,
                "bid": 69
            },
            {
                "price": 12922.35,
                "ask": 13,
                "bid": 51
            },
            {
                "price": 12710.15,
                "ask": 90,
                "bid": 66
            },
            {
                "price": 12964.25,
                "ask": 47,
                "bid": 15
            },
            {
                "price": 12943.99,
                "ask": 2,
                "bid": 36
            },
            {
                "price": 12738.05,
                "ask": 52,
                "bid": 59
            },
            {
                "price": 12959.74,
                "ask": 77,
                "bid": 2
            },
            {
                "price": 12843.37,
                "ask": 36,
                "bid": 97
            },
            {
                "price": 12978.38,
                "ask": 96,
                "bid": 78
            },
            {
                "price": 12931.36,
                "ask": 98,
                "bid": 84
            },
            {
                "price": 12970.61,
                "ask": 8,
                "bid": 61
            },
            {
                "price": 12920.2,
                "ask": 22,
                "bid": 20
            },
            {
                "price": 12814.27,
                "ask": 81,
                "bid": 66
            },
            {
                "price": 12930.48,
                "ask": 92,
                "bid": 45
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc96ec784cff73329d",
        "high": 12934.75,
        "open": 12834.74,
        "close": 12748.06,
        "low": 12712.15,
        "priceList": [
            {
                "price": 12718.52,
                "ask": 52,
                "bid": 20
            },
            {
                "price": 12771.29,
                "ask": 5,
                "bid": 25
            },
            {
                "price": 12836.61,
                "ask": 72,
                "bid": 67
            },
            {
                "price": 12997.51,
                "ask": 32,
                "bid": 81
            },
            {
                "price": 12858.57,
                "ask": 86,
                "bid": 78
            },
            {
                "price": 12927.33,
                "ask": 50,
                "bid": 44
            },
            {
                "price": 12812.13,
                "ask": 31,
                "bid": 2
            },
            {
                "price": 12709.69,
                "ask": 99,
                "bid": 87
            },
            {
                "price": 12785.84,
                "ask": 68,
                "bid": 54
            },
            {
                "price": 12733.69,
                "ask": 38,
                "bid": 54
            },
            {
                "price": 12825.03,
                "ask": 22,
                "bid": 63
            },
            {
                "price": 12941.46,
                "ask": 4,
                "bid": 64
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc36a4ff08e6742e8d",
        "high": 12825.06,
        "open": 12891.94,
        "close": 12707.86,
        "low": 12767.91,
        "priceList": [
            {
                "price": 12751.83,
                "ask": 43,
                "bid": 5
            },
            {
                "price": 12774.47,
                "ask": 33,
                "bid": 85
            },
            {
                "price": 12955.2,
                "ask": 21,
                "bid": 31
            },
            {
                "price": 12976,
                "ask": 3,
                "bid": 70
            },
            {
                "price": 12865.94,
                "ask": 91,
                "bid": 29
            },
            {
                "price": 12895.54,
                "ask": 21,
                "bid": 29
            },
            {
                "price": 12852.59,
                "ask": 74,
                "bid": 70
            },
            {
                "price": 12765.49,
                "ask": 34,
                "bid": 35
            },
            {
                "price": 12837.05,
                "ask": 15,
                "bid": 20
            },
            {
                "price": 12783,
                "ask": 4,
                "bid": 10
            },
            {
                "price": 12706.79,
                "ask": 39,
                "bid": 52
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc151e307d627204bc",
        "high": 12780.52,
        "open": 12985.12,
        "close": 12787.54,
        "low": 12803.84,
        "priceList": [
            {
                "price": 12832.64,
                "ask": 41,
                "bid": 62
            },
            {
                "price": 12887.91,
                "ask": 54,
                "bid": 87
            },
            {
                "price": 12749.14,
                "ask": 66,
                "bid": 21
            },
            {
                "price": 12777.99,
                "ask": 92,
                "bid": 32
            },
            {
                "price": 12822.53,
                "ask": 76,
                "bid": 55
            },
            {
                "price": 12792.37,
                "ask": 43,
                "bid": 16
            },
            {
                "price": 12775.98,
                "ask": 26,
                "bid": 63
            },
            {
                "price": 12914.28,
                "ask": 94,
                "bid": 78
            },
            {
                "price": 12788.26,
                "ask": 94,
                "bid": 14
            },
            {
                "price": 12751.72,
                "ask": 2,
                "bid": 49
            },
            {
                "price": 12975.98,
                "ask": 69,
                "bid": 69
            },
            {
                "price": 12971.87,
                "ask": 100,
                "bid": 98
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcded0bb2d963e1803",
        "high": 12943.18,
        "open": 12763.12,
        "close": 12916.93,
        "low": 12912.05,
        "priceList": [
            {
                "price": 12781.58,
                "ask": 30,
                "bid": 51
            },
            {
                "price": 12831.65,
                "ask": 56,
                "bid": 13
            },
            {
                "price": 12708.13,
                "ask": 40,
                "bid": 47
            },
            {
                "price": 12903.84,
                "ask": 16,
                "bid": 9
            },
            {
                "price": 12713.45,
                "ask": 62,
                "bid": 13
            },
            {
                "price": 12721.89,
                "ask": 2,
                "bid": 9
            },
            {
                "price": 12985.86,
                "ask": 69,
                "bid": 20
            },
            {
                "price": 12787.05,
                "ask": 27,
                "bid": 21
            },
            {
                "price": 12951.33,
                "ask": 99,
                "bid": 58
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcc59a23ab39e7e448",
        "high": 12957.97,
        "open": 12944.24,
        "close": 12878.08,
        "low": 12974.28,
        "priceList": [
            {
                "price": 12772.24,
                "ask": 67,
                "bid": 67
            },
            {
                "price": 12855.54,
                "ask": 12,
                "bid": 5
            },
            {
                "price": 12739.68,
                "ask": 21,
                "bid": 7
            },
            {
                "price": 12837.89,
                "ask": 69,
                "bid": 42
            },
            {
                "price": 12806.16,
                "ask": 73,
                "bid": 5
            },
            {
                "price": 12746.04,
                "ask": 0,
                "bid": 82
            },
            {
                "price": 12877.17,
                "ask": 88,
                "bid": 39
            },
            {
                "price": 12741.06,
                "ask": 93,
                "bid": 74
            },
            {
                "price": 12952.23,
                "ask": 54,
                "bid": 1
            },
            {
                "price": 12921.83,
                "ask": 85,
                "bid": 64
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcab78c354986435bc",
        "high": 12988.39,
        "open": 12813.81,
        "close": 12766.66,
        "low": 12815.46,
        "priceList": [
            {
                "price": 12851.03,
                "ask": 30,
                "bid": 47
            },
            {
                "price": 12983.92,
                "ask": 17,
                "bid": 59
            },
            {
                "price": 12927,
                "ask": 26,
                "bid": 52
            },
            {
                "price": 12965.74,
                "ask": 84,
                "bid": 48
            },
            {
                "price": 12981.64,
                "ask": 70,
                "bid": 95
            },
            {
                "price": 12830.66,
                "ask": 9,
                "bid": 30
            },
            {
                "price": 12939.27,
                "ask": 82,
                "bid": 70
            },
            {
                "price": 12800.11,
                "ask": 63,
                "bid": 28
            },
            {
                "price": 12769.97,
                "ask": 23,
                "bid": 30
            },
            {
                "price": 12917.81,
                "ask": 78,
                "bid": 77
            },
            {
                "price": 12856.28,
                "ask": 79,
                "bid": 13
            },
            {
                "price": 12856.99,
                "ask": 99,
                "bid": 62
            },
            {
                "price": 12788.86,
                "ask": 56,
                "bid": 64
            },
            {
                "price": 12854.47,
                "ask": 92,
                "bid": 6
            },
            {
                "price": 12997.39,
                "ask": 10,
                "bid": 47
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc1b559f1be84a85e5",
        "high": 12935.5,
        "open": 12915.12,
        "close": 12856.55,
        "low": 12841.87,
        "priceList": [
            {
                "price": 12740.21,
                "ask": 82,
                "bid": 85
            },
            {
                "price": 12974.49,
                "ask": 79,
                "bid": 57
            },
            {
                "price": 12975.27,
                "ask": 71,
                "bid": 98
            },
            {
                "price": 12853.82,
                "ask": 49,
                "bid": 39
            },
            {
                "price": 12890.91,
                "ask": 66,
                "bid": 8
            },
            {
                "price": 12991.68,
                "ask": 46,
                "bid": 66
            },
            {
                "price": 12886.38,
                "ask": 27,
                "bid": 40
            },
            {
                "price": 12808.28,
                "ask": 20,
                "bid": 18
            },
            {
                "price": 12891.31,
                "ask": 32,
                "bid": 45
            },
            {
                "price": 12931.52,
                "ask": 41,
                "bid": 16
            },
            {
                "price": 12969.09,
                "ask": 46,
                "bid": 56
            },
            {
                "price": 12871.05,
                "ask": 51,
                "bid": 55
            },
            {
                "price": 12918.08,
                "ask": 2,
                "bid": 57
            },
            {
                "price": 12841.24,
                "ask": 93,
                "bid": 74
            },
            {
                "price": 12991.28,
                "ask": 86,
                "bid": 10
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcf4f288a81abd4e8f",
        "high": 12961.03,
        "open": 12921.12,
        "close": 12799.55,
        "low": 12909.72,
        "priceList": [
            {
                "price": 12718.17,
                "ask": 45,
                "bid": 25
            },
            {
                "price": 12975.13,
                "ask": 87,
                "bid": 90
            },
            {
                "price": 12969.24,
                "ask": 75,
                "bid": 45
            },
            {
                "price": 12715.86,
                "ask": 93,
                "bid": 73
            },
            {
                "price": 12965.92,
                "ask": 65,
                "bid": 74
            },
            {
                "price": 12922.76,
                "ask": 91,
                "bid": 86
            },
            {
                "price": 12718.03,
                "ask": 28,
                "bid": 83
            },
            {
                "price": 12926.88,
                "ask": 33,
                "bid": 62
            },
            {
                "price": 12847.82,
                "ask": 92,
                "bid": 54
            },
            {
                "price": 12946.92,
                "ask": 24,
                "bid": 49
            },
            {
                "price": 12947.25,
                "ask": 69,
                "bid": 89
            },
            {
                "price": 12978.7,
                "ask": 90,
                "bid": 49
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc0ccbfb3a27af3fa7",
        "high": 12840.96,
        "open": 12769.38,
        "close": 12850.4,
        "low": 12907.62,
        "priceList": [
            {
                "price": 12838.57,
                "ask": 64,
                "bid": 16
            },
            {
                "price": 12805.88,
                "ask": 64,
                "bid": 79
            },
            {
                "price": 12998.1,
                "ask": 69,
                "bid": 42
            },
            {
                "price": 12852.68,
                "ask": 18,
                "bid": 40
            },
            {
                "price": 12738.67,
                "ask": 31,
                "bid": 91
            },
            {
                "price": 12765.18,
                "ask": 85,
                "bid": 18
            },
            {
                "price": 12921,
                "ask": 92,
                "bid": 7
            },
            {
                "price": 12787.61,
                "ask": 93,
                "bid": 78
            },
            {
                "price": 12998.06,
                "ask": 66,
                "bid": 9
            },
            {
                "price": 12900.94,
                "ask": 44,
                "bid": 26
            },
            {
                "price": 12724.64,
                "ask": 12,
                "bid": 81
            },
            {
                "price": 12702.86,
                "ask": 43,
                "bid": 68
            },
            {
                "price": 12910.04,
                "ask": 40,
                "bid": 47
            },
            {
                "price": 12727.54,
                "ask": 35,
                "bid": 9
            },
            {
                "price": 12744.63,
                "ask": 48,
                "bid": 6
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca5ea2aea99878643",
        "high": 12930.11,
        "open": 12916.55,
        "close": 12965.69,
        "low": 12700.1,
        "priceList": [
            {
                "price": 12923.43,
                "ask": 39,
                "bid": 14
            },
            {
                "price": 12874.17,
                "ask": 97,
                "bid": 54
            },
            {
                "price": 12843.49,
                "ask": 100,
                "bid": 74
            },
            {
                "price": 12921.94,
                "ask": 49,
                "bid": 1
            },
            {
                "price": 12828.22,
                "ask": 74,
                "bid": 36
            },
            {
                "price": 12981.05,
                "ask": 71,
                "bid": 98
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc6eea010ce85d8f92",
        "high": 12837.73,
        "open": 12966,
        "close": 12917.91,
        "low": 12980.26,
        "priceList": [
            {
                "price": 12943.04,
                "ask": 73,
                "bid": 14
            },
            {
                "price": 12997.64,
                "ask": 11,
                "bid": 79
            },
            {
                "price": 12980.07,
                "ask": 36,
                "bid": 15
            },
            {
                "price": 12700.62,
                "ask": 53,
                "bid": 98
            },
            {
                "price": 12752.39,
                "ask": 62,
                "bid": 70
            },
            {
                "price": 12820.44,
                "ask": 81,
                "bid": 30
            },
            {
                "price": 12800.38,
                "ask": 54,
                "bid": 1
            },
            {
                "price": 12736.34,
                "ask": 6,
                "bid": 9
            },
            {
                "price": 12713.53,
                "ask": 6,
                "bid": 46
            },
            {
                "price": 12790,
                "ask": 90,
                "bid": 36
            },
            {
                "price": 12924.4,
                "ask": 94,
                "bid": 81
            },
            {
                "price": 12836.61,
                "ask": 17,
                "bid": 49
            },
            {
                "price": 12960.86,
                "ask": 4,
                "bid": 62
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc6db8f9e8b4d620e3",
        "high": 12893.8,
        "open": 12826.06,
        "close": 12727.63,
        "low": 12771.17,
        "priceList": [
            {
                "price": 12957.37,
                "ask": 20,
                "bid": 63
            },
            {
                "price": 12832.93,
                "ask": 96,
                "bid": 70
            },
            {
                "price": 12982.16,
                "ask": 62,
                "bid": 56
            },
            {
                "price": 12956.59,
                "ask": 39,
                "bid": 20
            },
            {
                "price": 12874.03,
                "ask": 16,
                "bid": 99
            },
            {
                "price": 12758.76,
                "ask": 89,
                "bid": 7
            },
            {
                "price": 12927.56,
                "ask": 28,
                "bid": 56
            },
            {
                "price": 12924.15,
                "ask": 70,
                "bid": 40
            },
            {
                "price": 12987.62,
                "ask": 46,
                "bid": 38
            },
            {
                "price": 12998.59,
                "ask": 71,
                "bid": 8
            },
            {
                "price": 12912.06,
                "ask": 68,
                "bid": 36
            },
            {
                "price": 12738.07,
                "ask": 48,
                "bid": 28
            },
            {
                "price": 12899.06,
                "ask": 79,
                "bid": 49
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf8d6d6b78583ae22",
        "high": 12864.83,
        "open": 12817.93,
        "close": 12782.5,
        "low": 12839.79,
        "priceList": [
            {
                "price": 12994.69,
                "ask": 56,
                "bid": 31
            },
            {
                "price": 12811.36,
                "ask": 87,
                "bid": 51
            },
            {
                "price": 12925.43,
                "ask": 17,
                "bid": 11
            },
            {
                "price": 12790.53,
                "ask": 49,
                "bid": 82
            },
            {
                "price": 12940.13,
                "ask": 37,
                "bid": 40
            },
            {
                "price": 12716.2,
                "ask": 82,
                "bid": 14
            },
            {
                "price": 12912.84,
                "ask": 89,
                "bid": 86
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcc8a4c0c44dc44e4f",
        "high": 12722.99,
        "open": 12950.8,
        "close": 12900.71,
        "low": 12947.56,
        "priceList": [
            {
                "price": 12949.58,
                "ask": 55,
                "bid": 63
            },
            {
                "price": 12700.86,
                "ask": 22,
                "bid": 92
            },
            {
                "price": 12878.03,
                "ask": 12,
                "bid": 77
            },
            {
                "price": 12830.29,
                "ask": 31,
                "bid": 33
            },
            {
                "price": 12888.08,
                "ask": 42,
                "bid": 46
            },
            {
                "price": 12754.31,
                "ask": 26,
                "bid": 98
            },
            {
                "price": 12727.85,
                "ask": 28,
                "bid": 10
            },
            {
                "price": 12744.59,
                "ask": 56,
                "bid": 54
            },
            {
                "price": 12711.54,
                "ask": 89,
                "bid": 48
            },
            {
                "price": 12963.02,
                "ask": 52,
                "bid": 60
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcc9020a6cd61b9ef8",
        "high": 12944.1,
        "open": 12861.8,
        "close": 12720.29,
        "low": 12879.92,
        "priceList": [
            {
                "price": 12754.77,
                "ask": 0,
                "bid": 99
            },
            {
                "price": 12828.17,
                "ask": 94,
                "bid": 8
            },
            {
                "price": 12864.5,
                "ask": 18,
                "bid": 51
            },
            {
                "price": 12992.09,
                "ask": 33,
                "bid": 46
            },
            {
                "price": 12974.41,
                "ask": 55,
                "bid": 91
            },
            {
                "price": 12927,
                "ask": 68,
                "bid": 86
            },
            {
                "price": 12790.7,
                "ask": 22,
                "bid": 42
            },
            {
                "price": 12770.61,
                "ask": 35,
                "bid": 13
            },
            {
                "price": 12844.02,
                "ask": 61,
                "bid": 9
            },
            {
                "price": 12878.41,
                "ask": 76,
                "bid": 35
            },
            {
                "price": 12833.77,
                "ask": 89,
                "bid": 70
            },
            {
                "price": 12963.17,
                "ask": 76,
                "bid": 21
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc967b121963abdc12",
        "high": 12938.21,
        "open": 12835.05,
        "close": 12874.03,
        "low": 12841.05,
        "priceList": [
            {
                "price": 12931.32,
                "ask": 81,
                "bid": 85
            },
            {
                "price": 12847.8,
                "ask": 4,
                "bid": 8
            },
            {
                "price": 12993,
                "ask": 78,
                "bid": 36
            },
            {
                "price": 12891.83,
                "ask": 76,
                "bid": 10
            },
            {
                "price": 12744.22,
                "ask": 0,
                "bid": 77
            },
            {
                "price": 12881.87,
                "ask": 11,
                "bid": 46
            },
            {
                "price": 12791.37,
                "ask": 55,
                "bid": 15
            },
            {
                "price": 12926.26,
                "ask": 78,
                "bid": 83
            },
            {
                "price": 12891.17,
                "ask": 79,
                "bid": 81
            },
            {
                "price": 12936.86,
                "ask": 90,
                "bid": 14
            },
            {
                "price": 12766.1,
                "ask": 37,
                "bid": 93
            },
            {
                "price": 12836.77,
                "ask": 94,
                "bid": 17
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc92264a2b4d5a8c48",
        "high": 12783.39,
        "open": 12969.37,
        "close": 12998.92,
        "low": 12834.12,
        "priceList": [
            {
                "price": 12739.6,
                "ask": 5,
                "bid": 63
            },
            {
                "price": 12789.66,
                "ask": 26,
                "bid": 15
            },
            {
                "price": 12907.07,
                "ask": 97,
                "bid": 50
            },
            {
                "price": 12977.23,
                "ask": 39,
                "bid": 28
            },
            {
                "price": 12934.28,
                "ask": 81,
                "bid": 75
            },
            {
                "price": 12789.04,
                "ask": 67,
                "bid": 31
            },
            {
                "price": 12923.6,
                "ask": 59,
                "bid": 78
            },
            {
                "price": 12934.93,
                "ask": 16,
                "bid": 33
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc0d7c36ea811b4556",
        "high": 12993.19,
        "open": 12874.5,
        "close": 12867.01,
        "low": 12922.66,
        "priceList": [
            {
                "price": 12944.31,
                "ask": 16,
                "bid": 12
            },
            {
                "price": 12973.28,
                "ask": 49,
                "bid": 89
            },
            {
                "price": 12762.78,
                "ask": 92,
                "bid": 41
            },
            {
                "price": 12997.44,
                "ask": 34,
                "bid": 57
            },
            {
                "price": 12946.27,
                "ask": 75,
                "bid": 24
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc01aa7bbeb6c9813b",
        "high": 12857.78,
        "open": 12831.84,
        "close": 12934.57,
        "low": 12756.3,
        "priceList": [
            {
                "price": 12707.69,
                "ask": 64,
                "bid": 5
            },
            {
                "price": 12936.41,
                "ask": 44,
                "bid": 46
            },
            {
                "price": 12802.1,
                "ask": 0,
                "bid": 97
            },
            {
                "price": 12738.37,
                "ask": 98,
                "bid": 90
            },
            {
                "price": 12991.72,
                "ask": 97,
                "bid": 33
            },
            {
                "price": 12819.93,
                "ask": 75,
                "bid": 65
            },
            {
                "price": 12911.64,
                "ask": 44,
                "bid": 21
            },
            {
                "price": 12807.56,
                "ask": 61,
                "bid": 85
            },
            {
                "price": 12776.26,
                "ask": 87,
                "bid": 51
            },
            {
                "price": 12983.2,
                "ask": 33,
                "bid": 48
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc011e5a4a3b2a0a1b",
        "high": 12823.22,
        "open": 12921.4,
        "close": 12880.15,
        "low": 12878.06,
        "priceList": [
            {
                "price": 12775.34,
                "ask": 89,
                "bid": 75
            },
            {
                "price": 12969.38,
                "ask": 27,
                "bid": 71
            },
            {
                "price": 12948.31,
                "ask": 80,
                "bid": 4
            },
            {
                "price": 12763.41,
                "ask": 41,
                "bid": 54
            },
            {
                "price": 12762.33,
                "ask": 19,
                "bid": 62
            },
            {
                "price": 12769.47,
                "ask": 49,
                "bid": 50
            },
            {
                "price": 12798.27,
                "ask": 69,
                "bid": 100
            },
            {
                "price": 12923.89,
                "ask": 40,
                "bid": 15
            },
            {
                "price": 12835.88,
                "ask": 95,
                "bid": 77
            },
            {
                "price": 12825.74,
                "ask": 51,
                "bid": 100
            },
            {
                "price": 12981.43,
                "ask": 19,
                "bid": 94
            },
            {
                "price": 12824.03,
                "ask": 74,
                "bid": 73
            },
            {
                "price": 12863.63,
                "ask": 98,
                "bid": 40
            },
            {
                "price": 12798.49,
                "ask": 22,
                "bid": 33
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcbde8ae74a87b9d21",
        "high": 12845.77,
        "open": 12831.51,
        "close": 12789.76,
        "low": 12837.04,
        "priceList": [
            {
                "price": 12887.1,
                "ask": 70,
                "bid": 46
            },
            {
                "price": 12710.91,
                "ask": 62,
                "bid": 68
            },
            {
                "price": 12919.18,
                "ask": 54,
                "bid": 68
            },
            {
                "price": 12854.13,
                "ask": 57,
                "bid": 33
            },
            {
                "price": 12872.07,
                "ask": 44,
                "bid": 7
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc995401b0418f8b6c",
        "high": 12954.85,
        "open": 12897.11,
        "close": 12827.06,
        "low": 12815.16,
        "priceList": [
            {
                "price": 12819.58,
                "ask": 0,
                "bid": 22
            },
            {
                "price": 12755.96,
                "ask": 39,
                "bid": 75
            },
            {
                "price": 12959.19,
                "ask": 43,
                "bid": 66
            },
            {
                "price": 12854.83,
                "ask": 53,
                "bid": 61
            },
            {
                "price": 12913.03,
                "ask": 47,
                "bid": 42
            },
            {
                "price": 12938.69,
                "ask": 3,
                "bid": 30
            },
            {
                "price": 12844.29,
                "ask": 92,
                "bid": 53
            },
            {
                "price": 12891.05,
                "ask": 31,
                "bid": 39
            },
            {
                "price": 12771.43,
                "ask": 84,
                "bid": 51
            },
            {
                "price": 12961.78,
                "ask": 22,
                "bid": 40
            },
            {
                "price": 12778.2,
                "ask": 95,
                "bid": 48
            },
            {
                "price": 12986.53,
                "ask": 13,
                "bid": 24
            },
            {
                "price": 12814.91,
                "ask": 42,
                "bid": 94
            },
            {
                "price": 12740.32,
                "ask": 34,
                "bid": 72
            },
            {
                "price": 12998.22,
                "ask": 76,
                "bid": 40
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcbb68e32351acdb9f",
        "high": 12726.18,
        "open": 12910.72,
        "close": 12858.42,
        "low": 12720.53,
        "priceList": [
            {
                "price": 12980.49,
                "ask": 76,
                "bid": 14
            },
            {
                "price": 12837.4,
                "ask": 28,
                "bid": 17
            },
            {
                "price": 12957.49,
                "ask": 83,
                "bid": 6
            },
            {
                "price": 12893.75,
                "ask": 4,
                "bid": 69
            },
            {
                "price": 12743.39,
                "ask": 94,
                "bid": 34
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc8126504ef7c05000",
        "high": 12845.04,
        "open": 12796.47,
        "close": 12748.14,
        "low": 12955.67,
        "priceList": [
            {
                "price": 12737.37,
                "ask": 82,
                "bid": 11
            },
            {
                "price": 12916.68,
                "ask": 54,
                "bid": 65
            },
            {
                "price": 12904.18,
                "ask": 82,
                "bid": 0
            },
            {
                "price": 12757.99,
                "ask": 77,
                "bid": 46
            },
            {
                "price": 12958.13,
                "ask": 86,
                "bid": 18
            },
            {
                "price": 12874.94,
                "ask": 63,
                "bid": 60
            },
            {
                "price": 12975.57,
                "ask": 44,
                "bid": 37
            },
            {
                "price": 12782.78,
                "ask": 38,
                "bid": 60
            },
            {
                "price": 12892.55,
                "ask": 53,
                "bid": 6
            },
            {
                "price": 12882.75,
                "ask": 74,
                "bid": 58
            },
            {
                "price": 12812.98,
                "ask": 78,
                "bid": 38
            },
            {
                "price": 12845.53,
                "ask": 47,
                "bid": 52
            },
            {
                "price": 12881.62,
                "ask": 60,
                "bid": 50
            },
            {
                "price": 12747.09,
                "ask": 68,
                "bid": 19
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc1a20449b9f66ec20",
        "high": 12987.42,
        "open": 12963.17,
        "close": 12803.09,
        "low": 12819.86,
        "priceList": [
            {
                "price": 12803.43,
                "ask": 59,
                "bid": 39
            },
            {
                "price": 12779.92,
                "ask": 76,
                "bid": 7
            },
            {
                "price": 12802.98,
                "ask": 47,
                "bid": 1
            },
            {
                "price": 12908.64,
                "ask": 65,
                "bid": 12
            },
            {
                "price": 12923.4,
                "ask": 45,
                "bid": 17
            },
            {
                "price": 12723.79,
                "ask": 49,
                "bid": 74
            },
            {
                "price": 12847.91,
                "ask": 74,
                "bid": 83
            },
            {
                "price": 12781.13,
                "ask": 36,
                "bid": 29
            },
            {
                "price": 12765.67,
                "ask": 99,
                "bid": 34
            },
            {
                "price": 12788.99,
                "ask": 41,
                "bid": 80
            },
            {
                "price": 12770.91,
                "ask": 5,
                "bid": 66
            },
            {
                "price": 12727.34,
                "ask": 29,
                "bid": 7
            },
            {
                "price": 12770.16,
                "ask": 55,
                "bid": 92
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcffc97c2b655055c1",
        "high": 12732.36,
        "open": 12972.07,
        "close": 12906.88,
        "low": 12856.61,
        "priceList": [
            {
                "price": 12827.03,
                "ask": 31,
                "bid": 63
            },
            {
                "price": 12765.71,
                "ask": 10,
                "bid": 81
            },
            {
                "price": 12827.92,
                "ask": 71,
                "bid": 12
            },
            {
                "price": 12771.27,
                "ask": 90,
                "bid": 70
            },
            {
                "price": 12725.95,
                "ask": 95,
                "bid": 46
            },
            {
                "price": 12780.79,
                "ask": 20,
                "bid": 93
            },
            {
                "price": 12935.17,
                "ask": 53,
                "bid": 17
            },
            {
                "price": 12794.88,
                "ask": 63,
                "bid": 28
            },
            {
                "price": 12748,
                "ask": 37,
                "bid": 6
            },
            {
                "price": 12935.66,
                "ask": 39,
                "bid": 8
            },
            {
                "price": 12762.37,
                "ask": 50,
                "bid": 65
            },
            {
                "price": 12886.84,
                "ask": 53,
                "bid": 85
            },
            {
                "price": 12952.25,
                "ask": 37,
                "bid": 94
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcb23a27bf61bb1fd9",
        "high": 12818.32,
        "open": 12856.81,
        "close": 12832.32,
        "low": 12937.35,
        "priceList": [
            {
                "price": 12993.19,
                "ask": 51,
                "bid": 94
            },
            {
                "price": 12782.21,
                "ask": 68,
                "bid": 43
            },
            {
                "price": 12912.84,
                "ask": 87,
                "bid": 23
            },
            {
                "price": 12888.26,
                "ask": 68,
                "bid": 5
            },
            {
                "price": 12730.16,
                "ask": 98,
                "bid": 25
            },
            {
                "price": 12929.52,
                "ask": 5,
                "bid": 47
            },
            {
                "price": 12723.74,
                "ask": 89,
                "bid": 45
            },
            {
                "price": 12858.27,
                "ask": 26,
                "bid": 74
            },
            {
                "price": 12816.93,
                "ask": 19,
                "bid": 1
            },
            {
                "price": 12833.88,
                "ask": 32,
                "bid": 73
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc95914b696fa0382a",
        "high": 12881.57,
        "open": 12950.52,
        "close": 12810.87,
        "low": 12759.05,
        "priceList": [
            {
                "price": 12897.78,
                "ask": 25,
                "bid": 99
            },
            {
                "price": 12781.24,
                "ask": 48,
                "bid": 99
            },
            {
                "price": 12812.3,
                "ask": 27,
                "bid": 85
            },
            {
                "price": 12722.74,
                "ask": 97,
                "bid": 42
            },
            {
                "price": 12745.59,
                "ask": 11,
                "bid": 79
            },
            {
                "price": 12857.68,
                "ask": 81,
                "bid": 49
            },
            {
                "price": 12969.63,
                "ask": 29,
                "bid": 17
            },
            {
                "price": 12867.37,
                "ask": 72,
                "bid": 8
            },
            {
                "price": 12824.92,
                "ask": 89,
                "bid": 34
            },
            {
                "price": 12853.56,
                "ask": 23,
                "bid": 20
            },
            {
                "price": 12869.24,
                "ask": 23,
                "bid": 96
            },
            {
                "price": 12816.56,
                "ask": 97,
                "bid": 70
            },
            {
                "price": 12887.55,
                "ask": 67,
                "bid": 38
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf5eead565587ee4b",
        "high": 12901.44,
        "open": 12713.86,
        "close": 12886.32,
        "low": 12997.58,
        "priceList": [
            {
                "price": 12773.89,
                "ask": 26,
                "bid": 75
            },
            {
                "price": 12703.38,
                "ask": 54,
                "bid": 14
            },
            {
                "price": 12814.88,
                "ask": 83,
                "bid": 4
            },
            {
                "price": 12951.04,
                "ask": 36,
                "bid": 48
            },
            {
                "price": 12708.35,
                "ask": 87,
                "bid": 35
            },
            {
                "price": 12907.92,
                "ask": 0,
                "bid": 56
            },
            {
                "price": 12962.94,
                "ask": 13,
                "bid": 51
            },
            {
                "price": 12705.37,
                "ask": 53,
                "bid": 85
            },
            {
                "price": 12839.58,
                "ask": 90,
                "bid": 77
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcfa2e77566cb4e3e4",
        "high": 12807.69,
        "open": 12738.69,
        "close": 12759.18,
        "low": 12864.82,
        "priceList": [
            {
                "price": 12998.33,
                "ask": 85,
                "bid": 41
            },
            {
                "price": 12874.72,
                "ask": 100,
                "bid": 93
            },
            {
                "price": 12793.98,
                "ask": 50,
                "bid": 75
            },
            {
                "price": 12782.52,
                "ask": 83,
                "bid": 37
            },
            {
                "price": 12718.42,
                "ask": 35,
                "bid": 12
            },
            {
                "price": 12742.42,
                "ask": 1,
                "bid": 85
            },
            {
                "price": 12986.89,
                "ask": 35,
                "bid": 56
            },
            {
                "price": 12935.47,
                "ask": 81,
                "bid": 25
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc49d9a951849a8405",
        "high": 12935.34,
        "open": 12980.73,
        "close": 12944.16,
        "low": 12760.41,
        "priceList": [
            {
                "price": 12958.69,
                "ask": 40,
                "bid": 42
            },
            {
                "price": 12781.73,
                "ask": 55,
                "bid": 96
            },
            {
                "price": 12921.84,
                "ask": 52,
                "bid": 8
            },
            {
                "price": 12777.27,
                "ask": 32,
                "bid": 7
            },
            {
                "price": 12905.86,
                "ask": 9,
                "bid": 29
            },
            {
                "price": 12872.69,
                "ask": 35,
                "bid": 39
            },
            {
                "price": 12858.37,
                "ask": 81,
                "bid": 73
            },
            {
                "price": 12983.89,
                "ask": 79,
                "bid": 71
            },
            {
                "price": 12778.62,
                "ask": 41,
                "bid": 73
            },
            {
                "price": 12975.68,
                "ask": 97,
                "bid": 49
            },
            {
                "price": 12843.7,
                "ask": 85,
                "bid": 13
            },
            {
                "price": 12844.24,
                "ask": 18,
                "bid": 34
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc3b2e0712703fb98d",
        "high": 12928.08,
        "open": 12923.17,
        "close": 12751.54,
        "low": 12747.04,
        "priceList": [
            {
                "price": 12955.03,
                "ask": 45,
                "bid": 52
            },
            {
                "price": 12870.34,
                "ask": 95,
                "bid": 77
            },
            {
                "price": 12771.32,
                "ask": 61,
                "bid": 86
            },
            {
                "price": 12710.76,
                "ask": 77,
                "bid": 93
            },
            {
                "price": 12892.48,
                "ask": 80,
                "bid": 45
            },
            {
                "price": 12835.29,
                "ask": 46,
                "bid": 41
            },
            {
                "price": 12733.57,
                "ask": 26,
                "bid": 46
            },
            {
                "price": 12927.03,
                "ask": 90,
                "bid": 87
            },
            {
                "price": 12771.37,
                "ask": 13,
                "bid": 20
            },
            {
                "price": 12807.16,
                "ask": 62,
                "bid": 58
            },
            {
                "price": 12794.34,
                "ask": 99,
                "bid": 43
            },
            {
                "price": 12897.63,
                "ask": 49,
                "bid": 18
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc89169bdacc87b0b5",
        "high": 12947.11,
        "open": 12877.94,
        "close": 12879.56,
        "low": 12973.83,
        "priceList": [
            {
                "price": 12813.72,
                "ask": 23,
                "bid": 97
            },
            {
                "price": 12723.35,
                "ask": 15,
                "bid": 37
            },
            {
                "price": 12787.17,
                "ask": 81,
                "bid": 12
            },
            {
                "price": 12704.64,
                "ask": 64,
                "bid": 64
            },
            {
                "price": 12998.01,
                "ask": 71,
                "bid": 20
            },
            {
                "price": 12955.07,
                "ask": 68,
                "bid": 67
            },
            {
                "price": 12706.03,
                "ask": 72,
                "bid": 22
            },
            {
                "price": 12995.09,
                "ask": 4,
                "bid": 53
            },
            {
                "price": 12819.48,
                "ask": 26,
                "bid": 3
            },
            {
                "price": 12846.47,
                "ask": 53,
                "bid": 51
            },
            {
                "price": 12796.66,
                "ask": 77,
                "bid": 63
            },
            {
                "price": 12751.42,
                "ask": 36,
                "bid": 65
            },
            {
                "price": 12805.65,
                "ask": 23,
                "bid": 38
            },
            {
                "price": 12820.02,
                "ask": 62,
                "bid": 44
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc787101325078ce74",
        "high": 12774.51,
        "open": 12889.89,
        "close": 12840.61,
        "low": 12996.38,
        "priceList": [
            {
                "price": 12947.36,
                "ask": 62,
                "bid": 86
            },
            {
                "price": 12770.91,
                "ask": 77,
                "bid": 98
            },
            {
                "price": 12835.9,
                "ask": 42,
                "bid": 38
            },
            {
                "price": 12701.99,
                "ask": 10,
                "bid": 82
            },
            {
                "price": 12740.78,
                "ask": 54,
                "bid": 67
            },
            {
                "price": 12875.17,
                "ask": 51,
                "bid": 37
            },
            {
                "price": 12832.41,
                "ask": 49,
                "bid": 23
            },
            {
                "price": 12732.18,
                "ask": 71,
                "bid": 98
            },
            {
                "price": 12797.61,
                "ask": 47,
                "bid": 67
            },
            {
                "price": 12700.3,
                "ask": 37,
                "bid": 6
            },
            {
                "price": 12975.09,
                "ask": 66,
                "bid": 30
            },
            {
                "price": 12998.79,
                "ask": 97,
                "bid": 83
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcef50032a8f5c5dd3",
        "high": 12704.96,
        "open": 12748.41,
        "close": 12733.16,
        "low": 12708.47,
        "priceList": [
            {
                "price": 12913.1,
                "ask": 87,
                "bid": 42
            },
            {
                "price": 12798.99,
                "ask": 40,
                "bid": 77
            },
            {
                "price": 12725.5,
                "ask": 75,
                "bid": 68
            },
            {
                "price": 12889.41,
                "ask": 89,
                "bid": 50
            },
            {
                "price": 12740.21,
                "ask": 43,
                "bid": 43
            },
            {
                "price": 12803.6,
                "ask": 78,
                "bid": 1
            },
            {
                "price": 12769.93,
                "ask": 100,
                "bid": 7
            },
            {
                "price": 12957.49,
                "ask": 48,
                "bid": 91
            },
            {
                "price": 12851.97,
                "ask": 20,
                "bid": 93
            },
            {
                "price": 12740.17,
                "ask": 26,
                "bid": 83
            },
            {
                "price": 12849.82,
                "ask": 15,
                "bid": 85
            },
            {
                "price": 12790.55,
                "ask": 94,
                "bid": 21
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dca3a2abd9fc0077c3",
        "high": 12734.94,
        "open": 12862.53,
        "close": 12934.19,
        "low": 12884.24,
        "priceList": [
            {
                "price": 12796.92,
                "ask": 37,
                "bid": 97
            },
            {
                "price": 12913.85,
                "ask": 96,
                "bid": 48
            },
            {
                "price": 12994.72,
                "ask": 30,
                "bid": 8
            },
            {
                "price": 12745.52,
                "ask": 3,
                "bid": 33
            },
            {
                "price": 12953.2,
                "ask": 58,
                "bid": 59
            },
            {
                "price": 12711.42,
                "ask": 63,
                "bid": 93
            },
            {
                "price": 12817.14,
                "ask": 63,
                "bid": 93
            },
            {
                "price": 12964.63,
                "ask": 78,
                "bid": 59
            },
            {
                "price": 12735.94,
                "ask": 45,
                "bid": 10
            },
            {
                "price": 12985.17,
                "ask": 82,
                "bid": 2
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc47e336da8aa8fec8",
        "high": 12822.8,
        "open": 12735.18,
        "close": 12819.53,
        "low": 12708.25,
        "priceList": [
            {
                "price": 12958.16,
                "ask": 67,
                "bid": 41
            },
            {
                "price": 12849.79,
                "ask": 15,
                "bid": 88
            },
            {
                "price": 12765,
                "ask": 10,
                "bid": 76
            },
            {
                "price": 12951.33,
                "ask": 6,
                "bid": 99
            },
            {
                "price": 12784.98,
                "ask": 21,
                "bid": 90
            },
            {
                "price": 12952.74,
                "ask": 55,
                "bid": 64
            },
            {
                "price": 12753.88,
                "ask": 6,
                "bid": 48
            },
            {
                "price": 12921.79,
                "ask": 75,
                "bid": 43
            },
            {
                "price": 12916.94,
                "ask": 1,
                "bid": 21
            },
            {
                "price": 12860.6,
                "ask": 15,
                "bid": 98
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc515fc1a0401db950",
        "high": 12996.51,
        "open": 12703.6,
        "close": 12978.45,
        "low": 12898.99,
        "priceList": [
            {
                "price": 12974.46,
                "ask": 10,
                "bid": 77
            },
            {
                "price": 12894.27,
                "ask": 75,
                "bid": 52
            },
            {
                "price": 12861.32,
                "ask": 19,
                "bid": 87
            },
            {
                "price": 12756.95,
                "ask": 13,
                "bid": 33
            },
            {
                "price": 12818.58,
                "ask": 93,
                "bid": 97
            },
            {
                "price": 12907.51,
                "ask": 52,
                "bid": 47
            },
            {
                "price": 12862.45,
                "ask": 97,
                "bid": 79
            },
            {
                "price": 12921.58,
                "ask": 17,
                "bid": 70
            },
            {
                "price": 12848.06,
                "ask": 33,
                "bid": 47
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcfef719888359dea1",
        "high": 12971.38,
        "open": 12857.8,
        "close": 12732.35,
        "low": 12821.28,
        "priceList": [
            {
                "price": 12756.44,
                "ask": 23,
                "bid": 14
            },
            {
                "price": 12734.15,
                "ask": 36,
                "bid": 36
            },
            {
                "price": 12910.52,
                "ask": 59,
                "bid": 68
            },
            {
                "price": 12995.83,
                "ask": 40,
                "bid": 58
            },
            {
                "price": 12780.59,
                "ask": 59,
                "bid": 4
            },
            {
                "price": 12982.8,
                "ask": 93,
                "bid": 38
            },
            {
                "price": 12732.41,
                "ask": 15,
                "bid": 61
            },
            {
                "price": 12773.31,
                "ask": 64,
                "bid": 28
            },
            {
                "price": 12835.28,
                "ask": 56,
                "bid": 69
            },
            {
                "price": 12918.33,
                "ask": 41,
                "bid": 42
            },
            {
                "price": 12928.54,
                "ask": 5,
                "bid": 74
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc575e5a5fdb21815b",
        "high": 12991.53,
        "open": 12714.3,
        "close": 12854.03,
        "low": 12780.33,
        "priceList": [
            {
                "price": 12742.11,
                "ask": 6,
                "bid": 11
            },
            {
                "price": 12961.62,
                "ask": 9,
                "bid": 15
            },
            {
                "price": 12936.59,
                "ask": 82,
                "bid": 12
            },
            {
                "price": 12991.26,
                "ask": 70,
                "bid": 44
            },
            {
                "price": 12818.5,
                "ask": 47,
                "bid": 93
            },
            {
                "price": 12900.8,
                "ask": 68,
                "bid": 33
            },
            {
                "price": 12786.05,
                "ask": 56,
                "bid": 98
            },
            {
                "price": 12918.82,
                "ask": 94,
                "bid": 8
            },
            {
                "price": 12959.05,
                "ask": 42,
                "bid": 27
            },
            {
                "price": 12788.28,
                "ask": 79,
                "bid": 54
            },
            {
                "price": 12742.71,
                "ask": 39,
                "bid": 22
            },
            {
                "price": 12735.33,
                "ask": 43,
                "bid": 86
            },
            {
                "price": 12824.01,
                "ask": 15,
                "bid": 30
            },
            {
                "price": 12974.55,
                "ask": 57,
                "bid": 26
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc8ad0b7f88164634c",
        "high": 12888.69,
        "open": 12739.19,
        "close": 12760.2,
        "low": 12702.27,
        "priceList": [
            {
                "price": 12998.37,
                "ask": 6,
                "bid": 41
            },
            {
                "price": 12964.67,
                "ask": 79,
                "bid": 38
            },
            {
                "price": 12986.02,
                "ask": 6,
                "bid": 17
            },
            {
                "price": 12909.33,
                "ask": 81,
                "bid": 40
            },
            {
                "price": 12727.07,
                "ask": 2,
                "bid": 32
            },
            {
                "price": 12810.06,
                "ask": 96,
                "bid": 78
            },
            {
                "price": 12774.11,
                "ask": 28,
                "bid": 22
            },
            {
                "price": 12823.92,
                "ask": 24,
                "bid": 71
            },
            {
                "price": 12947.77,
                "ask": 58,
                "bid": 41
            },
            {
                "price": 12841.51,
                "ask": 11,
                "bid": 85
            },
            {
                "price": 12744.87,
                "ask": 95,
                "bid": 77
            },
            {
                "price": 12895.91,
                "ask": 6,
                "bid": 36
            },
            {
                "price": 12834.99,
                "ask": 8,
                "bid": 59
            },
            {
                "price": 12731.39,
                "ask": 17,
                "bid": 29
            },
            {
                "price": 12712.65,
                "ask": 6,
                "bid": 20
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcaad6bcede7d5d55e",
        "high": 12741.36,
        "open": 12997.47,
        "close": 12881.8,
        "low": 12975.43,
        "priceList": [
            {
                "price": 12942.72,
                "ask": 53,
                "bid": 54
            },
            {
                "price": 12999.4,
                "ask": 82,
                "bid": 29
            },
            {
                "price": 12786.43,
                "ask": 91,
                "bid": 78
            },
            {
                "price": 12851.71,
                "ask": 100,
                "bid": 91
            },
            {
                "price": 12864.37,
                "ask": 17,
                "bid": 75
            },
            {
                "price": 12794.09,
                "ask": 56,
                "bid": 50
            },
            {
                "price": 12737.96,
                "ask": 97,
                "bid": 36
            },
            {
                "price": 12831.28,
                "ask": 58,
                "bid": 57
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc3d961ec1590f9f95",
        "high": 12858.8,
        "open": 12950.09,
        "close": 12967.23,
        "low": 12801.41,
        "priceList": [
            {
                "price": 12725.2,
                "ask": 41,
                "bid": 7
            },
            {
                "price": 12726.93,
                "ask": 26,
                "bid": 30
            },
            {
                "price": 12935.43,
                "ask": 93,
                "bid": 93
            },
            {
                "price": 12843.21,
                "ask": 99,
                "bid": 94
            },
            {
                "price": 12748.65,
                "ask": 57,
                "bid": 13
            },
            {
                "price": 12879.11,
                "ask": 26,
                "bid": 15
            },
            {
                "price": 12777.91,
                "ask": 61,
                "bid": 19
            },
            {
                "price": 12734.29,
                "ask": 41,
                "bid": 84
            },
            {
                "price": 12882.85,
                "ask": 41,
                "bid": 70
            },
            {
                "price": 12765.28,
                "ask": 73,
                "bid": 33
            },
            {
                "price": 12728.97,
                "ask": 96,
                "bid": 67
            },
            {
                "price": 12903.56,
                "ask": 1,
                "bid": 99
            },
            {
                "price": 12781.84,
                "ask": 28,
                "bid": 40
            },
            {
                "price": 12844.82,
                "ask": 74,
                "bid": 74
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcdba05ca7c91f2c24",
        "high": 12972.29,
        "open": 12762.86,
        "close": 12984.23,
        "low": 12744.37,
        "priceList": [
            {
                "price": 12988.61,
                "ask": 30,
                "bid": 31
            },
            {
                "price": 12858.03,
                "ask": 44,
                "bid": 98
            },
            {
                "price": 12810.26,
                "ask": 84,
                "bid": 86
            },
            {
                "price": 12883.27,
                "ask": 92,
                "bid": 40
            },
            {
                "price": 12812.69,
                "ask": 74,
                "bid": 12
            },
            {
                "price": 12784.83,
                "ask": 99,
                "bid": 59
            },
            {
                "price": 12851.68,
                "ask": 94,
                "bid": 49
            },
            {
                "price": 12711.6,
                "ask": 54,
                "bid": 3
            },
            {
                "price": 12797.92,
                "ask": 59,
                "bid": 77
            },
            {
                "price": 12735.6,
                "ask": 77,
                "bid": 89
            },
            {
                "price": 12768.21,
                "ask": 86,
                "bid": 38
            },
            {
                "price": 12776.3,
                "ask": 1,
                "bid": 91
            },
            {
                "price": 12926.69,
                "ask": 99,
                "bid": 18
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc8bcd09ed640c0eae",
        "high": 12941.19,
        "open": 12959.63,
        "close": 12842.51,
        "low": 12704.83,
        "priceList": [
            {
                "price": 12748.73,
                "ask": 4,
                "bid": 82
            },
            {
                "price": 12889.35,
                "ask": 68,
                "bid": 10
            },
            {
                "price": 12719.03,
                "ask": 50,
                "bid": 17
            },
            {
                "price": 12708.92,
                "ask": 3,
                "bid": 23
            },
            {
                "price": 12832.15,
                "ask": 28,
                "bid": 91
            },
            {
                "price": 12851.51,
                "ask": 49,
                "bid": 26
            },
            {
                "price": 12957.53,
                "ask": 39,
                "bid": 9
            },
            {
                "price": 12993.83,
                "ask": 72,
                "bid": 22
            },
            {
                "price": 12711.91,
                "ask": 6,
                "bid": 28
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcaafcb390d38e380f",
        "high": 12917.38,
        "open": 12821.42,
        "close": 12891.41,
        "low": 12978.39,
        "priceList": [
            {
                "price": 12744.1,
                "ask": 68,
                "bid": 23
            },
            {
                "price": 12881.85,
                "ask": 38,
                "bid": 77
            },
            {
                "price": 12988.04,
                "ask": 3,
                "bid": 43
            },
            {
                "price": 12939.08,
                "ask": 58,
                "bid": 49
            },
            {
                "price": 12747.12,
                "ask": 66,
                "bid": 59
            },
            {
                "price": 12920.43,
                "ask": 12,
                "bid": 32
            },
            {
                "price": 12990.92,
                "ask": 81,
                "bid": 84
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcc3ebf1775c380165",
        "high": 12862.9,
        "open": 12903.65,
        "close": 12783.82,
        "low": 12921.19,
        "priceList": [
            {
                "price": 12876.2,
                "ask": 10,
                "bid": 88
            },
            {
                "price": 12887.73,
                "ask": 29,
                "bid": 75
            },
            {
                "price": 12964.08,
                "ask": 1,
                "bid": 50
            },
            {
                "price": 12939.43,
                "ask": 78,
                "bid": 47
            },
            {
                "price": 12717.5,
                "ask": 46,
                "bid": 92
            },
            {
                "price": 12803.07,
                "ask": 15,
                "bid": 75
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc0dcd51dff83e6d10",
        "high": 12923.26,
        "open": 12877.07,
        "close": 12977.44,
        "low": 12748.55,
        "priceList": [
            {
                "price": 12890.77,
                "ask": 13,
                "bid": 50
            },
            {
                "price": 12896.57,
                "ask": 63,
                "bid": 87
            },
            {
                "price": 12773.82,
                "ask": 82,
                "bid": 13
            },
            {
                "price": 12790.33,
                "ask": 21,
                "bid": 19
            },
            {
                "price": 12781.34,
                "ask": 51,
                "bid": 2
            },
            {
                "price": 12812.05,
                "ask": 91,
                "bid": 48
            },
            {
                "price": 12924.43,
                "ask": 87,
                "bid": 64
            },
            {
                "price": 12859.83,
                "ask": 41,
                "bid": 55
            },
            {
                "price": 12911.12,
                "ask": 60,
                "bid": 99
            },
            {
                "price": 12930.17,
                "ask": 21,
                "bid": 80
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcfaef2f5ef642aed3",
        "high": 12728.48,
        "open": 12800.98,
        "close": 12957.93,
        "low": 12707.82,
        "priceList": [
            {
                "price": 12905.55,
                "ask": 69,
                "bid": 90
            },
            {
                "price": 12705.63,
                "ask": 15,
                "bid": 90
            },
            {
                "price": 12883.32,
                "ask": 12,
                "bid": 0
            },
            {
                "price": 12742.88,
                "ask": 28,
                "bid": 27
            },
            {
                "price": 12979.65,
                "ask": 83,
                "bid": 89
            },
            {
                "price": 12817.9,
                "ask": 43,
                "bid": 81
            },
            {
                "price": 12930.65,
                "ask": 54,
                "bid": 61
            },
            {
                "price": 12952.7,
                "ask": 11,
                "bid": 80
            },
            {
                "price": 12999.37,
                "ask": 53,
                "bid": 66
            },
            {
                "price": 12969.47,
                "ask": 40,
                "bid": 63
            },
            {
                "price": 12841.71,
                "ask": 23,
                "bid": 100
            },
            {
                "price": 12748.91,
                "ask": 23,
                "bid": 11
            },
            {
                "price": 12884.67,
                "ask": 98,
                "bid": 5
            },
            {
                "price": 12987.92,
                "ask": 7,
                "bid": 33
            },
            {
                "price": 12987.53,
                "ask": 99,
                "bid": 76
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc10c5ae16bb6428ef",
        "high": 12965.4,
        "open": 12962.37,
        "close": 12829.07,
        "low": 12978.25,
        "priceList": [
            {
                "price": 12921.68,
                "ask": 58,
                "bid": 23
            },
            {
                "price": 12961.82,
                "ask": 3,
                "bid": 27
            },
            {
                "price": 12891.83,
                "ask": 83,
                "bid": 1
            },
            {
                "price": 12947.01,
                "ask": 54,
                "bid": 6
            },
            {
                "price": 12937.18,
                "ask": 0,
                "bid": 29
            },
            {
                "price": 12942.16,
                "ask": 60,
                "bid": 95
            },
            {
                "price": 12736.44,
                "ask": 28,
                "bid": 10
            },
            {
                "price": 12815.19,
                "ask": 100,
                "bid": 25
            },
            {
                "price": 12918.04,
                "ask": 9,
                "bid": 14
            },
            {
                "price": 12871.15,
                "ask": 41,
                "bid": 26
            },
            {
                "price": 12959.59,
                "ask": 22,
                "bid": 21
            },
            {
                "price": 12781.95,
                "ask": 56,
                "bid": 70
            },
            {
                "price": 12847.68,
                "ask": 55,
                "bid": 7
            },
            {
                "price": 12705.26,
                "ask": 85,
                "bid": 72
            },
            {
                "price": 12717.42,
                "ask": 62,
                "bid": 7
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc19cf5d533c5deb87",
        "high": 12893.67,
        "open": 12977.09,
        "close": 12806.11,
        "low": 12826.99,
        "priceList": [
            {
                "price": 12926.24,
                "ask": 46,
                "bid": 59
            },
            {
                "price": 12726.41,
                "ask": 71,
                "bid": 33
            },
            {
                "price": 12988.94,
                "ask": 30,
                "bid": 27
            },
            {
                "price": 12723.73,
                "ask": 81,
                "bid": 0
            },
            {
                "price": 12899.03,
                "ask": 81,
                "bid": 6
            },
            {
                "price": 12829.78,
                "ask": 2,
                "bid": 46
            },
            {
                "price": 12799.25,
                "ask": 2,
                "bid": 83
            },
            {
                "price": 12785.15,
                "ask": 50,
                "bid": 44
            },
            {
                "price": 12964.85,
                "ask": 2,
                "bid": 42
            },
            {
                "price": 12931.96,
                "ask": 2,
                "bid": 63
            },
            {
                "price": 12815.74,
                "ask": 39,
                "bid": 31
            },
            {
                "price": 12889.4,
                "ask": 72,
                "bid": 83
            },
            {
                "price": 12726.93,
                "ask": 38,
                "bid": 86
            },
            {
                "price": 12856.97,
                "ask": 61,
                "bid": 60
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc52ae5f2b83b70b96",
        "high": 12756.47,
        "open": 12745.03,
        "close": 12816.23,
        "low": 12919.86,
        "priceList": [
            {
                "price": 12753.87,
                "ask": 51,
                "bid": 18
            },
            {
                "price": 12939.61,
                "ask": 95,
                "bid": 85
            },
            {
                "price": 12836.79,
                "ask": 15,
                "bid": 45
            },
            {
                "price": 12972.13,
                "ask": 31,
                "bid": 3
            },
            {
                "price": 12907.02,
                "ask": 61,
                "bid": 12
            },
            {
                "price": 12805.91,
                "ask": 15,
                "bid": 8
            },
            {
                "price": 12945.75,
                "ask": 92,
                "bid": 60
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcf628a26a486707ec",
        "high": 12789.02,
        "open": 12926.37,
        "close": 12918.77,
        "low": 12992.84,
        "priceList": [
            {
                "price": 12773.94,
                "ask": 31,
                "bid": 75
            },
            {
                "price": 12882.12,
                "ask": 38,
                "bid": 78
            },
            {
                "price": 12808.9,
                "ask": 69,
                "bid": 53
            },
            {
                "price": 12804.77,
                "ask": 80,
                "bid": 31
            },
            {
                "price": 12872.76,
                "ask": 47,
                "bid": 2
            },
            {
                "price": 12814.95,
                "ask": 45,
                "bid": 17
            },
            {
                "price": 12938.4,
                "ask": 67,
                "bid": 55
            },
            {
                "price": 12949.5,
                "ask": 8,
                "bid": 6
            },
            {
                "price": 12744.25,
                "ask": 58,
                "bid": 71
            },
            {
                "price": 12798.11,
                "ask": 27,
                "bid": 75
            },
            {
                "price": 12948.7,
                "ask": 10,
                "bid": 85
            },
            {
                "price": 12931.9,
                "ask": 44,
                "bid": 97
            },
            {
                "price": 12840.01,
                "ask": 56,
                "bid": 45
            },
            {
                "price": 12883.02,
                "ask": 44,
                "bid": 63
            },
            {
                "price": 12888.41,
                "ask": 93,
                "bid": 47
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc89b11a976526b392",
        "high": 12791.22,
        "open": 12873.9,
        "close": 12849.24,
        "low": 12914.22,
        "priceList": [
            {
                "price": 12760.71,
                "ask": 55,
                "bid": 43
            },
            {
                "price": 12772.44,
                "ask": 15,
                "bid": 58
            },
            {
                "price": 12723.61,
                "ask": 68,
                "bid": 66
            },
            {
                "price": 12724.15,
                "ask": 55,
                "bid": 5
            },
            {
                "price": 12731.73,
                "ask": 42,
                "bid": 86
            },
            {
                "price": 12880.76,
                "ask": 10,
                "bid": 49
            },
            {
                "price": 12828.54,
                "ask": 29,
                "bid": 44
            },
            {
                "price": 12815.62,
                "ask": 14,
                "bid": 98
            },
            {
                "price": 12909.01,
                "ask": 72,
                "bid": 85
            },
            {
                "price": 12956.25,
                "ask": 46,
                "bid": 29
            },
            {
                "price": 12730.59,
                "ask": 41,
                "bid": 2
            },
            {
                "price": 12887.81,
                "ask": 37,
                "bid": 78
            },
            {
                "price": 12888.96,
                "ask": 65,
                "bid": 67
            },
            {
                "price": 12953.63,
                "ask": 30,
                "bid": 99
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcd1f58b28daf71236",
        "high": 12951.47,
        "open": 12776.05,
        "close": 12900.12,
        "low": 12920.28,
        "priceList": [
            {
                "price": 12923.05,
                "ask": 50,
                "bid": 63
            },
            {
                "price": 12804.95,
                "ask": 81,
                "bid": 50
            },
            {
                "price": 12887.64,
                "ask": 14,
                "bid": 83
            },
            {
                "price": 12830.92,
                "ask": 85,
                "bid": 61
            },
            {
                "price": 12833.28,
                "ask": 70,
                "bid": 27
            },
            {
                "price": 12870.5,
                "ask": 13,
                "bid": 91
            },
            {
                "price": 12844.82,
                "ask": 94,
                "bid": 5
            },
            {
                "price": 12809.41,
                "ask": 60,
                "bid": 34
            },
            {
                "price": 12964.81,
                "ask": 79,
                "bid": 5
            },
            {
                "price": 12831.4,
                "ask": 80,
                "bid": 3
            },
            {
                "price": 12810.98,
                "ask": 88,
                "bid": 21
            },
            {
                "price": 12975.47,
                "ask": 68,
                "bid": 38
            },
            {
                "price": 12729.78,
                "ask": 8,
                "bid": 39
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dca69f1477ecaa0015",
        "high": 12984.17,
        "open": 12999.27,
        "close": 12718.13,
        "low": 12729.35,
        "priceList": [
            {
                "price": 12770.13,
                "ask": 24,
                "bid": 78
            },
            {
                "price": 12966.75,
                "ask": 91,
                "bid": 81
            },
            {
                "price": 12762.04,
                "ask": 47,
                "bid": 3
            },
            {
                "price": 12869.08,
                "ask": 90,
                "bid": 58
            },
            {
                "price": 12993.89,
                "ask": 35,
                "bid": 57
            },
            {
                "price": 12764.26,
                "ask": 45,
                "bid": 13
            },
            {
                "price": 12847.11,
                "ask": 34,
                "bid": 91
            },
            {
                "price": 12750.2,
                "ask": 6,
                "bid": 37
            },
            {
                "price": 12902.17,
                "ask": 61,
                "bid": 17
            },
            {
                "price": 12941.39,
                "ask": 80,
                "bid": 61
            },
            {
                "price": 12860.14,
                "ask": 34,
                "bid": 32
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dce0b8a8b42b891aa4",
        "high": 12779.59,
        "open": 12803.41,
        "close": 12959.02,
        "low": 12971.91,
        "priceList": [
            {
                "price": 12919.85,
                "ask": 94,
                "bid": 22
            },
            {
                "price": 12959.43,
                "ask": 9,
                "bid": 21
            },
            {
                "price": 12967.24,
                "ask": 22,
                "bid": 48
            },
            {
                "price": 12961.2,
                "ask": 75,
                "bid": 29
            },
            {
                "price": 12979.31,
                "ask": 85,
                "bid": 30
            },
            {
                "price": 12925.12,
                "ask": 48,
                "bid": 94
            },
            {
                "price": 12719.21,
                "ask": 39,
                "bid": 30
            },
            {
                "price": 12858.25,
                "ask": 7,
                "bid": 37
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcda638fae74580970",
        "high": 12805.29,
        "open": 12850.33,
        "close": 12822.45,
        "low": 12769.53,
        "priceList": [
            {
                "price": 12951.62,
                "ask": 62,
                "bid": 34
            },
            {
                "price": 12926.55,
                "ask": 34,
                "bid": 81
            },
            {
                "price": 12760.44,
                "ask": 96,
                "bid": 22
            },
            {
                "price": 12721.75,
                "ask": 57,
                "bid": 72
            },
            {
                "price": 12946.59,
                "ask": 76,
                "bid": 32
            },
            {
                "price": 12790.05,
                "ask": 21,
                "bid": 12
            },
            {
                "price": 12978.09,
                "ask": 30,
                "bid": 76
            },
            {
                "price": 12743.93,
                "ask": 51,
                "bid": 56
            },
            {
                "price": 12808.02,
                "ask": 3,
                "bid": 18
            },
            {
                "price": 12991.19,
                "ask": 2,
                "bid": 6
            },
            {
                "price": 12993.06,
                "ask": 75,
                "bid": 97
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcd7cadec0366b5801",
        "high": 12913.33,
        "open": 12709.45,
        "close": 12868.21,
        "low": 12982.19,
        "priceList": [
            {
                "price": 12967.9,
                "ask": 87,
                "bid": 29
            },
            {
                "price": 12862.72,
                "ask": 20,
                "bid": 18
            },
            {
                "price": 12993.03,
                "ask": 1,
                "bid": 60
            },
            {
                "price": 12765.76,
                "ask": 27,
                "bid": 15
            },
            {
                "price": 12764.31,
                "ask": 14,
                "bid": 15
            },
            {
                "price": 12958.81,
                "ask": 90,
                "bid": 52
            },
            {
                "price": 12822.11,
                "ask": 63,
                "bid": 100
            },
            {
                "price": 12999.06,
                "ask": 47,
                "bid": 90
            },
            {
                "price": 12794.27,
                "ask": 74,
                "bid": 52
            },
            {
                "price": 12989.32,
                "ask": 11,
                "bid": 62
            },
            {
                "price": 12717.06,
                "ask": 99,
                "bid": 61
            },
            {
                "price": 12914.08,
                "ask": 42,
                "bid": 77
            },
            {
                "price": 12918.07,
                "ask": 62,
                "bid": 44
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dce94c3dc2ffeb0d9b",
        "high": 12792.99,
        "open": 12997.98,
        "close": 12941.13,
        "low": 12933.55,
        "priceList": [
            {
                "price": 12744.33,
                "ask": 65,
                "bid": 3
            },
            {
                "price": 12921.5,
                "ask": 27,
                "bid": 26
            },
            {
                "price": 12954.26,
                "ask": 17,
                "bid": 80
            },
            {
                "price": 12776.49,
                "ask": 13,
                "bid": 89
            },
            {
                "price": 12799.3,
                "ask": 67,
                "bid": 33
            },
            {
                "price": 12854.21,
                "ask": 70,
                "bid": 52
            },
            {
                "price": 12930.52,
                "ask": 46,
                "bid": 13
            },
            {
                "price": 12853.46,
                "ask": 17,
                "bid": 6
            },
            {
                "price": 12828.38,
                "ask": 46,
                "bid": 26
            },
            {
                "price": 12927.16,
                "ask": 57,
                "bid": 50
            },
            {
                "price": 12974.85,
                "ask": 100,
                "bid": 23
            },
            {
                "price": 12891.78,
                "ask": 26,
                "bid": 66
            },
            {
                "price": 12983.88,
                "ask": 36,
                "bid": 49
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcfc0ff196e504e5fb",
        "high": 12836.53,
        "open": 12804.98,
        "close": 12738.85,
        "low": 12873.8,
        "priceList": [
            {
                "price": 12754.08,
                "ask": 71,
                "bid": 40
            },
            {
                "price": 12882.95,
                "ask": 7,
                "bid": 47
            },
            {
                "price": 12839.88,
                "ask": 89,
                "bid": 42
            },
            {
                "price": 12874.35,
                "ask": 72,
                "bid": 37
            },
            {
                "price": 12814.38,
                "ask": 32,
                "bid": 63
            },
            {
                "price": 12789.24,
                "ask": 41,
                "bid": 9
            },
            {
                "price": 12953.7,
                "ask": 99,
                "bid": 24
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcfaced175e106620d",
        "high": 12707.23,
        "open": 12755.28,
        "close": 12721.01,
        "low": 12770.62,
        "priceList": [
            {
                "price": 12999.53,
                "ask": 45,
                "bid": 86
            },
            {
                "price": 12895.54,
                "ask": 28,
                "bid": 8
            },
            {
                "price": 12757.14,
                "ask": 61,
                "bid": 60
            },
            {
                "price": 12803.1,
                "ask": 3,
                "bid": 97
            },
            {
                "price": 12769.46,
                "ask": 90,
                "bid": 58
            },
            {
                "price": 12974.94,
                "ask": 62,
                "bid": 26
            },
            {
                "price": 12778.37,
                "ask": 50,
                "bid": 81
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcd518abaecfa1ba10",
        "high": 12896.57,
        "open": 12778.58,
        "close": 12716.98,
        "low": 12741.51,
        "priceList": [
            {
                "price": 12877.53,
                "ask": 8,
                "bid": 25
            },
            {
                "price": 12809.2,
                "ask": 15,
                "bid": 30
            },
            {
                "price": 12933.07,
                "ask": 21,
                "bid": 67
            },
            {
                "price": 12887.15,
                "ask": 79,
                "bid": 34
            },
            {
                "price": 12775.93,
                "ask": 46,
                "bid": 94
            },
            {
                "price": 12864.64,
                "ask": 99,
                "bid": 21
            },
            {
                "price": 12911.85,
                "ask": 41,
                "bid": 32
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc8c1889b438f50759",
        "high": 12958.37,
        "open": 12848.28,
        "close": 12785.67,
        "low": 12908.05,
        "priceList": [
            {
                "price": 12731.6,
                "ask": 75,
                "bid": 52
            },
            {
                "price": 12812.6,
                "ask": 34,
                "bid": 91
            },
            {
                "price": 12797.57,
                "ask": 96,
                "bid": 9
            },
            {
                "price": 12826.48,
                "ask": 60,
                "bid": 32
            },
            {
                "price": 12845.56,
                "ask": 24,
                "bid": 10
            },
            {
                "price": 12918.65,
                "ask": 70,
                "bid": 26
            },
            {
                "price": 12860.53,
                "ask": 72,
                "bid": 54
            },
            {
                "price": 12823.09,
                "ask": 29,
                "bid": 76
            },
            {
                "price": 12969.88,
                "ask": 21,
                "bid": 78
            },
            {
                "price": 12738.29,
                "ask": 68,
                "bid": 85
            },
            {
                "price": 12724.7,
                "ask": 19,
                "bid": 83
            },
            {
                "price": 12737.97,
                "ask": 63,
                "bid": 19
            },
            {
                "price": 12958.28,
                "ask": 61,
                "bid": 76
            },
            {
                "price": 12706.5,
                "ask": 43,
                "bid": 28
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcb9ea53b83680868f",
        "high": 12831.85,
        "open": 12878.76,
        "close": 12789.55,
        "low": 12700.39,
        "priceList": [
            {
                "price": 12713.78,
                "ask": 66,
                "bid": 39
            },
            {
                "price": 12893.46,
                "ask": 5,
                "bid": 55
            },
            {
                "price": 12704.05,
                "ask": 43,
                "bid": 36
            },
            {
                "price": 12748.11,
                "ask": 50,
                "bid": 86
            },
            {
                "price": 12797.8,
                "ask": 17,
                "bid": 67
            },
            {
                "price": 12769.19,
                "ask": 3,
                "bid": 15
            },
            {
                "price": 12857.7,
                "ask": 67,
                "bid": 48
            },
            {
                "price": 12733.29,
                "ask": 15,
                "bid": 94
            },
            {
                "price": 12931.21,
                "ask": 3,
                "bid": 85
            },
            {
                "price": 12868.02,
                "ask": 19,
                "bid": 37
            },
            {
                "price": 12943.86,
                "ask": 79,
                "bid": 94
            },
            {
                "price": 12904.57,
                "ask": 17,
                "bid": 7
            },
            {
                "price": 12981.51,
                "ask": 73,
                "bid": 85
            },
            {
                "price": 12752.24,
                "ask": 89,
                "bid": 61
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc62c7dda083828839",
        "high": 12824.13,
        "open": 12898.63,
        "close": 12963.09,
        "low": 12715.39,
        "priceList": [
            {
                "price": 12851.63,
                "ask": 27,
                "bid": 24
            },
            {
                "price": 12935.46,
                "ask": 53,
                "bid": 95
            },
            {
                "price": 12765.29,
                "ask": 55,
                "bid": 24
            },
            {
                "price": 12942.22,
                "ask": 50,
                "bid": 65
            },
            {
                "price": 12825.49,
                "ask": 51,
                "bid": 74
            },
            {
                "price": 12924.08,
                "ask": 40,
                "bid": 6
            },
            {
                "price": 12870.97,
                "ask": 31,
                "bid": 61
            },
            {
                "price": 12887.65,
                "ask": 20,
                "bid": 70
            },
            {
                "price": 12791.55,
                "ask": 85,
                "bid": 57
            },
            {
                "price": 12796.49,
                "ask": 31,
                "bid": 34
            },
            {
                "price": 12980.13,
                "ask": 84,
                "bid": 49
            },
            {
                "price": 12971.04,
                "ask": 26,
                "bid": 17
            },
            {
                "price": 12851.53,
                "ask": 41,
                "bid": 75
            },
            {
                "price": 12758.97,
                "ask": 40,
                "bid": 63
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dce23d0fe6cc5f34a5",
        "high": 12737.89,
        "open": 12803.64,
        "close": 12856.91,
        "low": 12836.29,
        "priceList": [
            {
                "price": 12980.26,
                "ask": 33,
                "bid": 71
            },
            {
                "price": 12746.85,
                "ask": 52,
                "bid": 37
            },
            {
                "price": 12794.19,
                "ask": 48,
                "bid": 94
            },
            {
                "price": 12908.66,
                "ask": 47,
                "bid": 63
            },
            {
                "price": 12990.81,
                "ask": 78,
                "bid": 71
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcb5199df7c1170e50",
        "high": 12878.79,
        "open": 12773.09,
        "close": 12720.54,
        "low": 12987.38,
        "priceList": [
            {
                "price": 12746.03,
                "ask": 86,
                "bid": 74
            },
            {
                "price": 12894.34,
                "ask": 92,
                "bid": 65
            },
            {
                "price": 12866.46,
                "ask": 42,
                "bid": 82
            },
            {
                "price": 12791.07,
                "ask": 82,
                "bid": 90
            },
            {
                "price": 12821.02,
                "ask": 22,
                "bid": 69
            },
            {
                "price": 12848.12,
                "ask": 99,
                "bid": 22
            },
            {
                "price": 12985.99,
                "ask": 72,
                "bid": 74
            },
            {
                "price": 12736.69,
                "ask": 56,
                "bid": 93
            },
            {
                "price": 12793.37,
                "ask": 44,
                "bid": 40
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc261db4259d6b44ea",
        "high": 12750.89,
        "open": 12858.61,
        "close": 12800.05,
        "low": 12765.34,
        "priceList": [
            {
                "price": 12850.47,
                "ask": 88,
                "bid": 50
            },
            {
                "price": 12796.73,
                "ask": 89,
                "bid": 12
            },
            {
                "price": 12812.61,
                "ask": 35,
                "bid": 32
            },
            {
                "price": 12921.23,
                "ask": 74,
                "bid": 59
            },
            {
                "price": 12942.77,
                "ask": 52,
                "bid": 81
            },
            {
                "price": 12857.19,
                "ask": 37,
                "bid": 80
            },
            {
                "price": 12868.01,
                "ask": 15,
                "bid": 61
            },
            {
                "price": 12884.47,
                "ask": 45,
                "bid": 93
            },
            {
                "price": 12784.82,
                "ask": 44,
                "bid": 8
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc53e16daef459f13d",
        "high": 12831,
        "open": 12838.81,
        "close": 12744.83,
        "low": 12746.74,
        "priceList": [
            {
                "price": 12866.26,
                "ask": 39,
                "bid": 18
            },
            {
                "price": 12978.59,
                "ask": 88,
                "bid": 61
            },
            {
                "price": 12856.2,
                "ask": 19,
                "bid": 88
            },
            {
                "price": 12712.22,
                "ask": 1,
                "bid": 36
            },
            {
                "price": 12705.53,
                "ask": 88,
                "bid": 24
            },
            {
                "price": 12997.83,
                "ask": 40,
                "bid": 75
            },
            {
                "price": 12912.12,
                "ask": 37,
                "bid": 11
            },
            {
                "price": 12869.1,
                "ask": 11,
                "bid": 45
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dca719def6daa39acb",
        "high": 12795.36,
        "open": 12937.29,
        "close": 12959.61,
        "low": 12884.24,
        "priceList": [
            {
                "price": 12850.95,
                "ask": 83,
                "bid": 4
            },
            {
                "price": 12943.65,
                "ask": 81,
                "bid": 51
            },
            {
                "price": 12902.44,
                "ask": 42,
                "bid": 55
            },
            {
                "price": 12895.65,
                "ask": 19,
                "bid": 7
            },
            {
                "price": 12708.78,
                "ask": 38,
                "bid": 53
            },
            {
                "price": 12950.66,
                "ask": 58,
                "bid": 58
            },
            {
                "price": 12749.35,
                "ask": 89,
                "bid": 74
            },
            {
                "price": 12725.85,
                "ask": 4,
                "bid": 80
            },
            {
                "price": 12906.55,
                "ask": 52,
                "bid": 17
            },
            {
                "price": 12947.2,
                "ask": 50,
                "bid": 42
            },
            {
                "price": 12985.39,
                "ask": 29,
                "bid": 63
            },
            {
                "price": 12966.08,
                "ask": 28,
                "bid": 59
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc5402d457b0e7209d",
        "high": 12870.55,
        "open": 12705.59,
        "close": 12806.8,
        "low": 12782.17,
        "priceList": [
            {
                "price": 12896.82,
                "ask": 58,
                "bid": 31
            },
            {
                "price": 12867.88,
                "ask": 6,
                "bid": 71
            },
            {
                "price": 12763.36,
                "ask": 61,
                "bid": 2
            },
            {
                "price": 12826.23,
                "ask": 13,
                "bid": 91
            },
            {
                "price": 12703.58,
                "ask": 66,
                "bid": 87
            },
            {
                "price": 12890.6,
                "ask": 28,
                "bid": 9
            },
            {
                "price": 12924.88,
                "ask": 48,
                "bid": 87
            },
            {
                "price": 12984.38,
                "ask": 12,
                "bid": 57
            },
            {
                "price": 12870.25,
                "ask": 9,
                "bid": 31
            },
            {
                "price": 12974.2,
                "ask": 6,
                "bid": 22
            },
            {
                "price": 12893.56,
                "ask": 63,
                "bid": 70
            },
            {
                "price": 12741.97,
                "ask": 45,
                "bid": 3
            },
            {
                "price": 12756.41,
                "ask": 57,
                "bid": 66
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc365f40b2e136c59b",
        "high": 12980.63,
        "open": 12895.72,
        "close": 12758.37,
        "low": 12735.86,
        "priceList": [
            {
                "price": 12961.72,
                "ask": 47,
                "bid": 81
            },
            {
                "price": 12757.76,
                "ask": 88,
                "bid": 68
            },
            {
                "price": 12907.44,
                "ask": 55,
                "bid": 17
            },
            {
                "price": 12976.03,
                "ask": 72,
                "bid": 11
            },
            {
                "price": 12853.28,
                "ask": 71,
                "bid": 16
            },
            {
                "price": 12946.99,
                "ask": 33,
                "bid": 6
            },
            {
                "price": 12887.4,
                "ask": 80,
                "bid": 91
            },
            {
                "price": 12930.34,
                "ask": 85,
                "bid": 20
            },
            {
                "price": 12896.13,
                "ask": 39,
                "bid": 89
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcb422e6b3a686e212",
        "high": 12871.38,
        "open": 12711.28,
        "close": 12974.92,
        "low": 12965.94,
        "priceList": [
            {
                "price": 12800.74,
                "ask": 58,
                "bid": 15
            },
            {
                "price": 12941.45,
                "ask": 87,
                "bid": 34
            },
            {
                "price": 12826.06,
                "ask": 72,
                "bid": 1
            },
            {
                "price": 12918.08,
                "ask": 100,
                "bid": 87
            },
            {
                "price": 12882.65,
                "ask": 1,
                "bid": 72
            },
            {
                "price": 12979.95,
                "ask": 93,
                "bid": 13
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc22eec255c9b5eabf",
        "high": 12899.23,
        "open": 12879.41,
        "close": 12785.01,
        "low": 12839.95,
        "priceList": [
            {
                "price": 12747.67,
                "ask": 5,
                "bid": 35
            },
            {
                "price": 12991.82,
                "ask": 50,
                "bid": 13
            },
            {
                "price": 12941.83,
                "ask": 34,
                "bid": 13
            },
            {
                "price": 12966.66,
                "ask": 25,
                "bid": 31
            },
            {
                "price": 12816.88,
                "ask": 77,
                "bid": 83
            },
            {
                "price": 12809.34,
                "ask": 35,
                "bid": 70
            },
            {
                "price": 12997.78,
                "ask": 12,
                "bid": 13
            },
            {
                "price": 12712.65,
                "ask": 79,
                "bid": 87
            },
            {
                "price": 12794.6,
                "ask": 74,
                "bid": 45
            },
            {
                "price": 12868.73,
                "ask": 14,
                "bid": 48
            },
            {
                "price": 12891.96,
                "ask": 43,
                "bid": 25
            },
            {
                "price": 12939.35,
                "ask": 54,
                "bid": 78
            },
            {
                "price": 12876.41,
                "ask": 73,
                "bid": 2
            },
            {
                "price": 12928.29,
                "ask": 32,
                "bid": 20
            },
            {
                "price": 12962.24,
                "ask": 76,
                "bid": 42
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc41f9cb92940c7c00",
        "high": 12818.81,
        "open": 12783.22,
        "close": 12883.99,
        "low": 12724.6,
        "priceList": [
            {
                "price": 12756,
                "ask": 53,
                "bid": 92
            },
            {
                "price": 12791.62,
                "ask": 46,
                "bid": 10
            },
            {
                "price": 12900.48,
                "ask": 83,
                "bid": 51
            },
            {
                "price": 12779.7,
                "ask": 43,
                "bid": 65
            },
            {
                "price": 12781.47,
                "ask": 47,
                "bid": 42
            },
            {
                "price": 12852.68,
                "ask": 81,
                "bid": 48
            },
            {
                "price": 12887.86,
                "ask": 33,
                "bid": 50
            },
            {
                "price": 12954.81,
                "ask": 87,
                "bid": 72
            },
            {
                "price": 12784.12,
                "ask": 55,
                "bid": 57
            },
            {
                "price": 12898.59,
                "ask": 53,
                "bid": 58
            },
            {
                "price": 12929.2,
                "ask": 93,
                "bid": 43
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc130ea4d263b40e5b",
        "high": 12982.18,
        "open": 12747.06,
        "close": 12819.13,
        "low": 12781.67,
        "priceList": [
            {
                "price": 12784.89,
                "ask": 62,
                "bid": 98
            },
            {
                "price": 12905.54,
                "ask": 0,
                "bid": 33
            },
            {
                "price": 12890.14,
                "ask": 31,
                "bid": 40
            },
            {
                "price": 12862.49,
                "ask": 60,
                "bid": 90
            },
            {
                "price": 12843.17,
                "ask": 34,
                "bid": 60
            },
            {
                "price": 12759.67,
                "ask": 55,
                "bid": 86
            },
            {
                "price": 12984.74,
                "ask": 64,
                "bid": 100
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcdd2189c12c1ecf44",
        "high": 12979.55,
        "open": 12847.97,
        "close": 12745.89,
        "low": 12978.64,
        "priceList": [
            {
                "price": 12973.11,
                "ask": 93,
                "bid": 21
            },
            {
                "price": 12772.35,
                "ask": 15,
                "bid": 67
            },
            {
                "price": 12851.75,
                "ask": 29,
                "bid": 92
            },
            {
                "price": 12960.98,
                "ask": 19,
                "bid": 67
            },
            {
                "price": 12940.74,
                "ask": 11,
                "bid": 15
            },
            {
                "price": 12772.32,
                "ask": 24,
                "bid": 37
            },
            {
                "price": 12864.93,
                "ask": 55,
                "bid": 76
            },
            {
                "price": 12788.29,
                "ask": 64,
                "bid": 77
            },
            {
                "price": 12973.79,
                "ask": 61,
                "bid": 24
            },
            {
                "price": 12912.09,
                "ask": 67,
                "bid": 89
            },
            {
                "price": 12855.1,
                "ask": 44,
                "bid": 6
            },
            {
                "price": 12951.12,
                "ask": 51,
                "bid": 50
            },
            {
                "price": 12932.11,
                "ask": 83,
                "bid": 38
            },
            {
                "price": 12977.17,
                "ask": 65,
                "bid": 25
            },
            {
                "price": 12738.87,
                "ask": 81,
                "bid": 71
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcfb27279dd9c189ab",
        "high": 12919.2,
        "open": 12876.22,
        "close": 12887.92,
        "low": 12970.65,
        "priceList": [
            {
                "price": 12940.6,
                "ask": 9,
                "bid": 82
            },
            {
                "price": 12971.18,
                "ask": 6,
                "bid": 25
            },
            {
                "price": 12702.25,
                "ask": 85,
                "bid": 61
            },
            {
                "price": 12817.15,
                "ask": 89,
                "bid": 29
            },
            {
                "price": 12972.55,
                "ask": 45,
                "bid": 0
            },
            {
                "price": 12741.06,
                "ask": 52,
                "bid": 57
            },
            {
                "price": 12734.6,
                "ask": 67,
                "bid": 13
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc5cf652e48f8457d2",
        "high": 12856.56,
        "open": 12708.96,
        "close": 12733.1,
        "low": 12722.45,
        "priceList": [
            {
                "price": 12918.12,
                "ask": 53,
                "bid": 66
            },
            {
                "price": 12878.67,
                "ask": 49,
                "bid": 81
            },
            {
                "price": 12735.71,
                "ask": 23,
                "bid": 71
            },
            {
                "price": 12978.65,
                "ask": 61,
                "bid": 68
            },
            {
                "price": 12716.61,
                "ask": 82,
                "bid": 70
            },
            {
                "price": 12785.8,
                "ask": 21,
                "bid": 42
            },
            {
                "price": 12922.15,
                "ask": 39,
                "bid": 47
            },
            {
                "price": 12814.34,
                "ask": 2,
                "bid": 54
            },
            {
                "price": 12963.14,
                "ask": 28,
                "bid": 76
            },
            {
                "price": 12875.88,
                "ask": 31,
                "bid": 59
            },
            {
                "price": 12995.58,
                "ask": 42,
                "bid": 63
            },
            {
                "price": 12712.92,
                "ask": 62,
                "bid": 48
            },
            {
                "price": 12783.55,
                "ask": 20,
                "bid": 51
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcea90f1d714deed0c",
        "high": 12775.72,
        "open": 12929.02,
        "close": 12770.9,
        "low": 12852.22,
        "priceList": [
            {
                "price": 12779.9,
                "ask": 25,
                "bid": 54
            },
            {
                "price": 12936.44,
                "ask": 84,
                "bid": 68
            },
            {
                "price": 12760.68,
                "ask": 16,
                "bid": 89
            },
            {
                "price": 12859.31,
                "ask": 60,
                "bid": 13
            },
            {
                "price": 12808.61,
                "ask": 97,
                "bid": 37
            },
            {
                "price": 12737.17,
                "ask": 20,
                "bid": 81
            },
            {
                "price": 12772.7,
                "ask": 87,
                "bid": 57
            },
            {
                "price": 12826.49,
                "ask": 43,
                "bid": 24
            },
            {
                "price": 12889.21,
                "ask": 20,
                "bid": 55
            },
            {
                "price": 12950.94,
                "ask": 90,
                "bid": 32
            },
            {
                "price": 12820.03,
                "ask": 99,
                "bid": 52
            },
            {
                "price": 12709,
                "ask": 91,
                "bid": 20
            },
            {
                "price": 12985.9,
                "ask": 99,
                "bid": 89
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc258d54a946189974",
        "high": 12893.21,
        "open": 12970.37,
        "close": 12718.73,
        "low": 12880.86,
        "priceList": [
            {
                "price": 12859.14,
                "ask": 28,
                "bid": 94
            },
            {
                "price": 12803.35,
                "ask": 46,
                "bid": 61
            },
            {
                "price": 12808.19,
                "ask": 42,
                "bid": 47
            },
            {
                "price": 12974.6,
                "ask": 100,
                "bid": 66
            },
            {
                "price": 12728.05,
                "ask": 8,
                "bid": 18
            },
            {
                "price": 12897.32,
                "ask": 3,
                "bid": 45
            },
            {
                "price": 12970.97,
                "ask": 56,
                "bid": 52
            },
            {
                "price": 12792.72,
                "ask": 31,
                "bid": 13
            },
            {
                "price": 12915.15,
                "ask": 30,
                "bid": 75
            },
            {
                "price": 12867.7,
                "ask": 85,
                "bid": 38
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc176e638728dab64a",
        "high": 12722.2,
        "open": 12788.53,
        "close": 12800.32,
        "low": 12812.29,
        "priceList": [
            {
                "price": 12749.05,
                "ask": 93,
                "bid": 98
            },
            {
                "price": 12768.45,
                "ask": 35,
                "bid": 46
            },
            {
                "price": 12847.62,
                "ask": 98,
                "bid": 28
            },
            {
                "price": 12730.38,
                "ask": 40,
                "bid": 55
            },
            {
                "price": 12742.2,
                "ask": 16,
                "bid": 68
            },
            {
                "price": 12954.85,
                "ask": 33,
                "bid": 66
            },
            {
                "price": 12992.5,
                "ask": 63,
                "bid": 75
            },
            {
                "price": 12729.44,
                "ask": 22,
                "bid": 0
            },
            {
                "price": 12911.53,
                "ask": 30,
                "bid": 29
            },
            {
                "price": 12947.61,
                "ask": 87,
                "bid": 0
            },
            {
                "price": 12750.57,
                "ask": 66,
                "bid": 45
            },
            {
                "price": 12784.97,
                "ask": 65,
                "bid": 10
            },
            {
                "price": 12807.06,
                "ask": 70,
                "bid": 16
            },
            {
                "price": 12817.07,
                "ask": 34,
                "bid": 85
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc9459baf1708076db",
        "high": 12724.09,
        "open": 12881.59,
        "close": 12909.18,
        "low": 12964.75,
        "priceList": [
            {
                "price": 12975.58,
                "ask": 1,
                "bid": 26
            },
            {
                "price": 12806.11,
                "ask": 5,
                "bid": 19
            },
            {
                "price": 12792.74,
                "ask": 74,
                "bid": 56
            },
            {
                "price": 12773.82,
                "ask": 93,
                "bid": 86
            },
            {
                "price": 12824.58,
                "ask": 52,
                "bid": 52
            },
            {
                "price": 12703.72,
                "ask": 87,
                "bid": 93
            },
            {
                "price": 12854.46,
                "ask": 49,
                "bid": 65
            },
            {
                "price": 12944.81,
                "ask": 85,
                "bid": 11
            },
            {
                "price": 12717.56,
                "ask": 61,
                "bid": 42
            },
            {
                "price": 12929.03,
                "ask": 30,
                "bid": 80
            },
            {
                "price": 12874.5,
                "ask": 18,
                "bid": 21
            },
            {
                "price": 12703.69,
                "ask": 59,
                "bid": 80
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc9e460dae544c49f1",
        "high": 12770.61,
        "open": 12781.87,
        "close": 12909.28,
        "low": 12914.23,
        "priceList": [
            {
                "price": 12782.18,
                "ask": 65,
                "bid": 14
            },
            {
                "price": 12767.96,
                "ask": 20,
                "bid": 98
            },
            {
                "price": 12821.84,
                "ask": 18,
                "bid": 73
            },
            {
                "price": 12999.54,
                "ask": 57,
                "bid": 81
            },
            {
                "price": 12846.9,
                "ask": 67,
                "bid": 43
            },
            {
                "price": 12909.43,
                "ask": 45,
                "bid": 63
            },
            {
                "price": 12807.09,
                "ask": 64,
                "bid": 27
            },
            {
                "price": 12934.76,
                "ask": 15,
                "bid": 72
            },
            {
                "price": 12820.57,
                "ask": 81,
                "bid": 14
            },
            {
                "price": 12907.07,
                "ask": 94,
                "bid": 50
            },
            {
                "price": 12862.9,
                "ask": 57,
                "bid": 56
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc47504385d27eddb9",
        "high": 12779.34,
        "open": 12963.58,
        "close": 12974.18,
        "low": 12860.77,
        "priceList": [
            {
                "price": 12885.64,
                "ask": 69,
                "bid": 94
            },
            {
                "price": 12955.45,
                "ask": 39,
                "bid": 50
            },
            {
                "price": 12898.03,
                "ask": 66,
                "bid": 70
            },
            {
                "price": 12787.23,
                "ask": 7,
                "bid": 48
            },
            {
                "price": 12865.84,
                "ask": 53,
                "bid": 69
            },
            {
                "price": 12870.49,
                "ask": 58,
                "bid": 64
            },
            {
                "price": 12990.72,
                "ask": 78,
                "bid": 70
            },
            {
                "price": 12839.95,
                "ask": 16,
                "bid": 64
            },
            {
                "price": 12996.36,
                "ask": 55,
                "bid": 18
            },
            {
                "price": 12855.21,
                "ask": 53,
                "bid": 93
            },
            {
                "price": 12761.82,
                "ask": 95,
                "bid": 21
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc26a2d5e3bd0a7902",
        "high": 12748.21,
        "open": 12756.07,
        "close": 12737.4,
        "low": 12832.54,
        "priceList": [
            {
                "price": 12709.7,
                "ask": 6,
                "bid": 85
            },
            {
                "price": 12703.36,
                "ask": 43,
                "bid": 10
            },
            {
                "price": 12901.01,
                "ask": 47,
                "bid": 53
            },
            {
                "price": 12972.6,
                "ask": 43,
                "bid": 9
            },
            {
                "price": 12746.01,
                "ask": 14,
                "bid": 87
            },
            {
                "price": 12737.79,
                "ask": 69,
                "bid": 70
            },
            {
                "price": 12823.24,
                "ask": 90,
                "bid": 90
            },
            {
                "price": 12762.54,
                "ask": 11,
                "bid": 79
            },
            {
                "price": 12764.23,
                "ask": 40,
                "bid": 95
            },
            {
                "price": 12826.61,
                "ask": 71,
                "bid": 51
            },
            {
                "price": 12807.17,
                "ask": 82,
                "bid": 4
            },
            {
                "price": 12708.05,
                "ask": 15,
                "bid": 78
            },
            {
                "price": 12765.65,
                "ask": 80,
                "bid": 60
            },
            {
                "price": 12968.5,
                "ask": 15,
                "bid": 77
            },
            {
                "price": 12825.91,
                "ask": 25,
                "bid": 45
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc30372474b4275497",
        "high": 12725.05,
        "open": 12709.88,
        "close": 12974.56,
        "low": 12866.16,
        "priceList": [
            {
                "price": 12722.61,
                "ask": 76,
                "bid": 49
            },
            {
                "price": 12934.37,
                "ask": 42,
                "bid": 66
            },
            {
                "price": 12773.6,
                "ask": 63,
                "bid": 85
            },
            {
                "price": 12852.97,
                "ask": 93,
                "bid": 51
            },
            {
                "price": 12881.66,
                "ask": 58,
                "bid": 1
            },
            {
                "price": 12829.31,
                "ask": 31,
                "bid": 59
            },
            {
                "price": 12945.69,
                "ask": 26,
                "bid": 35
            },
            {
                "price": 12795.88,
                "ask": 85,
                "bid": 33
            },
            {
                "price": 12906.11,
                "ask": 21,
                "bid": 37
            },
            {
                "price": 12851.17,
                "ask": 46,
                "bid": 74
            },
            {
                "price": 12939.7,
                "ask": 3,
                "bid": 74
            },
            {
                "price": 12822.36,
                "ask": 1,
                "bid": 51
            },
            {
                "price": 12929.27,
                "ask": 28,
                "bid": 24
            },
            {
                "price": 12825.99,
                "ask": 74,
                "bid": 26
            },
            {
                "price": 12895.78,
                "ask": 44,
                "bid": 79
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc61032a3661b06878",
        "high": 12791.5,
        "open": 12869.27,
        "close": 12833.95,
        "low": 12909.18,
        "priceList": [
            {
                "price": 12716.24,
                "ask": 49,
                "bid": 13
            },
            {
                "price": 12827.18,
                "ask": 3,
                "bid": 75
            },
            {
                "price": 12978.65,
                "ask": 98,
                "bid": 41
            },
            {
                "price": 12744.02,
                "ask": 39,
                "bid": 48
            },
            {
                "price": 12906.31,
                "ask": 9,
                "bid": 74
            },
            {
                "price": 12752.02,
                "ask": 14,
                "bid": 30
            },
            {
                "price": 12705.21,
                "ask": 6,
                "bid": 100
            },
            {
                "price": 12968.94,
                "ask": 92,
                "bid": 55
            },
            {
                "price": 12781.66,
                "ask": 60,
                "bid": 1
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc62f0685a6e1e782a",
        "high": 12800.58,
        "open": 12942.06,
        "close": 12938.49,
        "low": 12795.85,
        "priceList": [
            {
                "price": 12805.26,
                "ask": 67,
                "bid": 98
            },
            {
                "price": 12859.48,
                "ask": 44,
                "bid": 61
            },
            {
                "price": 12799.24,
                "ask": 48,
                "bid": 31
            },
            {
                "price": 12960.33,
                "ask": 23,
                "bid": 21
            },
            {
                "price": 12983.08,
                "ask": 87,
                "bid": 22
            },
            {
                "price": 12774.64,
                "ask": 37,
                "bid": 80
            },
            {
                "price": 12791.1,
                "ask": 50,
                "bid": 24
            },
            {
                "price": 12745.7,
                "ask": 43,
                "bid": 75
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc53e4abe636d7cdba",
        "high": 12717.38,
        "open": 12922.15,
        "close": 12884.02,
        "low": 12734.86,
        "priceList": [
            {
                "price": 12887.88,
                "ask": 27,
                "bid": 55
            },
            {
                "price": 12906.27,
                "ask": 97,
                "bid": 28
            },
            {
                "price": 12899.52,
                "ask": 97,
                "bid": 55
            },
            {
                "price": 12911.51,
                "ask": 38,
                "bid": 90
            },
            {
                "price": 12800.76,
                "ask": 77,
                "bid": 7
            },
            {
                "price": 12725.74,
                "ask": 61,
                "bid": 100
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc36bb9007006a11c6",
        "high": 12831.6,
        "open": 12935.5,
        "close": 12828.57,
        "low": 12755.03,
        "priceList": [
            {
                "price": 12937.17,
                "ask": 51,
                "bid": 94
            },
            {
                "price": 12727.67,
                "ask": 9,
                "bid": 23
            },
            {
                "price": 12948.46,
                "ask": 53,
                "bid": 58
            },
            {
                "price": 12805.45,
                "ask": 89,
                "bid": 9
            },
            {
                "price": 12701.11,
                "ask": 11,
                "bid": 92
            },
            {
                "price": 12784.88,
                "ask": 82,
                "bid": 31
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc046436bf873ca608",
        "high": 12789.25,
        "open": 12896.01,
        "close": 12880.53,
        "low": 12989.67,
        "priceList": [
            {
                "price": 12842.7,
                "ask": 12,
                "bid": 3
            },
            {
                "price": 12956.11,
                "ask": 99,
                "bid": 57
            },
            {
                "price": 12758.81,
                "ask": 93,
                "bid": 94
            },
            {
                "price": 12710.73,
                "ask": 78,
                "bid": 91
            },
            {
                "price": 12917.97,
                "ask": 71,
                "bid": 21
            },
            {
                "price": 12772.61,
                "ask": 43,
                "bid": 97
            },
            {
                "price": 12956.53,
                "ask": 23,
                "bid": 52
            },
            {
                "price": 12739.02,
                "ask": 69,
                "bid": 51
            },
            {
                "price": 12708.54,
                "ask": 50,
                "bid": 78
            },
            {
                "price": 12871.57,
                "ask": 59,
                "bid": 4
            },
            {
                "price": 12976.65,
                "ask": 54,
                "bid": 38
            },
            {
                "price": 12889.6,
                "ask": 41,
                "bid": 24
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc235039c759fa2464",
        "high": 12967.33,
        "open": 12975.18,
        "close": 12708.73,
        "low": 12735.58,
        "priceList": [
            {
                "price": 12837.25,
                "ask": 43,
                "bid": 25
            },
            {
                "price": 12912.76,
                "ask": 54,
                "bid": 41
            },
            {
                "price": 12980.97,
                "ask": 76,
                "bid": 65
            },
            {
                "price": 12829.58,
                "ask": 38,
                "bid": 92
            },
            {
                "price": 12841.36,
                "ask": 8,
                "bid": 3
            },
            {
                "price": 12705.06,
                "ask": 86,
                "bid": 42
            },
            {
                "price": 12837.56,
                "ask": 13,
                "bid": 25
            },
            {
                "price": 12854.23,
                "ask": 50,
                "bid": 2
            },
            {
                "price": 12941.62,
                "ask": 68,
                "bid": 14
            },
            {
                "price": 12959.14,
                "ask": 42,
                "bid": 21
            },
            {
                "price": 12981.3,
                "ask": 65,
                "bid": 33
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc8d51cbf0e825a446",
        "high": 12895.96,
        "open": 12749.96,
        "close": 12736.32,
        "low": 12838,
        "priceList": [
            {
                "price": 12737.59,
                "ask": 42,
                "bid": 24
            },
            {
                "price": 12951.05,
                "ask": 71,
                "bid": 66
            },
            {
                "price": 12800.91,
                "ask": 50,
                "bid": 65
            },
            {
                "price": 12807.72,
                "ask": 86,
                "bid": 28
            },
            {
                "price": 12828.41,
                "ask": 62,
                "bid": 55
            },
            {
                "price": 12933.88,
                "ask": 49,
                "bid": 28
            },
            {
                "price": 12837.54,
                "ask": 8,
                "bid": 31
            },
            {
                "price": 12785.38,
                "ask": 94,
                "bid": 3
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc9eca6e3e2886ef3b",
        "high": 12788.37,
        "open": 12788.49,
        "close": 12962.17,
        "low": 12808.23,
        "priceList": [
            {
                "price": 12799.41,
                "ask": 45,
                "bid": 63
            },
            {
                "price": 12957.64,
                "ask": 61,
                "bid": 4
            },
            {
                "price": 12752.06,
                "ask": 52,
                "bid": 68
            },
            {
                "price": 12729.6,
                "ask": 45,
                "bid": 2
            },
            {
                "price": 12716.73,
                "ask": 48,
                "bid": 25
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc52427ddf0ba60a5a",
        "high": 12713.89,
        "open": 12799.9,
        "close": 12770.11,
        "low": 12744.05,
        "priceList": [
            {
                "price": 12971.71,
                "ask": 58,
                "bid": 99
            },
            {
                "price": 12993.05,
                "ask": 22,
                "bid": 93
            },
            {
                "price": 12864.37,
                "ask": 53,
                "bid": 85
            },
            {
                "price": 12762.82,
                "ask": 63,
                "bid": 19
            },
            {
                "price": 12988.09,
                "ask": 85,
                "bid": 24
            },
            {
                "price": 12981.28,
                "ask": 92,
                "bid": 14
            },
            {
                "price": 12849.56,
                "ask": 60,
                "bid": 57
            },
            {
                "price": 12827.29,
                "ask": 87,
                "bid": 88
            },
            {
                "price": 12984,
                "ask": 17,
                "bid": 72
            },
            {
                "price": 12736.56,
                "ask": 63,
                "bid": 25
            },
            {
                "price": 12888.61,
                "ask": 25,
                "bid": 95
            },
            {
                "price": 12938.02,
                "ask": 16,
                "bid": 37
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dce80f2e7c9d19c0e8",
        "high": 12871.91,
        "open": 12731.87,
        "close": 12712.68,
        "low": 12979.41,
        "priceList": [
            {
                "price": 12957.33,
                "ask": 0,
                "bid": 35
            },
            {
                "price": 12758.93,
                "ask": 84,
                "bid": 75
            },
            {
                "price": 12789.75,
                "ask": 35,
                "bid": 32
            },
            {
                "price": 12957.64,
                "ask": 10,
                "bid": 83
            },
            {
                "price": 12969.01,
                "ask": 21,
                "bid": 37
            },
            {
                "price": 12839.58,
                "ask": 14,
                "bid": 16
            },
            {
                "price": 12967.17,
                "ask": 93,
                "bid": 86
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcb61397475476bf25",
        "high": 12732.63,
        "open": 12786.57,
        "close": 12755.34,
        "low": 12966.61,
        "priceList": [
            {
                "price": 12974.44,
                "ask": 85,
                "bid": 68
            },
            {
                "price": 12856.05,
                "ask": 69,
                "bid": 17
            },
            {
                "price": 12718.34,
                "ask": 38,
                "bid": 26
            },
            {
                "price": 12984.12,
                "ask": 79,
                "bid": 99
            },
            {
                "price": 12823.33,
                "ask": 84,
                "bid": 30
            },
            {
                "price": 12739.66,
                "ask": 7,
                "bid": 88
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc6fff82df141cc054",
        "high": 12741.92,
        "open": 12906.8,
        "close": 12832.88,
        "low": 12950.06,
        "priceList": [
            {
                "price": 12701.17,
                "ask": 33,
                "bid": 67
            },
            {
                "price": 12913.98,
                "ask": 90,
                "bid": 80
            },
            {
                "price": 12747.23,
                "ask": 55,
                "bid": 0
            },
            {
                "price": 12979.42,
                "ask": 4,
                "bid": 51
            },
            {
                "price": 12872.03,
                "ask": 77,
                "bid": 2
            },
            {
                "price": 12884.13,
                "ask": 79,
                "bid": 54
            },
            {
                "price": 12714.81,
                "ask": 78,
                "bid": 11
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc722b9bef85f1c610",
        "high": 12895.44,
        "open": 12751.86,
        "close": 12738.79,
        "low": 12848.24,
        "priceList": [
            {
                "price": 12807.82,
                "ask": 42,
                "bid": 82
            },
            {
                "price": 12821.04,
                "ask": 46,
                "bid": 32
            },
            {
                "price": 12731.33,
                "ask": 41,
                "bid": 35
            },
            {
                "price": 12700.69,
                "ask": 52,
                "bid": 20
            },
            {
                "price": 12885.46,
                "ask": 13,
                "bid": 48
            },
            {
                "price": 12743.98,
                "ask": 28,
                "bid": 78
            },
            {
                "price": 12910.46,
                "ask": 59,
                "bid": 11
            },
            {
                "price": 12721.12,
                "ask": 9,
                "bid": 28
            },
            {
                "price": 12847.52,
                "ask": 14,
                "bid": 51
            },
            {
                "price": 12883.75,
                "ask": 35,
                "bid": 15
            },
            {
                "price": 12804.2,
                "ask": 77,
                "bid": 98
            },
            {
                "price": 12960.58,
                "ask": 30,
                "bid": 15
            },
            {
                "price": 12799.91,
                "ask": 66,
                "bid": 46
            },
            {
                "price": 12985.98,
                "ask": 39,
                "bid": 97
            },
            {
                "price": 12875.43,
                "ask": 76,
                "bid": 80
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc8b061dafef52025f",
        "high": 12893.98,
        "open": 12758.49,
        "close": 12786.13,
        "low": 12777.03,
        "priceList": [
            {
                "price": 12846.58,
                "ask": 62,
                "bid": 96
            },
            {
                "price": 12998.16,
                "ask": 26,
                "bid": 17
            },
            {
                "price": 12777.1,
                "ask": 41,
                "bid": 69
            },
            {
                "price": 12878.43,
                "ask": 85,
                "bid": 1
            },
            {
                "price": 12958.24,
                "ask": 48,
                "bid": 42
            },
            {
                "price": 12922.67,
                "ask": 5,
                "bid": 17
            },
            {
                "price": 12876.15,
                "ask": 50,
                "bid": 75
            },
            {
                "price": 12818.73,
                "ask": 6,
                "bid": 88
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcea9ba28a562d4781",
        "high": 12897.25,
        "open": 12882.82,
        "close": 12969.09,
        "low": 12841.99,
        "priceList": [
            {
                "price": 12929.18,
                "ask": 68,
                "bid": 51
            },
            {
                "price": 12835.05,
                "ask": 55,
                "bid": 32
            },
            {
                "price": 12944.66,
                "ask": 1,
                "bid": 17
            },
            {
                "price": 12717.65,
                "ask": 4,
                "bid": 68
            },
            {
                "price": 12861.72,
                "ask": 77,
                "bid": 76
            },
            {
                "price": 12722.37,
                "ask": 9,
                "bid": 36
            },
            {
                "price": 12952.59,
                "ask": 28,
                "bid": 26
            },
            {
                "price": 12759.23,
                "ask": 72,
                "bid": 22
            },
            {
                "price": 12727.95,
                "ask": 47,
                "bid": 60
            },
            {
                "price": 12745.51,
                "ask": 28,
                "bid": 43
            },
            {
                "price": 12799.17,
                "ask": 51,
                "bid": 76
            },
            {
                "price": 12974.53,
                "ask": 11,
                "bid": 91
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcf0556d0373a2269d",
        "high": 12937.24,
        "open": 12949.62,
        "close": 12872.4,
        "low": 12881.15,
        "priceList": [
            {
                "price": 12707.88,
                "ask": 92,
                "bid": 67
            },
            {
                "price": 12717.29,
                "ask": 53,
                "bid": 13
            },
            {
                "price": 12919.05,
                "ask": 67,
                "bid": 16
            },
            {
                "price": 12858.28,
                "ask": 44,
                "bid": 57
            },
            {
                "price": 12940.28,
                "ask": 18,
                "bid": 32
            },
            {
                "price": 12718.12,
                "ask": 30,
                "bid": 87
            },
            {
                "price": 12935.37,
                "ask": 62,
                "bid": 81
            },
            {
                "price": 12953.34,
                "ask": 60,
                "bid": 52
            },
            {
                "price": 12860.79,
                "ask": 58,
                "bid": 60
            },
            {
                "price": 12881.57,
                "ask": 71,
                "bid": 94
            },
            {
                "price": 12790.93,
                "ask": 32,
                "bid": 46
            },
            {
                "price": 12793.82,
                "ask": 18,
                "bid": 37
            },
            {
                "price": 12833,
                "ask": 10,
                "bid": 57
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc4c6ca9279ffdde06",
        "high": 12876.91,
        "open": 12918.45,
        "close": 12721.59,
        "low": 12910.3,
        "priceList": [
            {
                "price": 12857.65,
                "ask": 49,
                "bid": 64
            },
            {
                "price": 12891.81,
                "ask": 52,
                "bid": 99
            },
            {
                "price": 12723.5,
                "ask": 92,
                "bid": 5
            },
            {
                "price": 12840.28,
                "ask": 61,
                "bid": 93
            },
            {
                "price": 12942.82,
                "ask": 94,
                "bid": 2
            },
            {
                "price": 12836.33,
                "ask": 51,
                "bid": 4
            },
            {
                "price": 12975.06,
                "ask": 4,
                "bid": 75
            },
            {
                "price": 12746,
                "ask": 43,
                "bid": 84
            },
            {
                "price": 12731.29,
                "ask": 41,
                "bid": 23
            },
            {
                "price": 12855.34,
                "ask": 8,
                "bid": 36
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcdd643f1ac0f966a0",
        "high": 12734.25,
        "open": 12970.63,
        "close": 12936.07,
        "low": 12950.57,
        "priceList": [
            {
                "price": 12949.22,
                "ask": 75,
                "bid": 24
            },
            {
                "price": 12917.98,
                "ask": 33,
                "bid": 30
            },
            {
                "price": 12710.28,
                "ask": 44,
                "bid": 55
            },
            {
                "price": 12831.38,
                "ask": 47,
                "bid": 46
            },
            {
                "price": 12768.07,
                "ask": 66,
                "bid": 82
            },
            {
                "price": 12908.91,
                "ask": 29,
                "bid": 83
            },
            {
                "price": 12843.35,
                "ask": 98,
                "bid": 52
            },
            {
                "price": 12956.92,
                "ask": 27,
                "bid": 68
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcd1c354da5a2b9f0b",
        "high": 12852.3,
        "open": 12764.68,
        "close": 12732.27,
        "low": 12893.9,
        "priceList": [
            {
                "price": 12726.08,
                "ask": 62,
                "bid": 70
            },
            {
                "price": 12779.81,
                "ask": 48,
                "bid": 16
            },
            {
                "price": 12997.11,
                "ask": 14,
                "bid": 63
            },
            {
                "price": 12718.95,
                "ask": 54,
                "bid": 36
            },
            {
                "price": 12846.25,
                "ask": 20,
                "bid": 69
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dca3e48f022d4617c7",
        "high": 12869.93,
        "open": 12833.3,
        "close": 12925.07,
        "low": 12898.51,
        "priceList": [
            {
                "price": 12743.27,
                "ask": 81,
                "bid": 5
            },
            {
                "price": 12732.23,
                "ask": 78,
                "bid": 31
            },
            {
                "price": 12785.73,
                "ask": 22,
                "bid": 62
            },
            {
                "price": 12852.5,
                "ask": 59,
                "bid": 33
            },
            {
                "price": 12998.46,
                "ask": 52,
                "bid": 38
            },
            {
                "price": 12896.49,
                "ask": 21,
                "bid": 100
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc34ae895ef5047ba5",
        "high": 12777.44,
        "open": 12851.95,
        "close": 12983.37,
        "low": 12732.19,
        "priceList": [
            {
                "price": 12777.62,
                "ask": 50,
                "bid": 20
            },
            {
                "price": 12948.63,
                "ask": 74,
                "bid": 12
            },
            {
                "price": 12834.18,
                "ask": 64,
                "bid": 68
            },
            {
                "price": 12788.51,
                "ask": 38,
                "bid": 31
            },
            {
                "price": 12894.1,
                "ask": 23,
                "bid": 30
            },
            {
                "price": 12735.81,
                "ask": 73,
                "bid": 8
            },
            {
                "price": 12991.96,
                "ask": 28,
                "bid": 75
            },
            {
                "price": 12780.58,
                "ask": 61,
                "bid": 54
            },
            {
                "price": 12909.28,
                "ask": 83,
                "bid": 26
            },
            {
                "price": 12917.45,
                "ask": 97,
                "bid": 5
            },
            {
                "price": 12985.8,
                "ask": 21,
                "bid": 34
            },
            {
                "price": 12732.04,
                "ask": 56,
                "bid": 9
            },
            {
                "price": 12726.09,
                "ask": 96,
                "bid": 76
            },
            {
                "price": 12734.88,
                "ask": 76,
                "bid": 89
            },
            {
                "price": 12906.87,
                "ask": 31,
                "bid": 32
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dceca79d216e88f6db",
        "high": 12890.71,
        "open": 12963.55,
        "close": 12730.58,
        "low": 12770.23,
        "priceList": [
            {
                "price": 12806.89,
                "ask": 93,
                "bid": 19
            },
            {
                "price": 12707.61,
                "ask": 14,
                "bid": 92
            },
            {
                "price": 12838.43,
                "ask": 87,
                "bid": 40
            },
            {
                "price": 12944.45,
                "ask": 2,
                "bid": 15
            },
            {
                "price": 12996.13,
                "ask": 12,
                "bid": 97
            },
            {
                "price": 12773.19,
                "ask": 40,
                "bid": 14
            },
            {
                "price": 12793.62,
                "ask": 27,
                "bid": 81
            },
            {
                "price": 12935.86,
                "ask": 73,
                "bid": 30
            },
            {
                "price": 12932.63,
                "ask": 58,
                "bid": 70
            },
            {
                "price": 12757.5,
                "ask": 89,
                "bid": 30
            },
            {
                "price": 12782.44,
                "ask": 97,
                "bid": 58
            },
            {
                "price": 12758.39,
                "ask": 98,
                "bid": 96
            },
            {
                "price": 12832.76,
                "ask": 66,
                "bid": 98
            },
            {
                "price": 12722.22,
                "ask": 79,
                "bid": 81
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc92180dc73f7fb7e7",
        "high": 12857.21,
        "open": 12959.43,
        "close": 12808.52,
        "low": 12745.98,
        "priceList": [
            {
                "price": 12788.85,
                "ask": 18,
                "bid": 32
            },
            {
                "price": 12722.12,
                "ask": 7,
                "bid": 25
            },
            {
                "price": 12946.25,
                "ask": 31,
                "bid": 53
            },
            {
                "price": 12966.31,
                "ask": 65,
                "bid": 18
            },
            {
                "price": 12814.85,
                "ask": 70,
                "bid": 79
            },
            {
                "price": 12706.87,
                "ask": 42,
                "bid": 82
            },
            {
                "price": 12960.89,
                "ask": 44,
                "bid": 39
            },
            {
                "price": 12711.66,
                "ask": 25,
                "bid": 62
            },
            {
                "price": 12832.78,
                "ask": 49,
                "bid": 51
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcb786a7385b1a2031",
        "high": 12801.93,
        "open": 12752.67,
        "close": 12785.36,
        "low": 12814.58,
        "priceList": [
            {
                "price": 12780.9,
                "ask": 93,
                "bid": 98
            },
            {
                "price": 12775.56,
                "ask": 39,
                "bid": 80
            },
            {
                "price": 12922.75,
                "ask": 74,
                "bid": 92
            },
            {
                "price": 12758.27,
                "ask": 3,
                "bid": 75
            },
            {
                "price": 12736.1,
                "ask": 67,
                "bid": 50
            },
            {
                "price": 12717.07,
                "ask": 40,
                "bid": 6
            },
            {
                "price": 12921.9,
                "ask": 88,
                "bid": 33
            },
            {
                "price": 12895.32,
                "ask": 60,
                "bid": 4
            },
            {
                "price": 12752,
                "ask": 82,
                "bid": 40
            },
            {
                "price": 12815.75,
                "ask": 57,
                "bid": 64
            },
            {
                "price": 12824.36,
                "ask": 14,
                "bid": 35
            },
            {
                "price": 12947.52,
                "ask": 33,
                "bid": 22
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc054406a22c9671f6",
        "high": 12762.06,
        "open": 12899.6,
        "close": 12799.99,
        "low": 12940.9,
        "priceList": [
            {
                "price": 12966.47,
                "ask": 47,
                "bid": 90
            },
            {
                "price": 12914.97,
                "ask": 70,
                "bid": 15
            },
            {
                "price": 12861.6,
                "ask": 66,
                "bid": 23
            },
            {
                "price": 12756.37,
                "ask": 66,
                "bid": 33
            },
            {
                "price": 12781.24,
                "ask": 18,
                "bid": 34
            },
            {
                "price": 12996.41,
                "ask": 78,
                "bid": 42
            },
            {
                "price": 12916.68,
                "ask": 84,
                "bid": 9
            },
            {
                "price": 12743.27,
                "ask": 56,
                "bid": 51
            },
            {
                "price": 12955.74,
                "ask": 74,
                "bid": 93
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc0717429d5a783dc3",
        "high": 12781.08,
        "open": 12854.86,
        "close": 12965.24,
        "low": 12854.92,
        "priceList": [
            {
                "price": 12764.56,
                "ask": 60,
                "bid": 88
            },
            {
                "price": 12810.49,
                "ask": 27,
                "bid": 74
            },
            {
                "price": 12732.28,
                "ask": 63,
                "bid": 66
            },
            {
                "price": 12909.48,
                "ask": 63,
                "bid": 0
            },
            {
                "price": 12708.23,
                "ask": 8,
                "bid": 16
            },
            {
                "price": 12936.52,
                "ask": 37,
                "bid": 6
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc323159a4864eacfc",
        "high": 12970.19,
        "open": 12786.26,
        "close": 12716.24,
        "low": 12942.06,
        "priceList": [
            {
                "price": 12788.12,
                "ask": 56,
                "bid": 21
            },
            {
                "price": 12781.99,
                "ask": 82,
                "bid": 47
            },
            {
                "price": 12705.86,
                "ask": 58,
                "bid": 19
            },
            {
                "price": 12892.78,
                "ask": 91,
                "bid": 19
            },
            {
                "price": 12829.1,
                "ask": 29,
                "bid": 7
            },
            {
                "price": 12958.23,
                "ask": 13,
                "bid": 4
            },
            {
                "price": 12707.81,
                "ask": 72,
                "bid": 13
            },
            {
                "price": 12899.67,
                "ask": 91,
                "bid": 15
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc29d0d471f24d8a2c",
        "high": 12869.41,
        "open": 12705.27,
        "close": 12875.02,
        "low": 12753.38,
        "priceList": [
            {
                "price": 12954.69,
                "ask": 87,
                "bid": 33
            },
            {
                "price": 12755.69,
                "ask": 32,
                "bid": 67
            },
            {
                "price": 12867.63,
                "ask": 92,
                "bid": 14
            },
            {
                "price": 12733.22,
                "ask": 6,
                "bid": 81
            },
            {
                "price": 12828.9,
                "ask": 7,
                "bid": 72
            },
            {
                "price": 12740.26,
                "ask": 34,
                "bid": 37
            },
            {
                "price": 12947.39,
                "ask": 56,
                "bid": 53
            },
            {
                "price": 12866.86,
                "ask": 70,
                "bid": 31
            },
            {
                "price": 12878.46,
                "ask": 84,
                "bid": 84
            },
            {
                "price": 12873.01,
                "ask": 54,
                "bid": 50
            },
            {
                "price": 12990.35,
                "ask": 26,
                "bid": 42
            },
            {
                "price": 12995.85,
                "ask": 74,
                "bid": 16
            },
            {
                "price": 12904.81,
                "ask": 40,
                "bid": 28
            },
            {
                "price": 12944.68,
                "ask": 58,
                "bid": 19
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcbd6d2d1b82159b75",
        "high": 12912.37,
        "open": 12850.75,
        "close": 12921.49,
        "low": 12786.48,
        "priceList": [
            {
                "price": 12992.49,
                "ask": 8,
                "bid": 75
            },
            {
                "price": 12784.96,
                "ask": 57,
                "bid": 9
            },
            {
                "price": 12857.17,
                "ask": 13,
                "bid": 11
            },
            {
                "price": 12889.15,
                "ask": 67,
                "bid": 63
            },
            {
                "price": 12785.83,
                "ask": 92,
                "bid": 82
            },
            {
                "price": 12846.17,
                "ask": 98,
                "bid": 10
            },
            {
                "price": 12863.11,
                "ask": 69,
                "bid": 23
            },
            {
                "price": 12924.66,
                "ask": 76,
                "bid": 73
            },
            {
                "price": 12978.17,
                "ask": 34,
                "bid": 55
            },
            {
                "price": 12812.99,
                "ask": 59,
                "bid": 72
            },
            {
                "price": 12881.04,
                "ask": 13,
                "bid": 75
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc00fc89016b6c0f9e",
        "high": 12870.21,
        "open": 12778.26,
        "close": 12836.87,
        "low": 12787.88,
        "priceList": [
            {
                "price": 12871.59,
                "ask": 22,
                "bid": 95
            },
            {
                "price": 12875.16,
                "ask": 45,
                "bid": 42
            },
            {
                "price": 12815.86,
                "ask": 48,
                "bid": 87
            },
            {
                "price": 12735.63,
                "ask": 81,
                "bid": 68
            },
            {
                "price": 12992.27,
                "ask": 1,
                "bid": 100
            },
            {
                "price": 12731.09,
                "ask": 20,
                "bid": 36
            },
            {
                "price": 12990.69,
                "ask": 20,
                "bid": 54
            },
            {
                "price": 12844.09,
                "ask": 40,
                "bid": 49
            },
            {
                "price": 12914.75,
                "ask": 36,
                "bid": 88
            },
            {
                "price": 12966.32,
                "ask": 22,
                "bid": 97
            },
            {
                "price": 12746.08,
                "ask": 33,
                "bid": 28
            },
            {
                "price": 12996.56,
                "ask": 45,
                "bid": 13
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcea996e4d42663590",
        "high": 12928.55,
        "open": 12765.57,
        "close": 12750.78,
        "low": 12703.36,
        "priceList": [
            {
                "price": 12887.6,
                "ask": 51,
                "bid": 62
            },
            {
                "price": 12787.86,
                "ask": 70,
                "bid": 86
            },
            {
                "price": 12956.84,
                "ask": 17,
                "bid": 86
            },
            {
                "price": 12806.66,
                "ask": 61,
                "bid": 44
            },
            {
                "price": 12840.92,
                "ask": 10,
                "bid": 26
            },
            {
                "price": 12897.34,
                "ask": 52,
                "bid": 20
            },
            {
                "price": 12944.02,
                "ask": 41,
                "bid": 51
            },
            {
                "price": 12705.19,
                "ask": 52,
                "bid": 83
            },
            {
                "price": 12749.3,
                "ask": 73,
                "bid": 12
            },
            {
                "price": 12938.53,
                "ask": 80,
                "bid": 35
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc1e31a81bd66f92fa",
        "high": 12766.49,
        "open": 12810,
        "close": 12944.39,
        "low": 12891.13,
        "priceList": [
            {
                "price": 12957.75,
                "ask": 90,
                "bid": 26
            },
            {
                "price": 12877.55,
                "ask": 40,
                "bid": 57
            },
            {
                "price": 12820.65,
                "ask": 93,
                "bid": 65
            },
            {
                "price": 12910.72,
                "ask": 79,
                "bid": 24
            },
            {
                "price": 12876.66,
                "ask": 45,
                "bid": 81
            },
            {
                "price": 12709.61,
                "ask": 24,
                "bid": 44
            },
            {
                "price": 12915.19,
                "ask": 24,
                "bid": 27
            },
            {
                "price": 12888.86,
                "ask": 76,
                "bid": 59
            },
            {
                "price": 12870.46,
                "ask": 31,
                "bid": 45
            },
            {
                "price": 12954.48,
                "ask": 1,
                "bid": 6
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcb9b03685f2f74fc2",
        "high": 12766.98,
        "open": 12747.94,
        "close": 12987.86,
        "low": 12971.8,
        "priceList": [
            {
                "price": 12978.38,
                "ask": 14,
                "bid": 13
            },
            {
                "price": 12838.85,
                "ask": 30,
                "bid": 87
            },
            {
                "price": 12886.71,
                "ask": 88,
                "bid": 79
            },
            {
                "price": 12743.04,
                "ask": 17,
                "bid": 55
            },
            {
                "price": 12845.18,
                "ask": 15,
                "bid": 19
            },
            {
                "price": 12874.12,
                "ask": 71,
                "bid": 49
            },
            {
                "price": 12927.66,
                "ask": 16,
                "bid": 95
            },
            {
                "price": 12833.36,
                "ask": 45,
                "bid": 29
            },
            {
                "price": 12752.96,
                "ask": 59,
                "bid": 30
            },
            {
                "price": 12864.6,
                "ask": 91,
                "bid": 7
            },
            {
                "price": 12797.49,
                "ask": 25,
                "bid": 17
            },
            {
                "price": 12959.15,
                "ask": 67,
                "bid": 54
            },
            {
                "price": 12931.44,
                "ask": 45,
                "bid": 35
            },
            {
                "price": 12751.12,
                "ask": 67,
                "bid": 57
            },
            {
                "price": 12721.26,
                "ask": 13,
                "bid": 67
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc481a04d5d7d5eb8d",
        "high": 12760.47,
        "open": 12941.86,
        "close": 12824.36,
        "low": 12742.79,
        "priceList": [
            {
                "price": 12819.75,
                "ask": 93,
                "bid": 46
            },
            {
                "price": 12791.5,
                "ask": 30,
                "bid": 84
            },
            {
                "price": 12783.82,
                "ask": 97,
                "bid": 17
            },
            {
                "price": 12805.83,
                "ask": 64,
                "bid": 88
            },
            {
                "price": 12747.22,
                "ask": 11,
                "bid": 26
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcd68f7ae6298eeb7f",
        "high": 12776.39,
        "open": 12711.99,
        "close": 12844.99,
        "low": 12909.22,
        "priceList": [
            {
                "price": 12975.83,
                "ask": 84,
                "bid": 38
            },
            {
                "price": 12716.4,
                "ask": 45,
                "bid": 33
            },
            {
                "price": 12978.71,
                "ask": 41,
                "bid": 31
            },
            {
                "price": 12870.79,
                "ask": 48,
                "bid": 14
            },
            {
                "price": 12776.58,
                "ask": 20,
                "bid": 92
            },
            {
                "price": 12823.16,
                "ask": 35,
                "bid": 78
            },
            {
                "price": 12911.86,
                "ask": 43,
                "bid": 78
            },
            {
                "price": 12936.51,
                "ask": 79,
                "bid": 70
            },
            {
                "price": 12798.63,
                "ask": 14,
                "bid": 97
            },
            {
                "price": 12906.2,
                "ask": 18,
                "bid": 23
            },
            {
                "price": 12943.3,
                "ask": 75,
                "bid": 59
            },
            {
                "price": 12863.18,
                "ask": 64,
                "bid": 53
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc6de6545a941c0f9b",
        "high": 12708.48,
        "open": 12953.92,
        "close": 12708.53,
        "low": 12757.96,
        "priceList": [
            {
                "price": 12836.39,
                "ask": 42,
                "bid": 85
            },
            {
                "price": 12930.8,
                "ask": 35,
                "bid": 19
            },
            {
                "price": 12980.46,
                "ask": 2,
                "bid": 5
            },
            {
                "price": 12802.18,
                "ask": 90,
                "bid": 71
            },
            {
                "price": 12927.07,
                "ask": 46,
                "bid": 88
            },
            {
                "price": 12824.93,
                "ask": 82,
                "bid": 34
            },
            {
                "price": 12915.64,
                "ask": 88,
                "bid": 3
            },
            {
                "price": 12996.03,
                "ask": 35,
                "bid": 85
            },
            {
                "price": 12937.44,
                "ask": 34,
                "bid": 19
            },
            {
                "price": 12859.28,
                "ask": 40,
                "bid": 4
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcb9fd9349b0a56a08",
        "high": 12844.55,
        "open": 12986.6,
        "close": 12991.39,
        "low": 12849.73,
        "priceList": [
            {
                "price": 12984.66,
                "ask": 79,
                "bid": 39
            },
            {
                "price": 12825.42,
                "ask": 78,
                "bid": 0
            },
            {
                "price": 12741.98,
                "ask": 55,
                "bid": 24
            },
            {
                "price": 12827.72,
                "ask": 30,
                "bid": 28
            },
            {
                "price": 12808.44,
                "ask": 6,
                "bid": 64
            },
            {
                "price": 12867.36,
                "ask": 34,
                "bid": 37
            },
            {
                "price": 12852.07,
                "ask": 30,
                "bid": 49
            },
            {
                "price": 12930.29,
                "ask": 8,
                "bid": 87
            },
            {
                "price": 12703.71,
                "ask": 43,
                "bid": 19
            },
            {
                "price": 12776.69,
                "ask": 13,
                "bid": 68
            },
            {
                "price": 12960.22,
                "ask": 76,
                "bid": 35
            },
            {
                "price": 12892.47,
                "ask": 18,
                "bid": 42
            },
            {
                "price": 12776.35,
                "ask": 0,
                "bid": 71
            },
            {
                "price": 12845.91,
                "ask": 100,
                "bid": 95
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc7a5e0c8d783a442b",
        "high": 12862.78,
        "open": 12993.96,
        "close": 12791.08,
        "low": 12979.44,
        "priceList": [
            {
                "price": 12963.6,
                "ask": 20,
                "bid": 92
            },
            {
                "price": 12726.12,
                "ask": 35,
                "bid": 58
            },
            {
                "price": 12984.27,
                "ask": 15,
                "bid": 18
            },
            {
                "price": 12834.79,
                "ask": 75,
                "bid": 26
            },
            {
                "price": 12700.07,
                "ask": 64,
                "bid": 38
            },
            {
                "price": 12762.65,
                "ask": 80,
                "bid": 40
            },
            {
                "price": 12705.51,
                "ask": 48,
                "bid": 94
            },
            {
                "price": 12899.9,
                "ask": 10,
                "bid": 19
            },
            {
                "price": 12743.24,
                "ask": 97,
                "bid": 8
            },
            {
                "price": 12732.22,
                "ask": 99,
                "bid": 57
            },
            {
                "price": 12782.53,
                "ask": 72,
                "bid": 75
            },
            {
                "price": 12927.45,
                "ask": 70,
                "bid": 53
            },
            {
                "price": 12857.12,
                "ask": 1,
                "bid": 31
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc0d13392c3b48a2f3",
        "high": 12788.01,
        "open": 12801.61,
        "close": 12701.66,
        "low": 12985.1,
        "priceList": [
            {
                "price": 12801.81,
                "ask": 19,
                "bid": 1
            },
            {
                "price": 12903.3,
                "ask": 16,
                "bid": 14
            },
            {
                "price": 12925.28,
                "ask": 34,
                "bid": 39
            },
            {
                "price": 12866.47,
                "ask": 10,
                "bid": 92
            },
            {
                "price": 12864.57,
                "ask": 46,
                "bid": 58
            },
            {
                "price": 12891.46,
                "ask": 66,
                "bid": 20
            },
            {
                "price": 12852.42,
                "ask": 64,
                "bid": 36
            },
            {
                "price": 12973.51,
                "ask": 3,
                "bid": 0
            },
            {
                "price": 12700.9,
                "ask": 86,
                "bid": 65
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc39f35c7f2e665598",
        "high": 12745.78,
        "open": 12961.29,
        "close": 12996.4,
        "low": 12758.64,
        "priceList": [
            {
                "price": 12993.24,
                "ask": 3,
                "bid": 7
            },
            {
                "price": 12969.3,
                "ask": 32,
                "bid": 94
            },
            {
                "price": 12750.32,
                "ask": 63,
                "bid": 7
            },
            {
                "price": 12811.57,
                "ask": 70,
                "bid": 28
            },
            {
                "price": 12862.8,
                "ask": 62,
                "bid": 3
            },
            {
                "price": 12876.38,
                "ask": 15,
                "bid": 80
            },
            {
                "price": 12807.13,
                "ask": 61,
                "bid": 95
            },
            {
                "price": 12810.35,
                "ask": 30,
                "bid": 82
            },
            {
                "price": 12909.6,
                "ask": 78,
                "bid": 37
            },
            {
                "price": 12773.15,
                "ask": 29,
                "bid": 68
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc40b84de72776d8ec",
        "high": 12931.73,
        "open": 12846.52,
        "close": 12784.31,
        "low": 12798.66,
        "priceList": [
            {
                "price": 12905.37,
                "ask": 89,
                "bid": 40
            },
            {
                "price": 12834.73,
                "ask": 38,
                "bid": 2
            },
            {
                "price": 12842.27,
                "ask": 34,
                "bid": 71
            },
            {
                "price": 12848.21,
                "ask": 60,
                "bid": 68
            },
            {
                "price": 12785.6,
                "ask": 57,
                "bid": 40
            },
            {
                "price": 12935.34,
                "ask": 39,
                "bid": 31
            },
            {
                "price": 12882.24,
                "ask": 69,
                "bid": 100
            },
            {
                "price": 12716.26,
                "ask": 62,
                "bid": 4
            },
            {
                "price": 12804.2,
                "ask": 76,
                "bid": 66
            },
            {
                "price": 12974.01,
                "ask": 21,
                "bid": 88
            },
            {
                "price": 12885.49,
                "ask": 38,
                "bid": 85
            },
            {
                "price": 12978.41,
                "ask": 35,
                "bid": 64
            },
            {
                "price": 12873.16,
                "ask": 71,
                "bid": 93
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcbe71887c7cfb13dc",
        "high": 12746.53,
        "open": 12861.77,
        "close": 12840.17,
        "low": 12738.42,
        "priceList": [
            {
                "price": 12700.18,
                "ask": 52,
                "bid": 41
            },
            {
                "price": 12748.2,
                "ask": 30,
                "bid": 40
            },
            {
                "price": 12974.27,
                "ask": 84,
                "bid": 23
            },
            {
                "price": 12727,
                "ask": 22,
                "bid": 40
            },
            {
                "price": 12873.44,
                "ask": 3,
                "bid": 70
            },
            {
                "price": 12987.48,
                "ask": 67,
                "bid": 6
            },
            {
                "price": 12719.34,
                "ask": 8,
                "bid": 55
            },
            {
                "price": 12816.9,
                "ask": 70,
                "bid": 16
            },
            {
                "price": 12747.71,
                "ask": 16,
                "bid": 37
            },
            {
                "price": 12809.57,
                "ask": 5,
                "bid": 41
            },
            {
                "price": 12819.75,
                "ask": 3,
                "bid": 0
            },
            {
                "price": 12857.54,
                "ask": 65,
                "bid": 27
            },
            {
                "price": 12894.04,
                "ask": 45,
                "bid": 70
            },
            {
                "price": 12982.57,
                "ask": 49,
                "bid": 78
            },
            {
                "price": 12946.32,
                "ask": 64,
                "bid": 94
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc49d203057c83e0ac",
        "high": 12851.66,
        "open": 12725.96,
        "close": 12890.45,
        "low": 12732.93,
        "priceList": [
            {
                "price": 12960.48,
                "ask": 45,
                "bid": 11
            },
            {
                "price": 12880.31,
                "ask": 18,
                "bid": 16
            },
            {
                "price": 12768.87,
                "ask": 39,
                "bid": 10
            },
            {
                "price": 12816.58,
                "ask": 51,
                "bid": 35
            },
            {
                "price": 12830.64,
                "ask": 15,
                "bid": 46
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc66c99d3a074ff2ad",
        "high": 12876.84,
        "open": 12991.32,
        "close": 12977.03,
        "low": 12958.76,
        "priceList": [
            {
                "price": 12828.6,
                "ask": 58,
                "bid": 49
            },
            {
                "price": 12898.08,
                "ask": 46,
                "bid": 28
            },
            {
                "price": 12968.57,
                "ask": 65,
                "bid": 83
            },
            {
                "price": 12861.19,
                "ask": 45,
                "bid": 12
            },
            {
                "price": 12911.48,
                "ask": 51,
                "bid": 5
            },
            {
                "price": 12841.25,
                "ask": 10,
                "bid": 80
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcad5191a38f6b0479",
        "high": 12771.84,
        "open": 12899.4,
        "close": 12875.53,
        "low": 12995.98,
        "priceList": [
            {
                "price": 12837.09,
                "ask": 4,
                "bid": 90
            },
            {
                "price": 12932.6,
                "ask": 43,
                "bid": 43
            },
            {
                "price": 12902.83,
                "ask": 67,
                "bid": 3
            },
            {
                "price": 12712.74,
                "ask": 58,
                "bid": 77
            },
            {
                "price": 12756.08,
                "ask": 19,
                "bid": 34
            },
            {
                "price": 12748.85,
                "ask": 65,
                "bid": 69
            },
            {
                "price": 12878.5,
                "ask": 81,
                "bid": 45
            },
            {
                "price": 12711.41,
                "ask": 69,
                "bid": 27
            },
            {
                "price": 12943.15,
                "ask": 76,
                "bid": 60
            },
            {
                "price": 12849.51,
                "ask": 25,
                "bid": 92
            },
            {
                "price": 12938.11,
                "ask": 95,
                "bid": 49
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc412025a8e57c43e7",
        "high": 12715.32,
        "open": 12969.78,
        "close": 12777,
        "low": 12760.88,
        "priceList": [
            {
                "price": 12799.81,
                "ask": 90,
                "bid": 84
            },
            {
                "price": 12907.92,
                "ask": 47,
                "bid": 65
            },
            {
                "price": 12892.6,
                "ask": 70,
                "bid": 71
            },
            {
                "price": 12881.95,
                "ask": 32,
                "bid": 51
            },
            {
                "price": 12885.71,
                "ask": 86,
                "bid": 1
            },
            {
                "price": 12883.73,
                "ask": 5,
                "bid": 67
            },
            {
                "price": 12843.4,
                "ask": 26,
                "bid": 17
            },
            {
                "price": 12921.49,
                "ask": 33,
                "bid": 60
            },
            {
                "price": 12722.68,
                "ask": 36,
                "bid": 52
            },
            {
                "price": 12789.83,
                "ask": 24,
                "bid": 97
            },
            {
                "price": 12944.58,
                "ask": 57,
                "bid": 74
            },
            {
                "price": 12958.46,
                "ask": 10,
                "bid": 99
            },
            {
                "price": 12917.94,
                "ask": 72,
                "bid": 22
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcb4d46047c5d67f0e",
        "high": 12702.54,
        "open": 12712.07,
        "close": 12884,
        "low": 12958.54,
        "priceList": [
            {
                "price": 12998.08,
                "ask": 85,
                "bid": 12
            },
            {
                "price": 12848.97,
                "ask": 68,
                "bid": 0
            },
            {
                "price": 12798.67,
                "ask": 65,
                "bid": 51
            },
            {
                "price": 12754.78,
                "ask": 32,
                "bid": 95
            },
            {
                "price": 12845.06,
                "ask": 74,
                "bid": 88
            },
            {
                "price": 12826.87,
                "ask": 12,
                "bid": 27
            },
            {
                "price": 12706.32,
                "ask": 27,
                "bid": 68
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcb998a5f4ac1ebccd",
        "high": 12775.63,
        "open": 12894.52,
        "close": 12882.83,
        "low": 12891.65,
        "priceList": [
            {
                "price": 12881.54,
                "ask": 70,
                "bid": 46
            },
            {
                "price": 12856.41,
                "ask": 51,
                "bid": 12
            },
            {
                "price": 12737.79,
                "ask": 94,
                "bid": 63
            },
            {
                "price": 12917.32,
                "ask": 65,
                "bid": 50
            },
            {
                "price": 12705.87,
                "ask": 64,
                "bid": 65
            },
            {
                "price": 12705.8,
                "ask": 98,
                "bid": 24
            },
            {
                "price": 12960.98,
                "ask": 62,
                "bid": 42
            },
            {
                "price": 12988.66,
                "ask": 24,
                "bid": 59
            },
            {
                "price": 12791.07,
                "ask": 94,
                "bid": 3
            },
            {
                "price": 12800.06,
                "ask": 17,
                "bid": 8
            },
            {
                "price": 12799.47,
                "ask": 9,
                "bid": 70
            },
            {
                "price": 12791.12,
                "ask": 17,
                "bid": 55
            },
            {
                "price": 12712.8,
                "ask": 43,
                "bid": 69
            },
            {
                "price": 12977.37,
                "ask": 59,
                "bid": 77
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcbc9019a3d9cbee1d",
        "high": 12779.04,
        "open": 12855.73,
        "close": 12989.25,
        "low": 12998.27,
        "priceList": [
            {
                "price": 12831.77,
                "ask": 33,
                "bid": 12
            },
            {
                "price": 12984.02,
                "ask": 87,
                "bid": 15
            },
            {
                "price": 12974.78,
                "ask": 36,
                "bid": 25
            },
            {
                "price": 12977.17,
                "ask": 25,
                "bid": 32
            },
            {
                "price": 12885.74,
                "ask": 26,
                "bid": 43
            },
            {
                "price": 12995.1,
                "ask": 3,
                "bid": 63
            },
            {
                "price": 12886.4,
                "ask": 97,
                "bid": 13
            },
            {
                "price": 12904.36,
                "ask": 3,
                "bid": 12
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc9997e3dd8d9684ec",
        "high": 12809.11,
        "open": 12988.38,
        "close": 12767.79,
        "low": 12717.01,
        "priceList": [
            {
                "price": 12911.94,
                "ask": 24,
                "bid": 85
            },
            {
                "price": 12878.77,
                "ask": 7,
                "bid": 1
            },
            {
                "price": 12856.19,
                "ask": 90,
                "bid": 5
            },
            {
                "price": 12844.94,
                "ask": 70,
                "bid": 63
            },
            {
                "price": 12977.62,
                "ask": 66,
                "bid": 100
            },
            {
                "price": 12722.05,
                "ask": 9,
                "bid": 93
            },
            {
                "price": 12910.93,
                "ask": 65,
                "bid": 17
            },
            {
                "price": 12729.85,
                "ask": 33,
                "bid": 92
            },
            {
                "price": 12758.11,
                "ask": 40,
                "bid": 28
            },
            {
                "price": 12836.63,
                "ask": 32,
                "bid": 69
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc7cf63dd079bb71c1",
        "high": 12866.98,
        "open": 12844.84,
        "close": 12986.64,
        "low": 12957.56,
        "priceList": [
            {
                "price": 12869.31,
                "ask": 61,
                "bid": 79
            },
            {
                "price": 12896.04,
                "ask": 97,
                "bid": 42
            },
            {
                "price": 12866.26,
                "ask": 21,
                "bid": 59
            },
            {
                "price": 12877.62,
                "ask": 3,
                "bid": 57
            },
            {
                "price": 12747.17,
                "ask": 17,
                "bid": 22
            },
            {
                "price": 12915.46,
                "ask": 72,
                "bid": 68
            },
            {
                "price": 12982.64,
                "ask": 61,
                "bid": 37
            },
            {
                "price": 12787.53,
                "ask": 87,
                "bid": 79
            },
            {
                "price": 12814.71,
                "ask": 75,
                "bid": 6
            },
            {
                "price": 12938.13,
                "ask": 32,
                "bid": 95
            },
            {
                "price": 12995.47,
                "ask": 69,
                "bid": 55
            },
            {
                "price": 12729.7,
                "ask": 72,
                "bid": 16
            },
            {
                "price": 12752.87,
                "ask": 31,
                "bid": 64
            },
            {
                "price": 12740.04,
                "ask": 19,
                "bid": 5
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc0baa9689c32a7471",
        "high": 12839.55,
        "open": 12992.66,
        "close": 12859.24,
        "low": 12748.16,
        "priceList": [
            {
                "price": 12869.86,
                "ask": 9,
                "bid": 62
            },
            {
                "price": 12806.23,
                "ask": 83,
                "bid": 28
            },
            {
                "price": 12944,
                "ask": 83,
                "bid": 70
            },
            {
                "price": 12752.47,
                "ask": 32,
                "bid": 36
            },
            {
                "price": 12854.43,
                "ask": 63,
                "bid": 5
            },
            {
                "price": 12765.35,
                "ask": 29,
                "bid": 82
            },
            {
                "price": 12853.94,
                "ask": 16,
                "bid": 64
            },
            {
                "price": 12968.76,
                "ask": 65,
                "bid": 48
            },
            {
                "price": 12831.94,
                "ask": 77,
                "bid": 24
            },
            {
                "price": 12797.71,
                "ask": 57,
                "bid": 9
            },
            {
                "price": 12876.39,
                "ask": 6,
                "bid": 84
            },
            {
                "price": 12871.1,
                "ask": 73,
                "bid": 1
            },
            {
                "price": 12717.51,
                "ask": 44,
                "bid": 9
            },
            {
                "price": 12741.12,
                "ask": 18,
                "bid": 65
            },
            {
                "price": 12853.42,
                "ask": 79,
                "bid": 19
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc2216e0274f73802d",
        "high": 12887.43,
        "open": 12787.34,
        "close": 12825.42,
        "low": 12702.41,
        "priceList": [
            {
                "price": 12725.39,
                "ask": 88,
                "bid": 100
            },
            {
                "price": 12982.49,
                "ask": 54,
                "bid": 56
            },
            {
                "price": 12889.4,
                "ask": 0,
                "bid": 100
            },
            {
                "price": 12813.97,
                "ask": 31,
                "bid": 83
            },
            {
                "price": 12817.08,
                "ask": 39,
                "bid": 36
            },
            {
                "price": 12810.44,
                "ask": 39,
                "bid": 61
            },
            {
                "price": 12917.21,
                "ask": 4,
                "bid": 93
            },
            {
                "price": 12884.27,
                "ask": 14,
                "bid": 59
            },
            {
                "price": 12930.66,
                "ask": 15,
                "bid": 44
            },
            {
                "price": 12805.28,
                "ask": 42,
                "bid": 9
            },
            {
                "price": 12939,
                "ask": 7,
                "bid": 75
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcf0a124704c75b42d",
        "high": 12808.52,
        "open": 12930.06,
        "close": 12894.92,
        "low": 12776.68,
        "priceList": [
            {
                "price": 12943.54,
                "ask": 10,
                "bid": 30
            },
            {
                "price": 12947.14,
                "ask": 36,
                "bid": 47
            },
            {
                "price": 12764.91,
                "ask": 91,
                "bid": 28
            },
            {
                "price": 12707.23,
                "ask": 21,
                "bid": 11
            },
            {
                "price": 12988.01,
                "ask": 26,
                "bid": 48
            },
            {
                "price": 12802.68,
                "ask": 14,
                "bid": 61
            },
            {
                "price": 12782.71,
                "ask": 25,
                "bid": 92
            },
            {
                "price": 12731.4,
                "ask": 37,
                "bid": 84
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcb9450135857f3df5",
        "high": 12740.17,
        "open": 12735.92,
        "close": 12921.17,
        "low": 12896.78,
        "priceList": [
            {
                "price": 12728.42,
                "ask": 15,
                "bid": 69
            },
            {
                "price": 12938.97,
                "ask": 62,
                "bid": 7
            },
            {
                "price": 12943.45,
                "ask": 59,
                "bid": 44
            },
            {
                "price": 12703.7,
                "ask": 98,
                "bid": 82
            },
            {
                "price": 12747.76,
                "ask": 22,
                "bid": 46
            },
            {
                "price": 12779.87,
                "ask": 81,
                "bid": 63
            },
            {
                "price": 12743.78,
                "ask": 67,
                "bid": 100
            },
            {
                "price": 12992.8,
                "ask": 95,
                "bid": 49
            },
            {
                "price": 12844.65,
                "ask": 27,
                "bid": 22
            },
            {
                "price": 12985.53,
                "ask": 28,
                "bid": 54
            },
            {
                "price": 12717.62,
                "ask": 54,
                "bid": 26
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcc7dc34e03a350242",
        "high": 12720.34,
        "open": 12824.16,
        "close": 12938.19,
        "low": 12938.66,
        "priceList": [
            {
                "price": 12823.13,
                "ask": 95,
                "bid": 97
            },
            {
                "price": 12865.46,
                "ask": 46,
                "bid": 66
            },
            {
                "price": 12803.49,
                "ask": 45,
                "bid": 31
            },
            {
                "price": 12968.37,
                "ask": 73,
                "bid": 15
            },
            {
                "price": 12758.52,
                "ask": 81,
                "bid": 72
            },
            {
                "price": 12994.78,
                "ask": 16,
                "bid": 48
            },
            {
                "price": 12770.75,
                "ask": 90,
                "bid": 32
            },
            {
                "price": 12944.25,
                "ask": 81,
                "bid": 0
            },
            {
                "price": 12991.12,
                "ask": 15,
                "bid": 99
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc19b510166b3e8ca4",
        "high": 12974.29,
        "open": 12824.43,
        "close": 12870.89,
        "low": 12922.67,
        "priceList": [
            {
                "price": 12842.42,
                "ask": 6,
                "bid": 75
            },
            {
                "price": 12721.23,
                "ask": 42,
                "bid": 8
            },
            {
                "price": 12778.68,
                "ask": 30,
                "bid": 38
            },
            {
                "price": 12873.21,
                "ask": 88,
                "bid": 24
            },
            {
                "price": 12931.74,
                "ask": 16,
                "bid": 72
            },
            {
                "price": 12965.93,
                "ask": 9,
                "bid": 69
            },
            {
                "price": 12808.85,
                "ask": 25,
                "bid": 32
            },
            {
                "price": 12760.64,
                "ask": 39,
                "bid": 47
            },
            {
                "price": 12968.41,
                "ask": 93,
                "bid": 4
            },
            {
                "price": 12912.88,
                "ask": 39,
                "bid": 56
            },
            {
                "price": 12997.83,
                "ask": 22,
                "bid": 43
            },
            {
                "price": 12771.67,
                "ask": 90,
                "bid": 69
            },
            {
                "price": 12829.19,
                "ask": 13,
                "bid": 82
            },
            {
                "price": 12743.65,
                "ask": 6,
                "bid": 88
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcb7b0095ecbe1b312",
        "high": 12893.27,
        "open": 12820.16,
        "close": 12840.19,
        "low": 12899.28,
        "priceList": [
            {
                "price": 12944.34,
                "ask": 20,
                "bid": 8
            },
            {
                "price": 12953.3,
                "ask": 61,
                "bid": 22
            },
            {
                "price": 12847.48,
                "ask": 89,
                "bid": 27
            },
            {
                "price": 12806.26,
                "ask": 98,
                "bid": 67
            },
            {
                "price": 12919.54,
                "ask": 85,
                "bid": 29
            },
            {
                "price": 12854.48,
                "ask": 34,
                "bid": 42
            },
            {
                "price": 12721.98,
                "ask": 97,
                "bid": 43
            },
            {
                "price": 12929.66,
                "ask": 51,
                "bid": 86
            },
            {
                "price": 12867.82,
                "ask": 54,
                "bid": 36
            },
            {
                "price": 12952.31,
                "ask": 73,
                "bid": 49
            },
            {
                "price": 12954.58,
                "ask": 57,
                "bid": 7
            },
            {
                "price": 12886.68,
                "ask": 76,
                "bid": 56
            },
            {
                "price": 12851.67,
                "ask": 2,
                "bid": 19
            },
            {
                "price": 12710.91,
                "ask": 32,
                "bid": 2
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc0e57489dd5ba997b",
        "high": 12994.11,
        "open": 12720.11,
        "close": 12940.69,
        "low": 12804.35,
        "priceList": [
            {
                "price": 12826.36,
                "ask": 84,
                "bid": 66
            },
            {
                "price": 12829.02,
                "ask": 54,
                "bid": 66
            },
            {
                "price": 12929.18,
                "ask": 18,
                "bid": 31
            },
            {
                "price": 12954.3,
                "ask": 25,
                "bid": 60
            },
            {
                "price": 12742.17,
                "ask": 93,
                "bid": 6
            },
            {
                "price": 12853.12,
                "ask": 35,
                "bid": 10
            },
            {
                "price": 12911.18,
                "ask": 77,
                "bid": 70
            },
            {
                "price": 12830.65,
                "ask": 60,
                "bid": 100
            },
            {
                "price": 12888.22,
                "ask": 85,
                "bid": 36
            },
            {
                "price": 12831.65,
                "ask": 62,
                "bid": 20
            },
            {
                "price": 12904.89,
                "ask": 80,
                "bid": 3
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4cace679e4e6db4b",
        "high": 12938.92,
        "open": 12809.9,
        "close": 12863.38,
        "low": 12709.06,
        "priceList": [
            {
                "price": 12767.13,
                "ask": 35,
                "bid": 21
            },
            {
                "price": 12853.78,
                "ask": 44,
                "bid": 10
            },
            {
                "price": 12937.54,
                "ask": 5,
                "bid": 72
            },
            {
                "price": 12838.13,
                "ask": 21,
                "bid": 94
            },
            {
                "price": 12899.48,
                "ask": 99,
                "bid": 69
            },
            {
                "price": 12923.4,
                "ask": 50,
                "bid": 96
            },
            {
                "price": 12901.68,
                "ask": 8,
                "bid": 53
            },
            {
                "price": 12924.32,
                "ask": 67,
                "bid": 7
            },
            {
                "price": 12806.56,
                "ask": 0,
                "bid": 0
            },
            {
                "price": 12905.43,
                "ask": 81,
                "bid": 83
            },
            {
                "price": 12817.56,
                "ask": 53,
                "bid": 83
            },
            {
                "price": 12889.93,
                "ask": 5,
                "bid": 32
            },
            {
                "price": 12748.73,
                "ask": 7,
                "bid": 5
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dce1d1dfd44a26a88c",
        "high": 12758.75,
        "open": 12726.15,
        "close": 12760.77,
        "low": 12733.36,
        "priceList": [
            {
                "price": 12852.92,
                "ask": 46,
                "bid": 68
            },
            {
                "price": 12837.12,
                "ask": 80,
                "bid": 49
            },
            {
                "price": 12869.62,
                "ask": 49,
                "bid": 66
            },
            {
                "price": 12937.5,
                "ask": 28,
                "bid": 5
            },
            {
                "price": 12861.22,
                "ask": 33,
                "bid": 45
            },
            {
                "price": 12970.14,
                "ask": 3,
                "bid": 22
            },
            {
                "price": 12998.71,
                "ask": 33,
                "bid": 64
            },
            {
                "price": 12957.03,
                "ask": 6,
                "bid": 96
            },
            {
                "price": 12922.51,
                "ask": 30,
                "bid": 52
            },
            {
                "price": 12780.34,
                "ask": 59,
                "bid": 18
            },
            {
                "price": 12921.9,
                "ask": 62,
                "bid": 87
            },
            {
                "price": 12909.96,
                "ask": 81,
                "bid": 88
            },
            {
                "price": 12707.07,
                "ask": 74,
                "bid": 83
            },
            {
                "price": 12755.04,
                "ask": 100,
                "bid": 95
            },
            {
                "price": 12899.66,
                "ask": 55,
                "bid": 0
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc7930a9dce066b55b",
        "high": 12903.47,
        "open": 12921.14,
        "close": 12872.72,
        "low": 12772.7,
        "priceList": [
            {
                "price": 12762.03,
                "ask": 79,
                "bid": 60
            },
            {
                "price": 12805.22,
                "ask": 23,
                "bid": 76
            },
            {
                "price": 12885.4,
                "ask": 22,
                "bid": 21
            },
            {
                "price": 12717.7,
                "ask": 59,
                "bid": 13
            },
            {
                "price": 12935.72,
                "ask": 44,
                "bid": 4
            },
            {
                "price": 12864.76,
                "ask": 28,
                "bid": 12
            },
            {
                "price": 12843.62,
                "ask": 64,
                "bid": 52
            },
            {
                "price": 12787.5,
                "ask": 12,
                "bid": 72
            },
            {
                "price": 12721.35,
                "ask": 90,
                "bid": 55
            },
            {
                "price": 12817.12,
                "ask": 36,
                "bid": 53
            },
            {
                "price": 12723.96,
                "ask": 59,
                "bid": 49
            },
            {
                "price": 12947.54,
                "ask": 69,
                "bid": 92
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc4b55663d9fa24933",
        "high": 12912.77,
        "open": 12718.72,
        "close": 12719.06,
        "low": 12827.31,
        "priceList": [
            {
                "price": 12799.71,
                "ask": 16,
                "bid": 23
            },
            {
                "price": 12738.4,
                "ask": 35,
                "bid": 53
            },
            {
                "price": 12832.84,
                "ask": 87,
                "bid": 97
            },
            {
                "price": 12901.35,
                "ask": 93,
                "bid": 44
            },
            {
                "price": 12798.07,
                "ask": 0,
                "bid": 47
            },
            {
                "price": 12871.72,
                "ask": 71,
                "bid": 63
            },
            {
                "price": 12792.69,
                "ask": 51,
                "bid": 47
            },
            {
                "price": 12822.7,
                "ask": 40,
                "bid": 34
            },
            {
                "price": 12839.17,
                "ask": 14,
                "bid": 78
            },
            {
                "price": 12712.32,
                "ask": 67,
                "bid": 100
            },
            {
                "price": 12709.32,
                "ask": 1,
                "bid": 41
            },
            {
                "price": 12975.14,
                "ask": 99,
                "bid": 47
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc5b7ae85db7bcb68f",
        "high": 12783.87,
        "open": 12801.01,
        "close": 12863.99,
        "low": 12779.53,
        "priceList": [
            {
                "price": 12932.46,
                "ask": 37,
                "bid": 54
            },
            {
                "price": 12835.94,
                "ask": 21,
                "bid": 23
            },
            {
                "price": 12942.65,
                "ask": 31,
                "bid": 51
            },
            {
                "price": 12747.51,
                "ask": 0,
                "bid": 66
            },
            {
                "price": 12768.52,
                "ask": 60,
                "bid": 1
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc28eaad834c803cd8",
        "high": 12962.14,
        "open": 12995.15,
        "close": 12710.19,
        "low": 12749.26,
        "priceList": [
            {
                "price": 12833.34,
                "ask": 49,
                "bid": 79
            },
            {
                "price": 12883.06,
                "ask": 67,
                "bid": 73
            },
            {
                "price": 12978.85,
                "ask": 70,
                "bid": 77
            },
            {
                "price": 12726.6,
                "ask": 70,
                "bid": 91
            },
            {
                "price": 12715.44,
                "ask": 59,
                "bid": 25
            },
            {
                "price": 12919.66,
                "ask": 28,
                "bid": 37
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dce55a3d2668c38aed",
        "high": 12762.91,
        "open": 12725.79,
        "close": 12747.87,
        "low": 12884.9,
        "priceList": [
            {
                "price": 12771.51,
                "ask": 60,
                "bid": 7
            },
            {
                "price": 12736.95,
                "ask": 87,
                "bid": 55
            },
            {
                "price": 12771.77,
                "ask": 80,
                "bid": 0
            },
            {
                "price": 12793.02,
                "ask": 1,
                "bid": 42
            },
            {
                "price": 12793.51,
                "ask": 93,
                "bid": 8
            },
            {
                "price": 12879.02,
                "ask": 57,
                "bid": 7
            },
            {
                "price": 12984.11,
                "ask": 8,
                "bid": 96
            },
            {
                "price": 12836.47,
                "ask": 53,
                "bid": 51
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcf183d2873c880eae",
        "high": 12753.63,
        "open": 12846.04,
        "close": 12769.14,
        "low": 12779.95,
        "priceList": [
            {
                "price": 12960.33,
                "ask": 16,
                "bid": 37
            },
            {
                "price": 12915.44,
                "ask": 90,
                "bid": 86
            },
            {
                "price": 12785.05,
                "ask": 20,
                "bid": 59
            },
            {
                "price": 12749.57,
                "ask": 61,
                "bid": 42
            },
            {
                "price": 12796.23,
                "ask": 78,
                "bid": 100
            },
            {
                "price": 12743.78,
                "ask": 85,
                "bid": 22
            },
            {
                "price": 12879.89,
                "ask": 67,
                "bid": 58
            },
            {
                "price": 12995.21,
                "ask": 87,
                "bid": 32
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc8f3886764f5b0b43",
        "high": 12981.27,
        "open": 12886.69,
        "close": 12708.71,
        "low": 12813.6,
        "priceList": [
            {
                "price": 12991.39,
                "ask": 61,
                "bid": 97
            },
            {
                "price": 12724.33,
                "ask": 16,
                "bid": 79
            },
            {
                "price": 12761.75,
                "ask": 22,
                "bid": 52
            },
            {
                "price": 12991,
                "ask": 82,
                "bid": 42
            },
            {
                "price": 12862.7,
                "ask": 94,
                "bid": 32
            },
            {
                "price": 12849.52,
                "ask": 26,
                "bid": 18
            },
            {
                "price": 12921.73,
                "ask": 40,
                "bid": 49
            },
            {
                "price": 12846.56,
                "ask": 9,
                "bid": 25
            },
            {
                "price": 12919.14,
                "ask": 2,
                "bid": 18
            },
            {
                "price": 12721.95,
                "ask": 89,
                "bid": 77
            },
            {
                "price": 12737.7,
                "ask": 7,
                "bid": 95
            },
            {
                "price": 12912.38,
                "ask": 14,
                "bid": 77
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc888620da7ecbff1f",
        "high": 12850.89,
        "open": 12793.46,
        "close": 12719.78,
        "low": 12823.44,
        "priceList": [
            {
                "price": 12814.27,
                "ask": 79,
                "bid": 78
            },
            {
                "price": 12968.82,
                "ask": 64,
                "bid": 30
            },
            {
                "price": 12878.61,
                "ask": 83,
                "bid": 96
            },
            {
                "price": 12736.45,
                "ask": 78,
                "bid": 39
            },
            {
                "price": 12795.66,
                "ask": 96,
                "bid": 79
            },
            {
                "price": 12921.42,
                "ask": 58,
                "bid": 76
            },
            {
                "price": 12756.68,
                "ask": 85,
                "bid": 12
            },
            {
                "price": 12888.32,
                "ask": 100,
                "bid": 71
            },
            {
                "price": 12943.5,
                "ask": 52,
                "bid": 15
            },
            {
                "price": 12994.76,
                "ask": 60,
                "bid": 99
            },
            {
                "price": 12772.47,
                "ask": 57,
                "bid": 56
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4ff26881e99b3923",
        "high": 12969.94,
        "open": 12778.03,
        "close": 12853.24,
        "low": 12820.23,
        "priceList": [
            {
                "price": 12928.91,
                "ask": 40,
                "bid": 1
            },
            {
                "price": 12837.72,
                "ask": 28,
                "bid": 100
            },
            {
                "price": 12812.17,
                "ask": 15,
                "bid": 79
            },
            {
                "price": 12920.24,
                "ask": 32,
                "bid": 5
            },
            {
                "price": 12924.19,
                "ask": 20,
                "bid": 12
            },
            {
                "price": 12954.43,
                "ask": 39,
                "bid": 40
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc9e41655c60dab01c",
        "high": 12900.29,
        "open": 12829.19,
        "close": 12734.82,
        "low": 12886.99,
        "priceList": [
            {
                "price": 12712.8,
                "ask": 25,
                "bid": 93
            },
            {
                "price": 12838.44,
                "ask": 100,
                "bid": 30
            },
            {
                "price": 12765.16,
                "ask": 38,
                "bid": 4
            },
            {
                "price": 12948.98,
                "ask": 26,
                "bid": 50
            },
            {
                "price": 12995.09,
                "ask": 21,
                "bid": 69
            },
            {
                "price": 12802.57,
                "ask": 15,
                "bid": 16
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc97a469ff64d8c22c",
        "high": 12736.61,
        "open": 12837.34,
        "close": 12947.47,
        "low": 12750.46,
        "priceList": [
            {
                "price": 12736.72,
                "ask": 44,
                "bid": 43
            },
            {
                "price": 12797.97,
                "ask": 66,
                "bid": 63
            },
            {
                "price": 12766.09,
                "ask": 78,
                "bid": 74
            },
            {
                "price": 12984.94,
                "ask": 53,
                "bid": 91
            },
            {
                "price": 12978.75,
                "ask": 70,
                "bid": 61
            },
            {
                "price": 12718.63,
                "ask": 52,
                "bid": 5
            },
            {
                "price": 12758.44,
                "ask": 4,
                "bid": 18
            },
            {
                "price": 12857.92,
                "ask": 0,
                "bid": 15
            },
            {
                "price": 12763.53,
                "ask": 57,
                "bid": 2
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc1f1cbc4bc0fed8a9",
        "high": 12877.63,
        "open": 12894.53,
        "close": 12801.1,
        "low": 12884.02,
        "priceList": [
            {
                "price": 12815.99,
                "ask": 0,
                "bid": 42
            },
            {
                "price": 12798.98,
                "ask": 27,
                "bid": 37
            },
            {
                "price": 12790.68,
                "ask": 35,
                "bid": 39
            },
            {
                "price": 12844.62,
                "ask": 23,
                "bid": 23
            },
            {
                "price": 12997.19,
                "ask": 44,
                "bid": 53
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcc7ceccf45741693b",
        "high": 12954.44,
        "open": 12763.23,
        "close": 12775.21,
        "low": 12720.67,
        "priceList": [
            {
                "price": 12880.35,
                "ask": 89,
                "bid": 0
            },
            {
                "price": 12959.91,
                "ask": 42,
                "bid": 66
            },
            {
                "price": 12926.98,
                "ask": 100,
                "bid": 3
            },
            {
                "price": 12885.76,
                "ask": 1,
                "bid": 6
            },
            {
                "price": 12927.02,
                "ask": 51,
                "bid": 64
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dca4b2a75d9a9879e6",
        "high": 12840.81,
        "open": 12722.37,
        "close": 12840.76,
        "low": 12976.95,
        "priceList": [
            {
                "price": 12948.93,
                "ask": 53,
                "bid": 45
            },
            {
                "price": 12953.48,
                "ask": 70,
                "bid": 9
            },
            {
                "price": 12715.51,
                "ask": 47,
                "bid": 25
            },
            {
                "price": 12996.17,
                "ask": 75,
                "bid": 69
            },
            {
                "price": 12762.33,
                "ask": 6,
                "bid": 7
            },
            {
                "price": 12788.13,
                "ask": 14,
                "bid": 2
            },
            {
                "price": 12875.84,
                "ask": 68,
                "bid": 79
            },
            {
                "price": 12809.24,
                "ask": 55,
                "bid": 80
            },
            {
                "price": 12869.57,
                "ask": 42,
                "bid": 80
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcaf4c87be06388cf6",
        "high": 12711.93,
        "open": 12772.42,
        "close": 12940.54,
        "low": 12850.08,
        "priceList": [
            {
                "price": 12830.57,
                "ask": 9,
                "bid": 64
            },
            {
                "price": 12742.95,
                "ask": 16,
                "bid": 78
            },
            {
                "price": 12774.75,
                "ask": 23,
                "bid": 89
            },
            {
                "price": 12874.43,
                "ask": 7,
                "bid": 18
            },
            {
                "price": 12963.01,
                "ask": 84,
                "bid": 54
            },
            {
                "price": 12904.25,
                "ask": 72,
                "bid": 27
            },
            {
                "price": 12909.25,
                "ask": 10,
                "bid": 85
            },
            {
                "price": 12729.22,
                "ask": 66,
                "bid": 8
            },
            {
                "price": 12707,
                "ask": 22,
                "bid": 19
            },
            {
                "price": 12773.4,
                "ask": 2,
                "bid": 53
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc264f4236b05f6aae",
        "high": 12961,
        "open": 12786.35,
        "close": 12870.65,
        "low": 12801.74,
        "priceList": [
            {
                "price": 12985.98,
                "ask": 14,
                "bid": 11
            },
            {
                "price": 12783.26,
                "ask": 39,
                "bid": 29
            },
            {
                "price": 12807.39,
                "ask": 99,
                "bid": 77
            },
            {
                "price": 12736.5,
                "ask": 70,
                "bid": 49
            },
            {
                "price": 12903.7,
                "ask": 86,
                "bid": 27
            },
            {
                "price": 12846.42,
                "ask": 81,
                "bid": 96
            },
            {
                "price": 12788.52,
                "ask": 51,
                "bid": 47
            },
            {
                "price": 12940.57,
                "ask": 80,
                "bid": 58
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc709ee155bc3083ed",
        "high": 12838.28,
        "open": 12744.17,
        "close": 12878.06,
        "low": 12878.4,
        "priceList": [
            {
                "price": 12708.83,
                "ask": 59,
                "bid": 94
            },
            {
                "price": 12947.46,
                "ask": 20,
                "bid": 29
            },
            {
                "price": 12959.36,
                "ask": 76,
                "bid": 66
            },
            {
                "price": 12964.32,
                "ask": 69,
                "bid": 25
            },
            {
                "price": 12852.17,
                "ask": 42,
                "bid": 8
            },
            {
                "price": 12951.61,
                "ask": 90,
                "bid": 51
            },
            {
                "price": 12836.01,
                "ask": 60,
                "bid": 90
            },
            {
                "price": 12807.57,
                "ask": 71,
                "bid": 85
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc288b389ae9defe38",
        "high": 12935.64,
        "open": 12830.65,
        "close": 12736.42,
        "low": 12836.39,
        "priceList": [
            {
                "price": 12826.1,
                "ask": 63,
                "bid": 75
            },
            {
                "price": 12733.3,
                "ask": 57,
                "bid": 64
            },
            {
                "price": 12897.78,
                "ask": 80,
                "bid": 88
            },
            {
                "price": 12987.62,
                "ask": 46,
                "bid": 92
            },
            {
                "price": 12913.93,
                "ask": 30,
                "bid": 26
            },
            {
                "price": 12884.17,
                "ask": 18,
                "bid": 59
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc9da2a457555875bb",
        "high": 12918.84,
        "open": 12729.66,
        "close": 12905.34,
        "low": 12947.06,
        "priceList": [
            {
                "price": 12836.12,
                "ask": 68,
                "bid": 18
            },
            {
                "price": 12826.41,
                "ask": 95,
                "bid": 57
            },
            {
                "price": 12765.36,
                "ask": 78,
                "bid": 78
            },
            {
                "price": 12795.83,
                "ask": 52,
                "bid": 100
            },
            {
                "price": 12814.13,
                "ask": 39,
                "bid": 3
            },
            {
                "price": 12879.82,
                "ask": 62,
                "bid": 57
            },
            {
                "price": 12930,
                "ask": 91,
                "bid": 68
            },
            {
                "price": 12734.66,
                "ask": 68,
                "bid": 100
            },
            {
                "price": 12859.48,
                "ask": 66,
                "bid": 87
            },
            {
                "price": 12902.56,
                "ask": 78,
                "bid": 97
            },
            {
                "price": 12970.88,
                "ask": 98,
                "bid": 75
            },
            {
                "price": 12994.96,
                "ask": 80,
                "bid": 4
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc617db131f1d7d177",
        "high": 12723.59,
        "open": 12864.69,
        "close": 12761.36,
        "low": 12830.53,
        "priceList": [
            {
                "price": 12878.4,
                "ask": 22,
                "bid": 36
            },
            {
                "price": 12786.7,
                "ask": 53,
                "bid": 12
            },
            {
                "price": 12864.94,
                "ask": 64,
                "bid": 12
            },
            {
                "price": 12733,
                "ask": 11,
                "bid": 45
            },
            {
                "price": 12782.83,
                "ask": 13,
                "bid": 20
            },
            {
                "price": 12706.7,
                "ask": 100,
                "bid": 88
            },
            {
                "price": 12817.59,
                "ask": 41,
                "bid": 74
            },
            {
                "price": 12930.38,
                "ask": 39,
                "bid": 59
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcf4283790c996dc8b",
        "high": 12719.91,
        "open": 12882.38,
        "close": 12927.99,
        "low": 12861.43,
        "priceList": [
            {
                "price": 12983.63,
                "ask": 82,
                "bid": 38
            },
            {
                "price": 12751.25,
                "ask": 83,
                "bid": 19
            },
            {
                "price": 12787.33,
                "ask": 48,
                "bid": 17
            },
            {
                "price": 12953.93,
                "ask": 84,
                "bid": 21
            },
            {
                "price": 12989.87,
                "ask": 43,
                "bid": 12
            },
            {
                "price": 12743.91,
                "ask": 55,
                "bid": 77
            },
            {
                "price": 12903.86,
                "ask": 26,
                "bid": 78
            },
            {
                "price": 12863.54,
                "ask": 49,
                "bid": 4
            },
            {
                "price": 12886.2,
                "ask": 99,
                "bid": 6
            },
            {
                "price": 12847.71,
                "ask": 59,
                "bid": 89
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcbae54dfa7577d820",
        "high": 12939.6,
        "open": 12783.85,
        "close": 12808.62,
        "low": 12867.76,
        "priceList": [
            {
                "price": 12937.23,
                "ask": 0,
                "bid": 18
            },
            {
                "price": 12853.35,
                "ask": 13,
                "bid": 83
            },
            {
                "price": 12719.92,
                "ask": 43,
                "bid": 22
            },
            {
                "price": 12804.37,
                "ask": 32,
                "bid": 81
            },
            {
                "price": 12860.64,
                "ask": 97,
                "bid": 87
            },
            {
                "price": 12881.97,
                "ask": 57,
                "bid": 43
            },
            {
                "price": 12877.77,
                "ask": 74,
                "bid": 31
            },
            {
                "price": 12927.13,
                "ask": 30,
                "bid": 28
            },
            {
                "price": 12791.14,
                "ask": 92,
                "bid": 62
            },
            {
                "price": 12997.56,
                "ask": 82,
                "bid": 25
            },
            {
                "price": 12977.69,
                "ask": 88,
                "bid": 77
            },
            {
                "price": 12732.33,
                "ask": 95,
                "bid": 21
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc676d5b2314153ab8",
        "high": 12708.05,
        "open": 12788.27,
        "close": 12748.35,
        "low": 12800.96,
        "priceList": [
            {
                "price": 12859.51,
                "ask": 66,
                "bid": 51
            },
            {
                "price": 12818.85,
                "ask": 57,
                "bid": 25
            },
            {
                "price": 12849.5,
                "ask": 28,
                "bid": 17
            },
            {
                "price": 12743.67,
                "ask": 70,
                "bid": 97
            },
            {
                "price": 12823.31,
                "ask": 6,
                "bid": 9
            },
            {
                "price": 12762.71,
                "ask": 19,
                "bid": 40
            },
            {
                "price": 12790.72,
                "ask": 44,
                "bid": 85
            },
            {
                "price": 12701.41,
                "ask": 48,
                "bid": 81
            },
            {
                "price": 12831.59,
                "ask": 52,
                "bid": 47
            },
            {
                "price": 12921.86,
                "ask": 33,
                "bid": 78
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcc7a1166d339baa89",
        "high": 12822.75,
        "open": 12713.69,
        "close": 12793.55,
        "low": 12771.43,
        "priceList": [
            {
                "price": 12912.76,
                "ask": 56,
                "bid": 23
            },
            {
                "price": 12824.14,
                "ask": 5,
                "bid": 30
            },
            {
                "price": 12819.83,
                "ask": 46,
                "bid": 57
            },
            {
                "price": 12819.51,
                "ask": 49,
                "bid": 97
            },
            {
                "price": 12942.4,
                "ask": 66,
                "bid": 47
            },
            {
                "price": 12802.77,
                "ask": 23,
                "bid": 45
            },
            {
                "price": 12776.8,
                "ask": 83,
                "bid": 67
            },
            {
                "price": 12910.61,
                "ask": 11,
                "bid": 11
            },
            {
                "price": 12948.52,
                "ask": 25,
                "bid": 97
            },
            {
                "price": 12806.08,
                "ask": 65,
                "bid": 50
            },
            {
                "price": 12704.1,
                "ask": 57,
                "bid": 78
            },
            {
                "price": 12809.01,
                "ask": 35,
                "bid": 75
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc49af1469151eb6d9",
        "high": 12860.43,
        "open": 12798.74,
        "close": 12937.99,
        "low": 12824.67,
        "priceList": [
            {
                "price": 12797.25,
                "ask": 33,
                "bid": 95
            },
            {
                "price": 12815.61,
                "ask": 2,
                "bid": 47
            },
            {
                "price": 12961.74,
                "ask": 76,
                "bid": 35
            },
            {
                "price": 12798.47,
                "ask": 48,
                "bid": 57
            },
            {
                "price": 12748.54,
                "ask": 25,
                "bid": 9
            },
            {
                "price": 12853.31,
                "ask": 96,
                "bid": 0
            },
            {
                "price": 12848.96,
                "ask": 33,
                "bid": 98
            },
            {
                "price": 12767.42,
                "ask": 77,
                "bid": 43
            },
            {
                "price": 12913.51,
                "ask": 53,
                "bid": 71
            },
            {
                "price": 12844.13,
                "ask": 50,
                "bid": 90
            },
            {
                "price": 12768.04,
                "ask": 86,
                "bid": 17
            },
            {
                "price": 12736.27,
                "ask": 39,
                "bid": 10
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc5d7044a2283c7c21",
        "high": 12859.76,
        "open": 12780.95,
        "close": 12798.63,
        "low": 12865.9,
        "priceList": [
            {
                "price": 12991.76,
                "ask": 39,
                "bid": 41
            },
            {
                "price": 12784.06,
                "ask": 14,
                "bid": 12
            },
            {
                "price": 12848.66,
                "ask": 51,
                "bid": 58
            },
            {
                "price": 12976.19,
                "ask": 37,
                "bid": 83
            },
            {
                "price": 12923.27,
                "ask": 14,
                "bid": 18
            },
            {
                "price": 12968.76,
                "ask": 89,
                "bid": 2
            },
            {
                "price": 12897.98,
                "ask": 60,
                "bid": 10
            },
            {
                "price": 12993.49,
                "ask": 46,
                "bid": 11
            },
            {
                "price": 12929.28,
                "ask": 54,
                "bid": 3
            },
            {
                "price": 12743.6,
                "ask": 65,
                "bid": 65
            },
            {
                "price": 12938.62,
                "ask": 37,
                "bid": 6
            },
            {
                "price": 12709.8,
                "ask": 18,
                "bid": 13
            },
            {
                "price": 12992.32,
                "ask": 81,
                "bid": 84
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc7a7cb0b0a0d8b4c8",
        "high": 12868.87,
        "open": 12973.9,
        "close": 12755.66,
        "low": 12983.69,
        "priceList": [
            {
                "price": 12804.79,
                "ask": 39,
                "bid": 15
            },
            {
                "price": 12890.54,
                "ask": 29,
                "bid": 87
            },
            {
                "price": 12795.36,
                "ask": 69,
                "bid": 51
            },
            {
                "price": 12774.32,
                "ask": 16,
                "bid": 17
            },
            {
                "price": 12997.53,
                "ask": 86,
                "bid": 60
            },
            {
                "price": 12846.07,
                "ask": 61,
                "bid": 82
            },
            {
                "price": 12997.73,
                "ask": 10,
                "bid": 38
            },
            {
                "price": 12847.79,
                "ask": 68,
                "bid": 72
            },
            {
                "price": 12954.47,
                "ask": 61,
                "bid": 43
            },
            {
                "price": 12709.12,
                "ask": 82,
                "bid": 46
            },
            {
                "price": 12815.78,
                "ask": 52,
                "bid": 84
            },
            {
                "price": 12972.66,
                "ask": 66,
                "bid": 58
            },
            {
                "price": 12783.38,
                "ask": 45,
                "bid": 62
            },
            {
                "price": 12790.61,
                "ask": 89,
                "bid": 15
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc414d930d5f2fe7fb",
        "high": 12734.36,
        "open": 12823.99,
        "close": 12821.31,
        "low": 12924.62,
        "priceList": [
            {
                "price": 12945.44,
                "ask": 64,
                "bid": 69
            },
            {
                "price": 12870.87,
                "ask": 93,
                "bid": 5
            },
            {
                "price": 12953.9,
                "ask": 20,
                "bid": 63
            },
            {
                "price": 12999.92,
                "ask": 75,
                "bid": 85
            },
            {
                "price": 12968.16,
                "ask": 66,
                "bid": 29
            },
            {
                "price": 12958.74,
                "ask": 55,
                "bid": 46
            },
            {
                "price": 12931.91,
                "ask": 26,
                "bid": 92
            },
            {
                "price": 12797.37,
                "ask": 43,
                "bid": 16
            },
            {
                "price": 12710.4,
                "ask": 100,
                "bid": 48
            },
            {
                "price": 12736.67,
                "ask": 96,
                "bid": 61
            },
            {
                "price": 12782.93,
                "ask": 9,
                "bid": 60
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc7876f1b727a5a622",
        "high": 12883.72,
        "open": 12719.52,
        "close": 12869.62,
        "low": 12986.36,
        "priceList": [
            {
                "price": 12906.08,
                "ask": 16,
                "bid": 45
            },
            {
                "price": 12873.18,
                "ask": 68,
                "bid": 27
            },
            {
                "price": 12865.38,
                "ask": 11,
                "bid": 8
            },
            {
                "price": 12976.88,
                "ask": 80,
                "bid": 87
            },
            {
                "price": 12750.37,
                "ask": 13,
                "bid": 90
            },
            {
                "price": 12826.45,
                "ask": 3,
                "bid": 48
            },
            {
                "price": 12860.92,
                "ask": 29,
                "bid": 17
            },
            {
                "price": 12737.39,
                "ask": 84,
                "bid": 56
            },
            {
                "price": 12918.1,
                "ask": 100,
                "bid": 82
            },
            {
                "price": 12824.25,
                "ask": 19,
                "bid": 83
            },
            {
                "price": 12988.15,
                "ask": 73,
                "bid": 34
            },
            {
                "price": 12846.18,
                "ask": 88,
                "bid": 7
            },
            {
                "price": 12947.12,
                "ask": 85,
                "bid": 85
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc8c117f8a6494229b",
        "high": 12716.35,
        "open": 12775.9,
        "close": 12882.22,
        "low": 12780.24,
        "priceList": [
            {
                "price": 12832.36,
                "ask": 12,
                "bid": 8
            },
            {
                "price": 12881.21,
                "ask": 30,
                "bid": 65
            },
            {
                "price": 12975.31,
                "ask": 28,
                "bid": 12
            },
            {
                "price": 12892.54,
                "ask": 49,
                "bid": 10
            },
            {
                "price": 12887.54,
                "ask": 12,
                "bid": 10
            },
            {
                "price": 12920.39,
                "ask": 72,
                "bid": 86
            },
            {
                "price": 12914.03,
                "ask": 31,
                "bid": 31
            },
            {
                "price": 12920.67,
                "ask": 84,
                "bid": 64
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcc6e1db5d319e3e9d",
        "high": 12864.68,
        "open": 12881.31,
        "close": 12777.3,
        "low": 12933.09,
        "priceList": [
            {
                "price": 12926.39,
                "ask": 96,
                "bid": 94
            },
            {
                "price": 12905.41,
                "ask": 4,
                "bid": 63
            },
            {
                "price": 12723.24,
                "ask": 53,
                "bid": 26
            },
            {
                "price": 12782.41,
                "ask": 27,
                "bid": 98
            },
            {
                "price": 12772.74,
                "ask": 52,
                "bid": 35
            },
            {
                "price": 12903.26,
                "ask": 2,
                "bid": 70
            },
            {
                "price": 12877.64,
                "ask": 41,
                "bid": 57
            },
            {
                "price": 12971.48,
                "ask": 80,
                "bid": 0
            },
            {
                "price": 12746.46,
                "ask": 1,
                "bid": 13
            },
            {
                "price": 12863.05,
                "ask": 30,
                "bid": 94
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc62bee97c82e8f4a7",
        "high": 12825.27,
        "open": 12815.5,
        "close": 12761.97,
        "low": 12777.5,
        "priceList": [
            {
                "price": 12880.27,
                "ask": 2,
                "bid": 74
            },
            {
                "price": 12899.7,
                "ask": 59,
                "bid": 39
            },
            {
                "price": 12777.18,
                "ask": 84,
                "bid": 12
            },
            {
                "price": 12831.52,
                "ask": 41,
                "bid": 34
            },
            {
                "price": 12996.41,
                "ask": 13,
                "bid": 0
            },
            {
                "price": 12863.79,
                "ask": 48,
                "bid": 66
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dced05be3cd4012433",
        "high": 12949.69,
        "open": 12964.31,
        "close": 12953.56,
        "low": 12922.26,
        "priceList": [
            {
                "price": 12748.95,
                "ask": 10,
                "bid": 68
            },
            {
                "price": 12998.53,
                "ask": 23,
                "bid": 16
            },
            {
                "price": 12856.97,
                "ask": 81,
                "bid": 11
            },
            {
                "price": 12705.4,
                "ask": 90,
                "bid": 70
            },
            {
                "price": 12774.15,
                "ask": 11,
                "bid": 74
            },
            {
                "price": 12784.06,
                "ask": 28,
                "bid": 89
            },
            {
                "price": 12726.96,
                "ask": 51,
                "bid": 74
            },
            {
                "price": 12702.5,
                "ask": 92,
                "bid": 83
            },
            {
                "price": 12902.25,
                "ask": 26,
                "bid": 38
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc5302aa60214d2398",
        "high": 12877.63,
        "open": 12830.82,
        "close": 12766.27,
        "low": 12997.31,
        "priceList": [
            {
                "price": 12800.12,
                "ask": 50,
                "bid": 46
            },
            {
                "price": 12965.17,
                "ask": 11,
                "bid": 72
            },
            {
                "price": 12947.34,
                "ask": 37,
                "bid": 6
            },
            {
                "price": 12728.53,
                "ask": 1,
                "bid": 90
            },
            {
                "price": 12843.1,
                "ask": 10,
                "bid": 13
            },
            {
                "price": 12939.74,
                "ask": 19,
                "bid": 73
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc580ebfb1c8fc5d20",
        "high": 12973.78,
        "open": 12963.26,
        "close": 12905.77,
        "low": 12707.09,
        "priceList": [
            {
                "price": 12891.52,
                "ask": 39,
                "bid": 97
            },
            {
                "price": 12909.3,
                "ask": 96,
                "bid": 92
            },
            {
                "price": 12946.89,
                "ask": 51,
                "bid": 80
            },
            {
                "price": 12748.29,
                "ask": 28,
                "bid": 33
            },
            {
                "price": 12734.05,
                "ask": 36,
                "bid": 18
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc1e9a1afee54ad38a",
        "high": 12970.47,
        "open": 12735.85,
        "close": 12722.92,
        "low": 12705.27,
        "priceList": [
            {
                "price": 12713.36,
                "ask": 75,
                "bid": 77
            },
            {
                "price": 12801.69,
                "ask": 87,
                "bid": 54
            },
            {
                "price": 12736.95,
                "ask": 29,
                "bid": 39
            },
            {
                "price": 12842.63,
                "ask": 88,
                "bid": 7
            },
            {
                "price": 12772.79,
                "ask": 60,
                "bid": 5
            },
            {
                "price": 12777.78,
                "ask": 87,
                "bid": 63
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc7e43e2272b6c976d",
        "high": 12903.5,
        "open": 12802.76,
        "close": 12984.89,
        "low": 12752.93,
        "priceList": [
            {
                "price": 12709.2,
                "ask": 67,
                "bid": 34
            },
            {
                "price": 12721.62,
                "ask": 6,
                "bid": 98
            },
            {
                "price": 12986.6,
                "ask": 11,
                "bid": 28
            },
            {
                "price": 12975.53,
                "ask": 19,
                "bid": 25
            },
            {
                "price": 12703.7,
                "ask": 92,
                "bid": 71
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcbb5c7f47644481c3",
        "high": 12815.36,
        "open": 12972.2,
        "close": 12730,
        "low": 12994.59,
        "priceList": [
            {
                "price": 12982.32,
                "ask": 35,
                "bid": 20
            },
            {
                "price": 12703.7,
                "ask": 48,
                "bid": 45
            },
            {
                "price": 12810.52,
                "ask": 68,
                "bid": 5
            },
            {
                "price": 12832.47,
                "ask": 95,
                "bid": 79
            },
            {
                "price": 12982.41,
                "ask": 45,
                "bid": 67
            },
            {
                "price": 12913.4,
                "ask": 27,
                "bid": 88
            },
            {
                "price": 12958.25,
                "ask": 16,
                "bid": 5
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcfc7012f695205e5f",
        "high": 12897.37,
        "open": 12874.73,
        "close": 12874.02,
        "low": 12774.15,
        "priceList": [
            {
                "price": 12970.86,
                "ask": 57,
                "bid": 82
            },
            {
                "price": 12993.32,
                "ask": 38,
                "bid": 15
            },
            {
                "price": 12943.09,
                "ask": 55,
                "bid": 32
            },
            {
                "price": 12818.76,
                "ask": 69,
                "bid": 16
            },
            {
                "price": 12900.59,
                "ask": 87,
                "bid": 0
            },
            {
                "price": 12829.88,
                "ask": 76,
                "bid": 3
            },
            {
                "price": 12808.37,
                "ask": 32,
                "bid": 5
            },
            {
                "price": 12852.87,
                "ask": 70,
                "bid": 55
            },
            {
                "price": 12710.79,
                "ask": 89,
                "bid": 16
            },
            {
                "price": 12959.74,
                "ask": 14,
                "bid": 87
            },
            {
                "price": 12789.2,
                "ask": 43,
                "bid": 78
            },
            {
                "price": 12824.38,
                "ask": 68,
                "bid": 78
            },
            {
                "price": 12774.75,
                "ask": 17,
                "bid": 74
            },
            {
                "price": 12966.31,
                "ask": 70,
                "bid": 57
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc286a25d3161c4910",
        "high": 12870.82,
        "open": 12814,
        "close": 12822.65,
        "low": 12975.72,
        "priceList": [
            {
                "price": 12908.59,
                "ask": 96,
                "bid": 33
            },
            {
                "price": 12715.24,
                "ask": 37,
                "bid": 3
            },
            {
                "price": 12968.55,
                "ask": 25,
                "bid": 15
            },
            {
                "price": 12779.63,
                "ask": 54,
                "bid": 82
            },
            {
                "price": 12741.98,
                "ask": 48,
                "bid": 47
            },
            {
                "price": 12923.44,
                "ask": 51,
                "bid": 85
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcbe3a4b3a34860d5c",
        "high": 12892.93,
        "open": 12710.62,
        "close": 12721.87,
        "low": 12961.64,
        "priceList": [
            {
                "price": 12855.16,
                "ask": 73,
                "bid": 59
            },
            {
                "price": 12962.86,
                "ask": 68,
                "bid": 39
            },
            {
                "price": 12943.44,
                "ask": 47,
                "bid": 65
            },
            {
                "price": 12710.9,
                "ask": 10,
                "bid": 65
            },
            {
                "price": 12703.2,
                "ask": 23,
                "bid": 8
            },
            {
                "price": 12881.23,
                "ask": 54,
                "bid": 65
            },
            {
                "price": 12835.3,
                "ask": 50,
                "bid": 100
            },
            {
                "price": 12948.18,
                "ask": 77,
                "bid": 11
            },
            {
                "price": 12937.89,
                "ask": 92,
                "bid": 54
            },
            {
                "price": 12825,
                "ask": 71,
                "bid": 97
            },
            {
                "price": 12839.73,
                "ask": 100,
                "bid": 11
            },
            {
                "price": 12728.97,
                "ask": 58,
                "bid": 39
            },
            {
                "price": 12729.88,
                "ask": 30,
                "bid": 99
            },
            {
                "price": 12737.94,
                "ask": 3,
                "bid": 7
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcb0fa99da0494722b",
        "high": 12820.2,
        "open": 12886.74,
        "close": 12730.98,
        "low": 12758.51,
        "priceList": [
            {
                "price": 12880.22,
                "ask": 2,
                "bid": 67
            },
            {
                "price": 12855.13,
                "ask": 2,
                "bid": 67
            },
            {
                "price": 12751.64,
                "ask": 33,
                "bid": 52
            },
            {
                "price": 12722.16,
                "ask": 8,
                "bid": 80
            },
            {
                "price": 12814.61,
                "ask": 46,
                "bid": 17
            },
            {
                "price": 12958.54,
                "ask": 91,
                "bid": 89
            },
            {
                "price": 12900.12,
                "ask": 68,
                "bid": 95
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcd5609d662412f93c",
        "high": 12745.06,
        "open": 12908.54,
        "close": 12975.73,
        "low": 12795.07,
        "priceList": [
            {
                "price": 12851.63,
                "ask": 9,
                "bid": 69
            },
            {
                "price": 12939.81,
                "ask": 56,
                "bid": 39
            },
            {
                "price": 12736.41,
                "ask": 4,
                "bid": 93
            },
            {
                "price": 12766.86,
                "ask": 97,
                "bid": 8
            },
            {
                "price": 12714.89,
                "ask": 69,
                "bid": 65
            },
            {
                "price": 12882.87,
                "ask": 92,
                "bid": 82
            },
            {
                "price": 12991.8,
                "ask": 48,
                "bid": 30
            },
            {
                "price": 12977.02,
                "ask": 71,
                "bid": 10
            },
            {
                "price": 12775.52,
                "ask": 16,
                "bid": 47
            },
            {
                "price": 12819.84,
                "ask": 26,
                "bid": 100
            },
            {
                "price": 12885.39,
                "ask": 69,
                "bid": 53
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc7982a5c874d5618a",
        "high": 12717.95,
        "open": 12942.13,
        "close": 12849.87,
        "low": 12764.88,
        "priceList": [
            {
                "price": 12982.96,
                "ask": 30,
                "bid": 21
            },
            {
                "price": 12946.39,
                "ask": 54,
                "bid": 31
            },
            {
                "price": 12921.22,
                "ask": 27,
                "bid": 76
            },
            {
                "price": 12955.39,
                "ask": 59,
                "bid": 5
            },
            {
                "price": 12768.43,
                "ask": 60,
                "bid": 56
            },
            {
                "price": 12726.75,
                "ask": 74,
                "bid": 61
            },
            {
                "price": 12906.05,
                "ask": 32,
                "bid": 41
            },
            {
                "price": 12820.26,
                "ask": 24,
                "bid": 34
            },
            {
                "price": 12744,
                "ask": 3,
                "bid": 30
            },
            {
                "price": 12950.44,
                "ask": 2,
                "bid": 20
            },
            {
                "price": 12941.34,
                "ask": 75,
                "bid": 94
            },
            {
                "price": 12729.71,
                "ask": 26,
                "bid": 40
            },
            {
                "price": 12715.19,
                "ask": 27,
                "bid": 62
            },
            {
                "price": 12862.37,
                "ask": 17,
                "bid": 79
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc0fcaee19c941e75b",
        "high": 12792.95,
        "open": 12928.8,
        "close": 12959.74,
        "low": 12947.76,
        "priceList": [
            {
                "price": 12721.24,
                "ask": 59,
                "bid": 10
            },
            {
                "price": 12817.81,
                "ask": 55,
                "bid": 75
            },
            {
                "price": 12822.81,
                "ask": 100,
                "bid": 50
            },
            {
                "price": 12758.95,
                "ask": 89,
                "bid": 27
            },
            {
                "price": 12731.51,
                "ask": 70,
                "bid": 79
            },
            {
                "price": 12759.78,
                "ask": 59,
                "bid": 21
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcc24ecc4a079a547b",
        "high": 12908.71,
        "open": 12857.31,
        "close": 12972.69,
        "low": 12764.5,
        "priceList": [
            {
                "price": 12874,
                "ask": 60,
                "bid": 95
            },
            {
                "price": 12740.45,
                "ask": 94,
                "bid": 47
            },
            {
                "price": 12985.09,
                "ask": 33,
                "bid": 33
            },
            {
                "price": 12712.97,
                "ask": 36,
                "bid": 81
            },
            {
                "price": 12975.27,
                "ask": 31,
                "bid": 21
            },
            {
                "price": 12755.49,
                "ask": 76,
                "bid": 70
            },
            {
                "price": 12869.41,
                "ask": 56,
                "bid": 34
            },
            {
                "price": 12801.71,
                "ask": 52,
                "bid": 12
            },
            {
                "price": 12791.17,
                "ask": 99,
                "bid": 38
            },
            {
                "price": 12995.6,
                "ask": 66,
                "bid": 77
            },
            {
                "price": 12989.5,
                "ask": 74,
                "bid": 99
            },
            {
                "price": 12961.73,
                "ask": 1,
                "bid": 32
            },
            {
                "price": 12953.55,
                "ask": 37,
                "bid": 83
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dca33236fbdd9ab0c8",
        "high": 12807.78,
        "open": 12863.3,
        "close": 12901.21,
        "low": 12979.72,
        "priceList": [
            {
                "price": 12778.26,
                "ask": 55,
                "bid": 63
            },
            {
                "price": 12966.82,
                "ask": 73,
                "bid": 76
            },
            {
                "price": 12965.16,
                "ask": 56,
                "bid": 12
            },
            {
                "price": 12814.65,
                "ask": 21,
                "bid": 36
            },
            {
                "price": 12808.48,
                "ask": 25,
                "bid": 84
            },
            {
                "price": 12864.34,
                "ask": 75,
                "bid": 31
            },
            {
                "price": 12925.51,
                "ask": 19,
                "bid": 50
            },
            {
                "price": 12794.31,
                "ask": 9,
                "bid": 82
            },
            {
                "price": 12867.58,
                "ask": 88,
                "bid": 90
            },
            {
                "price": 12731.61,
                "ask": 52,
                "bid": 27
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcf5d6394042ad2254",
        "high": 12731.32,
        "open": 12817.48,
        "close": 12869.15,
        "low": 12955.77,
        "priceList": [
            {
                "price": 12864.35,
                "ask": 5,
                "bid": 56
            },
            {
                "price": 12732.93,
                "ask": 85,
                "bid": 6
            },
            {
                "price": 12849.86,
                "ask": 36,
                "bid": 38
            },
            {
                "price": 12736.35,
                "ask": 92,
                "bid": 92
            },
            {
                "price": 12911.95,
                "ask": 81,
                "bid": 46
            },
            {
                "price": 12978.55,
                "ask": 47,
                "bid": 14
            },
            {
                "price": 12882.11,
                "ask": 79,
                "bid": 9
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dca914d556f4184ef1",
        "high": 12792.62,
        "open": 12782.12,
        "close": 12974.04,
        "low": 12705.03,
        "priceList": [
            {
                "price": 12955.11,
                "ask": 19,
                "bid": 36
            },
            {
                "price": 12979.33,
                "ask": 59,
                "bid": 88
            },
            {
                "price": 12989.86,
                "ask": 19,
                "bid": 38
            },
            {
                "price": 12911.42,
                "ask": 4,
                "bid": 41
            },
            {
                "price": 12893.76,
                "ask": 33,
                "bid": 26
            },
            {
                "price": 12909.43,
                "ask": 8,
                "bid": 22
            },
            {
                "price": 12768.7,
                "ask": 34,
                "bid": 46
            },
            {
                "price": 12905.29,
                "ask": 84,
                "bid": 36
            },
            {
                "price": 12916.02,
                "ask": 33,
                "bid": 16
            },
            {
                "price": 12984.73,
                "ask": 71,
                "bid": 20
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc96df3a5d3c68fead",
        "high": 12828.78,
        "open": 12938.66,
        "close": 12766.16,
        "low": 12899.55,
        "priceList": [
            {
                "price": 12829.95,
                "ask": 77,
                "bid": 33
            },
            {
                "price": 12933.06,
                "ask": 31,
                "bid": 50
            },
            {
                "price": 12722.07,
                "ask": 97,
                "bid": 10
            },
            {
                "price": 12888.08,
                "ask": 43,
                "bid": 62
            },
            {
                "price": 12785.42,
                "ask": 97,
                "bid": 9
            },
            {
                "price": 12796.75,
                "ask": 38,
                "bid": 84
            },
            {
                "price": 12820.73,
                "ask": 52,
                "bid": 10
            },
            {
                "price": 12990.15,
                "ask": 24,
                "bid": 47
            },
            {
                "price": 12972.65,
                "ask": 80,
                "bid": 20
            },
            {
                "price": 12831.99,
                "ask": 19,
                "bid": 10
            },
            {
                "price": 12764.5,
                "ask": 78,
                "bid": 79
            },
            {
                "price": 12773.45,
                "ask": 69,
                "bid": 44
            },
            {
                "price": 12764.69,
                "ask": 35,
                "bid": 56
            },
            {
                "price": 12725.3,
                "ask": 31,
                "bid": 47
            },
            {
                "price": 12789.63,
                "ask": 58,
                "bid": 88
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca18db9b9874a3bd7",
        "high": 12727.96,
        "open": 12862.48,
        "close": 12717.43,
        "low": 12979.8,
        "priceList": [
            {
                "price": 12854.19,
                "ask": 29,
                "bid": 100
            },
            {
                "price": 12750.46,
                "ask": 71,
                "bid": 93
            },
            {
                "price": 12828.23,
                "ask": 30,
                "bid": 63
            },
            {
                "price": 12958.63,
                "ask": 18,
                "bid": 45
            },
            {
                "price": 12737.31,
                "ask": 61,
                "bid": 48
            },
            {
                "price": 12983.57,
                "ask": 36,
                "bid": 28
            },
            {
                "price": 12736.55,
                "ask": 68,
                "bid": 18
            },
            {
                "price": 12904.39,
                "ask": 98,
                "bid": 75
            },
            {
                "price": 12849.79,
                "ask": 28,
                "bid": 70
            },
            {
                "price": 12972.29,
                "ask": 52,
                "bid": 66
            },
            {
                "price": 12937.61,
                "ask": 51,
                "bid": 60
            },
            {
                "price": 12792.67,
                "ask": 89,
                "bid": 73
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcc91707b872910d98",
        "high": 12965.33,
        "open": 12780.1,
        "close": 12872.61,
        "low": 12923.28,
        "priceList": [
            {
                "price": 12874.84,
                "ask": 37,
                "bid": 96
            },
            {
                "price": 12744.43,
                "ask": 23,
                "bid": 79
            },
            {
                "price": 12882.28,
                "ask": 27,
                "bid": 65
            },
            {
                "price": 12957.21,
                "ask": 51,
                "bid": 46
            },
            {
                "price": 12854.33,
                "ask": 70,
                "bid": 87
            },
            {
                "price": 12836.09,
                "ask": 27,
                "bid": 30
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc2cb592e5fbee53fb",
        "high": 12915.22,
        "open": 12886.78,
        "close": 12964.49,
        "low": 12908.14,
        "priceList": [
            {
                "price": 12946.19,
                "ask": 83,
                "bid": 59
            },
            {
                "price": 12713.17,
                "ask": 6,
                "bid": 15
            },
            {
                "price": 12943.48,
                "ask": 60,
                "bid": 41
            },
            {
                "price": 12966.94,
                "ask": 20,
                "bid": 52
            },
            {
                "price": 12829.86,
                "ask": 55,
                "bid": 36
            },
            {
                "price": 12895.56,
                "ask": 17,
                "bid": 7
            },
            {
                "price": 12917.08,
                "ask": 45,
                "bid": 8
            },
            {
                "price": 12853.76,
                "ask": 71,
                "bid": 81
            },
            {
                "price": 12949.05,
                "ask": 85,
                "bid": 76
            },
            {
                "price": 12757.3,
                "ask": 77,
                "bid": 25
            },
            {
                "price": 12805.07,
                "ask": 41,
                "bid": 68
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc57742fd34fb20fcb",
        "high": 12852.93,
        "open": 12735.64,
        "close": 12813.25,
        "low": 12985.14,
        "priceList": [
            {
                "price": 12792.47,
                "ask": 58,
                "bid": 42
            },
            {
                "price": 12912.63,
                "ask": 91,
                "bid": 0
            },
            {
                "price": 12820.65,
                "ask": 75,
                "bid": 86
            },
            {
                "price": 12882.09,
                "ask": 9,
                "bid": 47
            },
            {
                "price": 12798.66,
                "ask": 77,
                "bid": 19
            },
            {
                "price": 12834.33,
                "ask": 80,
                "bid": 52
            },
            {
                "price": 12885.65,
                "ask": 89,
                "bid": 47
            },
            {
                "price": 12789.85,
                "ask": 6,
                "bid": 85
            },
            {
                "price": 12809.49,
                "ask": 71,
                "bid": 51
            },
            {
                "price": 12796.63,
                "ask": 31,
                "bid": 22
            },
            {
                "price": 12706.09,
                "ask": 75,
                "bid": 43
            },
            {
                "price": 12754.83,
                "ask": 17,
                "bid": 51
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc815d9fda07dcfe25",
        "high": 12811.65,
        "open": 12814.69,
        "close": 12990.17,
        "low": 12981.96,
        "priceList": [
            {
                "price": 12814.93,
                "ask": 40,
                "bid": 30
            },
            {
                "price": 12866.07,
                "ask": 95,
                "bid": 65
            },
            {
                "price": 12979.51,
                "ask": 78,
                "bid": 11
            },
            {
                "price": 12993.48,
                "ask": 33,
                "bid": 33
            },
            {
                "price": 12714.87,
                "ask": 56,
                "bid": 5
            },
            {
                "price": 12836.63,
                "ask": 9,
                "bid": 33
            },
            {
                "price": 12888.65,
                "ask": 73,
                "bid": 78
            },
            {
                "price": 12813.11,
                "ask": 60,
                "bid": 46
            },
            {
                "price": 12894.08,
                "ask": 98,
                "bid": 6
            },
            {
                "price": 12979.26,
                "ask": 74,
                "bid": 21
            },
            {
                "price": 12751.94,
                "ask": 84,
                "bid": 57
            },
            {
                "price": 12771.97,
                "ask": 83,
                "bid": 34
            },
            {
                "price": 12756.72,
                "ask": 73,
                "bid": 39
            },
            {
                "price": 12761.11,
                "ask": 26,
                "bid": 72
            },
            {
                "price": 12766.32,
                "ask": 47,
                "bid": 64
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6f4376b3e29333a9",
        "high": 12921.14,
        "open": 12895.87,
        "close": 12997.98,
        "low": 12776.15,
        "priceList": [
            {
                "price": 12791.6,
                "ask": 10,
                "bid": 6
            },
            {
                "price": 12706.81,
                "ask": 37,
                "bid": 1
            },
            {
                "price": 12846.17,
                "ask": 35,
                "bid": 18
            },
            {
                "price": 12969.6,
                "ask": 4,
                "bid": 14
            },
            {
                "price": 12984,
                "ask": 43,
                "bid": 46
            },
            {
                "price": 12959.37,
                "ask": 39,
                "bid": 33
            },
            {
                "price": 12985.98,
                "ask": 75,
                "bid": 71
            },
            {
                "price": 12790.76,
                "ask": 60,
                "bid": 21
            },
            {
                "price": 12709.17,
                "ask": 48,
                "bid": 45
            },
            {
                "price": 12986.11,
                "ask": 77,
                "bid": 89
            },
            {
                "price": 12782.18,
                "ask": 34,
                "bid": 55
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dca094376fac2d14eb",
        "high": 12746.53,
        "open": 12722.76,
        "close": 12915.42,
        "low": 12904.48,
        "priceList": [
            {
                "price": 12902.7,
                "ask": 12,
                "bid": 14
            },
            {
                "price": 12795.56,
                "ask": 59,
                "bid": 61
            },
            {
                "price": 12850.82,
                "ask": 61,
                "bid": 85
            },
            {
                "price": 12856.85,
                "ask": 40,
                "bid": 53
            },
            {
                "price": 12785.45,
                "ask": 49,
                "bid": 30
            },
            {
                "price": 12971.66,
                "ask": 70,
                "bid": 98
            },
            {
                "price": 12829.52,
                "ask": 75,
                "bid": 33
            },
            {
                "price": 12815.1,
                "ask": 11,
                "bid": 48
            },
            {
                "price": 12742.76,
                "ask": 46,
                "bid": 20
            },
            {
                "price": 12953.12,
                "ask": 34,
                "bid": 0
            },
            {
                "price": 12735.37,
                "ask": 66,
                "bid": 1
            },
            {
                "price": 12861.42,
                "ask": 2,
                "bid": 13
            },
            {
                "price": 12874.37,
                "ask": 50,
                "bid": 35
            },
            {
                "price": 12997.46,
                "ask": 18,
                "bid": 56
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcb48976787c6dd278",
        "high": 12800.04,
        "open": 12963.59,
        "close": 12948.18,
        "low": 12821.18,
        "priceList": [
            {
                "price": 12969.85,
                "ask": 20,
                "bid": 12
            },
            {
                "price": 12956.8,
                "ask": 76,
                "bid": 66
            },
            {
                "price": 12792.01,
                "ask": 39,
                "bid": 6
            },
            {
                "price": 12901.11,
                "ask": 85,
                "bid": 12
            },
            {
                "price": 12987.86,
                "ask": 68,
                "bid": 10
            },
            {
                "price": 12702.97,
                "ask": 1,
                "bid": 57
            },
            {
                "price": 12884.19,
                "ask": 14,
                "bid": 6
            },
            {
                "price": 12975.06,
                "ask": 97,
                "bid": 95
            },
            {
                "price": 12925.61,
                "ask": 41,
                "bid": 28
            },
            {
                "price": 12809.27,
                "ask": 54,
                "bid": 81
            },
            {
                "price": 12946.82,
                "ask": 60,
                "bid": 65
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc33df7ef35569cacc",
        "high": 12735.59,
        "open": 12748.91,
        "close": 12884.7,
        "low": 12987.5,
        "priceList": [
            {
                "price": 12825.87,
                "ask": 27,
                "bid": 84
            },
            {
                "price": 12759.11,
                "ask": 51,
                "bid": 28
            },
            {
                "price": 12998.11,
                "ask": 38,
                "bid": 93
            },
            {
                "price": 12803.2,
                "ask": 0,
                "bid": 33
            },
            {
                "price": 12813.9,
                "ask": 68,
                "bid": 40
            },
            {
                "price": 12708.02,
                "ask": 29,
                "bid": 17
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dce3639d509aac27d0",
        "high": 12738.87,
        "open": 12911.17,
        "close": 12865.54,
        "low": 12750.08,
        "priceList": [
            {
                "price": 12844.57,
                "ask": 96,
                "bid": 24
            },
            {
                "price": 12763.96,
                "ask": 52,
                "bid": 25
            },
            {
                "price": 12737.25,
                "ask": 77,
                "bid": 13
            },
            {
                "price": 12807.03,
                "ask": 24,
                "bid": 18
            },
            {
                "price": 12729.92,
                "ask": 76,
                "bid": 80
            },
            {
                "price": 12950.46,
                "ask": 35,
                "bid": 59
            },
            {
                "price": 12842.49,
                "ask": 91,
                "bid": 89
            },
            {
                "price": 12715.99,
                "ask": 49,
                "bid": 69
            },
            {
                "price": 12941.25,
                "ask": 62,
                "bid": 56
            },
            {
                "price": 12932.8,
                "ask": 37,
                "bid": 90
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc185ba5018aa03c30",
        "high": 12776.76,
        "open": 12886.05,
        "close": 12730.31,
        "low": 12762.95,
        "priceList": [
            {
                "price": 12818.82,
                "ask": 40,
                "bid": 46
            },
            {
                "price": 12754.82,
                "ask": 88,
                "bid": 55
            },
            {
                "price": 12751.98,
                "ask": 96,
                "bid": 67
            },
            {
                "price": 12722.29,
                "ask": 75,
                "bid": 32
            },
            {
                "price": 12809.15,
                "ask": 1,
                "bid": 59
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc7cb9bb571071fbb1",
        "high": 12733.24,
        "open": 12814.82,
        "close": 12747.8,
        "low": 12728.81,
        "priceList": [
            {
                "price": 12922.46,
                "ask": 76,
                "bid": 10
            },
            {
                "price": 12969.76,
                "ask": 91,
                "bid": 20
            },
            {
                "price": 12827.24,
                "ask": 7,
                "bid": 82
            },
            {
                "price": 12963.72,
                "ask": 32,
                "bid": 37
            },
            {
                "price": 12894.27,
                "ask": 78,
                "bid": 77
            },
            {
                "price": 12892.53,
                "ask": 76,
                "bid": 37
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcfcea99c475955910",
        "high": 12869.1,
        "open": 12922.29,
        "close": 12950.8,
        "low": 12962.05,
        "priceList": [
            {
                "price": 12898.99,
                "ask": 5,
                "bid": 21
            },
            {
                "price": 12896.73,
                "ask": 57,
                "bid": 5
            },
            {
                "price": 12893.69,
                "ask": 87,
                "bid": 27
            },
            {
                "price": 12734.23,
                "ask": 51,
                "bid": 81
            },
            {
                "price": 12928.97,
                "ask": 98,
                "bid": 95
            },
            {
                "price": 12945.11,
                "ask": 75,
                "bid": 56
            },
            {
                "price": 12757.23,
                "ask": 27,
                "bid": 70
            },
            {
                "price": 12903.3,
                "ask": 67,
                "bid": 62
            },
            {
                "price": 12746.2,
                "ask": 9,
                "bid": 75
            },
            {
                "price": 12772.34,
                "ask": 14,
                "bid": 61
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc1701ace8738e9e4e",
        "high": 12900.32,
        "open": 12980.59,
        "close": 12948.46,
        "low": 12802.71,
        "priceList": [
            {
                "price": 12902.52,
                "ask": 45,
                "bid": 59
            },
            {
                "price": 12945.44,
                "ask": 31,
                "bid": 6
            },
            {
                "price": 12963.41,
                "ask": 89,
                "bid": 71
            },
            {
                "price": 12823.42,
                "ask": 58,
                "bid": 89
            },
            {
                "price": 12813.22,
                "ask": 35,
                "bid": 23
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcff00bca6825a855b",
        "high": 12973.2,
        "open": 12757.07,
        "close": 12803.35,
        "low": 12820.29,
        "priceList": [
            {
                "price": 12827.89,
                "ask": 23,
                "bid": 11
            },
            {
                "price": 12844.47,
                "ask": 19,
                "bid": 87
            },
            {
                "price": 12934,
                "ask": 58,
                "bid": 60
            },
            {
                "price": 12912.59,
                "ask": 64,
                "bid": 63
            },
            {
                "price": 12911.7,
                "ask": 1,
                "bid": 21
            },
            {
                "price": 12973.92,
                "ask": 77,
                "bid": 44
            },
            {
                "price": 12898.11,
                "ask": 65,
                "bid": 28
            },
            {
                "price": 12928.11,
                "ask": 14,
                "bid": 35
            },
            {
                "price": 12954.15,
                "ask": 86,
                "bid": 28
            },
            {
                "price": 12947.24,
                "ask": 38,
                "bid": 51
            },
            {
                "price": 12862.16,
                "ask": 40,
                "bid": 48
            },
            {
                "price": 12757.03,
                "ask": 15,
                "bid": 7
            },
            {
                "price": 12796.04,
                "ask": 97,
                "bid": 73
            },
            {
                "price": 12935.36,
                "ask": 49,
                "bid": 64
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc5f9f657cc840b192",
        "high": 12764.49,
        "open": 12827.72,
        "close": 12994.35,
        "low": 12967.48,
        "priceList": [
            {
                "price": 12840.79,
                "ask": 70,
                "bid": 61
            },
            {
                "price": 12847.81,
                "ask": 3,
                "bid": 15
            },
            {
                "price": 12951.5,
                "ask": 8,
                "bid": 50
            },
            {
                "price": 12964.64,
                "ask": 23,
                "bid": 20
            },
            {
                "price": 12752.87,
                "ask": 28,
                "bid": 42
            },
            {
                "price": 12918.77,
                "ask": 0,
                "bid": 21
            },
            {
                "price": 12769.21,
                "ask": 69,
                "bid": 64
            },
            {
                "price": 12935.42,
                "ask": 5,
                "bid": 29
            },
            {
                "price": 12857.71,
                "ask": 39,
                "bid": 69
            },
            {
                "price": 12850.65,
                "ask": 3,
                "bid": 94
            },
            {
                "price": 12796.59,
                "ask": 29,
                "bid": 22
            },
            {
                "price": 12963.51,
                "ask": 24,
                "bid": 4
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcae4e3f1ce6c05bac",
        "high": 12889.38,
        "open": 12937.44,
        "close": 12845.03,
        "low": 12956.11,
        "priceList": [
            {
                "price": 12743.41,
                "ask": 81,
                "bid": 74
            },
            {
                "price": 12956.84,
                "ask": 64,
                "bid": 92
            },
            {
                "price": 12837,
                "ask": 2,
                "bid": 56
            },
            {
                "price": 12749.73,
                "ask": 91,
                "bid": 59
            },
            {
                "price": 12821.56,
                "ask": 67,
                "bid": 64
            },
            {
                "price": 12844.89,
                "ask": 64,
                "bid": 69
            },
            {
                "price": 12834.3,
                "ask": 31,
                "bid": 11
            },
            {
                "price": 12895.21,
                "ask": 43,
                "bid": 99
            },
            {
                "price": 12758.36,
                "ask": 86,
                "bid": 34
            },
            {
                "price": 12960.6,
                "ask": 96,
                "bid": 35
            },
            {
                "price": 12750.95,
                "ask": 35,
                "bid": 95
            },
            {
                "price": 12945.02,
                "ask": 94,
                "bid": 85
            },
            {
                "price": 12731.53,
                "ask": 38,
                "bid": 3
            },
            {
                "price": 12945.1,
                "ask": 0,
                "bid": 55
            },
            {
                "price": 12872.38,
                "ask": 66,
                "bid": 80
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc4b59feaa6339c1d5",
        "high": 12919.71,
        "open": 12779.22,
        "close": 12903.9,
        "low": 12739.26,
        "priceList": [
            {
                "price": 12795.5,
                "ask": 97,
                "bid": 97
            },
            {
                "price": 12908.4,
                "ask": 83,
                "bid": 23
            },
            {
                "price": 12837.76,
                "ask": 63,
                "bid": 15
            },
            {
                "price": 12770.41,
                "ask": 70,
                "bid": 32
            },
            {
                "price": 12871.62,
                "ask": 0,
                "bid": 29
            },
            {
                "price": 12864.66,
                "ask": 47,
                "bid": 3
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc08c410d7132486b9",
        "high": 12792.27,
        "open": 12883.91,
        "close": 12916.77,
        "low": 12840.9,
        "priceList": [
            {
                "price": 12812.46,
                "ask": 28,
                "bid": 53
            },
            {
                "price": 12779.94,
                "ask": 24,
                "bid": 76
            },
            {
                "price": 12999.33,
                "ask": 99,
                "bid": 2
            },
            {
                "price": 12715.72,
                "ask": 48,
                "bid": 96
            },
            {
                "price": 12902.85,
                "ask": 98,
                "bid": 4
            },
            {
                "price": 12744.72,
                "ask": 40,
                "bid": 4
            },
            {
                "price": 12831.92,
                "ask": 67,
                "bid": 21
            },
            {
                "price": 12949.79,
                "ask": 64,
                "bid": 85
            },
            {
                "price": 12817.24,
                "ask": 96,
                "bid": 65
            },
            {
                "price": 12802.68,
                "ask": 71,
                "bid": 11
            },
            {
                "price": 12855.91,
                "ask": 54,
                "bid": 4
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc38e4cea2425e9903",
        "high": 12832.08,
        "open": 12939.38,
        "close": 12906.78,
        "low": 12716.37,
        "priceList": [
            {
                "price": 12994.93,
                "ask": 61,
                "bid": 59
            },
            {
                "price": 12719.69,
                "ask": 33,
                "bid": 2
            },
            {
                "price": 12847.04,
                "ask": 48,
                "bid": 61
            },
            {
                "price": 12715.47,
                "ask": 37,
                "bid": 63
            },
            {
                "price": 12957.69,
                "ask": 95,
                "bid": 89
            },
            {
                "price": 12887.91,
                "ask": 65,
                "bid": 85
            },
            {
                "price": 12988.61,
                "ask": 55,
                "bid": 14
            },
            {
                "price": 12803.39,
                "ask": 97,
                "bid": 89
            },
            {
                "price": 12928.38,
                "ask": 100,
                "bid": 56
            },
            {
                "price": 12772,
                "ask": 52,
                "bid": 0
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcab412de4bfe39274",
        "high": 12823.43,
        "open": 12847.71,
        "close": 12803.13,
        "low": 12716.87,
        "priceList": [
            {
                "price": 12832.5,
                "ask": 16,
                "bid": 94
            },
            {
                "price": 12962.68,
                "ask": 4,
                "bid": 6
            },
            {
                "price": 12851.14,
                "ask": 21,
                "bid": 29
            },
            {
                "price": 12995.32,
                "ask": 53,
                "bid": 61
            },
            {
                "price": 12907.69,
                "ask": 51,
                "bid": 29
            },
            {
                "price": 12749.28,
                "ask": 20,
                "bid": 49
            },
            {
                "price": 12941.05,
                "ask": 76,
                "bid": 27
            },
            {
                "price": 12709.7,
                "ask": 86,
                "bid": 48
            },
            {
                "price": 12884.27,
                "ask": 23,
                "bid": 52
            },
            {
                "price": 12944.82,
                "ask": 79,
                "bid": 81
            },
            {
                "price": 12760.73,
                "ask": 35,
                "bid": 52
            },
            {
                "price": 12765.07,
                "ask": 13,
                "bid": 48
            },
            {
                "price": 12815.66,
                "ask": 89,
                "bid": 82
            },
            {
                "price": 12731.21,
                "ask": 83,
                "bid": 17
            },
            {
                "price": 12796.43,
                "ask": 35,
                "bid": 46
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc2ff7b134781cb0b4",
        "high": 12832.17,
        "open": 12839.86,
        "close": 12740.39,
        "low": 12807.45,
        "priceList": [
            {
                "price": 12894.43,
                "ask": 69,
                "bid": 38
            },
            {
                "price": 12822.71,
                "ask": 52,
                "bid": 99
            },
            {
                "price": 12754.76,
                "ask": 5,
                "bid": 43
            },
            {
                "price": 12824.46,
                "ask": 8,
                "bid": 68
            },
            {
                "price": 12885.5,
                "ask": 32,
                "bid": 0
            },
            {
                "price": 12962.23,
                "ask": 12,
                "bid": 45
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc3aa1d8ef5ceefb28",
        "high": 12972.71,
        "open": 12738.37,
        "close": 12923.74,
        "low": 12734.11,
        "priceList": [
            {
                "price": 12883.16,
                "ask": 73,
                "bid": 86
            },
            {
                "price": 12729.77,
                "ask": 0,
                "bid": 37
            },
            {
                "price": 12973.73,
                "ask": 3,
                "bid": 72
            },
            {
                "price": 12804.47,
                "ask": 30,
                "bid": 16
            },
            {
                "price": 12940.03,
                "ask": 8,
                "bid": 90
            },
            {
                "price": 12713.52,
                "ask": 2,
                "bid": 26
            },
            {
                "price": 12951.15,
                "ask": 89,
                "bid": 81
            },
            {
                "price": 12815.59,
                "ask": 14,
                "bid": 59
            },
            {
                "price": 12749.77,
                "ask": 45,
                "bid": 14
            },
            {
                "price": 12868.76,
                "ask": 95,
                "bid": 36
            },
            {
                "price": 12989.06,
                "ask": 56,
                "bid": 83
            },
            {
                "price": 12708.45,
                "ask": 76,
                "bid": 72
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc28b3fedf390f029d",
        "high": 12772.37,
        "open": 12838.53,
        "close": 12789.69,
        "low": 12744.18,
        "priceList": [
            {
                "price": 12730.39,
                "ask": 63,
                "bid": 81
            },
            {
                "price": 12760.01,
                "ask": 22,
                "bid": 53
            },
            {
                "price": 12915.07,
                "ask": 26,
                "bid": 65
            },
            {
                "price": 12972.77,
                "ask": 21,
                "bid": 19
            },
            {
                "price": 12910.66,
                "ask": 91,
                "bid": 22
            },
            {
                "price": 12973.93,
                "ask": 27,
                "bid": 89
            },
            {
                "price": 12938.11,
                "ask": 90,
                "bid": 97
            },
            {
                "price": 12836.21,
                "ask": 3,
                "bid": 12
            },
            {
                "price": 12980.72,
                "ask": 20,
                "bid": 91
            },
            {
                "price": 12948.88,
                "ask": 91,
                "bid": 98
            },
            {
                "price": 12712.18,
                "ask": 36,
                "bid": 41
            },
            {
                "price": 12808.72,
                "ask": 96,
                "bid": 34
            },
            {
                "price": 12807.52,
                "ask": 78,
                "bid": 76
            },
            {
                "price": 12718.92,
                "ask": 94,
                "bid": 6
            },
            {
                "price": 12702.79,
                "ask": 13,
                "bid": 78
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6512eb72a975589a",
        "high": 12999.07,
        "open": 12994.13,
        "close": 12738.9,
        "low": 12986.96,
        "priceList": [
            {
                "price": 12945.42,
                "ask": 72,
                "bid": 35
            },
            {
                "price": 12727.53,
                "ask": 23,
                "bid": 77
            },
            {
                "price": 12969.07,
                "ask": 37,
                "bid": 83
            },
            {
                "price": 12968.81,
                "ask": 79,
                "bid": 50
            },
            {
                "price": 12878.49,
                "ask": 3,
                "bid": 98
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc7bbce0e9bcf745b1",
        "high": 12897.66,
        "open": 12839.26,
        "close": 12812.37,
        "low": 12806.47,
        "priceList": [
            {
                "price": 12704.3,
                "ask": 0,
                "bid": 21
            },
            {
                "price": 12865.45,
                "ask": 78,
                "bid": 64
            },
            {
                "price": 12870.22,
                "ask": 61,
                "bid": 91
            },
            {
                "price": 12834.39,
                "ask": 72,
                "bid": 59
            },
            {
                "price": 12726.92,
                "ask": 73,
                "bid": 4
            },
            {
                "price": 12732.05,
                "ask": 43,
                "bid": 1
            },
            {
                "price": 12902.15,
                "ask": 36,
                "bid": 99
            },
            {
                "price": 12880.4,
                "ask": 81,
                "bid": 1
            },
            {
                "price": 12829.03,
                "ask": 5,
                "bid": 28
            },
            {
                "price": 12820.8,
                "ask": 76,
                "bid": 66
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcbe7d6c22987061c8",
        "high": 12978.58,
        "open": 12893.29,
        "close": 12775.8,
        "low": 12814.3,
        "priceList": [
            {
                "price": 12915.31,
                "ask": 50,
                "bid": 73
            },
            {
                "price": 12851.26,
                "ask": 92,
                "bid": 69
            },
            {
                "price": 12796.14,
                "ask": 94,
                "bid": 24
            },
            {
                "price": 12801.25,
                "ask": 8,
                "bid": 69
            },
            {
                "price": 12810.27,
                "ask": 74,
                "bid": 41
            },
            {
                "price": 12933.47,
                "ask": 59,
                "bid": 58
            },
            {
                "price": 12886.48,
                "ask": 63,
                "bid": 56
            },
            {
                "price": 12994.36,
                "ask": 66,
                "bid": 21
            },
            {
                "price": 12867.07,
                "ask": 56,
                "bid": 67
            },
            {
                "price": 12773.93,
                "ask": 21,
                "bid": 54
            },
            {
                "price": 12911.28,
                "ask": 23,
                "bid": 19
            },
            {
                "price": 12933.41,
                "ask": 15,
                "bid": 92
            },
            {
                "price": 12744.14,
                "ask": 87,
                "bid": 82
            },
            {
                "price": 12860.9,
                "ask": 15,
                "bid": 11
            },
            {
                "price": 12893.89,
                "ask": 88,
                "bid": 68
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcd601f9c6adb5b68c",
        "high": 12957.91,
        "open": 12988.34,
        "close": 12751.08,
        "low": 12793.07,
        "priceList": [
            {
                "price": 12743.85,
                "ask": 33,
                "bid": 6
            },
            {
                "price": 12805.6,
                "ask": 83,
                "bid": 55
            },
            {
                "price": 12981.09,
                "ask": 59,
                "bid": 74
            },
            {
                "price": 12922.89,
                "ask": 87,
                "bid": 75
            },
            {
                "price": 12768.78,
                "ask": 11,
                "bid": 4
            },
            {
                "price": 12817.63,
                "ask": 11,
                "bid": 79
            },
            {
                "price": 12945.71,
                "ask": 21,
                "bid": 40
            },
            {
                "price": 12975.62,
                "ask": 68,
                "bid": 6
            },
            {
                "price": 12736.21,
                "ask": 5,
                "bid": 13
            },
            {
                "price": 12976.93,
                "ask": 3,
                "bid": 45
            },
            {
                "price": 12894.55,
                "ask": 64,
                "bid": 45
            },
            {
                "price": 12848.04,
                "ask": 57,
                "bid": 18
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc171a8f15decb2fa3",
        "high": 12723.09,
        "open": 12910.43,
        "close": 12855.24,
        "low": 12863.13,
        "priceList": [
            {
                "price": 12941.76,
                "ask": 72,
                "bid": 35
            },
            {
                "price": 12705.72,
                "ask": 88,
                "bid": 78
            },
            {
                "price": 12872.59,
                "ask": 73,
                "bid": 95
            },
            {
                "price": 12815.98,
                "ask": 69,
                "bid": 57
            },
            {
                "price": 12941.52,
                "ask": 50,
                "bid": 39
            },
            {
                "price": 12711.25,
                "ask": 23,
                "bid": 74
            },
            {
                "price": 12703.85,
                "ask": 100,
                "bid": 44
            },
            {
                "price": 12932.06,
                "ask": 8,
                "bid": 19
            },
            {
                "price": 12947.79,
                "ask": 73,
                "bid": 86
            },
            {
                "price": 12953.86,
                "ask": 18,
                "bid": 34
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dce4505e1a3a0c4765",
        "high": 12714.32,
        "open": 12917.92,
        "close": 12724.42,
        "low": 12827.28,
        "priceList": [
            {
                "price": 12955.68,
                "ask": 34,
                "bid": 48
            },
            {
                "price": 12952.28,
                "ask": 51,
                "bid": 95
            },
            {
                "price": 12717.85,
                "ask": 82,
                "bid": 91
            },
            {
                "price": 12840.78,
                "ask": 21,
                "bid": 23
            },
            {
                "price": 12936.66,
                "ask": 16,
                "bid": 9
            },
            {
                "price": 12825.64,
                "ask": 65,
                "bid": 33
            },
            {
                "price": 12857.89,
                "ask": 53,
                "bid": 92
            },
            {
                "price": 12963.64,
                "ask": 84,
                "bid": 48
            },
            {
                "price": 12760.43,
                "ask": 19,
                "bid": 84
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcee2a41081552bb49",
        "high": 12838.06,
        "open": 12824.47,
        "close": 12910.16,
        "low": 12757.63,
        "priceList": [
            {
                "price": 12720.81,
                "ask": 72,
                "bid": 18
            },
            {
                "price": 12941.65,
                "ask": 2,
                "bid": 24
            },
            {
                "price": 12982.94,
                "ask": 87,
                "bid": 67
            },
            {
                "price": 12983.42,
                "ask": 85,
                "bid": 40
            },
            {
                "price": 12901.29,
                "ask": 53,
                "bid": 81
            },
            {
                "price": 12785.56,
                "ask": 58,
                "bid": 40
            },
            {
                "price": 12730.3,
                "ask": 67,
                "bid": 35
            },
            {
                "price": 12937.53,
                "ask": 95,
                "bid": 37
            },
            {
                "price": 12889.85,
                "ask": 86,
                "bid": 67
            },
            {
                "price": 12866.99,
                "ask": 10,
                "bid": 49
            },
            {
                "price": 12864.76,
                "ask": 92,
                "bid": 58
            },
            {
                "price": 12800.21,
                "ask": 56,
                "bid": 58
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcb781d00f94955964",
        "high": 12872.45,
        "open": 12943.28,
        "close": 12915.04,
        "low": 12715.58,
        "priceList": [
            {
                "price": 12977.54,
                "ask": 68,
                "bid": 63
            },
            {
                "price": 12712.22,
                "ask": 36,
                "bid": 83
            },
            {
                "price": 12928.03,
                "ask": 81,
                "bid": 97
            },
            {
                "price": 12752.74,
                "ask": 100,
                "bid": 31
            },
            {
                "price": 12879.28,
                "ask": 41,
                "bid": 78
            },
            {
                "price": 12750.09,
                "ask": 45,
                "bid": 8
            },
            {
                "price": 12706.41,
                "ask": 59,
                "bid": 30
            },
            {
                "price": 12728.34,
                "ask": 90,
                "bid": 92
            },
            {
                "price": 12932.92,
                "ask": 99,
                "bid": 4
            },
            {
                "price": 12866.54,
                "ask": 85,
                "bid": 90
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcde1fd687c0d09be3",
        "high": 12706.82,
        "open": 12794.44,
        "close": 12743.78,
        "low": 12901.39,
        "priceList": [
            {
                "price": 12873.84,
                "ask": 4,
                "bid": 99
            },
            {
                "price": 12882.24,
                "ask": 60,
                "bid": 27
            },
            {
                "price": 12931.09,
                "ask": 25,
                "bid": 46
            },
            {
                "price": 12786.82,
                "ask": 86,
                "bid": 29
            },
            {
                "price": 12799.2,
                "ask": 95,
                "bid": 45
            },
            {
                "price": 12783.57,
                "ask": 72,
                "bid": 92
            },
            {
                "price": 12717.35,
                "ask": 21,
                "bid": 3
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcecba9d81c1909bf4",
        "high": 12852.78,
        "open": 12917.49,
        "close": 12854.87,
        "low": 12957.67,
        "priceList": [
            {
                "price": 12701.64,
                "ask": 42,
                "bid": 32
            },
            {
                "price": 12879.09,
                "ask": 87,
                "bid": 90
            },
            {
                "price": 12841.1,
                "ask": 60,
                "bid": 63
            },
            {
                "price": 12969.61,
                "ask": 41,
                "bid": 47
            },
            {
                "price": 12905.24,
                "ask": 35,
                "bid": 51
            },
            {
                "price": 12977.29,
                "ask": 70,
                "bid": 30
            },
            {
                "price": 12879.41,
                "ask": 99,
                "bid": 96
            },
            {
                "price": 12725.24,
                "ask": 18,
                "bid": 74
            },
            {
                "price": 12934.14,
                "ask": 99,
                "bid": 88
            },
            {
                "price": 12968.33,
                "ask": 0,
                "bid": 26
            },
            {
                "price": 12843.56,
                "ask": 36,
                "bid": 7
            },
            {
                "price": 12896.89,
                "ask": 44,
                "bid": 87
            },
            {
                "price": 12775.56,
                "ask": 66,
                "bid": 67
            },
            {
                "price": 12914.97,
                "ask": 30,
                "bid": 62
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc511c45f472046666",
        "high": 12837.81,
        "open": 12991.24,
        "close": 12922.02,
        "low": 12875.52,
        "priceList": [
            {
                "price": 12810.15,
                "ask": 94,
                "bid": 76
            },
            {
                "price": 12902.75,
                "ask": 63,
                "bid": 65
            },
            {
                "price": 12934.67,
                "ask": 55,
                "bid": 69
            },
            {
                "price": 12865.03,
                "ask": 31,
                "bid": 54
            },
            {
                "price": 12811.7,
                "ask": 72,
                "bid": 45
            },
            {
                "price": 12810.15,
                "ask": 14,
                "bid": 92
            },
            {
                "price": 12720.46,
                "ask": 62,
                "bid": 83
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcdea1124b83224650",
        "high": 12867.48,
        "open": 12815.84,
        "close": 12781.12,
        "low": 12937.5,
        "priceList": [
            {
                "price": 12906.34,
                "ask": 44,
                "bid": 20
            },
            {
                "price": 12742.85,
                "ask": 100,
                "bid": 72
            },
            {
                "price": 12982.71,
                "ask": 49,
                "bid": 96
            },
            {
                "price": 12775.46,
                "ask": 70,
                "bid": 96
            },
            {
                "price": 12781.29,
                "ask": 92,
                "bid": 70
            },
            {
                "price": 12857.81,
                "ask": 98,
                "bid": 92
            },
            {
                "price": 12792.08,
                "ask": 46,
                "bid": 90
            },
            {
                "price": 12888.35,
                "ask": 41,
                "bid": 22
            },
            {
                "price": 12704.79,
                "ask": 58,
                "bid": 41
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc9d187b70a3382bb9",
        "high": 12704.98,
        "open": 12977.04,
        "close": 12769.19,
        "low": 12778.26,
        "priceList": [
            {
                "price": 12798.29,
                "ask": 11,
                "bid": 41
            },
            {
                "price": 12903.13,
                "ask": 81,
                "bid": 23
            },
            {
                "price": 12997.54,
                "ask": 13,
                "bid": 61
            },
            {
                "price": 12730.65,
                "ask": 62,
                "bid": 24
            },
            {
                "price": 12847.66,
                "ask": 5,
                "bid": 92
            },
            {
                "price": 12799.92,
                "ask": 28,
                "bid": 96
            },
            {
                "price": 12889,
                "ask": 88,
                "bid": 96
            },
            {
                "price": 12934.88,
                "ask": 41,
                "bid": 62
            },
            {
                "price": 12929.55,
                "ask": 30,
                "bid": 74
            },
            {
                "price": 12997.93,
                "ask": 59,
                "bid": 99
            },
            {
                "price": 12963.83,
                "ask": 7,
                "bid": 32
            },
            {
                "price": 12862.64,
                "ask": 64,
                "bid": 44
            },
            {
                "price": 12863.7,
                "ask": 11,
                "bid": 23
            },
            {
                "price": 12889.3,
                "ask": 36,
                "bid": 60
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc5c0f309dbfa86f05",
        "high": 12828.9,
        "open": 12864.06,
        "close": 12969.36,
        "low": 12733.86,
        "priceList": [
            {
                "price": 12862.21,
                "ask": 73,
                "bid": 46
            },
            {
                "price": 12794.08,
                "ask": 67,
                "bid": 10
            },
            {
                "price": 12832.84,
                "ask": 86,
                "bid": 18
            },
            {
                "price": 12952.85,
                "ask": 71,
                "bid": 94
            },
            {
                "price": 12970.06,
                "ask": 70,
                "bid": 69
            },
            {
                "price": 12800.4,
                "ask": 36,
                "bid": 14
            },
            {
                "price": 12953.09,
                "ask": 21,
                "bid": 17
            },
            {
                "price": 12719.22,
                "ask": 88,
                "bid": 89
            },
            {
                "price": 12877.82,
                "ask": 36,
                "bid": 40
            },
            {
                "price": 12916.46,
                "ask": 66,
                "bid": 47
            },
            {
                "price": 12700.21,
                "ask": 12,
                "bid": 91
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4b32a420540c134f",
        "high": 12715.55,
        "open": 12745.86,
        "close": 12956.47,
        "low": 12929.8,
        "priceList": [
            {
                "price": 12783.64,
                "ask": 2,
                "bid": 28
            },
            {
                "price": 12792.89,
                "ask": 69,
                "bid": 3
            },
            {
                "price": 12797.66,
                "ask": 39,
                "bid": 16
            },
            {
                "price": 12761.69,
                "ask": 49,
                "bid": 32
            },
            {
                "price": 12947.12,
                "ask": 76,
                "bid": 37
            },
            {
                "price": 12797.15,
                "ask": 19,
                "bid": 22
            },
            {
                "price": 12855.13,
                "ask": 30,
                "bid": 84
            },
            {
                "price": 12751.54,
                "ask": 53,
                "bid": 99
            },
            {
                "price": 12770.05,
                "ask": 2,
                "bid": 35
            },
            {
                "price": 12923.53,
                "ask": 86,
                "bid": 3
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcba8083fa867d7ef4",
        "high": 12858.65,
        "open": 12876.65,
        "close": 12712.61,
        "low": 12844.46,
        "priceList": [
            {
                "price": 12832.34,
                "ask": 3,
                "bid": 27
            },
            {
                "price": 12945.81,
                "ask": 86,
                "bid": 11
            },
            {
                "price": 12772.62,
                "ask": 57,
                "bid": 75
            },
            {
                "price": 12894.58,
                "ask": 46,
                "bid": 62
            },
            {
                "price": 12748.59,
                "ask": 38,
                "bid": 23
            },
            {
                "price": 12740.84,
                "ask": 34,
                "bid": 35
            },
            {
                "price": 12794.11,
                "ask": 70,
                "bid": 28
            },
            {
                "price": 12795.78,
                "ask": 80,
                "bid": 18
            },
            {
                "price": 12889.14,
                "ask": 16,
                "bid": 46
            },
            {
                "price": 12903.82,
                "ask": 30,
                "bid": 25
            },
            {
                "price": 12942.88,
                "ask": 83,
                "bid": 48
            },
            {
                "price": 12764.43,
                "ask": 11,
                "bid": 86
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcb3cdd3a56cc3eae5",
        "high": 12908.84,
        "open": 12958.93,
        "close": 12928.16,
        "low": 12903.72,
        "priceList": [
            {
                "price": 12858.28,
                "ask": 37,
                "bid": 45
            },
            {
                "price": 12818.56,
                "ask": 2,
                "bid": 56
            },
            {
                "price": 12712.95,
                "ask": 71,
                "bid": 17
            },
            {
                "price": 12770.04,
                "ask": 51,
                "bid": 68
            },
            {
                "price": 12722.46,
                "ask": 38,
                "bid": 85
            },
            {
                "price": 12940.69,
                "ask": 57,
                "bid": 91
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcef0fcf0fd0c3533f",
        "high": 12907.27,
        "open": 12995.29,
        "close": 12939.99,
        "low": 12806.8,
        "priceList": [
            {
                "price": 12932.69,
                "ask": 2,
                "bid": 49
            },
            {
                "price": 12931.84,
                "ask": 62,
                "bid": 59
            },
            {
                "price": 12874.97,
                "ask": 33,
                "bid": 22
            },
            {
                "price": 12878.94,
                "ask": 14,
                "bid": 81
            },
            {
                "price": 12858.88,
                "ask": 28,
                "bid": 70
            },
            {
                "price": 12887.54,
                "ask": 71,
                "bid": 76
            },
            {
                "price": 12741.94,
                "ask": 5,
                "bid": 94
            },
            {
                "price": 12795.96,
                "ask": 16,
                "bid": 2
            },
            {
                "price": 12714.48,
                "ask": 86,
                "bid": 94
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc8fcfd6e73a8a926f",
        "high": 12892.2,
        "open": 12900.53,
        "close": 12898.55,
        "low": 12917.36,
        "priceList": [
            {
                "price": 12801.28,
                "ask": 90,
                "bid": 65
            },
            {
                "price": 12836.14,
                "ask": 1,
                "bid": 89
            },
            {
                "price": 12925.63,
                "ask": 82,
                "bid": 58
            },
            {
                "price": 12738.19,
                "ask": 88,
                "bid": 5
            },
            {
                "price": 12721.36,
                "ask": 63,
                "bid": 86
            },
            {
                "price": 12857.47,
                "ask": 10,
                "bid": 89
            },
            {
                "price": 12803.54,
                "ask": 53,
                "bid": 3
            },
            {
                "price": 12987.9,
                "ask": 7,
                "bid": 69
            },
            {
                "price": 12723.5,
                "ask": 38,
                "bid": 13
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dca6411f80fae2e73e",
        "high": 12778.46,
        "open": 12903.82,
        "close": 12743.04,
        "low": 12826.43,
        "priceList": [
            {
                "price": 12948.34,
                "ask": 57,
                "bid": 70
            },
            {
                "price": 12809,
                "ask": 88,
                "bid": 18
            },
            {
                "price": 12735.96,
                "ask": 53,
                "bid": 55
            },
            {
                "price": 12852.24,
                "ask": 67,
                "bid": 52
            },
            {
                "price": 12748.52,
                "ask": 55,
                "bid": 98
            },
            {
                "price": 12849.54,
                "ask": 41,
                "bid": 44
            },
            {
                "price": 12924.3,
                "ask": 7,
                "bid": 7
            },
            {
                "price": 12920.93,
                "ask": 63,
                "bid": 16
            },
            {
                "price": 12980.61,
                "ask": 34,
                "bid": 22
            },
            {
                "price": 12923.11,
                "ask": 34,
                "bid": 67
            },
            {
                "price": 12874.1,
                "ask": 59,
                "bid": 78
            },
            {
                "price": 12933.33,
                "ask": 67,
                "bid": 58
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc258086d552a74fb7",
        "high": 12832.76,
        "open": 12801.47,
        "close": 12868.35,
        "low": 12796.04,
        "priceList": [
            {
                "price": 12741.75,
                "ask": 37,
                "bid": 3
            },
            {
                "price": 12744.61,
                "ask": 94,
                "bid": 36
            },
            {
                "price": 12909.16,
                "ask": 94,
                "bid": 72
            },
            {
                "price": 12899.7,
                "ask": 33,
                "bid": 0
            },
            {
                "price": 12900.16,
                "ask": 40,
                "bid": 92
            },
            {
                "price": 12864.69,
                "ask": 17,
                "bid": 2
            },
            {
                "price": 12999.24,
                "ask": 37,
                "bid": 33
            },
            {
                "price": 12757.83,
                "ask": 99,
                "bid": 4
            },
            {
                "price": 12822.78,
                "ask": 48,
                "bid": 12
            },
            {
                "price": 12771.3,
                "ask": 89,
                "bid": 77
            },
            {
                "price": 12836.83,
                "ask": 11,
                "bid": 72
            },
            {
                "price": 12791.97,
                "ask": 73,
                "bid": 6
            },
            {
                "price": 12811.68,
                "ask": 8,
                "bid": 62
            },
            {
                "price": 12849.08,
                "ask": 85,
                "bid": 66
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcd345c328667d0e19",
        "high": 12768.9,
        "open": 12749.74,
        "close": 12708.65,
        "low": 12907.2,
        "priceList": [
            {
                "price": 12700.36,
                "ask": 3,
                "bid": 30
            },
            {
                "price": 12714.09,
                "ask": 44,
                "bid": 78
            },
            {
                "price": 12916.39,
                "ask": 81,
                "bid": 11
            },
            {
                "price": 12767.78,
                "ask": 10,
                "bid": 56
            },
            {
                "price": 12820.07,
                "ask": 93,
                "bid": 96
            },
            {
                "price": 12966.02,
                "ask": 2,
                "bid": 10
            },
            {
                "price": 12740,
                "ask": 7,
                "bid": 69
            },
            {
                "price": 12793.93,
                "ask": 65,
                "bid": 54
            },
            {
                "price": 12732.11,
                "ask": 6,
                "bid": 5
            },
            {
                "price": 12902.41,
                "ask": 59,
                "bid": 61
            },
            {
                "price": 12961.14,
                "ask": 97,
                "bid": 60
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dce8b4367ad9128719",
        "high": 12879.75,
        "open": 12852.4,
        "close": 12948.68,
        "low": 12935.88,
        "priceList": [
            {
                "price": 12791.33,
                "ask": 98,
                "bid": 2
            },
            {
                "price": 12788.61,
                "ask": 88,
                "bid": 82
            },
            {
                "price": 12945.03,
                "ask": 13,
                "bid": 81
            },
            {
                "price": 12836.72,
                "ask": 2,
                "bid": 89
            },
            {
                "price": 12964.99,
                "ask": 54,
                "bid": 13
            },
            {
                "price": 12832.12,
                "ask": 78,
                "bid": 24
            },
            {
                "price": 12792.66,
                "ask": 52,
                "bid": 4
            },
            {
                "price": 12938.15,
                "ask": 97,
                "bid": 37
            },
            {
                "price": 12893.24,
                "ask": 84,
                "bid": 18
            },
            {
                "price": 12885.49,
                "ask": 100,
                "bid": 20
            },
            {
                "price": 12908.78,
                "ask": 98,
                "bid": 96
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc403b73ddcdf9cb87",
        "high": 12992.51,
        "open": 12797.6,
        "close": 12730,
        "low": 12930.76,
        "priceList": [
            {
                "price": 12852.55,
                "ask": 19,
                "bid": 99
            },
            {
                "price": 12811.04,
                "ask": 0,
                "bid": 65
            },
            {
                "price": 12976.9,
                "ask": 86,
                "bid": 28
            },
            {
                "price": 12952.84,
                "ask": 89,
                "bid": 54
            },
            {
                "price": 12723.8,
                "ask": 66,
                "bid": 99
            },
            {
                "price": 12986.99,
                "ask": 84,
                "bid": 43
            },
            {
                "price": 12989.23,
                "ask": 80,
                "bid": 53
            },
            {
                "price": 12798.47,
                "ask": 27,
                "bid": 14
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc83ea8188a5d3f7f7",
        "high": 12874.06,
        "open": 12874.97,
        "close": 12899.57,
        "low": 12722.56,
        "priceList": [
            {
                "price": 12743.38,
                "ask": 38,
                "bid": 33
            },
            {
                "price": 12930.74,
                "ask": 68,
                "bid": 6
            },
            {
                "price": 12719.95,
                "ask": 50,
                "bid": 83
            },
            {
                "price": 12847.19,
                "ask": 62,
                "bid": 25
            },
            {
                "price": 12793.53,
                "ask": 64,
                "bid": 83
            },
            {
                "price": 12929.58,
                "ask": 1,
                "bid": 13
            },
            {
                "price": 12883.36,
                "ask": 24,
                "bid": 90
            },
            {
                "price": 12911.41,
                "ask": 6,
                "bid": 33
            },
            {
                "price": 12795.59,
                "ask": 99,
                "bid": 36
            },
            {
                "price": 12932.97,
                "ask": 29,
                "bid": 47
            },
            {
                "price": 12874.41,
                "ask": 61,
                "bid": 85
            },
            {
                "price": 12737.69,
                "ask": 9,
                "bid": 63
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcb76cabe35bcfbb28",
        "high": 12900.86,
        "open": 12865.13,
        "close": 12736.27,
        "low": 12789.53,
        "priceList": [
            {
                "price": 12856.94,
                "ask": 41,
                "bid": 18
            },
            {
                "price": 12961.28,
                "ask": 41,
                "bid": 54
            },
            {
                "price": 12791.17,
                "ask": 56,
                "bid": 45
            },
            {
                "price": 12919.69,
                "ask": 92,
                "bid": 49
            },
            {
                "price": 12909.41,
                "ask": 31,
                "bid": 84
            },
            {
                "price": 12897.79,
                "ask": 1,
                "bid": 49
            },
            {
                "price": 12840.58,
                "ask": 10,
                "bid": 27
            },
            {
                "price": 12825.2,
                "ask": 40,
                "bid": 34
            },
            {
                "price": 12823.86,
                "ask": 14,
                "bid": 50
            },
            {
                "price": 12841.27,
                "ask": 33,
                "bid": 11
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc86b89d173a59d006",
        "high": 12904.24,
        "open": 12815.51,
        "close": 12854.84,
        "low": 12858.05,
        "priceList": [
            {
                "price": 12760.99,
                "ask": 98,
                "bid": 64
            },
            {
                "price": 12768.11,
                "ask": 51,
                "bid": 14
            },
            {
                "price": 12950.62,
                "ask": 87,
                "bid": 74
            },
            {
                "price": 12991.29,
                "ask": 18,
                "bid": 25
            },
            {
                "price": 12960.13,
                "ask": 67,
                "bid": 32
            },
            {
                "price": 12838.03,
                "ask": 16,
                "bid": 14
            },
            {
                "price": 12822.56,
                "ask": 25,
                "bid": 37
            },
            {
                "price": 12840.36,
                "ask": 77,
                "bid": 35
            },
            {
                "price": 12711.06,
                "ask": 95,
                "bid": 53
            },
            {
                "price": 12730.17,
                "ask": 55,
                "bid": 81
            },
            {
                "price": 12885.58,
                "ask": 65,
                "bid": 79
            },
            {
                "price": 12793.4,
                "ask": 18,
                "bid": 51
            },
            {
                "price": 12734.14,
                "ask": 8,
                "bid": 70
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcfa5adeef0aaf6504",
        "high": 12722.28,
        "open": 12713.78,
        "close": 12784.14,
        "low": 12804.69,
        "priceList": [
            {
                "price": 12718.51,
                "ask": 94,
                "bid": 96
            },
            {
                "price": 12847.19,
                "ask": 76,
                "bid": 76
            },
            {
                "price": 12946.57,
                "ask": 40,
                "bid": 90
            },
            {
                "price": 12978.13,
                "ask": 45,
                "bid": 29
            },
            {
                "price": 12781.72,
                "ask": 19,
                "bid": 89
            },
            {
                "price": 12777.18,
                "ask": 70,
                "bid": 71
            },
            {
                "price": 12801,
                "ask": 57,
                "bid": 40
            },
            {
                "price": 12876.25,
                "ask": 78,
                "bid": 93
            },
            {
                "price": 12722.37,
                "ask": 84,
                "bid": 78
            },
            {
                "price": 12844.2,
                "ask": 5,
                "bid": 27
            },
            {
                "price": 12755.19,
                "ask": 9,
                "bid": 30
            },
            {
                "price": 12746.41,
                "ask": 69,
                "bid": 43
            },
            {
                "price": 12943.98,
                "ask": 63,
                "bid": 61
            },
            {
                "price": 12960.17,
                "ask": 99,
                "bid": 57
            },
            {
                "price": 12724.73,
                "ask": 32,
                "bid": 6
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc3027cd458f3243e7",
        "high": 12711.32,
        "open": 12822.02,
        "close": 12831.58,
        "low": 12846.13,
        "priceList": [
            {
                "price": 12898.69,
                "ask": 10,
                "bid": 30
            },
            {
                "price": 12808.73,
                "ask": 1,
                "bid": 77
            },
            {
                "price": 12903.23,
                "ask": 70,
                "bid": 81
            },
            {
                "price": 12790.49,
                "ask": 100,
                "bid": 77
            },
            {
                "price": 12734.52,
                "ask": 22,
                "bid": 87
            },
            {
                "price": 12943.25,
                "ask": 39,
                "bid": 41
            },
            {
                "price": 12927.73,
                "ask": 86,
                "bid": 7
            },
            {
                "price": 12781.97,
                "ask": 86,
                "bid": 37
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dca665a0b46ff416bc",
        "high": 12970.12,
        "open": 12891.9,
        "close": 12786.95,
        "low": 12995.31,
        "priceList": [
            {
                "price": 12787.97,
                "ask": 25,
                "bid": 24
            },
            {
                "price": 12877.71,
                "ask": 23,
                "bid": 51
            },
            {
                "price": 12843.66,
                "ask": 45,
                "bid": 75
            },
            {
                "price": 12781.19,
                "ask": 71,
                "bid": 85
            },
            {
                "price": 12930.14,
                "ask": 3,
                "bid": 21
            },
            {
                "price": 12717.9,
                "ask": 40,
                "bid": 33
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcdef195a2874ab90e",
        "high": 12736.3,
        "open": 12798.19,
        "close": 12746.31,
        "low": 12774.31,
        "priceList": [
            {
                "price": 12875.93,
                "ask": 57,
                "bid": 20
            },
            {
                "price": 12929.18,
                "ask": 95,
                "bid": 37
            },
            {
                "price": 12755.94,
                "ask": 4,
                "bid": 51
            },
            {
                "price": 12763.11,
                "ask": 95,
                "bid": 62
            },
            {
                "price": 12980.37,
                "ask": 66,
                "bid": 14
            },
            {
                "price": 12836.79,
                "ask": 28,
                "bid": 90
            },
            {
                "price": 12715.42,
                "ask": 90,
                "bid": 85
            },
            {
                "price": 12753.51,
                "ask": 50,
                "bid": 87
            },
            {
                "price": 12932.61,
                "ask": 70,
                "bid": 100
            },
            {
                "price": 12963.99,
                "ask": 53,
                "bid": 48
            },
            {
                "price": 12999.68,
                "ask": 3,
                "bid": 98
            },
            {
                "price": 12870.63,
                "ask": 9,
                "bid": 84
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc687e775d617caa15",
        "high": 12962.76,
        "open": 12956.84,
        "close": 12751.77,
        "low": 12949.85,
        "priceList": [
            {
                "price": 12805.78,
                "ask": 53,
                "bid": 88
            },
            {
                "price": 12897.33,
                "ask": 78,
                "bid": 24
            },
            {
                "price": 12976.93,
                "ask": 74,
                "bid": 4
            },
            {
                "price": 12883.19,
                "ask": 10,
                "bid": 77
            },
            {
                "price": 12833.26,
                "ask": 15,
                "bid": 65
            },
            {
                "price": 12799.79,
                "ask": 55,
                "bid": 72
            },
            {
                "price": 12793.65,
                "ask": 57,
                "bid": 15
            },
            {
                "price": 12761.17,
                "ask": 86,
                "bid": 28
            },
            {
                "price": 12747.38,
                "ask": 29,
                "bid": 92
            },
            {
                "price": 12713.76,
                "ask": 52,
                "bid": 67
            },
            {
                "price": 12959.78,
                "ask": 7,
                "bid": 67
            },
            {
                "price": 12895.04,
                "ask": 94,
                "bid": 44
            },
            {
                "price": 12761.28,
                "ask": 84,
                "bid": 75
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcd5f0679729d6aedd",
        "high": 12936.97,
        "open": 12815.72,
        "close": 12843.01,
        "low": 12888.1,
        "priceList": [
            {
                "price": 12782.41,
                "ask": 17,
                "bid": 14
            },
            {
                "price": 12890.59,
                "ask": 30,
                "bid": 79
            },
            {
                "price": 12754.96,
                "ask": 71,
                "bid": 72
            },
            {
                "price": 12934.58,
                "ask": 52,
                "bid": 21
            },
            {
                "price": 12713.31,
                "ask": 98,
                "bid": 0
            },
            {
                "price": 12709.87,
                "ask": 46,
                "bid": 92
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc364fd326c907d38c",
        "high": 12938.25,
        "open": 12947.55,
        "close": 12728.66,
        "low": 12873.23,
        "priceList": [
            {
                "price": 12852.88,
                "ask": 78,
                "bid": 78
            },
            {
                "price": 12796.85,
                "ask": 15,
                "bid": 79
            },
            {
                "price": 12815.84,
                "ask": 48,
                "bid": 46
            },
            {
                "price": 12767.25,
                "ask": 77,
                "bid": 70
            },
            {
                "price": 12862.86,
                "ask": 99,
                "bid": 52
            },
            {
                "price": 12907.58,
                "ask": 9,
                "bid": 68
            },
            {
                "price": 12739.45,
                "ask": 33,
                "bid": 60
            },
            {
                "price": 12724.62,
                "ask": 23,
                "bid": 79
            },
            {
                "price": 12979.04,
                "ask": 89,
                "bid": 34
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcad0f29161da9cb53",
        "high": 12705.07,
        "open": 12702.37,
        "close": 12707.38,
        "low": 12754.35,
        "priceList": [
            {
                "price": 12908.96,
                "ask": 92,
                "bid": 66
            },
            {
                "price": 12870.15,
                "ask": 94,
                "bid": 61
            },
            {
                "price": 12849.93,
                "ask": 16,
                "bid": 32
            },
            {
                "price": 12850,
                "ask": 52,
                "bid": 100
            },
            {
                "price": 12932.4,
                "ask": 62,
                "bid": 80
            },
            {
                "price": 12718.27,
                "ask": 16,
                "bid": 15
            },
            {
                "price": 12856.82,
                "ask": 72,
                "bid": 9
            },
            {
                "price": 12821.63,
                "ask": 73,
                "bid": 53
            },
            {
                "price": 12865.46,
                "ask": 97,
                "bid": 41
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcd18ce281b13db02a",
        "high": 12972.69,
        "open": 12803.3,
        "close": 12820.84,
        "low": 12798.82,
        "priceList": [
            {
                "price": 12755.87,
                "ask": 67,
                "bid": 53
            },
            {
                "price": 12829.37,
                "ask": 87,
                "bid": 88
            },
            {
                "price": 12994.4,
                "ask": 29,
                "bid": 30
            },
            {
                "price": 12940.4,
                "ask": 90,
                "bid": 96
            },
            {
                "price": 12874.12,
                "ask": 5,
                "bid": 48
            },
            {
                "price": 12772.68,
                "ask": 16,
                "bid": 7
            },
            {
                "price": 12743.12,
                "ask": 58,
                "bid": 73
            },
            {
                "price": 12839.8,
                "ask": 61,
                "bid": 20
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc55d035186886cdec",
        "high": 12811.44,
        "open": 12722.64,
        "close": 12887.28,
        "low": 12723.97,
        "priceList": [
            {
                "price": 12938.04,
                "ask": 61,
                "bid": 49
            },
            {
                "price": 12944.17,
                "ask": 27,
                "bid": 21
            },
            {
                "price": 12903.96,
                "ask": 2,
                "bid": 17
            },
            {
                "price": 12750.42,
                "ask": 40,
                "bid": 76
            },
            {
                "price": 12873.18,
                "ask": 53,
                "bid": 57
            },
            {
                "price": 12790.17,
                "ask": 32,
                "bid": 34
            },
            {
                "price": 12867.82,
                "ask": 18,
                "bid": 33
            },
            {
                "price": 12760.38,
                "ask": 87,
                "bid": 33
            },
            {
                "price": 12917.27,
                "ask": 94,
                "bid": 79
            },
            {
                "price": 12809.57,
                "ask": 59,
                "bid": 45
            },
            {
                "price": 12844.79,
                "ask": 86,
                "bid": 96
            },
            {
                "price": 12898.91,
                "ask": 63,
                "bid": 90
            },
            {
                "price": 12757.19,
                "ask": 15,
                "bid": 97
            },
            {
                "price": 12767.56,
                "ask": 39,
                "bid": 76
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcf9cd0bbcf9e69d4d",
        "high": 12706.92,
        "open": 12863.24,
        "close": 12776.1,
        "low": 12844.6,
        "priceList": [
            {
                "price": 12932.78,
                "ask": 94,
                "bid": 86
            },
            {
                "price": 12779.53,
                "ask": 85,
                "bid": 73
            },
            {
                "price": 12789.25,
                "ask": 52,
                "bid": 6
            },
            {
                "price": 12744.11,
                "ask": 92,
                "bid": 2
            },
            {
                "price": 12720.29,
                "ask": 84,
                "bid": 41
            },
            {
                "price": 12781.78,
                "ask": 64,
                "bid": 24
            },
            {
                "price": 12789.6,
                "ask": 52,
                "bid": 62
            },
            {
                "price": 12868.78,
                "ask": 71,
                "bid": 66
            },
            {
                "price": 12805.37,
                "ask": 97,
                "bid": 1
            },
            {
                "price": 12819.81,
                "ask": 16,
                "bid": 4
            },
            {
                "price": 12895.36,
                "ask": 23,
                "bid": 57
            },
            {
                "price": 12761.47,
                "ask": 7,
                "bid": 83
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcc460873fbf5ba792",
        "high": 12742.36,
        "open": 12971.6,
        "close": 12805.8,
        "low": 12875.78,
        "priceList": [
            {
                "price": 12776.9,
                "ask": 6,
                "bid": 32
            },
            {
                "price": 12765,
                "ask": 37,
                "bid": 69
            },
            {
                "price": 12862.63,
                "ask": 43,
                "bid": 40
            },
            {
                "price": 12931.59,
                "ask": 39,
                "bid": 33
            },
            {
                "price": 12762.67,
                "ask": 98,
                "bid": 99
            },
            {
                "price": 12758.76,
                "ask": 54,
                "bid": 99
            },
            {
                "price": 12930.78,
                "ask": 44,
                "bid": 99
            },
            {
                "price": 12871.94,
                "ask": 19,
                "bid": 11
            },
            {
                "price": 12999.7,
                "ask": 23,
                "bid": 87
            },
            {
                "price": 12700.67,
                "ask": 67,
                "bid": 31
            },
            {
                "price": 12710.2,
                "ask": 1,
                "bid": 71
            },
            {
                "price": 12937.46,
                "ask": 15,
                "bid": 89
            },
            {
                "price": 12831.06,
                "ask": 23,
                "bid": 35
            },
            {
                "price": 12823.18,
                "ask": 35,
                "bid": 79
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc5a82530c65aa2019",
        "high": 12761.37,
        "open": 12944.24,
        "close": 12723.57,
        "low": 12899.61,
        "priceList": [
            {
                "price": 12820.51,
                "ask": 67,
                "bid": 33
            },
            {
                "price": 12778.38,
                "ask": 18,
                "bid": 12
            },
            {
                "price": 12957.72,
                "ask": 74,
                "bid": 57
            },
            {
                "price": 12786.4,
                "ask": 71,
                "bid": 95
            },
            {
                "price": 12921.09,
                "ask": 52,
                "bid": 27
            },
            {
                "price": 12724.01,
                "ask": 42,
                "bid": 23
            },
            {
                "price": 12845.32,
                "ask": 93,
                "bid": 7
            },
            {
                "price": 12890.74,
                "ask": 39,
                "bid": 71
            },
            {
                "price": 12765.31,
                "ask": 6,
                "bid": 38
            },
            {
                "price": 12808.25,
                "ask": 4,
                "bid": 49
            },
            {
                "price": 12710.29,
                "ask": 54,
                "bid": 57
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc50b631e094aac2fc",
        "high": 12729.75,
        "open": 12906.76,
        "close": 12761.56,
        "low": 12762.03,
        "priceList": [
            {
                "price": 12749.61,
                "ask": 88,
                "bid": 18
            },
            {
                "price": 12912.71,
                "ask": 32,
                "bid": 78
            },
            {
                "price": 12775.34,
                "ask": 23,
                "bid": 23
            },
            {
                "price": 12709.85,
                "ask": 71,
                "bid": 53
            },
            {
                "price": 12851.67,
                "ask": 68,
                "bid": 36
            },
            {
                "price": 12794.6,
                "ask": 23,
                "bid": 67
            },
            {
                "price": 12701.91,
                "ask": 0,
                "bid": 6
            },
            {
                "price": 12880.34,
                "ask": 39,
                "bid": 62
            },
            {
                "price": 12711.51,
                "ask": 83,
                "bid": 40
            },
            {
                "price": 12836.51,
                "ask": 22,
                "bid": 85
            },
            {
                "price": 12904.11,
                "ask": 0,
                "bid": 47
            },
            {
                "price": 12923.61,
                "ask": 76,
                "bid": 35
            },
            {
                "price": 12796.38,
                "ask": 3,
                "bid": 79
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dce24b26a372726fdf",
        "high": 12901.04,
        "open": 12923.2,
        "close": 12857.82,
        "low": 12856.86,
        "priceList": [
            {
                "price": 12724.1,
                "ask": 62,
                "bid": 76
            },
            {
                "price": 12925.09,
                "ask": 21,
                "bid": 32
            },
            {
                "price": 12715.25,
                "ask": 92,
                "bid": 17
            },
            {
                "price": 12871.68,
                "ask": 91,
                "bid": 94
            },
            {
                "price": 12935.37,
                "ask": 83,
                "bid": 55
            },
            {
                "price": 12998.18,
                "ask": 39,
                "bid": 68
            },
            {
                "price": 12992,
                "ask": 25,
                "bid": 95
            },
            {
                "price": 12960.19,
                "ask": 86,
                "bid": 30
            },
            {
                "price": 12761.84,
                "ask": 3,
                "bid": 10
            },
            {
                "price": 12881.67,
                "ask": 23,
                "bid": 44
            },
            {
                "price": 12705.44,
                "ask": 9,
                "bid": 61
            },
            {
                "price": 12985.62,
                "ask": 90,
                "bid": 7
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc212dcf7e8475fe18",
        "high": 12930.95,
        "open": 12828.67,
        "close": 12795.68,
        "low": 12770.8,
        "priceList": [
            {
                "price": 12989.81,
                "ask": 99,
                "bid": 73
            },
            {
                "price": 12958.36,
                "ask": 63,
                "bid": 14
            },
            {
                "price": 12746.45,
                "ask": 39,
                "bid": 72
            },
            {
                "price": 12996.68,
                "ask": 49,
                "bid": 36
            },
            {
                "price": 12871.63,
                "ask": 27,
                "bid": 25
            },
            {
                "price": 12737.82,
                "ask": 69,
                "bid": 94
            },
            {
                "price": 12804.16,
                "ask": 62,
                "bid": 69
            },
            {
                "price": 12821.01,
                "ask": 23,
                "bid": 26
            },
            {
                "price": 12801.61,
                "ask": 73,
                "bid": 6
            },
            {
                "price": 12861.09,
                "ask": 79,
                "bid": 56
            },
            {
                "price": 12906.27,
                "ask": 88,
                "bid": 50
            },
            {
                "price": 12751.1,
                "ask": 30,
                "bid": 34
            },
            {
                "price": 12857.27,
                "ask": 74,
                "bid": 31
            },
            {
                "price": 12890.92,
                "ask": 6,
                "bid": 55
            },
            {
                "price": 12875.46,
                "ask": 8,
                "bid": 22
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc03b69790fcf7e9e0",
        "high": 12881.35,
        "open": 12967.71,
        "close": 12908.1,
        "low": 12901.38,
        "priceList": [
            {
                "price": 12935.46,
                "ask": 88,
                "bid": 45
            },
            {
                "price": 12900.17,
                "ask": 87,
                "bid": 0
            },
            {
                "price": 12834.47,
                "ask": 23,
                "bid": 100
            },
            {
                "price": 12757.38,
                "ask": 82,
                "bid": 69
            },
            {
                "price": 12713.39,
                "ask": 32,
                "bid": 79
            },
            {
                "price": 12873.16,
                "ask": 78,
                "bid": 18
            },
            {
                "price": 12997.91,
                "ask": 42,
                "bid": 77
            },
            {
                "price": 12925.63,
                "ask": 48,
                "bid": 11
            },
            {
                "price": 12800.72,
                "ask": 2,
                "bid": 15
            },
            {
                "price": 12971.1,
                "ask": 8,
                "bid": 81
            },
            {
                "price": 12871.29,
                "ask": 76,
                "bid": 83
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc2fbe826d7033eb2f",
        "high": 12961.69,
        "open": 12884.39,
        "close": 12951,
        "low": 12889.06,
        "priceList": [
            {
                "price": 12774.2,
                "ask": 71,
                "bid": 22
            },
            {
                "price": 12901.74,
                "ask": 5,
                "bid": 31
            },
            {
                "price": 12824.54,
                "ask": 61,
                "bid": 31
            },
            {
                "price": 12756.55,
                "ask": 74,
                "bid": 71
            },
            {
                "price": 12799.89,
                "ask": 43,
                "bid": 44
            },
            {
                "price": 12966.34,
                "ask": 15,
                "bid": 52
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcc9efb6e5f140c117",
        "high": 12872.15,
        "open": 12713.19,
        "close": 12847.42,
        "low": 12708.43,
        "priceList": [
            {
                "price": 12882.24,
                "ask": 89,
                "bid": 85
            },
            {
                "price": 12728.39,
                "ask": 100,
                "bid": 4
            },
            {
                "price": 12736.33,
                "ask": 23,
                "bid": 83
            },
            {
                "price": 12781.38,
                "ask": 68,
                "bid": 82
            },
            {
                "price": 12851.65,
                "ask": 42,
                "bid": 68
            },
            {
                "price": 12855.69,
                "ask": 17,
                "bid": 79
            },
            {
                "price": 12904.39,
                "ask": 28,
                "bid": 18
            },
            {
                "price": 12982.49,
                "ask": 10,
                "bid": 61
            },
            {
                "price": 12847.19,
                "ask": 75,
                "bid": 27
            },
            {
                "price": 12806.53,
                "ask": 98,
                "bid": 94
            },
            {
                "price": 12934.97,
                "ask": 89,
                "bid": 29
            },
            {
                "price": 12719.95,
                "ask": 12,
                "bid": 39
            },
            {
                "price": 12809.43,
                "ask": 93,
                "bid": 46
            },
            {
                "price": 12993.03,
                "ask": 48,
                "bid": 11
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc20dc63080ec6042a",
        "high": 12918.46,
        "open": 12825.01,
        "close": 12803.73,
        "low": 12807.04,
        "priceList": [
            {
                "price": 12974.67,
                "ask": 38,
                "bid": 45
            },
            {
                "price": 12872.56,
                "ask": 20,
                "bid": 23
            },
            {
                "price": 12935.67,
                "ask": 88,
                "bid": 82
            },
            {
                "price": 12801.78,
                "ask": 25,
                "bid": 53
            },
            {
                "price": 12860,
                "ask": 63,
                "bid": 58
            },
            {
                "price": 12773.99,
                "ask": 34,
                "bid": 40
            },
            {
                "price": 12956.6,
                "ask": 6,
                "bid": 49
            },
            {
                "price": 12924.44,
                "ask": 51,
                "bid": 12
            },
            {
                "price": 12821.22,
                "ask": 8,
                "bid": 76
            },
            {
                "price": 12799.07,
                "ask": 14,
                "bid": 25
            },
            {
                "price": 12935.68,
                "ask": 29,
                "bid": 66
            },
            {
                "price": 12743.29,
                "ask": 8,
                "bid": 6
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc2c1e6ee98b66cb4a",
        "high": 12805.97,
        "open": 12996.62,
        "close": 12783.74,
        "low": 12752.44,
        "priceList": [
            {
                "price": 12804.84,
                "ask": 84,
                "bid": 76
            },
            {
                "price": 12841.48,
                "ask": 89,
                "bid": 62
            },
            {
                "price": 12850.62,
                "ask": 16,
                "bid": 30
            },
            {
                "price": 12940.71,
                "ask": 90,
                "bid": 30
            },
            {
                "price": 12885.75,
                "ask": 2,
                "bid": 25
            },
            {
                "price": 12708.66,
                "ask": 52,
                "bid": 51
            },
            {
                "price": 12869.38,
                "ask": 70,
                "bid": 13
            },
            {
                "price": 12778.16,
                "ask": 97,
                "bid": 12
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc5339abe7af5bb9a5",
        "high": 12720.95,
        "open": 12982.61,
        "close": 12968.05,
        "low": 12866.3,
        "priceList": [
            {
                "price": 12761.31,
                "ask": 19,
                "bid": 56
            },
            {
                "price": 12898.31,
                "ask": 8,
                "bid": 32
            },
            {
                "price": 12866.14,
                "ask": 46,
                "bid": 75
            },
            {
                "price": 12918.31,
                "ask": 39,
                "bid": 69
            },
            {
                "price": 12727.24,
                "ask": 71,
                "bid": 45
            },
            {
                "price": 12768.31,
                "ask": 4,
                "bid": 85
            },
            {
                "price": 12963.85,
                "ask": 14,
                "bid": 21
            },
            {
                "price": 12841.62,
                "ask": 70,
                "bid": 4
            },
            {
                "price": 12917.24,
                "ask": 55,
                "bid": 29
            },
            {
                "price": 12711.1,
                "ask": 36,
                "bid": 60
            },
            {
                "price": 12712.82,
                "ask": 19,
                "bid": 53
            },
            {
                "price": 12856.86,
                "ask": 50,
                "bid": 97
            },
            {
                "price": 12757.84,
                "ask": 93,
                "bid": 59
            },
            {
                "price": 12896.24,
                "ask": 83,
                "bid": 25
            },
            {
                "price": 12948.59,
                "ask": 87,
                "bid": 2
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc24ad5ef05e6a14ab",
        "high": 12835.11,
        "open": 12925.19,
        "close": 12956.47,
        "low": 12797.86,
        "priceList": [
            {
                "price": 12713.65,
                "ask": 70,
                "bid": 14
            },
            {
                "price": 12896.46,
                "ask": 22,
                "bid": 81
            },
            {
                "price": 12851.92,
                "ask": 20,
                "bid": 63
            },
            {
                "price": 12709,
                "ask": 48,
                "bid": 16
            },
            {
                "price": 12981.53,
                "ask": 74,
                "bid": 29
            },
            {
                "price": 12820.01,
                "ask": 5,
                "bid": 55
            },
            {
                "price": 12773.58,
                "ask": 69,
                "bid": 14
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc3f4579ea65034338",
        "high": 12907.59,
        "open": 12885.47,
        "close": 12793.65,
        "low": 12845.01,
        "priceList": [
            {
                "price": 12718.48,
                "ask": 22,
                "bid": 7
            },
            {
                "price": 12732.67,
                "ask": 2,
                "bid": 97
            },
            {
                "price": 12891.06,
                "ask": 75,
                "bid": 75
            },
            {
                "price": 12833.83,
                "ask": 3,
                "bid": 68
            },
            {
                "price": 12987.37,
                "ask": 33,
                "bid": 6
            },
            {
                "price": 12997.87,
                "ask": 32,
                "bid": 28
            },
            {
                "price": 12768.07,
                "ask": 88,
                "bid": 64
            },
            {
                "price": 12762.79,
                "ask": 45,
                "bid": 71
            },
            {
                "price": 12929.22,
                "ask": 10,
                "bid": 78
            },
            {
                "price": 12977.9,
                "ask": 63,
                "bid": 39
            },
            {
                "price": 12987.61,
                "ask": 74,
                "bid": 47
            },
            {
                "price": 12982.84,
                "ask": 94,
                "bid": 44
            },
            {
                "price": 12873.39,
                "ask": 26,
                "bid": 56
            },
            {
                "price": 12798.63,
                "ask": 72,
                "bid": 45
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcc12ff5b816bf674b",
        "high": 12759.63,
        "open": 12796.76,
        "close": 12890.77,
        "low": 12751.44,
        "priceList": [
            {
                "price": 12729.92,
                "ask": 72,
                "bid": 20
            },
            {
                "price": 12773.26,
                "ask": 89,
                "bid": 22
            },
            {
                "price": 12989.97,
                "ask": 52,
                "bid": 91
            },
            {
                "price": 12766.37,
                "ask": 97,
                "bid": 0
            },
            {
                "price": 12956.2,
                "ask": 24,
                "bid": 67
            },
            {
                "price": 12801.69,
                "ask": 8,
                "bid": 15
            },
            {
                "price": 12977.7,
                "ask": 83,
                "bid": 12
            },
            {
                "price": 12787.17,
                "ask": 42,
                "bid": 5
            },
            {
                "price": 12731.87,
                "ask": 47,
                "bid": 6
            },
            {
                "price": 12887.91,
                "ask": 17,
                "bid": 26
            },
            {
                "price": 12976.23,
                "ask": 57,
                "bid": 97
            },
            {
                "price": 12895.86,
                "ask": 16,
                "bid": 13
            },
            {
                "price": 12971.77,
                "ask": 43,
                "bid": 3
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf8cf984a7410f23a",
        "high": 12720.64,
        "open": 12794.98,
        "close": 12774.83,
        "low": 12844.35,
        "priceList": [
            {
                "price": 12805.31,
                "ask": 23,
                "bid": 47
            },
            {
                "price": 12925.17,
                "ask": 68,
                "bid": 74
            },
            {
                "price": 12886.38,
                "ask": 66,
                "bid": 27
            },
            {
                "price": 12878.96,
                "ask": 40,
                "bid": 30
            },
            {
                "price": 12848.71,
                "ask": 68,
                "bid": 50
            },
            {
                "price": 12902.49,
                "ask": 20,
                "bid": 17
            },
            {
                "price": 12748.3,
                "ask": 42,
                "bid": 64
            },
            {
                "price": 12785.34,
                "ask": 99,
                "bid": 75
            },
            {
                "price": 12724.05,
                "ask": 98,
                "bid": 100
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc2d6e5ff41ed511cd",
        "high": 12811.73,
        "open": 12761.38,
        "close": 12817.11,
        "low": 12899.65,
        "priceList": [
            {
                "price": 12738.7,
                "ask": 44,
                "bid": 50
            },
            {
                "price": 12711.89,
                "ask": 42,
                "bid": 73
            },
            {
                "price": 12883.66,
                "ask": 100,
                "bid": 76
            },
            {
                "price": 12913.59,
                "ask": 49,
                "bid": 37
            },
            {
                "price": 12745.38,
                "ask": 51,
                "bid": 2
            },
            {
                "price": 12741.48,
                "ask": 51,
                "bid": 59
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc90e48b3c8540d8a1",
        "high": 12979.48,
        "open": 12744.49,
        "close": 12730.48,
        "low": 12876.63,
        "priceList": [
            {
                "price": 12947.53,
                "ask": 100,
                "bid": 16
            },
            {
                "price": 12703.37,
                "ask": 91,
                "bid": 14
            },
            {
                "price": 12894.54,
                "ask": 57,
                "bid": 15
            },
            {
                "price": 12880.06,
                "ask": 60,
                "bid": 58
            },
            {
                "price": 12801.32,
                "ask": 70,
                "bid": 52
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc1316f2a1571548ab",
        "high": 12807.56,
        "open": 12767.99,
        "close": 12961.58,
        "low": 12858.87,
        "priceList": [
            {
                "price": 12880.15,
                "ask": 10,
                "bid": 33
            },
            {
                "price": 12939.26,
                "ask": 58,
                "bid": 7
            },
            {
                "price": 12996.57,
                "ask": 21,
                "bid": 20
            },
            {
                "price": 12769.17,
                "ask": 15,
                "bid": 42
            },
            {
                "price": 12794.11,
                "ask": 91,
                "bid": 55
            },
            {
                "price": 12912.36,
                "ask": 15,
                "bid": 22
            },
            {
                "price": 12957.5,
                "ask": 7,
                "bid": 33
            },
            {
                "price": 12758.45,
                "ask": 28,
                "bid": 72
            },
            {
                "price": 12830.29,
                "ask": 61,
                "bid": 58
            },
            {
                "price": 12884.73,
                "ask": 31,
                "bid": 1
            },
            {
                "price": 12871.83,
                "ask": 56,
                "bid": 17
            },
            {
                "price": 12854.81,
                "ask": 80,
                "bid": 17
            },
            {
                "price": 12924.18,
                "ask": 59,
                "bid": 81
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc65ad7d16085cdd79",
        "high": 12767.71,
        "open": 12704.51,
        "close": 12790.21,
        "low": 12846.91,
        "priceList": [
            {
                "price": 12869.48,
                "ask": 40,
                "bid": 41
            },
            {
                "price": 12915.73,
                "ask": 29,
                "bid": 25
            },
            {
                "price": 12869.51,
                "ask": 61,
                "bid": 97
            },
            {
                "price": 12924.57,
                "ask": 89,
                "bid": 4
            },
            {
                "price": 12808.54,
                "ask": 20,
                "bid": 88
            },
            {
                "price": 12814.8,
                "ask": 89,
                "bid": 2
            },
            {
                "price": 12811.81,
                "ask": 9,
                "bid": 54
            },
            {
                "price": 12740.48,
                "ask": 16,
                "bid": 11
            },
            {
                "price": 12868.46,
                "ask": 67,
                "bid": 30
            },
            {
                "price": 12782.7,
                "ask": 76,
                "bid": 12
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc1051e04e7a283736",
        "high": 12754.52,
        "open": 12961.89,
        "close": 12870.5,
        "low": 12754.7,
        "priceList": [
            {
                "price": 12715.17,
                "ask": 34,
                "bid": 99
            },
            {
                "price": 12846.81,
                "ask": 92,
                "bid": 48
            },
            {
                "price": 12790.43,
                "ask": 26,
                "bid": 55
            },
            {
                "price": 12994.97,
                "ask": 82,
                "bid": 19
            },
            {
                "price": 12993.22,
                "ask": 79,
                "bid": 80
            },
            {
                "price": 12885.87,
                "ask": 22,
                "bid": 81
            },
            {
                "price": 12932.02,
                "ask": 1,
                "bid": 76
            },
            {
                "price": 12875.76,
                "ask": 92,
                "bid": 98
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcc1f0068e02627d58",
        "high": 12706.89,
        "open": 12768.77,
        "close": 12759.47,
        "low": 12781.54,
        "priceList": [
            {
                "price": 12863.5,
                "ask": 97,
                "bid": 57
            },
            {
                "price": 12968.88,
                "ask": 96,
                "bid": 14
            },
            {
                "price": 12779.6,
                "ask": 91,
                "bid": 9
            },
            {
                "price": 12906.63,
                "ask": 61,
                "bid": 86
            },
            {
                "price": 12878.7,
                "ask": 35,
                "bid": 74
            },
            {
                "price": 12774.39,
                "ask": 5,
                "bid": 57
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc8b93df5fe74601d1",
        "high": 12896.36,
        "open": 12788.23,
        "close": 12712.64,
        "low": 12864.78,
        "priceList": [
            {
                "price": 12975.32,
                "ask": 35,
                "bid": 39
            },
            {
                "price": 12940.97,
                "ask": 86,
                "bid": 70
            },
            {
                "price": 12803.37,
                "ask": 9,
                "bid": 22
            },
            {
                "price": 12923.46,
                "ask": 35,
                "bid": 45
            },
            {
                "price": 12892.04,
                "ask": 59,
                "bid": 65
            },
            {
                "price": 12934.7,
                "ask": 99,
                "bid": 94
            },
            {
                "price": 12926.48,
                "ask": 25,
                "bid": 31
            },
            {
                "price": 12989.32,
                "ask": 64,
                "bid": 51
            },
            {
                "price": 12942.95,
                "ask": 80,
                "bid": 94
            },
            {
                "price": 12843.02,
                "ask": 83,
                "bid": 26
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dccc24126449620a38",
        "high": 12770.71,
        "open": 12807.57,
        "close": 12894.52,
        "low": 12756.94,
        "priceList": [
            {
                "price": 12711.79,
                "ask": 68,
                "bid": 22
            },
            {
                "price": 12949.34,
                "ask": 76,
                "bid": 18
            },
            {
                "price": 12834.32,
                "ask": 64,
                "bid": 58
            },
            {
                "price": 12870.66,
                "ask": 42,
                "bid": 62
            },
            {
                "price": 12905.61,
                "ask": 12,
                "bid": 98
            },
            {
                "price": 12850.75,
                "ask": 21,
                "bid": 37
            },
            {
                "price": 12801.03,
                "ask": 10,
                "bid": 50
            },
            {
                "price": 12942.58,
                "ask": 16,
                "bid": 52
            },
            {
                "price": 12737.35,
                "ask": 41,
                "bid": 55
            },
            {
                "price": 12951.54,
                "ask": 95,
                "bid": 2
            },
            {
                "price": 12860.59,
                "ask": 29,
                "bid": 49
            },
            {
                "price": 12797.84,
                "ask": 82,
                "bid": 2
            },
            {
                "price": 12887.71,
                "ask": 77,
                "bid": 92
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc8016aa0d5db3bc4c",
        "high": 12721.41,
        "open": 12829.03,
        "close": 12781.88,
        "low": 12975.35,
        "priceList": [
            {
                "price": 12882.22,
                "ask": 20,
                "bid": 42
            },
            {
                "price": 12867.46,
                "ask": 11,
                "bid": 53
            },
            {
                "price": 12702.25,
                "ask": 86,
                "bid": 5
            },
            {
                "price": 12721.17,
                "ask": 55,
                "bid": 61
            },
            {
                "price": 12813.28,
                "ask": 8,
                "bid": 4
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc36810b78bfb90b97",
        "high": 12762.18,
        "open": 12859.82,
        "close": 12765.96,
        "low": 12768.15,
        "priceList": [
            {
                "price": 12786.41,
                "ask": 93,
                "bid": 31
            },
            {
                "price": 12743.88,
                "ask": 42,
                "bid": 55
            },
            {
                "price": 12719.77,
                "ask": 3,
                "bid": 83
            },
            {
                "price": 12896.24,
                "ask": 84,
                "bid": 45
            },
            {
                "price": 12988.58,
                "ask": 63,
                "bid": 59
            },
            {
                "price": 12964.27,
                "ask": 71,
                "bid": 23
            },
            {
                "price": 12869.08,
                "ask": 0,
                "bid": 97
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dce0fa597e947da0ed",
        "high": 12989.8,
        "open": 12898.26,
        "close": 12738.89,
        "low": 12870.85,
        "priceList": [
            {
                "price": 12812.37,
                "ask": 46,
                "bid": 75
            },
            {
                "price": 12837.93,
                "ask": 10,
                "bid": 94
            },
            {
                "price": 12953.98,
                "ask": 62,
                "bid": 52
            },
            {
                "price": 12950.34,
                "ask": 9,
                "bid": 8
            },
            {
                "price": 12755.61,
                "ask": 90,
                "bid": 83
            },
            {
                "price": 12866.83,
                "ask": 5,
                "bid": 37
            },
            {
                "price": 12849.83,
                "ask": 79,
                "bid": 11
            },
            {
                "price": 12850.66,
                "ask": 8,
                "bid": 47
            },
            {
                "price": 12885.65,
                "ask": 45,
                "bid": 70
            },
            {
                "price": 12782.82,
                "ask": 19,
                "bid": 59
            },
            {
                "price": 12904.38,
                "ask": 71,
                "bid": 73
            },
            {
                "price": 12997.98,
                "ask": 93,
                "bid": 40
            },
            {
                "price": 12988.98,
                "ask": 92,
                "bid": 97
            },
            {
                "price": 12980.83,
                "ask": 16,
                "bid": 95
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc57457c86b7c41412",
        "high": 12928.31,
        "open": 12925.59,
        "close": 12973.93,
        "low": 12828.25,
        "priceList": [
            {
                "price": 12879.24,
                "ask": 67,
                "bid": 98
            },
            {
                "price": 12852.19,
                "ask": 77,
                "bid": 95
            },
            {
                "price": 12882.39,
                "ask": 64,
                "bid": 3
            },
            {
                "price": 12703.85,
                "ask": 15,
                "bid": 12
            },
            {
                "price": 12886.3,
                "ask": 91,
                "bid": 89
            },
            {
                "price": 12746.87,
                "ask": 70,
                "bid": 20
            },
            {
                "price": 12914.96,
                "ask": 87,
                "bid": 54
            },
            {
                "price": 12824.43,
                "ask": 5,
                "bid": 48
            },
            {
                "price": 12700.33,
                "ask": 67,
                "bid": 37
            },
            {
                "price": 12867.76,
                "ask": 51,
                "bid": 77
            },
            {
                "price": 12920.87,
                "ask": 2,
                "bid": 55
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcac3a287b259b4621",
        "high": 12839.26,
        "open": 12884.62,
        "close": 12965.6,
        "low": 12871.62,
        "priceList": [
            {
                "price": 12761.72,
                "ask": 70,
                "bid": 36
            },
            {
                "price": 12722.84,
                "ask": 25,
                "bid": 62
            },
            {
                "price": 12852.47,
                "ask": 74,
                "bid": 67
            },
            {
                "price": 12916.68,
                "ask": 62,
                "bid": 21
            },
            {
                "price": 12872.5,
                "ask": 72,
                "bid": 97
            },
            {
                "price": 12849.23,
                "ask": 68,
                "bid": 20
            },
            {
                "price": 12833.85,
                "ask": 41,
                "bid": 89
            },
            {
                "price": 12973.35,
                "ask": 43,
                "bid": 32
            },
            {
                "price": 12940.81,
                "ask": 42,
                "bid": 48
            },
            {
                "price": 12852.02,
                "ask": 27,
                "bid": 40
            },
            {
                "price": 12783.64,
                "ask": 20,
                "bid": 72
            },
            {
                "price": 12857.95,
                "ask": 33,
                "bid": 18
            },
            {
                "price": 12759.98,
                "ask": 97,
                "bid": 62
            },
            {
                "price": 12922.65,
                "ask": 48,
                "bid": 26
            },
            {
                "price": 12798.33,
                "ask": 58,
                "bid": 89
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dccb0abe4b18fc185a",
        "high": 12963.36,
        "open": 12784.26,
        "close": 12970.88,
        "low": 12758.95,
        "priceList": [
            {
                "price": 12802.96,
                "ask": 100,
                "bid": 26
            },
            {
                "price": 12946.02,
                "ask": 96,
                "bid": 50
            },
            {
                "price": 12830.4,
                "ask": 80,
                "bid": 33
            },
            {
                "price": 12903.45,
                "ask": 80,
                "bid": 40
            },
            {
                "price": 12814.24,
                "ask": 86,
                "bid": 56
            },
            {
                "price": 12973.43,
                "ask": 46,
                "bid": 14
            },
            {
                "price": 12938.91,
                "ask": 70,
                "bid": 93
            },
            {
                "price": 12861.98,
                "ask": 13,
                "bid": 23
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc335eca882ed8cff6",
        "high": 12747.59,
        "open": 12830.51,
        "close": 12833.07,
        "low": 12933.8,
        "priceList": [
            {
                "price": 12827.95,
                "ask": 11,
                "bid": 25
            },
            {
                "price": 12874.15,
                "ask": 90,
                "bid": 11
            },
            {
                "price": 12812.34,
                "ask": 2,
                "bid": 48
            },
            {
                "price": 12774.19,
                "ask": 70,
                "bid": 31
            },
            {
                "price": 12798.36,
                "ask": 20,
                "bid": 74
            },
            {
                "price": 12891.1,
                "ask": 15,
                "bid": 77
            },
            {
                "price": 12730.85,
                "ask": 7,
                "bid": 1
            },
            {
                "price": 12740.08,
                "ask": 21,
                "bid": 11
            },
            {
                "price": 12712.98,
                "ask": 64,
                "bid": 56
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc282d8bcc5c8e10de",
        "high": 12789.56,
        "open": 12902.69,
        "close": 12777.75,
        "low": 12852,
        "priceList": [
            {
                "price": 12847.17,
                "ask": 67,
                "bid": 67
            },
            {
                "price": 12773.72,
                "ask": 14,
                "bid": 12
            },
            {
                "price": 12852.81,
                "ask": 26,
                "bid": 20
            },
            {
                "price": 12861.73,
                "ask": 73,
                "bid": 64
            },
            {
                "price": 12706.52,
                "ask": 50,
                "bid": 89
            },
            {
                "price": 12744.95,
                "ask": 61,
                "bid": 24
            },
            {
                "price": 12889.9,
                "ask": 86,
                "bid": 47
            },
            {
                "price": 12775.84,
                "ask": 17,
                "bid": 30
            },
            {
                "price": 12738.05,
                "ask": 74,
                "bid": 75
            },
            {
                "price": 12987.06,
                "ask": 93,
                "bid": 48
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc533ab198af478d2d",
        "high": 12812.25,
        "open": 12862.11,
        "close": 12842.88,
        "low": 12961.9,
        "priceList": [
            {
                "price": 12931.95,
                "ask": 2,
                "bid": 24
            },
            {
                "price": 12730.3,
                "ask": 20,
                "bid": 29
            },
            {
                "price": 12954.17,
                "ask": 88,
                "bid": 29
            },
            {
                "price": 12965.26,
                "ask": 43,
                "bid": 84
            },
            {
                "price": 12811.87,
                "ask": 57,
                "bid": 43
            },
            {
                "price": 12979.9,
                "ask": 87,
                "bid": 85
            },
            {
                "price": 12718.03,
                "ask": 80,
                "bid": 44
            },
            {
                "price": 12812.37,
                "ask": 100,
                "bid": 71
            },
            {
                "price": 12980.1,
                "ask": 65,
                "bid": 49
            },
            {
                "price": 12841.7,
                "ask": 51,
                "bid": 70
            },
            {
                "price": 12734.09,
                "ask": 18,
                "bid": 38
            },
            {
                "price": 12935.35,
                "ask": 18,
                "bid": 8
            },
            {
                "price": 12758.84,
                "ask": 16,
                "bid": 62
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf983856790761b3e",
        "high": 12946.97,
        "open": 12975.15,
        "close": 12955.81,
        "low": 12966.4,
        "priceList": [
            {
                "price": 12919.57,
                "ask": 71,
                "bid": 40
            },
            {
                "price": 12744.9,
                "ask": 45,
                "bid": 85
            },
            {
                "price": 12972.27,
                "ask": 84,
                "bid": 73
            },
            {
                "price": 12852.79,
                "ask": 56,
                "bid": 95
            },
            {
                "price": 12760.88,
                "ask": 95,
                "bid": 38
            },
            {
                "price": 12801.89,
                "ask": 6,
                "bid": 25
            },
            {
                "price": 12730.38,
                "ask": 17,
                "bid": 21
            },
            {
                "price": 12731.32,
                "ask": 53,
                "bid": 72
            },
            {
                "price": 12992.7,
                "ask": 19,
                "bid": 76
            },
            {
                "price": 12745.53,
                "ask": 40,
                "bid": 63
            },
            {
                "price": 12865.3,
                "ask": 74,
                "bid": 43
            },
            {
                "price": 12972.15,
                "ask": 67,
                "bid": 14
            },
            {
                "price": 12737.45,
                "ask": 48,
                "bid": 83
            },
            {
                "price": 12723.61,
                "ask": 18,
                "bid": 45
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcf1b560fff1f10240",
        "high": 12912.22,
        "open": 12722.61,
        "close": 12833.71,
        "low": 12827.19,
        "priceList": [
            {
                "price": 12930.26,
                "ask": 43,
                "bid": 71
            },
            {
                "price": 12998.68,
                "ask": 47,
                "bid": 40
            },
            {
                "price": 12837.15,
                "ask": 75,
                "bid": 72
            },
            {
                "price": 12833.97,
                "ask": 83,
                "bid": 9
            },
            {
                "price": 12785.98,
                "ask": 79,
                "bid": 53
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc95b2fd92afc4353f",
        "high": 12987.93,
        "open": 12753.67,
        "close": 12818.21,
        "low": 12928.02,
        "priceList": [
            {
                "price": 12941.02,
                "ask": 21,
                "bid": 51
            },
            {
                "price": 12853.09,
                "ask": 89,
                "bid": 57
            },
            {
                "price": 12782.4,
                "ask": 79,
                "bid": 30
            },
            {
                "price": 12866,
                "ask": 82,
                "bid": 22
            },
            {
                "price": 12729.17,
                "ask": 70,
                "bid": 68
            },
            {
                "price": 12952.27,
                "ask": 99,
                "bid": 47
            },
            {
                "price": 12861.42,
                "ask": 91,
                "bid": 51
            },
            {
                "price": 12711.98,
                "ask": 92,
                "bid": 76
            },
            {
                "price": 12870.38,
                "ask": 16,
                "bid": 0
            },
            {
                "price": 12799.33,
                "ask": 24,
                "bid": 86
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcd41bcf1af4f889b5",
        "high": 12985.16,
        "open": 12883.96,
        "close": 12720.37,
        "low": 12802.23,
        "priceList": [
            {
                "price": 12731.13,
                "ask": 27,
                "bid": 52
            },
            {
                "price": 12869.78,
                "ask": 92,
                "bid": 90
            },
            {
                "price": 12829.75,
                "ask": 100,
                "bid": 74
            },
            {
                "price": 12859.9,
                "ask": 75,
                "bid": 7
            },
            {
                "price": 12768.24,
                "ask": 37,
                "bid": 8
            },
            {
                "price": 12829.88,
                "ask": 51,
                "bid": 23
            },
            {
                "price": 12896.9,
                "ask": 79,
                "bid": 17
            },
            {
                "price": 12956.07,
                "ask": 53,
                "bid": 38
            },
            {
                "price": 12867.72,
                "ask": 19,
                "bid": 84
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dceab85b38e71e2024",
        "high": 12867.7,
        "open": 12742.1,
        "close": 12857.77,
        "low": 12868.19,
        "priceList": [
            {
                "price": 12964.15,
                "ask": 7,
                "bid": 92
            },
            {
                "price": 12715.11,
                "ask": 44,
                "bid": 57
            },
            {
                "price": 12763.84,
                "ask": 36,
                "bid": 54
            },
            {
                "price": 12734.59,
                "ask": 61,
                "bid": 21
            },
            {
                "price": 12962.3,
                "ask": 47,
                "bid": 56
            },
            {
                "price": 12902.51,
                "ask": 92,
                "bid": 39
            },
            {
                "price": 12867.1,
                "ask": 70,
                "bid": 72
            },
            {
                "price": 12955.83,
                "ask": 54,
                "bid": 64
            },
            {
                "price": 12815.94,
                "ask": 16,
                "bid": 85
            },
            {
                "price": 12962.89,
                "ask": 88,
                "bid": 57
            },
            {
                "price": 12845.93,
                "ask": 6,
                "bid": 80
            },
            {
                "price": 12783.66,
                "ask": 85,
                "bid": 34
            },
            {
                "price": 12880.7,
                "ask": 58,
                "bid": 14
            },
            {
                "price": 12844.96,
                "ask": 45,
                "bid": 33
            },
            {
                "price": 12964.95,
                "ask": 46,
                "bid": 88
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc4dd22a32ddce2ef5",
        "high": 12974.86,
        "open": 12960.24,
        "close": 12937.47,
        "low": 12979.79,
        "priceList": [
            {
                "price": 12774.21,
                "ask": 32,
                "bid": 7
            },
            {
                "price": 12720.21,
                "ask": 43,
                "bid": 47
            },
            {
                "price": 12843.22,
                "ask": 66,
                "bid": 31
            },
            {
                "price": 12783.49,
                "ask": 2,
                "bid": 39
            },
            {
                "price": 12917.51,
                "ask": 27,
                "bid": 50
            },
            {
                "price": 12799.29,
                "ask": 32,
                "bid": 75
            },
            {
                "price": 12767.1,
                "ask": 25,
                "bid": 74
            },
            {
                "price": 12948.22,
                "ask": 19,
                "bid": 68
            },
            {
                "price": 12852.15,
                "ask": 61,
                "bid": 39
            },
            {
                "price": 12975.21,
                "ask": 20,
                "bid": 39
            },
            {
                "price": 12979.86,
                "ask": 91,
                "bid": 35
            },
            {
                "price": 12938.66,
                "ask": 65,
                "bid": 68
            },
            {
                "price": 12782.45,
                "ask": 64,
                "bid": 44
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc552efb24006b0a04",
        "high": 12902.76,
        "open": 12992.94,
        "close": 12770.91,
        "low": 12810.85,
        "priceList": [
            {
                "price": 12913.57,
                "ask": 59,
                "bid": 38
            },
            {
                "price": 12956.06,
                "ask": 95,
                "bid": 8
            },
            {
                "price": 12821.33,
                "ask": 40,
                "bid": 11
            },
            {
                "price": 12862.17,
                "ask": 24,
                "bid": 15
            },
            {
                "price": 12757.39,
                "ask": 68,
                "bid": 97
            },
            {
                "price": 12784.44,
                "ask": 57,
                "bid": 47
            },
            {
                "price": 12796.77,
                "ask": 16,
                "bid": 70
            },
            {
                "price": 12770.75,
                "ask": 3,
                "bid": 38
            },
            {
                "price": 12918.01,
                "ask": 66,
                "bid": 67
            },
            {
                "price": 12871.2,
                "ask": 85,
                "bid": 49
            },
            {
                "price": 12809.98,
                "ask": 7,
                "bid": 39
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc891b8a5ba2151859",
        "high": 12909.15,
        "open": 12799.23,
        "close": 12725.56,
        "low": 12937.84,
        "priceList": [
            {
                "price": 12786.45,
                "ask": 93,
                "bid": 0
            },
            {
                "price": 12940.41,
                "ask": 4,
                "bid": 54
            },
            {
                "price": 12794.32,
                "ask": 44,
                "bid": 79
            },
            {
                "price": 12873.29,
                "ask": 3,
                "bid": 1
            },
            {
                "price": 12862.67,
                "ask": 39,
                "bid": 37
            },
            {
                "price": 12862.81,
                "ask": 47,
                "bid": 57
            },
            {
                "price": 12949.49,
                "ask": 76,
                "bid": 100
            },
            {
                "price": 12707.35,
                "ask": 86,
                "bid": 61
            },
            {
                "price": 12809.16,
                "ask": 69,
                "bid": 10
            },
            {
                "price": 12973.16,
                "ask": 6,
                "bid": 43
            },
            {
                "price": 12743.75,
                "ask": 65,
                "bid": 85
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4d8b7843c34f9422",
        "high": 12908.88,
        "open": 12812.3,
        "close": 12812.1,
        "low": 12745.13,
        "priceList": [
            {
                "price": 12768.25,
                "ask": 53,
                "bid": 55
            },
            {
                "price": 12934.09,
                "ask": 25,
                "bid": 67
            },
            {
                "price": 12852.14,
                "ask": 32,
                "bid": 61
            },
            {
                "price": 12978.51,
                "ask": 54,
                "bid": 31
            },
            {
                "price": 12901.52,
                "ask": 8,
                "bid": 100
            },
            {
                "price": 12749.06,
                "ask": 15,
                "bid": 8
            },
            {
                "price": 12759.47,
                "ask": 9,
                "bid": 10
            },
            {
                "price": 12844.12,
                "ask": 90,
                "bid": 65
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc5645bfec2a742efb",
        "high": 12755.19,
        "open": 12874.16,
        "close": 12901.23,
        "low": 12909,
        "priceList": [
            {
                "price": 12843.58,
                "ask": 13,
                "bid": 87
            },
            {
                "price": 12802.97,
                "ask": 38,
                "bid": 4
            },
            {
                "price": 12969.2,
                "ask": 71,
                "bid": 57
            },
            {
                "price": 12900.18,
                "ask": 44,
                "bid": 16
            },
            {
                "price": 12726.29,
                "ask": 33,
                "bid": 74
            },
            {
                "price": 12791.29,
                "ask": 63,
                "bid": 28
            },
            {
                "price": 12758.03,
                "ask": 50,
                "bid": 90
            },
            {
                "price": 12878.3,
                "ask": 17,
                "bid": 8
            },
            {
                "price": 12805.97,
                "ask": 95,
                "bid": 4
            },
            {
                "price": 12925.24,
                "ask": 25,
                "bid": 12
            },
            {
                "price": 12816.01,
                "ask": 46,
                "bid": 15
            },
            {
                "price": 12749.72,
                "ask": 46,
                "bid": 1
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcb760effaa04d0401",
        "high": 12904.35,
        "open": 12869.72,
        "close": 12966.94,
        "low": 12896.01,
        "priceList": [
            {
                "price": 12823.75,
                "ask": 58,
                "bid": 25
            },
            {
                "price": 12967.9,
                "ask": 11,
                "bid": 48
            },
            {
                "price": 12999.22,
                "ask": 28,
                "bid": 53
            },
            {
                "price": 12991.06,
                "ask": 54,
                "bid": 44
            },
            {
                "price": 12921.79,
                "ask": 39,
                "bid": 69
            },
            {
                "price": 12954.88,
                "ask": 58,
                "bid": 83
            },
            {
                "price": 12841.13,
                "ask": 66,
                "bid": 38
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcadf23e0e1504edb7",
        "high": 12830.6,
        "open": 12870.31,
        "close": 12882.77,
        "low": 12752.48,
        "priceList": [
            {
                "price": 12905.71,
                "ask": 15,
                "bid": 77
            },
            {
                "price": 12810.81,
                "ask": 5,
                "bid": 3
            },
            {
                "price": 12966.51,
                "ask": 57,
                "bid": 31
            },
            {
                "price": 12745.38,
                "ask": 16,
                "bid": 34
            },
            {
                "price": 12714.62,
                "ask": 16,
                "bid": 48
            },
            {
                "price": 12865.01,
                "ask": 38,
                "bid": 63
            },
            {
                "price": 12703.22,
                "ask": 18,
                "bid": 72
            },
            {
                "price": 12908.55,
                "ask": 39,
                "bid": 70
            },
            {
                "price": 12719.16,
                "ask": 94,
                "bid": 39
            },
            {
                "price": 12867.71,
                "ask": 66,
                "bid": 5
            },
            {
                "price": 12976.66,
                "ask": 87,
                "bid": 69
            },
            {
                "price": 12909.53,
                "ask": 7,
                "bid": 63
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc17271c7d07cca6cd",
        "high": 12956.06,
        "open": 12810.44,
        "close": 12701.67,
        "low": 12873.98,
        "priceList": [
            {
                "price": 12868.33,
                "ask": 74,
                "bid": 70
            },
            {
                "price": 12855.85,
                "ask": 58,
                "bid": 32
            },
            {
                "price": 12999.72,
                "ask": 54,
                "bid": 33
            },
            {
                "price": 12833.38,
                "ask": 7,
                "bid": 81
            },
            {
                "price": 12920.01,
                "ask": 58,
                "bid": 95
            },
            {
                "price": 12992.23,
                "ask": 15,
                "bid": 11
            },
            {
                "price": 12812.13,
                "ask": 6,
                "bid": 39
            },
            {
                "price": 12904.03,
                "ask": 83,
                "bid": 29
            },
            {
                "price": 12768.77,
                "ask": 37,
                "bid": 48
            },
            {
                "price": 12875.33,
                "ask": 53,
                "bid": 59
            },
            {
                "price": 12941.33,
                "ask": 41,
                "bid": 34
            },
            {
                "price": 12703.3,
                "ask": 87,
                "bid": 12
            },
            {
                "price": 12733.4,
                "ask": 87,
                "bid": 58
            },
            {
                "price": 12716.26,
                "ask": 92,
                "bid": 82
            },
            {
                "price": 12987.77,
                "ask": 55,
                "bid": 83
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca7d0d10b5d370ada",
        "high": 12823.74,
        "open": 12932.03,
        "close": 12977.38,
        "low": 12946.83,
        "priceList": [
            {
                "price": 12760.32,
                "ask": 23,
                "bid": 78
            },
            {
                "price": 12813.34,
                "ask": 90,
                "bid": 74
            },
            {
                "price": 12791.8,
                "ask": 7,
                "bid": 72
            },
            {
                "price": 12800.93,
                "ask": 42,
                "bid": 29
            },
            {
                "price": 12770.15,
                "ask": 6,
                "bid": 43
            },
            {
                "price": 12987.31,
                "ask": 88,
                "bid": 76
            },
            {
                "price": 12778.78,
                "ask": 21,
                "bid": 57
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc3724aeeb91a8d8de",
        "high": 12867.6,
        "open": 12750.26,
        "close": 12983.93,
        "low": 12863.91,
        "priceList": [
            {
                "price": 12727.55,
                "ask": 35,
                "bid": 25
            },
            {
                "price": 12793.08,
                "ask": 37,
                "bid": 1
            },
            {
                "price": 12878.27,
                "ask": 97,
                "bid": 5
            },
            {
                "price": 12846.26,
                "ask": 90,
                "bid": 39
            },
            {
                "price": 12901.84,
                "ask": 97,
                "bid": 41
            },
            {
                "price": 12963,
                "ask": 64,
                "bid": 52
            },
            {
                "price": 12719.99,
                "ask": 48,
                "bid": 80
            },
            {
                "price": 12743.18,
                "ask": 13,
                "bid": 53
            },
            {
                "price": 12956.6,
                "ask": 28,
                "bid": 45
            },
            {
                "price": 12881.28,
                "ask": 46,
                "bid": 39
            },
            {
                "price": 12791.94,
                "ask": 83,
                "bid": 11
            },
            {
                "price": 12944.39,
                "ask": 51,
                "bid": 21
            },
            {
                "price": 12853.34,
                "ask": 51,
                "bid": 26
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc5a79b15dba825111",
        "high": 12900.71,
        "open": 12819.16,
        "close": 12772.33,
        "low": 12806.09,
        "priceList": [
            {
                "price": 12946.81,
                "ask": 74,
                "bid": 59
            },
            {
                "price": 12719.8,
                "ask": 7,
                "bid": 56
            },
            {
                "price": 12927.4,
                "ask": 86,
                "bid": 15
            },
            {
                "price": 12873.39,
                "ask": 96,
                "bid": 71
            },
            {
                "price": 12825.32,
                "ask": 70,
                "bid": 84
            },
            {
                "price": 12712.86,
                "ask": 51,
                "bid": 12
            },
            {
                "price": 12715.43,
                "ask": 25,
                "bid": 35
            },
            {
                "price": 12896.64,
                "ask": 86,
                "bid": 81
            },
            {
                "price": 12765.2,
                "ask": 33,
                "bid": 87
            },
            {
                "price": 12889.06,
                "ask": 75,
                "bid": 41
            },
            {
                "price": 12753.1,
                "ask": 58,
                "bid": 13
            },
            {
                "price": 12718.06,
                "ask": 35,
                "bid": 33
            },
            {
                "price": 12887.11,
                "ask": 20,
                "bid": 40
            },
            {
                "price": 12894.19,
                "ask": 83,
                "bid": 15
            },
            {
                "price": 12932.89,
                "ask": 86,
                "bid": 67
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc9c03d920e5daf28f",
        "high": 12751.79,
        "open": 12721.65,
        "close": 12854.31,
        "low": 12999.92,
        "priceList": [
            {
                "price": 12853.48,
                "ask": 27,
                "bid": 81
            },
            {
                "price": 12858.63,
                "ask": 18,
                "bid": 55
            },
            {
                "price": 12926.29,
                "ask": 73,
                "bid": 11
            },
            {
                "price": 12869.42,
                "ask": 13,
                "bid": 46
            },
            {
                "price": 12975.26,
                "ask": 77,
                "bid": 45
            },
            {
                "price": 12716.9,
                "ask": 100,
                "bid": 13
            },
            {
                "price": 12891.02,
                "ask": 47,
                "bid": 75
            },
            {
                "price": 12993.66,
                "ask": 60,
                "bid": 80
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc3535ac17e003159e",
        "high": 12877,
        "open": 12733.91,
        "close": 12786.01,
        "low": 12867.68,
        "priceList": [
            {
                "price": 12870.99,
                "ask": 77,
                "bid": 67
            },
            {
                "price": 12717.58,
                "ask": 59,
                "bid": 0
            },
            {
                "price": 12916.93,
                "ask": 35,
                "bid": 9
            },
            {
                "price": 12809.99,
                "ask": 95,
                "bid": 25
            },
            {
                "price": 12868.73,
                "ask": 81,
                "bid": 95
            },
            {
                "price": 12785.25,
                "ask": 93,
                "bid": 91
            },
            {
                "price": 12883.17,
                "ask": 74,
                "bid": 15
            },
            {
                "price": 12726.15,
                "ask": 22,
                "bid": 26
            },
            {
                "price": 12705.93,
                "ask": 15,
                "bid": 84
            },
            {
                "price": 12730.3,
                "ask": 98,
                "bid": 90
            },
            {
                "price": 12850.47,
                "ask": 79,
                "bid": 66
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc385fcbaf9c758327",
        "high": 12949.42,
        "open": 12811.08,
        "close": 12788.47,
        "low": 12872.7,
        "priceList": [
            {
                "price": 12758.22,
                "ask": 40,
                "bid": 51
            },
            {
                "price": 12807.85,
                "ask": 75,
                "bid": 78
            },
            {
                "price": 12828.55,
                "ask": 68,
                "bid": 39
            },
            {
                "price": 12901.33,
                "ask": 64,
                "bid": 78
            },
            {
                "price": 12966.41,
                "ask": 90,
                "bid": 32
            },
            {
                "price": 12739.98,
                "ask": 59,
                "bid": 44
            },
            {
                "price": 12990.33,
                "ask": 10,
                "bid": 90
            },
            {
                "price": 12971.74,
                "ask": 8,
                "bid": 29
            },
            {
                "price": 12987.95,
                "ask": 6,
                "bid": 89
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcdb9c4aeb8620a5eb",
        "high": 12865.48,
        "open": 12869.49,
        "close": 12886.38,
        "low": 12724.56,
        "priceList": [
            {
                "price": 12902.95,
                "ask": 4,
                "bid": 90
            },
            {
                "price": 12954.6,
                "ask": 47,
                "bid": 23
            },
            {
                "price": 12825.5,
                "ask": 90,
                "bid": 77
            },
            {
                "price": 12937.6,
                "ask": 30,
                "bid": 0
            },
            {
                "price": 12828.73,
                "ask": 36,
                "bid": 0
            },
            {
                "price": 12981.01,
                "ask": 18,
                "bid": 51
            },
            {
                "price": 12939.18,
                "ask": 38,
                "bid": 84
            },
            {
                "price": 12761.33,
                "ask": 65,
                "bid": 22
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc0732a8f7eaf63a2b",
        "high": 12728.88,
        "open": 12929.18,
        "close": 12875.31,
        "low": 12715.17,
        "priceList": [
            {
                "price": 12798.55,
                "ask": 91,
                "bid": 10
            },
            {
                "price": 12884.65,
                "ask": 69,
                "bid": 36
            },
            {
                "price": 12955.73,
                "ask": 38,
                "bid": 11
            },
            {
                "price": 12714.66,
                "ask": 5,
                "bid": 34
            },
            {
                "price": 12851.86,
                "ask": 49,
                "bid": 72
            },
            {
                "price": 12763.39,
                "ask": 28,
                "bid": 95
            },
            {
                "price": 12947.44,
                "ask": 7,
                "bid": 80
            },
            {
                "price": 12867.01,
                "ask": 45,
                "bid": 2
            },
            {
                "price": 12970.46,
                "ask": 75,
                "bid": 80
            },
            {
                "price": 12934.34,
                "ask": 28,
                "bid": 32
            },
            {
                "price": 12716.78,
                "ask": 34,
                "bid": 84
            },
            {
                "price": 12808.92,
                "ask": 100,
                "bid": 8
            },
            {
                "price": 12871.18,
                "ask": 29,
                "bid": 5
            },
            {
                "price": 12860.44,
                "ask": 97,
                "bid": 27
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc21c12d5ee16fd65b",
        "high": 12971.38,
        "open": 12965.19,
        "close": 12745.61,
        "low": 12833.1,
        "priceList": [
            {
                "price": 12984.44,
                "ask": 67,
                "bid": 37
            },
            {
                "price": 12807.77,
                "ask": 94,
                "bid": 1
            },
            {
                "price": 12908.95,
                "ask": 64,
                "bid": 2
            },
            {
                "price": 12974.94,
                "ask": 83,
                "bid": 7
            },
            {
                "price": 12736.87,
                "ask": 18,
                "bid": 99
            },
            {
                "price": 12754.21,
                "ask": 96,
                "bid": 69
            },
            {
                "price": 12797.75,
                "ask": 73,
                "bid": 65
            },
            {
                "price": 12973.86,
                "ask": 33,
                "bid": 39
            },
            {
                "price": 12754.32,
                "ask": 14,
                "bid": 40
            },
            {
                "price": 12756.16,
                "ask": 51,
                "bid": 5
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc4a09e7b328bd44bf",
        "high": 12995.32,
        "open": 12989.19,
        "close": 12736.1,
        "low": 12857.09,
        "priceList": [
            {
                "price": 12955.27,
                "ask": 53,
                "bid": 66
            },
            {
                "price": 12967.32,
                "ask": 19,
                "bid": 84
            },
            {
                "price": 12954.94,
                "ask": 47,
                "bid": 24
            },
            {
                "price": 12779.03,
                "ask": 80,
                "bid": 27
            },
            {
                "price": 12970.35,
                "ask": 69,
                "bid": 4
            },
            {
                "price": 12948.1,
                "ask": 51,
                "bid": 77
            },
            {
                "price": 12862.55,
                "ask": 5,
                "bid": 43
            },
            {
                "price": 12846.42,
                "ask": 27,
                "bid": 12
            },
            {
                "price": 12715.53,
                "ask": 22,
                "bid": 97
            },
            {
                "price": 12879.38,
                "ask": 47,
                "bid": 23
            },
            {
                "price": 12779.97,
                "ask": 97,
                "bid": 47
            },
            {
                "price": 12859.95,
                "ask": 32,
                "bid": 94
            },
            {
                "price": 12980.29,
                "ask": 89,
                "bid": 72
            },
            {
                "price": 12722.86,
                "ask": 75,
                "bid": 26
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc6dd4345eacf767bf",
        "high": 12902.06,
        "open": 12894.74,
        "close": 12800.83,
        "low": 12768.68,
        "priceList": [
            {
                "price": 12713.91,
                "ask": 5,
                "bid": 71
            },
            {
                "price": 12989.74,
                "ask": 67,
                "bid": 17
            },
            {
                "price": 12842.31,
                "ask": 99,
                "bid": 29
            },
            {
                "price": 12894.91,
                "ask": 15,
                "bid": 87
            },
            {
                "price": 12805.44,
                "ask": 15,
                "bid": 90
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc15366fbee2830d8b",
        "high": 12730.99,
        "open": 12891.63,
        "close": 12959.6,
        "low": 12911.11,
        "priceList": [
            {
                "price": 12855.45,
                "ask": 77,
                "bid": 10
            },
            {
                "price": 12974.31,
                "ask": 74,
                "bid": 46
            },
            {
                "price": 12852.27,
                "ask": 37,
                "bid": 12
            },
            {
                "price": 12946.39,
                "ask": 1,
                "bid": 70
            },
            {
                "price": 12766.75,
                "ask": 56,
                "bid": 36
            },
            {
                "price": 12841.63,
                "ask": 0,
                "bid": 41
            },
            {
                "price": 12859.68,
                "ask": 81,
                "bid": 90
            },
            {
                "price": 12732.67,
                "ask": 5,
                "bid": 13
            },
            {
                "price": 12948.75,
                "ask": 75,
                "bid": 10
            },
            {
                "price": 12886.21,
                "ask": 51,
                "bid": 60
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dce835ebc1723527c6",
        "high": 12945.67,
        "open": 12966.58,
        "close": 12840.31,
        "low": 12700.58,
        "priceList": [
            {
                "price": 12731.3,
                "ask": 59,
                "bid": 31
            },
            {
                "price": 12926.11,
                "ask": 30,
                "bid": 28
            },
            {
                "price": 12727.79,
                "ask": 71,
                "bid": 45
            },
            {
                "price": 12723.31,
                "ask": 12,
                "bid": 39
            },
            {
                "price": 12856.06,
                "ask": 14,
                "bid": 92
            },
            {
                "price": 12923.78,
                "ask": 5,
                "bid": 100
            },
            {
                "price": 12948.45,
                "ask": 55,
                "bid": 97
            },
            {
                "price": 12986.78,
                "ask": 95,
                "bid": 5
            },
            {
                "price": 12809.74,
                "ask": 58,
                "bid": 89
            },
            {
                "price": 12923.88,
                "ask": 25,
                "bid": 60
            },
            {
                "price": 12953.66,
                "ask": 53,
                "bid": 1
            },
            {
                "price": 12951.02,
                "ask": 74,
                "bid": 40
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc93573ac9a852b80d",
        "high": 12734.45,
        "open": 12948.36,
        "close": 12915.94,
        "low": 12805.94,
        "priceList": [
            {
                "price": 12902.28,
                "ask": 34,
                "bid": 48
            },
            {
                "price": 12942.31,
                "ask": 91,
                "bid": 94
            },
            {
                "price": 12802.42,
                "ask": 89,
                "bid": 54
            },
            {
                "price": 12750.74,
                "ask": 54,
                "bid": 77
            },
            {
                "price": 12717.35,
                "ask": 69,
                "bid": 82
            },
            {
                "price": 12804.93,
                "ask": 64,
                "bid": 76
            },
            {
                "price": 12910.51,
                "ask": 62,
                "bid": 24
            },
            {
                "price": 12736.98,
                "ask": 89,
                "bid": 65
            },
            {
                "price": 12708.06,
                "ask": 78,
                "bid": 13
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcd353b7897b291cde",
        "high": 12846.45,
        "open": 12740.41,
        "close": 12964.51,
        "low": 12919.94,
        "priceList": [
            {
                "price": 12917.53,
                "ask": 96,
                "bid": 54
            },
            {
                "price": 12751.11,
                "ask": 21,
                "bid": 65
            },
            {
                "price": 12764.71,
                "ask": 18,
                "bid": 34
            },
            {
                "price": 12881.09,
                "ask": 0,
                "bid": 78
            },
            {
                "price": 12706.2,
                "ask": 65,
                "bid": 61
            },
            {
                "price": 12787.58,
                "ask": 60,
                "bid": 41
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcb3eb72cff0d83d00",
        "high": 12927.98,
        "open": 12784.85,
        "close": 12938.03,
        "low": 12707.79,
        "priceList": [
            {
                "price": 12928.47,
                "ask": 46,
                "bid": 39
            },
            {
                "price": 12702.01,
                "ask": 66,
                "bid": 29
            },
            {
                "price": 12762.47,
                "ask": 66,
                "bid": 45
            },
            {
                "price": 12821.54,
                "ask": 42,
                "bid": 76
            },
            {
                "price": 12848.13,
                "ask": 54,
                "bid": 77
            },
            {
                "price": 12924.48,
                "ask": 75,
                "bid": 51
            },
            {
                "price": 12956.71,
                "ask": 46,
                "bid": 85
            },
            {
                "price": 12711.44,
                "ask": 93,
                "bid": 71
            },
            {
                "price": 12809.59,
                "ask": 57,
                "bid": 29
            },
            {
                "price": 12938.32,
                "ask": 34,
                "bid": 47
            },
            {
                "price": 12813.65,
                "ask": 88,
                "bid": 39
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcb78939afec5ed27c",
        "high": 12728.55,
        "open": 12939.17,
        "close": 12769.33,
        "low": 12899.84,
        "priceList": [
            {
                "price": 12908.95,
                "ask": 53,
                "bid": 39
            },
            {
                "price": 12824.67,
                "ask": 27,
                "bid": 77
            },
            {
                "price": 12816.33,
                "ask": 33,
                "bid": 60
            },
            {
                "price": 12799.75,
                "ask": 43,
                "bid": 16
            },
            {
                "price": 12905.28,
                "ask": 30,
                "bid": 15
            },
            {
                "price": 12749.71,
                "ask": 93,
                "bid": 55
            },
            {
                "price": 12756.75,
                "ask": 76,
                "bid": 32
            },
            {
                "price": 12811.56,
                "ask": 30,
                "bid": 92
            },
            {
                "price": 12829.49,
                "ask": 34,
                "bid": 89
            },
            {
                "price": 12724.69,
                "ask": 75,
                "bid": 45
            },
            {
                "price": 12708.2,
                "ask": 27,
                "bid": 37
            },
            {
                "price": 12803.86,
                "ask": 63,
                "bid": 68
            },
            {
                "price": 12858.01,
                "ask": 10,
                "bid": 85
            },
            {
                "price": 12977.27,
                "ask": 6,
                "bid": 0
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc98dc107f400605f1",
        "high": 12865.59,
        "open": 12871.34,
        "close": 12925.55,
        "low": 12852.91,
        "priceList": [
            {
                "price": 12829.59,
                "ask": 61,
                "bid": 55
            },
            {
                "price": 12849.78,
                "ask": 98,
                "bid": 15
            },
            {
                "price": 12767.95,
                "ask": 56,
                "bid": 3
            },
            {
                "price": 12743.91,
                "ask": 54,
                "bid": 19
            },
            {
                "price": 12986.71,
                "ask": 67,
                "bid": 55
            },
            {
                "price": 12808.8,
                "ask": 49,
                "bid": 46
            },
            {
                "price": 12959.24,
                "ask": 86,
                "bid": 92
            },
            {
                "price": 12913.74,
                "ask": 29,
                "bid": 51
            },
            {
                "price": 12908.86,
                "ask": 52,
                "bid": 67
            },
            {
                "price": 12711.16,
                "ask": 57,
                "bid": 28
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc01fae2b11299c9f6",
        "high": 12955.77,
        "open": 12816.95,
        "close": 12791.18,
        "low": 12812.89,
        "priceList": [
            {
                "price": 12799.53,
                "ask": 56,
                "bid": 20
            },
            {
                "price": 12898.33,
                "ask": 60,
                "bid": 56
            },
            {
                "price": 12903.54,
                "ask": 28,
                "bid": 43
            },
            {
                "price": 12891.59,
                "ask": 100,
                "bid": 16
            },
            {
                "price": 12814.04,
                "ask": 36,
                "bid": 65
            },
            {
                "price": 12969.35,
                "ask": 64,
                "bid": 12
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc0139e6175a5f5f57",
        "high": 12862.32,
        "open": 12952.07,
        "close": 12986.09,
        "low": 12710.93,
        "priceList": [
            {
                "price": 12907.36,
                "ask": 40,
                "bid": 78
            },
            {
                "price": 12761.67,
                "ask": 8,
                "bid": 91
            },
            {
                "price": 12979.2,
                "ask": 58,
                "bid": 28
            },
            {
                "price": 12776.63,
                "ask": 37,
                "bid": 69
            },
            {
                "price": 12954.74,
                "ask": 17,
                "bid": 46
            },
            {
                "price": 12852.52,
                "ask": 60,
                "bid": 21
            },
            {
                "price": 12727.79,
                "ask": 62,
                "bid": 58
            },
            {
                "price": 12920.17,
                "ask": 16,
                "bid": 70
            },
            {
                "price": 12702.17,
                "ask": 51,
                "bid": 100
            },
            {
                "price": 12873.67,
                "ask": 36,
                "bid": 74
            },
            {
                "price": 12879.23,
                "ask": 34,
                "bid": 53
            },
            {
                "price": 12940.06,
                "ask": 76,
                "bid": 7
            },
            {
                "price": 12728.04,
                "ask": 33,
                "bid": 60
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc1b6709ddbcaa16d8",
        "high": 12757.84,
        "open": 12743.15,
        "close": 12814.91,
        "low": 12808.16,
        "priceList": [
            {
                "price": 12862.47,
                "ask": 40,
                "bid": 47
            },
            {
                "price": 12718.28,
                "ask": 7,
                "bid": 14
            },
            {
                "price": 12991.57,
                "ask": 97,
                "bid": 26
            },
            {
                "price": 12810.55,
                "ask": 27,
                "bid": 32
            },
            {
                "price": 12930.88,
                "ask": 4,
                "bid": 89
            },
            {
                "price": 12967.09,
                "ask": 92,
                "bid": 74
            },
            {
                "price": 12797.68,
                "ask": 34,
                "bid": 51
            },
            {
                "price": 12731.99,
                "ask": 56,
                "bid": 51
            },
            {
                "price": 12931.38,
                "ask": 58,
                "bid": 1
            },
            {
                "price": 12826.71,
                "ask": 11,
                "bid": 96
            },
            {
                "price": 12777.78,
                "ask": 38,
                "bid": 14
            },
            {
                "price": 12825.65,
                "ask": 10,
                "bid": 13
            },
            {
                "price": 12977.4,
                "ask": 94,
                "bid": 76
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc1b80e5ad5bfe284d",
        "high": 12934.8,
        "open": 12843.86,
        "close": 12845.14,
        "low": 12898.69,
        "priceList": [
            {
                "price": 12920.77,
                "ask": 10,
                "bid": 32
            },
            {
                "price": 12961.32,
                "ask": 82,
                "bid": 54
            },
            {
                "price": 12732.28,
                "ask": 40,
                "bid": 11
            },
            {
                "price": 12813.67,
                "ask": 40,
                "bid": 93
            },
            {
                "price": 12983.46,
                "ask": 63,
                "bid": 60
            },
            {
                "price": 12760.73,
                "ask": 73,
                "bid": 41
            },
            {
                "price": 12820.96,
                "ask": 53,
                "bid": 79
            },
            {
                "price": 12881.34,
                "ask": 39,
                "bid": 45
            },
            {
                "price": 12952.21,
                "ask": 5,
                "bid": 85
            },
            {
                "price": 12796.65,
                "ask": 37,
                "bid": 53
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc99cb2f2716dd6b3f",
        "high": 12938.56,
        "open": 12952.64,
        "close": 12783.85,
        "low": 12707.18,
        "priceList": [
            {
                "price": 12977.57,
                "ask": 68,
                "bid": 82
            },
            {
                "price": 12983.22,
                "ask": 72,
                "bid": 38
            },
            {
                "price": 12976.65,
                "ask": 86,
                "bid": 29
            },
            {
                "price": 12849.8,
                "ask": 4,
                "bid": 54
            },
            {
                "price": 12804.07,
                "ask": 39,
                "bid": 96
            },
            {
                "price": 12827.1,
                "ask": 88,
                "bid": 27
            },
            {
                "price": 12751.06,
                "ask": 7,
                "bid": 52
            },
            {
                "price": 12834.8,
                "ask": 56,
                "bid": 35
            },
            {
                "price": 12891.92,
                "ask": 73,
                "bid": 96
            },
            {
                "price": 12798.88,
                "ask": 91,
                "bid": 26
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcc79e3b66a592796e",
        "high": 12787.94,
        "open": 12926.51,
        "close": 12791.79,
        "low": 12907.15,
        "priceList": [
            {
                "price": 12707.4,
                "ask": 98,
                "bid": 62
            },
            {
                "price": 12801.93,
                "ask": 81,
                "bid": 19
            },
            {
                "price": 12760.4,
                "ask": 85,
                "bid": 78
            },
            {
                "price": 12857.04,
                "ask": 54,
                "bid": 8
            },
            {
                "price": 12931.9,
                "ask": 100,
                "bid": 95
            },
            {
                "price": 12982.96,
                "ask": 100,
                "bid": 89
            },
            {
                "price": 12881.55,
                "ask": 39,
                "bid": 64
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcabc5979a9b421650",
        "high": 12921.83,
        "open": 12887.73,
        "close": 12873.27,
        "low": 12862.43,
        "priceList": [
            {
                "price": 12761.4,
                "ask": 5,
                "bid": 89
            },
            {
                "price": 12707.03,
                "ask": 41,
                "bid": 29
            },
            {
                "price": 12815.42,
                "ask": 41,
                "bid": 30
            },
            {
                "price": 12756.13,
                "ask": 26,
                "bid": 50
            },
            {
                "price": 12701.95,
                "ask": 69,
                "bid": 36
            },
            {
                "price": 12913.04,
                "ask": 6,
                "bid": 72
            },
            {
                "price": 12789.06,
                "ask": 78,
                "bid": 13
            },
            {
                "price": 12894.05,
                "ask": 7,
                "bid": 69
            },
            {
                "price": 12781.65,
                "ask": 89,
                "bid": 50
            },
            {
                "price": 12726.54,
                "ask": 52,
                "bid": 70
            },
            {
                "price": 12832.11,
                "ask": 75,
                "bid": 64
            },
            {
                "price": 12960.91,
                "ask": 35,
                "bid": 2
            },
            {
                "price": 12783.95,
                "ask": 50,
                "bid": 100
            },
            {
                "price": 12797.19,
                "ask": 57,
                "bid": 26
            },
            {
                "price": 12863.09,
                "ask": 33,
                "bid": 33
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc5167c52f1a312aa2",
        "high": 12789.87,
        "open": 12910.75,
        "close": 12777.62,
        "low": 12862.9,
        "priceList": [
            {
                "price": 12806.84,
                "ask": 4,
                "bid": 39
            },
            {
                "price": 12913.01,
                "ask": 8,
                "bid": 15
            },
            {
                "price": 12970.01,
                "ask": 18,
                "bid": 38
            },
            {
                "price": 12854.96,
                "ask": 94,
                "bid": 67
            },
            {
                "price": 12761.08,
                "ask": 76,
                "bid": 87
            },
            {
                "price": 12951.85,
                "ask": 68,
                "bid": 46
            },
            {
                "price": 12806.84,
                "ask": 38,
                "bid": 46
            },
            {
                "price": 12975.77,
                "ask": 45,
                "bid": 68
            },
            {
                "price": 12916.18,
                "ask": 12,
                "bid": 90
            },
            {
                "price": 12769.38,
                "ask": 1,
                "bid": 8
            },
            {
                "price": 12832.65,
                "ask": 20,
                "bid": 95
            },
            {
                "price": 12824.16,
                "ask": 23,
                "bid": 95
            },
            {
                "price": 12885.23,
                "ask": 75,
                "bid": 16
            },
            {
                "price": 12937.9,
                "ask": 8,
                "bid": 55
            },
            {
                "price": 12807.71,
                "ask": 50,
                "bid": 25
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc865877ea76bad5a0",
        "high": 12990.87,
        "open": 12817.61,
        "close": 12895.88,
        "low": 12994.71,
        "priceList": [
            {
                "price": 12878.38,
                "ask": 89,
                "bid": 62
            },
            {
                "price": 12767.45,
                "ask": 13,
                "bid": 16
            },
            {
                "price": 12941.45,
                "ask": 79,
                "bid": 95
            },
            {
                "price": 12846.29,
                "ask": 17,
                "bid": 1
            },
            {
                "price": 12968.84,
                "ask": 53,
                "bid": 84
            },
            {
                "price": 12767.34,
                "ask": 67,
                "bid": 15
            },
            {
                "price": 12958.3,
                "ask": 71,
                "bid": 63
            },
            {
                "price": 12963.98,
                "ask": 67,
                "bid": 80
            },
            {
                "price": 12967.53,
                "ask": 100,
                "bid": 13
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc6affbcedbb0354e8",
        "high": 12901.33,
        "open": 12725.58,
        "close": 12874.76,
        "low": 12807.51,
        "priceList": [
            {
                "price": 12907.82,
                "ask": 80,
                "bid": 98
            },
            {
                "price": 12892.55,
                "ask": 48,
                "bid": 3
            },
            {
                "price": 12886.2,
                "ask": 38,
                "bid": 39
            },
            {
                "price": 12898,
                "ask": 23,
                "bid": 35
            },
            {
                "price": 12754.7,
                "ask": 81,
                "bid": 15
            },
            {
                "price": 12893.37,
                "ask": 10,
                "bid": 57
            },
            {
                "price": 12821.81,
                "ask": 39,
                "bid": 55
            },
            {
                "price": 12893.54,
                "ask": 34,
                "bid": 90
            },
            {
                "price": 12962.85,
                "ask": 54,
                "bid": 92
            },
            {
                "price": 12724.03,
                "ask": 65,
                "bid": 90
            },
            {
                "price": 12901.99,
                "ask": 100,
                "bid": 98
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc807dacc85f75c228",
        "high": 12830.13,
        "open": 12904.07,
        "close": 12871.38,
        "low": 12940.44,
        "priceList": [
            {
                "price": 12708.1,
                "ask": 61,
                "bid": 55
            },
            {
                "price": 12911.5,
                "ask": 54,
                "bid": 71
            },
            {
                "price": 12885.01,
                "ask": 98,
                "bid": 20
            },
            {
                "price": 12947.4,
                "ask": 20,
                "bid": 7
            },
            {
                "price": 12935.49,
                "ask": 0,
                "bid": 42
            },
            {
                "price": 12725.91,
                "ask": 33,
                "bid": 27
            },
            {
                "price": 12931.3,
                "ask": 86,
                "bid": 77
            },
            {
                "price": 12862.37,
                "ask": 13,
                "bid": 77
            },
            {
                "price": 12984.63,
                "ask": 22,
                "bid": 46
            },
            {
                "price": 12755.7,
                "ask": 91,
                "bid": 61
            },
            {
                "price": 12860.84,
                "ask": 5,
                "bid": 43
            },
            {
                "price": 12878.14,
                "ask": 12,
                "bid": 93
            },
            {
                "price": 12761.38,
                "ask": 46,
                "bid": 37
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dce9f0e205d19c5ee3",
        "high": 12756.01,
        "open": 12923.28,
        "close": 12929.36,
        "low": 12897.29,
        "priceList": [
            {
                "price": 12829.32,
                "ask": 82,
                "bid": 21
            },
            {
                "price": 12964.33,
                "ask": 62,
                "bid": 24
            },
            {
                "price": 12809.06,
                "ask": 78,
                "bid": 24
            },
            {
                "price": 12745.94,
                "ask": 91,
                "bid": 29
            },
            {
                "price": 12949.09,
                "ask": 80,
                "bid": 66
            },
            {
                "price": 12918.65,
                "ask": 63,
                "bid": 19
            },
            {
                "price": 12876.56,
                "ask": 72,
                "bid": 52
            },
            {
                "price": 12958.12,
                "ask": 37,
                "bid": 29
            },
            {
                "price": 12933.12,
                "ask": 21,
                "bid": 23
            },
            {
                "price": 12848.76,
                "ask": 89,
                "bid": 42
            },
            {
                "price": 12915.48,
                "ask": 81,
                "bid": 55
            },
            {
                "price": 12979.2,
                "ask": 66,
                "bid": 49
            },
            {
                "price": 12807.25,
                "ask": 8,
                "bid": 73
            },
            {
                "price": 12841.35,
                "ask": 9,
                "bid": 93
            },
            {
                "price": 12862.65,
                "ask": 77,
                "bid": 51
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dccc5fecceeae4d751",
        "high": 12879.97,
        "open": 12899.81,
        "close": 12934.65,
        "low": 12968.94,
        "priceList": [
            {
                "price": 12771.68,
                "ask": 17,
                "bid": 48
            },
            {
                "price": 12705.19,
                "ask": 42,
                "bid": 82
            },
            {
                "price": 12782.05,
                "ask": 74,
                "bid": 76
            },
            {
                "price": 12844.23,
                "ask": 35,
                "bid": 44
            },
            {
                "price": 12907.71,
                "ask": 52,
                "bid": 44
            },
            {
                "price": 12937.88,
                "ask": 23,
                "bid": 30
            },
            {
                "price": 12973.42,
                "ask": 78,
                "bid": 28
            },
            {
                "price": 12701.91,
                "ask": 95,
                "bid": 4
            },
            {
                "price": 12796.26,
                "ask": 67,
                "bid": 100
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc052667ae5b0780cb",
        "high": 12849.68,
        "open": 12924.47,
        "close": 12905.24,
        "low": 12838.11,
        "priceList": [
            {
                "price": 12999.41,
                "ask": 12,
                "bid": 97
            },
            {
                "price": 12759.99,
                "ask": 39,
                "bid": 12
            },
            {
                "price": 12848.02,
                "ask": 82,
                "bid": 41
            },
            {
                "price": 12904.92,
                "ask": 64,
                "bid": 61
            },
            {
                "price": 12928.39,
                "ask": 94,
                "bid": 90
            },
            {
                "price": 12955.28,
                "ask": 73,
                "bid": 12
            },
            {
                "price": 12972.94,
                "ask": 16,
                "bid": 0
            },
            {
                "price": 12793.86,
                "ask": 40,
                "bid": 28
            },
            {
                "price": 12885.65,
                "ask": 44,
                "bid": 33
            },
            {
                "price": 12959.82,
                "ask": 57,
                "bid": 60
            },
            {
                "price": 12961.2,
                "ask": 56,
                "bid": 47
            },
            {
                "price": 12785.07,
                "ask": 37,
                "bid": 43
            },
            {
                "price": 12789.53,
                "ask": 78,
                "bid": 6
            },
            {
                "price": 12788.08,
                "ask": 54,
                "bid": 41
            },
            {
                "price": 12919.46,
                "ask": 60,
                "bid": 41
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc8f0ec21e9da9c4d1",
        "high": 12800.83,
        "open": 12807.18,
        "close": 12731.72,
        "low": 12898.99,
        "priceList": [
            {
                "price": 12942.43,
                "ask": 41,
                "bid": 61
            },
            {
                "price": 12700.13,
                "ask": 87,
                "bid": 75
            },
            {
                "price": 12781.05,
                "ask": 80,
                "bid": 77
            },
            {
                "price": 12737.5,
                "ask": 10,
                "bid": 88
            },
            {
                "price": 12945.51,
                "ask": 43,
                "bid": 32
            },
            {
                "price": 12810.58,
                "ask": 57,
                "bid": 55
            },
            {
                "price": 12720.71,
                "ask": 88,
                "bid": 26
            },
            {
                "price": 12906.89,
                "ask": 66,
                "bid": 13
            },
            {
                "price": 12795.68,
                "ask": 70,
                "bid": 8
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcd58efe260bff0a6e",
        "high": 12789.45,
        "open": 12924.45,
        "close": 12850.36,
        "low": 12735.42,
        "priceList": [
            {
                "price": 12893.52,
                "ask": 0,
                "bid": 66
            },
            {
                "price": 12983.95,
                "ask": 97,
                "bid": 7
            },
            {
                "price": 12726.8,
                "ask": 75,
                "bid": 27
            },
            {
                "price": 12841.23,
                "ask": 72,
                "bid": 2
            },
            {
                "price": 12997.04,
                "ask": 4,
                "bid": 73
            },
            {
                "price": 12889.32,
                "ask": 41,
                "bid": 70
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dce2ece026eb647615",
        "high": 12943.89,
        "open": 12734.78,
        "close": 12895.22,
        "low": 12909.74,
        "priceList": [
            {
                "price": 12961.87,
                "ask": 93,
                "bid": 72
            },
            {
                "price": 12780.5,
                "ask": 87,
                "bid": 93
            },
            {
                "price": 12984.25,
                "ask": 34,
                "bid": 61
            },
            {
                "price": 12972.69,
                "ask": 35,
                "bid": 59
            },
            {
                "price": 12709.11,
                "ask": 64,
                "bid": 74
            },
            {
                "price": 12835.34,
                "ask": 67,
                "bid": 88
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcf4a88709a5990c26",
        "high": 12767.84,
        "open": 12977.68,
        "close": 12780.14,
        "low": 12805.08,
        "priceList": [
            {
                "price": 12727.03,
                "ask": 30,
                "bid": 51
            },
            {
                "price": 12774.11,
                "ask": 18,
                "bid": 23
            },
            {
                "price": 12765.25,
                "ask": 26,
                "bid": 69
            },
            {
                "price": 12869.49,
                "ask": 5,
                "bid": 51
            },
            {
                "price": 12786.02,
                "ask": 85,
                "bid": 68
            },
            {
                "price": 12805.52,
                "ask": 18,
                "bid": 92
            },
            {
                "price": 12721.63,
                "ask": 51,
                "bid": 66
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc6c3514051e69eae5",
        "high": 12881.67,
        "open": 12750.88,
        "close": 12931.7,
        "low": 12755.26,
        "priceList": [
            {
                "price": 12737.88,
                "ask": 51,
                "bid": 77
            },
            {
                "price": 12947.49,
                "ask": 9,
                "bid": 27
            },
            {
                "price": 12981.86,
                "ask": 69,
                "bid": 43
            },
            {
                "price": 12877.07,
                "ask": 78,
                "bid": 36
            },
            {
                "price": 12720.19,
                "ask": 91,
                "bid": 40
            },
            {
                "price": 12963.7,
                "ask": 24,
                "bid": 46
            },
            {
                "price": 12793.38,
                "ask": 61,
                "bid": 49
            },
            {
                "price": 12804.06,
                "ask": 78,
                "bid": 7
            },
            {
                "price": 12885.36,
                "ask": 22,
                "bid": 45
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc3b8f2a5b9cef816c",
        "high": 12896.06,
        "open": 12813.96,
        "close": 12839.81,
        "low": 12785.96,
        "priceList": [
            {
                "price": 12804.42,
                "ask": 46,
                "bid": 10
            },
            {
                "price": 12818.53,
                "ask": 47,
                "bid": 42
            },
            {
                "price": 12918.85,
                "ask": 19,
                "bid": 85
            },
            {
                "price": 12707.11,
                "ask": 0,
                "bid": 93
            },
            {
                "price": 12813.66,
                "ask": 30,
                "bid": 65
            },
            {
                "price": 12896.04,
                "ask": 43,
                "bid": 59
            },
            {
                "price": 12724.71,
                "ask": 85,
                "bid": 9
            },
            {
                "price": 12977.86,
                "ask": 94,
                "bid": 95
            },
            {
                "price": 12838.49,
                "ask": 72,
                "bid": 30
            },
            {
                "price": 12988.87,
                "ask": 13,
                "bid": 5
            },
            {
                "price": 12852.7,
                "ask": 71,
                "bid": 20
            },
            {
                "price": 12772.62,
                "ask": 79,
                "bid": 50
            },
            {
                "price": 12715.46,
                "ask": 58,
                "bid": 46
            },
            {
                "price": 12991.15,
                "ask": 12,
                "bid": 69
            },
            {
                "price": 12709.35,
                "ask": 89,
                "bid": 35
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc124256de09fd2601",
        "high": 12845.99,
        "open": 12942.03,
        "close": 12977.98,
        "low": 12844.32,
        "priceList": [
            {
                "price": 12822.37,
                "ask": 51,
                "bid": 10
            },
            {
                "price": 12764.58,
                "ask": 77,
                "bid": 30
            },
            {
                "price": 12942.9,
                "ask": 49,
                "bid": 41
            },
            {
                "price": 12781.89,
                "ask": 41,
                "bid": 54
            },
            {
                "price": 12862.14,
                "ask": 73,
                "bid": 40
            },
            {
                "price": 12920.47,
                "ask": 37,
                "bid": 90
            },
            {
                "price": 12786.28,
                "ask": 84,
                "bid": 97
            },
            {
                "price": 12923.93,
                "ask": 54,
                "bid": 18
            },
            {
                "price": 12891.08,
                "ask": 49,
                "bid": 85
            },
            {
                "price": 12721.96,
                "ask": 91,
                "bid": 38
            },
            {
                "price": 12986.01,
                "ask": 28,
                "bid": 33
            },
            {
                "price": 12785.07,
                "ask": 72,
                "bid": 35
            },
            {
                "price": 12726.02,
                "ask": 25,
                "bid": 79
            },
            {
                "price": 12969.76,
                "ask": 53,
                "bid": 94
            },
            {
                "price": 12817.76,
                "ask": 93,
                "bid": 98
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc40a097a7df3e9093",
        "high": 12975.95,
        "open": 12784.8,
        "close": 12712.37,
        "low": 12793.86,
        "priceList": [
            {
                "price": 12735.67,
                "ask": 80,
                "bid": 68
            },
            {
                "price": 12712.46,
                "ask": 62,
                "bid": 3
            },
            {
                "price": 12913.57,
                "ask": 68,
                "bid": 66
            },
            {
                "price": 12767.55,
                "ask": 30,
                "bid": 90
            },
            {
                "price": 12821.56,
                "ask": 96,
                "bid": 18
            },
            {
                "price": 12739.06,
                "ask": 7,
                "bid": 94
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcb0b313f3995f85e6",
        "high": 12890.97,
        "open": 12754.61,
        "close": 12766.52,
        "low": 12857.4,
        "priceList": [
            {
                "price": 12920.55,
                "ask": 63,
                "bid": 31
            },
            {
                "price": 12819.86,
                "ask": 6,
                "bid": 10
            },
            {
                "price": 12864.48,
                "ask": 21,
                "bid": 91
            },
            {
                "price": 12712.21,
                "ask": 20,
                "bid": 20
            },
            {
                "price": 12826.6,
                "ask": 64,
                "bid": 55
            },
            {
                "price": 12823.62,
                "ask": 51,
                "bid": 74
            },
            {
                "price": 12807.37,
                "ask": 92,
                "bid": 60
            },
            {
                "price": 12853.26,
                "ask": 41,
                "bid": 27
            },
            {
                "price": 12909.57,
                "ask": 66,
                "bid": 88
            },
            {
                "price": 12713.85,
                "ask": 62,
                "bid": 41
            },
            {
                "price": 12737.83,
                "ask": 31,
                "bid": 31
            },
            {
                "price": 12783.21,
                "ask": 67,
                "bid": 94
            },
            {
                "price": 12920.66,
                "ask": 71,
                "bid": 7
            },
            {
                "price": 12752.48,
                "ask": 67,
                "bid": 22
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc34dbbcf1e1459316",
        "high": 12981.4,
        "open": 12793.94,
        "close": 12835.59,
        "low": 12884.56,
        "priceList": [
            {
                "price": 12811.57,
                "ask": 4,
                "bid": 63
            },
            {
                "price": 12759.57,
                "ask": 9,
                "bid": 99
            },
            {
                "price": 12993.37,
                "ask": 100,
                "bid": 72
            },
            {
                "price": 12761.26,
                "ask": 61,
                "bid": 36
            },
            {
                "price": 12735.78,
                "ask": 58,
                "bid": 4
            },
            {
                "price": 12961.34,
                "ask": 52,
                "bid": 36
            },
            {
                "price": 12851.5,
                "ask": 44,
                "bid": 18
            },
            {
                "price": 12846.1,
                "ask": 6,
                "bid": 19
            },
            {
                "price": 12936.59,
                "ask": 94,
                "bid": 67
            },
            {
                "price": 12756.17,
                "ask": 70,
                "bid": 73
            },
            {
                "price": 12790.45,
                "ask": 37,
                "bid": 13
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc1116d89d1941b080",
        "high": 12743.51,
        "open": 12729.76,
        "close": 12939.46,
        "low": 12985.75,
        "priceList": [
            {
                "price": 12790.68,
                "ask": 44,
                "bid": 19
            },
            {
                "price": 12972.51,
                "ask": 42,
                "bid": 2
            },
            {
                "price": 12867.55,
                "ask": 21,
                "bid": 51
            },
            {
                "price": 12917.42,
                "ask": 53,
                "bid": 64
            },
            {
                "price": 12961.02,
                "ask": 32,
                "bid": 24
            },
            {
                "price": 12885.1,
                "ask": 29,
                "bid": 47
            },
            {
                "price": 12845.86,
                "ask": 7,
                "bid": 16
            },
            {
                "price": 12851.38,
                "ask": 65,
                "bid": 32
            },
            {
                "price": 12800.18,
                "ask": 34,
                "bid": 13
            },
            {
                "price": 12915.74,
                "ask": 35,
                "bid": 75
            },
            {
                "price": 12732.19,
                "ask": 54,
                "bid": 13
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4201298f3b04559c",
        "high": 12957.24,
        "open": 12885.71,
        "close": 12927.76,
        "low": 12915.04,
        "priceList": [
            {
                "price": 12889.39,
                "ask": 98,
                "bid": 17
            },
            {
                "price": 12832.89,
                "ask": 27,
                "bid": 44
            },
            {
                "price": 12990.51,
                "ask": 90,
                "bid": 100
            },
            {
                "price": 12900.42,
                "ask": 34,
                "bid": 63
            },
            {
                "price": 12957.66,
                "ask": 89,
                "bid": 76
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc8697fb30a389ee56",
        "high": 12967.25,
        "open": 12944.95,
        "close": 12885.79,
        "low": 12936.14,
        "priceList": [
            {
                "price": 12947.96,
                "ask": 55,
                "bid": 52
            },
            {
                "price": 12892.29,
                "ask": 65,
                "bid": 41
            },
            {
                "price": 12848.3,
                "ask": 100,
                "bid": 51
            },
            {
                "price": 12740.19,
                "ask": 86,
                "bid": 30
            },
            {
                "price": 12704.73,
                "ask": 73,
                "bid": 22
            },
            {
                "price": 12849.7,
                "ask": 55,
                "bid": 52
            },
            {
                "price": 12790.21,
                "ask": 92,
                "bid": 100
            },
            {
                "price": 12876.56,
                "ask": 18,
                "bid": 39
            },
            {
                "price": 12881.39,
                "ask": 51,
                "bid": 43
            },
            {
                "price": 12846.67,
                "ask": 70,
                "bid": 15
            },
            {
                "price": 12720.94,
                "ask": 70,
                "bid": 62
            },
            {
                "price": 12765.7,
                "ask": 58,
                "bid": 22
            },
            {
                "price": 12803.96,
                "ask": 43,
                "bid": 79
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dced331d507f35229a",
        "high": 12975.47,
        "open": 12887.11,
        "close": 12859.81,
        "low": 12840.07,
        "priceList": [
            {
                "price": 12897.69,
                "ask": 37,
                "bid": 96
            },
            {
                "price": 12980.93,
                "ask": 71,
                "bid": 94
            },
            {
                "price": 12963.56,
                "ask": 38,
                "bid": 53
            },
            {
                "price": 12787.41,
                "ask": 65,
                "bid": 49
            },
            {
                "price": 12951.19,
                "ask": 13,
                "bid": 13
            },
            {
                "price": 12971.98,
                "ask": 21,
                "bid": 98
            },
            {
                "price": 12752,
                "ask": 59,
                "bid": 20
            },
            {
                "price": 12972.78,
                "ask": 18,
                "bid": 14
            },
            {
                "price": 12732.26,
                "ask": 73,
                "bid": 68
            },
            {
                "price": 12780.02,
                "ask": 64,
                "bid": 11
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dce00dcf451b869621",
        "high": 12762.47,
        "open": 12987.31,
        "close": 12721.35,
        "low": 12822.64,
        "priceList": [
            {
                "price": 12810.9,
                "ask": 29,
                "bid": 13
            },
            {
                "price": 12770.8,
                "ask": 13,
                "bid": 67
            },
            {
                "price": 12858.69,
                "ask": 41,
                "bid": 22
            },
            {
                "price": 12873.5,
                "ask": 73,
                "bid": 80
            },
            {
                "price": 12869.67,
                "ask": 92,
                "bid": 93
            },
            {
                "price": 12764.08,
                "ask": 83,
                "bid": 36
            },
            {
                "price": 12898.76,
                "ask": 29,
                "bid": 25
            },
            {
                "price": 12866.35,
                "ask": 35,
                "bid": 10
            },
            {
                "price": 12752.64,
                "ask": 4,
                "bid": 31
            },
            {
                "price": 12986.12,
                "ask": 51,
                "bid": 66
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcdc7315323ddf86a6",
        "high": 12939.75,
        "open": 12949.71,
        "close": 12774.63,
        "low": 12904.72,
        "priceList": [
            {
                "price": 12990.54,
                "ask": 56,
                "bid": 57
            },
            {
                "price": 12925.72,
                "ask": 40,
                "bid": 12
            },
            {
                "price": 12813.05,
                "ask": 40,
                "bid": 98
            },
            {
                "price": 12707.98,
                "ask": 53,
                "bid": 71
            },
            {
                "price": 12874.2,
                "ask": 79,
                "bid": 84
            },
            {
                "price": 12716.73,
                "ask": 81,
                "bid": 72
            },
            {
                "price": 12744.26,
                "ask": 11,
                "bid": 14
            },
            {
                "price": 12783.44,
                "ask": 78,
                "bid": 83
            },
            {
                "price": 12861.07,
                "ask": 14,
                "bid": 70
            },
            {
                "price": 12823.08,
                "ask": 35,
                "bid": 74
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcdad53283ed35e5d1",
        "high": 12947.9,
        "open": 12921.14,
        "close": 12928.58,
        "low": 12996.46,
        "priceList": [
            {
                "price": 12900.17,
                "ask": 15,
                "bid": 84
            },
            {
                "price": 12946.17,
                "ask": 40,
                "bid": 32
            },
            {
                "price": 12936.93,
                "ask": 85,
                "bid": 99
            },
            {
                "price": 12936.18,
                "ask": 75,
                "bid": 49
            },
            {
                "price": 12772.55,
                "ask": 7,
                "bid": 96
            },
            {
                "price": 12972.23,
                "ask": 44,
                "bid": 62
            },
            {
                "price": 12981.26,
                "ask": 72,
                "bid": 16
            },
            {
                "price": 12932.81,
                "ask": 95,
                "bid": 92
            },
            {
                "price": 12880.44,
                "ask": 60,
                "bid": 0
            },
            {
                "price": 12705.72,
                "ask": 77,
                "bid": 85
            },
            {
                "price": 12786.99,
                "ask": 11,
                "bid": 91
            },
            {
                "price": 12785.03,
                "ask": 61,
                "bid": 99
            },
            {
                "price": 12830.91,
                "ask": 40,
                "bid": 28
            },
            {
                "price": 12931.23,
                "ask": 7,
                "bid": 61
            },
            {
                "price": 12813.68,
                "ask": 90,
                "bid": 57
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcc4b7c43c671afb44",
        "high": 12993.59,
        "open": 12740.9,
        "close": 12873.68,
        "low": 12935.56,
        "priceList": [
            {
                "price": 12911.08,
                "ask": 47,
                "bid": 53
            },
            {
                "price": 12787.58,
                "ask": 100,
                "bid": 26
            },
            {
                "price": 12719.95,
                "ask": 78,
                "bid": 82
            },
            {
                "price": 12756.09,
                "ask": 88,
                "bid": 53
            },
            {
                "price": 12716.21,
                "ask": 30,
                "bid": 50
            },
            {
                "price": 12932.19,
                "ask": 64,
                "bid": 14
            },
            {
                "price": 12843.07,
                "ask": 13,
                "bid": 55
            },
            {
                "price": 12704.67,
                "ask": 9,
                "bid": 63
            },
            {
                "price": 12916.4,
                "ask": 93,
                "bid": 64
            },
            {
                "price": 12988.31,
                "ask": 11,
                "bid": 8
            },
            {
                "price": 12782.28,
                "ask": 93,
                "bid": 85
            },
            {
                "price": 12836.06,
                "ask": 26,
                "bid": 80
            },
            {
                "price": 12953.19,
                "ask": 52,
                "bid": 78
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc2fee06a53a0e66f7",
        "high": 12855.16,
        "open": 12785.05,
        "close": 12968.29,
        "low": 12780.75,
        "priceList": [
            {
                "price": 12832.69,
                "ask": 1,
                "bid": 86
            },
            {
                "price": 12896.77,
                "ask": 30,
                "bid": 1
            },
            {
                "price": 12864.47,
                "ask": 36,
                "bid": 95
            },
            {
                "price": 12999.13,
                "ask": 6,
                "bid": 52
            },
            {
                "price": 12750.9,
                "ask": 15,
                "bid": 93
            },
            {
                "price": 12931.81,
                "ask": 6,
                "bid": 67
            },
            {
                "price": 12866.98,
                "ask": 17,
                "bid": 72
            },
            {
                "price": 12880.54,
                "ask": 67,
                "bid": 23
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcf3cae6656db7a78b",
        "high": 12733.46,
        "open": 12897.15,
        "close": 12978.22,
        "low": 12732.03,
        "priceList": [
            {
                "price": 12839.7,
                "ask": 13,
                "bid": 58
            },
            {
                "price": 12927.47,
                "ask": 36,
                "bid": 16
            },
            {
                "price": 12972.06,
                "ask": 31,
                "bid": 50
            },
            {
                "price": 12866.37,
                "ask": 14,
                "bid": 67
            },
            {
                "price": 12997.86,
                "ask": 25,
                "bid": 92
            },
            {
                "price": 12757.07,
                "ask": 56,
                "bid": 12
            },
            {
                "price": 12927.01,
                "ask": 34,
                "bid": 20
            },
            {
                "price": 12959.38,
                "ask": 46,
                "bid": 67
            },
            {
                "price": 12886.78,
                "ask": 36,
                "bid": 31
            },
            {
                "price": 12968.18,
                "ask": 78,
                "bid": 98
            },
            {
                "price": 12950.98,
                "ask": 10,
                "bid": 61
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc21b2e571370363ad",
        "high": 12969.43,
        "open": 12832.62,
        "close": 12807.51,
        "low": 12728.17,
        "priceList": [
            {
                "price": 12968.25,
                "ask": 12,
                "bid": 96
            },
            {
                "price": 12719.5,
                "ask": 77,
                "bid": 34
            },
            {
                "price": 12726.28,
                "ask": 33,
                "bid": 4
            },
            {
                "price": 12907.8,
                "ask": 98,
                "bid": 39
            },
            {
                "price": 12804.86,
                "ask": 16,
                "bid": 91
            },
            {
                "price": 12984.29,
                "ask": 78,
                "bid": 25
            },
            {
                "price": 12720.14,
                "ask": 44,
                "bid": 38
            },
            {
                "price": 12802.69,
                "ask": 27,
                "bid": 99
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc896144c09db70920",
        "high": 12743.44,
        "open": 12743.82,
        "close": 12710.16,
        "low": 12912.21,
        "priceList": [
            {
                "price": 12952.69,
                "ask": 16,
                "bid": 51
            },
            {
                "price": 12976.17,
                "ask": 28,
                "bid": 53
            },
            {
                "price": 12735.63,
                "ask": 70,
                "bid": 72
            },
            {
                "price": 12734.89,
                "ask": 79,
                "bid": 31
            },
            {
                "price": 12877.61,
                "ask": 90,
                "bid": 14
            },
            {
                "price": 12709.76,
                "ask": 95,
                "bid": 2
            },
            {
                "price": 12975.33,
                "ask": 50,
                "bid": 54
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc8c61f41bb1b67c1b",
        "high": 12972.42,
        "open": 12744.25,
        "close": 12762.74,
        "low": 12787.5,
        "priceList": [
            {
                "price": 12725.28,
                "ask": 55,
                "bid": 35
            },
            {
                "price": 12807.89,
                "ask": 83,
                "bid": 54
            },
            {
                "price": 12818.58,
                "ask": 43,
                "bid": 26
            },
            {
                "price": 12827.78,
                "ask": 57,
                "bid": 10
            },
            {
                "price": 12754.15,
                "ask": 32,
                "bid": 81
            },
            {
                "price": 12825.46,
                "ask": 81,
                "bid": 17
            },
            {
                "price": 12900.02,
                "ask": 76,
                "bid": 50
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc68bf49504257b8c8",
        "high": 12747.33,
        "open": 12861.63,
        "close": 12986.59,
        "low": 12978.43,
        "priceList": [
            {
                "price": 12797.36,
                "ask": 5,
                "bid": 78
            },
            {
                "price": 12890.8,
                "ask": 43,
                "bid": 88
            },
            {
                "price": 12772.22,
                "ask": 32,
                "bid": 91
            },
            {
                "price": 12923.65,
                "ask": 90,
                "bid": 1
            },
            {
                "price": 12789.6,
                "ask": 78,
                "bid": 95
            },
            {
                "price": 12760.5,
                "ask": 92,
                "bid": 19
            },
            {
                "price": 12874.26,
                "ask": 37,
                "bid": 72
            },
            {
                "price": 12901.82,
                "ask": 1,
                "bid": 10
            },
            {
                "price": 12840.03,
                "ask": 99,
                "bid": 88
            },
            {
                "price": 12834.9,
                "ask": 3,
                "bid": 48
            },
            {
                "price": 12700.32,
                "ask": 80,
                "bid": 39
            },
            {
                "price": 12787.83,
                "ask": 8,
                "bid": 30
            },
            {
                "price": 12970.4,
                "ask": 62,
                "bid": 58
            },
            {
                "price": 12996.5,
                "ask": 14,
                "bid": 14
            },
            {
                "price": 12899.1,
                "ask": 45,
                "bid": 59
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc5eb9c13d91d3932f",
        "high": 12927.02,
        "open": 12915.21,
        "close": 12956.76,
        "low": 12814.2,
        "priceList": [
            {
                "price": 12880.49,
                "ask": 60,
                "bid": 73
            },
            {
                "price": 12895.69,
                "ask": 19,
                "bid": 91
            },
            {
                "price": 12876.15,
                "ask": 49,
                "bid": 53
            },
            {
                "price": 12934.18,
                "ask": 70,
                "bid": 86
            },
            {
                "price": 12760.56,
                "ask": 13,
                "bid": 85
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dca82b3dc521bb30dc",
        "high": 12766.3,
        "open": 12819.42,
        "close": 12962.93,
        "low": 12792.61,
        "priceList": [
            {
                "price": 12928.22,
                "ask": 49,
                "bid": 100
            },
            {
                "price": 12780.15,
                "ask": 1,
                "bid": 64
            },
            {
                "price": 12918.57,
                "ask": 84,
                "bid": 32
            },
            {
                "price": 12837.34,
                "ask": 57,
                "bid": 81
            },
            {
                "price": 12993.86,
                "ask": 27,
                "bid": 100
            },
            {
                "price": 12851.78,
                "ask": 4,
                "bid": 5
            },
            {
                "price": 12732.46,
                "ask": 41,
                "bid": 29
            },
            {
                "price": 12948.64,
                "ask": 33,
                "bid": 80
            },
            {
                "price": 12723.28,
                "ask": 47,
                "bid": 0
            },
            {
                "price": 12807.21,
                "ask": 59,
                "bid": 98
            },
            {
                "price": 12994.6,
                "ask": 34,
                "bid": 92
            },
            {
                "price": 12903.64,
                "ask": 75,
                "bid": 7
            },
            {
                "price": 12771.9,
                "ask": 96,
                "bid": 95
            },
            {
                "price": 12844.07,
                "ask": 66,
                "bid": 32
            },
            {
                "price": 12937.38,
                "ask": 100,
                "bid": 90
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dce42b74f2eb1ed7d9",
        "high": 12977.11,
        "open": 12756.12,
        "close": 12883.24,
        "low": 12817.63,
        "priceList": [
            {
                "price": 12977.48,
                "ask": 26,
                "bid": 12
            },
            {
                "price": 12833.39,
                "ask": 59,
                "bid": 92
            },
            {
                "price": 12988.83,
                "ask": 75,
                "bid": 37
            },
            {
                "price": 12800.09,
                "ask": 5,
                "bid": 40
            },
            {
                "price": 12940.52,
                "ask": 62,
                "bid": 53
            },
            {
                "price": 12921.81,
                "ask": 57,
                "bid": 6
            },
            {
                "price": 12880.34,
                "ask": 80,
                "bid": 25
            },
            {
                "price": 12787.82,
                "ask": 69,
                "bid": 88
            },
            {
                "price": 12735.28,
                "ask": 62,
                "bid": 82
            },
            {
                "price": 12779.12,
                "ask": 34,
                "bid": 11
            },
            {
                "price": 12756.68,
                "ask": 57,
                "bid": 3
            },
            {
                "price": 12772.29,
                "ask": 100,
                "bid": 37
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc37a8c9bddacb3525",
        "high": 12790.35,
        "open": 12825.04,
        "close": 12993.12,
        "low": 12730.45,
        "priceList": [
            {
                "price": 12798.34,
                "ask": 78,
                "bid": 30
            },
            {
                "price": 12862.49,
                "ask": 18,
                "bid": 19
            },
            {
                "price": 12770.8,
                "ask": 10,
                "bid": 81
            },
            {
                "price": 12843.58,
                "ask": 78,
                "bid": 59
            },
            {
                "price": 12714.07,
                "ask": 64,
                "bid": 15
            },
            {
                "price": 12858.18,
                "ask": 86,
                "bid": 35
            },
            {
                "price": 12766.84,
                "ask": 93,
                "bid": 3
            },
            {
                "price": 12773.25,
                "ask": 91,
                "bid": 72
            },
            {
                "price": 12879.39,
                "ask": 50,
                "bid": 95
            },
            {
                "price": 12898.22,
                "ask": 35,
                "bid": 57
            },
            {
                "price": 12921,
                "ask": 96,
                "bid": 21
            },
            {
                "price": 12794.56,
                "ask": 89,
                "bid": 40
            },
            {
                "price": 12798.35,
                "ask": 91,
                "bid": 83
            },
            {
                "price": 12703.55,
                "ask": 10,
                "bid": 40
            },
            {
                "price": 12822.23,
                "ask": 18,
                "bid": 88
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc11185307646ad296",
        "high": 12707.89,
        "open": 12888.24,
        "close": 12878.37,
        "low": 12912.01,
        "priceList": [
            {
                "price": 12984.62,
                "ask": 9,
                "bid": 69
            },
            {
                "price": 12991.53,
                "ask": 69,
                "bid": 32
            },
            {
                "price": 12903.94,
                "ask": 21,
                "bid": 32
            },
            {
                "price": 12937.54,
                "ask": 10,
                "bid": 77
            },
            {
                "price": 12919.1,
                "ask": 34,
                "bid": 22
            },
            {
                "price": 12702.68,
                "ask": 60,
                "bid": 18
            },
            {
                "price": 12870.92,
                "ask": 50,
                "bid": 72
            },
            {
                "price": 12750.65,
                "ask": 99,
                "bid": 46
            },
            {
                "price": 12900.45,
                "ask": 13,
                "bid": 49
            },
            {
                "price": 12893.81,
                "ask": 60,
                "bid": 33
            },
            {
                "price": 12917.56,
                "ask": 9,
                "bid": 42
            },
            {
                "price": 12913.98,
                "ask": 36,
                "bid": 47
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcaaa3b56ed4156023",
        "high": 12895,
        "open": 12730.29,
        "close": 12965.36,
        "low": 12985.2,
        "priceList": [
            {
                "price": 12869.62,
                "ask": 31,
                "bid": 82
            },
            {
                "price": 12712.12,
                "ask": 58,
                "bid": 100
            },
            {
                "price": 12924.66,
                "ask": 16,
                "bid": 34
            },
            {
                "price": 12806.19,
                "ask": 32,
                "bid": 77
            },
            {
                "price": 12841.15,
                "ask": 36,
                "bid": 78
            },
            {
                "price": 12958.79,
                "ask": 84,
                "bid": 66
            },
            {
                "price": 12855.09,
                "ask": 18,
                "bid": 75
            },
            {
                "price": 12847.91,
                "ask": 54,
                "bid": 12
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc6589fd0652503be4",
        "high": 12884.03,
        "open": 12820.76,
        "close": 12829.64,
        "low": 12866.32,
        "priceList": [
            {
                "price": 12772.63,
                "ask": 63,
                "bid": 34
            },
            {
                "price": 12715.15,
                "ask": 38,
                "bid": 64
            },
            {
                "price": 12940.43,
                "ask": 24,
                "bid": 84
            },
            {
                "price": 12863.44,
                "ask": 0,
                "bid": 88
            },
            {
                "price": 12860.78,
                "ask": 65,
                "bid": 14
            },
            {
                "price": 12782.91,
                "ask": 74,
                "bid": 54
            },
            {
                "price": 12910.31,
                "ask": 61,
                "bid": 64
            },
            {
                "price": 12929.03,
                "ask": 6,
                "bid": 100
            },
            {
                "price": 12859.21,
                "ask": 89,
                "bid": 38
            },
            {
                "price": 12759,
                "ask": 69,
                "bid": 8
            },
            {
                "price": 12761.61,
                "ask": 90,
                "bid": 94
            },
            {
                "price": 12736.43,
                "ask": 92,
                "bid": 82
            },
            {
                "price": 12840.35,
                "ask": 81,
                "bid": 24
            },
            {
                "price": 12954.66,
                "ask": 6,
                "bid": 31
            },
            {
                "price": 12745.18,
                "ask": 81,
                "bid": 48
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc0126dec3ef26f004",
        "high": 12710.25,
        "open": 12748.33,
        "close": 12833.51,
        "low": 12923.16,
        "priceList": [
            {
                "price": 12999.1,
                "ask": 71,
                "bid": 52
            },
            {
                "price": 12908.57,
                "ask": 32,
                "bid": 37
            },
            {
                "price": 12756.29,
                "ask": 53,
                "bid": 9
            },
            {
                "price": 12733.45,
                "ask": 94,
                "bid": 24
            },
            {
                "price": 12931.58,
                "ask": 71,
                "bid": 59
            },
            {
                "price": 12732.84,
                "ask": 11,
                "bid": 59
            },
            {
                "price": 12945.73,
                "ask": 19,
                "bid": 45
            },
            {
                "price": 12796.24,
                "ask": 52,
                "bid": 77
            },
            {
                "price": 12780.18,
                "ask": 30,
                "bid": 36
            },
            {
                "price": 12810.12,
                "ask": 33,
                "bid": 85
            },
            {
                "price": 12833.11,
                "ask": 78,
                "bid": 64
            },
            {
                "price": 12788.47,
                "ask": 31,
                "bid": 59
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc3bdda29d5a44448a",
        "high": 12784.24,
        "open": 12955.01,
        "close": 12793.72,
        "low": 12812.39,
        "priceList": [
            {
                "price": 12947.92,
                "ask": 80,
                "bid": 3
            },
            {
                "price": 12903.85,
                "ask": 95,
                "bid": 55
            },
            {
                "price": 12770.25,
                "ask": 49,
                "bid": 45
            },
            {
                "price": 12724.11,
                "ask": 38,
                "bid": 44
            },
            {
                "price": 12720.02,
                "ask": 82,
                "bid": 12
            },
            {
                "price": 12947.41,
                "ask": 83,
                "bid": 8
            },
            {
                "price": 12827.69,
                "ask": 84,
                "bid": 30
            },
            {
                "price": 12962.9,
                "ask": 71,
                "bid": 71
            },
            {
                "price": 12717.42,
                "ask": 54,
                "bid": 89
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc4c61575c0b2d4a6d",
        "high": 12763.14,
        "open": 12920.31,
        "close": 12868.74,
        "low": 12977.19,
        "priceList": [
            {
                "price": 12739.73,
                "ask": 45,
                "bid": 76
            },
            {
                "price": 12917.17,
                "ask": 64,
                "bid": 69
            },
            {
                "price": 12964.86,
                "ask": 35,
                "bid": 48
            },
            {
                "price": 12710.31,
                "ask": 55,
                "bid": 28
            },
            {
                "price": 12861.75,
                "ask": 20,
                "bid": 62
            },
            {
                "price": 12872.43,
                "ask": 97,
                "bid": 8
            },
            {
                "price": 12704.77,
                "ask": 40,
                "bid": 58
            },
            {
                "price": 12861.86,
                "ask": 75,
                "bid": 2
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc23338ddc4f247fd7",
        "high": 12732.62,
        "open": 12890.94,
        "close": 12809.32,
        "low": 12959.97,
        "priceList": [
            {
                "price": 12998.87,
                "ask": 26,
                "bid": 57
            },
            {
                "price": 12776.3,
                "ask": 26,
                "bid": 4
            },
            {
                "price": 12980.45,
                "ask": 59,
                "bid": 92
            },
            {
                "price": 12781.37,
                "ask": 47,
                "bid": 35
            },
            {
                "price": 12988.74,
                "ask": 13,
                "bid": 100
            },
            {
                "price": 12940.53,
                "ask": 54,
                "bid": 47
            },
            {
                "price": 12933.55,
                "ask": 93,
                "bid": 65
            },
            {
                "price": 12701.06,
                "ask": 72,
                "bid": 15
            },
            {
                "price": 12711.14,
                "ask": 64,
                "bid": 49
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcb5dfb94221f0d251",
        "high": 12915.86,
        "open": 12931.47,
        "close": 12966.61,
        "low": 12755.45,
        "priceList": [
            {
                "price": 12768.44,
                "ask": 39,
                "bid": 70
            },
            {
                "price": 12787.22,
                "ask": 59,
                "bid": 21
            },
            {
                "price": 12964.64,
                "ask": 18,
                "bid": 78
            },
            {
                "price": 12841.47,
                "ask": 23,
                "bid": 38
            },
            {
                "price": 12824.13,
                "ask": 89,
                "bid": 13
            },
            {
                "price": 12715.26,
                "ask": 3,
                "bid": 44
            },
            {
                "price": 12913.72,
                "ask": 36,
                "bid": 53
            },
            {
                "price": 12916.88,
                "ask": 58,
                "bid": 100
            },
            {
                "price": 12758.02,
                "ask": 0,
                "bid": 40
            },
            {
                "price": 12945.31,
                "ask": 58,
                "bid": 13
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc43adaa2e50d408ac",
        "high": 12708.81,
        "open": 12993.89,
        "close": 12983.41,
        "low": 12931.46,
        "priceList": [
            {
                "price": 12927.87,
                "ask": 65,
                "bid": 64
            },
            {
                "price": 12722.21,
                "ask": 31,
                "bid": 65
            },
            {
                "price": 12948.17,
                "ask": 68,
                "bid": 60
            },
            {
                "price": 12749.1,
                "ask": 58,
                "bid": 1
            },
            {
                "price": 12954.33,
                "ask": 50,
                "bid": 49
            },
            {
                "price": 12877.53,
                "ask": 83,
                "bid": 65
            },
            {
                "price": 12756.76,
                "ask": 91,
                "bid": 15
            },
            {
                "price": 12999.35,
                "ask": 25,
                "bid": 19
            },
            {
                "price": 12762.12,
                "ask": 1,
                "bid": 12
            },
            {
                "price": 12722.74,
                "ask": 54,
                "bid": 89
            },
            {
                "price": 12884.96,
                "ask": 36,
                "bid": 93
            },
            {
                "price": 12859.85,
                "ask": 12,
                "bid": 14
            },
            {
                "price": 12981.04,
                "ask": 8,
                "bid": 70
            },
            {
                "price": 12921,
                "ask": 44,
                "bid": 16
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dce8e0954e53a7049e",
        "high": 12714.24,
        "open": 12873.7,
        "close": 12968.78,
        "low": 12935.44,
        "priceList": [
            {
                "price": 12755.86,
                "ask": 69,
                "bid": 69
            },
            {
                "price": 12871.24,
                "ask": 98,
                "bid": 51
            },
            {
                "price": 12945.24,
                "ask": 46,
                "bid": 42
            },
            {
                "price": 12893.75,
                "ask": 32,
                "bid": 67
            },
            {
                "price": 12720.04,
                "ask": 58,
                "bid": 72
            },
            {
                "price": 12814.29,
                "ask": 41,
                "bid": 93
            },
            {
                "price": 12843.06,
                "ask": 7,
                "bid": 44
            },
            {
                "price": 12942.34,
                "ask": 92,
                "bid": 56
            },
            {
                "price": 12748.86,
                "ask": 54,
                "bid": 66
            },
            {
                "price": 12892.93,
                "ask": 11,
                "bid": 40
            },
            {
                "price": 12719.8,
                "ask": 67,
                "bid": 4
            },
            {
                "price": 12716.55,
                "ask": 41,
                "bid": 22
            },
            {
                "price": 12869.93,
                "ask": 53,
                "bid": 48
            },
            {
                "price": 12983.08,
                "ask": 45,
                "bid": 30
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc31b40c87b8cd893c",
        "high": 12763.91,
        "open": 12822.38,
        "close": 12996.62,
        "low": 12858.64,
        "priceList": [
            {
                "price": 12767.61,
                "ask": 36,
                "bid": 8
            },
            {
                "price": 12975.77,
                "ask": 97,
                "bid": 22
            },
            {
                "price": 12892.39,
                "ask": 69,
                "bid": 29
            },
            {
                "price": 12714,
                "ask": 91,
                "bid": 2
            },
            {
                "price": 12916.61,
                "ask": 13,
                "bid": 87
            },
            {
                "price": 12930.79,
                "ask": 96,
                "bid": 67
            },
            {
                "price": 12829.24,
                "ask": 74,
                "bid": 33
            },
            {
                "price": 12837.9,
                "ask": 56,
                "bid": 93
            },
            {
                "price": 12989.24,
                "ask": 88,
                "bid": 36
            },
            {
                "price": 12793.81,
                "ask": 90,
                "bid": 70
            },
            {
                "price": 12728.62,
                "ask": 94,
                "bid": 61
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc3d9d33e70d308265",
        "high": 12942.4,
        "open": 12846.71,
        "close": 12866.69,
        "low": 12835.66,
        "priceList": [
            {
                "price": 12940.8,
                "ask": 84,
                "bid": 90
            },
            {
                "price": 12867.13,
                "ask": 2,
                "bid": 89
            },
            {
                "price": 12867.82,
                "ask": 31,
                "bid": 60
            },
            {
                "price": 12753.27,
                "ask": 16,
                "bid": 8
            },
            {
                "price": 12845.38,
                "ask": 22,
                "bid": 18
            },
            {
                "price": 12817.96,
                "ask": 73,
                "bid": 37
            },
            {
                "price": 12932.35,
                "ask": 81,
                "bid": 45
            },
            {
                "price": 12984.17,
                "ask": 8,
                "bid": 0
            },
            {
                "price": 12845.92,
                "ask": 0,
                "bid": 100
            },
            {
                "price": 12869.01,
                "ask": 23,
                "bid": 89
            },
            {
                "price": 12916.69,
                "ask": 74,
                "bid": 69
            },
            {
                "price": 12753.73,
                "ask": 39,
                "bid": 82
            },
            {
                "price": 12798,
                "ask": 95,
                "bid": 69
            },
            {
                "price": 12906.14,
                "ask": 71,
                "bid": 82
            },
            {
                "price": 12919.58,
                "ask": 52,
                "bid": 83
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc8c7f6c3c5932d1db",
        "high": 12792.98,
        "open": 12953.17,
        "close": 12934.42,
        "low": 12926.28,
        "priceList": [
            {
                "price": 12928.19,
                "ask": 35,
                "bid": 2
            },
            {
                "price": 12887.65,
                "ask": 82,
                "bid": 27
            },
            {
                "price": 12843.53,
                "ask": 53,
                "bid": 43
            },
            {
                "price": 12824.25,
                "ask": 57,
                "bid": 9
            },
            {
                "price": 12975.53,
                "ask": 25,
                "bid": 99
            },
            {
                "price": 12770.92,
                "ask": 8,
                "bid": 55
            },
            {
                "price": 12931.4,
                "ask": 25,
                "bid": 38
            },
            {
                "price": 12908.59,
                "ask": 17,
                "bid": 73
            },
            {
                "price": 12803.29,
                "ask": 3,
                "bid": 73
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc09af5debc54e4d86",
        "high": 12841.89,
        "open": 12722.25,
        "close": 12739.35,
        "low": 12959.69,
        "priceList": [
            {
                "price": 12755.97,
                "ask": 58,
                "bid": 42
            },
            {
                "price": 12962.85,
                "ask": 12,
                "bid": 54
            },
            {
                "price": 12960.16,
                "ask": 72,
                "bid": 22
            },
            {
                "price": 12829.1,
                "ask": 82,
                "bid": 68
            },
            {
                "price": 12714.7,
                "ask": 22,
                "bid": 72
            },
            {
                "price": 12751.24,
                "ask": 1,
                "bid": 72
            },
            {
                "price": 12880.29,
                "ask": 17,
                "bid": 31
            },
            {
                "price": 12770.73,
                "ask": 33,
                "bid": 51
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc701e7bccff9af866",
        "high": 12979.53,
        "open": 12905.29,
        "close": 12952.9,
        "low": 12708.79,
        "priceList": [
            {
                "price": 12735.79,
                "ask": 16,
                "bid": 2
            },
            {
                "price": 12733.39,
                "ask": 83,
                "bid": 77
            },
            {
                "price": 12861.38,
                "ask": 56,
                "bid": 7
            },
            {
                "price": 12723.95,
                "ask": 31,
                "bid": 18
            },
            {
                "price": 12712.96,
                "ask": 40,
                "bid": 89
            },
            {
                "price": 12783.41,
                "ask": 49,
                "bid": 100
            },
            {
                "price": 12797.39,
                "ask": 42,
                "bid": 33
            },
            {
                "price": 12865.89,
                "ask": 93,
                "bid": 2
            },
            {
                "price": 12755.32,
                "ask": 59,
                "bid": 20
            },
            {
                "price": 12909.13,
                "ask": 55,
                "bid": 31
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc0d3815b0c6d117ef",
        "high": 12744.58,
        "open": 12888.34,
        "close": 12739.29,
        "low": 12905.66,
        "priceList": [
            {
                "price": 12703.87,
                "ask": 34,
                "bid": 47
            },
            {
                "price": 12719.34,
                "ask": 9,
                "bid": 0
            },
            {
                "price": 12702.1,
                "ask": 9,
                "bid": 7
            },
            {
                "price": 12885.63,
                "ask": 33,
                "bid": 49
            },
            {
                "price": 12860.69,
                "ask": 16,
                "bid": 66
            },
            {
                "price": 12832.28,
                "ask": 69,
                "bid": 31
            },
            {
                "price": 12829.8,
                "ask": 59,
                "bid": 4
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc75acfe92a452adcc",
        "high": 12992.3,
        "open": 12952.51,
        "close": 12961.96,
        "low": 12792.72,
        "priceList": [
            {
                "price": 12944.38,
                "ask": 50,
                "bid": 93
            },
            {
                "price": 12790.09,
                "ask": 61,
                "bid": 49
            },
            {
                "price": 12938.02,
                "ask": 31,
                "bid": 64
            },
            {
                "price": 12964.09,
                "ask": 64,
                "bid": 13
            },
            {
                "price": 12782.51,
                "ask": 41,
                "bid": 17
            },
            {
                "price": 12985.06,
                "ask": 49,
                "bid": 7
            },
            {
                "price": 12710.69,
                "ask": 4,
                "bid": 79
            },
            {
                "price": 12894.96,
                "ask": 15,
                "bid": 83
            },
            {
                "price": 12906.75,
                "ask": 68,
                "bid": 3
            },
            {
                "price": 12943.64,
                "ask": 20,
                "bid": 43
            },
            {
                "price": 12914.34,
                "ask": 94,
                "bid": 53
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcfd2c4336c2098333",
        "high": 12980.94,
        "open": 12897.06,
        "close": 12909.64,
        "low": 12719.02,
        "priceList": [
            {
                "price": 12757.27,
                "ask": 59,
                "bid": 73
            },
            {
                "price": 12942.71,
                "ask": 94,
                "bid": 12
            },
            {
                "price": 12865.1,
                "ask": 68,
                "bid": 58
            },
            {
                "price": 12835.31,
                "ask": 53,
                "bid": 62
            },
            {
                "price": 12722.01,
                "ask": 92,
                "bid": 98
            },
            {
                "price": 12904.8,
                "ask": 50,
                "bid": 89
            },
            {
                "price": 12725.67,
                "ask": 84,
                "bid": 82
            },
            {
                "price": 12939.39,
                "ask": 69,
                "bid": 87
            },
            {
                "price": 12904.24,
                "ask": 93,
                "bid": 86
            },
            {
                "price": 12840.48,
                "ask": 31,
                "bid": 92
            },
            {
                "price": 12849.05,
                "ask": 84,
                "bid": 76
            },
            {
                "price": 12902.86,
                "ask": 7,
                "bid": 43
            },
            {
                "price": 12790.22,
                "ask": 35,
                "bid": 76
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcd390ea6c73755cc5",
        "high": 12871.28,
        "open": 12833.07,
        "close": 12995.64,
        "low": 12853.84,
        "priceList": [
            {
                "price": 12936.09,
                "ask": 98,
                "bid": 61
            },
            {
                "price": 12844.86,
                "ask": 43,
                "bid": 5
            },
            {
                "price": 12926.45,
                "ask": 7,
                "bid": 67
            },
            {
                "price": 12967.6,
                "ask": 52,
                "bid": 53
            },
            {
                "price": 12800.03,
                "ask": 78,
                "bid": 42
            },
            {
                "price": 12749.03,
                "ask": 35,
                "bid": 10
            },
            {
                "price": 12707.37,
                "ask": 73,
                "bid": 78
            },
            {
                "price": 12738.66,
                "ask": 31,
                "bid": 85
            },
            {
                "price": 12705.3,
                "ask": 40,
                "bid": 55
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcf4473025d51653a9",
        "high": 12898.09,
        "open": 12978.36,
        "close": 12821.54,
        "low": 12751.79,
        "priceList": [
            {
                "price": 12789.32,
                "ask": 44,
                "bid": 42
            },
            {
                "price": 12739.81,
                "ask": 71,
                "bid": 41
            },
            {
                "price": 12963.72,
                "ask": 73,
                "bid": 69
            },
            {
                "price": 12830.1,
                "ask": 73,
                "bid": 47
            },
            {
                "price": 12787.11,
                "ask": 91,
                "bid": 60
            },
            {
                "price": 12973.22,
                "ask": 97,
                "bid": 22
            },
            {
                "price": 12938.46,
                "ask": 69,
                "bid": 16
            },
            {
                "price": 12915.42,
                "ask": 92,
                "bid": 69
            },
            {
                "price": 12813.28,
                "ask": 86,
                "bid": 62
            },
            {
                "price": 12807.49,
                "ask": 7,
                "bid": 19
            },
            {
                "price": 12936.67,
                "ask": 17,
                "bid": 63
            },
            {
                "price": 12985.69,
                "ask": 81,
                "bid": 41
            },
            {
                "price": 12913.66,
                "ask": 28,
                "bid": 78
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc1c0b803933e20d5a",
        "high": 12773.03,
        "open": 12771.15,
        "close": 12871.21,
        "low": 12867.38,
        "priceList": [
            {
                "price": 12964.99,
                "ask": 77,
                "bid": 72
            },
            {
                "price": 12972.74,
                "ask": 67,
                "bid": 98
            },
            {
                "price": 12902.91,
                "ask": 45,
                "bid": 8
            },
            {
                "price": 12723.59,
                "ask": 65,
                "bid": 24
            },
            {
                "price": 12858.62,
                "ask": 60,
                "bid": 2
            },
            {
                "price": 12878.4,
                "ask": 10,
                "bid": 21
            },
            {
                "price": 12752.21,
                "ask": 38,
                "bid": 64
            },
            {
                "price": 12913.69,
                "ask": 57,
                "bid": 23
            },
            {
                "price": 12775.27,
                "ask": 73,
                "bid": 82
            },
            {
                "price": 12965.11,
                "ask": 87,
                "bid": 59
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc8aa7a75cc65a416d",
        "high": 12979.16,
        "open": 12940.99,
        "close": 12858.34,
        "low": 12822.48,
        "priceList": [
            {
                "price": 12909.93,
                "ask": 23,
                "bid": 42
            },
            {
                "price": 12700.64,
                "ask": 22,
                "bid": 91
            },
            {
                "price": 12792.91,
                "ask": 77,
                "bid": 44
            },
            {
                "price": 12980.92,
                "ask": 15,
                "bid": 36
            },
            {
                "price": 12858.63,
                "ask": 7,
                "bid": 32
            },
            {
                "price": 12784.53,
                "ask": 46,
                "bid": 23
            },
            {
                "price": 12766.57,
                "ask": 75,
                "bid": 68
            },
            {
                "price": 12895.32,
                "ask": 20,
                "bid": 52
            },
            {
                "price": 12976.27,
                "ask": 43,
                "bid": 74
            },
            {
                "price": 12954.43,
                "ask": 38,
                "bid": 22
            },
            {
                "price": 12818.91,
                "ask": 23,
                "bid": 41
            },
            {
                "price": 12742.67,
                "ask": 22,
                "bid": 9
            },
            {
                "price": 12846.15,
                "ask": 22,
                "bid": 99
            },
            {
                "price": 12861.19,
                "ask": 100,
                "bid": 0
            },
            {
                "price": 12810.63,
                "ask": 19,
                "bid": 38
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc40d02d37be492a4e",
        "high": 12711.12,
        "open": 12940.59,
        "close": 12808.17,
        "low": 12866.91,
        "priceList": [
            {
                "price": 12903.92,
                "ask": 16,
                "bid": 27
            },
            {
                "price": 12962.54,
                "ask": 18,
                "bid": 43
            },
            {
                "price": 12977.43,
                "ask": 37,
                "bid": 28
            },
            {
                "price": 12788.8,
                "ask": 43,
                "bid": 64
            },
            {
                "price": 12910.88,
                "ask": 96,
                "bid": 80
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc9df04c9a420d86a7",
        "high": 12943.98,
        "open": 12863.3,
        "close": 12848.5,
        "low": 12705.29,
        "priceList": [
            {
                "price": 12747.2,
                "ask": 10,
                "bid": 90
            },
            {
                "price": 12798.82,
                "ask": 30,
                "bid": 4
            },
            {
                "price": 12714.04,
                "ask": 22,
                "bid": 58
            },
            {
                "price": 12762.31,
                "ask": 90,
                "bid": 59
            },
            {
                "price": 12826.17,
                "ask": 94,
                "bid": 99
            },
            {
                "price": 12753.83,
                "ask": 79,
                "bid": 76
            },
            {
                "price": 12775.84,
                "ask": 5,
                "bid": 18
            },
            {
                "price": 12752.95,
                "ask": 25,
                "bid": 58
            },
            {
                "price": 12756.35,
                "ask": 2,
                "bid": 44
            },
            {
                "price": 12776.29,
                "ask": 69,
                "bid": 63
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dccd6af03eba0f7f24",
        "high": 12901.36,
        "open": 12890.58,
        "close": 12932.41,
        "low": 12965.71,
        "priceList": [
            {
                "price": 12928.32,
                "ask": 60,
                "bid": 20
            },
            {
                "price": 12926.14,
                "ask": 39,
                "bid": 45
            },
            {
                "price": 12736.23,
                "ask": 88,
                "bid": 71
            },
            {
                "price": 12777.18,
                "ask": 48,
                "bid": 5
            },
            {
                "price": 12867.42,
                "ask": 28,
                "bid": 25
            },
            {
                "price": 12825.88,
                "ask": 55,
                "bid": 20
            },
            {
                "price": 12896.05,
                "ask": 52,
                "bid": 48
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcb2abb5ae0f4500f1",
        "high": 12852.71,
        "open": 12718.45,
        "close": 12823.46,
        "low": 12794.43,
        "priceList": [
            {
                "price": 12720.79,
                "ask": 35,
                "bid": 44
            },
            {
                "price": 12718.07,
                "ask": 97,
                "bid": 53
            },
            {
                "price": 12965.01,
                "ask": 66,
                "bid": 80
            },
            {
                "price": 12897.46,
                "ask": 0,
                "bid": 30
            },
            {
                "price": 12978.07,
                "ask": 82,
                "bid": 75
            },
            {
                "price": 12795.74,
                "ask": 57,
                "bid": 70
            },
            {
                "price": 12865.09,
                "ask": 93,
                "bid": 78
            },
            {
                "price": 12712.58,
                "ask": 54,
                "bid": 64
            },
            {
                "price": 12892.32,
                "ask": 5,
                "bid": 81
            },
            {
                "price": 12927.1,
                "ask": 50,
                "bid": 26
            },
            {
                "price": 12951.98,
                "ask": 42,
                "bid": 11
            },
            {
                "price": 12860.64,
                "ask": 57,
                "bid": 100
            },
            {
                "price": 12996.56,
                "ask": 4,
                "bid": 38
            },
            {
                "price": 12853.59,
                "ask": 45,
                "bid": 23
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcbb207162e233b935",
        "high": 12710.27,
        "open": 12984.09,
        "close": 12839.11,
        "low": 12844.15,
        "priceList": [
            {
                "price": 12717.37,
                "ask": 83,
                "bid": 72
            },
            {
                "price": 12716.33,
                "ask": 73,
                "bid": 83
            },
            {
                "price": 12771.79,
                "ask": 39,
                "bid": 79
            },
            {
                "price": 12802.24,
                "ask": 67,
                "bid": 10
            },
            {
                "price": 12756.53,
                "ask": 66,
                "bid": 14
            },
            {
                "price": 12815.43,
                "ask": 47,
                "bid": 35
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc36678bb42cd1fcf4",
        "high": 12736.38,
        "open": 12965.49,
        "close": 12705.79,
        "low": 12804.83,
        "priceList": [
            {
                "price": 12799.6,
                "ask": 66,
                "bid": 29
            },
            {
                "price": 12756.32,
                "ask": 62,
                "bid": 42
            },
            {
                "price": 12723.91,
                "ask": 78,
                "bid": 24
            },
            {
                "price": 12809.25,
                "ask": 5,
                "bid": 95
            },
            {
                "price": 12874.3,
                "ask": 4,
                "bid": 93
            },
            {
                "price": 12853.97,
                "ask": 79,
                "bid": 14
            },
            {
                "price": 12764.12,
                "ask": 80,
                "bid": 80
            },
            {
                "price": 12843.25,
                "ask": 53,
                "bid": 78
            },
            {
                "price": 12870.2,
                "ask": 2,
                "bid": 58
            },
            {
                "price": 12826.74,
                "ask": 11,
                "bid": 9
            },
            {
                "price": 12969.85,
                "ask": 71,
                "bid": 61
            },
            {
                "price": 12724.73,
                "ask": 80,
                "bid": 89
            },
            {
                "price": 12829.39,
                "ask": 36,
                "bid": 44
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc0cc17cfc06219151",
        "high": 12795.44,
        "open": 12743.52,
        "close": 12926.4,
        "low": 12802.97,
        "priceList": [
            {
                "price": 12721.9,
                "ask": 78,
                "bid": 2
            },
            {
                "price": 12858.28,
                "ask": 18,
                "bid": 13
            },
            {
                "price": 12706.25,
                "ask": 40,
                "bid": 93
            },
            {
                "price": 12927.54,
                "ask": 57,
                "bid": 5
            },
            {
                "price": 12907.05,
                "ask": 39,
                "bid": 17
            },
            {
                "price": 12874.88,
                "ask": 81,
                "bid": 34
            },
            {
                "price": 12745.96,
                "ask": 25,
                "bid": 77
            },
            {
                "price": 12736.05,
                "ask": 46,
                "bid": 53
            },
            {
                "price": 12842.33,
                "ask": 14,
                "bid": 32
            },
            {
                "price": 12784.71,
                "ask": 64,
                "bid": 43
            },
            {
                "price": 12704.77,
                "ask": 43,
                "bid": 64
            },
            {
                "price": 12994.04,
                "ask": 29,
                "bid": 29
            },
            {
                "price": 12872.09,
                "ask": 73,
                "bid": 28
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc585b24844563593",
        "high": 12854.96,
        "open": 12834.72,
        "close": 12903.08,
        "low": 12827.02,
        "priceList": [
            {
                "price": 12804.37,
                "ask": 93,
                "bid": 85
            },
            {
                "price": 12727.51,
                "ask": 72,
                "bid": 8
            },
            {
                "price": 12996.44,
                "ask": 68,
                "bid": 83
            },
            {
                "price": 12982.99,
                "ask": 59,
                "bid": 41
            },
            {
                "price": 12949.8,
                "ask": 76,
                "bid": 59
            },
            {
                "price": 12844.68,
                "ask": 100,
                "bid": 35
            },
            {
                "price": 12852.75,
                "ask": 2,
                "bid": 10
            },
            {
                "price": 12709.51,
                "ask": 0,
                "bid": 74
            },
            {
                "price": 12913.51,
                "ask": 10,
                "bid": 3
            },
            {
                "price": 12881.86,
                "ask": 72,
                "bid": 14
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc5e2855b017bb1a31",
        "high": 12705.55,
        "open": 12886.82,
        "close": 12840.26,
        "low": 12999.08,
        "priceList": [
            {
                "price": 12970.41,
                "ask": 27,
                "bid": 9
            },
            {
                "price": 12761.09,
                "ask": 45,
                "bid": 7
            },
            {
                "price": 12946.32,
                "ask": 83,
                "bid": 85
            },
            {
                "price": 12897.34,
                "ask": 35,
                "bid": 56
            },
            {
                "price": 12835.68,
                "ask": 62,
                "bid": 62
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcf28522af00c83057",
        "high": 12876.81,
        "open": 12748.46,
        "close": 12825.14,
        "low": 12795.24,
        "priceList": [
            {
                "price": 12979.69,
                "ask": 87,
                "bid": 25
            },
            {
                "price": 12799.69,
                "ask": 68,
                "bid": 90
            },
            {
                "price": 12982.96,
                "ask": 26,
                "bid": 76
            },
            {
                "price": 12867.22,
                "ask": 22,
                "bid": 67
            },
            {
                "price": 12878.55,
                "ask": 48,
                "bid": 6
            },
            {
                "price": 12970.03,
                "ask": 36,
                "bid": 18
            },
            {
                "price": 12714.67,
                "ask": 93,
                "bid": 15
            },
            {
                "price": 12825.04,
                "ask": 91,
                "bid": 12
            },
            {
                "price": 12938.36,
                "ask": 78,
                "bid": 61
            },
            {
                "price": 12770.42,
                "ask": 91,
                "bid": 65
            },
            {
                "price": 12862.71,
                "ask": 6,
                "bid": 88
            },
            {
                "price": 12923.85,
                "ask": 53,
                "bid": 95
            },
            {
                "price": 12938.37,
                "ask": 10,
                "bid": 14
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc285419635819d60d",
        "high": 12892.04,
        "open": 12743.81,
        "close": 12959.73,
        "low": 12767.9,
        "priceList": [
            {
                "price": 12906.81,
                "ask": 6,
                "bid": 21
            },
            {
                "price": 12734.58,
                "ask": 14,
                "bid": 14
            },
            {
                "price": 12879.14,
                "ask": 28,
                "bid": 11
            },
            {
                "price": 12761.94,
                "ask": 10,
                "bid": 61
            },
            {
                "price": 12932.67,
                "ask": 23,
                "bid": 77
            },
            {
                "price": 12986.21,
                "ask": 28,
                "bid": 83
            },
            {
                "price": 12857.23,
                "ask": 48,
                "bid": 20
            },
            {
                "price": 12707.99,
                "ask": 10,
                "bid": 32
            },
            {
                "price": 12976.38,
                "ask": 37,
                "bid": 24
            },
            {
                "price": 12818.05,
                "ask": 56,
                "bid": 74
            },
            {
                "price": 12969.62,
                "ask": 10,
                "bid": 30
            },
            {
                "price": 12727.42,
                "ask": 86,
                "bid": 53
            },
            {
                "price": 12885.15,
                "ask": 5,
                "bid": 26
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc838646f68359aee",
        "high": 12882.65,
        "open": 12927.06,
        "close": 12935.37,
        "low": 12831.78,
        "priceList": [
            {
                "price": 12786.29,
                "ask": 92,
                "bid": 11
            },
            {
                "price": 12879.51,
                "ask": 85,
                "bid": 23
            },
            {
                "price": 12975.85,
                "ask": 67,
                "bid": 34
            },
            {
                "price": 12752.38,
                "ask": 91,
                "bid": 90
            },
            {
                "price": 12935.37,
                "ask": 15,
                "bid": 2
            },
            {
                "price": 12710.15,
                "ask": 80,
                "bid": 14
            },
            {
                "price": 12998.82,
                "ask": 74,
                "bid": 100
            },
            {
                "price": 12907.11,
                "ask": 58,
                "bid": 57
            },
            {
                "price": 12986.29,
                "ask": 36,
                "bid": 93
            },
            {
                "price": 12811.93,
                "ask": 41,
                "bid": 2
            },
            {
                "price": 12766.75,
                "ask": 22,
                "bid": 85
            },
            {
                "price": 12810.29,
                "ask": 64,
                "bid": 76
            },
            {
                "price": 12909.75,
                "ask": 5,
                "bid": 18
            },
            {
                "price": 12808.54,
                "ask": 77,
                "bid": 53
            },
            {
                "price": 12917.66,
                "ask": 83,
                "bid": 47
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6ff5e64a721dc884",
        "high": 12807.76,
        "open": 12850.17,
        "close": 12715.56,
        "low": 12999.78,
        "priceList": [
            {
                "price": 12796.93,
                "ask": 90,
                "bid": 75
            },
            {
                "price": 12903.4,
                "ask": 72,
                "bid": 83
            },
            {
                "price": 12715.94,
                "ask": 25,
                "bid": 72
            },
            {
                "price": 12741.87,
                "ask": 51,
                "bid": 78
            },
            {
                "price": 12867.57,
                "ask": 0,
                "bid": 52
            },
            {
                "price": 12742.79,
                "ask": 19,
                "bid": 99
            },
            {
                "price": 12795,
                "ask": 53,
                "bid": 89
            },
            {
                "price": 12873.4,
                "ask": 26,
                "bid": 85
            },
            {
                "price": 12835.49,
                "ask": 58,
                "bid": 59
            },
            {
                "price": 12804.56,
                "ask": 74,
                "bid": 55
            },
            {
                "price": 12803.89,
                "ask": 31,
                "bid": 16
            },
            {
                "price": 12783.73,
                "ask": 81,
                "bid": 19
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc6e711d670a9afbf9",
        "high": 12860.43,
        "open": 12795.6,
        "close": 12779.98,
        "low": 12971.34,
        "priceList": [
            {
                "price": 12703.2,
                "ask": 53,
                "bid": 37
            },
            {
                "price": 12785.54,
                "ask": 16,
                "bid": 80
            },
            {
                "price": 12914.91,
                "ask": 68,
                "bid": 11
            },
            {
                "price": 12989.54,
                "ask": 38,
                "bid": 22
            },
            {
                "price": 12855.65,
                "ask": 68,
                "bid": 73
            },
            {
                "price": 12983.44,
                "ask": 36,
                "bid": 18
            },
            {
                "price": 12873.02,
                "ask": 1,
                "bid": 68
            },
            {
                "price": 12895.22,
                "ask": 33,
                "bid": 30
            },
            {
                "price": 12975.27,
                "ask": 88,
                "bid": 92
            },
            {
                "price": 12793.15,
                "ask": 87,
                "bid": 89
            },
            {
                "price": 12721.16,
                "ask": 3,
                "bid": 97
            },
            {
                "price": 12715.65,
                "ask": 51,
                "bid": 59
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc0142a5642c7a6de4",
        "high": 12983.9,
        "open": 12945.86,
        "close": 12721.89,
        "low": 12794.51,
        "priceList": [
            {
                "price": 12793.98,
                "ask": 68,
                "bid": 20
            },
            {
                "price": 12728.56,
                "ask": 87,
                "bid": 22
            },
            {
                "price": 12841.81,
                "ask": 23,
                "bid": 48
            },
            {
                "price": 12813.28,
                "ask": 9,
                "bid": 2
            },
            {
                "price": 12946.87,
                "ask": 22,
                "bid": 63
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc086c68b3e197495a",
        "high": 12718.59,
        "open": 12971.89,
        "close": 12998.55,
        "low": 12709.26,
        "priceList": [
            {
                "price": 12834.94,
                "ask": 91,
                "bid": 24
            },
            {
                "price": 12715.21,
                "ask": 86,
                "bid": 49
            },
            {
                "price": 12942.28,
                "ask": 44,
                "bid": 0
            },
            {
                "price": 12988.61,
                "ask": 56,
                "bid": 55
            },
            {
                "price": 12816.36,
                "ask": 92,
                "bid": 42
            },
            {
                "price": 12948.36,
                "ask": 67,
                "bid": 30
            },
            {
                "price": 12793.79,
                "ask": 12,
                "bid": 24
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc92395b20fead828e",
        "high": 12842.19,
        "open": 12960.76,
        "close": 12734.86,
        "low": 12885.55,
        "priceList": [
            {
                "price": 12846.52,
                "ask": 28,
                "bid": 41
            },
            {
                "price": 12785.03,
                "ask": 25,
                "bid": 69
            },
            {
                "price": 12742.62,
                "ask": 10,
                "bid": 97
            },
            {
                "price": 12778.75,
                "ask": 38,
                "bid": 69
            },
            {
                "price": 12917.78,
                "ask": 2,
                "bid": 91
            },
            {
                "price": 12807.32,
                "ask": 32,
                "bid": 35
            },
            {
                "price": 12777.74,
                "ask": 65,
                "bid": 49
            },
            {
                "price": 12860.7,
                "ask": 58,
                "bid": 1
            },
            {
                "price": 12908.23,
                "ask": 48,
                "bid": 80
            },
            {
                "price": 12740.65,
                "ask": 15,
                "bid": 54
            },
            {
                "price": 12897.02,
                "ask": 72,
                "bid": 82
            },
            {
                "price": 12953.4,
                "ask": 3,
                "bid": 66
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcf5ac4bed957bb2e3",
        "high": 12746.28,
        "open": 12894.19,
        "close": 12713.88,
        "low": 12979.51,
        "priceList": [
            {
                "price": 12797.69,
                "ask": 99,
                "bid": 80
            },
            {
                "price": 12719.51,
                "ask": 63,
                "bid": 82
            },
            {
                "price": 12935.69,
                "ask": 47,
                "bid": 13
            },
            {
                "price": 12831.99,
                "ask": 26,
                "bid": 5
            },
            {
                "price": 12724.62,
                "ask": 3,
                "bid": 42
            },
            {
                "price": 12727.9,
                "ask": 69,
                "bid": 27
            },
            {
                "price": 12776.13,
                "ask": 56,
                "bid": 4
            },
            {
                "price": 12795.23,
                "ask": 83,
                "bid": 58
            },
            {
                "price": 12757.85,
                "ask": 12,
                "bid": 56
            },
            {
                "price": 12939.22,
                "ask": 98,
                "bid": 73
            },
            {
                "price": 12714.92,
                "ask": 14,
                "bid": 42
            },
            {
                "price": 12902.19,
                "ask": 73,
                "bid": 49
            },
            {
                "price": 12706.34,
                "ask": 81,
                "bid": 95
            },
            {
                "price": 12788.18,
                "ask": 28,
                "bid": 79
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc6030a73852af25a1",
        "high": 12989.84,
        "open": 12901.63,
        "close": 12818.22,
        "low": 12723.89,
        "priceList": [
            {
                "price": 12809,
                "ask": 31,
                "bid": 67
            },
            {
                "price": 12859.95,
                "ask": 24,
                "bid": 30
            },
            {
                "price": 12735.89,
                "ask": 85,
                "bid": 10
            },
            {
                "price": 12910.18,
                "ask": 75,
                "bid": 65
            },
            {
                "price": 12786.25,
                "ask": 91,
                "bid": 58
            },
            {
                "price": 12780.65,
                "ask": 11,
                "bid": 35
            },
            {
                "price": 12891.21,
                "ask": 88,
                "bid": 0
            },
            {
                "price": 12995.6,
                "ask": 90,
                "bid": 63
            },
            {
                "price": 12970.48,
                "ask": 60,
                "bid": 52
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc5b9618148c1f73a8",
        "high": 12866.53,
        "open": 12791.42,
        "close": 12763.61,
        "low": 12983.39,
        "priceList": [
            {
                "price": 12896.07,
                "ask": 54,
                "bid": 96
            },
            {
                "price": 12915.55,
                "ask": 0,
                "bid": 67
            },
            {
                "price": 12833.03,
                "ask": 51,
                "bid": 3
            },
            {
                "price": 12936.78,
                "ask": 31,
                "bid": 20
            },
            {
                "price": 12740.97,
                "ask": 63,
                "bid": 86
            },
            {
                "price": 12966.69,
                "ask": 46,
                "bid": 63
            },
            {
                "price": 12700.95,
                "ask": 43,
                "bid": 86
            },
            {
                "price": 12712.93,
                "ask": 43,
                "bid": 3
            },
            {
                "price": 12946.51,
                "ask": 45,
                "bid": 11
            },
            {
                "price": 12736.41,
                "ask": 88,
                "bid": 58
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc104ba9263fabbd47",
        "high": 12904.8,
        "open": 12876.47,
        "close": 12778.09,
        "low": 12798.25,
        "priceList": [
            {
                "price": 12894.1,
                "ask": 65,
                "bid": 78
            },
            {
                "price": 12781.7,
                "ask": 10,
                "bid": 46
            },
            {
                "price": 12776.9,
                "ask": 79,
                "bid": 58
            },
            {
                "price": 12908.66,
                "ask": 74,
                "bid": 98
            },
            {
                "price": 12703.51,
                "ask": 39,
                "bid": 0
            },
            {
                "price": 12873.71,
                "ask": 20,
                "bid": 94
            },
            {
                "price": 12811.93,
                "ask": 47,
                "bid": 97
            },
            {
                "price": 12905.42,
                "ask": 25,
                "bid": 82
            },
            {
                "price": 12730.75,
                "ask": 27,
                "bid": 23
            },
            {
                "price": 12780.49,
                "ask": 50,
                "bid": 40
            },
            {
                "price": 12977.09,
                "ask": 82,
                "bid": 56
            },
            {
                "price": 12819.57,
                "ask": 25,
                "bid": 83
            },
            {
                "price": 12930.62,
                "ask": 82,
                "bid": 11
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc529a0d728531053f",
        "high": 12889.7,
        "open": 12750.95,
        "close": 12984.42,
        "low": 12865.07,
        "priceList": [
            {
                "price": 12843.94,
                "ask": 23,
                "bid": 97
            },
            {
                "price": 12890.73,
                "ask": 74,
                "bid": 93
            },
            {
                "price": 12774.99,
                "ask": 88,
                "bid": 28
            },
            {
                "price": 12793.38,
                "ask": 55,
                "bid": 58
            },
            {
                "price": 12952.36,
                "ask": 15,
                "bid": 98
            },
            {
                "price": 12826.07,
                "ask": 36,
                "bid": 50
            },
            {
                "price": 12831.72,
                "ask": 93,
                "bid": 63
            },
            {
                "price": 12717.43,
                "ask": 76,
                "bid": 41
            },
            {
                "price": 12752.67,
                "ask": 32,
                "bid": 79
            },
            {
                "price": 12899.6,
                "ask": 67,
                "bid": 89
            },
            {
                "price": 12893.26,
                "ask": 76,
                "bid": 1
            },
            {
                "price": 12912.13,
                "ask": 10,
                "bid": 0
            },
            {
                "price": 12850.14,
                "ask": 34,
                "bid": 42
            },
            {
                "price": 12833.61,
                "ask": 3,
                "bid": 28
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc99d8d156939d7300",
        "high": 12760.91,
        "open": 12718.76,
        "close": 12725.08,
        "low": 12711.41,
        "priceList": [
            {
                "price": 12899.94,
                "ask": 4,
                "bid": 66
            },
            {
                "price": 12854.55,
                "ask": 78,
                "bid": 74
            },
            {
                "price": 12748.88,
                "ask": 28,
                "bid": 51
            },
            {
                "price": 12789.9,
                "ask": 28,
                "bid": 54
            },
            {
                "price": 12803.06,
                "ask": 62,
                "bid": 90
            },
            {
                "price": 12752.59,
                "ask": 51,
                "bid": 46
            },
            {
                "price": 12826.12,
                "ask": 98,
                "bid": 0
            },
            {
                "price": 12711.14,
                "ask": 9,
                "bid": 24
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcba7de65c832a8fde",
        "high": 12930.41,
        "open": 12864.08,
        "close": 12949.68,
        "low": 12751.08,
        "priceList": [
            {
                "price": 12811.59,
                "ask": 33,
                "bid": 87
            },
            {
                "price": 12866.96,
                "ask": 31,
                "bid": 16
            },
            {
                "price": 12888.73,
                "ask": 83,
                "bid": 0
            },
            {
                "price": 12824.53,
                "ask": 70,
                "bid": 55
            },
            {
                "price": 12788.64,
                "ask": 63,
                "bid": 33
            },
            {
                "price": 12991.45,
                "ask": 54,
                "bid": 58
            },
            {
                "price": 12760.08,
                "ask": 59,
                "bid": 69
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc335bc3c32a2e3b33",
        "high": 12907.87,
        "open": 12960.56,
        "close": 12940.07,
        "low": 12702.2,
        "priceList": [
            {
                "price": 12890.32,
                "ask": 4,
                "bid": 88
            },
            {
                "price": 12891.84,
                "ask": 6,
                "bid": 15
            },
            {
                "price": 12786.64,
                "ask": 38,
                "bid": 64
            },
            {
                "price": 12726.32,
                "ask": 78,
                "bid": 99
            },
            {
                "price": 12818.42,
                "ask": 90,
                "bid": 68
            },
            {
                "price": 12827.17,
                "ask": 16,
                "bid": 36
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc80b9d7b496b995d8",
        "high": 12859.08,
        "open": 12982.75,
        "close": 12789.16,
        "low": 12722.43,
        "priceList": [
            {
                "price": 12835.75,
                "ask": 60,
                "bid": 68
            },
            {
                "price": 12919.68,
                "ask": 29,
                "bid": 9
            },
            {
                "price": 12941.29,
                "ask": 57,
                "bid": 31
            },
            {
                "price": 12985.39,
                "ask": 80,
                "bid": 44
            },
            {
                "price": 12864.17,
                "ask": 41,
                "bid": 34
            },
            {
                "price": 12911.83,
                "ask": 91,
                "bid": 7
            },
            {
                "price": 12988.19,
                "ask": 82,
                "bid": 18
            },
            {
                "price": 12909.55,
                "ask": 23,
                "bid": 69
            },
            {
                "price": 12799.72,
                "ask": 74,
                "bid": 83
            },
            {
                "price": 12914.44,
                "ask": 81,
                "bid": 94
            },
            {
                "price": 12702.89,
                "ask": 66,
                "bid": 30
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc7670736f3ffb7ee6",
        "high": 12897.54,
        "open": 12931.4,
        "close": 12732.78,
        "low": 12737.64,
        "priceList": [
            {
                "price": 12707.38,
                "ask": 3,
                "bid": 76
            },
            {
                "price": 12733.6,
                "ask": 3,
                "bid": 42
            },
            {
                "price": 12846.41,
                "ask": 67,
                "bid": 99
            },
            {
                "price": 12761.36,
                "ask": 52,
                "bid": 77
            },
            {
                "price": 12764.56,
                "ask": 48,
                "bid": 24
            },
            {
                "price": 12753.45,
                "ask": 89,
                "bid": 89
            },
            {
                "price": 12890.78,
                "ask": 55,
                "bid": 10
            },
            {
                "price": 12873.01,
                "ask": 29,
                "bid": 87
            },
            {
                "price": 12969.08,
                "ask": 8,
                "bid": 91
            },
            {
                "price": 12820.15,
                "ask": 7,
                "bid": 9
            },
            {
                "price": 12759.87,
                "ask": 41,
                "bid": 99
            },
            {
                "price": 12846.49,
                "ask": 30,
                "bid": 19
            },
            {
                "price": 12916.03,
                "ask": 29,
                "bid": 41
            },
            {
                "price": 12795.12,
                "ask": 41,
                "bid": 27
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcc99915248f9a9569",
        "high": 12889.28,
        "open": 12726.42,
        "close": 12878.71,
        "low": 12771.35,
        "priceList": [
            {
                "price": 12914.97,
                "ask": 18,
                "bid": 37
            },
            {
                "price": 12804.32,
                "ask": 83,
                "bid": 44
            },
            {
                "price": 12864.95,
                "ask": 48,
                "bid": 51
            },
            {
                "price": 12886.02,
                "ask": 71,
                "bid": 60
            },
            {
                "price": 12891.05,
                "ask": 91,
                "bid": 61
            },
            {
                "price": 12857.26,
                "ask": 27,
                "bid": 42
            },
            {
                "price": 12853.64,
                "ask": 95,
                "bid": 49
            },
            {
                "price": 12752.58,
                "ask": 5,
                "bid": 6
            },
            {
                "price": 12861.46,
                "ask": 8,
                "bid": 87
            },
            {
                "price": 12831.71,
                "ask": 80,
                "bid": 34
            },
            {
                "price": 12832.25,
                "ask": 46,
                "bid": 50
            },
            {
                "price": 12896.01,
                "ask": 87,
                "bid": 32
            },
            {
                "price": 12939.49,
                "ask": 1,
                "bid": 78
            },
            {
                "price": 12956.24,
                "ask": 70,
                "bid": 43
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcd7bc6ecd462c70bb",
        "high": 12870.81,
        "open": 12933.19,
        "close": 12784.04,
        "low": 12837.1,
        "priceList": [
            {
                "price": 12965.37,
                "ask": 86,
                "bid": 35
            },
            {
                "price": 12779.32,
                "ask": 69,
                "bid": 90
            },
            {
                "price": 12709.11,
                "ask": 47,
                "bid": 14
            },
            {
                "price": 12785.47,
                "ask": 88,
                "bid": 27
            },
            {
                "price": 12981.52,
                "ask": 76,
                "bid": 44
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc3e5194af4173d9bc",
        "high": 12828.03,
        "open": 12767.4,
        "close": 12841.35,
        "low": 12846.27,
        "priceList": [
            {
                "price": 12834.79,
                "ask": 79,
                "bid": 9
            },
            {
                "price": 12848.74,
                "ask": 37,
                "bid": 19
            },
            {
                "price": 12956.74,
                "ask": 82,
                "bid": 81
            },
            {
                "price": 12974.01,
                "ask": 81,
                "bid": 80
            },
            {
                "price": 12943.09,
                "ask": 24,
                "bid": 56
            },
            {
                "price": 12922.98,
                "ask": 25,
                "bid": 36
            },
            {
                "price": 12867.45,
                "ask": 38,
                "bid": 89
            },
            {
                "price": 12860.04,
                "ask": 57,
                "bid": 55
            },
            {
                "price": 12844.37,
                "ask": 73,
                "bid": 32
            },
            {
                "price": 12742.91,
                "ask": 29,
                "bid": 75
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcf57bfb4134f107a9",
        "high": 12816.1,
        "open": 12885.29,
        "close": 12730.33,
        "low": 12857.49,
        "priceList": [
            {
                "price": 12719.27,
                "ask": 38,
                "bid": 11
            },
            {
                "price": 12768.59,
                "ask": 81,
                "bid": 15
            },
            {
                "price": 12814.7,
                "ask": 17,
                "bid": 30
            },
            {
                "price": 12730.54,
                "ask": 55,
                "bid": 57
            },
            {
                "price": 12847.57,
                "ask": 94,
                "bid": 98
            },
            {
                "price": 12740.54,
                "ask": 79,
                "bid": 44
            },
            {
                "price": 12787.81,
                "ask": 87,
                "bid": 61
            },
            {
                "price": 12889.78,
                "ask": 2,
                "bid": 70
            },
            {
                "price": 12990.44,
                "ask": 2,
                "bid": 34
            },
            {
                "price": 12952.19,
                "ask": 40,
                "bid": 7
            },
            {
                "price": 12920.85,
                "ask": 41,
                "bid": 85
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc3251aebb6a86d374",
        "high": 12910.17,
        "open": 12818.49,
        "close": 12885.95,
        "low": 12975.75,
        "priceList": [
            {
                "price": 12762.22,
                "ask": 30,
                "bid": 23
            },
            {
                "price": 12710.29,
                "ask": 41,
                "bid": 41
            },
            {
                "price": 12917.59,
                "ask": 1,
                "bid": 83
            },
            {
                "price": 12975.65,
                "ask": 72,
                "bid": 90
            },
            {
                "price": 12810.76,
                "ask": 49,
                "bid": 54
            },
            {
                "price": 12921.9,
                "ask": 77,
                "bid": 19
            },
            {
                "price": 12880.82,
                "ask": 76,
                "bid": 41
            },
            {
                "price": 12717.75,
                "ask": 84,
                "bid": 4
            },
            {
                "price": 12962.61,
                "ask": 99,
                "bid": 35
            },
            {
                "price": 12800.67,
                "ask": 43,
                "bid": 21
            },
            {
                "price": 12910.73,
                "ask": 77,
                "bid": 19
            },
            {
                "price": 12854.31,
                "ask": 31,
                "bid": 63
            },
            {
                "price": 12742.04,
                "ask": 2,
                "bid": 21
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc524e2894558e0504",
        "high": 12894.26,
        "open": 12973.89,
        "close": 12914.33,
        "low": 12978.21,
        "priceList": [
            {
                "price": 12871.17,
                "ask": 37,
                "bid": 85
            },
            {
                "price": 12883.36,
                "ask": 61,
                "bid": 68
            },
            {
                "price": 12999.52,
                "ask": 33,
                "bid": 67
            },
            {
                "price": 12807.94,
                "ask": 50,
                "bid": 4
            },
            {
                "price": 12849.98,
                "ask": 83,
                "bid": 14
            },
            {
                "price": 12961.04,
                "ask": 21,
                "bid": 90
            },
            {
                "price": 12904.95,
                "ask": 63,
                "bid": 50
            },
            {
                "price": 12868.6,
                "ask": 89,
                "bid": 100
            },
            {
                "price": 12955.1,
                "ask": 83,
                "bid": 41
            },
            {
                "price": 12945.59,
                "ask": 56,
                "bid": 26
            },
            {
                "price": 12910.35,
                "ask": 13,
                "bid": 19
            },
            {
                "price": 12879.37,
                "ask": 44,
                "bid": 75
            },
            {
                "price": 12707.94,
                "ask": 76,
                "bid": 83
            },
            {
                "price": 12873.53,
                "ask": 92,
                "bid": 17
            },
            {
                "price": 12875.94,
                "ask": 35,
                "bid": 16
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc547c86277e6b7073",
        "high": 12739.27,
        "open": 12747.18,
        "close": 12965.47,
        "low": 12750.96,
        "priceList": [
            {
                "price": 12854.31,
                "ask": 16,
                "bid": 68
            },
            {
                "price": 12938.44,
                "ask": 86,
                "bid": 0
            },
            {
                "price": 12762.88,
                "ask": 44,
                "bid": 83
            },
            {
                "price": 12793.59,
                "ask": 52,
                "bid": 85
            },
            {
                "price": 12728.58,
                "ask": 83,
                "bid": 32
            },
            {
                "price": 12784.57,
                "ask": 84,
                "bid": 22
            },
            {
                "price": 12974.71,
                "ask": 10,
                "bid": 14
            },
            {
                "price": 12728.7,
                "ask": 17,
                "bid": 96
            },
            {
                "price": 12882.75,
                "ask": 41,
                "bid": 56
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc11798eeb671816c5",
        "high": 12872.22,
        "open": 12782.02,
        "close": 12896.91,
        "low": 12943.27,
        "priceList": [
            {
                "price": 12869.56,
                "ask": 69,
                "bid": 0
            },
            {
                "price": 12830.3,
                "ask": 31,
                "bid": 10
            },
            {
                "price": 12990.38,
                "ask": 14,
                "bid": 75
            },
            {
                "price": 12826.14,
                "ask": 60,
                "bid": 81
            },
            {
                "price": 12775.26,
                "ask": 88,
                "bid": 85
            },
            {
                "price": 12866.04,
                "ask": 38,
                "bid": 38
            },
            {
                "price": 12903.49,
                "ask": 40,
                "bid": 65
            },
            {
                "price": 12935.23,
                "ask": 71,
                "bid": 9
            },
            {
                "price": 12736.22,
                "ask": 75,
                "bid": 61
            },
            {
                "price": 12833.9,
                "ask": 64,
                "bid": 13
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc678840c6e7aed91e",
        "high": 12710.12,
        "open": 12700.16,
        "close": 12765.82,
        "low": 12735.25,
        "priceList": [
            {
                "price": 12881.35,
                "ask": 13,
                "bid": 74
            },
            {
                "price": 12888.82,
                "ask": 45,
                "bid": 50
            },
            {
                "price": 12947.25,
                "ask": 37,
                "bid": 75
            },
            {
                "price": 12954.77,
                "ask": 29,
                "bid": 54
            },
            {
                "price": 12927.83,
                "ask": 57,
                "bid": 100
            },
            {
                "price": 12895.48,
                "ask": 14,
                "bid": 85
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc9a031571f0ec139d",
        "high": 12937.77,
        "open": 12974,
        "close": 12861.34,
        "low": 12983.14,
        "priceList": [
            {
                "price": 12880.1,
                "ask": 77,
                "bid": 98
            },
            {
                "price": 12889.15,
                "ask": 1,
                "bid": 4
            },
            {
                "price": 12986.69,
                "ask": 92,
                "bid": 23
            },
            {
                "price": 12758.55,
                "ask": 26,
                "bid": 47
            },
            {
                "price": 12717.15,
                "ask": 32,
                "bid": 32
            },
            {
                "price": 12950.31,
                "ask": 52,
                "bid": 56
            },
            {
                "price": 12805.34,
                "ask": 91,
                "bid": 71
            },
            {
                "price": 12814.08,
                "ask": 95,
                "bid": 57
            },
            {
                "price": 12700.32,
                "ask": 78,
                "bid": 48
            },
            {
                "price": 12979.29,
                "ask": 1,
                "bid": 93
            },
            {
                "price": 12752.23,
                "ask": 60,
                "bid": 47
            },
            {
                "price": 12960.37,
                "ask": 59,
                "bid": 52
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc6913788494363a9d",
        "high": 12879.09,
        "open": 12857.15,
        "close": 12930.41,
        "low": 12762.15,
        "priceList": [
            {
                "price": 12852.55,
                "ask": 63,
                "bid": 94
            },
            {
                "price": 12839.82,
                "ask": 23,
                "bid": 97
            },
            {
                "price": 12950.05,
                "ask": 94,
                "bid": 95
            },
            {
                "price": 12771.82,
                "ask": 5,
                "bid": 89
            },
            {
                "price": 12897.81,
                "ask": 64,
                "bid": 56
            },
            {
                "price": 12887.61,
                "ask": 36,
                "bid": 73
            },
            {
                "price": 12907,
                "ask": 12,
                "bid": 94
            },
            {
                "price": 12970.86,
                "ask": 87,
                "bid": 30
            },
            {
                "price": 12948.51,
                "ask": 57,
                "bid": 2
            },
            {
                "price": 12891.79,
                "ask": 15,
                "bid": 16
            },
            {
                "price": 12881.35,
                "ask": 25,
                "bid": 34
            },
            {
                "price": 12940.85,
                "ask": 22,
                "bid": 22
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc3f9ed34f6afedee0",
        "high": 12779.03,
        "open": 12907.56,
        "close": 12881.52,
        "low": 12788.19,
        "priceList": [
            {
                "price": 12733.72,
                "ask": 96,
                "bid": 93
            },
            {
                "price": 12775.42,
                "ask": 18,
                "bid": 16
            },
            {
                "price": 12934.79,
                "ask": 26,
                "bid": 53
            },
            {
                "price": 12851.36,
                "ask": 64,
                "bid": 80
            },
            {
                "price": 12952.98,
                "ask": 35,
                "bid": 48
            },
            {
                "price": 12962.94,
                "ask": 97,
                "bid": 76
            },
            {
                "price": 12844.84,
                "ask": 50,
                "bid": 36
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcde73472bd8f633be",
        "high": 12834.09,
        "open": 12959.31,
        "close": 12722.86,
        "low": 12805.33,
        "priceList": [
            {
                "price": 12837.65,
                "ask": 59,
                "bid": 21
            },
            {
                "price": 12916.82,
                "ask": 31,
                "bid": 58
            },
            {
                "price": 12725.28,
                "ask": 1,
                "bid": 46
            },
            {
                "price": 12961.79,
                "ask": 33,
                "bid": 13
            },
            {
                "price": 12898.23,
                "ask": 39,
                "bid": 96
            },
            {
                "price": 12767.37,
                "ask": 54,
                "bid": 63
            },
            {
                "price": 12957.55,
                "ask": 50,
                "bid": 26
            },
            {
                "price": 12931.08,
                "ask": 53,
                "bid": 52
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc31663b93203af75e",
        "high": 12988.14,
        "open": 12797.45,
        "close": 12818.16,
        "low": 12967.63,
        "priceList": [
            {
                "price": 12760.17,
                "ask": 53,
                "bid": 29
            },
            {
                "price": 12792.25,
                "ask": 13,
                "bid": 30
            },
            {
                "price": 12834.93,
                "ask": 84,
                "bid": 51
            },
            {
                "price": 12790.91,
                "ask": 52,
                "bid": 21
            },
            {
                "price": 12998,
                "ask": 91,
                "bid": 10
            },
            {
                "price": 12913.39,
                "ask": 91,
                "bid": 31
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc5728363a1841b3df",
        "high": 12710.1,
        "open": 12914.69,
        "close": 12745.1,
        "low": 12924.63,
        "priceList": [
            {
                "price": 12989.75,
                "ask": 66,
                "bid": 94
            },
            {
                "price": 12850.09,
                "ask": 96,
                "bid": 86
            },
            {
                "price": 12878.83,
                "ask": 41,
                "bid": 0
            },
            {
                "price": 12741.99,
                "ask": 47,
                "bid": 26
            },
            {
                "price": 12871.59,
                "ask": 68,
                "bid": 41
            },
            {
                "price": 12806.54,
                "ask": 44,
                "bid": 82
            },
            {
                "price": 12766.35,
                "ask": 25,
                "bid": 96
            },
            {
                "price": 12803.62,
                "ask": 40,
                "bid": 22
            },
            {
                "price": 12742.77,
                "ask": 26,
                "bid": 1
            },
            {
                "price": 12896.27,
                "ask": 80,
                "bid": 10
            },
            {
                "price": 12723.89,
                "ask": 61,
                "bid": 3
            },
            {
                "price": 12922.11,
                "ask": 57,
                "bid": 29
            },
            {
                "price": 12969.3,
                "ask": 13,
                "bid": 2
            },
            {
                "price": 12786.56,
                "ask": 60,
                "bid": 57
            },
            {
                "price": 12860.86,
                "ask": 82,
                "bid": 59
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc151a27cc61d73a84",
        "high": 12899.98,
        "open": 12886.46,
        "close": 12987.07,
        "low": 12726.43,
        "priceList": [
            {
                "price": 12940.89,
                "ask": 96,
                "bid": 29
            },
            {
                "price": 12792.23,
                "ask": 62,
                "bid": 51
            },
            {
                "price": 12941.59,
                "ask": 58,
                "bid": 88
            },
            {
                "price": 12891.82,
                "ask": 98,
                "bid": 78
            },
            {
                "price": 12864,
                "ask": 65,
                "bid": 67
            },
            {
                "price": 12737.26,
                "ask": 82,
                "bid": 96
            },
            {
                "price": 12832.18,
                "ask": 89,
                "bid": 11
            },
            {
                "price": 12712.12,
                "ask": 89,
                "bid": 65
            },
            {
                "price": 12864.48,
                "ask": 45,
                "bid": 20
            },
            {
                "price": 12830.88,
                "ask": 75,
                "bid": 76
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcc6fb85331478ad4d",
        "high": 12723.39,
        "open": 12948.28,
        "close": 12776.63,
        "low": 12934.05,
        "priceList": [
            {
                "price": 12823.63,
                "ask": 28,
                "bid": 15
            },
            {
                "price": 12887.93,
                "ask": 60,
                "bid": 94
            },
            {
                "price": 12830.8,
                "ask": 69,
                "bid": 50
            },
            {
                "price": 12997.78,
                "ask": 85,
                "bid": 50
            },
            {
                "price": 12786.86,
                "ask": 84,
                "bid": 60
            },
            {
                "price": 12725.77,
                "ask": 76,
                "bid": 75
            },
            {
                "price": 12970.22,
                "ask": 74,
                "bid": 11
            },
            {
                "price": 12718.2,
                "ask": 27,
                "bid": 49
            },
            {
                "price": 12737.42,
                "ask": 40,
                "bid": 33
            },
            {
                "price": 12914.47,
                "ask": 10,
                "bid": 1
            },
            {
                "price": 12804.64,
                "ask": 46,
                "bid": 12
            },
            {
                "price": 12989.03,
                "ask": 42,
                "bid": 51
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc92d83fc3fc61b39c",
        "high": 12945.52,
        "open": 12997.53,
        "close": 12847.08,
        "low": 12762.45,
        "priceList": [
            {
                "price": 12719.27,
                "ask": 17,
                "bid": 50
            },
            {
                "price": 12935.92,
                "ask": 100,
                "bid": 38
            },
            {
                "price": 12982.9,
                "ask": 27,
                "bid": 60
            },
            {
                "price": 12775.47,
                "ask": 67,
                "bid": 85
            },
            {
                "price": 12911.34,
                "ask": 93,
                "bid": 97
            },
            {
                "price": 12937.79,
                "ask": 2,
                "bid": 71
            },
            {
                "price": 12751.04,
                "ask": 39,
                "bid": 21
            },
            {
                "price": 12723.14,
                "ask": 27,
                "bid": 36
            },
            {
                "price": 12771.58,
                "ask": 3,
                "bid": 31
            },
            {
                "price": 12740.98,
                "ask": 23,
                "bid": 61
            },
            {
                "price": 12873.68,
                "ask": 64,
                "bid": 65
            },
            {
                "price": 12761.13,
                "ask": 92,
                "bid": 6
            },
            {
                "price": 12752.66,
                "ask": 39,
                "bid": 29
            },
            {
                "price": 12879.1,
                "ask": 100,
                "bid": 72
            },
            {
                "price": 12763.41,
                "ask": 48,
                "bid": 94
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc2c87da53cafa7642",
        "high": 12785.05,
        "open": 12835.08,
        "close": 12739.23,
        "low": 12780.69,
        "priceList": [
            {
                "price": 12911.63,
                "ask": 27,
                "bid": 38
            },
            {
                "price": 12900.56,
                "ask": 66,
                "bid": 13
            },
            {
                "price": 12784.35,
                "ask": 4,
                "bid": 18
            },
            {
                "price": 12955.38,
                "ask": 79,
                "bid": 23
            },
            {
                "price": 12798.74,
                "ask": 2,
                "bid": 89
            },
            {
                "price": 12866.11,
                "ask": 75,
                "bid": 58
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc8db87090abe7877f",
        "high": 12935.22,
        "open": 12808.84,
        "close": 12791.23,
        "low": 12747.29,
        "priceList": [
            {
                "price": 12783.55,
                "ask": 40,
                "bid": 32
            },
            {
                "price": 12867.52,
                "ask": 16,
                "bid": 1
            },
            {
                "price": 12715.31,
                "ask": 76,
                "bid": 97
            },
            {
                "price": 12849.54,
                "ask": 48,
                "bid": 26
            },
            {
                "price": 12852.15,
                "ask": 57,
                "bid": 91
            },
            {
                "price": 12859.64,
                "ask": 74,
                "bid": 85
            },
            {
                "price": 12865.56,
                "ask": 85,
                "bid": 97
            },
            {
                "price": 12912.3,
                "ask": 83,
                "bid": 35
            },
            {
                "price": 12940.06,
                "ask": 35,
                "bid": 26
            },
            {
                "price": 12961.68,
                "ask": 44,
                "bid": 38
            },
            {
                "price": 12829.25,
                "ask": 63,
                "bid": 59
            },
            {
                "price": 12863.08,
                "ask": 19,
                "bid": 34
            },
            {
                "price": 12812.95,
                "ask": 61,
                "bid": 30
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc93b2e1ae56d0d4f2",
        "high": 12783.07,
        "open": 12974.27,
        "close": 12795.49,
        "low": 12772.19,
        "priceList": [
            {
                "price": 12823.93,
                "ask": 78,
                "bid": 94
            },
            {
                "price": 12925.89,
                "ask": 21,
                "bid": 46
            },
            {
                "price": 12857.77,
                "ask": 6,
                "bid": 79
            },
            {
                "price": 12856.12,
                "ask": 97,
                "bid": 20
            },
            {
                "price": 12948.43,
                "ask": 61,
                "bid": 10
            },
            {
                "price": 12808.12,
                "ask": 34,
                "bid": 8
            },
            {
                "price": 12837.73,
                "ask": 92,
                "bid": 60
            },
            {
                "price": 12846.72,
                "ask": 25,
                "bid": 29
            },
            {
                "price": 12860.42,
                "ask": 36,
                "bid": 61
            },
            {
                "price": 12984.81,
                "ask": 29,
                "bid": 43
            },
            {
                "price": 12910.46,
                "ask": 53,
                "bid": 63
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dccb324d82e3b3d0df",
        "high": 12975.41,
        "open": 12972.91,
        "close": 12891.68,
        "low": 12986.22,
        "priceList": [
            {
                "price": 12896.49,
                "ask": 20,
                "bid": 73
            },
            {
                "price": 12819.38,
                "ask": 70,
                "bid": 33
            },
            {
                "price": 12920.69,
                "ask": 58,
                "bid": 18
            },
            {
                "price": 12748.52,
                "ask": 15,
                "bid": 56
            },
            {
                "price": 12881.89,
                "ask": 4,
                "bid": 64
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc659b2a8ed4cdf4af",
        "high": 12821.57,
        "open": 12700.72,
        "close": 12923.89,
        "low": 12957.53,
        "priceList": [
            {
                "price": 12922.37,
                "ask": 95,
                "bid": 64
            },
            {
                "price": 12757.06,
                "ask": 19,
                "bid": 27
            },
            {
                "price": 12846.06,
                "ask": 11,
                "bid": 73
            },
            {
                "price": 12903.2,
                "ask": 74,
                "bid": 93
            },
            {
                "price": 12719.68,
                "ask": 89,
                "bid": 90
            },
            {
                "price": 12710.6,
                "ask": 58,
                "bid": 0
            },
            {
                "price": 12748.86,
                "ask": 65,
                "bid": 30
            },
            {
                "price": 12996.96,
                "ask": 99,
                "bid": 24
            },
            {
                "price": 12974.28,
                "ask": 93,
                "bid": 47
            },
            {
                "price": 12930.55,
                "ask": 70,
                "bid": 80
            },
            {
                "price": 12951.62,
                "ask": 38,
                "bid": 50
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc5bc1ce7938cd4aa2",
        "high": 12702.85,
        "open": 12840.81,
        "close": 12987.31,
        "low": 12948.23,
        "priceList": [
            {
                "price": 12999.51,
                "ask": 7,
                "bid": 7
            },
            {
                "price": 12990.51,
                "ask": 68,
                "bid": 61
            },
            {
                "price": 12755.04,
                "ask": 31,
                "bid": 82
            },
            {
                "price": 12972.47,
                "ask": 12,
                "bid": 95
            },
            {
                "price": 12834.44,
                "ask": 62,
                "bid": 47
            },
            {
                "price": 12852.5,
                "ask": 59,
                "bid": 4
            },
            {
                "price": 12748.02,
                "ask": 67,
                "bid": 87
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc784d0769844cedd9",
        "high": 12940.11,
        "open": 12907.04,
        "close": 12760.33,
        "low": 12989.66,
        "priceList": [
            {
                "price": 12954.29,
                "ask": 46,
                "bid": 75
            },
            {
                "price": 12703.11,
                "ask": 35,
                "bid": 74
            },
            {
                "price": 12932.3,
                "ask": 74,
                "bid": 53
            },
            {
                "price": 12894.89,
                "ask": 82,
                "bid": 90
            },
            {
                "price": 12859.81,
                "ask": 90,
                "bid": 25
            },
            {
                "price": 12964.99,
                "ask": 30,
                "bid": 34
            },
            {
                "price": 12935.09,
                "ask": 66,
                "bid": 99
            },
            {
                "price": 12883.46,
                "ask": 68,
                "bid": 38
            },
            {
                "price": 12933.37,
                "ask": 88,
                "bid": 4
            },
            {
                "price": 12973.49,
                "ask": 12,
                "bid": 37
            },
            {
                "price": 12934.93,
                "ask": 100,
                "bid": 19
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc65d434f6a8190c15",
        "high": 12797.75,
        "open": 12779.99,
        "close": 12757.26,
        "low": 12790.02,
        "priceList": [
            {
                "price": 12818.79,
                "ask": 36,
                "bid": 28
            },
            {
                "price": 12936.02,
                "ask": 67,
                "bid": 26
            },
            {
                "price": 12707.47,
                "ask": 7,
                "bid": 52
            },
            {
                "price": 12778.1,
                "ask": 79,
                "bid": 90
            },
            {
                "price": 12851.93,
                "ask": 84,
                "bid": 58
            },
            {
                "price": 12779.23,
                "ask": 32,
                "bid": 49
            },
            {
                "price": 12828.75,
                "ask": 26,
                "bid": 66
            },
            {
                "price": 12826.11,
                "ask": 54,
                "bid": 10
            },
            {
                "price": 12916.65,
                "ask": 88,
                "bid": 8
            },
            {
                "price": 12923.12,
                "ask": 54,
                "bid": 22
            },
            {
                "price": 12874.4,
                "ask": 77,
                "bid": 66
            },
            {
                "price": 12855.52,
                "ask": 66,
                "bid": 33
            },
            {
                "price": 12779.23,
                "ask": 45,
                "bid": 85
            },
            {
                "price": 12805.11,
                "ask": 42,
                "bid": 96
            },
            {
                "price": 12734.47,
                "ask": 15,
                "bid": 42
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca957cc6c4af40281",
        "high": 12972.92,
        "open": 12804,
        "close": 12974.89,
        "low": 12743.01,
        "priceList": [
            {
                "price": 12763.88,
                "ask": 49,
                "bid": 61
            },
            {
                "price": 12892.45,
                "ask": 6,
                "bid": 35
            },
            {
                "price": 12986.53,
                "ask": 13,
                "bid": 100
            },
            {
                "price": 12759.14,
                "ask": 49,
                "bid": 75
            },
            {
                "price": 12781.96,
                "ask": 13,
                "bid": 26
            },
            {
                "price": 12874.35,
                "ask": 0,
                "bid": 27
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcd62fbca09d912cef",
        "high": 12792.32,
        "open": 12765.93,
        "close": 12974.26,
        "low": 12735.04,
        "priceList": [
            {
                "price": 12863.06,
                "ask": 15,
                "bid": 19
            },
            {
                "price": 12979.05,
                "ask": 11,
                "bid": 9
            },
            {
                "price": 12998.87,
                "ask": 48,
                "bid": 79
            },
            {
                "price": 12785.84,
                "ask": 10,
                "bid": 71
            },
            {
                "price": 12809.58,
                "ask": 100,
                "bid": 40
            },
            {
                "price": 12883.33,
                "ask": 83,
                "bid": 24
            },
            {
                "price": 12831.7,
                "ask": 72,
                "bid": 67
            },
            {
                "price": 12985.95,
                "ask": 46,
                "bid": 76
            },
            {
                "price": 12816.56,
                "ask": 7,
                "bid": 63
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcb34b0c1427c3e6c3",
        "high": 12811.26,
        "open": 12864.21,
        "close": 12708.46,
        "low": 12959.04,
        "priceList": [
            {
                "price": 12906.99,
                "ask": 83,
                "bid": 16
            },
            {
                "price": 12857.32,
                "ask": 44,
                "bid": 33
            },
            {
                "price": 12872.92,
                "ask": 54,
                "bid": 27
            },
            {
                "price": 12763.11,
                "ask": 65,
                "bid": 98
            },
            {
                "price": 12851.69,
                "ask": 7,
                "bid": 54
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc951fe9a4329e4267",
        "high": 12824.81,
        "open": 12751.83,
        "close": 12969.88,
        "low": 12716.04,
        "priceList": [
            {
                "price": 12996.5,
                "ask": 53,
                "bid": 59
            },
            {
                "price": 12724.88,
                "ask": 76,
                "bid": 88
            },
            {
                "price": 12973,
                "ask": 57,
                "bid": 55
            },
            {
                "price": 12843.07,
                "ask": 44,
                "bid": 77
            },
            {
                "price": 12706.99,
                "ask": 80,
                "bid": 33
            },
            {
                "price": 12946.68,
                "ask": 73,
                "bid": 61
            },
            {
                "price": 12798.97,
                "ask": 94,
                "bid": 42
            },
            {
                "price": 12934.65,
                "ask": 84,
                "bid": 51
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcdd72b32d58b1937d",
        "high": 12711.85,
        "open": 12855.89,
        "close": 12979.76,
        "low": 12916.38,
        "priceList": [
            {
                "price": 12896.34,
                "ask": 97,
                "bid": 41
            },
            {
                "price": 12857.9,
                "ask": 31,
                "bid": 59
            },
            {
                "price": 12867.57,
                "ask": 87,
                "bid": 31
            },
            {
                "price": 12733.52,
                "ask": 74,
                "bid": 62
            },
            {
                "price": 12901.13,
                "ask": 14,
                "bid": 64
            },
            {
                "price": 12751.81,
                "ask": 67,
                "bid": 15
            },
            {
                "price": 12754.08,
                "ask": 47,
                "bid": 34
            },
            {
                "price": 12948.63,
                "ask": 31,
                "bid": 90
            },
            {
                "price": 12703.81,
                "ask": 80,
                "bid": 43
            },
            {
                "price": 12904.87,
                "ask": 60,
                "bid": 35
            },
            {
                "price": 12735.31,
                "ask": 30,
                "bid": 93
            },
            {
                "price": 12968.26,
                "ask": 52,
                "bid": 85
            },
            {
                "price": 12808.06,
                "ask": 0,
                "bid": 81
            },
            {
                "price": 12788.86,
                "ask": 46,
                "bid": 68
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc422c1b06d819bc04",
        "high": 12893.4,
        "open": 12925.5,
        "close": 12944.37,
        "low": 12901.64,
        "priceList": [
            {
                "price": 12957.11,
                "ask": 95,
                "bid": 21
            },
            {
                "price": 12806.62,
                "ask": 0,
                "bid": 2
            },
            {
                "price": 12719.04,
                "ask": 41,
                "bid": 42
            },
            {
                "price": 12731.92,
                "ask": 37,
                "bid": 37
            },
            {
                "price": 12872.17,
                "ask": 35,
                "bid": 3
            },
            {
                "price": 12748.03,
                "ask": 61,
                "bid": 93
            },
            {
                "price": 12990.49,
                "ask": 2,
                "bid": 90
            },
            {
                "price": 12882.69,
                "ask": 85,
                "bid": 29
            },
            {
                "price": 12986.59,
                "ask": 22,
                "bid": 62
            },
            {
                "price": 12783,
                "ask": 39,
                "bid": 66
            },
            {
                "price": 12761.04,
                "ask": 57,
                "bid": 56
            },
            {
                "price": 12876.16,
                "ask": 72,
                "bid": 55
            },
            {
                "price": 12851.01,
                "ask": 11,
                "bid": 4
            },
            {
                "price": 12817.67,
                "ask": 91,
                "bid": 63
            },
            {
                "price": 12711.29,
                "ask": 59,
                "bid": 34
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dceb1a80503270aa5a",
        "high": 12734.32,
        "open": 12909.34,
        "close": 12992.97,
        "low": 12875.58,
        "priceList": [
            {
                "price": 12782.56,
                "ask": 88,
                "bid": 64
            },
            {
                "price": 12980.16,
                "ask": 30,
                "bid": 45
            },
            {
                "price": 12859.21,
                "ask": 71,
                "bid": 8
            },
            {
                "price": 12885.13,
                "ask": 1,
                "bid": 73
            },
            {
                "price": 12745.59,
                "ask": 60,
                "bid": 88
            },
            {
                "price": 12899.59,
                "ask": 17,
                "bid": 38
            },
            {
                "price": 12824.29,
                "ask": 54,
                "bid": 37
            },
            {
                "price": 12885.29,
                "ask": 97,
                "bid": 63
            },
            {
                "price": 12752.52,
                "ask": 23,
                "bid": 50
            },
            {
                "price": 12938.32,
                "ask": 38,
                "bid": 79
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc91f8c14619949f45",
        "high": 12871.56,
        "open": 12834.65,
        "close": 12902.87,
        "low": 12793.43,
        "priceList": [
            {
                "price": 12905.59,
                "ask": 50,
                "bid": 71
            },
            {
                "price": 12896.24,
                "ask": 39,
                "bid": 2
            },
            {
                "price": 12771.85,
                "ask": 0,
                "bid": 36
            },
            {
                "price": 12798.07,
                "ask": 41,
                "bid": 1
            },
            {
                "price": 12772.45,
                "ask": 64,
                "bid": 41
            },
            {
                "price": 12972.14,
                "ask": 96,
                "bid": 18
            },
            {
                "price": 12857.15,
                "ask": 69,
                "bid": 2
            },
            {
                "price": 12755.01,
                "ask": 80,
                "bid": 35
            },
            {
                "price": 12955.61,
                "ask": 53,
                "bid": 74
            },
            {
                "price": 12787.93,
                "ask": 93,
                "bid": 25
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dce21a6125c05f2fb4",
        "high": 12988.4,
        "open": 12976.25,
        "close": 12715.06,
        "low": 12881.45,
        "priceList": [
            {
                "price": 12752.53,
                "ask": 25,
                "bid": 96
            },
            {
                "price": 12934.16,
                "ask": 33,
                "bid": 100
            },
            {
                "price": 12972.8,
                "ask": 93,
                "bid": 29
            },
            {
                "price": 12972.8,
                "ask": 58,
                "bid": 83
            },
            {
                "price": 12882.95,
                "ask": 79,
                "bid": 32
            },
            {
                "price": 12934.63,
                "ask": 51,
                "bid": 1
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc3807d514286666fc",
        "high": 12942.43,
        "open": 12941.19,
        "close": 12790.78,
        "low": 12754.23,
        "priceList": [
            {
                "price": 12797.11,
                "ask": 83,
                "bid": 15
            },
            {
                "price": 12813.57,
                "ask": 36,
                "bid": 20
            },
            {
                "price": 12854.38,
                "ask": 41,
                "bid": 20
            },
            {
                "price": 12845.35,
                "ask": 46,
                "bid": 74
            },
            {
                "price": 12908.66,
                "ask": 92,
                "bid": 46
            },
            {
                "price": 12733.56,
                "ask": 56,
                "bid": 74
            },
            {
                "price": 12836.98,
                "ask": 29,
                "bid": 85
            },
            {
                "price": 12827.06,
                "ask": 96,
                "bid": 15
            },
            {
                "price": 12919.76,
                "ask": 77,
                "bid": 60
            },
            {
                "price": 12972.64,
                "ask": 59,
                "bid": 91
            },
            {
                "price": 12788.14,
                "ask": 26,
                "bid": 26
            },
            {
                "price": 12960.15,
                "ask": 69,
                "bid": 52
            },
            {
                "price": 12863.53,
                "ask": 62,
                "bid": 46
            },
            {
                "price": 12875.4,
                "ask": 80,
                "bid": 45
            },
            {
                "price": 12710.87,
                "ask": 29,
                "bid": 47
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc92816ffdbdeea528",
        "high": 12973.26,
        "open": 12901.6,
        "close": 12869.58,
        "low": 12866.75,
        "priceList": [
            {
                "price": 12736.28,
                "ask": 12,
                "bid": 39
            },
            {
                "price": 12985.27,
                "ask": 79,
                "bid": 100
            },
            {
                "price": 12888.52,
                "ask": 7,
                "bid": 56
            },
            {
                "price": 12981.07,
                "ask": 94,
                "bid": 54
            },
            {
                "price": 12728.87,
                "ask": 50,
                "bid": 63
            },
            {
                "price": 12902.81,
                "ask": 27,
                "bid": 98
            },
            {
                "price": 12976.02,
                "ask": 21,
                "bid": 94
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcbec5221bba430843",
        "high": 12980.97,
        "open": 12892.78,
        "close": 12998.15,
        "low": 12882.69,
        "priceList": [
            {
                "price": 12781.1,
                "ask": 20,
                "bid": 87
            },
            {
                "price": 12987.96,
                "ask": 17,
                "bid": 41
            },
            {
                "price": 12729.37,
                "ask": 36,
                "bid": 65
            },
            {
                "price": 12894.15,
                "ask": 61,
                "bid": 81
            },
            {
                "price": 12965.49,
                "ask": 81,
                "bid": 6
            },
            {
                "price": 12881.27,
                "ask": 50,
                "bid": 58
            },
            {
                "price": 12938.58,
                "ask": 76,
                "bid": 79
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc8c0878d5fa9a1ba3",
        "high": 12800.36,
        "open": 12870.1,
        "close": 12789.21,
        "low": 12704.16,
        "priceList": [
            {
                "price": 12833.01,
                "ask": 11,
                "bid": 94
            },
            {
                "price": 12919.38,
                "ask": 95,
                "bid": 47
            },
            {
                "price": 12839.49,
                "ask": 33,
                "bid": 60
            },
            {
                "price": 12990.01,
                "ask": 13,
                "bid": 13
            },
            {
                "price": 12774.17,
                "ask": 28,
                "bid": 44
            },
            {
                "price": 12857.18,
                "ask": 95,
                "bid": 52
            },
            {
                "price": 12820.15,
                "ask": 60,
                "bid": 17
            },
            {
                "price": 12946.52,
                "ask": 99,
                "bid": 69
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc383328f5bf470eb1",
        "high": 12969.24,
        "open": 12772.36,
        "close": 12826.07,
        "low": 12784.6,
        "priceList": [
            {
                "price": 12794.22,
                "ask": 52,
                "bid": 81
            },
            {
                "price": 12921.24,
                "ask": 28,
                "bid": 22
            },
            {
                "price": 12892.12,
                "ask": 61,
                "bid": 36
            },
            {
                "price": 12798.51,
                "ask": 10,
                "bid": 45
            },
            {
                "price": 12959.57,
                "ask": 43,
                "bid": 36
            },
            {
                "price": 12785.42,
                "ask": 98,
                "bid": 66
            },
            {
                "price": 12768.96,
                "ask": 100,
                "bid": 15
            },
            {
                "price": 12986.49,
                "ask": 71,
                "bid": 69
            },
            {
                "price": 12853.88,
                "ask": 37,
                "bid": 22
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc9d2bb702b2edae9d",
        "high": 12740.69,
        "open": 12746.28,
        "close": 12947.48,
        "low": 12957.05,
        "priceList": [
            {
                "price": 12848.36,
                "ask": 0,
                "bid": 20
            },
            {
                "price": 12760.22,
                "ask": 42,
                "bid": 61
            },
            {
                "price": 12933.25,
                "ask": 76,
                "bid": 86
            },
            {
                "price": 12919.35,
                "ask": 20,
                "bid": 84
            },
            {
                "price": 12936.29,
                "ask": 70,
                "bid": 8
            },
            {
                "price": 12834.27,
                "ask": 15,
                "bid": 64
            },
            {
                "price": 12848.24,
                "ask": 42,
                "bid": 28
            },
            {
                "price": 12999.96,
                "ask": 87,
                "bid": 16
            },
            {
                "price": 12919.8,
                "ask": 86,
                "bid": 71
            },
            {
                "price": 12910.01,
                "ask": 55,
                "bid": 94
            },
            {
                "price": 12719.66,
                "ask": 0,
                "bid": 38
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcf9b691cf283dc22f",
        "high": 12888.15,
        "open": 12886.03,
        "close": 12957.17,
        "low": 12859.88,
        "priceList": [
            {
                "price": 12918.97,
                "ask": 38,
                "bid": 38
            },
            {
                "price": 12739.8,
                "ask": 66,
                "bid": 49
            },
            {
                "price": 12928.43,
                "ask": 3,
                "bid": 19
            },
            {
                "price": 12719.87,
                "ask": 65,
                "bid": 98
            },
            {
                "price": 12731.56,
                "ask": 83,
                "bid": 16
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc9ab1df734904ed49",
        "high": 12828.23,
        "open": 12848.49,
        "close": 12796.15,
        "low": 12995.67,
        "priceList": [
            {
                "price": 12897.43,
                "ask": 7,
                "bid": 22
            },
            {
                "price": 12771.31,
                "ask": 18,
                "bid": 92
            },
            {
                "price": 12865.79,
                "ask": 11,
                "bid": 85
            },
            {
                "price": 12911.99,
                "ask": 19,
                "bid": 74
            },
            {
                "price": 12896.88,
                "ask": 54,
                "bid": 33
            },
            {
                "price": 12834.16,
                "ask": 35,
                "bid": 37
            },
            {
                "price": 12849.65,
                "ask": 50,
                "bid": 60
            },
            {
                "price": 12832.93,
                "ask": 10,
                "bid": 5
            },
            {
                "price": 12721.99,
                "ask": 97,
                "bid": 45
            },
            {
                "price": 12975.59,
                "ask": 64,
                "bid": 55
            },
            {
                "price": 12832.46,
                "ask": 92,
                "bid": 3
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc5424a1395498874a",
        "high": 12739.63,
        "open": 12702.05,
        "close": 12937.69,
        "low": 12711.57,
        "priceList": [
            {
                "price": 12775.74,
                "ask": 92,
                "bid": 52
            },
            {
                "price": 12880.59,
                "ask": 13,
                "bid": 90
            },
            {
                "price": 12802.41,
                "ask": 26,
                "bid": 79
            },
            {
                "price": 12955.48,
                "ask": 97,
                "bid": 38
            },
            {
                "price": 12807.22,
                "ask": 36,
                "bid": 75
            },
            {
                "price": 12792.93,
                "ask": 9,
                "bid": 36
            },
            {
                "price": 12977.91,
                "ask": 67,
                "bid": 14
            },
            {
                "price": 12852.78,
                "ask": 14,
                "bid": 6
            },
            {
                "price": 12829.12,
                "ask": 28,
                "bid": 90
            },
            {
                "price": 12769.49,
                "ask": 97,
                "bid": 59
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc9aa37b7663805f17",
        "high": 12780.49,
        "open": 12854.44,
        "close": 12708.35,
        "low": 12724.79,
        "priceList": [
            {
                "price": 12743.66,
                "ask": 86,
                "bid": 77
            },
            {
                "price": 12977.49,
                "ask": 22,
                "bid": 71
            },
            {
                "price": 12900.28,
                "ask": 19,
                "bid": 92
            },
            {
                "price": 12825.94,
                "ask": 81,
                "bid": 52
            },
            {
                "price": 12862.53,
                "ask": 6,
                "bid": 94
            },
            {
                "price": 12935.58,
                "ask": 64,
                "bid": 6
            },
            {
                "price": 12771.2,
                "ask": 89,
                "bid": 69
            },
            {
                "price": 12727.4,
                "ask": 16,
                "bid": 25
            },
            {
                "price": 12783.65,
                "ask": 4,
                "bid": 42
            },
            {
                "price": 12876.69,
                "ask": 36,
                "bid": 71
            },
            {
                "price": 12797.47,
                "ask": 33,
                "bid": 30
            },
            {
                "price": 12755.38,
                "ask": 37,
                "bid": 8
            },
            {
                "price": 12806.31,
                "ask": 75,
                "bid": 70
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc30301c5e3d5e4759",
        "high": 12724.29,
        "open": 12804.36,
        "close": 12813.99,
        "low": 12731.89,
        "priceList": [
            {
                "price": 12977.43,
                "ask": 62,
                "bid": 43
            },
            {
                "price": 12883.24,
                "ask": 55,
                "bid": 33
            },
            {
                "price": 12967.61,
                "ask": 16,
                "bid": 41
            },
            {
                "price": 12729.94,
                "ask": 63,
                "bid": 87
            },
            {
                "price": 12906.11,
                "ask": 43,
                "bid": 92
            },
            {
                "price": 12978.05,
                "ask": 89,
                "bid": 95
            },
            {
                "price": 12855.08,
                "ask": 2,
                "bid": 95
            },
            {
                "price": 12884.6,
                "ask": 84,
                "bid": 99
            },
            {
                "price": 12972.89,
                "ask": 8,
                "bid": 24
            },
            {
                "price": 12839.99,
                "ask": 76,
                "bid": 15
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc9afc1f566a723f5e",
        "high": 12844.47,
        "open": 12952.97,
        "close": 12883.68,
        "low": 12918.85,
        "priceList": [
            {
                "price": 12865.92,
                "ask": 70,
                "bid": 27
            },
            {
                "price": 12708.45,
                "ask": 16,
                "bid": 85
            },
            {
                "price": 12933.15,
                "ask": 26,
                "bid": 49
            },
            {
                "price": 12925.42,
                "ask": 53,
                "bid": 11
            },
            {
                "price": 12931.81,
                "ask": 6,
                "bid": 37
            },
            {
                "price": 12789.88,
                "ask": 28,
                "bid": 58
            },
            {
                "price": 12741.07,
                "ask": 74,
                "bid": 92
            },
            {
                "price": 12843.62,
                "ask": 62,
                "bid": 47
            },
            {
                "price": 12822.95,
                "ask": 44,
                "bid": 37
            },
            {
                "price": 12900.47,
                "ask": 80,
                "bid": 59
            },
            {
                "price": 12743.04,
                "ask": 89,
                "bid": 62
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc8320c72c4415a1b6",
        "high": 12705.66,
        "open": 12869.91,
        "close": 12781.84,
        "low": 12868.52,
        "priceList": [
            {
                "price": 12928.5,
                "ask": 35,
                "bid": 71
            },
            {
                "price": 12827.7,
                "ask": 88,
                "bid": 69
            },
            {
                "price": 12780.49,
                "ask": 47,
                "bid": 12
            },
            {
                "price": 12820.33,
                "ask": 59,
                "bid": 39
            },
            {
                "price": 12881.54,
                "ask": 98,
                "bid": 19
            },
            {
                "price": 12857,
                "ask": 34,
                "bid": 99
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcf1525c8b00f19892",
        "high": 12935.05,
        "open": 12829.79,
        "close": 12894.23,
        "low": 12990.99,
        "priceList": [
            {
                "price": 12944.3,
                "ask": 45,
                "bid": 65
            },
            {
                "price": 12895.54,
                "ask": 93,
                "bid": 88
            },
            {
                "price": 12979.78,
                "ask": 67,
                "bid": 95
            },
            {
                "price": 12747.66,
                "ask": 62,
                "bid": 61
            },
            {
                "price": 12917.29,
                "ask": 8,
                "bid": 10
            },
            {
                "price": 12798.44,
                "ask": 57,
                "bid": 45
            },
            {
                "price": 12803.99,
                "ask": 90,
                "bid": 27
            },
            {
                "price": 12984.09,
                "ask": 54,
                "bid": 17
            },
            {
                "price": 12762.58,
                "ask": 34,
                "bid": 81
            },
            {
                "price": 12991.87,
                "ask": 37,
                "bid": 74
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcad41694b109f88d3",
        "high": 12768.5,
        "open": 12980.55,
        "close": 12959.45,
        "low": 12726.89,
        "priceList": [
            {
                "price": 12740.54,
                "ask": 26,
                "bid": 75
            },
            {
                "price": 12752.41,
                "ask": 11,
                "bid": 37
            },
            {
                "price": 12716.49,
                "ask": 51,
                "bid": 89
            },
            {
                "price": 12765.77,
                "ask": 98,
                "bid": 89
            },
            {
                "price": 12864.48,
                "ask": 97,
                "bid": 54
            },
            {
                "price": 12825.1,
                "ask": 27,
                "bid": 32
            },
            {
                "price": 12836.61,
                "ask": 99,
                "bid": 25
            },
            {
                "price": 12823.99,
                "ask": 78,
                "bid": 40
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc0f92c157a7d18403",
        "high": 12979.38,
        "open": 12901.93,
        "close": 12930.76,
        "low": 12941.31,
        "priceList": [
            {
                "price": 12839.26,
                "ask": 52,
                "bid": 24
            },
            {
                "price": 12828.86,
                "ask": 100,
                "bid": 8
            },
            {
                "price": 12824.41,
                "ask": 75,
                "bid": 99
            },
            {
                "price": 12733.44,
                "ask": 76,
                "bid": 82
            },
            {
                "price": 12922.75,
                "ask": 13,
                "bid": 13
            },
            {
                "price": 12805.99,
                "ask": 93,
                "bid": 84
            },
            {
                "price": 12838.43,
                "ask": 92,
                "bid": 50
            },
            {
                "price": 12751.32,
                "ask": 2,
                "bid": 57
            },
            {
                "price": 12766.8,
                "ask": 24,
                "bid": 36
            },
            {
                "price": 12928.29,
                "ask": 2,
                "bid": 67
            },
            {
                "price": 12932.98,
                "ask": 40,
                "bid": 33
            },
            {
                "price": 12756.32,
                "ask": 13,
                "bid": 22
            },
            {
                "price": 12724.16,
                "ask": 36,
                "bid": 92
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc1764437a8ff4e8c3",
        "high": 12820.08,
        "open": 12769.04,
        "close": 12875.16,
        "low": 12799.17,
        "priceList": [
            {
                "price": 12873.18,
                "ask": 57,
                "bid": 16
            },
            {
                "price": 12973.62,
                "ask": 32,
                "bid": 3
            },
            {
                "price": 12922.11,
                "ask": 96,
                "bid": 42
            },
            {
                "price": 12938.11,
                "ask": 52,
                "bid": 12
            },
            {
                "price": 12798.59,
                "ask": 19,
                "bid": 35
            },
            {
                "price": 12714.28,
                "ask": 95,
                "bid": 50
            },
            {
                "price": 12878.03,
                "ask": 67,
                "bid": 93
            },
            {
                "price": 12790.27,
                "ask": 84,
                "bid": 15
            },
            {
                "price": 12931.52,
                "ask": 92,
                "bid": 97
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc735a197f14ac0025",
        "high": 12883.42,
        "open": 12845.85,
        "close": 12982,
        "low": 12855.45,
        "priceList": [
            {
                "price": 12975.62,
                "ask": 59,
                "bid": 63
            },
            {
                "price": 12844.3,
                "ask": 80,
                "bid": 21
            },
            {
                "price": 12800.59,
                "ask": 16,
                "bid": 18
            },
            {
                "price": 12995.15,
                "ask": 16,
                "bid": 75
            },
            {
                "price": 12815.62,
                "ask": 95,
                "bid": 19
            },
            {
                "price": 12864.53,
                "ask": 79,
                "bid": 1
            },
            {
                "price": 12936.74,
                "ask": 53,
                "bid": 46
            },
            {
                "price": 12797.37,
                "ask": 48,
                "bid": 64
            },
            {
                "price": 12716.47,
                "ask": 46,
                "bid": 96
            },
            {
                "price": 12745.96,
                "ask": 22,
                "bid": 21
            },
            {
                "price": 12881.96,
                "ask": 7,
                "bid": 77
            },
            {
                "price": 12907.28,
                "ask": 56,
                "bid": 1
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc6614253212a599bc",
        "high": 12789.75,
        "open": 12757.21,
        "close": 12705.48,
        "low": 12884.97,
        "priceList": [
            {
                "price": 12870.55,
                "ask": 21,
                "bid": 41
            },
            {
                "price": 12829.56,
                "ask": 75,
                "bid": 76
            },
            {
                "price": 12746.76,
                "ask": 4,
                "bid": 11
            },
            {
                "price": 12949.42,
                "ask": 73,
                "bid": 77
            },
            {
                "price": 12907.11,
                "ask": 96,
                "bid": 8
            },
            {
                "price": 12767.15,
                "ask": 87,
                "bid": 0
            },
            {
                "price": 12966.4,
                "ask": 46,
                "bid": 40
            },
            {
                "price": 12771.88,
                "ask": 33,
                "bid": 91
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc9a43cd5c43c1cf0f",
        "high": 12754.19,
        "open": 12995.95,
        "close": 12725.54,
        "low": 12957.8,
        "priceList": [
            {
                "price": 12771.29,
                "ask": 93,
                "bid": 13
            },
            {
                "price": 12983.99,
                "ask": 54,
                "bid": 62
            },
            {
                "price": 12936.07,
                "ask": 80,
                "bid": 0
            },
            {
                "price": 12847.53,
                "ask": 14,
                "bid": 26
            },
            {
                "price": 12799.01,
                "ask": 59,
                "bid": 20
            },
            {
                "price": 12955.63,
                "ask": 42,
                "bid": 89
            },
            {
                "price": 12970.8,
                "ask": 55,
                "bid": 26
            },
            {
                "price": 12916.73,
                "ask": 37,
                "bid": 70
            },
            {
                "price": 12957.99,
                "ask": 35,
                "bid": 79
            },
            {
                "price": 12826.89,
                "ask": 16,
                "bid": 58
            },
            {
                "price": 12954.03,
                "ask": 48,
                "bid": 71
            },
            {
                "price": 12767.07,
                "ask": 30,
                "bid": 44
            },
            {
                "price": 12782.08,
                "ask": 82,
                "bid": 68
            },
            {
                "price": 12761.07,
                "ask": 24,
                "bid": 44
            },
            {
                "price": 12753.95,
                "ask": 50,
                "bid": 13
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcb366512222a612d5",
        "high": 12796.75,
        "open": 12795.21,
        "close": 12951.77,
        "low": 12836.37,
        "priceList": [
            {
                "price": 12926.74,
                "ask": 88,
                "bid": 45
            },
            {
                "price": 12881.89,
                "ask": 43,
                "bid": 69
            },
            {
                "price": 12982.02,
                "ask": 68,
                "bid": 82
            },
            {
                "price": 12753.58,
                "ask": 30,
                "bid": 86
            },
            {
                "price": 12952.28,
                "ask": 46,
                "bid": 24
            },
            {
                "price": 12858.05,
                "ask": 82,
                "bid": 61
            },
            {
                "price": 12735.14,
                "ask": 23,
                "bid": 39
            },
            {
                "price": 12753.78,
                "ask": 40,
                "bid": 15
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc2b1f77e013378055",
        "high": 12779.24,
        "open": 12820.36,
        "close": 12772.42,
        "low": 12984.75,
        "priceList": [
            {
                "price": 12751.75,
                "ask": 4,
                "bid": 99
            },
            {
                "price": 12705.98,
                "ask": 36,
                "bid": 36
            },
            {
                "price": 12896.89,
                "ask": 39,
                "bid": 13
            },
            {
                "price": 12946.94,
                "ask": 1,
                "bid": 5
            },
            {
                "price": 12713.61,
                "ask": 7,
                "bid": 25
            },
            {
                "price": 12955.56,
                "ask": 84,
                "bid": 32
            },
            {
                "price": 12822.15,
                "ask": 55,
                "bid": 93
            },
            {
                "price": 12849.41,
                "ask": 33,
                "bid": 26
            },
            {
                "price": 12971.59,
                "ask": 24,
                "bid": 87
            },
            {
                "price": 12947.58,
                "ask": 69,
                "bid": 77
            },
            {
                "price": 12756.23,
                "ask": 53,
                "bid": 57
            },
            {
                "price": 12945.98,
                "ask": 18,
                "bid": 45
            },
            {
                "price": 12826.46,
                "ask": 23,
                "bid": 11
            },
            {
                "price": 12788.62,
                "ask": 15,
                "bid": 91
            },
            {
                "price": 12928.72,
                "ask": 53,
                "bid": 45
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca3a1d3ac88046f32",
        "high": 12972.4,
        "open": 12922.28,
        "close": 12931.28,
        "low": 12705.95,
        "priceList": [
            {
                "price": 12993.03,
                "ask": 43,
                "bid": 69
            },
            {
                "price": 12977.31,
                "ask": 69,
                "bid": 63
            },
            {
                "price": 12819.63,
                "ask": 72,
                "bid": 90
            },
            {
                "price": 12956.82,
                "ask": 68,
                "bid": 35
            },
            {
                "price": 12808.26,
                "ask": 0,
                "bid": 4
            },
            {
                "price": 12740.27,
                "ask": 83,
                "bid": 51
            },
            {
                "price": 12841.76,
                "ask": 0,
                "bid": 1
            },
            {
                "price": 12929.68,
                "ask": 85,
                "bid": 9
            },
            {
                "price": 12715.51,
                "ask": 29,
                "bid": 95
            },
            {
                "price": 12880.37,
                "ask": 65,
                "bid": 21
            },
            {
                "price": 12957.73,
                "ask": 58,
                "bid": 10
            },
            {
                "price": 12983.87,
                "ask": 83,
                "bid": 56
            },
            {
                "price": 12915.69,
                "ask": 22,
                "bid": 27
            },
            {
                "price": 12790.29,
                "ask": 96,
                "bid": 60
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcdcd170ad671ba1e1",
        "high": 12934.54,
        "open": 12897.47,
        "close": 12846.75,
        "low": 12960.62,
        "priceList": [
            {
                "price": 12916.02,
                "ask": 77,
                "bid": 55
            },
            {
                "price": 12808.04,
                "ask": 23,
                "bid": 79
            },
            {
                "price": 12938.34,
                "ask": 71,
                "bid": 82
            },
            {
                "price": 12842.75,
                "ask": 69,
                "bid": 44
            },
            {
                "price": 12891.76,
                "ask": 18,
                "bid": 52
            },
            {
                "price": 12911.99,
                "ask": 7,
                "bid": 97
            },
            {
                "price": 12753.62,
                "ask": 24,
                "bid": 9
            },
            {
                "price": 12732.67,
                "ask": 59,
                "bid": 54
            },
            {
                "price": 12749.59,
                "ask": 15,
                "bid": 16
            },
            {
                "price": 12852.93,
                "ask": 56,
                "bid": 61
            },
            {
                "price": 12737.92,
                "ask": 81,
                "bid": 72
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc4a2a6368434956ad",
        "high": 12736.99,
        "open": 12997.46,
        "close": 12738.69,
        "low": 12959.75,
        "priceList": [
            {
                "price": 12838.61,
                "ask": 34,
                "bid": 31
            },
            {
                "price": 12983.96,
                "ask": 77,
                "bid": 57
            },
            {
                "price": 12920.3,
                "ask": 0,
                "bid": 59
            },
            {
                "price": 12981.61,
                "ask": 59,
                "bid": 28
            },
            {
                "price": 12870.49,
                "ask": 10,
                "bid": 72
            },
            {
                "price": 12913.47,
                "ask": 83,
                "bid": 30
            },
            {
                "price": 12963.63,
                "ask": 27,
                "bid": 92
            },
            {
                "price": 12774.11,
                "ask": 62,
                "bid": 45
            },
            {
                "price": 12809.16,
                "ask": 81,
                "bid": 65
            },
            {
                "price": 12963.09,
                "ask": 86,
                "bid": 0
            },
            {
                "price": 12804.18,
                "ask": 49,
                "bid": 30
            },
            {
                "price": 12910.56,
                "ask": 37,
                "bid": 67
            },
            {
                "price": 12794.18,
                "ask": 3,
                "bid": 35
            },
            {
                "price": 12746.62,
                "ask": 75,
                "bid": 87
            },
            {
                "price": 12801.11,
                "ask": 59,
                "bid": 100
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc835ebd03e3008884",
        "high": 12716.67,
        "open": 12950.9,
        "close": 12799.39,
        "low": 12854.82,
        "priceList": [
            {
                "price": 12966.23,
                "ask": 8,
                "bid": 21
            },
            {
                "price": 12725.58,
                "ask": 17,
                "bid": 18
            },
            {
                "price": 12946.41,
                "ask": 62,
                "bid": 71
            },
            {
                "price": 12779.64,
                "ask": 21,
                "bid": 61
            },
            {
                "price": 12816.92,
                "ask": 1,
                "bid": 82
            },
            {
                "price": 12966.79,
                "ask": 71,
                "bid": 8
            },
            {
                "price": 12948.65,
                "ask": 7,
                "bid": 27
            },
            {
                "price": 12877.34,
                "ask": 10,
                "bid": 63
            },
            {
                "price": 12771.97,
                "ask": 85,
                "bid": 34
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc7ec34536782ad788",
        "high": 12796.5,
        "open": 12724.67,
        "close": 12892.99,
        "low": 12947.46,
        "priceList": [
            {
                "price": 12844.59,
                "ask": 8,
                "bid": 50
            },
            {
                "price": 12885.98,
                "ask": 41,
                "bid": 5
            },
            {
                "price": 12893.71,
                "ask": 38,
                "bid": 82
            },
            {
                "price": 12829.21,
                "ask": 26,
                "bid": 9
            },
            {
                "price": 12908.12,
                "ask": 15,
                "bid": 56
            },
            {
                "price": 12935.57,
                "ask": 82,
                "bid": 33
            },
            {
                "price": 12916.72,
                "ask": 13,
                "bid": 74
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcaf7eeebb0d7fa4ac",
        "high": 12927.65,
        "open": 12832.71,
        "close": 12714.38,
        "low": 12905.84,
        "priceList": [
            {
                "price": 12721.37,
                "ask": 63,
                "bid": 6
            },
            {
                "price": 12975.92,
                "ask": 55,
                "bid": 5
            },
            {
                "price": 12933.7,
                "ask": 26,
                "bid": 44
            },
            {
                "price": 12935.53,
                "ask": 99,
                "bid": 83
            },
            {
                "price": 12886.92,
                "ask": 44,
                "bid": 29
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc8085d85eb8b282f0",
        "high": 12842.09,
        "open": 12919.18,
        "close": 12845.99,
        "low": 12779.49,
        "priceList": [
            {
                "price": 12836.62,
                "ask": 9,
                "bid": 12
            },
            {
                "price": 12902.51,
                "ask": 12,
                "bid": 13
            },
            {
                "price": 12939.36,
                "ask": 78,
                "bid": 35
            },
            {
                "price": 12771.26,
                "ask": 57,
                "bid": 92
            },
            {
                "price": 12793.3,
                "ask": 6,
                "bid": 48
            },
            {
                "price": 12960.24,
                "ask": 9,
                "bid": 4
            },
            {
                "price": 12969.41,
                "ask": 13,
                "bid": 49
            },
            {
                "price": 12954.49,
                "ask": 75,
                "bid": 100
            },
            {
                "price": 12788.91,
                "ask": 60,
                "bid": 39
            },
            {
                "price": 12777.16,
                "ask": 3,
                "bid": 98
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc748ff15ee339c2f5",
        "high": 12895.57,
        "open": 12887.69,
        "close": 12880.5,
        "low": 12963.18,
        "priceList": [
            {
                "price": 12997.27,
                "ask": 32,
                "bid": 66
            },
            {
                "price": 12809.08,
                "ask": 74,
                "bid": 5
            },
            {
                "price": 12821.21,
                "ask": 50,
                "bid": 95
            },
            {
                "price": 12973.78,
                "ask": 64,
                "bid": 23
            },
            {
                "price": 12744.62,
                "ask": 87,
                "bid": 28
            },
            {
                "price": 12837.63,
                "ask": 100,
                "bid": 55
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcf0560294e7173e4f",
        "high": 12766.62,
        "open": 12824.08,
        "close": 12974.49,
        "low": 12984.86,
        "priceList": [
            {
                "price": 12706.27,
                "ask": 70,
                "bid": 86
            },
            {
                "price": 12751.36,
                "ask": 39,
                "bid": 86
            },
            {
                "price": 12853.47,
                "ask": 40,
                "bid": 31
            },
            {
                "price": 12948.24,
                "ask": 50,
                "bid": 18
            },
            {
                "price": 12873.7,
                "ask": 65,
                "bid": 18
            },
            {
                "price": 12890.15,
                "ask": 43,
                "bid": 46
            },
            {
                "price": 12929.87,
                "ask": 27,
                "bid": 4
            },
            {
                "price": 12882.79,
                "ask": 56,
                "bid": 78
            },
            {
                "price": 12887.61,
                "ask": 24,
                "bid": 17
            },
            {
                "price": 12741.33,
                "ask": 32,
                "bid": 2
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc2f3d1143510fdcd1",
        "high": 12791.15,
        "open": 12906.99,
        "close": 12815.35,
        "low": 12760.96,
        "priceList": [
            {
                "price": 12923.18,
                "ask": 87,
                "bid": 26
            },
            {
                "price": 12968.96,
                "ask": 62,
                "bid": 1
            },
            {
                "price": 12884.32,
                "ask": 33,
                "bid": 95
            },
            {
                "price": 12990.31,
                "ask": 13,
                "bid": 85
            },
            {
                "price": 12719.62,
                "ask": 19,
                "bid": 82
            },
            {
                "price": 12987.1,
                "ask": 98,
                "bid": 13
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc2c4b19c175c33993",
        "high": 12966.45,
        "open": 12717.5,
        "close": 12819.91,
        "low": 12982.04,
        "priceList": [
            {
                "price": 12893.84,
                "ask": 24,
                "bid": 60
            },
            {
                "price": 12977.97,
                "ask": 19,
                "bid": 24
            },
            {
                "price": 12732.25,
                "ask": 86,
                "bid": 25
            },
            {
                "price": 12791.2,
                "ask": 93,
                "bid": 60
            },
            {
                "price": 12957.94,
                "ask": 7,
                "bid": 37
            },
            {
                "price": 12989.15,
                "ask": 8,
                "bid": 86
            },
            {
                "price": 12798.37,
                "ask": 65,
                "bid": 98
            },
            {
                "price": 12939.73,
                "ask": 11,
                "bid": 48
            },
            {
                "price": 12935.94,
                "ask": 90,
                "bid": 83
            },
            {
                "price": 12763.07,
                "ask": 36,
                "bid": 65
            },
            {
                "price": 12762.96,
                "ask": 10,
                "bid": 5
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dccc0c538b747603cc",
        "high": 12926.07,
        "open": 12737.9,
        "close": 12851.94,
        "low": 12972.68,
        "priceList": [
            {
                "price": 12879.39,
                "ask": 63,
                "bid": 94
            },
            {
                "price": 12855.73,
                "ask": 23,
                "bid": 4
            },
            {
                "price": 12865.52,
                "ask": 66,
                "bid": 87
            },
            {
                "price": 12930.97,
                "ask": 41,
                "bid": 41
            },
            {
                "price": 12700.87,
                "ask": 88,
                "bid": 46
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcffb5c4f63d323fe3",
        "high": 12995.53,
        "open": 12768.97,
        "close": 12895.87,
        "low": 12799.96,
        "priceList": [
            {
                "price": 12997.4,
                "ask": 41,
                "bid": 54
            },
            {
                "price": 12880.12,
                "ask": 5,
                "bid": 16
            },
            {
                "price": 12907.02,
                "ask": 4,
                "bid": 34
            },
            {
                "price": 12712.46,
                "ask": 12,
                "bid": 9
            },
            {
                "price": 12738.07,
                "ask": 38,
                "bid": 13
            },
            {
                "price": 12833.26,
                "ask": 74,
                "bid": 3
            },
            {
                "price": 12878.4,
                "ask": 59,
                "bid": 94
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcefece13094b7180f",
        "high": 12895.95,
        "open": 12808.6,
        "close": 12804.66,
        "low": 12856.14,
        "priceList": [
            {
                "price": 12945.01,
                "ask": 94,
                "bid": 71
            },
            {
                "price": 12913.46,
                "ask": 81,
                "bid": 26
            },
            {
                "price": 12808.91,
                "ask": 31,
                "bid": 21
            },
            {
                "price": 12875,
                "ask": 100,
                "bid": 40
            },
            {
                "price": 12970.81,
                "ask": 70,
                "bid": 55
            },
            {
                "price": 12715.31,
                "ask": 56,
                "bid": 69
            },
            {
                "price": 12800.27,
                "ask": 31,
                "bid": 67
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc0439372f8ced6bed",
        "high": 12968.22,
        "open": 12703.85,
        "close": 12913.54,
        "low": 12982.41,
        "priceList": [
            {
                "price": 12814.11,
                "ask": 88,
                "bid": 89
            },
            {
                "price": 12729.09,
                "ask": 55,
                "bid": 92
            },
            {
                "price": 12989.67,
                "ask": 13,
                "bid": 5
            },
            {
                "price": 12985.5,
                "ask": 1,
                "bid": 39
            },
            {
                "price": 12865.48,
                "ask": 1,
                "bid": 40
            },
            {
                "price": 12827.26,
                "ask": 58,
                "bid": 59
            },
            {
                "price": 12720.67,
                "ask": 98,
                "bid": 20
            },
            {
                "price": 12797.27,
                "ask": 12,
                "bid": 54
            },
            {
                "price": 12788.56,
                "ask": 64,
                "bid": 89
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc083a94d22e5f1c33",
        "high": 12783.84,
        "open": 12832.4,
        "close": 12939.11,
        "low": 12770.98,
        "priceList": [
            {
                "price": 12858.87,
                "ask": 48,
                "bid": 66
            },
            {
                "price": 12986.84,
                "ask": 30,
                "bid": 28
            },
            {
                "price": 12706.6,
                "ask": 95,
                "bid": 5
            },
            {
                "price": 12976.45,
                "ask": 16,
                "bid": 76
            },
            {
                "price": 12828.26,
                "ask": 87,
                "bid": 93
            },
            {
                "price": 12891.39,
                "ask": 91,
                "bid": 32
            },
            {
                "price": 12940.48,
                "ask": 64,
                "bid": 43
            },
            {
                "price": 12941.48,
                "ask": 84,
                "bid": 44
            },
            {
                "price": 12905.61,
                "ask": 88,
                "bid": 39
            },
            {
                "price": 12749.34,
                "ask": 31,
                "bid": 53
            },
            {
                "price": 12774.8,
                "ask": 45,
                "bid": 21
            },
            {
                "price": 12740.91,
                "ask": 82,
                "bid": 61
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc978451a723f0ef0d",
        "high": 12890.23,
        "open": 12836.97,
        "close": 12859.14,
        "low": 12776.54,
        "priceList": [
            {
                "price": 12969.23,
                "ask": 100,
                "bid": 77
            },
            {
                "price": 12913.86,
                "ask": 75,
                "bid": 38
            },
            {
                "price": 12969.29,
                "ask": 10,
                "bid": 76
            },
            {
                "price": 12824.1,
                "ask": 71,
                "bid": 72
            },
            {
                "price": 12832.7,
                "ask": 50,
                "bid": 42
            },
            {
                "price": 12981.15,
                "ask": 32,
                "bid": 24
            },
            {
                "price": 12968.31,
                "ask": 4,
                "bid": 20
            },
            {
                "price": 12932.47,
                "ask": 45,
                "bid": 72
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc2811d727f2b8dec1",
        "high": 12836.12,
        "open": 12822.61,
        "close": 12934.84,
        "low": 12845.65,
        "priceList": [
            {
                "price": 12875.39,
                "ask": 56,
                "bid": 23
            },
            {
                "price": 12978.62,
                "ask": 20,
                "bid": 82
            },
            {
                "price": 12711,
                "ask": 31,
                "bid": 18
            },
            {
                "price": 12768.79,
                "ask": 47,
                "bid": 85
            },
            {
                "price": 12783.95,
                "ask": 90,
                "bid": 88
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc952e1aff3eaec6a5",
        "high": 12959.96,
        "open": 12916.85,
        "close": 12747.75,
        "low": 12754.44,
        "priceList": [
            {
                "price": 12754.4,
                "ask": 79,
                "bid": 38
            },
            {
                "price": 12933.14,
                "ask": 70,
                "bid": 93
            },
            {
                "price": 12745.34,
                "ask": 33,
                "bid": 42
            },
            {
                "price": 12703.51,
                "ask": 29,
                "bid": 61
            },
            {
                "price": 12728.19,
                "ask": 14,
                "bid": 51
            },
            {
                "price": 12981.8,
                "ask": 82,
                "bid": 60
            },
            {
                "price": 12785.69,
                "ask": 50,
                "bid": 57
            },
            {
                "price": 12999.09,
                "ask": 75,
                "bid": 68
            },
            {
                "price": 12898.92,
                "ask": 10,
                "bid": 48
            },
            {
                "price": 12961.43,
                "ask": 6,
                "bid": 80
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc8942cc2263162840",
        "high": 12824.09,
        "open": 12742.52,
        "close": 12992.56,
        "low": 12734.88,
        "priceList": [
            {
                "price": 12777.25,
                "ask": 8,
                "bid": 71
            },
            {
                "price": 12974.27,
                "ask": 88,
                "bid": 28
            },
            {
                "price": 12973.24,
                "ask": 92,
                "bid": 29
            },
            {
                "price": 12952.95,
                "ask": 51,
                "bid": 66
            },
            {
                "price": 12781.63,
                "ask": 89,
                "bid": 72
            },
            {
                "price": 12980.96,
                "ask": 17,
                "bid": 81
            },
            {
                "price": 12907.23,
                "ask": 5,
                "bid": 75
            },
            {
                "price": 12836.07,
                "ask": 39,
                "bid": 100
            },
            {
                "price": 12804.49,
                "ask": 36,
                "bid": 25
            },
            {
                "price": 12854.82,
                "ask": 61,
                "bid": 65
            },
            {
                "price": 12965.37,
                "ask": 89,
                "bid": 96
            },
            {
                "price": 12981.5,
                "ask": 27,
                "bid": 80
            },
            {
                "price": 12743.81,
                "ask": 22,
                "bid": 18
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcddeba31027061d16",
        "high": 12837.03,
        "open": 12980.16,
        "close": 12729.41,
        "low": 12773.19,
        "priceList": [
            {
                "price": 12827.56,
                "ask": 76,
                "bid": 30
            },
            {
                "price": 12730.54,
                "ask": 73,
                "bid": 78
            },
            {
                "price": 12974.95,
                "ask": 13,
                "bid": 75
            },
            {
                "price": 12902.39,
                "ask": 98,
                "bid": 30
            },
            {
                "price": 12895.38,
                "ask": 92,
                "bid": 68
            },
            {
                "price": 12803.81,
                "ask": 98,
                "bid": 71
            },
            {
                "price": 12793.35,
                "ask": 46,
                "bid": 32
            },
            {
                "price": 12937.77,
                "ask": 36,
                "bid": 58
            },
            {
                "price": 12930.55,
                "ask": 49,
                "bid": 16
            },
            {
                "price": 12984.51,
                "ask": 63,
                "bid": 52
            },
            {
                "price": 12810.07,
                "ask": 37,
                "bid": 21
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc6f798348ed86609a",
        "high": 12871.49,
        "open": 12767.56,
        "close": 12812.59,
        "low": 12995.65,
        "priceList": [
            {
                "price": 12972.31,
                "ask": 6,
                "bid": 81
            },
            {
                "price": 12805.68,
                "ask": 42,
                "bid": 77
            },
            {
                "price": 12918.98,
                "ask": 79,
                "bid": 76
            },
            {
                "price": 12829.14,
                "ask": 82,
                "bid": 11
            },
            {
                "price": 12827.27,
                "ask": 4,
                "bid": 98
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc955ef3d1715f4e6d",
        "high": 12747.82,
        "open": 12930.56,
        "close": 12904.52,
        "low": 12798.25,
        "priceList": [
            {
                "price": 12895.62,
                "ask": 29,
                "bid": 29
            },
            {
                "price": 12935.22,
                "ask": 34,
                "bid": 78
            },
            {
                "price": 12724.23,
                "ask": 56,
                "bid": 63
            },
            {
                "price": 12950.13,
                "ask": 41,
                "bid": 66
            },
            {
                "price": 12909.82,
                "ask": 53,
                "bid": 47
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcc181bea5ca789797",
        "high": 12788.63,
        "open": 12843.01,
        "close": 12826.87,
        "low": 12952.32,
        "priceList": [
            {
                "price": 12835.61,
                "ask": 66,
                "bid": 99
            },
            {
                "price": 12810.32,
                "ask": 58,
                "bid": 27
            },
            {
                "price": 12806.65,
                "ask": 10,
                "bid": 89
            },
            {
                "price": 12928.26,
                "ask": 73,
                "bid": 42
            },
            {
                "price": 12936.51,
                "ask": 48,
                "bid": 7
            },
            {
                "price": 12813.13,
                "ask": 35,
                "bid": 87
            },
            {
                "price": 12750.22,
                "ask": 83,
                "bid": 11
            },
            {
                "price": 12714.81,
                "ask": 11,
                "bid": 96
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc44bef50c4ecc0161",
        "high": 12995.05,
        "open": 12989.37,
        "close": 12887.26,
        "low": 12865.64,
        "priceList": [
            {
                "price": 12908.49,
                "ask": 85,
                "bid": 82
            },
            {
                "price": 12712.04,
                "ask": 48,
                "bid": 46
            },
            {
                "price": 12852.91,
                "ask": 92,
                "bid": 13
            },
            {
                "price": 12979.76,
                "ask": 12,
                "bid": 55
            },
            {
                "price": 12829.07,
                "ask": 19,
                "bid": 15
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc09d627c31c34f672",
        "high": 12941.25,
        "open": 12822.96,
        "close": 12863.01,
        "low": 12955.41,
        "priceList": [
            {
                "price": 12916.4,
                "ask": 42,
                "bid": 67
            },
            {
                "price": 12978.06,
                "ask": 43,
                "bid": 68
            },
            {
                "price": 12785.62,
                "ask": 24,
                "bid": 43
            },
            {
                "price": 12716.89,
                "ask": 82,
                "bid": 95
            },
            {
                "price": 12999.8,
                "ask": 30,
                "bid": 39
            },
            {
                "price": 12890.66,
                "ask": 83,
                "bid": 53
            },
            {
                "price": 12929.08,
                "ask": 28,
                "bid": 95
            },
            {
                "price": 12876.5,
                "ask": 64,
                "bid": 2
            },
            {
                "price": 12796,
                "ask": 69,
                "bid": 70
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcf3fcb6f30136cd57",
        "high": 12734.83,
        "open": 12765.34,
        "close": 12840.4,
        "low": 12842.29,
        "priceList": [
            {
                "price": 12886.26,
                "ask": 38,
                "bid": 100
            },
            {
                "price": 12893.79,
                "ask": 61,
                "bid": 83
            },
            {
                "price": 12844.14,
                "ask": 12,
                "bid": 16
            },
            {
                "price": 12994.15,
                "ask": 64,
                "bid": 72
            },
            {
                "price": 12856.41,
                "ask": 57,
                "bid": 31
            },
            {
                "price": 12743.9,
                "ask": 21,
                "bid": 87
            },
            {
                "price": 12984.44,
                "ask": 68,
                "bid": 50
            },
            {
                "price": 12860.12,
                "ask": 16,
                "bid": 84
            },
            {
                "price": 12937.89,
                "ask": 33,
                "bid": 81
            },
            {
                "price": 12729.16,
                "ask": 29,
                "bid": 34
            },
            {
                "price": 12950.15,
                "ask": 67,
                "bid": 18
            },
            {
                "price": 12758.02,
                "ask": 7,
                "bid": 76
            },
            {
                "price": 12855.3,
                "ask": 12,
                "bid": 65
            },
            {
                "price": 12870,
                "ask": 79,
                "bid": 91
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc28a29d7155357745",
        "high": 12815.47,
        "open": 12952.44,
        "close": 12784.99,
        "low": 12812.06,
        "priceList": [
            {
                "price": 12855,
                "ask": 9,
                "bid": 61
            },
            {
                "price": 12895.98,
                "ask": 52,
                "bid": 38
            },
            {
                "price": 12797.19,
                "ask": 79,
                "bid": 82
            },
            {
                "price": 12886.38,
                "ask": 95,
                "bid": 24
            },
            {
                "price": 12738.85,
                "ask": 26,
                "bid": 40
            },
            {
                "price": 12963.15,
                "ask": 59,
                "bid": 24
            },
            {
                "price": 12957.38,
                "ask": 10,
                "bid": 67
            },
            {
                "price": 12905.28,
                "ask": 12,
                "bid": 4
            },
            {
                "price": 12723.12,
                "ask": 24,
                "bid": 44
            },
            {
                "price": 12933.01,
                "ask": 59,
                "bid": 41
            },
            {
                "price": 12700.07,
                "ask": 18,
                "bid": 20
            },
            {
                "price": 12992.01,
                "ask": 11,
                "bid": 72
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc19167736f2a70024",
        "high": 12997.56,
        "open": 12981.01,
        "close": 12959.84,
        "low": 12814.86,
        "priceList": [
            {
                "price": 12819.85,
                "ask": 13,
                "bid": 25
            },
            {
                "price": 12878.77,
                "ask": 30,
                "bid": 44
            },
            {
                "price": 12932.98,
                "ask": 62,
                "bid": 8
            },
            {
                "price": 12851.58,
                "ask": 26,
                "bid": 71
            },
            {
                "price": 12876.97,
                "ask": 48,
                "bid": 67
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc46c586616f1cacfa",
        "high": 12949.7,
        "open": 12884.98,
        "close": 12892.4,
        "low": 12848.19,
        "priceList": [
            {
                "price": 12800.99,
                "ask": 50,
                "bid": 59
            },
            {
                "price": 12734.78,
                "ask": 52,
                "bid": 28
            },
            {
                "price": 12723.2,
                "ask": 64,
                "bid": 13
            },
            {
                "price": 12818.3,
                "ask": 98,
                "bid": 10
            },
            {
                "price": 12733.19,
                "ask": 43,
                "bid": 76
            },
            {
                "price": 12974.88,
                "ask": 21,
                "bid": 53
            },
            {
                "price": 12822.21,
                "ask": 98,
                "bid": 77
            },
            {
                "price": 12827.19,
                "ask": 51,
                "bid": 58
            },
            {
                "price": 12831.55,
                "ask": 74,
                "bid": 30
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc85039fcac14aea4f",
        "high": 12768.63,
        "open": 12733.25,
        "close": 12776.38,
        "low": 12846.95,
        "priceList": [
            {
                "price": 12808.9,
                "ask": 69,
                "bid": 17
            },
            {
                "price": 12802.46,
                "ask": 88,
                "bid": 34
            },
            {
                "price": 12814.33,
                "ask": 12,
                "bid": 49
            },
            {
                "price": 12750.81,
                "ask": 85,
                "bid": 25
            },
            {
                "price": 12961.43,
                "ask": 83,
                "bid": 16
            },
            {
                "price": 12835.41,
                "ask": 27,
                "bid": 66
            },
            {
                "price": 12781.99,
                "ask": 96,
                "bid": 68
            },
            {
                "price": 12805.51,
                "ask": 70,
                "bid": 39
            },
            {
                "price": 12951.86,
                "ask": 22,
                "bid": 70
            },
            {
                "price": 12833.16,
                "ask": 16,
                "bid": 26
            },
            {
                "price": 12899.9,
                "ask": 56,
                "bid": 14
            },
            {
                "price": 12712.08,
                "ask": 90,
                "bid": 45
            },
            {
                "price": 12708.14,
                "ask": 19,
                "bid": 27
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcba14ff4c8893d1a5",
        "high": 12731.42,
        "open": 12922.48,
        "close": 12961.74,
        "low": 12742.5,
        "priceList": [
            {
                "price": 12862.31,
                "ask": 42,
                "bid": 37
            },
            {
                "price": 12906.73,
                "ask": 36,
                "bid": 83
            },
            {
                "price": 12848.48,
                "ask": 59,
                "bid": 75
            },
            {
                "price": 12965.14,
                "ask": 21,
                "bid": 33
            },
            {
                "price": 12943.56,
                "ask": 12,
                "bid": 53
            },
            {
                "price": 12907.99,
                "ask": 90,
                "bid": 25
            },
            {
                "price": 12861.28,
                "ask": 50,
                "bid": 57
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcfdb7857b47fbcadc",
        "high": 12879.4,
        "open": 12882.6,
        "close": 12951.26,
        "low": 12912.3,
        "priceList": [
            {
                "price": 12787.71,
                "ask": 30,
                "bid": 58
            },
            {
                "price": 12851.4,
                "ask": 44,
                "bid": 25
            },
            {
                "price": 12929.46,
                "ask": 58,
                "bid": 80
            },
            {
                "price": 12770.46,
                "ask": 1,
                "bid": 59
            },
            {
                "price": 12984.17,
                "ask": 67,
                "bid": 92
            },
            {
                "price": 12926.45,
                "ask": 50,
                "bid": 75
            },
            {
                "price": 12996.73,
                "ask": 16,
                "bid": 4
            },
            {
                "price": 12944.87,
                "ask": 30,
                "bid": 22
            },
            {
                "price": 12804.37,
                "ask": 64,
                "bid": 66
            },
            {
                "price": 12700.74,
                "ask": 66,
                "bid": 77
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcb58bda0acef74657",
        "high": 12876.08,
        "open": 12786.58,
        "close": 12937.22,
        "low": 12767.17,
        "priceList": [
            {
                "price": 12800.04,
                "ask": 62,
                "bid": 91
            },
            {
                "price": 12994.94,
                "ask": 55,
                "bid": 40
            },
            {
                "price": 12806.71,
                "ask": 76,
                "bid": 20
            },
            {
                "price": 12962.31,
                "ask": 53,
                "bid": 99
            },
            {
                "price": 12801.1,
                "ask": 32,
                "bid": 19
            },
            {
                "price": 12751.54,
                "ask": 37,
                "bid": 99
            },
            {
                "price": 12888.43,
                "ask": 96,
                "bid": 93
            },
            {
                "price": 12776.5,
                "ask": 57,
                "bid": 15
            },
            {
                "price": 12939.41,
                "ask": 0,
                "bid": 89
            },
            {
                "price": 12701.59,
                "ask": 97,
                "bid": 21
            },
            {
                "price": 12813.48,
                "ask": 37,
                "bid": 90
            },
            {
                "price": 12943.45,
                "ask": 80,
                "bid": 32
            },
            {
                "price": 12838.52,
                "ask": 36,
                "bid": 62
            },
            {
                "price": 12744.28,
                "ask": 75,
                "bid": 54
            },
            {
                "price": 12836.01,
                "ask": 83,
                "bid": 94
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc08e9d118c0e10154",
        "high": 12826.88,
        "open": 12964.79,
        "close": 12715.7,
        "low": 12737.53,
        "priceList": [
            {
                "price": 12713.62,
                "ask": 64,
                "bid": 42
            },
            {
                "price": 12736.88,
                "ask": 79,
                "bid": 74
            },
            {
                "price": 12891.14,
                "ask": 99,
                "bid": 37
            },
            {
                "price": 12853.24,
                "ask": 98,
                "bid": 46
            },
            {
                "price": 12957.84,
                "ask": 84,
                "bid": 42
            },
            {
                "price": 12838.32,
                "ask": 33,
                "bid": 33
            },
            {
                "price": 12817.25,
                "ask": 56,
                "bid": 75
            },
            {
                "price": 12783.44,
                "ask": 31,
                "bid": 57
            },
            {
                "price": 12718.93,
                "ask": 80,
                "bid": 41
            },
            {
                "price": 12821.68,
                "ask": 89,
                "bid": 100
            },
            {
                "price": 12791.87,
                "ask": 43,
                "bid": 90
            },
            {
                "price": 12992.99,
                "ask": 94,
                "bid": 2
            },
            {
                "price": 12755.56,
                "ask": 98,
                "bid": 11
            },
            {
                "price": 12725.37,
                "ask": 21,
                "bid": 78
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc91b2ef5572ab4084",
        "high": 12831.78,
        "open": 12867.93,
        "close": 12821.02,
        "low": 12947.39,
        "priceList": [
            {
                "price": 12987.37,
                "ask": 96,
                "bid": 11
            },
            {
                "price": 12884.07,
                "ask": 18,
                "bid": 12
            },
            {
                "price": 12967.01,
                "ask": 33,
                "bid": 9
            },
            {
                "price": 12888.67,
                "ask": 100,
                "bid": 70
            },
            {
                "price": 12833.21,
                "ask": 23,
                "bid": 67
            },
            {
                "price": 12956.63,
                "ask": 51,
                "bid": 82
            },
            {
                "price": 12873.54,
                "ask": 96,
                "bid": 53
            },
            {
                "price": 12760.75,
                "ask": 63,
                "bid": 21
            },
            {
                "price": 12934.58,
                "ask": 87,
                "bid": 49
            },
            {
                "price": 12717.42,
                "ask": 86,
                "bid": 35
            },
            {
                "price": 12980.02,
                "ask": 37,
                "bid": 26
            },
            {
                "price": 12943.48,
                "ask": 91,
                "bid": 56
            },
            {
                "price": 12874.87,
                "ask": 54,
                "bid": 19
            },
            {
                "price": 12914.36,
                "ask": 20,
                "bid": 56
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcb39340696f9ddb98",
        "high": 12780.11,
        "open": 12979.87,
        "close": 12713.68,
        "low": 12859.38,
        "priceList": [
            {
                "price": 12928,
                "ask": 61,
                "bid": 1
            },
            {
                "price": 12902.1,
                "ask": 45,
                "bid": 84
            },
            {
                "price": 12735.74,
                "ask": 57,
                "bid": 13
            },
            {
                "price": 12997.46,
                "ask": 94,
                "bid": 35
            },
            {
                "price": 12838.24,
                "ask": 36,
                "bid": 35
            },
            {
                "price": 12744.09,
                "ask": 75,
                "bid": 75
            },
            {
                "price": 12750.69,
                "ask": 3,
                "bid": 44
            },
            {
                "price": 12881.62,
                "ask": 0,
                "bid": 52
            },
            {
                "price": 12782.46,
                "ask": 27,
                "bid": 75
            },
            {
                "price": 12986.98,
                "ask": 24,
                "bid": 7
            },
            {
                "price": 12972.48,
                "ask": 93,
                "bid": 41
            },
            {
                "price": 12737.52,
                "ask": 27,
                "bid": 39
            },
            {
                "price": 12848.58,
                "ask": 87,
                "bid": 10
            },
            {
                "price": 12809.11,
                "ask": 99,
                "bid": 70
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc82333dc00462f729",
        "high": 12854.81,
        "open": 12901.63,
        "close": 12970.79,
        "low": 12714.96,
        "priceList": [
            {
                "price": 12914.27,
                "ask": 72,
                "bid": 62
            },
            {
                "price": 12886.38,
                "ask": 95,
                "bid": 85
            },
            {
                "price": 12853.15,
                "ask": 53,
                "bid": 18
            },
            {
                "price": 12965.42,
                "ask": 97,
                "bid": 61
            },
            {
                "price": 12788.75,
                "ask": 68,
                "bid": 87
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcd5189b51c9920114",
        "high": 12996.86,
        "open": 12973.97,
        "close": 12782.71,
        "low": 12881.95,
        "priceList": [
            {
                "price": 12846.98,
                "ask": 68,
                "bid": 99
            },
            {
                "price": 12755.6,
                "ask": 0,
                "bid": 30
            },
            {
                "price": 12830.09,
                "ask": 38,
                "bid": 84
            },
            {
                "price": 12924.51,
                "ask": 49,
                "bid": 16
            },
            {
                "price": 12767.05,
                "ask": 33,
                "bid": 26
            },
            {
                "price": 12984.77,
                "ask": 53,
                "bid": 2
            },
            {
                "price": 12959.61,
                "ask": 78,
                "bid": 5
            },
            {
                "price": 12990.36,
                "ask": 95,
                "bid": 26
            },
            {
                "price": 12716.08,
                "ask": 34,
                "bid": 73
            },
            {
                "price": 12801.88,
                "ask": 66,
                "bid": 32
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc7858310a9dd37838",
        "high": 12948.71,
        "open": 12928.71,
        "close": 12777.95,
        "low": 12860.33,
        "priceList": [
            {
                "price": 12890.05,
                "ask": 81,
                "bid": 95
            },
            {
                "price": 12757.79,
                "ask": 66,
                "bid": 99
            },
            {
                "price": 12943.22,
                "ask": 61,
                "bid": 57
            },
            {
                "price": 12921.95,
                "ask": 68,
                "bid": 16
            },
            {
                "price": 12747.51,
                "ask": 53,
                "bid": 0
            },
            {
                "price": 12732.02,
                "ask": 44,
                "bid": 84
            },
            {
                "price": 12916.22,
                "ask": 73,
                "bid": 9
            },
            {
                "price": 12941.28,
                "ask": 73,
                "bid": 49
            },
            {
                "price": 12872.4,
                "ask": 36,
                "bid": 7
            },
            {
                "price": 12875.19,
                "ask": 77,
                "bid": 23
            },
            {
                "price": 12733.13,
                "ask": 40,
                "bid": 29
            },
            {
                "price": 12706.85,
                "ask": 22,
                "bid": 48
            },
            {
                "price": 12813.62,
                "ask": 45,
                "bid": 92
            },
            {
                "price": 12896.19,
                "ask": 45,
                "bid": 39
            },
            {
                "price": 12950.52,
                "ask": 61,
                "bid": 3
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc0efe071614773e3e",
        "high": 12907.44,
        "open": 12723.58,
        "close": 12773.08,
        "low": 12868.89,
        "priceList": [
            {
                "price": 12978.81,
                "ask": 90,
                "bid": 10
            },
            {
                "price": 12882.94,
                "ask": 24,
                "bid": 63
            },
            {
                "price": 12969.48,
                "ask": 97,
                "bid": 36
            },
            {
                "price": 12956.21,
                "ask": 22,
                "bid": 47
            },
            {
                "price": 12941.01,
                "ask": 83,
                "bid": 34
            },
            {
                "price": 12828.36,
                "ask": 94,
                "bid": 22
            },
            {
                "price": 12778.31,
                "ask": 39,
                "bid": 35
            },
            {
                "price": 12886.34,
                "ask": 82,
                "bid": 98
            },
            {
                "price": 12838.28,
                "ask": 79,
                "bid": 100
            },
            {
                "price": 12905.65,
                "ask": 57,
                "bid": 21
            },
            {
                "price": 12815.75,
                "ask": 40,
                "bid": 83
            },
            {
                "price": 12736.3,
                "ask": 46,
                "bid": 27
            },
            {
                "price": 12776.58,
                "ask": 76,
                "bid": 26
            },
            {
                "price": 12996.33,
                "ask": 9,
                "bid": 23
            },
            {
                "price": 12724.55,
                "ask": 76,
                "bid": 38
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc464c9ceeddb53d30",
        "high": 12994.25,
        "open": 12830.59,
        "close": 12900.26,
        "low": 12741.65,
        "priceList": [
            {
                "price": 12792.92,
                "ask": 21,
                "bid": 10
            },
            {
                "price": 12788.33,
                "ask": 2,
                "bid": 11
            },
            {
                "price": 12703.99,
                "ask": 74,
                "bid": 12
            },
            {
                "price": 12974.69,
                "ask": 11,
                "bid": 33
            },
            {
                "price": 12740.14,
                "ask": 11,
                "bid": 58
            },
            {
                "price": 12894.14,
                "ask": 5,
                "bid": 75
            },
            {
                "price": 12869.46,
                "ask": 59,
                "bid": 45
            },
            {
                "price": 12826.86,
                "ask": 96,
                "bid": 77
            },
            {
                "price": 12867.48,
                "ask": 16,
                "bid": 99
            },
            {
                "price": 12800.96,
                "ask": 7,
                "bid": 74
            },
            {
                "price": 12817.26,
                "ask": 94,
                "bid": 20
            },
            {
                "price": 12944.4,
                "ask": 12,
                "bid": 27
            },
            {
                "price": 12913.93,
                "ask": 59,
                "bid": 43
            },
            {
                "price": 12816.45,
                "ask": 47,
                "bid": 16
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcfa10b03ac28625fb",
        "high": 12844.22,
        "open": 12924.69,
        "close": 12869.33,
        "low": 12825.77,
        "priceList": [
            {
                "price": 12729.48,
                "ask": 66,
                "bid": 10
            },
            {
                "price": 12779.92,
                "ask": 13,
                "bid": 85
            },
            {
                "price": 12969.66,
                "ask": 24,
                "bid": 59
            },
            {
                "price": 12826.43,
                "ask": 47,
                "bid": 55
            },
            {
                "price": 12715.52,
                "ask": 34,
                "bid": 11
            },
            {
                "price": 12785.22,
                "ask": 22,
                "bid": 8
            },
            {
                "price": 12707.85,
                "ask": 5,
                "bid": 52
            },
            {
                "price": 12896.95,
                "ask": 60,
                "bid": 75
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc653e2f5ebf06e5ca",
        "high": 12848.72,
        "open": 12967.92,
        "close": 12871.91,
        "low": 12959.69,
        "priceList": [
            {
                "price": 12976.37,
                "ask": 29,
                "bid": 32
            },
            {
                "price": 12716.48,
                "ask": 43,
                "bid": 94
            },
            {
                "price": 12762.5,
                "ask": 24,
                "bid": 67
            },
            {
                "price": 12863.73,
                "ask": 69,
                "bid": 35
            },
            {
                "price": 12855.6,
                "ask": 5,
                "bid": 19
            },
            {
                "price": 12810.95,
                "ask": 81,
                "bid": 90
            },
            {
                "price": 12737.71,
                "ask": 60,
                "bid": 89
            },
            {
                "price": 12891.52,
                "ask": 81,
                "bid": 16
            },
            {
                "price": 12997.04,
                "ask": 22,
                "bid": 56
            },
            {
                "price": 12799.9,
                "ask": 10,
                "bid": 11
            },
            {
                "price": 12927.8,
                "ask": 92,
                "bid": 16
            },
            {
                "price": 12916.01,
                "ask": 95,
                "bid": 10
            },
            {
                "price": 12847.4,
                "ask": 59,
                "bid": 93
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcf7aaad1ceaab8e94",
        "high": 12744.97,
        "open": 12712.79,
        "close": 12985.06,
        "low": 12985.41,
        "priceList": [
            {
                "price": 12885.17,
                "ask": 0,
                "bid": 67
            },
            {
                "price": 12757.72,
                "ask": 28,
                "bid": 74
            },
            {
                "price": 12849.89,
                "ask": 64,
                "bid": 1
            },
            {
                "price": 12849.42,
                "ask": 7,
                "bid": 92
            },
            {
                "price": 12943.53,
                "ask": 73,
                "bid": 44
            },
            {
                "price": 12723.01,
                "ask": 27,
                "bid": 72
            },
            {
                "price": 12752.71,
                "ask": 64,
                "bid": 61
            },
            {
                "price": 12973.36,
                "ask": 38,
                "bid": 37
            },
            {
                "price": 12756.2,
                "ask": 87,
                "bid": 100
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc9fc3fde3d1f64647",
        "high": 12765.88,
        "open": 12738.53,
        "close": 12709.43,
        "low": 12935.16,
        "priceList": [
            {
                "price": 12751.49,
                "ask": 69,
                "bid": 29
            },
            {
                "price": 12893.44,
                "ask": 50,
                "bid": 92
            },
            {
                "price": 12879.72,
                "ask": 72,
                "bid": 21
            },
            {
                "price": 12747.8,
                "ask": 8,
                "bid": 11
            },
            {
                "price": 12706.81,
                "ask": 18,
                "bid": 46
            },
            {
                "price": 12716.93,
                "ask": 10,
                "bid": 28
            },
            {
                "price": 12982.95,
                "ask": 40,
                "bid": 89
            },
            {
                "price": 12865.05,
                "ask": 69,
                "bid": 48
            },
            {
                "price": 12876.78,
                "ask": 46,
                "bid": 82
            },
            {
                "price": 12719.18,
                "ask": 52,
                "bid": 66
            },
            {
                "price": 12828.79,
                "ask": 67,
                "bid": 8
            },
            {
                "price": 12858.76,
                "ask": 39,
                "bid": 53
            },
            {
                "price": 12760.11,
                "ask": 70,
                "bid": 64
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcd06b0c2785526252",
        "high": 12855.58,
        "open": 12906.01,
        "close": 12872.02,
        "low": 12880.87,
        "priceList": [
            {
                "price": 12757.39,
                "ask": 83,
                "bid": 87
            },
            {
                "price": 12986.54,
                "ask": 53,
                "bid": 0
            },
            {
                "price": 12893.15,
                "ask": 28,
                "bid": 69
            },
            {
                "price": 12740.61,
                "ask": 14,
                "bid": 49
            },
            {
                "price": 12756.69,
                "ask": 30,
                "bid": 72
            },
            {
                "price": 12766.12,
                "ask": 100,
                "bid": 69
            },
            {
                "price": 12843.88,
                "ask": 75,
                "bid": 24
            },
            {
                "price": 12709.61,
                "ask": 49,
                "bid": 37
            },
            {
                "price": 12963.27,
                "ask": 89,
                "bid": 41
            },
            {
                "price": 12733.35,
                "ask": 71,
                "bid": 32
            },
            {
                "price": 12923.07,
                "ask": 60,
                "bid": 68
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc70adeb0091c9e057",
        "high": 12868.77,
        "open": 12953.25,
        "close": 12825.23,
        "low": 12980.73,
        "priceList": [
            {
                "price": 12999.46,
                "ask": 60,
                "bid": 62
            },
            {
                "price": 12980.78,
                "ask": 43,
                "bid": 31
            },
            {
                "price": 12721.19,
                "ask": 45,
                "bid": 81
            },
            {
                "price": 12894.51,
                "ask": 33,
                "bid": 37
            },
            {
                "price": 12754.25,
                "ask": 61,
                "bid": 29
            },
            {
                "price": 12735.49,
                "ask": 28,
                "bid": 65
            },
            {
                "price": 12972.62,
                "ask": 77,
                "bid": 31
            },
            {
                "price": 12702.8,
                "ask": 6,
                "bid": 81
            },
            {
                "price": 12860.04,
                "ask": 95,
                "bid": 75
            },
            {
                "price": 12940.32,
                "ask": 5,
                "bid": 13
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc422587a6eb978428",
        "high": 12994.24,
        "open": 12774.76,
        "close": 12986.68,
        "low": 12869.17,
        "priceList": [
            {
                "price": 12931.43,
                "ask": 87,
                "bid": 86
            },
            {
                "price": 12995.49,
                "ask": 22,
                "bid": 1
            },
            {
                "price": 12943.63,
                "ask": 2,
                "bid": 64
            },
            {
                "price": 12776.85,
                "ask": 17,
                "bid": 95
            },
            {
                "price": 12744.01,
                "ask": 56,
                "bid": 43
            },
            {
                "price": 12921.35,
                "ask": 69,
                "bid": 66
            },
            {
                "price": 12967.61,
                "ask": 25,
                "bid": 31
            },
            {
                "price": 12930.41,
                "ask": 78,
                "bid": 11
            },
            {
                "price": 12839.08,
                "ask": 3,
                "bid": 84
            },
            {
                "price": 12887.62,
                "ask": 24,
                "bid": 57
            },
            {
                "price": 12718.79,
                "ask": 17,
                "bid": 9
            },
            {
                "price": 12795.64,
                "ask": 92,
                "bid": 54
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc29392cb8f82c0a5e",
        "high": 12875.17,
        "open": 12807.78,
        "close": 12990.26,
        "low": 12760.67,
        "priceList": [
            {
                "price": 12782.97,
                "ask": 74,
                "bid": 10
            },
            {
                "price": 12700.63,
                "ask": 16,
                "bid": 47
            },
            {
                "price": 12811.89,
                "ask": 10,
                "bid": 70
            },
            {
                "price": 12758.71,
                "ask": 36,
                "bid": 6
            },
            {
                "price": 12736.52,
                "ask": 73,
                "bid": 14
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcdd25ffd494de7138",
        "high": 12983.92,
        "open": 12753.57,
        "close": 12764.37,
        "low": 12809.02,
        "priceList": [
            {
                "price": 12809.52,
                "ask": 91,
                "bid": 81
            },
            {
                "price": 12826.51,
                "ask": 45,
                "bid": 1
            },
            {
                "price": 12734.59,
                "ask": 18,
                "bid": 13
            },
            {
                "price": 12834.76,
                "ask": 86,
                "bid": 51
            },
            {
                "price": 12719.63,
                "ask": 72,
                "bid": 2
            },
            {
                "price": 12973.63,
                "ask": 62,
                "bid": 48
            },
            {
                "price": 12871.99,
                "ask": 76,
                "bid": 19
            },
            {
                "price": 12893.85,
                "ask": 31,
                "bid": 79
            },
            {
                "price": 12799.81,
                "ask": 49,
                "bid": 15
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc880dac81dc9233a1",
        "high": 12789.52,
        "open": 12791.82,
        "close": 12973.6,
        "low": 12931.24,
        "priceList": [
            {
                "price": 12884.19,
                "ask": 81,
                "bid": 99
            },
            {
                "price": 12704.22,
                "ask": 77,
                "bid": 61
            },
            {
                "price": 12769.33,
                "ask": 41,
                "bid": 27
            },
            {
                "price": 12818.24,
                "ask": 54,
                "bid": 95
            },
            {
                "price": 12708,
                "ask": 91,
                "bid": 39
            },
            {
                "price": 12860.39,
                "ask": 100,
                "bid": 34
            },
            {
                "price": 12876.63,
                "ask": 2,
                "bid": 77
            },
            {
                "price": 12816.22,
                "ask": 83,
                "bid": 82
            },
            {
                "price": 12894.28,
                "ask": 82,
                "bid": 96
            },
            {
                "price": 12912.53,
                "ask": 8,
                "bid": 21
            },
            {
                "price": 12961.77,
                "ask": 55,
                "bid": 37
            },
            {
                "price": 12775.91,
                "ask": 91,
                "bid": 41
            },
            {
                "price": 12828.87,
                "ask": 94,
                "bid": 71
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc7195f21e54c774ae",
        "high": 12826.33,
        "open": 12765.95,
        "close": 12960.78,
        "low": 12893.24,
        "priceList": [
            {
                "price": 12887.49,
                "ask": 85,
                "bid": 60
            },
            {
                "price": 12766.36,
                "ask": 37,
                "bid": 92
            },
            {
                "price": 12942.33,
                "ask": 16,
                "bid": 22
            },
            {
                "price": 12958.68,
                "ask": 17,
                "bid": 95
            },
            {
                "price": 12835.94,
                "ask": 90,
                "bid": 46
            },
            {
                "price": 12990.93,
                "ask": 34,
                "bid": 52
            },
            {
                "price": 12977.79,
                "ask": 39,
                "bid": 25
            },
            {
                "price": 12730.18,
                "ask": 89,
                "bid": 20
            },
            {
                "price": 12791.49,
                "ask": 64,
                "bid": 51
            },
            {
                "price": 12832.99,
                "ask": 94,
                "bid": 17
            },
            {
                "price": 12752.11,
                "ask": 22,
                "bid": 82
            },
            {
                "price": 12950.31,
                "ask": 12,
                "bid": 62
            },
            {
                "price": 12963.31,
                "ask": 9,
                "bid": 25
            },
            {
                "price": 12995.68,
                "ask": 38,
                "bid": 11
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc533a1d9c0bf10492",
        "high": 12738.49,
        "open": 12759.63,
        "close": 12752.91,
        "low": 12745.47,
        "priceList": [
            {
                "price": 12958.67,
                "ask": 78,
                "bid": 58
            },
            {
                "price": 12833.74,
                "ask": 24,
                "bid": 48
            },
            {
                "price": 12870.32,
                "ask": 12,
                "bid": 11
            },
            {
                "price": 12916.57,
                "ask": 28,
                "bid": 51
            },
            {
                "price": 12872.62,
                "ask": 81,
                "bid": 49
            },
            {
                "price": 12839.34,
                "ask": 74,
                "bid": 97
            },
            {
                "price": 12874.91,
                "ask": 72,
                "bid": 81
            },
            {
                "price": 12779.36,
                "ask": 33,
                "bid": 90
            },
            {
                "price": 12792.06,
                "ask": 2,
                "bid": 39
            },
            {
                "price": 12962.95,
                "ask": 86,
                "bid": 56
            },
            {
                "price": 12956.74,
                "ask": 19,
                "bid": 39
            },
            {
                "price": 12923.1,
                "ask": 83,
                "bid": 100
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc5f74d3273b75e6f0",
        "high": 12880.99,
        "open": 12909.25,
        "close": 12862.55,
        "low": 12849.3,
        "priceList": [
            {
                "price": 12835.66,
                "ask": 95,
                "bid": 25
            },
            {
                "price": 12814.71,
                "ask": 7,
                "bid": 27
            },
            {
                "price": 12818.98,
                "ask": 84,
                "bid": 74
            },
            {
                "price": 12798.91,
                "ask": 82,
                "bid": 59
            },
            {
                "price": 12832.8,
                "ask": 39,
                "bid": 37
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc0a468bf4bed30407",
        "high": 12966.49,
        "open": 12943.21,
        "close": 12706.89,
        "low": 12800.77,
        "priceList": [
            {
                "price": 12727.02,
                "ask": 37,
                "bid": 73
            },
            {
                "price": 12727.86,
                "ask": 99,
                "bid": 3
            },
            {
                "price": 12951.75,
                "ask": 99,
                "bid": 1
            },
            {
                "price": 12705.65,
                "ask": 11,
                "bid": 94
            },
            {
                "price": 12759.06,
                "ask": 86,
                "bid": 60
            },
            {
                "price": 12928.14,
                "ask": 49,
                "bid": 68
            },
            {
                "price": 12726.38,
                "ask": 62,
                "bid": 29
            },
            {
                "price": 12774.41,
                "ask": 58,
                "bid": 32
            },
            {
                "price": 12986.25,
                "ask": 5,
                "bid": 15
            },
            {
                "price": 12826.37,
                "ask": 14,
                "bid": 83
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc1eb1d83988379438",
        "high": 12762.84,
        "open": 12826.19,
        "close": 12994.65,
        "low": 12776.98,
        "priceList": [
            {
                "price": 12722.47,
                "ask": 2,
                "bid": 10
            },
            {
                "price": 12792.64,
                "ask": 48,
                "bid": 91
            },
            {
                "price": 12877.52,
                "ask": 42,
                "bid": 78
            },
            {
                "price": 12706.76,
                "ask": 96,
                "bid": 89
            },
            {
                "price": 12846.95,
                "ask": 81,
                "bid": 98
            },
            {
                "price": 12759.01,
                "ask": 96,
                "bid": 5
            },
            {
                "price": 12878.85,
                "ask": 65,
                "bid": 41
            },
            {
                "price": 12752.49,
                "ask": 10,
                "bid": 84
            },
            {
                "price": 12946.67,
                "ask": 6,
                "bid": 84
            },
            {
                "price": 12922.57,
                "ask": 6,
                "bid": 8
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcf1e46d9c4469469a",
        "high": 12750.22,
        "open": 12840.81,
        "close": 12837.83,
        "low": 12952.82,
        "priceList": [
            {
                "price": 12984.02,
                "ask": 98,
                "bid": 60
            },
            {
                "price": 12774.53,
                "ask": 27,
                "bid": 92
            },
            {
                "price": 12896.46,
                "ask": 23,
                "bid": 91
            },
            {
                "price": 12769.72,
                "ask": 17,
                "bid": 75
            },
            {
                "price": 12728.16,
                "ask": 35,
                "bid": 7
            },
            {
                "price": 12977.43,
                "ask": 34,
                "bid": 19
            },
            {
                "price": 12728.35,
                "ask": 38,
                "bid": 93
            },
            {
                "price": 12897.39,
                "ask": 3,
                "bid": 85
            },
            {
                "price": 12970.76,
                "ask": 68,
                "bid": 37
            },
            {
                "price": 12948.89,
                "ask": 92,
                "bid": 55
            },
            {
                "price": 12702.09,
                "ask": 78,
                "bid": 42
            },
            {
                "price": 12971.7,
                "ask": 3,
                "bid": 10
            },
            {
                "price": 12748.23,
                "ask": 59,
                "bid": 37
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc681b4c910ad064b",
        "high": 12778.98,
        "open": 12973.88,
        "close": 12967.82,
        "low": 12829.19,
        "priceList": [
            {
                "price": 12825.63,
                "ask": 21,
                "bid": 30
            },
            {
                "price": 12804.07,
                "ask": 55,
                "bid": 8
            },
            {
                "price": 12924.41,
                "ask": 75,
                "bid": 56
            },
            {
                "price": 12880.42,
                "ask": 69,
                "bid": 77
            },
            {
                "price": 12891.26,
                "ask": 9,
                "bid": 72
            },
            {
                "price": 12818.08,
                "ask": 39,
                "bid": 87
            },
            {
                "price": 12840.89,
                "ask": 14,
                "bid": 58
            },
            {
                "price": 12893.15,
                "ask": 78,
                "bid": 82
            },
            {
                "price": 12865.22,
                "ask": 28,
                "bid": 56
            },
            {
                "price": 12736.66,
                "ask": 86,
                "bid": 50
            },
            {
                "price": 12839.4,
                "ask": 94,
                "bid": 81
            },
            {
                "price": 12778.98,
                "ask": 55,
                "bid": 32
            },
            {
                "price": 12777.57,
                "ask": 29,
                "bid": 76
            },
            {
                "price": 12956.5,
                "ask": 84,
                "bid": 47
            },
            {
                "price": 12702.16,
                "ask": 50,
                "bid": 18
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcc8f9736db6b20538",
        "high": 12897.02,
        "open": 12835.62,
        "close": 12814.75,
        "low": 12956.45,
        "priceList": [
            {
                "price": 12908.07,
                "ask": 24,
                "bid": 14
            },
            {
                "price": 12714.29,
                "ask": 86,
                "bid": 49
            },
            {
                "price": 12855.76,
                "ask": 60,
                "bid": 100
            },
            {
                "price": 12956.15,
                "ask": 24,
                "bid": 58
            },
            {
                "price": 12829.97,
                "ask": 31,
                "bid": 12
            },
            {
                "price": 12725.69,
                "ask": 72,
                "bid": 66
            },
            {
                "price": 12949.68,
                "ask": 15,
                "bid": 63
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc4192ca80d4cb862a",
        "high": 12836.29,
        "open": 12706.17,
        "close": 12800.52,
        "low": 12882.75,
        "priceList": [
            {
                "price": 12790.69,
                "ask": 27,
                "bid": 86
            },
            {
                "price": 12977.38,
                "ask": 78,
                "bid": 60
            },
            {
                "price": 12867.39,
                "ask": 16,
                "bid": 18
            },
            {
                "price": 12805.99,
                "ask": 69,
                "bid": 11
            },
            {
                "price": 12954.51,
                "ask": 4,
                "bid": 77
            },
            {
                "price": 12788.23,
                "ask": 17,
                "bid": 33
            },
            {
                "price": 12733.88,
                "ask": 56,
                "bid": 44
            },
            {
                "price": 12986.72,
                "ask": 89,
                "bid": 55
            },
            {
                "price": 12730.06,
                "ask": 47,
                "bid": 47
            },
            {
                "price": 12872.23,
                "ask": 68,
                "bid": 89
            },
            {
                "price": 12723.32,
                "ask": 12,
                "bid": 90
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcad051b05f71fc9a1",
        "high": 12987.72,
        "open": 12985.69,
        "close": 12996.73,
        "low": 12874.02,
        "priceList": [
            {
                "price": 12792.65,
                "ask": 53,
                "bid": 14
            },
            {
                "price": 12872.47,
                "ask": 36,
                "bid": 0
            },
            {
                "price": 12901.95,
                "ask": 72,
                "bid": 35
            },
            {
                "price": 12902.96,
                "ask": 26,
                "bid": 11
            },
            {
                "price": 12710.3,
                "ask": 96,
                "bid": 58
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcaf4145e106975aa5",
        "high": 12871.29,
        "open": 12864.31,
        "close": 12751.52,
        "low": 12874.44,
        "priceList": [
            {
                "price": 12747.42,
                "ask": 46,
                "bid": 82
            },
            {
                "price": 12712.06,
                "ask": 41,
                "bid": 54
            },
            {
                "price": 12912.07,
                "ask": 85,
                "bid": 18
            },
            {
                "price": 12951.47,
                "ask": 72,
                "bid": 100
            },
            {
                "price": 12738.94,
                "ask": 27,
                "bid": 87
            },
            {
                "price": 12875.17,
                "ask": 46,
                "bid": 90
            },
            {
                "price": 12879,
                "ask": 97,
                "bid": 3
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc11f4097c13998623",
        "high": 12894.92,
        "open": 12864.74,
        "close": 12819.47,
        "low": 12982.42,
        "priceList": [
            {
                "price": 12721.14,
                "ask": 17,
                "bid": 37
            },
            {
                "price": 12749.1,
                "ask": 76,
                "bid": 100
            },
            {
                "price": 12807.26,
                "ask": 79,
                "bid": 14
            },
            {
                "price": 12897.83,
                "ask": 0,
                "bid": 3
            },
            {
                "price": 12880.1,
                "ask": 0,
                "bid": 35
            },
            {
                "price": 12800.81,
                "ask": 21,
                "bid": 11
            },
            {
                "price": 12902.34,
                "ask": 84,
                "bid": 45
            },
            {
                "price": 12900.53,
                "ask": 63,
                "bid": 76
            },
            {
                "price": 12752,
                "ask": 42,
                "bid": 48
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcca8c57c34c3d3aa7",
        "high": 12779.44,
        "open": 12948.92,
        "close": 12990.35,
        "low": 12899.77,
        "priceList": [
            {
                "price": 12838.22,
                "ask": 45,
                "bid": 86
            },
            {
                "price": 12951.73,
                "ask": 14,
                "bid": 43
            },
            {
                "price": 12767.26,
                "ask": 59,
                "bid": 7
            },
            {
                "price": 12734.38,
                "ask": 28,
                "bid": 5
            },
            {
                "price": 12920.68,
                "ask": 30,
                "bid": 74
            },
            {
                "price": 12996.19,
                "ask": 10,
                "bid": 11
            },
            {
                "price": 12713.54,
                "ask": 8,
                "bid": 87
            },
            {
                "price": 12807.97,
                "ask": 71,
                "bid": 50
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcaf39ee2e750efbf2",
        "high": 12770.9,
        "open": 12866.25,
        "close": 12730.77,
        "low": 12815.97,
        "priceList": [
            {
                "price": 12948.91,
                "ask": 40,
                "bid": 27
            },
            {
                "price": 12899.76,
                "ask": 24,
                "bid": 71
            },
            {
                "price": 12719.6,
                "ask": 52,
                "bid": 14
            },
            {
                "price": 12714.01,
                "ask": 47,
                "bid": 81
            },
            {
                "price": 12855.16,
                "ask": 64,
                "bid": 15
            },
            {
                "price": 12971.13,
                "ask": 51,
                "bid": 97
            },
            {
                "price": 12785.84,
                "ask": 26,
                "bid": 36
            },
            {
                "price": 12911.54,
                "ask": 60,
                "bid": 53
            },
            {
                "price": 12909.35,
                "ask": 21,
                "bid": 8
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcf791376ff89a4cb4",
        "high": 12708.29,
        "open": 12908.45,
        "close": 12891.89,
        "low": 12931.32,
        "priceList": [
            {
                "price": 12877.53,
                "ask": 89,
                "bid": 12
            },
            {
                "price": 12973.2,
                "ask": 18,
                "bid": 2
            },
            {
                "price": 12729.04,
                "ask": 6,
                "bid": 18
            },
            {
                "price": 12905.22,
                "ask": 65,
                "bid": 60
            },
            {
                "price": 12840.34,
                "ask": 51,
                "bid": 19
            },
            {
                "price": 12929.14,
                "ask": 90,
                "bid": 25
            },
            {
                "price": 12799.92,
                "ask": 6,
                "bid": 26
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc7f8725ae8ac69fdc",
        "high": 12985.18,
        "open": 12806.94,
        "close": 12782.65,
        "low": 12859.39,
        "priceList": [
            {
                "price": 12942.07,
                "ask": 44,
                "bid": 34
            },
            {
                "price": 12964.1,
                "ask": 46,
                "bid": 87
            },
            {
                "price": 12762.84,
                "ask": 14,
                "bid": 53
            },
            {
                "price": 12815.92,
                "ask": 63,
                "bid": 5
            },
            {
                "price": 12807.22,
                "ask": 2,
                "bid": 33
            },
            {
                "price": 12853.74,
                "ask": 78,
                "bid": 18
            },
            {
                "price": 12956.87,
                "ask": 3,
                "bid": 51
            },
            {
                "price": 12816.51,
                "ask": 30,
                "bid": 48
            },
            {
                "price": 12929.43,
                "ask": 86,
                "bid": 73
            },
            {
                "price": 12732.21,
                "ask": 66,
                "bid": 22
            },
            {
                "price": 12706.8,
                "ask": 37,
                "bid": 18
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcda6b6e8617e7e962",
        "high": 12707.91,
        "open": 12896.28,
        "close": 12728.32,
        "low": 12944.32,
        "priceList": [
            {
                "price": 12746.38,
                "ask": 42,
                "bid": 7
            },
            {
                "price": 12946.93,
                "ask": 53,
                "bid": 57
            },
            {
                "price": 12755.81,
                "ask": 29,
                "bid": 27
            },
            {
                "price": 12739.88,
                "ask": 85,
                "bid": 34
            },
            {
                "price": 12940.82,
                "ask": 22,
                "bid": 54
            },
            {
                "price": 12793.78,
                "ask": 26,
                "bid": 70
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc4a807dd7e1ad5e75",
        "high": 12972.96,
        "open": 12971.26,
        "close": 12854.08,
        "low": 12733.75,
        "priceList": [
            {
                "price": 12960.12,
                "ask": 100,
                "bid": 27
            },
            {
                "price": 12969.64,
                "ask": 40,
                "bid": 15
            },
            {
                "price": 12901.03,
                "ask": 32,
                "bid": 76
            },
            {
                "price": 12858.94,
                "ask": 16,
                "bid": 48
            },
            {
                "price": 12714.05,
                "ask": 0,
                "bid": 24
            },
            {
                "price": 12856.57,
                "ask": 27,
                "bid": 75
            },
            {
                "price": 12821.47,
                "ask": 74,
                "bid": 14
            },
            {
                "price": 12906.11,
                "ask": 66,
                "bid": 84
            },
            {
                "price": 12983.39,
                "ask": 7,
                "bid": 87
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc0cfc67bf6353f31f",
        "high": 12938.69,
        "open": 12711.34,
        "close": 12898.34,
        "low": 12810.73,
        "priceList": [
            {
                "price": 12879.57,
                "ask": 27,
                "bid": 75
            },
            {
                "price": 12984.7,
                "ask": 44,
                "bid": 69
            },
            {
                "price": 12769.4,
                "ask": 31,
                "bid": 7
            },
            {
                "price": 12717.45,
                "ask": 54,
                "bid": 97
            },
            {
                "price": 12764.72,
                "ask": 53,
                "bid": 33
            },
            {
                "price": 12822.61,
                "ask": 31,
                "bid": 63
            },
            {
                "price": 12929.68,
                "ask": 60,
                "bid": 6
            },
            {
                "price": 12779.34,
                "ask": 21,
                "bid": 30
            },
            {
                "price": 12859.83,
                "ask": 69,
                "bid": 79
            },
            {
                "price": 12717.06,
                "ask": 50,
                "bid": 30
            },
            {
                "price": 12824.41,
                "ask": 39,
                "bid": 18
            },
            {
                "price": 12983.35,
                "ask": 96,
                "bid": 37
            },
            {
                "price": 12749.91,
                "ask": 15,
                "bid": 0
            },
            {
                "price": 12998.54,
                "ask": 62,
                "bid": 36
            },
            {
                "price": 12873.25,
                "ask": 28,
                "bid": 28
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc4c030878cf7e8e4c",
        "high": 12928.05,
        "open": 12957.25,
        "close": 12872.53,
        "low": 12724.48,
        "priceList": [
            {
                "price": 12838.15,
                "ask": 37,
                "bid": 73
            },
            {
                "price": 12742.85,
                "ask": 14,
                "bid": 54
            },
            {
                "price": 12713.96,
                "ask": 54,
                "bid": 90
            },
            {
                "price": 12867.95,
                "ask": 79,
                "bid": 42
            },
            {
                "price": 12957.86,
                "ask": 55,
                "bid": 10
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc3f514ab4249b9288",
        "high": 12872.96,
        "open": 12701.32,
        "close": 12938.52,
        "low": 12862.75,
        "priceList": [
            {
                "price": 12787.07,
                "ask": 24,
                "bid": 74
            },
            {
                "price": 12993.63,
                "ask": 95,
                "bid": 34
            },
            {
                "price": 12981.32,
                "ask": 50,
                "bid": 11
            },
            {
                "price": 12717.97,
                "ask": 28,
                "bid": 26
            },
            {
                "price": 12990.1,
                "ask": 33,
                "bid": 36
            },
            {
                "price": 12732.71,
                "ask": 35,
                "bid": 33
            },
            {
                "price": 12759.52,
                "ask": 80,
                "bid": 25
            },
            {
                "price": 12901.76,
                "ask": 21,
                "bid": 50
            },
            {
                "price": 12959.46,
                "ask": 27,
                "bid": 82
            },
            {
                "price": 12770.91,
                "ask": 96,
                "bid": 86
            },
            {
                "price": 12789.78,
                "ask": 98,
                "bid": 37
            },
            {
                "price": 12851.13,
                "ask": 11,
                "bid": 80
            },
            {
                "price": 12940.24,
                "ask": 33,
                "bid": 81
            },
            {
                "price": 12720.49,
                "ask": 94,
                "bid": 36
            },
            {
                "price": 12834.17,
                "ask": 29,
                "bid": 84
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dce56f4efb64acc1dd",
        "high": 12913.53,
        "open": 12977.63,
        "close": 12868.58,
        "low": 12874.85,
        "priceList": [
            {
                "price": 12801.36,
                "ask": 44,
                "bid": 24
            },
            {
                "price": 12914.5,
                "ask": 38,
                "bid": 15
            },
            {
                "price": 12899.85,
                "ask": 83,
                "bid": 100
            },
            {
                "price": 12755.49,
                "ask": 2,
                "bid": 54
            },
            {
                "price": 12871.25,
                "ask": 66,
                "bid": 43
            },
            {
                "price": 12754.81,
                "ask": 40,
                "bid": 82
            },
            {
                "price": 12855.94,
                "ask": 67,
                "bid": 20
            },
            {
                "price": 12843.64,
                "ask": 69,
                "bid": 39
            },
            {
                "price": 12729.85,
                "ask": 10,
                "bid": 48
            },
            {
                "price": 12831.04,
                "ask": 94,
                "bid": 4
            },
            {
                "price": 12937.73,
                "ask": 50,
                "bid": 28
            },
            {
                "price": 12938.57,
                "ask": 25,
                "bid": 73
            },
            {
                "price": 12992.53,
                "ask": 72,
                "bid": 86
            },
            {
                "price": 12717.47,
                "ask": 21,
                "bid": 60
            },
            {
                "price": 12723.71,
                "ask": 90,
                "bid": 98
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc66bc5c0e5480619a",
        "high": 12711.38,
        "open": 12863.5,
        "close": 12971.56,
        "low": 12867.81,
        "priceList": [
            {
                "price": 12851.75,
                "ask": 88,
                "bid": 94
            },
            {
                "price": 12834.79,
                "ask": 55,
                "bid": 65
            },
            {
                "price": 12779.68,
                "ask": 50,
                "bid": 35
            },
            {
                "price": 12968.42,
                "ask": 85,
                "bid": 29
            },
            {
                "price": 12923.67,
                "ask": 50,
                "bid": 22
            },
            {
                "price": 12862.84,
                "ask": 1,
                "bid": 71
            },
            {
                "price": 12751.28,
                "ask": 26,
                "bid": 49
            },
            {
                "price": 12999.86,
                "ask": 53,
                "bid": 28
            },
            {
                "price": 12968.54,
                "ask": 23,
                "bid": 6
            },
            {
                "price": 12757.9,
                "ask": 58,
                "bid": 76
            },
            {
                "price": 12890.36,
                "ask": 14,
                "bid": 50
            },
            {
                "price": 12729.91,
                "ask": 88,
                "bid": 43
            },
            {
                "price": 12832.94,
                "ask": 14,
                "bid": 34
            },
            {
                "price": 12801.85,
                "ask": 25,
                "bid": 72
            },
            {
                "price": 12755.9,
                "ask": 74,
                "bid": 39
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcb51f6861af7094fd",
        "high": 12744.76,
        "open": 12813.81,
        "close": 12993.47,
        "low": 12800.55,
        "priceList": [
            {
                "price": 12749.76,
                "ask": 54,
                "bid": 5
            },
            {
                "price": 12736.52,
                "ask": 83,
                "bid": 28
            },
            {
                "price": 12738.62,
                "ask": 14,
                "bid": 50
            },
            {
                "price": 12734.11,
                "ask": 90,
                "bid": 65
            },
            {
                "price": 12716.99,
                "ask": 15,
                "bid": 94
            },
            {
                "price": 12847.39,
                "ask": 30,
                "bid": 32
            },
            {
                "price": 12825.91,
                "ask": 96,
                "bid": 11
            },
            {
                "price": 12808.57,
                "ask": 42,
                "bid": 29
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc8b4d3bf731539e06",
        "high": 12707.87,
        "open": 12864.58,
        "close": 12830.16,
        "low": 12784.01,
        "priceList": [
            {
                "price": 12855.51,
                "ask": 83,
                "bid": 78
            },
            {
                "price": 12723.02,
                "ask": 66,
                "bid": 56
            },
            {
                "price": 12710.57,
                "ask": 87,
                "bid": 64
            },
            {
                "price": 12899.12,
                "ask": 93,
                "bid": 86
            },
            {
                "price": 12980.24,
                "ask": 92,
                "bid": 93
            },
            {
                "price": 12827.9,
                "ask": 83,
                "bid": 91
            },
            {
                "price": 12798.86,
                "ask": 46,
                "bid": 39
            },
            {
                "price": 12950.18,
                "ask": 50,
                "bid": 58
            },
            {
                "price": 12932.71,
                "ask": 84,
                "bid": 93
            },
            {
                "price": 12773.01,
                "ask": 15,
                "bid": 81
            },
            {
                "price": 12904.27,
                "ask": 92,
                "bid": 69
            },
            {
                "price": 12754.83,
                "ask": 5,
                "bid": 53
            },
            {
                "price": 12920.77,
                "ask": 96,
                "bid": 29
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc7044c2a3244bd274",
        "high": 12868.32,
        "open": 12973.88,
        "close": 12933.52,
        "low": 12700.98,
        "priceList": [
            {
                "price": 12982.89,
                "ask": 47,
                "bid": 35
            },
            {
                "price": 12993.75,
                "ask": 16,
                "bid": 70
            },
            {
                "price": 12867.46,
                "ask": 53,
                "bid": 16
            },
            {
                "price": 12783.82,
                "ask": 75,
                "bid": 22
            },
            {
                "price": 12891.02,
                "ask": 14,
                "bid": 93
            },
            {
                "price": 12960.91,
                "ask": 40,
                "bid": 42
            },
            {
                "price": 12732.93,
                "ask": 65,
                "bid": 37
            },
            {
                "price": 12860.67,
                "ask": 50,
                "bid": 20
            },
            {
                "price": 12974.61,
                "ask": 0,
                "bid": 91
            },
            {
                "price": 12909.24,
                "ask": 63,
                "bid": 54
            },
            {
                "price": 12884.07,
                "ask": 67,
                "bid": 24
            },
            {
                "price": 12772.49,
                "ask": 44,
                "bid": 100
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc3b259335bcda2579",
        "high": 12981.63,
        "open": 12999.13,
        "close": 12764.63,
        "low": 12814.81,
        "priceList": [
            {
                "price": 12816.85,
                "ask": 82,
                "bid": 77
            },
            {
                "price": 12769.06,
                "ask": 94,
                "bid": 72
            },
            {
                "price": 12806.53,
                "ask": 19,
                "bid": 17
            },
            {
                "price": 12799.97,
                "ask": 21,
                "bid": 10
            },
            {
                "price": 12990.67,
                "ask": 20,
                "bid": 49
            },
            {
                "price": 12802.34,
                "ask": 0,
                "bid": 65
            },
            {
                "price": 12960.06,
                "ask": 26,
                "bid": 28
            },
            {
                "price": 12781.83,
                "ask": 83,
                "bid": 65
            },
            {
                "price": 12766.71,
                "ask": 41,
                "bid": 69
            },
            {
                "price": 12799.43,
                "ask": 92,
                "bid": 91
            },
            {
                "price": 12996.98,
                "ask": 100,
                "bid": 93
            },
            {
                "price": 12927.69,
                "ask": 82,
                "bid": 30
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcc1c51aeb4e1d7895",
        "high": 12908.94,
        "open": 12869.15,
        "close": 12700.2,
        "low": 12952.32,
        "priceList": [
            {
                "price": 12724.25,
                "ask": 100,
                "bid": 2
            },
            {
                "price": 12972.91,
                "ask": 93,
                "bid": 35
            },
            {
                "price": 12726.86,
                "ask": 61,
                "bid": 23
            },
            {
                "price": 12701.55,
                "ask": 88,
                "bid": 72
            },
            {
                "price": 12953.32,
                "ask": 90,
                "bid": 87
            },
            {
                "price": 12777.46,
                "ask": 9,
                "bid": 84
            },
            {
                "price": 12984.29,
                "ask": 18,
                "bid": 16
            },
            {
                "price": 12725.9,
                "ask": 13,
                "bid": 13
            },
            {
                "price": 12829.24,
                "ask": 86,
                "bid": 96
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dced57b370ee15578e",
        "high": 12898.09,
        "open": 12812.96,
        "close": 12896.81,
        "low": 12730.64,
        "priceList": [
            {
                "price": 12823.54,
                "ask": 19,
                "bid": 48
            },
            {
                "price": 12874.3,
                "ask": 24,
                "bid": 31
            },
            {
                "price": 12834.01,
                "ask": 95,
                "bid": 66
            },
            {
                "price": 12836.82,
                "ask": 9,
                "bid": 76
            },
            {
                "price": 12724,
                "ask": 70,
                "bid": 93
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcf5fe6af8e56955b8",
        "high": 12708.8,
        "open": 12948.73,
        "close": 12938.02,
        "low": 12830.11,
        "priceList": [
            {
                "price": 12809.84,
                "ask": 3,
                "bid": 24
            },
            {
                "price": 12945.42,
                "ask": 15,
                "bid": 94
            },
            {
                "price": 12738.1,
                "ask": 31,
                "bid": 20
            },
            {
                "price": 12930.37,
                "ask": 95,
                "bid": 28
            },
            {
                "price": 12747.57,
                "ask": 9,
                "bid": 49
            },
            {
                "price": 12926.47,
                "ask": 27,
                "bid": 27
            },
            {
                "price": 12850.28,
                "ask": 63,
                "bid": 6
            },
            {
                "price": 12714.53,
                "ask": 63,
                "bid": 52
            },
            {
                "price": 12819.47,
                "ask": 36,
                "bid": 70
            },
            {
                "price": 12807.77,
                "ask": 68,
                "bid": 43
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc69e03806a5e3b6a2",
        "high": 12859.2,
        "open": 12819.82,
        "close": 12711.75,
        "low": 12801.07,
        "priceList": [
            {
                "price": 12761.19,
                "ask": 54,
                "bid": 85
            },
            {
                "price": 12746.08,
                "ask": 79,
                "bid": 94
            },
            {
                "price": 12877.63,
                "ask": 64,
                "bid": 65
            },
            {
                "price": 12732.34,
                "ask": 65,
                "bid": 48
            },
            {
                "price": 12723.31,
                "ask": 0,
                "bid": 55
            },
            {
                "price": 12897.73,
                "ask": 78,
                "bid": 59
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcd28777df919ca65f",
        "high": 12887.89,
        "open": 12721.99,
        "close": 12965.17,
        "low": 12902.12,
        "priceList": [
            {
                "price": 12932.35,
                "ask": 89,
                "bid": 88
            },
            {
                "price": 12995.32,
                "ask": 48,
                "bid": 100
            },
            {
                "price": 12819.83,
                "ask": 20,
                "bid": 46
            },
            {
                "price": 12860.03,
                "ask": 8,
                "bid": 74
            },
            {
                "price": 12821.02,
                "ask": 82,
                "bid": 48
            },
            {
                "price": 12966.75,
                "ask": 44,
                "bid": 88
            },
            {
                "price": 12754.25,
                "ask": 73,
                "bid": 45
            },
            {
                "price": 12929.43,
                "ask": 18,
                "bid": 9
            },
            {
                "price": 12961.77,
                "ask": 88,
                "bid": 78
            },
            {
                "price": 12729.11,
                "ask": 38,
                "bid": 35
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcab9ff5ac78366f29",
        "high": 12955.21,
        "open": 12763.93,
        "close": 12740.37,
        "low": 12907.98,
        "priceList": [
            {
                "price": 12861.3,
                "ask": 42,
                "bid": 84
            },
            {
                "price": 12728.59,
                "ask": 23,
                "bid": 66
            },
            {
                "price": 12715.07,
                "ask": 85,
                "bid": 67
            },
            {
                "price": 12905.44,
                "ask": 96,
                "bid": 22
            },
            {
                "price": 12734.4,
                "ask": 54,
                "bid": 80
            },
            {
                "price": 12928.68,
                "ask": 57,
                "bid": 30
            },
            {
                "price": 12915.33,
                "ask": 47,
                "bid": 1
            },
            {
                "price": 12733.7,
                "ask": 81,
                "bid": 30
            },
            {
                "price": 12763.87,
                "ask": 34,
                "bid": 85
            },
            {
                "price": 12779.87,
                "ask": 13,
                "bid": 30
            },
            {
                "price": 12860.4,
                "ask": 72,
                "bid": 85
            },
            {
                "price": 12870.53,
                "ask": 23,
                "bid": 79
            },
            {
                "price": 12712.94,
                "ask": 45,
                "bid": 99
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc5c17cea60277bbbd",
        "high": 12991.91,
        "open": 12874.34,
        "close": 12849.06,
        "low": 12998.6,
        "priceList": [
            {
                "price": 12937.29,
                "ask": 96,
                "bid": 69
            },
            {
                "price": 12719.35,
                "ask": 75,
                "bid": 62
            },
            {
                "price": 12862.65,
                "ask": 76,
                "bid": 23
            },
            {
                "price": 12937.32,
                "ask": 35,
                "bid": 82
            },
            {
                "price": 12885.74,
                "ask": 69,
                "bid": 24
            },
            {
                "price": 12969.25,
                "ask": 95,
                "bid": 93
            },
            {
                "price": 12737.29,
                "ask": 90,
                "bid": 91
            },
            {
                "price": 12960.79,
                "ask": 47,
                "bid": 87
            },
            {
                "price": 12886.65,
                "ask": 85,
                "bid": 66
            },
            {
                "price": 12913.55,
                "ask": 14,
                "bid": 41
            },
            {
                "price": 12716.2,
                "ask": 86,
                "bid": 100
            },
            {
                "price": 12888.12,
                "ask": 36,
                "bid": 8
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcf8ffafcabf39a43c",
        "high": 12953,
        "open": 12700.49,
        "close": 12960.36,
        "low": 12734.08,
        "priceList": [
            {
                "price": 12889.07,
                "ask": 1,
                "bid": 94
            },
            {
                "price": 12737.29,
                "ask": 33,
                "bid": 15
            },
            {
                "price": 12748.81,
                "ask": 82,
                "bid": 2
            },
            {
                "price": 12721.37,
                "ask": 71,
                "bid": 32
            },
            {
                "price": 12914.52,
                "ask": 61,
                "bid": 22
            },
            {
                "price": 12814.76,
                "ask": 84,
                "bid": 37
            },
            {
                "price": 12753.42,
                "ask": 3,
                "bid": 72
            },
            {
                "price": 12758.54,
                "ask": 70,
                "bid": 33
            },
            {
                "price": 12817.57,
                "ask": 38,
                "bid": 1
            },
            {
                "price": 12756.76,
                "ask": 52,
                "bid": 21
            },
            {
                "price": 12803.71,
                "ask": 21,
                "bid": 34
            },
            {
                "price": 12825.54,
                "ask": 72,
                "bid": 90
            },
            {
                "price": 12775.23,
                "ask": 34,
                "bid": 37
            },
            {
                "price": 12843.75,
                "ask": 41,
                "bid": 0
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc8041c72e14d819f1",
        "high": 12893.97,
        "open": 12821.49,
        "close": 12863.08,
        "low": 12999.65,
        "priceList": [
            {
                "price": 12991.29,
                "ask": 42,
                "bid": 13
            },
            {
                "price": 12701.88,
                "ask": 88,
                "bid": 39
            },
            {
                "price": 12709.21,
                "ask": 25,
                "bid": 9
            },
            {
                "price": 12727.5,
                "ask": 65,
                "bid": 2
            },
            {
                "price": 12936.57,
                "ask": 71,
                "bid": 39
            },
            {
                "price": 12987.25,
                "ask": 83,
                "bid": 5
            },
            {
                "price": 12863.64,
                "ask": 71,
                "bid": 21
            },
            {
                "price": 12851.32,
                "ask": 15,
                "bid": 1
            },
            {
                "price": 12918.7,
                "ask": 80,
                "bid": 90
            },
            {
                "price": 12790.81,
                "ask": 4,
                "bid": 69
            },
            {
                "price": 12908.9,
                "ask": 5,
                "bid": 93
            },
            {
                "price": 12958.85,
                "ask": 2,
                "bid": 77
            },
            {
                "price": 12940.51,
                "ask": 70,
                "bid": 26
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcb95b61e16d071cd3",
        "high": 12751.42,
        "open": 12715.38,
        "close": 12980.36,
        "low": 12927.11,
        "priceList": [
            {
                "price": 12933.26,
                "ask": 90,
                "bid": 3
            },
            {
                "price": 12912.32,
                "ask": 95,
                "bid": 3
            },
            {
                "price": 12703.81,
                "ask": 15,
                "bid": 88
            },
            {
                "price": 12736.4,
                "ask": 86,
                "bid": 15
            },
            {
                "price": 12771.49,
                "ask": 58,
                "bid": 21
            },
            {
                "price": 12786.23,
                "ask": 72,
                "bid": 71
            },
            {
                "price": 12915.85,
                "ask": 0,
                "bid": 44
            },
            {
                "price": 12782.91,
                "ask": 98,
                "bid": 55
            },
            {
                "price": 12822.21,
                "ask": 0,
                "bid": 36
            },
            {
                "price": 12880.97,
                "ask": 16,
                "bid": 6
            },
            {
                "price": 12899.74,
                "ask": 18,
                "bid": 25
            },
            {
                "price": 12748.11,
                "ask": 6,
                "bid": 7
            },
            {
                "price": 12715.26,
                "ask": 60,
                "bid": 87
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcc76eb429ae845ada",
        "high": 12746.99,
        "open": 12763.6,
        "close": 12898.39,
        "low": 12895.49,
        "priceList": [
            {
                "price": 12701.52,
                "ask": 16,
                "bid": 93
            },
            {
                "price": 12951.67,
                "ask": 51,
                "bid": 66
            },
            {
                "price": 12735.2,
                "ask": 89,
                "bid": 30
            },
            {
                "price": 12868.37,
                "ask": 33,
                "bid": 9
            },
            {
                "price": 12853.57,
                "ask": 72,
                "bid": 32
            },
            {
                "price": 12825.57,
                "ask": 16,
                "bid": 32
            },
            {
                "price": 12848.07,
                "ask": 15,
                "bid": 9
            },
            {
                "price": 12932.13,
                "ask": 77,
                "bid": 30
            },
            {
                "price": 12890.18,
                "ask": 63,
                "bid": 18
            },
            {
                "price": 12918.34,
                "ask": 18,
                "bid": 84
            },
            {
                "price": 12969.46,
                "ask": 71,
                "bid": 44
            },
            {
                "price": 12744.52,
                "ask": 48,
                "bid": 33
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc201a5221311a9e77",
        "high": 12867.74,
        "open": 12790.18,
        "close": 12806.54,
        "low": 12956.77,
        "priceList": [
            {
                "price": 12824.54,
                "ask": 1,
                "bid": 53
            },
            {
                "price": 12709.56,
                "ask": 61,
                "bid": 69
            },
            {
                "price": 12845.22,
                "ask": 69,
                "bid": 6
            },
            {
                "price": 12921.06,
                "ask": 97,
                "bid": 27
            },
            {
                "price": 12753.96,
                "ask": 4,
                "bid": 92
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc54827a154a38ff00",
        "high": 12864.32,
        "open": 12777.74,
        "close": 12859.65,
        "low": 12707.81,
        "priceList": [
            {
                "price": 12821.07,
                "ask": 72,
                "bid": 36
            },
            {
                "price": 12717.18,
                "ask": 46,
                "bid": 72
            },
            {
                "price": 12773.48,
                "ask": 62,
                "bid": 27
            },
            {
                "price": 12718.37,
                "ask": 83,
                "bid": 72
            },
            {
                "price": 12880.74,
                "ask": 14,
                "bid": 87
            },
            {
                "price": 12753.78,
                "ask": 13,
                "bid": 41
            },
            {
                "price": 12922.66,
                "ask": 16,
                "bid": 27
            },
            {
                "price": 12759.28,
                "ask": 35,
                "bid": 12
            },
            {
                "price": 12942.42,
                "ask": 79,
                "bid": 100
            },
            {
                "price": 12895.32,
                "ask": 43,
                "bid": 23
            },
            {
                "price": 12971.27,
                "ask": 25,
                "bid": 31
            },
            {
                "price": 12979.77,
                "ask": 21,
                "bid": 0
            },
            {
                "price": 12803.49,
                "ask": 22,
                "bid": 18
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcca2a16ae2be506a5",
        "high": 12788.6,
        "open": 12851.42,
        "close": 12962.47,
        "low": 12970.09,
        "priceList": [
            {
                "price": 12915.48,
                "ask": 75,
                "bid": 95
            },
            {
                "price": 12979.8,
                "ask": 54,
                "bid": 47
            },
            {
                "price": 12938.81,
                "ask": 62,
                "bid": 48
            },
            {
                "price": 12973.9,
                "ask": 34,
                "bid": 28
            },
            {
                "price": 12754.26,
                "ask": 76,
                "bid": 65
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc932ae4a32b2b8e82",
        "high": 12740.85,
        "open": 12971.81,
        "close": 12824.66,
        "low": 12704.29,
        "priceList": [
            {
                "price": 12963.72,
                "ask": 98,
                "bid": 67
            },
            {
                "price": 12701.48,
                "ask": 79,
                "bid": 33
            },
            {
                "price": 12895,
                "ask": 48,
                "bid": 92
            },
            {
                "price": 12898.88,
                "ask": 50,
                "bid": 39
            },
            {
                "price": 12957.69,
                "ask": 37,
                "bid": 36
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcef59bdfdc5d17b2a",
        "high": 12719.69,
        "open": 12958.27,
        "close": 12796.64,
        "low": 12853.71,
        "priceList": [
            {
                "price": 12715.69,
                "ask": 1,
                "bid": 99
            },
            {
                "price": 12990.73,
                "ask": 12,
                "bid": 74
            },
            {
                "price": 12784.76,
                "ask": 86,
                "bid": 33
            },
            {
                "price": 12908.73,
                "ask": 54,
                "bid": 6
            },
            {
                "price": 12979.41,
                "ask": 33,
                "bid": 23
            },
            {
                "price": 12766.51,
                "ask": 46,
                "bid": 21
            },
            {
                "price": 12976.71,
                "ask": 15,
                "bid": 58
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc886993f1408fea65",
        "high": 12955.94,
        "open": 12989.62,
        "close": 12963.01,
        "low": 12850.14,
        "priceList": [
            {
                "price": 12813.57,
                "ask": 42,
                "bid": 61
            },
            {
                "price": 12904.07,
                "ask": 49,
                "bid": 1
            },
            {
                "price": 12736.19,
                "ask": 55,
                "bid": 53
            },
            {
                "price": 12840.05,
                "ask": 85,
                "bid": 47
            },
            {
                "price": 12805.35,
                "ask": 89,
                "bid": 7
            },
            {
                "price": 12769.63,
                "ask": 100,
                "bid": 94
            },
            {
                "price": 12798.28,
                "ask": 70,
                "bid": 54
            },
            {
                "price": 12975.65,
                "ask": 84,
                "bid": 8
            },
            {
                "price": 12961.8,
                "ask": 74,
                "bid": 77
            },
            {
                "price": 12837.29,
                "ask": 41,
                "bid": 50
            },
            {
                "price": 12963.8,
                "ask": 8,
                "bid": 72
            },
            {
                "price": 12985.47,
                "ask": 93,
                "bid": 50
            },
            {
                "price": 12784.08,
                "ask": 23,
                "bid": 41
            },
            {
                "price": 12840.44,
                "ask": 1,
                "bid": 51
            },
            {
                "price": 12944.84,
                "ask": 83,
                "bid": 28
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6a7a99392c613460",
        "high": 12739.32,
        "open": 12880.06,
        "close": 12780.47,
        "low": 12998.66,
        "priceList": [
            {
                "price": 12761.94,
                "ask": 40,
                "bid": 38
            },
            {
                "price": 12920.34,
                "ask": 73,
                "bid": 96
            },
            {
                "price": 12989.59,
                "ask": 54,
                "bid": 22
            },
            {
                "price": 12914.19,
                "ask": 62,
                "bid": 84
            },
            {
                "price": 12914.25,
                "ask": 8,
                "bid": 36
            },
            {
                "price": 12721.23,
                "ask": 60,
                "bid": 61
            },
            {
                "price": 12772.76,
                "ask": 34,
                "bid": 11
            },
            {
                "price": 12805.1,
                "ask": 36,
                "bid": 12
            },
            {
                "price": 12757.16,
                "ask": 35,
                "bid": 55
            },
            {
                "price": 12867.75,
                "ask": 21,
                "bid": 96
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc378414db22c15038",
        "high": 12943.67,
        "open": 12759.54,
        "close": 12926.67,
        "low": 12828.06,
        "priceList": [
            {
                "price": 12898.92,
                "ask": 42,
                "bid": 87
            },
            {
                "price": 12745.97,
                "ask": 9,
                "bid": 46
            },
            {
                "price": 12702.52,
                "ask": 82,
                "bid": 80
            },
            {
                "price": 12927.23,
                "ask": 71,
                "bid": 17
            },
            {
                "price": 12871.57,
                "ask": 12,
                "bid": 89
            },
            {
                "price": 12790.12,
                "ask": 53,
                "bid": 56
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcc90c7d79e575fc0a",
        "high": 12775.74,
        "open": 12731.42,
        "close": 12916.6,
        "low": 12844.85,
        "priceList": [
            {
                "price": 12951.36,
                "ask": 10,
                "bid": 27
            },
            {
                "price": 12701.11,
                "ask": 76,
                "bid": 76
            },
            {
                "price": 12879.51,
                "ask": 85,
                "bid": 10
            },
            {
                "price": 12920.6,
                "ask": 46,
                "bid": 95
            },
            {
                "price": 12829.53,
                "ask": 89,
                "bid": 16
            },
            {
                "price": 12849.7,
                "ask": 43,
                "bid": 58
            },
            {
                "price": 12891.33,
                "ask": 89,
                "bid": 33
            },
            {
                "price": 12804.27,
                "ask": 8,
                "bid": 65
            },
            {
                "price": 12849.83,
                "ask": 18,
                "bid": 31
            },
            {
                "price": 12985.89,
                "ask": 70,
                "bid": 95
            },
            {
                "price": 12799.15,
                "ask": 100,
                "bid": 64
            },
            {
                "price": 12964.9,
                "ask": 44,
                "bid": 14
            },
            {
                "price": 12946.65,
                "ask": 42,
                "bid": 43
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc67aa7aaa6f48587e",
        "high": 12861.1,
        "open": 12885.73,
        "close": 12926.48,
        "low": 12961.69,
        "priceList": [
            {
                "price": 12913.29,
                "ask": 14,
                "bid": 78
            },
            {
                "price": 12989.76,
                "ask": 20,
                "bid": 25
            },
            {
                "price": 12923.56,
                "ask": 9,
                "bid": 77
            },
            {
                "price": 12964.64,
                "ask": 34,
                "bid": 66
            },
            {
                "price": 12748.52,
                "ask": 55,
                "bid": 7
            },
            {
                "price": 12943.17,
                "ask": 42,
                "bid": 37
            },
            {
                "price": 12986.17,
                "ask": 15,
                "bid": 54
            },
            {
                "price": 12731.51,
                "ask": 10,
                "bid": 48
            },
            {
                "price": 12760.48,
                "ask": 31,
                "bid": 55
            },
            {
                "price": 12728.59,
                "ask": 79,
                "bid": 47
            },
            {
                "price": 12884.01,
                "ask": 80,
                "bid": 28
            },
            {
                "price": 12743.47,
                "ask": 44,
                "bid": 75
            },
            {
                "price": 12828.02,
                "ask": 34,
                "bid": 53
            },
            {
                "price": 12856.26,
                "ask": 12,
                "bid": 76
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc5e6875b56c865339",
        "high": 12732.1,
        "open": 12949.09,
        "close": 12930.39,
        "low": 12825.23,
        "priceList": [
            {
                "price": 12908.3,
                "ask": 51,
                "bid": 66
            },
            {
                "price": 12762.79,
                "ask": 89,
                "bid": 31
            },
            {
                "price": 12729.33,
                "ask": 10,
                "bid": 23
            },
            {
                "price": 12997.1,
                "ask": 72,
                "bid": 85
            },
            {
                "price": 12837.65,
                "ask": 20,
                "bid": 19
            },
            {
                "price": 12838.1,
                "ask": 32,
                "bid": 13
            },
            {
                "price": 12921.53,
                "ask": 78,
                "bid": 53
            },
            {
                "price": 12840.02,
                "ask": 36,
                "bid": 74
            },
            {
                "price": 12993.56,
                "ask": 86,
                "bid": 1
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcedf8b62d37edf7bf",
        "high": 12921.52,
        "open": 12939.73,
        "close": 12964.99,
        "low": 12896.16,
        "priceList": [
            {
                "price": 12844.76,
                "ask": 98,
                "bid": 56
            },
            {
                "price": 12772.36,
                "ask": 95,
                "bid": 55
            },
            {
                "price": 12949.27,
                "ask": 90,
                "bid": 76
            },
            {
                "price": 12769.13,
                "ask": 60,
                "bid": 1
            },
            {
                "price": 12992.86,
                "ask": 99,
                "bid": 76
            },
            {
                "price": 12810.54,
                "ask": 84,
                "bid": 8
            },
            {
                "price": 12957.46,
                "ask": 5,
                "bid": 60
            },
            {
                "price": 12718.41,
                "ask": 24,
                "bid": 34
            },
            {
                "price": 12953.39,
                "ask": 35,
                "bid": 27
            },
            {
                "price": 12702.87,
                "ask": 57,
                "bid": 6
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc97b5e1e904de4ab0",
        "high": 12703,
        "open": 12990.79,
        "close": 12810.97,
        "low": 12815.46,
        "priceList": [
            {
                "price": 12733.76,
                "ask": 21,
                "bid": 31
            },
            {
                "price": 12989.29,
                "ask": 65,
                "bid": 68
            },
            {
                "price": 12860.54,
                "ask": 38,
                "bid": 16
            },
            {
                "price": 12752.86,
                "ask": 99,
                "bid": 51
            },
            {
                "price": 12792.11,
                "ask": 69,
                "bid": 69
            },
            {
                "price": 12902.95,
                "ask": 83,
                "bid": 13
            },
            {
                "price": 12883.12,
                "ask": 16,
                "bid": 48
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc0a291b0a4420ccfe",
        "high": 12854,
        "open": 12916.83,
        "close": 12755.02,
        "low": 12949.79,
        "priceList": [
            {
                "price": 12929.66,
                "ask": 21,
                "bid": 34
            },
            {
                "price": 12835.87,
                "ask": 23,
                "bid": 35
            },
            {
                "price": 12796.81,
                "ask": 37,
                "bid": 37
            },
            {
                "price": 12995.37,
                "ask": 60,
                "bid": 60
            },
            {
                "price": 12862.68,
                "ask": 82,
                "bid": 54
            },
            {
                "price": 12880.07,
                "ask": 97,
                "bid": 83
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc681f6fe4c358f7f2",
        "high": 12774.59,
        "open": 12933.53,
        "close": 12915.72,
        "low": 12988.29,
        "priceList": [
            {
                "price": 12744.6,
                "ask": 75,
                "bid": 5
            },
            {
                "price": 12797.82,
                "ask": 73,
                "bid": 31
            },
            {
                "price": 12963.38,
                "ask": 81,
                "bid": 47
            },
            {
                "price": 12810.5,
                "ask": 12,
                "bid": 76
            },
            {
                "price": 12918.23,
                "ask": 40,
                "bid": 10
            },
            {
                "price": 12949.77,
                "ask": 18,
                "bid": 0
            },
            {
                "price": 12722.23,
                "ask": 15,
                "bid": 28
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcbe2199614f50945e",
        "high": 12747.11,
        "open": 12872.07,
        "close": 12994.31,
        "low": 12938.23,
        "priceList": [
            {
                "price": 12707.08,
                "ask": 53,
                "bid": 51
            },
            {
                "price": 12838.97,
                "ask": 1,
                "bid": 62
            },
            {
                "price": 12710.24,
                "ask": 48,
                "bid": 77
            },
            {
                "price": 12718.79,
                "ask": 68,
                "bid": 35
            },
            {
                "price": 12824.32,
                "ask": 43,
                "bid": 97
            },
            {
                "price": 12768.75,
                "ask": 99,
                "bid": 2
            },
            {
                "price": 12783.51,
                "ask": 40,
                "bid": 64
            },
            {
                "price": 12924.32,
                "ask": 3,
                "bid": 46
            },
            {
                "price": 12809.64,
                "ask": 82,
                "bid": 22
            },
            {
                "price": 12773.51,
                "ask": 76,
                "bid": 13
            },
            {
                "price": 12962.29,
                "ask": 93,
                "bid": 20
            },
            {
                "price": 12704.55,
                "ask": 1,
                "bid": 71
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dce665ac8b116a46a1",
        "high": 12759.27,
        "open": 12897.21,
        "close": 12911.43,
        "low": 12710.34,
        "priceList": [
            {
                "price": 12830.36,
                "ask": 32,
                "bid": 65
            },
            {
                "price": 12993.77,
                "ask": 32,
                "bid": 84
            },
            {
                "price": 12992.94,
                "ask": 74,
                "bid": 96
            },
            {
                "price": 12711.72,
                "ask": 69,
                "bid": 51
            },
            {
                "price": 12881.19,
                "ask": 76,
                "bid": 83
            },
            {
                "price": 12704.54,
                "ask": 80,
                "bid": 67
            },
            {
                "price": 12743.96,
                "ask": 26,
                "bid": 74
            },
            {
                "price": 12720.6,
                "ask": 34,
                "bid": 71
            },
            {
                "price": 12930.82,
                "ask": 68,
                "bid": 43
            },
            {
                "price": 12973.59,
                "ask": 81,
                "bid": 54
            },
            {
                "price": 12852.82,
                "ask": 89,
                "bid": 32
            },
            {
                "price": 12874.65,
                "ask": 63,
                "bid": 49
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc638c8bd0b0fa7469",
        "high": 12813.65,
        "open": 12954.03,
        "close": 12963.73,
        "low": 12849.71,
        "priceList": [
            {
                "price": 12919.44,
                "ask": 50,
                "bid": 58
            },
            {
                "price": 12801.86,
                "ask": 89,
                "bid": 71
            },
            {
                "price": 12735.62,
                "ask": 57,
                "bid": 87
            },
            {
                "price": 12946,
                "ask": 7,
                "bid": 95
            },
            {
                "price": 12988.59,
                "ask": 95,
                "bid": 62
            },
            {
                "price": 12927.09,
                "ask": 76,
                "bid": 66
            },
            {
                "price": 12967.87,
                "ask": 80,
                "bid": 51
            },
            {
                "price": 12881.93,
                "ask": 28,
                "bid": 96
            },
            {
                "price": 12988.09,
                "ask": 51,
                "bid": 90
            },
            {
                "price": 12921.94,
                "ask": 84,
                "bid": 31
            },
            {
                "price": 12961.64,
                "ask": 86,
                "bid": 72
            },
            {
                "price": 12824.15,
                "ask": 11,
                "bid": 92
            },
            {
                "price": 12951.78,
                "ask": 60,
                "bid": 18
            },
            {
                "price": 12972.08,
                "ask": 36,
                "bid": 25
            },
            {
                "price": 12799.15,
                "ask": 24,
                "bid": 51
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc083cf9b7fa1c522a",
        "high": 12701.75,
        "open": 12713.81,
        "close": 12753.5,
        "low": 12830.58,
        "priceList": [
            {
                "price": 12753.55,
                "ask": 87,
                "bid": 1
            },
            {
                "price": 12906.26,
                "ask": 23,
                "bid": 57
            },
            {
                "price": 12858.7,
                "ask": 75,
                "bid": 73
            },
            {
                "price": 12939.04,
                "ask": 22,
                "bid": 38
            },
            {
                "price": 12764.13,
                "ask": 70,
                "bid": 89
            },
            {
                "price": 12918.16,
                "ask": 15,
                "bid": 66
            },
            {
                "price": 12814.19,
                "ask": 75,
                "bid": 51
            },
            {
                "price": 12759.23,
                "ask": 62,
                "bid": 17
            },
            {
                "price": 12822.6,
                "ask": 82,
                "bid": 86
            },
            {
                "price": 12875.6,
                "ask": 77,
                "bid": 99
            },
            {
                "price": 12792.79,
                "ask": 95,
                "bid": 9
            },
            {
                "price": 12852.01,
                "ask": 47,
                "bid": 79
            },
            {
                "price": 12796.29,
                "ask": 86,
                "bid": 91
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc71d4692cf7ae136c",
        "high": 12721.41,
        "open": 12801.4,
        "close": 12984.6,
        "low": 12805.82,
        "priceList": [
            {
                "price": 12808.44,
                "ask": 8,
                "bid": 77
            },
            {
                "price": 12820.72,
                "ask": 33,
                "bid": 74
            },
            {
                "price": 12855.93,
                "ask": 46,
                "bid": 70
            },
            {
                "price": 12925.29,
                "ask": 71,
                "bid": 20
            },
            {
                "price": 12802.37,
                "ask": 44,
                "bid": 82
            },
            {
                "price": 12878.96,
                "ask": 97,
                "bid": 89
            },
            {
                "price": 12880.95,
                "ask": 25,
                "bid": 12
            },
            {
                "price": 12806.14,
                "ask": 2,
                "bid": 6
            },
            {
                "price": 12702.85,
                "ask": 12,
                "bid": 52
            },
            {
                "price": 12996.84,
                "ask": 61,
                "bid": 62
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc7bd82142cdf43522",
        "high": 12962.16,
        "open": 12835.58,
        "close": 12852.09,
        "low": 12776.87,
        "priceList": [
            {
                "price": 12959.19,
                "ask": 24,
                "bid": 30
            },
            {
                "price": 12816.95,
                "ask": 2,
                "bid": 73
            },
            {
                "price": 12728.75,
                "ask": 26,
                "bid": 27
            },
            {
                "price": 12845.11,
                "ask": 80,
                "bid": 92
            },
            {
                "price": 12932.4,
                "ask": 22,
                "bid": 75
            },
            {
                "price": 12726.69,
                "ask": 75,
                "bid": 63
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc74388cc13041241f",
        "high": 12847.07,
        "open": 12819.6,
        "close": 12716.43,
        "low": 12710.35,
        "priceList": [
            {
                "price": 12835.44,
                "ask": 2,
                "bid": 16
            },
            {
                "price": 12768.76,
                "ask": 3,
                "bid": 22
            },
            {
                "price": 12974.92,
                "ask": 12,
                "bid": 99
            },
            {
                "price": 12701.83,
                "ask": 38,
                "bid": 87
            },
            {
                "price": 12898.68,
                "ask": 21,
                "bid": 39
            },
            {
                "price": 12950.32,
                "ask": 11,
                "bid": 29
            },
            {
                "price": 12788.03,
                "ask": 22,
                "bid": 3
            },
            {
                "price": 12976.51,
                "ask": 13,
                "bid": 60
            },
            {
                "price": 12919.41,
                "ask": 68,
                "bid": 56
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc6d4af728eaa38d78",
        "high": 12842.34,
        "open": 12766.76,
        "close": 12975.7,
        "low": 12978.89,
        "priceList": [
            {
                "price": 12851.36,
                "ask": 30,
                "bid": 79
            },
            {
                "price": 12929.99,
                "ask": 33,
                "bid": 79
            },
            {
                "price": 12928.04,
                "ask": 78,
                "bid": 26
            },
            {
                "price": 12835.39,
                "ask": 99,
                "bid": 87
            },
            {
                "price": 12833.51,
                "ask": 38,
                "bid": 83
            },
            {
                "price": 12998.09,
                "ask": 96,
                "bid": 75
            },
            {
                "price": 12780.02,
                "ask": 36,
                "bid": 4
            },
            {
                "price": 12816.72,
                "ask": 12,
                "bid": 6
            },
            {
                "price": 12841.38,
                "ask": 22,
                "bid": 13
            },
            {
                "price": 12874.41,
                "ask": 40,
                "bid": 54
            },
            {
                "price": 12765.89,
                "ask": 85,
                "bid": 90
            },
            {
                "price": 12956.76,
                "ask": 11,
                "bid": 1
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc30af1b239975957e",
        "high": 12706.68,
        "open": 12883.16,
        "close": 12988.7,
        "low": 12712.51,
        "priceList": [
            {
                "price": 12795.4,
                "ask": 3,
                "bid": 38
            },
            {
                "price": 12928.37,
                "ask": 23,
                "bid": 23
            },
            {
                "price": 12812.06,
                "ask": 29,
                "bid": 36
            },
            {
                "price": 12876.67,
                "ask": 100,
                "bid": 82
            },
            {
                "price": 12756.19,
                "ask": 86,
                "bid": 7
            },
            {
                "price": 12715.04,
                "ask": 58,
                "bid": 17
            },
            {
                "price": 12994.67,
                "ask": 74,
                "bid": 52
            },
            {
                "price": 12702.85,
                "ask": 43,
                "bid": 70
            },
            {
                "price": 12754.35,
                "ask": 87,
                "bid": 52
            },
            {
                "price": 12885.98,
                "ask": 41,
                "bid": 15
            },
            {
                "price": 12972.98,
                "ask": 2,
                "bid": 100
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc8fc12a8f7e5009ae",
        "high": 12703.6,
        "open": 12857.22,
        "close": 12777.7,
        "low": 12728.73,
        "priceList": [
            {
                "price": 12802.99,
                "ask": 79,
                "bid": 96
            },
            {
                "price": 12889.89,
                "ask": 24,
                "bid": 14
            },
            {
                "price": 12847.02,
                "ask": 40,
                "bid": 59
            },
            {
                "price": 12998.45,
                "ask": 63,
                "bid": 4
            },
            {
                "price": 12895.68,
                "ask": 58,
                "bid": 64
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc7aee43f7de23abcc",
        "high": 12761.69,
        "open": 12969.4,
        "close": 12785.38,
        "low": 12932.42,
        "priceList": [
            {
                "price": 12997.73,
                "ask": 83,
                "bid": 87
            },
            {
                "price": 12705.38,
                "ask": 83,
                "bid": 99
            },
            {
                "price": 12871.07,
                "ask": 97,
                "bid": 51
            },
            {
                "price": 12796.41,
                "ask": 56,
                "bid": 43
            },
            {
                "price": 12876.73,
                "ask": 26,
                "bid": 28
            },
            {
                "price": 12850.14,
                "ask": 25,
                "bid": 22
            },
            {
                "price": 12844.9,
                "ask": 83,
                "bid": 55
            },
            {
                "price": 12862.66,
                "ask": 80,
                "bid": 31
            },
            {
                "price": 12998.75,
                "ask": 63,
                "bid": 5
            },
            {
                "price": 12996.14,
                "ask": 23,
                "bid": 59
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc4d764225b5d70bd4",
        "high": 12790.06,
        "open": 12867.8,
        "close": 12815.24,
        "low": 12857.73,
        "priceList": [
            {
                "price": 12873.06,
                "ask": 64,
                "bid": 5
            },
            {
                "price": 12775,
                "ask": 92,
                "bid": 32
            },
            {
                "price": 12714.08,
                "ask": 96,
                "bid": 87
            },
            {
                "price": 12933.6,
                "ask": 61,
                "bid": 35
            },
            {
                "price": 12710.17,
                "ask": 51,
                "bid": 68
            },
            {
                "price": 12821.24,
                "ask": 34,
                "bid": 70
            },
            {
                "price": 12982.31,
                "ask": 27,
                "bid": 14
            },
            {
                "price": 12910.5,
                "ask": 38,
                "bid": 70
            },
            {
                "price": 12951.18,
                "ask": 43,
                "bid": 11
            },
            {
                "price": 12847.88,
                "ask": 91,
                "bid": 81
            },
            {
                "price": 12871.93,
                "ask": 21,
                "bid": 90
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcb159724a83cf9dec",
        "high": 12984.74,
        "open": 12984.35,
        "close": 12840.6,
        "low": 12891.74,
        "priceList": [
            {
                "price": 12718.38,
                "ask": 21,
                "bid": 24
            },
            {
                "price": 12819.79,
                "ask": 61,
                "bid": 13
            },
            {
                "price": 12986.95,
                "ask": 83,
                "bid": 79
            },
            {
                "price": 12905.67,
                "ask": 43,
                "bid": 47
            },
            {
                "price": 12729.43,
                "ask": 97,
                "bid": 45
            },
            {
                "price": 12795.5,
                "ask": 35,
                "bid": 1
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcf2b74fcb13aea600",
        "high": 12823.54,
        "open": 12908,
        "close": 12893.02,
        "low": 12757.59,
        "priceList": [
            {
                "price": 12787.45,
                "ask": 83,
                "bid": 21
            },
            {
                "price": 12804.7,
                "ask": 24,
                "bid": 39
            },
            {
                "price": 12992.21,
                "ask": 55,
                "bid": 26
            },
            {
                "price": 12722.67,
                "ask": 11,
                "bid": 9
            },
            {
                "price": 12861.32,
                "ask": 26,
                "bid": 65
            },
            {
                "price": 12942.38,
                "ask": 82,
                "bid": 25
            },
            {
                "price": 12933.64,
                "ask": 67,
                "bid": 34
            },
            {
                "price": 12714.6,
                "ask": 81,
                "bid": 84
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc6c2b99077c8b0840",
        "high": 12957.57,
        "open": 12777.03,
        "close": 12811.81,
        "low": 12925.49,
        "priceList": [
            {
                "price": 12717.52,
                "ask": 13,
                "bid": 49
            },
            {
                "price": 12830.35,
                "ask": 41,
                "bid": 63
            },
            {
                "price": 12882.92,
                "ask": 43,
                "bid": 0
            },
            {
                "price": 12974.06,
                "ask": 56,
                "bid": 37
            },
            {
                "price": 12983.46,
                "ask": 39,
                "bid": 2
            },
            {
                "price": 12715.72,
                "ask": 58,
                "bid": 68
            },
            {
                "price": 12925.62,
                "ask": 64,
                "bid": 65
            },
            {
                "price": 12789.08,
                "ask": 99,
                "bid": 35
            },
            {
                "price": 12713.54,
                "ask": 36,
                "bid": 63
            },
            {
                "price": 12882.86,
                "ask": 82,
                "bid": 77
            },
            {
                "price": 12983.42,
                "ask": 41,
                "bid": 92
            },
            {
                "price": 12757.22,
                "ask": 33,
                "bid": 77
            },
            {
                "price": 12975.94,
                "ask": 100,
                "bid": 34
            },
            {
                "price": 12706.63,
                "ask": 21,
                "bid": 22
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc0d92821b3b837838",
        "high": 12794.89,
        "open": 12962.78,
        "close": 12937.79,
        "low": 12892.03,
        "priceList": [
            {
                "price": 12772.25,
                "ask": 100,
                "bid": 85
            },
            {
                "price": 12986.3,
                "ask": 47,
                "bid": 85
            },
            {
                "price": 12742.7,
                "ask": 63,
                "bid": 90
            },
            {
                "price": 12840.55,
                "ask": 78,
                "bid": 50
            },
            {
                "price": 12857.4,
                "ask": 0,
                "bid": 17
            },
            {
                "price": 12989.41,
                "ask": 61,
                "bid": 3
            },
            {
                "price": 12884.2,
                "ask": 59,
                "bid": 3
            },
            {
                "price": 12829.55,
                "ask": 24,
                "bid": 37
            },
            {
                "price": 12700.66,
                "ask": 4,
                "bid": 50
            },
            {
                "price": 12933.45,
                "ask": 27,
                "bid": 16
            },
            {
                "price": 12808.85,
                "ask": 43,
                "bid": 91
            },
            {
                "price": 12730.51,
                "ask": 65,
                "bid": 96
            },
            {
                "price": 12789.97,
                "ask": 87,
                "bid": 48
            },
            {
                "price": 12872.34,
                "ask": 27,
                "bid": 23
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcdb873b0865b101fe",
        "high": 12732.59,
        "open": 12936.42,
        "close": 12773.25,
        "low": 12827.78,
        "priceList": [
            {
                "price": 12939.71,
                "ask": 9,
                "bid": 33
            },
            {
                "price": 12850.89,
                "ask": 55,
                "bid": 53
            },
            {
                "price": 12837.42,
                "ask": 49,
                "bid": 91
            },
            {
                "price": 12997.57,
                "ask": 6,
                "bid": 32
            },
            {
                "price": 12703.68,
                "ask": 49,
                "bid": 40
            },
            {
                "price": 12948.59,
                "ask": 75,
                "bid": 48
            },
            {
                "price": 12982.75,
                "ask": 55,
                "bid": 0
            },
            {
                "price": 12882.22,
                "ask": 9,
                "bid": 20
            },
            {
                "price": 12775.91,
                "ask": 31,
                "bid": 3
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc71ecf3d67fb6f4ae",
        "high": 12923.97,
        "open": 12812.68,
        "close": 12876.91,
        "low": 12815.72,
        "priceList": [
            {
                "price": 12883.32,
                "ask": 16,
                "bid": 7
            },
            {
                "price": 12976.29,
                "ask": 29,
                "bid": 81
            },
            {
                "price": 12741.62,
                "ask": 48,
                "bid": 48
            },
            {
                "price": 12912.08,
                "ask": 100,
                "bid": 60
            },
            {
                "price": 12999.74,
                "ask": 35,
                "bid": 39
            },
            {
                "price": 12998.97,
                "ask": 50,
                "bid": 7
            },
            {
                "price": 12851.28,
                "ask": 2,
                "bid": 55
            },
            {
                "price": 12890.01,
                "ask": 66,
                "bid": 44
            },
            {
                "price": 12736.06,
                "ask": 54,
                "bid": 78
            },
            {
                "price": 12955.79,
                "ask": 66,
                "bid": 68
            },
            {
                "price": 12934.52,
                "ask": 19,
                "bid": 99
            },
            {
                "price": 12944.24,
                "ask": 16,
                "bid": 59
            },
            {
                "price": 12743.19,
                "ask": 27,
                "bid": 20
            },
            {
                "price": 12760.73,
                "ask": 49,
                "bid": 97
            },
            {
                "price": 12799.61,
                "ask": 41,
                "bid": 68
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcffa3dcc9d1f561c3",
        "high": 12819.04,
        "open": 12752.7,
        "close": 12736.75,
        "low": 12722.62,
        "priceList": [
            {
                "price": 12837.39,
                "ask": 20,
                "bid": 26
            },
            {
                "price": 12760.48,
                "ask": 37,
                "bid": 31
            },
            {
                "price": 12988.12,
                "ask": 37,
                "bid": 36
            },
            {
                "price": 12732.98,
                "ask": 70,
                "bid": 36
            },
            {
                "price": 12705.62,
                "ask": 52,
                "bid": 92
            },
            {
                "price": 12841.83,
                "ask": 79,
                "bid": 19
            },
            {
                "price": 12777.29,
                "ask": 85,
                "bid": 38
            },
            {
                "price": 12837.32,
                "ask": 19,
                "bid": 17
            },
            {
                "price": 12768.52,
                "ask": 21,
                "bid": 64
            },
            {
                "price": 12724.66,
                "ask": 22,
                "bid": 12
            },
            {
                "price": 12702.39,
                "ask": 8,
                "bid": 86
            },
            {
                "price": 12766.48,
                "ask": 38,
                "bid": 33
            },
            {
                "price": 12731.04,
                "ask": 18,
                "bid": 83
            },
            {
                "price": 12838.76,
                "ask": 2,
                "bid": 41
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc1c16ef98416a95d8",
        "high": 12724.56,
        "open": 12724.89,
        "close": 12816.75,
        "low": 12859.3,
        "priceList": [
            {
                "price": 12815.75,
                "ask": 89,
                "bid": 72
            },
            {
                "price": 12924.89,
                "ask": 77,
                "bid": 59
            },
            {
                "price": 12913.23,
                "ask": 69,
                "bid": 28
            },
            {
                "price": 12799.78,
                "ask": 77,
                "bid": 86
            },
            {
                "price": 12981.69,
                "ask": 84,
                "bid": 38
            },
            {
                "price": 12817.02,
                "ask": 25,
                "bid": 82
            },
            {
                "price": 12907.73,
                "ask": 54,
                "bid": 41
            },
            {
                "price": 12784.7,
                "ask": 39,
                "bid": 45
            },
            {
                "price": 12923.67,
                "ask": 99,
                "bid": 50
            },
            {
                "price": 12897.53,
                "ask": 66,
                "bid": 64
            },
            {
                "price": 12826.21,
                "ask": 46,
                "bid": 97
            },
            {
                "price": 12708.56,
                "ask": 69,
                "bid": 75
            },
            {
                "price": 12980.76,
                "ask": 89,
                "bid": 14
            },
            {
                "price": 12780.83,
                "ask": 13,
                "bid": 19
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc7c238a22175c241d",
        "high": 12853.05,
        "open": 12771.09,
        "close": 12983.58,
        "low": 12995.43,
        "priceList": [
            {
                "price": 12991.28,
                "ask": 31,
                "bid": 3
            },
            {
                "price": 12700.73,
                "ask": 1,
                "bid": 51
            },
            {
                "price": 12795.37,
                "ask": 22,
                "bid": 0
            },
            {
                "price": 12882.51,
                "ask": 86,
                "bid": 23
            },
            {
                "price": 12707.08,
                "ask": 64,
                "bid": 45
            },
            {
                "price": 12875.9,
                "ask": 18,
                "bid": 57
            },
            {
                "price": 12771.18,
                "ask": 0,
                "bid": 56
            },
            {
                "price": 12966.46,
                "ask": 81,
                "bid": 24
            },
            {
                "price": 12784.97,
                "ask": 85,
                "bid": 54
            },
            {
                "price": 12965.66,
                "ask": 63,
                "bid": 51
            },
            {
                "price": 12750.17,
                "ask": 31,
                "bid": 9
            },
            {
                "price": 12765.85,
                "ask": 24,
                "bid": 58
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc99abed6030c96db4",
        "high": 12932.73,
        "open": 12867.63,
        "close": 12767.35,
        "low": 12717.67,
        "priceList": [
            {
                "price": 12833.52,
                "ask": 35,
                "bid": 54
            },
            {
                "price": 12917.49,
                "ask": 20,
                "bid": 65
            },
            {
                "price": 12997.36,
                "ask": 79,
                "bid": 45
            },
            {
                "price": 12975.13,
                "ask": 35,
                "bid": 78
            },
            {
                "price": 12741.46,
                "ask": 29,
                "bid": 4
            },
            {
                "price": 12996.76,
                "ask": 63,
                "bid": 76
            },
            {
                "price": 12732.32,
                "ask": 59,
                "bid": 65
            },
            {
                "price": 12729.8,
                "ask": 51,
                "bid": 0
            },
            {
                "price": 12876.34,
                "ask": 52,
                "bid": 0
            },
            {
                "price": 12929.71,
                "ask": 42,
                "bid": 54
            },
            {
                "price": 12893.03,
                "ask": 39,
                "bid": 37
            },
            {
                "price": 12815.2,
                "ask": 79,
                "bid": 15
            },
            {
                "price": 12734.43,
                "ask": 34,
                "bid": 5
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc0304c807351c0f5c",
        "high": 12784.66,
        "open": 12942.76,
        "close": 12884.15,
        "low": 12947.25,
        "priceList": [
            {
                "price": 12890.67,
                "ask": 17,
                "bid": 7
            },
            {
                "price": 12986.97,
                "ask": 97,
                "bid": 76
            },
            {
                "price": 12710.68,
                "ask": 12,
                "bid": 70
            },
            {
                "price": 12816.68,
                "ask": 53,
                "bid": 41
            },
            {
                "price": 12989.23,
                "ask": 65,
                "bid": 50
            },
            {
                "price": 12901.65,
                "ask": 61,
                "bid": 5
            },
            {
                "price": 12949.77,
                "ask": 51,
                "bid": 30
            },
            {
                "price": 12868.92,
                "ask": 70,
                "bid": 70
            },
            {
                "price": 12789.19,
                "ask": 71,
                "bid": 5
            },
            {
                "price": 12941.56,
                "ask": 2,
                "bid": 59
            },
            {
                "price": 12816.19,
                "ask": 87,
                "bid": 98
            },
            {
                "price": 12867.16,
                "ask": 38,
                "bid": 48
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc61a1da8fd5a409ac",
        "high": 12916.28,
        "open": 12995.7,
        "close": 12793.9,
        "low": 12912.33,
        "priceList": [
            {
                "price": 12799.95,
                "ask": 17,
                "bid": 49
            },
            {
                "price": 12863.98,
                "ask": 35,
                "bid": 53
            },
            {
                "price": 12970.56,
                "ask": 94,
                "bid": 83
            },
            {
                "price": 12706.23,
                "ask": 5,
                "bid": 24
            },
            {
                "price": 12731.41,
                "ask": 53,
                "bid": 86
            },
            {
                "price": 12979.48,
                "ask": 97,
                "bid": 25
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcb1979ed93e61737e",
        "high": 12780.05,
        "open": 12832.71,
        "close": 12790.04,
        "low": 12817.37,
        "priceList": [
            {
                "price": 12925.41,
                "ask": 25,
                "bid": 79
            },
            {
                "price": 12988.3,
                "ask": 56,
                "bid": 18
            },
            {
                "price": 12954.54,
                "ask": 80,
                "bid": 75
            },
            {
                "price": 12762.63,
                "ask": 94,
                "bid": 32
            },
            {
                "price": 12743.96,
                "ask": 37,
                "bid": 66
            },
            {
                "price": 12967.18,
                "ask": 74,
                "bid": 62
            },
            {
                "price": 12913.84,
                "ask": 39,
                "bid": 79
            },
            {
                "price": 12902.29,
                "ask": 45,
                "bid": 38
            },
            {
                "price": 12842.15,
                "ask": 89,
                "bid": 68
            },
            {
                "price": 12863.03,
                "ask": 79,
                "bid": 59
            },
            {
                "price": 12820.54,
                "ask": 11,
                "bid": 29
            },
            {
                "price": 12735.42,
                "ask": 61,
                "bid": 65
            },
            {
                "price": 12856.11,
                "ask": 93,
                "bid": 60
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc8b91133c554cd659",
        "high": 12803.16,
        "open": 12852.75,
        "close": 12962.01,
        "low": 12759.09,
        "priceList": [
            {
                "price": 12703.05,
                "ask": 31,
                "bid": 3
            },
            {
                "price": 12783.61,
                "ask": 81,
                "bid": 88
            },
            {
                "price": 12873.09,
                "ask": 37,
                "bid": 80
            },
            {
                "price": 12911.04,
                "ask": 32,
                "bid": 44
            },
            {
                "price": 12977.49,
                "ask": 95,
                "bid": 91
            },
            {
                "price": 12778.22,
                "ask": 8,
                "bid": 70
            },
            {
                "price": 12781.32,
                "ask": 27,
                "bid": 20
            },
            {
                "price": 12826.16,
                "ask": 53,
                "bid": 63
            },
            {
                "price": 12872.71,
                "ask": 56,
                "bid": 58
            },
            {
                "price": 12787.06,
                "ask": 39,
                "bid": 43
            },
            {
                "price": 12911.15,
                "ask": 40,
                "bid": 48
            },
            {
                "price": 12872.51,
                "ask": 32,
                "bid": 41
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc55c8971b9dde10a3",
        "high": 12863.77,
        "open": 12787.46,
        "close": 12939.32,
        "low": 12995.09,
        "priceList": [
            {
                "price": 12897.15,
                "ask": 16,
                "bid": 14
            },
            {
                "price": 12872.94,
                "ask": 53,
                "bid": 63
            },
            {
                "price": 12937.54,
                "ask": 58,
                "bid": 89
            },
            {
                "price": 12759.11,
                "ask": 54,
                "bid": 89
            },
            {
                "price": 12985.47,
                "ask": 10,
                "bid": 34
            },
            {
                "price": 12992.05,
                "ask": 79,
                "bid": 93
            },
            {
                "price": 12828.61,
                "ask": 1,
                "bid": 53
            },
            {
                "price": 12759.91,
                "ask": 28,
                "bid": 57
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dcebf72dc904e711f6",
        "high": 12832.04,
        "open": 12756.78,
        "close": 12890.7,
        "low": 12804.16,
        "priceList": [
            {
                "price": 12765.68,
                "ask": 9,
                "bid": 73
            },
            {
                "price": 12958.33,
                "ask": 91,
                "bid": 41
            },
            {
                "price": 12844.85,
                "ask": 32,
                "bid": 52
            },
            {
                "price": 12905.09,
                "ask": 74,
                "bid": 91
            },
            {
                "price": 12794.07,
                "ask": 36,
                "bid": 73
            },
            {
                "price": 12975.11,
                "ask": 83,
                "bid": 68
            },
            {
                "price": 12769.52,
                "ask": 15,
                "bid": 12
            },
            {
                "price": 12846.52,
                "ask": 44,
                "bid": 8
            },
            {
                "price": 12906.5,
                "ask": 24,
                "bid": 77
            },
            {
                "price": 12941.26,
                "ask": 100,
                "bid": 11
            },
            {
                "price": 12921.93,
                "ask": 45,
                "bid": 59
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcc2574c85522fff1d",
        "high": 12950.23,
        "open": 12995.67,
        "close": 12847.06,
        "low": 12858.37,
        "priceList": [
            {
                "price": 12851.49,
                "ask": 48,
                "bid": 95
            },
            {
                "price": 12943.41,
                "ask": 64,
                "bid": 96
            },
            {
                "price": 12749.52,
                "ask": 100,
                "bid": 92
            },
            {
                "price": 12737.56,
                "ask": 43,
                "bid": 61
            },
            {
                "price": 12821.86,
                "ask": 38,
                "bid": 53
            },
            {
                "price": 12949.11,
                "ask": 68,
                "bid": 38
            },
            {
                "price": 12778.85,
                "ask": 33,
                "bid": 49
            },
            {
                "price": 12945.04,
                "ask": 86,
                "bid": 7
            },
            {
                "price": 12744.31,
                "ask": 38,
                "bid": 11
            },
            {
                "price": 12819.77,
                "ask": 80,
                "bid": 0
            },
            {
                "price": 12745.67,
                "ask": 18,
                "bid": 69
            },
            {
                "price": 12990.21,
                "ask": 98,
                "bid": 68
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc0ea02edabeade1d7",
        "high": 12808.59,
        "open": 12783.92,
        "close": 12994.89,
        "low": 12756.95,
        "priceList": [
            {
                "price": 12967.75,
                "ask": 48,
                "bid": 17
            },
            {
                "price": 12783.54,
                "ask": 99,
                "bid": 45
            },
            {
                "price": 12828.59,
                "ask": 79,
                "bid": 23
            },
            {
                "price": 12730.35,
                "ask": 9,
                "bid": 5
            },
            {
                "price": 12879.01,
                "ask": 73,
                "bid": 6
            },
            {
                "price": 12998.54,
                "ask": 70,
                "bid": 20
            },
            {
                "price": 12823.64,
                "ask": 48,
                "bid": 15
            },
            {
                "price": 12927.45,
                "ask": 43,
                "bid": 24
            },
            {
                "price": 12757.68,
                "ask": 25,
                "bid": 17
            },
            {
                "price": 12744.97,
                "ask": 47,
                "bid": 74
            },
            {
                "price": 12862.73,
                "ask": 2,
                "bid": 0
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc476625669ac9e5b7",
        "high": 12785.61,
        "open": 12882.13,
        "close": 12991.6,
        "low": 12871.37,
        "priceList": [
            {
                "price": 12811.98,
                "ask": 56,
                "bid": 78
            },
            {
                "price": 12916.54,
                "ask": 34,
                "bid": 91
            },
            {
                "price": 12999.18,
                "ask": 27,
                "bid": 59
            },
            {
                "price": 12835.96,
                "ask": 60,
                "bid": 77
            },
            {
                "price": 12820.21,
                "ask": 69,
                "bid": 41
            },
            {
                "price": 12882.37,
                "ask": 98,
                "bid": 42
            },
            {
                "price": 12701.14,
                "ask": 82,
                "bid": 74
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc4295d976dbabf876",
        "high": 12767.91,
        "open": 12986.52,
        "close": 12969.45,
        "low": 12793.12,
        "priceList": [
            {
                "price": 12911.22,
                "ask": 67,
                "bid": 50
            },
            {
                "price": 12948.03,
                "ask": 37,
                "bid": 9
            },
            {
                "price": 12815.51,
                "ask": 17,
                "bid": 49
            },
            {
                "price": 12864.82,
                "ask": 92,
                "bid": 14
            },
            {
                "price": 12955.05,
                "ask": 67,
                "bid": 97
            },
            {
                "price": 12847.24,
                "ask": 5,
                "bid": 48
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc8bf0202021b7cd8c",
        "high": 12981.89,
        "open": 12739.67,
        "close": 12882.76,
        "low": 12931.39,
        "priceList": [
            {
                "price": 12943.06,
                "ask": 33,
                "bid": 85
            },
            {
                "price": 12963.4,
                "ask": 14,
                "bid": 15
            },
            {
                "price": 12711.81,
                "ask": 4,
                "bid": 66
            },
            {
                "price": 12917.93,
                "ask": 69,
                "bid": 79
            },
            {
                "price": 12937.47,
                "ask": 5,
                "bid": 69
            },
            {
                "price": 12855.36,
                "ask": 5,
                "bid": 51
            },
            {
                "price": 12911.1,
                "ask": 87,
                "bid": 87
            },
            {
                "price": 12934.99,
                "ask": 20,
                "bid": 30
            },
            {
                "price": 12750.32,
                "ask": 26,
                "bid": 77
            },
            {
                "price": 12878.86,
                "ask": 2,
                "bid": 60
            },
            {
                "price": 12841.1,
                "ask": 11,
                "bid": 78
            },
            {
                "price": 12817.06,
                "ask": 20,
                "bid": 22
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc2c14eb4f156b0fcc",
        "high": 12756.25,
        "open": 12772.52,
        "close": 12775.98,
        "low": 12778.82,
        "priceList": [
            {
                "price": 12713.43,
                "ask": 18,
                "bid": 50
            },
            {
                "price": 12916.69,
                "ask": 35,
                "bid": 80
            },
            {
                "price": 12860.91,
                "ask": 24,
                "bid": 54
            },
            {
                "price": 12791.35,
                "ask": 62,
                "bid": 6
            },
            {
                "price": 12741.85,
                "ask": 57,
                "bid": 18
            },
            {
                "price": 12936.44,
                "ask": 57,
                "bid": 62
            },
            {
                "price": 12865.05,
                "ask": 47,
                "bid": 76
            },
            {
                "price": 12789.72,
                "ask": 46,
                "bid": 80
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc2287573537f9babd",
        "high": 12862.56,
        "open": 12898.37,
        "close": 12944.37,
        "low": 12865.53,
        "priceList": [
            {
                "price": 12785.95,
                "ask": 79,
                "bid": 44
            },
            {
                "price": 12910.95,
                "ask": 4,
                "bid": 52
            },
            {
                "price": 12975.65,
                "ask": 16,
                "bid": 91
            },
            {
                "price": 12796.84,
                "ask": 100,
                "bid": 51
            },
            {
                "price": 12772.04,
                "ask": 85,
                "bid": 13
            },
            {
                "price": 12709.54,
                "ask": 61,
                "bid": 67
            },
            {
                "price": 12851.38,
                "ask": 12,
                "bid": 24
            },
            {
                "price": 12930.72,
                "ask": 76,
                "bid": 19
            },
            {
                "price": 12707.78,
                "ask": 10,
                "bid": 1
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc0d2149949f0af6b1",
        "high": 12987.62,
        "open": 12753.88,
        "close": 12881.28,
        "low": 12911.23,
        "priceList": [
            {
                "price": 12963.72,
                "ask": 91,
                "bid": 98
            },
            {
                "price": 12979.13,
                "ask": 98,
                "bid": 14
            },
            {
                "price": 12979.63,
                "ask": 50,
                "bid": 10
            },
            {
                "price": 12773.72,
                "ask": 18,
                "bid": 14
            },
            {
                "price": 12780.95,
                "ask": 87,
                "bid": 77
            },
            {
                "price": 12903.09,
                "ask": 88,
                "bid": 9
            },
            {
                "price": 12923.24,
                "ask": 72,
                "bid": 79
            },
            {
                "price": 12996.33,
                "ask": 42,
                "bid": 1
            },
            {
                "price": 12854.66,
                "ask": 50,
                "bid": 5
            },
            {
                "price": 12710.84,
                "ask": 22,
                "bid": 82
            },
            {
                "price": 12961.25,
                "ask": 0,
                "bid": 33
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc67fbdc847fe1435c",
        "high": 12741.69,
        "open": 12951.79,
        "close": 12847.6,
        "low": 12928.37,
        "priceList": [
            {
                "price": 12931.61,
                "ask": 10,
                "bid": 31
            },
            {
                "price": 12804.18,
                "ask": 23,
                "bid": 2
            },
            {
                "price": 12854.68,
                "ask": 29,
                "bid": 76
            },
            {
                "price": 12777.52,
                "ask": 37,
                "bid": 83
            },
            {
                "price": 12721.01,
                "ask": 63,
                "bid": 6
            },
            {
                "price": 12819.24,
                "ask": 23,
                "bid": 62
            },
            {
                "price": 12979.88,
                "ask": 71,
                "bid": 17
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc2de3be09eab6a3ff",
        "high": 12920.95,
        "open": 12830.69,
        "close": 12724.32,
        "low": 12976.67,
        "priceList": [
            {
                "price": 12826.72,
                "ask": 72,
                "bid": 78
            },
            {
                "price": 12910.86,
                "ask": 14,
                "bid": 56
            },
            {
                "price": 12877.9,
                "ask": 19,
                "bid": 38
            },
            {
                "price": 12869.1,
                "ask": 20,
                "bid": 44
            },
            {
                "price": 12753.53,
                "ask": 76,
                "bid": 87
            },
            {
                "price": 12994.1,
                "ask": 35,
                "bid": 50
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc8c19ff92a64ed514",
        "high": 12972.66,
        "open": 12751.9,
        "close": 12982.51,
        "low": 12817.46,
        "priceList": [
            {
                "price": 12949.58,
                "ask": 75,
                "bid": 99
            },
            {
                "price": 12807.03,
                "ask": 54,
                "bid": 51
            },
            {
                "price": 12855.51,
                "ask": 40,
                "bid": 41
            },
            {
                "price": 12767.58,
                "ask": 22,
                "bid": 74
            },
            {
                "price": 12739.47,
                "ask": 45,
                "bid": 68
            },
            {
                "price": 12899.42,
                "ask": 79,
                "bid": 94
            },
            {
                "price": 12740.31,
                "ask": 75,
                "bid": 25
            },
            {
                "price": 12799.59,
                "ask": 68,
                "bid": 72
            },
            {
                "price": 12722.88,
                "ask": 9,
                "bid": 91
            },
            {
                "price": 12840.83,
                "ask": 79,
                "bid": 66
            },
            {
                "price": 12862.3,
                "ask": 95,
                "bid": 30
            },
            {
                "price": 12820.2,
                "ask": 20,
                "bid": 14
            },
            {
                "price": 12820.28,
                "ask": 72,
                "bid": 33
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc9fc4264236f80ef0",
        "high": 12790.88,
        "open": 12965.17,
        "close": 12984.31,
        "low": 12839.24,
        "priceList": [
            {
                "price": 12915.78,
                "ask": 35,
                "bid": 75
            },
            {
                "price": 12839.37,
                "ask": 27,
                "bid": 79
            },
            {
                "price": 12954.05,
                "ask": 55,
                "bid": 85
            },
            {
                "price": 12828.08,
                "ask": 35,
                "bid": 79
            },
            {
                "price": 12781.87,
                "ask": 79,
                "bid": 45
            },
            {
                "price": 12823.53,
                "ask": 92,
                "bid": 28
            },
            {
                "price": 12777.84,
                "ask": 70,
                "bid": 39
            },
            {
                "price": 12808.24,
                "ask": 94,
                "bid": 1
            },
            {
                "price": 12925.42,
                "ask": 29,
                "bid": 18
            },
            {
                "price": 12955.49,
                "ask": 10,
                "bid": 59
            },
            {
                "price": 12938.19,
                "ask": 89,
                "bid": 16
            },
            {
                "price": 12826.66,
                "ask": 12,
                "bid": 78
            },
            {
                "price": 12940.71,
                "ask": 41,
                "bid": 57
            },
            {
                "price": 12993.6,
                "ask": 25,
                "bid": 99
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcdd7c3d2977eb9cd8",
        "high": 12958.33,
        "open": 12860.62,
        "close": 12720.32,
        "low": 12935.21,
        "priceList": [
            {
                "price": 12746.26,
                "ask": 61,
                "bid": 55
            },
            {
                "price": 12883.35,
                "ask": 60,
                "bid": 67
            },
            {
                "price": 12780.05,
                "ask": 82,
                "bid": 70
            },
            {
                "price": 12961.34,
                "ask": 82,
                "bid": 63
            },
            {
                "price": 12755.62,
                "ask": 78,
                "bid": 21
            },
            {
                "price": 12874.57,
                "ask": 76,
                "bid": 76
            },
            {
                "price": 12898.8,
                "ask": 93,
                "bid": 59
            },
            {
                "price": 12883.89,
                "ask": 9,
                "bid": 76
            },
            {
                "price": 12904.95,
                "ask": 16,
                "bid": 73
            },
            {
                "price": 12704.02,
                "ask": 37,
                "bid": 50
            },
            {
                "price": 12784,
                "ask": 54,
                "bid": 82
            },
            {
                "price": 12985.28,
                "ask": 92,
                "bid": 6
            },
            {
                "price": 12757.72,
                "ask": 22,
                "bid": 100
            },
            {
                "price": 12756.73,
                "ask": 15,
                "bid": 48
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcbc26fccc348e90f3",
        "high": 12862.63,
        "open": 12780.62,
        "close": 12825.45,
        "low": 12947.62,
        "priceList": [
            {
                "price": 12845.21,
                "ask": 16,
                "bid": 6
            },
            {
                "price": 12762.44,
                "ask": 58,
                "bid": 67
            },
            {
                "price": 12998.27,
                "ask": 82,
                "bid": 43
            },
            {
                "price": 12870.56,
                "ask": 20,
                "bid": 51
            },
            {
                "price": 12860.31,
                "ask": 68,
                "bid": 36
            },
            {
                "price": 12794.66,
                "ask": 50,
                "bid": 26
            },
            {
                "price": 12864.96,
                "ask": 40,
                "bid": 53
            },
            {
                "price": 12712.86,
                "ask": 15,
                "bid": 88
            },
            {
                "price": 12991.55,
                "ask": 90,
                "bid": 9
            },
            {
                "price": 12954.23,
                "ask": 92,
                "bid": 40
            },
            {
                "price": 12789.35,
                "ask": 98,
                "bid": 91
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcbe218dd2171e2ac9",
        "high": 12779.59,
        "open": 12898.51,
        "close": 12908.41,
        "low": 12953.36,
        "priceList": [
            {
                "price": 12774.14,
                "ask": 62,
                "bid": 64
            },
            {
                "price": 12790.62,
                "ask": 62,
                "bid": 70
            },
            {
                "price": 12842.66,
                "ask": 74,
                "bid": 15
            },
            {
                "price": 12985.6,
                "ask": 47,
                "bid": 86
            },
            {
                "price": 12916.23,
                "ask": 40,
                "bid": 8
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc7350de678d118d36",
        "high": 12711.03,
        "open": 12859.24,
        "close": 12722.9,
        "low": 12737.63,
        "priceList": [
            {
                "price": 12842.35,
                "ask": 49,
                "bid": 9
            },
            {
                "price": 12933.76,
                "ask": 73,
                "bid": 33
            },
            {
                "price": 12840.45,
                "ask": 29,
                "bid": 53
            },
            {
                "price": 12797.5,
                "ask": 18,
                "bid": 78
            },
            {
                "price": 12968.84,
                "ask": 79,
                "bid": 4
            },
            {
                "price": 12927.75,
                "ask": 25,
                "bid": 50
            },
            {
                "price": 12858.31,
                "ask": 32,
                "bid": 61
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcedd47bf4f3526bea",
        "high": 12947.72,
        "open": 12822.19,
        "close": 12872.2,
        "low": 12729.98,
        "priceList": [
            {
                "price": 12929.41,
                "ask": 41,
                "bid": 8
            },
            {
                "price": 12940.77,
                "ask": 16,
                "bid": 7
            },
            {
                "price": 12822.86,
                "ask": 81,
                "bid": 42
            },
            {
                "price": 12935.03,
                "ask": 22,
                "bid": 0
            },
            {
                "price": 12935.08,
                "ask": 33,
                "bid": 8
            },
            {
                "price": 12811.93,
                "ask": 19,
                "bid": 82
            },
            {
                "price": 12922.7,
                "ask": 84,
                "bid": 16
            },
            {
                "price": 12894.67,
                "ask": 85,
                "bid": 71
            },
            {
                "price": 12928.82,
                "ask": 36,
                "bid": 7
            },
            {
                "price": 12775.69,
                "ask": 12,
                "bid": 22
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dced08e7d44b729ce4",
        "high": 12771.6,
        "open": 12796.26,
        "close": 12973.25,
        "low": 12834.88,
        "priceList": [
            {
                "price": 12925.89,
                "ask": 69,
                "bid": 75
            },
            {
                "price": 12854.39,
                "ask": 73,
                "bid": 15
            },
            {
                "price": 12913.32,
                "ask": 89,
                "bid": 29
            },
            {
                "price": 12709.21,
                "ask": 30,
                "bid": 13
            },
            {
                "price": 12840.14,
                "ask": 49,
                "bid": 55
            },
            {
                "price": 12782.26,
                "ask": 30,
                "bid": 70
            },
            {
                "price": 12891.39,
                "ask": 5,
                "bid": 33
            },
            {
                "price": 12953.24,
                "ask": 64,
                "bid": 36
            },
            {
                "price": 12869.95,
                "ask": 33,
                "bid": 55
            },
            {
                "price": 12825.56,
                "ask": 14,
                "bid": 30
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc59d4d80229839fdc",
        "high": 12789.72,
        "open": 12736.73,
        "close": 12814.63,
        "low": 12819.73,
        "priceList": [
            {
                "price": 12788.23,
                "ask": 68,
                "bid": 34
            },
            {
                "price": 12885.35,
                "ask": 1,
                "bid": 27
            },
            {
                "price": 12927.67,
                "ask": 90,
                "bid": 81
            },
            {
                "price": 12988.94,
                "ask": 13,
                "bid": 7
            },
            {
                "price": 12830,
                "ask": 73,
                "bid": 6
            },
            {
                "price": 12857.05,
                "ask": 59,
                "bid": 58
            },
            {
                "price": 12807.47,
                "ask": 52,
                "bid": 31
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc21527335c123b6cf",
        "high": 12834.27,
        "open": 12973.55,
        "close": 12738.48,
        "low": 12930.93,
        "priceList": [
            {
                "price": 12930.37,
                "ask": 82,
                "bid": 36
            },
            {
                "price": 12881.76,
                "ask": 37,
                "bid": 77
            },
            {
                "price": 12871.87,
                "ask": 43,
                "bid": 95
            },
            {
                "price": 12974.01,
                "ask": 15,
                "bid": 55
            },
            {
                "price": 12917.03,
                "ask": 85,
                "bid": 3
            },
            {
                "price": 12794.7,
                "ask": 98,
                "bid": 46
            },
            {
                "price": 12725.12,
                "ask": 78,
                "bid": 7
            },
            {
                "price": 12935.5,
                "ask": 29,
                "bid": 23
            },
            {
                "price": 12886.24,
                "ask": 28,
                "bid": 27
            },
            {
                "price": 12941.95,
                "ask": 95,
                "bid": 21
            },
            {
                "price": 12923.66,
                "ask": 4,
                "bid": 65
            },
            {
                "price": 12910.42,
                "ask": 13,
                "bid": 23
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc259f56f76a207554",
        "high": 12871.17,
        "open": 12926.22,
        "close": 12766.22,
        "low": 12948.9,
        "priceList": [
            {
                "price": 12823.88,
                "ask": 11,
                "bid": 54
            },
            {
                "price": 12946.17,
                "ask": 34,
                "bid": 98
            },
            {
                "price": 12986.7,
                "ask": 7,
                "bid": 36
            },
            {
                "price": 12992.81,
                "ask": 97,
                "bid": 78
            },
            {
                "price": 12759.06,
                "ask": 14,
                "bid": 58
            },
            {
                "price": 12919.94,
                "ask": 43,
                "bid": 44
            },
            {
                "price": 12968.25,
                "ask": 0,
                "bid": 90
            },
            {
                "price": 12877.64,
                "ask": 93,
                "bid": 44
            },
            {
                "price": 12843.68,
                "ask": 13,
                "bid": 58
            },
            {
                "price": 12848.12,
                "ask": 12,
                "bid": 72
            },
            {
                "price": 12990.52,
                "ask": 80,
                "bid": 74
            },
            {
                "price": 12849.15,
                "ask": 78,
                "bid": 68
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc9694206d43e47e9f",
        "high": 12749.85,
        "open": 12996.07,
        "close": 12849.54,
        "low": 12924.66,
        "priceList": [
            {
                "price": 12944.27,
                "ask": 51,
                "bid": 65
            },
            {
                "price": 12792.54,
                "ask": 56,
                "bid": 2
            },
            {
                "price": 12889.73,
                "ask": 84,
                "bid": 56
            },
            {
                "price": 12971.45,
                "ask": 59,
                "bid": 35
            },
            {
                "price": 12866.83,
                "ask": 54,
                "bid": 37
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc2b2880ff2bc7515f",
        "high": 12864.26,
        "open": 12761.73,
        "close": 12914,
        "low": 12762.5,
        "priceList": [
            {
                "price": 12873.16,
                "ask": 9,
                "bid": 28
            },
            {
                "price": 12762.28,
                "ask": 20,
                "bid": 14
            },
            {
                "price": 12998.85,
                "ask": 8,
                "bid": 100
            },
            {
                "price": 12794.11,
                "ask": 25,
                "bid": 45
            },
            {
                "price": 12855.13,
                "ask": 27,
                "bid": 6
            },
            {
                "price": 12914.23,
                "ask": 94,
                "bid": 31
            },
            {
                "price": 12789.61,
                "ask": 85,
                "bid": 88
            },
            {
                "price": 12753.62,
                "ask": 33,
                "bid": 73
            },
            {
                "price": 12858.94,
                "ask": 80,
                "bid": 36
            },
            {
                "price": 12921.24,
                "ask": 55,
                "bid": 25
            },
            {
                "price": 12864.03,
                "ask": 57,
                "bid": 46
            },
            {
                "price": 12981.04,
                "ask": 31,
                "bid": 100
            },
            {
                "price": 12835.41,
                "ask": 55,
                "bid": 77
            },
            {
                "price": 12929.5,
                "ask": 52,
                "bid": 86
            },
            {
                "price": 12835.7,
                "ask": 59,
                "bid": 3
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcf77d61c524ac0d9a",
        "high": 12953.29,
        "open": 12886.45,
        "close": 12799.64,
        "low": 12754.66,
        "priceList": [
            {
                "price": 12978.24,
                "ask": 48,
                "bid": 68
            },
            {
                "price": 12742.21,
                "ask": 86,
                "bid": 7
            },
            {
                "price": 12760.4,
                "ask": 87,
                "bid": 93
            },
            {
                "price": 12904.83,
                "ask": 31,
                "bid": 29
            },
            {
                "price": 12996.59,
                "ask": 18,
                "bid": 51
            },
            {
                "price": 12745.36,
                "ask": 40,
                "bid": 82
            },
            {
                "price": 12911.71,
                "ask": 20,
                "bid": 32
            },
            {
                "price": 12763.72,
                "ask": 58,
                "bid": 55
            },
            {
                "price": 12771.01,
                "ask": 93,
                "bid": 0
            },
            {
                "price": 12773.35,
                "ask": 84,
                "bid": 65
            },
            {
                "price": 12965.76,
                "ask": 53,
                "bid": 39
            },
            {
                "price": 12989.46,
                "ask": 28,
                "bid": 72
            },
            {
                "price": 12759.48,
                "ask": 86,
                "bid": 94
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc188dcfdf139dc4fe",
        "high": 12839.19,
        "open": 12842.52,
        "close": 12777.46,
        "low": 12898.64,
        "priceList": [
            {
                "price": 12951.35,
                "ask": 81,
                "bid": 89
            },
            {
                "price": 12964.65,
                "ask": 7,
                "bid": 47
            },
            {
                "price": 12778.06,
                "ask": 6,
                "bid": 85
            },
            {
                "price": 12971.72,
                "ask": 64,
                "bid": 30
            },
            {
                "price": 12748.04,
                "ask": 57,
                "bid": 60
            },
            {
                "price": 12956.63,
                "ask": 18,
                "bid": 28
            },
            {
                "price": 12786.9,
                "ask": 27,
                "bid": 15
            },
            {
                "price": 12877.38,
                "ask": 93,
                "bid": 82
            },
            {
                "price": 12829.69,
                "ask": 32,
                "bid": 12
            },
            {
                "price": 12841.51,
                "ask": 81,
                "bid": 97
            },
            {
                "price": 12982.81,
                "ask": 99,
                "bid": 28
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc6733b750ebb02b2a",
        "high": 12903.69,
        "open": 12947.34,
        "close": 12987.61,
        "low": 12903.92,
        "priceList": [
            {
                "price": 12879.92,
                "ask": 89,
                "bid": 32
            },
            {
                "price": 12825.55,
                "ask": 18,
                "bid": 39
            },
            {
                "price": 12880.32,
                "ask": 25,
                "bid": 83
            },
            {
                "price": 12862.17,
                "ask": 30,
                "bid": 94
            },
            {
                "price": 12810.9,
                "ask": 30,
                "bid": 100
            },
            {
                "price": 12787.65,
                "ask": 63,
                "bid": 93
            },
            {
                "price": 12917.77,
                "ask": 70,
                "bid": 96
            },
            {
                "price": 12993.67,
                "ask": 11,
                "bid": 69
            },
            {
                "price": 12766.84,
                "ask": 32,
                "bid": 83
            },
            {
                "price": 12832.41,
                "ask": 79,
                "bid": 96
            },
            {
                "price": 12806.36,
                "ask": 85,
                "bid": 91
            },
            {
                "price": 12872.7,
                "ask": 72,
                "bid": 50
            },
            {
                "price": 12853.63,
                "ask": 5,
                "bid": 86
            },
            {
                "price": 12995.5,
                "ask": 83,
                "bid": 15
            },
            {
                "price": 12990.23,
                "ask": 98,
                "bid": 24
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dce866ad35125f7b26",
        "high": 12948.52,
        "open": 12984.77,
        "close": 12948.85,
        "low": 12887.94,
        "priceList": [
            {
                "price": 12911.98,
                "ask": 97,
                "bid": 16
            },
            {
                "price": 12926.47,
                "ask": 87,
                "bid": 72
            },
            {
                "price": 12890.87,
                "ask": 3,
                "bid": 13
            },
            {
                "price": 12829.4,
                "ask": 34,
                "bid": 25
            },
            {
                "price": 12866.21,
                "ask": 95,
                "bid": 83
            },
            {
                "price": 12764.1,
                "ask": 31,
                "bid": 0
            },
            {
                "price": 12987.93,
                "ask": 96,
                "bid": 94
            },
            {
                "price": 12972.55,
                "ask": 32,
                "bid": 23
            },
            {
                "price": 12838.34,
                "ask": 73,
                "bid": 72
            },
            {
                "price": 12967.98,
                "ask": 39,
                "bid": 27
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc03933b0113f712a2",
        "high": 12709.41,
        "open": 12782.46,
        "close": 12902.84,
        "low": 12904.29,
        "priceList": [
            {
                "price": 12938.96,
                "ask": 64,
                "bid": 33
            },
            {
                "price": 12904.48,
                "ask": 46,
                "bid": 15
            },
            {
                "price": 12884.81,
                "ask": 11,
                "bid": 18
            },
            {
                "price": 12740.35,
                "ask": 90,
                "bid": 3
            },
            {
                "price": 12787.83,
                "ask": 51,
                "bid": 69
            },
            {
                "price": 12836.55,
                "ask": 97,
                "bid": 87
            },
            {
                "price": 12993.23,
                "ask": 59,
                "bid": 44
            },
            {
                "price": 12939.33,
                "ask": 66,
                "bid": 61
            },
            {
                "price": 12937.51,
                "ask": 80,
                "bid": 54
            },
            {
                "price": 12988.55,
                "ask": 28,
                "bid": 27
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc5cfe50d689dd619e",
        "high": 12710.3,
        "open": 12808.91,
        "close": 12737.38,
        "low": 12954.07,
        "priceList": [
            {
                "price": 12764.64,
                "ask": 80,
                "bid": 69
            },
            {
                "price": 12859.8,
                "ask": 11,
                "bid": 54
            },
            {
                "price": 12880.8,
                "ask": 64,
                "bid": 41
            },
            {
                "price": 12926.62,
                "ask": 2,
                "bid": 99
            },
            {
                "price": 12983.81,
                "ask": 75,
                "bid": 73
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcd5bc3b25789b6643",
        "high": 12701.64,
        "open": 12883.15,
        "close": 12773.89,
        "low": 12940.31,
        "priceList": [
            {
                "price": 12983.9,
                "ask": 74,
                "bid": 42
            },
            {
                "price": 12832.62,
                "ask": 21,
                "bid": 99
            },
            {
                "price": 12715.09,
                "ask": 33,
                "bid": 66
            },
            {
                "price": 12843.58,
                "ask": 10,
                "bid": 97
            },
            {
                "price": 12785.7,
                "ask": 84,
                "bid": 99
            },
            {
                "price": 12907,
                "ask": 29,
                "bid": 47
            },
            {
                "price": 12888.68,
                "ask": 37,
                "bid": 81
            },
            {
                "price": 12860,
                "ask": 87,
                "bid": 10
            },
            {
                "price": 12924.65,
                "ask": 92,
                "bid": 17
            },
            {
                "price": 12858.3,
                "ask": 6,
                "bid": 76
            },
            {
                "price": 12867.23,
                "ask": 52,
                "bid": 33
            },
            {
                "price": 12851.04,
                "ask": 57,
                "bid": 49
            },
            {
                "price": 12731.69,
                "ask": 5,
                "bid": 57
            },
            {
                "price": 12886.45,
                "ask": 16,
                "bid": 57
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcec62c76db7d57ebf",
        "high": 12958.16,
        "open": 12892.28,
        "close": 12977.14,
        "low": 12788.33,
        "priceList": [
            {
                "price": 12704.9,
                "ask": 37,
                "bid": 100
            },
            {
                "price": 12997.59,
                "ask": 46,
                "bid": 19
            },
            {
                "price": 12869.82,
                "ask": 71,
                "bid": 91
            },
            {
                "price": 12986.02,
                "ask": 43,
                "bid": 13
            },
            {
                "price": 12869.41,
                "ask": 65,
                "bid": 38
            },
            {
                "price": 12886.54,
                "ask": 47,
                "bid": 36
            },
            {
                "price": 12897.34,
                "ask": 51,
                "bid": 25
            },
            {
                "price": 12906.91,
                "ask": 77,
                "bid": 11
            },
            {
                "price": 12978.8,
                "ask": 96,
                "bid": 59
            },
            {
                "price": 12781.55,
                "ask": 29,
                "bid": 92
            },
            {
                "price": 12902.83,
                "ask": 70,
                "bid": 17
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc91eff4af3752c70a",
        "high": 12937.38,
        "open": 12718.12,
        "close": 12756.1,
        "low": 12734.55,
        "priceList": [
            {
                "price": 12753,
                "ask": 43,
                "bid": 43
            },
            {
                "price": 12799.42,
                "ask": 94,
                "bid": 84
            },
            {
                "price": 12877.25,
                "ask": 24,
                "bid": 87
            },
            {
                "price": 12819.49,
                "ask": 64,
                "bid": 54
            },
            {
                "price": 12872.27,
                "ask": 7,
                "bid": 67
            },
            {
                "price": 12710.43,
                "ask": 85,
                "bid": 82
            },
            {
                "price": 12874.26,
                "ask": 76,
                "bid": 59
            },
            {
                "price": 12925.91,
                "ask": 93,
                "bid": 53
            },
            {
                "price": 12886.49,
                "ask": 30,
                "bid": 23
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcd13ef7ef3c33eb7e",
        "high": 12788.01,
        "open": 12981.33,
        "close": 12986.34,
        "low": 12801.93,
        "priceList": [
            {
                "price": 12755.32,
                "ask": 46,
                "bid": 82
            },
            {
                "price": 12993.74,
                "ask": 75,
                "bid": 54
            },
            {
                "price": 12796.58,
                "ask": 53,
                "bid": 29
            },
            {
                "price": 12829.95,
                "ask": 89,
                "bid": 4
            },
            {
                "price": 12864.08,
                "ask": 46,
                "bid": 92
            },
            {
                "price": 12771.08,
                "ask": 81,
                "bid": 53
            },
            {
                "price": 12873.21,
                "ask": 62,
                "bid": 13
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dced573951e8f1efe6",
        "high": 12926.57,
        "open": 12833.61,
        "close": 12730.05,
        "low": 12885.05,
        "priceList": [
            {
                "price": 12911.25,
                "ask": 32,
                "bid": 28
            },
            {
                "price": 12841.62,
                "ask": 44,
                "bid": 23
            },
            {
                "price": 12789.85,
                "ask": 70,
                "bid": 72
            },
            {
                "price": 12953.02,
                "ask": 58,
                "bid": 41
            },
            {
                "price": 12931.37,
                "ask": 75,
                "bid": 0
            },
            {
                "price": 12982.19,
                "ask": 13,
                "bid": 26
            },
            {
                "price": 12947.87,
                "ask": 51,
                "bid": 13
            },
            {
                "price": 12743.78,
                "ask": 97,
                "bid": 99
            },
            {
                "price": 12945.27,
                "ask": 49,
                "bid": 17
            },
            {
                "price": 12814.12,
                "ask": 34,
                "bid": 65
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dce770d8ba82119d68",
        "high": 12826.63,
        "open": 12853.25,
        "close": 12876.96,
        "low": 12757.44,
        "priceList": [
            {
                "price": 12784.04,
                "ask": 49,
                "bid": 67
            },
            {
                "price": 12764.25,
                "ask": 35,
                "bid": 37
            },
            {
                "price": 12730.62,
                "ask": 39,
                "bid": 14
            },
            {
                "price": 12854.14,
                "ask": 51,
                "bid": 79
            },
            {
                "price": 12980.56,
                "ask": 23,
                "bid": 13
            },
            {
                "price": 12909.89,
                "ask": 58,
                "bid": 87
            },
            {
                "price": 12904.39,
                "ask": 35,
                "bid": 35
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcdd2346ef5fc659fc",
        "high": 12706.81,
        "open": 12996.64,
        "close": 12809.7,
        "low": 12906.06,
        "priceList": [
            {
                "price": 12820.56,
                "ask": 70,
                "bid": 93
            },
            {
                "price": 12975.88,
                "ask": 90,
                "bid": 76
            },
            {
                "price": 12833.91,
                "ask": 39,
                "bid": 4
            },
            {
                "price": 12811.51,
                "ask": 90,
                "bid": 89
            },
            {
                "price": 12744.8,
                "ask": 39,
                "bid": 88
            },
            {
                "price": 12819.32,
                "ask": 90,
                "bid": 80
            },
            {
                "price": 12851.1,
                "ask": 91,
                "bid": 62
            },
            {
                "price": 12745.83,
                "ask": 39,
                "bid": 71
            },
            {
                "price": 12704.79,
                "ask": 42,
                "bid": 51
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc9cda853e8141c4f7",
        "high": 12955.08,
        "open": 12851.05,
        "close": 12816.29,
        "low": 12851.17,
        "priceList": [
            {
                "price": 12985.98,
                "ask": 43,
                "bid": 90
            },
            {
                "price": 12714.4,
                "ask": 87,
                "bid": 59
            },
            {
                "price": 12948.47,
                "ask": 2,
                "bid": 63
            },
            {
                "price": 12807.68,
                "ask": 10,
                "bid": 91
            },
            {
                "price": 12747.35,
                "ask": 70,
                "bid": 55
            },
            {
                "price": 12908.86,
                "ask": 7,
                "bid": 90
            },
            {
                "price": 12927.95,
                "ask": 74,
                "bid": 46
            },
            {
                "price": 12706.28,
                "ask": 79,
                "bid": 89
            },
            {
                "price": 12828.76,
                "ask": 41,
                "bid": 92
            },
            {
                "price": 12700.44,
                "ask": 48,
                "bid": 34
            },
            {
                "price": 12777.39,
                "ask": 10,
                "bid": 60
            },
            {
                "price": 12708.06,
                "ask": 5,
                "bid": 58
            },
            {
                "price": 12727.74,
                "ask": 2,
                "bid": 62
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dc161424eb49778c9a",
        "high": 12853.09,
        "open": 12918.06,
        "close": 12798.92,
        "low": 12975.57,
        "priceList": [
            {
                "price": 12930.71,
                "ask": 2,
                "bid": 87
            },
            {
                "price": 12731.59,
                "ask": 4,
                "bid": 59
            },
            {
                "price": 12868.68,
                "ask": 59,
                "bid": 44
            },
            {
                "price": 12813.85,
                "ask": 74,
                "bid": 49
            },
            {
                "price": 12813.01,
                "ask": 18,
                "bid": 31
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc9094424571c79b55",
        "high": 12849.71,
        "open": 12709.89,
        "close": 12860.8,
        "low": 12795.26,
        "priceList": [
            {
                "price": 12843.69,
                "ask": 81,
                "bid": 52
            },
            {
                "price": 12813.12,
                "ask": 4,
                "bid": 92
            },
            {
                "price": 12791.06,
                "ask": 6,
                "bid": 9
            },
            {
                "price": 12998.89,
                "ask": 10,
                "bid": 24
            },
            {
                "price": 12777.23,
                "ask": 63,
                "bid": 75
            },
            {
                "price": 12909.54,
                "ask": 72,
                "bid": 8
            },
            {
                "price": 12964.83,
                "ask": 60,
                "bid": 13
            },
            {
                "price": 12953.39,
                "ask": 74,
                "bid": 91
            },
            {
                "price": 12993.44,
                "ask": 90,
                "bid": 85
            },
            {
                "price": 12863.21,
                "ask": 53,
                "bid": 23
            },
            {
                "price": 12897.77,
                "ask": 5,
                "bid": 50
            },
            {
                "price": 12775.28,
                "ask": 96,
                "bid": 61
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dcb1d8ab80523f12ca",
        "high": 12740,
        "open": 12725.6,
        "close": 12821.75,
        "low": 12940.01,
        "priceList": [
            {
                "price": 12842.44,
                "ask": 36,
                "bid": 5
            },
            {
                "price": 12714.44,
                "ask": 55,
                "bid": 8
            },
            {
                "price": 12786.22,
                "ask": 100,
                "bid": 55
            },
            {
                "price": 12994.26,
                "ask": 25,
                "bid": 40
            },
            {
                "price": 12935.73,
                "ask": 93,
                "bid": 42
            },
            {
                "price": 12844.53,
                "ask": 17,
                "bid": 7
            },
            {
                "price": 12803.05,
                "ask": 74,
                "bid": 35
            },
            {
                "price": 12830.37,
                "ask": 1,
                "bid": 53
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc4c738cd004564e55",
        "high": 12737.63,
        "open": 12722.31,
        "close": 12701.52,
        "low": 12858.64,
        "priceList": [
            {
                "price": 12908.82,
                "ask": 76,
                "bid": 53
            },
            {
                "price": 12735.27,
                "ask": 90,
                "bid": 62
            },
            {
                "price": 12921.84,
                "ask": 34,
                "bid": 37
            },
            {
                "price": 12810.33,
                "ask": 79,
                "bid": 53
            },
            {
                "price": 12806.48,
                "ask": 34,
                "bid": 2
            },
            {
                "price": 12821.18,
                "ask": 69,
                "bid": 56
            },
            {
                "price": 12877.31,
                "ask": 24,
                "bid": 61
            },
            {
                "price": 12987.23,
                "ask": 59,
                "bid": 94
            },
            {
                "price": 12762.18,
                "ask": 33,
                "bid": 61
            },
            {
                "price": 12901.92,
                "ask": 63,
                "bid": 18
            },
            {
                "price": 12810.98,
                "ask": 0,
                "bid": 24
            },
            {
                "price": 12904.17,
                "ask": 93,
                "bid": 32
            },
            {
                "price": 12752.01,
                "ask": 35,
                "bid": 13
            },
            {
                "price": 12995.45,
                "ask": 60,
                "bid": 39
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc655188b07d31a423",
        "high": 12785.84,
        "open": 12937.89,
        "close": 12852.12,
        "low": 12901.32,
        "priceList": [
            {
                "price": 12715.96,
                "ask": 14,
                "bid": 76
            },
            {
                "price": 12867.39,
                "ask": 96,
                "bid": 36
            },
            {
                "price": 12870.29,
                "ask": 65,
                "bid": 9
            },
            {
                "price": 12807.35,
                "ask": 10,
                "bid": 60
            },
            {
                "price": 12718.15,
                "ask": 8,
                "bid": 67
            },
            {
                "price": 12857.9,
                "ask": 81,
                "bid": 53
            },
            {
                "price": 12945.67,
                "ask": 73,
                "bid": 76
            },
            {
                "price": 12817.88,
                "ask": 37,
                "bid": 92
            },
            {
                "price": 12902.43,
                "ask": 44,
                "bid": 32
            },
            {
                "price": 12860.2,
                "ask": 20,
                "bid": 2
            },
            {
                "price": 12962.45,
                "ask": 75,
                "bid": 97
            },
            {
                "price": 12814.75,
                "ask": 7,
                "bid": 82
            },
            {
                "price": 12818.35,
                "ask": 79,
                "bid": 21
            },
            {
                "price": 12802.79,
                "ask": 85,
                "bid": 14
            },
            {
                "price": 12791.49,
                "ask": 52,
                "bid": 90
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc8387d08c57b9bd06",
        "high": 12780.06,
        "open": 12804.21,
        "close": 12802.45,
        "low": 12787.65,
        "priceList": [
            {
                "price": 12814.35,
                "ask": 9,
                "bid": 35
            },
            {
                "price": 12926.65,
                "ask": 80,
                "bid": 78
            },
            {
                "price": 12796.98,
                "ask": 62,
                "bid": 7
            },
            {
                "price": 12956.37,
                "ask": 20,
                "bid": 52
            },
            {
                "price": 12895.59,
                "ask": 75,
                "bid": 74
            },
            {
                "price": 12846.05,
                "ask": 74,
                "bid": 85
            },
            {
                "price": 12948.8,
                "ask": 44,
                "bid": 22
            },
            {
                "price": 12747.8,
                "ask": 79,
                "bid": 14
            },
            {
                "price": 12731.28,
                "ask": 23,
                "bid": 53
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc3b73f1a35c23e597",
        "high": 12795.78,
        "open": 12786.24,
        "close": 12896.97,
        "low": 12764.17,
        "priceList": [
            {
                "price": 12751.93,
                "ask": 2,
                "bid": 24
            },
            {
                "price": 12888.02,
                "ask": 16,
                "bid": 18
            },
            {
                "price": 12821.87,
                "ask": 24,
                "bid": 25
            },
            {
                "price": 12788.26,
                "ask": 61,
                "bid": 34
            },
            {
                "price": 12744.99,
                "ask": 25,
                "bid": 49
            },
            {
                "price": 12967.14,
                "ask": 95,
                "bid": 95
            },
            {
                "price": 12842.39,
                "ask": 89,
                "bid": 99
            },
            {
                "price": 12787.29,
                "ask": 51,
                "bid": 63
            },
            {
                "price": 12985.96,
                "ask": 12,
                "bid": 90
            },
            {
                "price": 12706.88,
                "ask": 6,
                "bid": 83
            },
            {
                "price": 12878.48,
                "ask": 15,
                "bid": 93
            },
            {
                "price": 12985.43,
                "ask": 9,
                "bid": 21
            },
            {
                "price": 12717.66,
                "ask": 80,
                "bid": 13
            },
            {
                "price": 12810.68,
                "ask": 97,
                "bid": 65
            },
            {
                "price": 12946.11,
                "ask": 49,
                "bid": 70
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcf3869b1c54a93bd7",
        "high": 12814.84,
        "open": 12936.94,
        "close": 12774.64,
        "low": 12835.58,
        "priceList": [
            {
                "price": 12776.23,
                "ask": 45,
                "bid": 77
            },
            {
                "price": 12834.93,
                "ask": 44,
                "bid": 77
            },
            {
                "price": 12827.83,
                "ask": 57,
                "bid": 4
            },
            {
                "price": 12873.24,
                "ask": 62,
                "bid": 75
            },
            {
                "price": 12726.7,
                "ask": 67,
                "bid": 62
            },
            {
                "price": 12750.88,
                "ask": 53,
                "bid": 20
            },
            {
                "price": 12766.13,
                "ask": 51,
                "bid": 27
            },
            {
                "price": 12932.37,
                "ask": 42,
                "bid": 7
            },
            {
                "price": 12982.8,
                "ask": 59,
                "bid": 82
            },
            {
                "price": 12929.9,
                "ask": 81,
                "bid": 86
            },
            {
                "price": 12789.44,
                "ask": 17,
                "bid": 74
            },
            {
                "price": 12966.61,
                "ask": 81,
                "bid": 52
            },
            {
                "price": 12852.62,
                "ask": 68,
                "bid": 76
            },
            {
                "price": 12708.26,
                "ask": 74,
                "bid": 30
            },
            {
                "price": 12773.05,
                "ask": 2,
                "bid": 36
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc704a04cc87dd2ead",
        "high": 12905.24,
        "open": 12828.33,
        "close": 12739.97,
        "low": 12988.68,
        "priceList": [
            {
                "price": 12869.71,
                "ask": 95,
                "bid": 71
            },
            {
                "price": 12793.24,
                "ask": 87,
                "bid": 85
            },
            {
                "price": 12890.55,
                "ask": 65,
                "bid": 6
            },
            {
                "price": 12731.36,
                "ask": 85,
                "bid": 2
            },
            {
                "price": 12865.14,
                "ask": 12,
                "bid": 70
            },
            {
                "price": 12900.31,
                "ask": 12,
                "bid": 28
            },
            {
                "price": 12887.33,
                "ask": 29,
                "bid": 28
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc929760e056aa9749",
        "high": 12720.18,
        "open": 12811.72,
        "close": 12844.84,
        "low": 12981.86,
        "priceList": [
            {
                "price": 12855.62,
                "ask": 21,
                "bid": 78
            },
            {
                "price": 12738.81,
                "ask": 64,
                "bid": 50
            },
            {
                "price": 12838.94,
                "ask": 75,
                "bid": 53
            },
            {
                "price": 12720.9,
                "ask": 81,
                "bid": 55
            },
            {
                "price": 12949.93,
                "ask": 27,
                "bid": 66
            },
            {
                "price": 12810.52,
                "ask": 40,
                "bid": 54
            },
            {
                "price": 12723.22,
                "ask": 0,
                "bid": 83
            },
            {
                "price": 12829.21,
                "ask": 7,
                "bid": 60
            },
            {
                "price": 12857.59,
                "ask": 29,
                "bid": 64
            },
            {
                "price": 12786.55,
                "ask": 85,
                "bid": 27
            },
            {
                "price": 12702,
                "ask": 59,
                "bid": 99
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcd42d5d4179f5add4",
        "high": 12796,
        "open": 12794.08,
        "close": 12960.14,
        "low": 12833.44,
        "priceList": [
            {
                "price": 12968.07,
                "ask": 35,
                "bid": 10
            },
            {
                "price": 12882.59,
                "ask": 37,
                "bid": 38
            },
            {
                "price": 12876.38,
                "ask": 11,
                "bid": 67
            },
            {
                "price": 12772.63,
                "ask": 65,
                "bid": 21
            },
            {
                "price": 12891.92,
                "ask": 4,
                "bid": 92
            },
            {
                "price": 12852.53,
                "ask": 42,
                "bid": 51
            },
            {
                "price": 12806.29,
                "ask": 34,
                "bid": 8
            },
            {
                "price": 12908.86,
                "ask": 13,
                "bid": 78
            },
            {
                "price": 12981.74,
                "ask": 74,
                "bid": 87
            },
            {
                "price": 12849.27,
                "ask": 33,
                "bid": 94
            },
            {
                "price": 12931.55,
                "ask": 54,
                "bid": 8
            },
            {
                "price": 12796,
                "ask": 61,
                "bid": 9
            },
            {
                "price": 12953.92,
                "ask": 89,
                "bid": 4
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcd46989eb6c66a174",
        "high": 12952.03,
        "open": 12959.14,
        "close": 12882.06,
        "low": 12861.06,
        "priceList": [
            {
                "price": 12828.1,
                "ask": 72,
                "bid": 7
            },
            {
                "price": 12960.47,
                "ask": 9,
                "bid": 83
            },
            {
                "price": 12943.54,
                "ask": 18,
                "bid": 21
            },
            {
                "price": 12900.16,
                "ask": 88,
                "bid": 30
            },
            {
                "price": 12894.1,
                "ask": 20,
                "bid": 34
            },
            {
                "price": 12758.55,
                "ask": 93,
                "bid": 1
            },
            {
                "price": 12982.05,
                "ask": 80,
                "bid": 93
            },
            {
                "price": 12745.2,
                "ask": 0,
                "bid": 28
            },
            {
                "price": 12769.14,
                "ask": 83,
                "bid": 84
            },
            {
                "price": 12709.79,
                "ask": 16,
                "bid": 93
            },
            {
                "price": 12888.42,
                "ask": 40,
                "bid": 36
            },
            {
                "price": 12893.97,
                "ask": 33,
                "bid": 66
            },
            {
                "price": 12816.1,
                "ask": 0,
                "bid": 94
            },
            {
                "price": 12908.91,
                "ask": 78,
                "bid": 61
            },
            {
                "price": 12914.19,
                "ask": 63,
                "bid": 14
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc2841bef3d2c2b625",
        "high": 12873.72,
        "open": 12796.62,
        "close": 12713.23,
        "low": 12897.73,
        "priceList": [
            {
                "price": 12857.88,
                "ask": 38,
                "bid": 25
            },
            {
                "price": 12804.02,
                "ask": 16,
                "bid": 5
            },
            {
                "price": 12876.08,
                "ask": 21,
                "bid": 43
            },
            {
                "price": 12778.21,
                "ask": 70,
                "bid": 8
            },
            {
                "price": 12970.81,
                "ask": 42,
                "bid": 13
            },
            {
                "price": 12795.3,
                "ask": 69,
                "bid": 90
            },
            {
                "price": 12837.84,
                "ask": 54,
                "bid": 21
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc6bfa6ccbe1e68e6a",
        "high": 12898.98,
        "open": 12956.94,
        "close": 12929.64,
        "low": 12710.88,
        "priceList": [
            {
                "price": 12732.5,
                "ask": 22,
                "bid": 10
            },
            {
                "price": 12998.92,
                "ask": 14,
                "bid": 31
            },
            {
                "price": 12731.98,
                "ask": 97,
                "bid": 18
            },
            {
                "price": 12713.65,
                "ask": 25,
                "bid": 86
            },
            {
                "price": 12729.2,
                "ask": 96,
                "bid": 22
            },
            {
                "price": 12925.81,
                "ask": 22,
                "bid": 84
            },
            {
                "price": 12800.86,
                "ask": 90,
                "bid": 22
            },
            {
                "price": 12845.68,
                "ask": 35,
                "bid": 3
            },
            {
                "price": 12868.21,
                "ask": 90,
                "bid": 46
            },
            {
                "price": 12829.79,
                "ask": 30,
                "bid": 88
            },
            {
                "price": 12741.63,
                "ask": 35,
                "bid": 44
            },
            {
                "price": 12785.19,
                "ask": 67,
                "bid": 93
            },
            {
                "price": 12739.98,
                "ask": 21,
                "bid": 50
            },
            {
                "price": 12895.63,
                "ask": 85,
                "bid": 38
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc2bd9dbe4b2366972",
        "high": 12849.22,
        "open": 12912.45,
        "close": 12972.9,
        "low": 12882.4,
        "priceList": [
            {
                "price": 12715.8,
                "ask": 16,
                "bid": 9
            },
            {
                "price": 12789.02,
                "ask": 80,
                "bid": 40
            },
            {
                "price": 12772.49,
                "ask": 33,
                "bid": 13
            },
            {
                "price": 12960.35,
                "ask": 16,
                "bid": 57
            },
            {
                "price": 12760.48,
                "ask": 5,
                "bid": 16
            },
            {
                "price": 12813.06,
                "ask": 56,
                "bid": 11
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc1f8ae9dea01a76d4",
        "high": 12918.18,
        "open": 12769.82,
        "close": 12720.16,
        "low": 12809.7,
        "priceList": [
            {
                "price": 12844.18,
                "ask": 93,
                "bid": 64
            },
            {
                "price": 12912.7,
                "ask": 11,
                "bid": 93
            },
            {
                "price": 12980.44,
                "ask": 83,
                "bid": 50
            },
            {
                "price": 12871.79,
                "ask": 29,
                "bid": 58
            },
            {
                "price": 12860.97,
                "ask": 60,
                "bid": 38
            },
            {
                "price": 12766.27,
                "ask": 55,
                "bid": 73
            },
            {
                "price": 12819.55,
                "ask": 80,
                "bid": 22
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc851666367311076c",
        "high": 12758.14,
        "open": 12899.89,
        "close": 12742.55,
        "low": 12763.23,
        "priceList": [
            {
                "price": 12988.24,
                "ask": 26,
                "bid": 8
            },
            {
                "price": 12730.99,
                "ask": 7,
                "bid": 10
            },
            {
                "price": 12939.25,
                "ask": 30,
                "bid": 96
            },
            {
                "price": 12983.34,
                "ask": 60,
                "bid": 30
            },
            {
                "price": 12968.08,
                "ask": 86,
                "bid": 46
            },
            {
                "price": 12967.37,
                "ask": 29,
                "bid": 30
            },
            {
                "price": 12906.63,
                "ask": 41,
                "bid": 1
            },
            {
                "price": 12806.43,
                "ask": 34,
                "bid": 9
            },
            {
                "price": 12978.24,
                "ask": 0,
                "bid": 76
            },
            {
                "price": 12724,
                "ask": 88,
                "bid": 81
            },
            {
                "price": 12909.18,
                "ask": 46,
                "bid": 26
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc1520b28f30423000",
        "high": 12891.72,
        "open": 12701.57,
        "close": 12904.7,
        "low": 12840.25,
        "priceList": [
            {
                "price": 12700.37,
                "ask": 27,
                "bid": 64
            },
            {
                "price": 12888.45,
                "ask": 11,
                "bid": 47
            },
            {
                "price": 12867.04,
                "ask": 45,
                "bid": 30
            },
            {
                "price": 12801.64,
                "ask": 38,
                "bid": 22
            },
            {
                "price": 12988.9,
                "ask": 8,
                "bid": 13
            },
            {
                "price": 12911.6,
                "ask": 93,
                "bid": 43
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcc20ae1a31ebaf066",
        "high": 12787.03,
        "open": 12787.47,
        "close": 12976.59,
        "low": 12801.48,
        "priceList": [
            {
                "price": 12745.68,
                "ask": 8,
                "bid": 4
            },
            {
                "price": 12941.56,
                "ask": 43,
                "bid": 99
            },
            {
                "price": 12764.59,
                "ask": 34,
                "bid": 2
            },
            {
                "price": 12992.28,
                "ask": 21,
                "bid": 78
            },
            {
                "price": 12933.88,
                "ask": 70,
                "bid": 34
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc9bb966450cb5363d",
        "high": 12923.4,
        "open": 12702.5,
        "close": 12876.71,
        "low": 12827.36,
        "priceList": [
            {
                "price": 12954.95,
                "ask": 42,
                "bid": 59
            },
            {
                "price": 12899.43,
                "ask": 90,
                "bid": 76
            },
            {
                "price": 12782.52,
                "ask": 60,
                "bid": 17
            },
            {
                "price": 12858.22,
                "ask": 33,
                "bid": 2
            },
            {
                "price": 12799.35,
                "ask": 27,
                "bid": 36
            },
            {
                "price": 12827.86,
                "ask": 40,
                "bid": 66
            },
            {
                "price": 12973.48,
                "ask": 96,
                "bid": 89
            },
            {
                "price": 12791.38,
                "ask": 78,
                "bid": 54
            },
            {
                "price": 12976.02,
                "ask": 75,
                "bid": 5
            },
            {
                "price": 12870.06,
                "ask": 25,
                "bid": 81
            },
            {
                "price": 12923.91,
                "ask": 61,
                "bid": 79
            },
            {
                "price": 12862.19,
                "ask": 97,
                "bid": 23
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dce724d210c89200c8",
        "high": 12826.02,
        "open": 12914.8,
        "close": 12838.01,
        "low": 12854.62,
        "priceList": [
            {
                "price": 12849.71,
                "ask": 93,
                "bid": 13
            },
            {
                "price": 12902.06,
                "ask": 21,
                "bid": 70
            },
            {
                "price": 12728.49,
                "ask": 57,
                "bid": 87
            },
            {
                "price": 12768.88,
                "ask": 31,
                "bid": 85
            },
            {
                "price": 12986.33,
                "ask": 87,
                "bid": 99
            },
            {
                "price": 12871.65,
                "ask": 78,
                "bid": 66
            },
            {
                "price": 12844.44,
                "ask": 32,
                "bid": 53
            },
            {
                "price": 12810.78,
                "ask": 78,
                "bid": 91
            },
            {
                "price": 12717.46,
                "ask": 49,
                "bid": 28
            },
            {
                "price": 12846.11,
                "ask": 11,
                "bid": 6
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dcdc5b644f71b6a561",
        "high": 12885.62,
        "open": 12746.61,
        "close": 12823.07,
        "low": 12985.47,
        "priceList": [
            {
                "price": 12863.31,
                "ask": 71,
                "bid": 41
            },
            {
                "price": 12789.24,
                "ask": 80,
                "bid": 42
            },
            {
                "price": 12999.51,
                "ask": 10,
                "bid": 48
            },
            {
                "price": 12830.92,
                "ask": 83,
                "bid": 6
            },
            {
                "price": 12836.56,
                "ask": 25,
                "bid": 15
            },
            {
                "price": 12932.79,
                "ask": 40,
                "bid": 38
            },
            {
                "price": 12966.84,
                "ask": 64,
                "bid": 32
            },
            {
                "price": 12809.76,
                "ask": 38,
                "bid": 61
            },
            {
                "price": 12985.46,
                "ask": 88,
                "bid": 84
            },
            {
                "price": 12791.19,
                "ask": 84,
                "bid": 64
            },
            {
                "price": 12927.15,
                "ask": 29,
                "bid": 41
            },
            {
                "price": 12787.36,
                "ask": 75,
                "bid": 19
            },
            {
                "price": 12713.07,
                "ask": 16,
                "bid": 35
            },
            {
                "price": 12951.29,
                "ask": 91,
                "bid": 46
            },
            {
                "price": 12964.53,
                "ask": 54,
                "bid": 53
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc167e05e1ee10ad5b",
        "high": 12793.33,
        "open": 12937.99,
        "close": 12707.63,
        "low": 12852.7,
        "priceList": [
            {
                "price": 12814.81,
                "ask": 3,
                "bid": 8
            },
            {
                "price": 12805.19,
                "ask": 40,
                "bid": 30
            },
            {
                "price": 12813.3,
                "ask": 85,
                "bid": 48
            },
            {
                "price": 12987.85,
                "ask": 91,
                "bid": 68
            },
            {
                "price": 12875.1,
                "ask": 60,
                "bid": 77
            },
            {
                "price": 12894.57,
                "ask": 15,
                "bid": 90
            },
            {
                "price": 12813.5,
                "ask": 100,
                "bid": 84
            },
            {
                "price": 12732.84,
                "ask": 8,
                "bid": 59
            },
            {
                "price": 12934.15,
                "ask": 70,
                "bid": 5
            },
            {
                "price": 12764.83,
                "ask": 69,
                "bid": 22
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc050f48054778c120",
        "high": 12777.75,
        "open": 12792.46,
        "close": 12893.54,
        "low": 12778.97,
        "priceList": [
            {
                "price": 12710.86,
                "ask": 79,
                "bid": 92
            },
            {
                "price": 12988.22,
                "ask": 79,
                "bid": 29
            },
            {
                "price": 12978.01,
                "ask": 59,
                "bid": 36
            },
            {
                "price": 12757.2,
                "ask": 19,
                "bid": 76
            },
            {
                "price": 12833.59,
                "ask": 30,
                "bid": 84
            },
            {
                "price": 12899.7,
                "ask": 74,
                "bid": 18
            },
            {
                "price": 12973.5,
                "ask": 21,
                "bid": 93
            },
            {
                "price": 12783.71,
                "ask": 30,
                "bid": 89
            },
            {
                "price": 12919.84,
                "ask": 87,
                "bid": 59
            },
            {
                "price": 12800.12,
                "ask": 62,
                "bid": 8
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc2f79b80119aec726",
        "high": 12867.1,
        "open": 12713.17,
        "close": 12960.3,
        "low": 12919.75,
        "priceList": [
            {
                "price": 12844.93,
                "ask": 51,
                "bid": 49
            },
            {
                "price": 12731.64,
                "ask": 89,
                "bid": 23
            },
            {
                "price": 12946.47,
                "ask": 86,
                "bid": 6
            },
            {
                "price": 12886.99,
                "ask": 72,
                "bid": 46
            },
            {
                "price": 12784.03,
                "ask": 42,
                "bid": 71
            },
            {
                "price": 12710.59,
                "ask": 61,
                "bid": 52
            },
            {
                "price": 12828.03,
                "ask": 41,
                "bid": 35
            },
            {
                "price": 12720.37,
                "ask": 92,
                "bid": 0
            },
            {
                "price": 12705.9,
                "ask": 62,
                "bid": 1
            },
            {
                "price": 12781.69,
                "ask": 6,
                "bid": 45
            },
            {
                "price": 12967.55,
                "ask": 56,
                "bid": 65
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc5542306afc30f777",
        "high": 12899.63,
        "open": 12848.04,
        "close": 12797.6,
        "low": 12737.51,
        "priceList": [
            {
                "price": 12956.16,
                "ask": 97,
                "bid": 95
            },
            {
                "price": 12733.45,
                "ask": 55,
                "bid": 69
            },
            {
                "price": 12933.68,
                "ask": 64,
                "bid": 55
            },
            {
                "price": 12966.14,
                "ask": 33,
                "bid": 62
            },
            {
                "price": 12773.91,
                "ask": 6,
                "bid": 80
            },
            {
                "price": 12971.47,
                "ask": 30,
                "bid": 37
            },
            {
                "price": 12810.6,
                "ask": 52,
                "bid": 87
            },
            {
                "price": 12868.9,
                "ask": 82,
                "bid": 40
            },
            {
                "price": 12863.91,
                "ask": 63,
                "bid": 52
            },
            {
                "price": 12702.09,
                "ask": 51,
                "bid": 67
            },
            {
                "price": 12761.17,
                "ask": 80,
                "bid": 36
            },
            {
                "price": 12852.1,
                "ask": 1,
                "bid": 23
            },
            {
                "price": 12843.26,
                "ask": 47,
                "bid": 89
            },
            {
                "price": 12979.73,
                "ask": 12,
                "bid": 47
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc39163511d6f86358",
        "high": 12790.12,
        "open": 12987.84,
        "close": 12861.79,
        "low": 12803.57,
        "priceList": [
            {
                "price": 12765.89,
                "ask": 26,
                "bid": 76
            },
            {
                "price": 12975.49,
                "ask": 84,
                "bid": 43
            },
            {
                "price": 12984.13,
                "ask": 8,
                "bid": 11
            },
            {
                "price": 12904.31,
                "ask": 94,
                "bid": 79
            },
            {
                "price": 12861.97,
                "ask": 89,
                "bid": 13
            },
            {
                "price": 12724.87,
                "ask": 35,
                "bid": 32
            },
            {
                "price": 12796.24,
                "ask": 87,
                "bid": 50
            },
            {
                "price": 12899.27,
                "ask": 95,
                "bid": 33
            },
            {
                "price": 12995.74,
                "ask": 10,
                "bid": 98
            },
            {
                "price": 12776.09,
                "ask": 15,
                "bid": 79
            },
            {
                "price": 12884.39,
                "ask": 83,
                "bid": 5
            },
            {
                "price": 12730.98,
                "ask": 16,
                "bid": 98
            },
            {
                "price": 12728.58,
                "ask": 16,
                "bid": 65
            },
            {
                "price": 12900.42,
                "ask": 33,
                "bid": 51
            },
            {
                "price": 12866.69,
                "ask": 55,
                "bid": 58
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dcec201f544ad7a48a",
        "high": 12707.81,
        "open": 12736.2,
        "close": 12701.21,
        "low": 12965.75,
        "priceList": [
            {
                "price": 12783.34,
                "ask": 12,
                "bid": 1
            },
            {
                "price": 12826.71,
                "ask": 9,
                "bid": 94
            },
            {
                "price": 12890.46,
                "ask": 61,
                "bid": 53
            },
            {
                "price": 12732.58,
                "ask": 92,
                "bid": 34
            },
            {
                "price": 12886.88,
                "ask": 72,
                "bid": 35
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcd204b0abb27c72ca",
        "high": 12708.85,
        "open": 12747.61,
        "close": 12704.61,
        "low": 12853.58,
        "priceList": [
            {
                "price": 12977.79,
                "ask": 70,
                "bid": 86
            },
            {
                "price": 12742.19,
                "ask": 51,
                "bid": 89
            },
            {
                "price": 12762.08,
                "ask": 54,
                "bid": 47
            },
            {
                "price": 12978.47,
                "ask": 79,
                "bid": 96
            },
            {
                "price": 12820.09,
                "ask": 70,
                "bid": 47
            },
            {
                "price": 12977.15,
                "ask": 55,
                "bid": 44
            },
            {
                "price": 12819.06,
                "ask": 80,
                "bid": 91
            },
            {
                "price": 12836.42,
                "ask": 14,
                "bid": 98
            },
            {
                "price": 12971.58,
                "ask": 68,
                "bid": 66
            },
            {
                "price": 12865.01,
                "ask": 60,
                "bid": 76
            },
            {
                "price": 12739.3,
                "ask": 43,
                "bid": 100
            },
            {
                "price": 12824.34,
                "ask": 100,
                "bid": 1
            },
            {
                "price": 12806.24,
                "ask": 94,
                "bid": 2
            },
            {
                "price": 12808.32,
                "ask": 53,
                "bid": 69
            },
            {
                "price": 12965.28,
                "ask": 51,
                "bid": 29
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6647c66297bec4e7",
        "high": 12810.76,
        "open": 12811.26,
        "close": 12977.44,
        "low": 12797.1,
        "priceList": [
            {
                "price": 12885.36,
                "ask": 45,
                "bid": 100
            },
            {
                "price": 12828.94,
                "ask": 34,
                "bid": 9
            },
            {
                "price": 12767.99,
                "ask": 84,
                "bid": 2
            },
            {
                "price": 12837,
                "ask": 46,
                "bid": 19
            },
            {
                "price": 12872.43,
                "ask": 38,
                "bid": 43
            },
            {
                "price": 12803.9,
                "ask": 46,
                "bid": 4
            },
            {
                "price": 12848.76,
                "ask": 24,
                "bid": 61
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dc1d42aae2bda2e654",
        "high": 12798.23,
        "open": 12902.1,
        "close": 12845.18,
        "low": 12780.76,
        "priceList": [
            {
                "price": 12911.21,
                "ask": 12,
                "bid": 94
            },
            {
                "price": 12831.82,
                "ask": 56,
                "bid": 63
            },
            {
                "price": 12959.9,
                "ask": 17,
                "bid": 15
            },
            {
                "price": 12726.87,
                "ask": 100,
                "bid": 30
            },
            {
                "price": 12800.55,
                "ask": 99,
                "bid": 28
            },
            {
                "price": 12967.58,
                "ask": 5,
                "bid": 96
            },
            {
                "price": 12929.74,
                "ask": 45,
                "bid": 25
            },
            {
                "price": 12764.4,
                "ask": 30,
                "bid": 14
            },
            {
                "price": 12733.22,
                "ask": 38,
                "bid": 89
            },
            {
                "price": 12980.02,
                "ask": 65,
                "bid": 41
            },
            {
                "price": 12996.01,
                "ask": 48,
                "bid": 51
            },
            {
                "price": 12943.86,
                "ask": 67,
                "bid": 75
            },
            {
                "price": 12798.53,
                "ask": 72,
                "bid": 64
            },
            {
                "price": 12917.68,
                "ask": 97,
                "bid": 14
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dca16f3f8c27fc15bf",
        "high": 12766.18,
        "open": 12821.2,
        "close": 12782.39,
        "low": 12880.24,
        "priceList": [
            {
                "price": 12846.73,
                "ask": 68,
                "bid": 3
            },
            {
                "price": 12800.85,
                "ask": 19,
                "bid": 52
            },
            {
                "price": 12766.47,
                "ask": 6,
                "bid": 83
            },
            {
                "price": 12848.49,
                "ask": 93,
                "bid": 97
            },
            {
                "price": 12712.45,
                "ask": 52,
                "bid": 91
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcb6bd53e5fe3f036e",
        "high": 12812.31,
        "open": 12833.92,
        "close": 12717.19,
        "low": 12836.29,
        "priceList": [
            {
                "price": 12740.61,
                "ask": 0,
                "bid": 68
            },
            {
                "price": 12903.83,
                "ask": 34,
                "bid": 44
            },
            {
                "price": 12747.22,
                "ask": 56,
                "bid": 65
            },
            {
                "price": 12909.29,
                "ask": 89,
                "bid": 41
            },
            {
                "price": 12934.25,
                "ask": 82,
                "bid": 5
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcf3647a50e67014fb",
        "high": 12881.07,
        "open": 12997.72,
        "close": 12986.09,
        "low": 12716.3,
        "priceList": [
            {
                "price": 12839.2,
                "ask": 6,
                "bid": 85
            },
            {
                "price": 12845.31,
                "ask": 29,
                "bid": 81
            },
            {
                "price": 12786.05,
                "ask": 55,
                "bid": 69
            },
            {
                "price": 12764.93,
                "ask": 66,
                "bid": 99
            },
            {
                "price": 12946.56,
                "ask": 61,
                "bid": 47
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dcc1356c02045d2f2a",
        "high": 12786.24,
        "open": 12753.95,
        "close": 12866.49,
        "low": 12806.15,
        "priceList": [
            {
                "price": 12919.37,
                "ask": 18,
                "bid": 68
            },
            {
                "price": 12976.22,
                "ask": 57,
                "bid": 84
            },
            {
                "price": 12817.19,
                "ask": 59,
                "bid": 47
            },
            {
                "price": 12921.64,
                "ask": 32,
                "bid": 21
            },
            {
                "price": 12810.72,
                "ask": 48,
                "bid": 50
            },
            {
                "price": 12859.14,
                "ask": 94,
                "bid": 58
            },
            {
                "price": 12896.91,
                "ask": 3,
                "bid": 43
            },
            {
                "price": 12722.15,
                "ask": 72,
                "bid": 29
            },
            {
                "price": 12963.89,
                "ask": 45,
                "bid": 21
            },
            {
                "price": 12773.79,
                "ask": 48,
                "bid": 0
            },
            {
                "price": 12842.08,
                "ask": 82,
                "bid": 46
            },
            {
                "price": 12860.3,
                "ask": 47,
                "bid": 72
            },
            {
                "price": 12804.92,
                "ask": 62,
                "bid": 97
            },
            {
                "price": 12977.9,
                "ask": 22,
                "bid": 0
            },
            {
                "price": 12824.88,
                "ask": 61,
                "bid": 10
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dc6ebb057a0cbdb696",
        "high": 12865.35,
        "open": 12812.92,
        "close": 12970.7,
        "low": 12757.34,
        "priceList": [
            {
                "price": 12868.71,
                "ask": 14,
                "bid": 10
            },
            {
                "price": 12765.95,
                "ask": 1,
                "bid": 83
            },
            {
                "price": 12792.46,
                "ask": 65,
                "bid": 99
            },
            {
                "price": 12975.12,
                "ask": 49,
                "bid": 63
            },
            {
                "price": 12836.32,
                "ask": 36,
                "bid": 75
            },
            {
                "price": 12883.02,
                "ask": 92,
                "bid": 75
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc9e1f23758b8b364a",
        "high": 12974.68,
        "open": 12839.47,
        "close": 12742.5,
        "low": 12752.11,
        "priceList": [
            {
                "price": 12781.49,
                "ask": 100,
                "bid": 87
            },
            {
                "price": 12992.35,
                "ask": 69,
                "bid": 79
            },
            {
                "price": 12814.43,
                "ask": 31,
                "bid": 41
            },
            {
                "price": 12814.78,
                "ask": 54,
                "bid": 50
            },
            {
                "price": 12763.41,
                "ask": 21,
                "bid": 89
            },
            {
                "price": 12786.2,
                "ask": 58,
                "bid": 99
            },
            {
                "price": 12897.21,
                "ask": 61,
                "bid": 0
            },
            {
                "price": 12988.44,
                "ask": 24,
                "bid": 60
            },
            {
                "price": 12704.27,
                "ask": 27,
                "bid": 35
            },
            {
                "price": 12955.05,
                "ask": 37,
                "bid": 46
            },
            {
                "price": 12838.26,
                "ask": 74,
                "bid": 21
            },
            {
                "price": 12781.6,
                "ask": 28,
                "bid": 31
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc32cac5bcd836d2ad",
        "high": 12813.57,
        "open": 12959.17,
        "close": 12839.54,
        "low": 12995.5,
        "priceList": [
            {
                "price": 12881.85,
                "ask": 34,
                "bid": 90
            },
            {
                "price": 12765.45,
                "ask": 6,
                "bid": 41
            },
            {
                "price": 12948.24,
                "ask": 52,
                "bid": 88
            },
            {
                "price": 12914.45,
                "ask": 80,
                "bid": 32
            },
            {
                "price": 12837.16,
                "ask": 1,
                "bid": 73
            },
            {
                "price": 12986.1,
                "ask": 10,
                "bid": 29
            },
            {
                "price": 12715.17,
                "ask": 50,
                "bid": 87
            },
            {
                "price": 12988.33,
                "ask": 39,
                "bid": 99
            },
            {
                "price": 12973.62,
                "ask": 84,
                "bid": 93
            },
            {
                "price": 12919.26,
                "ask": 18,
                "bid": 51
            },
            {
                "price": 12998.75,
                "ask": 9,
                "bid": 23
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcae02658a9160a7d5",
        "high": 12867.78,
        "open": 12807.69,
        "close": 12831.21,
        "low": 12932.48,
        "priceList": [
            {
                "price": 12726.99,
                "ask": 28,
                "bid": 3
            },
            {
                "price": 12933.42,
                "ask": 55,
                "bid": 46
            },
            {
                "price": 12713.16,
                "ask": 15,
                "bid": 13
            },
            {
                "price": 12749.26,
                "ask": 80,
                "bid": 28
            },
            {
                "price": 12732.96,
                "ask": 75,
                "bid": 81
            },
            {
                "price": 12731.09,
                "ask": 56,
                "bid": 35
            },
            {
                "price": 12879.72,
                "ask": 39,
                "bid": 36
            },
            {
                "price": 12768.16,
                "ask": 92,
                "bid": 89
            },
            {
                "price": 12730,
                "ask": 47,
                "bid": 42
            },
            {
                "price": 12731.04,
                "ask": 86,
                "bid": 2
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc0b0ec718aa7b7d95",
        "high": 12758.8,
        "open": 12736.96,
        "close": 12719.17,
        "low": 12959.05,
        "priceList": [
            {
                "price": 12726.98,
                "ask": 20,
                "bid": 81
            },
            {
                "price": 12843.22,
                "ask": 58,
                "bid": 49
            },
            {
                "price": 12830.42,
                "ask": 10,
                "bid": 49
            },
            {
                "price": 12777.22,
                "ask": 95,
                "bid": 0
            },
            {
                "price": 12851.02,
                "ask": 9,
                "bid": 15
            },
            {
                "price": 12919.88,
                "ask": 98,
                "bid": 25
            },
            {
                "price": 12859.57,
                "ask": 86,
                "bid": 68
            },
            {
                "price": 12794.77,
                "ask": 97,
                "bid": 93
            },
            {
                "price": 12845.35,
                "ask": 65,
                "bid": 81
            },
            {
                "price": 12953.36,
                "ask": 32,
                "bid": 48
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc2bfb6300791581d3",
        "high": 12951.85,
        "open": 12763.48,
        "close": 12777.46,
        "low": 12925.29,
        "priceList": [
            {
                "price": 12863.57,
                "ask": 41,
                "bid": 15
            },
            {
                "price": 12970.87,
                "ask": 59,
                "bid": 1
            },
            {
                "price": 12937.3,
                "ask": 1,
                "bid": 73
            },
            {
                "price": 12831.29,
                "ask": 26,
                "bid": 56
            },
            {
                "price": 12892.9,
                "ask": 47,
                "bid": 2
            },
            {
                "price": 12754.81,
                "ask": 27,
                "bid": 80
            },
            {
                "price": 12895.95,
                "ask": 11,
                "bid": 37
            },
            {
                "price": 12776.34,
                "ask": 66,
                "bid": 41
            },
            {
                "price": 12839.41,
                "ask": 29,
                "bid": 86
            },
            {
                "price": 12838.94,
                "ask": 35,
                "bid": 27
            },
            {
                "price": 12771.39,
                "ask": 80,
                "bid": 19
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc5b41bc67ddb65762",
        "high": 12843.92,
        "open": 12815.5,
        "close": 12733.84,
        "low": 12749.16,
        "priceList": [
            {
                "price": 12836.87,
                "ask": 13,
                "bid": 48
            },
            {
                "price": 12788.14,
                "ask": 85,
                "bid": 79
            },
            {
                "price": 12891.51,
                "ask": 43,
                "bid": 46
            },
            {
                "price": 12802.61,
                "ask": 64,
                "bid": 44
            },
            {
                "price": 12741,
                "ask": 25,
                "bid": 90
            },
            {
                "price": 12775.61,
                "ask": 55,
                "bid": 91
            },
            {
                "price": 12850.07,
                "ask": 12,
                "bid": 90
            },
            {
                "price": 12844.41,
                "ask": 76,
                "bid": 67
            },
            {
                "price": 12779.52,
                "ask": 65,
                "bid": 76
            },
            {
                "price": 12997.28,
                "ask": 38,
                "bid": 19
            },
            {
                "price": 12742.25,
                "ask": 78,
                "bid": 28
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dcc8fd2363ffa6a6c6",
        "high": 12996.83,
        "open": 12722.21,
        "close": 12976.75,
        "low": 12826.92,
        "priceList": [
            {
                "price": 12950.59,
                "ask": 60,
                "bid": 33
            },
            {
                "price": 12934.57,
                "ask": 31,
                "bid": 9
            },
            {
                "price": 12969.41,
                "ask": 5,
                "bid": 3
            },
            {
                "price": 12711.61,
                "ask": 13,
                "bid": 81
            },
            {
                "price": 12711.27,
                "ask": 90,
                "bid": 81
            },
            {
                "price": 12756.61,
                "ask": 22,
                "bid": 5
            },
            {
                "price": 12976.76,
                "ask": 36,
                "bid": 11
            },
            {
                "price": 12750.45,
                "ask": 23,
                "bid": 9
            },
            {
                "price": 12797.6,
                "ask": 96,
                "bid": 18
            },
            {
                "price": 12977.31,
                "ask": 48,
                "bid": 43
            },
            {
                "price": 12997.21,
                "ask": 65,
                "bid": 13
            },
            {
                "price": 12720.73,
                "ask": 12,
                "bid": 0
            },
            {
                "price": 12971.59,
                "ask": 33,
                "bid": 77
            },
            {
                "price": 12905.04,
                "ask": 72,
                "bid": 5
            },
            {
                "price": 12745.11,
                "ask": 89,
                "bid": 7
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dca27719e16a8f7b00",
        "high": 12742.49,
        "open": 12957.33,
        "close": 12921.46,
        "low": 12899.1,
        "priceList": [
            {
                "price": 12894.8,
                "ask": 10,
                "bid": 84
            },
            {
                "price": 12996.54,
                "ask": 93,
                "bid": 69
            },
            {
                "price": 12927.69,
                "ask": 67,
                "bid": 31
            },
            {
                "price": 12837.55,
                "ask": 74,
                "bid": 96
            },
            {
                "price": 12831.78,
                "ask": 5,
                "bid": 21
            },
            {
                "price": 12882,
                "ask": 14,
                "bid": 99
            },
            {
                "price": 12748.09,
                "ask": 40,
                "bid": 54
            },
            {
                "price": 12771.93,
                "ask": 52,
                "bid": 42
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc05c23a2e14d06086",
        "high": 12812.24,
        "open": 12726.57,
        "close": 12702.77,
        "low": 12891.75,
        "priceList": [
            {
                "price": 12893.18,
                "ask": 76,
                "bid": 61
            },
            {
                "price": 12879.5,
                "ask": 18,
                "bid": 50
            },
            {
                "price": 12934.6,
                "ask": 17,
                "bid": 62
            },
            {
                "price": 12872.75,
                "ask": 51,
                "bid": 22
            },
            {
                "price": 12772.94,
                "ask": 30,
                "bid": 75
            },
            {
                "price": 12901.32,
                "ask": 35,
                "bid": 0
            },
            {
                "price": 12983.2,
                "ask": 22,
                "bid": 15
            },
            {
                "price": 12997.53,
                "ask": 20,
                "bid": 84
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc4423574645e8c308",
        "high": 12867.23,
        "open": 12732.45,
        "close": 12919.8,
        "low": 12963.34,
        "priceList": [
            {
                "price": 12963.27,
                "ask": 5,
                "bid": 37
            },
            {
                "price": 12830.06,
                "ask": 73,
                "bid": 30
            },
            {
                "price": 12874.42,
                "ask": 27,
                "bid": 99
            },
            {
                "price": 12785.29,
                "ask": 51,
                "bid": 46
            },
            {
                "price": 12996.11,
                "ask": 75,
                "bid": 2
            },
            {
                "price": 12927.99,
                "ask": 59,
                "bid": 29
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dcbd590f873c8db88f",
        "high": 12794.23,
        "open": 12898.34,
        "close": 12992.77,
        "low": 12870.96,
        "priceList": [
            {
                "price": 12826.98,
                "ask": 61,
                "bid": 14
            },
            {
                "price": 12751.91,
                "ask": 54,
                "bid": 53
            },
            {
                "price": 12823.1,
                "ask": 23,
                "bid": 77
            },
            {
                "price": 12949.58,
                "ask": 7,
                "bid": 62
            },
            {
                "price": 12897.95,
                "ask": 17,
                "bid": 70
            },
            {
                "price": 12878.6,
                "ask": 20,
                "bid": 73
            },
            {
                "price": 12851.01,
                "ask": 66,
                "bid": 75
            },
            {
                "price": 12878.17,
                "ask": 2,
                "bid": 29
            },
            {
                "price": 12918.19,
                "ask": 0,
                "bid": 12
            },
            {
                "price": 12787.61,
                "ask": 85,
                "bid": 48
            },
            {
                "price": 12776.7,
                "ask": 72,
                "bid": 54
            },
            {
                "price": 12999.21,
                "ask": 94,
                "bid": 4
            },
            {
                "price": 12984.97,
                "ask": 36,
                "bid": 85
            },
            {
                "price": 12863,
                "ask": 9,
                "bid": 46
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcdb36ffd902c74eb2",
        "high": 12963.3,
        "open": 12820.22,
        "close": 12874.69,
        "low": 12788.15,
        "priceList": [
            {
                "price": 12952.54,
                "ask": 69,
                "bid": 92
            },
            {
                "price": 12710.03,
                "ask": 13,
                "bid": 1
            },
            {
                "price": 12936.31,
                "ask": 32,
                "bid": 88
            },
            {
                "price": 12749.06,
                "ask": 17,
                "bid": 61
            },
            {
                "price": 12878.32,
                "ask": 44,
                "bid": 67
            },
            {
                "price": 12711.87,
                "ask": 96,
                "bid": 3
            },
            {
                "price": 12912.13,
                "ask": 17,
                "bid": 78
            },
            {
                "price": 12856.3,
                "ask": 60,
                "bid": 63
            },
            {
                "price": 12858.42,
                "ask": 66,
                "bid": 87
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dc3c2f04d2a501a9b5",
        "high": 12987.88,
        "open": 12787.09,
        "close": 12841.05,
        "low": 12708.82,
        "priceList": [
            {
                "price": 12815.47,
                "ask": 78,
                "bid": 43
            },
            {
                "price": 12901.67,
                "ask": 93,
                "bid": 41
            },
            {
                "price": 12887.91,
                "ask": 23,
                "bid": 74
            },
            {
                "price": 12766.4,
                "ask": 36,
                "bid": 39
            },
            {
                "price": 12711.62,
                "ask": 3,
                "bid": 20
            },
            {
                "price": 12852.34,
                "ask": 51,
                "bid": 27
            },
            {
                "price": 12870.29,
                "ask": 83,
                "bid": 34
            },
            {
                "price": 12920.58,
                "ask": 3,
                "bid": 38
            },
            {
                "price": 12987.7,
                "ask": 34,
                "bid": 77
            },
            {
                "price": 12759.77,
                "ask": 28,
                "bid": 32
            },
            {
                "price": 12841.41,
                "ask": 84,
                "bid": 60
            },
            {
                "price": 12766.68,
                "ask": 68,
                "bid": 15
            },
            {
                "price": 12843.55,
                "ask": 98,
                "bid": 98
            },
            {
                "price": 12821.99,
                "ask": 1,
                "bid": 89
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc126276dd99bc2f96",
        "high": 12727.4,
        "open": 12854.72,
        "close": 12850.02,
        "low": 12763.51,
        "priceList": [
            {
                "price": 12941.02,
                "ask": 84,
                "bid": 30
            },
            {
                "price": 12789.24,
                "ask": 7,
                "bid": 71
            },
            {
                "price": 12761.81,
                "ask": 76,
                "bid": 34
            },
            {
                "price": 12782.74,
                "ask": 97,
                "bid": 94
            },
            {
                "price": 12951.53,
                "ask": 45,
                "bid": 80
            },
            {
                "price": 12846.71,
                "ask": 88,
                "bid": 45
            },
            {
                "price": 12929.42,
                "ask": 67,
                "bid": 25
            },
            {
                "price": 12732.51,
                "ask": 68,
                "bid": 84
            },
            {
                "price": 12750.35,
                "ask": 98,
                "bid": 13
            },
            {
                "price": 12940.71,
                "ask": 75,
                "bid": 36
            }
        ],
        "priceCount": 10
    },
    {
        "_id": "62ddd3dc15d77577b2119aa0",
        "high": 12879.65,
        "open": 12920.19,
        "close": 12975.5,
        "low": 12894.97,
        "priceList": [
            {
                "price": 12747.4,
                "ask": 67,
                "bid": 86
            },
            {
                "price": 12860.34,
                "ask": 1,
                "bid": 36
            },
            {
                "price": 12765.84,
                "ask": 95,
                "bid": 40
            },
            {
                "price": 12724.02,
                "ask": 72,
                "bid": 11
            },
            {
                "price": 12756.96,
                "ask": 50,
                "bid": 87
            },
            {
                "price": 12833.83,
                "ask": 76,
                "bid": 98
            },
            {
                "price": 12870.88,
                "ask": 77,
                "bid": 84
            }
        ],
        "priceCount": 7
    },
    {
        "_id": "62ddd3dcc2900ca6d0714241",
        "high": 12908.63,
        "open": 12721.91,
        "close": 12839.55,
        "low": 12866.98,
        "priceList": [
            {
                "price": 12995.89,
                "ask": 4,
                "bid": 15
            },
            {
                "price": 12859.64,
                "ask": 73,
                "bid": 41
            },
            {
                "price": 12904.1,
                "ask": 21,
                "bid": 51
            },
            {
                "price": 12838.87,
                "ask": 32,
                "bid": 44
            },
            {
                "price": 12800.54,
                "ask": 38,
                "bid": 81
            },
            {
                "price": 12845.88,
                "ask": 58,
                "bid": 80
            },
            {
                "price": 12999.73,
                "ask": 81,
                "bid": 30
            },
            {
                "price": 12806.08,
                "ask": 5,
                "bid": 97
            },
            {
                "price": 12918.1,
                "ask": 73,
                "bid": 95
            },
            {
                "price": 12818.26,
                "ask": 21,
                "bid": 71
            },
            {
                "price": 12939.88,
                "ask": 95,
                "bid": 17
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc3c59638861bc2bc7",
        "high": 12967.47,
        "open": 12803.07,
        "close": 12962.76,
        "low": 12723.43,
        "priceList": [
            {
                "price": 12835.01,
                "ask": 40,
                "bid": 62
            },
            {
                "price": 12701.32,
                "ask": 90,
                "bid": 18
            },
            {
                "price": 12860.21,
                "ask": 73,
                "bid": 87
            },
            {
                "price": 12808.66,
                "ask": 1,
                "bid": 60
            },
            {
                "price": 12836.93,
                "ask": 58,
                "bid": 94
            },
            {
                "price": 12747.2,
                "ask": 12,
                "bid": 67
            },
            {
                "price": 12946.69,
                "ask": 46,
                "bid": 78
            },
            {
                "price": 12899.81,
                "ask": 0,
                "bid": 80
            },
            {
                "price": 12941.08,
                "ask": 47,
                "bid": 68
            },
            {
                "price": 12826.97,
                "ask": 40,
                "bid": 3
            },
            {
                "price": 12766.65,
                "ask": 10,
                "bid": 50
            },
            {
                "price": 12919.77,
                "ask": 34,
                "bid": 3
            }
        ],
        "priceCount": 12
    },
    {
        "_id": "62ddd3dc8299e389a0c10108",
        "high": 12714.49,
        "open": 12743.98,
        "close": 12749.09,
        "low": 12808.91,
        "priceList": [
            {
                "price": 12706.83,
                "ask": 57,
                "bid": 56
            },
            {
                "price": 12950.56,
                "ask": 86,
                "bid": 69
            },
            {
                "price": 12867.27,
                "ask": 87,
                "bid": 1
            },
            {
                "price": 12826.53,
                "ask": 37,
                "bid": 99
            },
            {
                "price": 12745.75,
                "ask": 32,
                "bid": 17
            },
            {
                "price": 12768.23,
                "ask": 2,
                "bid": 46
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dca0acebca4cb5063a",
        "high": 12884.5,
        "open": 12896.08,
        "close": 12829.64,
        "low": 12983.51,
        "priceList": [
            {
                "price": 12831.1,
                "ask": 27,
                "bid": 75
            },
            {
                "price": 12976.76,
                "ask": 30,
                "bid": 41
            },
            {
                "price": 12847.9,
                "ask": 46,
                "bid": 94
            },
            {
                "price": 12830.08,
                "ask": 78,
                "bid": 12
            },
            {
                "price": 12904.05,
                "ask": 8,
                "bid": 76
            },
            {
                "price": 12963.49,
                "ask": 96,
                "bid": 91
            },
            {
                "price": 12710.44,
                "ask": 4,
                "bid": 89
            },
            {
                "price": 12908.56,
                "ask": 92,
                "bid": 55
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc0f681cfb9f615b38",
        "high": 12836.35,
        "open": 12785.26,
        "close": 12928.76,
        "low": 12808.85,
        "priceList": [
            {
                "price": 12935.29,
                "ask": 70,
                "bid": 17
            },
            {
                "price": 12746.81,
                "ask": 43,
                "bid": 6
            },
            {
                "price": 12831.21,
                "ask": 8,
                "bid": 97
            },
            {
                "price": 12939.3,
                "ask": 85,
                "bid": 52
            },
            {
                "price": 12783.17,
                "ask": 88,
                "bid": 37
            },
            {
                "price": 12928.49,
                "ask": 73,
                "bid": 53
            },
            {
                "price": 12738.97,
                "ask": 59,
                "bid": 39
            },
            {
                "price": 12859.99,
                "ask": 0,
                "bid": 22
            },
            {
                "price": 12993.81,
                "ask": 42,
                "bid": 44
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dce72874f98413cbb3",
        "high": 12700.65,
        "open": 12918.07,
        "close": 12981.89,
        "low": 12732.95,
        "priceList": [
            {
                "price": 12790.83,
                "ask": 42,
                "bid": 17
            },
            {
                "price": 12913.64,
                "ask": 47,
                "bid": 49
            },
            {
                "price": 12934.63,
                "ask": 82,
                "bid": 89
            },
            {
                "price": 12899.94,
                "ask": 72,
                "bid": 75
            },
            {
                "price": 12915.12,
                "ask": 20,
                "bid": 54
            },
            {
                "price": 12869.4,
                "ask": 39,
                "bid": 8
            },
            {
                "price": 12885.17,
                "ask": 47,
                "bid": 5
            },
            {
                "price": 12926.81,
                "ask": 76,
                "bid": 21
            },
            {
                "price": 12802.83,
                "ask": 41,
                "bid": 0
            }
        ],
        "priceCount": 9
    },
    {
        "_id": "62ddd3dcfde8eb88b80378d6",
        "high": 12986.17,
        "open": 12737.85,
        "close": 12776.24,
        "low": 12949.8,
        "priceList": [
            {
                "price": 12815.57,
                "ask": 11,
                "bid": 82
            },
            {
                "price": 12924.77,
                "ask": 34,
                "bid": 35
            },
            {
                "price": 12885.34,
                "ask": 87,
                "bid": 57
            },
            {
                "price": 12927.73,
                "ask": 21,
                "bid": 93
            },
            {
                "price": 12957.69,
                "ask": 66,
                "bid": 1
            },
            {
                "price": 12726.29,
                "ask": 31,
                "bid": 7
            }
        ],
        "priceCount": 6
    },
    {
        "_id": "62ddd3dc632579efa5e9ec85",
        "high": 12963.79,
        "open": 12810.75,
        "close": 12869.58,
        "low": 12963.34,
        "priceList": [
            {
                "price": 12869.46,
                "ask": 55,
                "bid": 29
            },
            {
                "price": 12760.4,
                "ask": 14,
                "bid": 10
            },
            {
                "price": 12858.77,
                "ask": 15,
                "bid": 71
            },
            {
                "price": 12952.33,
                "ask": 89,
                "bid": 45
            },
            {
                "price": 12725.73,
                "ask": 92,
                "bid": 8
            },
            {
                "price": 12970.7,
                "ask": 30,
                "bid": 46
            },
            {
                "price": 12977.8,
                "ask": 10,
                "bid": 81
            },
            {
                "price": 12780.77,
                "ask": 11,
                "bid": 15
            },
            {
                "price": 12997.07,
                "ask": 93,
                "bid": 94
            },
            {
                "price": 12952.06,
                "ask": 96,
                "bid": 88
            },
            {
                "price": 12854.55,
                "ask": 43,
                "bid": 4
            },
            {
                "price": 12745.23,
                "ask": 0,
                "bid": 59
            },
            {
                "price": 12753.73,
                "ask": 65,
                "bid": 0
            },
            {
                "price": 12768.31,
                "ask": 57,
                "bid": 55
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc9b02c92340afa637",
        "high": 12821.87,
        "open": 12967.18,
        "close": 12702.8,
        "low": 12793.62,
        "priceList": [
            {
                "price": 12882.9,
                "ask": 7,
                "bid": 65
            },
            {
                "price": 12871.29,
                "ask": 1,
                "bid": 77
            },
            {
                "price": 12822.75,
                "ask": 69,
                "bid": 13
            },
            {
                "price": 12719.82,
                "ask": 40,
                "bid": 31
            },
            {
                "price": 12845.58,
                "ask": 98,
                "bid": 60
            },
            {
                "price": 12758.45,
                "ask": 83,
                "bid": 15
            },
            {
                "price": 12936.01,
                "ask": 82,
                "bid": 93
            },
            {
                "price": 12812.33,
                "ask": 57,
                "bid": 8
            },
            {
                "price": 12981.71,
                "ask": 38,
                "bid": 16
            },
            {
                "price": 12965.42,
                "ask": 16,
                "bid": 64
            },
            {
                "price": 12906.69,
                "ask": 67,
                "bid": 61
            }
        ],
        "priceCount": 11
    },
    {
        "_id": "62ddd3dc11e8ad0e5eddeae2",
        "high": 12763.06,
        "open": 12798.95,
        "close": 12779.35,
        "low": 12861.19,
        "priceList": [
            {
                "price": 12888.89,
                "ask": 7,
                "bid": 83
            },
            {
                "price": 12759.48,
                "ask": 13,
                "bid": 87
            },
            {
                "price": 12758.55,
                "ask": 23,
                "bid": 95
            },
            {
                "price": 12771.74,
                "ask": 15,
                "bid": 10
            },
            {
                "price": 12792.07,
                "ask": 31,
                "bid": 61
            },
            {
                "price": 12956.72,
                "ask": 97,
                "bid": 65
            },
            {
                "price": 12952.84,
                "ask": 64,
                "bid": 65
            },
            {
                "price": 12754.35,
                "ask": 77,
                "bid": 76
            },
            {
                "price": 12867.23,
                "ask": 90,
                "bid": 24
            },
            {
                "price": 12836.13,
                "ask": 85,
                "bid": 43
            },
            {
                "price": 12913.82,
                "ask": 80,
                "bid": 86
            },
            {
                "price": 12706.38,
                "ask": 74,
                "bid": 51
            },
            {
                "price": 12942.53,
                "ask": 10,
                "bid": 26
            }
        ],
        "priceCount": 13
    },
    {
        "_id": "62ddd3dcb791bfbdd5990a60",
        "high": 12943.66,
        "open": 12964.81,
        "close": 12851.91,
        "low": 12906.31,
        "priceList": [
            {
                "price": 12780.23,
                "ask": 65,
                "bid": 15
            },
            {
                "price": 12762.82,
                "ask": 82,
                "bid": 13
            },
            {
                "price": 12997.05,
                "ask": 26,
                "bid": 20
            },
            {
                "price": 12715.58,
                "ask": 85,
                "bid": 67
            },
            {
                "price": 12940.82,
                "ask": 55,
                "bid": 25
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc2457c502c2aca64a",
        "high": 12991.41,
        "open": 12753.73,
        "close": 12791.12,
        "low": 12948.46,
        "priceList": [
            {
                "price": 12987,
                "ask": 52,
                "bid": 33
            },
            {
                "price": 12820.04,
                "ask": 40,
                "bid": 79
            },
            {
                "price": 12789.48,
                "ask": 92,
                "bid": 17
            },
            {
                "price": 12897.93,
                "ask": 90,
                "bid": 9
            },
            {
                "price": 12950.16,
                "ask": 60,
                "bid": 95
            },
            {
                "price": 12775.86,
                "ask": 60,
                "bid": 9
            },
            {
                "price": 12724.46,
                "ask": 98,
                "bid": 29
            },
            {
                "price": 12805.18,
                "ask": 92,
                "bid": 40
            }
        ],
        "priceCount": 8
    },
    {
        "_id": "62ddd3dc188fa28ae021994a",
        "high": 12858.65,
        "open": 12778.92,
        "close": 12940.34,
        "low": 12805.24,
        "priceList": [
            {
                "price": 12963.19,
                "ask": 38,
                "bid": 65
            },
            {
                "price": 12949.25,
                "ask": 87,
                "bid": 76
            },
            {
                "price": 12710.51,
                "ask": 12,
                "bid": 15
            },
            {
                "price": 12800.41,
                "ask": 56,
                "bid": 52
            },
            {
                "price": 12936.2,
                "ask": 21,
                "bid": 56
            },
            {
                "price": 12990.45,
                "ask": 87,
                "bid": 48
            },
            {
                "price": 12758.8,
                "ask": 52,
                "bid": 27
            },
            {
                "price": 12837.04,
                "ask": 56,
                "bid": 9
            },
            {
                "price": 12803.46,
                "ask": 15,
                "bid": 85
            },
            {
                "price": 12956.65,
                "ask": 40,
                "bid": 13
            },
            {
                "price": 12799.73,
                "ask": 87,
                "bid": 88
            },
            {
                "price": 12857.35,
                "ask": 88,
                "bid": 53
            },
            {
                "price": 12955.8,
                "ask": 48,
                "bid": 3
            },
            {
                "price": 12844.59,
                "ask": 21,
                "bid": 53
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dcd9c39a9965e55fa5",
        "high": 12732.74,
        "open": 12945.66,
        "close": 12700.65,
        "low": 12879.09,
        "priceList": [
            {
                "price": 12796.15,
                "ask": 79,
                "bid": 78
            },
            {
                "price": 12801.12,
                "ask": 16,
                "bid": 0
            },
            {
                "price": 12739.4,
                "ask": 18,
                "bid": 96
            },
            {
                "price": 12796.66,
                "ask": 45,
                "bid": 61
            },
            {
                "price": 12743.16,
                "ask": 87,
                "bid": 2
            },
            {
                "price": 12703.69,
                "ask": 25,
                "bid": 67
            },
            {
                "price": 12886.25,
                "ask": 2,
                "bid": 76
            },
            {
                "price": 12883.79,
                "ask": 20,
                "bid": 99
            },
            {
                "price": 12967.84,
                "ask": 5,
                "bid": 31
            },
            {
                "price": 12951.01,
                "ask": 57,
                "bid": 33
            },
            {
                "price": 12807.61,
                "ask": 32,
                "bid": 77
            },
            {
                "price": 12974.92,
                "ask": 21,
                "bid": 68
            },
            {
                "price": 12909.69,
                "ask": 34,
                "bid": 47
            },
            {
                "price": 12709.21,
                "ask": 42,
                "bid": 17
            },
            {
                "price": 12865.81,
                "ask": 36,
                "bid": 54
            }
        ],
        "priceCount": 15
    },
    {
        "_id": "62ddd3dce6713ce1f5fc88b2",
        "high": 12952.87,
        "open": 12926.37,
        "close": 12995.01,
        "low": 12752.41,
        "priceList": [
            {
                "price": 12914.64,
                "ask": 100,
                "bid": 42
            },
            {
                "price": 12994.08,
                "ask": 67,
                "bid": 15
            },
            {
                "price": 12814.38,
                "ask": 66,
                "bid": 26
            },
            {
                "price": 12823.48,
                "ask": 3,
                "bid": 65
            },
            {
                "price": 12780.5,
                "ask": 16,
                "bid": 49
            },
            {
                "price": 12794.15,
                "ask": 92,
                "bid": 98
            },
            {
                "price": 12788.92,
                "ask": 18,
                "bid": 86
            },
            {
                "price": 12714.07,
                "ask": 88,
                "bid": 88
            },
            {
                "price": 12866.43,
                "ask": 93,
                "bid": 94
            },
            {
                "price": 12995.98,
                "ask": 39,
                "bid": 66
            },
            {
                "price": 12861.11,
                "ask": 96,
                "bid": 48
            },
            {
                "price": 12852.54,
                "ask": 99,
                "bid": 52
            },
            {
                "price": 12717.06,
                "ask": 87,
                "bid": 64
            },
            {
                "price": 12881.97,
                "ask": 76,
                "bid": 1
            }
        ],
        "priceCount": 14
    },
    {
        "_id": "62ddd3dc2f21551cf7456534",
        "high": 12889.47,
        "open": 12831.49,
        "close": 12775.51,
        "low": 12943.5,
        "priceList": [
            {
                "price": 12947.96,
                "ask": 99,
                "bid": 67
            },
            {
                "price": 12824.51,
                "ask": 60,
                "bid": 71
            },
            {
                "price": 12939.47,
                "ask": 99,
                "bid": 43
            },
            {
                "price": 12824.3,
                "ask": 16,
                "bid": 54
            },
            {
                "price": 12729.62,
                "ask": 36,
                "bid": 71
            }
        ],
        "priceCount": 5
    },
    {
        "_id": "62ddd3dc08bae4a796df435b",
        "high": 12844.11,
        "open": 12909.58,
        "close": 12973.67,
        "low": 12783,
        "priceList": [
            {
                "price": 12930.07,
                "ask": 30,
                "bid": 65
            },
            {
                "price": 12785.41,
                "ask": 74,
                "bid": 89
            },
            {
                "price": 12856.31,
                "ask": 85,
                "bid": 41
            },
            {
                "price": 12724.97,
                "ask": 14,
                "bid": 70
            },
            {
                "price": 12871.75,
                "ask": 66,
                "bid": 49
            },
            {
                "price": 12754.81,
                "ask": 97,
                "bid": 98
            },
            {
                "price": 12901.36,
                "ask": 80,
                "bid": 31
            }
        ],
        "priceCount": 7
    }
]
