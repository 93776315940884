import React from 'react'
import {IOrder} from "../../../../Modules/Trading/models";
import {ClusterPriceBar} from "../ClusterPriceBar/ClusterPriceBar";

import "./ClusterPriceBarGroup.scss";

const sort = (a: number, b: number) => {
    return a > b ? a : b;
}

/**
 * .
 *
 * @prop .
 */
interface IProps {
    priceList: IOrder[];
}

export const ClusterPriceBarGroup: React.FC<IProps> = ({priceList}:IProps) => {
    let biggestOrder = 0;
    priceList.forEach(item => {
        const curr = sort(item.ask, item.bid)

        if (curr > biggestOrder) {
            biggestOrder = curr
        }
    })

    const renderContent = () => {
        return priceList && priceList.map((item, idx) => {
            return (
                <ClusterPriceBar key={`${item.ask}${idx}${item.price}`} orderList={item} biggestOrder={biggestOrder} />
            )
        })
    }
    return (
        <div className="">
            {renderContent()}
        </div>
    )

}

ClusterPriceBarGroup.displayName = 'ClusterPriceBarGroup'
