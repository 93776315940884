import { CandlesExtremums, Extremums, UCandle } from "../../../CandlesClient/models/Candle";
import { SettingsValue } from "../../../Store/models";
import { CANDLE_OFFSET, CANVAS_HEIGHT } from "../constants";
import { getScrolledCanlesCount } from "../helper";


export const getCandlesExtremums = (candles: UCandle[]): CandlesExtremums => {
    let extremums: CandlesExtremums = {
        highestPrice: 0,
        lowestPrice: 0,
        highestVolume: 0
    }

    if (candles == null || candles.length === 0) {
        return extremums;
    }

    extremums = {
        highestPrice: Number.MIN_VALUE,
        lowestPrice: Number.MAX_VALUE,
        highestVolume: Number.MIN_VALUE
    }

    candles.forEach(candle => {
        if (candle.high > extremums.highestPrice && candle.id != 0) {
            extremums.highestPrice = candle.high;
        }

        if (candle.low < extremums.lowestPrice && candle.id != 0) {
            extremums.lowestPrice = candle.low;
        }

        if (candle.volume > extremums.highestVolume && candle.id != 0) {
            extremums.highestVolume = candle.volume;
        }
    });

    return extremums;
}

export const getZoomExtremums = (extremums: Extremums, canvasHeight: number, direction: boolean, selectedPriceScaleSetting: SettingsValue): Extremums => {
    switch (selectedPriceScaleSetting) {
        case SettingsValue.PriceDefault:
            return getDefaultExtremums(direction, extremums);
        // case SettingsValue.LastPrice:
        //     return getExtremumsPerLastPrice(direction, lowestPoint, highestPoint, latestPrice, canvasHeight);

        case SettingsValue.MidlePrice:
            return getCenteredExtremums(direction, extremums, canvasHeight);

    }

    return getDefaultExtremums(direction, extremums);
}

const getDefaultExtremums = (direction: boolean, extremums: Extremums): Extremums => {
    const dif = extremums.highestPoint - extremums.lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas

    return {
        ...extremums,
        lowestPoint: direction ? extremums.lowestPoint + valueToChange : extremums.lowestPoint - valueToChange,
        highestPoint: direction ? extremums.highestPoint - valueToChange : extremums.highestPoint + valueToChange
    }
}

// const getExtremumsPerLastPrice = (direction: boolean, lowestPoint: number, highestPoint: number, latestPrice: number, canvasHeight: number): ZoomExtremums => {
//     const dif = highestPoint - lowestPoint;
//     const valueToChange = dif / 25; // 4 % size of canvas
//     const topValue = latestPrice + dif / 2;
//     const bottomValue = latestPrice - dif / 2;

//     return {
//         newLowestPoint: direction ? bottomValue + valueToChange : bottomValue - valueToChange,
//         newHighestPoint: direction ? topValue - valueToChange : topValue + valueToChange,
//         newScrollTop: CANVAS_HEIGHT / 2 - canvasHeight / 2
//     }
// }

const getCenteredExtremums = (direction: boolean, extremums: Extremums, canvasHeight: number): Extremums => {
    const dif = extremums.highestPoint - extremums.lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas
    const midlePrice = extremums.highestPrice - ((extremums.highestPrice - extremums.lowestPrice) / 2);
    const topValue = midlePrice + dif / 2;
    const bottomValue = midlePrice - dif / 2;

    return {
        ...extremums,
        lowestPoint: direction ? bottomValue + valueToChange : bottomValue - valueToChange,
        highestPoint: direction ? topValue - valueToChange : topValue + valueToChange,
        scrollTop: CANVAS_HEIGHT / 2 - canvasHeight / 2
    }
}

export const getFloatExtremums = (candles: UCandle[], canvasHeight: number): Extremums => {
    if (candles == null || candles.length === 0) {
        return {
            lowestPrice: 0,
            highestPrice: 0,
            lowestPoint: 0,
            highestPoint: 0,
            highestVolume: 0,
            scrollTop: 0
        }
    }

    const candlesExtremums = getCandlesExtremums(candles);

    const dif = candlesExtremums.highestPrice - candlesExtremums.lowestPrice;
    const viewportCanvasPoint = canvasHeight / dif;
    const gap = (CANVAS_HEIGHT - canvasHeight) / 2 / viewportCanvasPoint;

    let highestPoint = candlesExtremums.highestPrice + gap;
    let lowestPoint = candlesExtremums.lowestPrice - gap;

    const pixelsPerpoint = canvasHeight / dif;
    const scrollTop = pixelsPerpoint * (highestPoint - candlesExtremums.highestPrice);

    return {
        ...candlesExtremums,
        lowestPoint,
        highestPoint,
        scrollTop
    }
}

export const checkAvailableExtremums = (candleIn: UCandle, candleOut: UCandle, highest: number, lowest: number): boolean => {
    const dif = (highest - lowest) / 3;
    const high = highest - dif;
    const low = lowest + dif;

    return candleOut?.high >= high || candleOut?.low <= low || candleIn?.high >= high || candleIn?.low <= low;
}

export const getStaticExtremums = (candles: UCandle[], canvasWidth: number, scaleIndex: number, scrollLeft: number, canvasHeight: number, spaceGap: number): Extremums => {
    const candlesCountOnViewPort = canvasWidth / (CANDLE_OFFSET * scaleIndex);
    const scrolledCandlesCount = getScrolledCanlesCount(scrollLeft - spaceGap, scaleIndex);
    const startIndex = Math.round(scrolledCandlesCount);
    const endIndex = Math.round(startIndex + candlesCountOnViewPort);
    const candlesToCalculate = candles.slice(startIndex, endIndex);

    const candlesExtremums = getCandlesExtremums(candles);

    const dif = candlesExtremums.highestPrice - candlesExtremums.lowestPrice;
    const viewportCanvasPoint = canvasHeight / dif;
    const gap = (CANVAS_HEIGHT - canvasHeight) / 2 / viewportCanvasPoint;

    let highestPoint = candlesExtremums.highestPrice + gap;
    let lowestPoint = candlesExtremums.lowestPrice - gap;

    const pixelsPerpoint = canvasHeight / dif;
    const scrollTop = pixelsPerpoint * (highestPoint - candlesExtremums.highestPrice);

    return {
        ...candlesExtremums,
        lowestPoint,
        highestPoint,
        scrollTop
    }
}