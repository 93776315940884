import React, {memo} from 'react'
import cn from "classnames";

import "./ClusterBar.scss";

/**
 * Бар кластерного столбца.
 *
 * @prop open Цена открытия.
 * @prop high Наибольшая цена.
 * @prop low Наименьшая цена.
 * @prop close Цена закрытия.
 */
interface IProps {
    open: number;
    high: number;
    low: number;
    close: number;
    pricePixelRate: number;
}

export const ClusterBar: React.FC<IProps> = memo(({open, high, low, close, pricePixelRate}:IProps) => {
    const calcHighOpenHeight = (high - open) * pricePixelRate;
    const calcCloseLowHeight = (close - low) * pricePixelRate;
    const candleDealsHeight = Math.abs((open - close) * pricePixelRate)

    const dealsBarClassNames = cn({
        ['bar-body']: true,
        ['flat-bar']: open === close,
        ['green-bar']: open > close,
        ['red-bar']: open < close,
    })

    return (
        <div className="cluster-bar-container">
            <div style={{height: `${calcHighOpenHeight}px`}} />
            <div className={dealsBarClassNames} style={{height: `${candleDealsHeight}px`}} />
            <div style={{height: `${calcCloseLowHeight}px`}} />
        </div>
    )
})

ClusterBar.displayName = 'ClusterBar'
