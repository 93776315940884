import {useState, useRef} from 'react';
import {MouseEventHandler} from "react/index";

export default function useDragScroll() {
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
    const [initialX, setInitialX] = useState<number>(0);
    const [initialY, setInitialY] = useState<number>(0);
    const [scrollX, setScrollX] = useState<number>(0);
    const [scrollY, setScrollY] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(1000)

    const scrollRef = useRef<HTMLDivElement>(null);

    const handleMouseDown: MouseEventHandler = (event) => {
        setTimeout(() => {
            setIsMouseDown(true)

            scrollRef.current && setInitialX(event.pageX - scrollRef.current.offsetLeft);
            scrollRef.current && setScrollX(scrollRef.current.scrollLeft);

            scrollRef.current && setInitialY(event.pageY - scrollRef.current.offsetTop);
            scrollRef.current && setScrollY(scrollRef.current.scrollTop);
        }, 0)
    }

    const handleMouseUp: MouseEventHandler = (event) => {
        setTimeout(() => {
            setIsMouseDown(false)
            event.stopPropagation();
        }, 0)
    }

    const handleMouseMove: MouseEventHandler = (event) => {
        setTimeout(() => {
            if(!isMouseDown) return;
            event.preventDefault();

            if (scrollRef.current) {
                setScrollLeft(scrollRef.current.scrollLeft)
                const x = event.pageX - scrollRef.current.offsetLeft;
                const moveX = (x - initialX);
                scrollRef.current.scrollLeft = scrollX - moveX;

                const y = event.pageY - scrollRef.current.offsetTop;
                const moveY = (x - initialY);
                scrollRef.current.scrollTop = scrollY - moveY;
            }
        }, 0)
        event.stopPropagation();
    }

    return { scrollRef, isMouseDown, handleMouseDown, handleMouseUp, handleMouseMove, scrollLeft };
}
