// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.candleClassicColumn {
  height: 100%;
  width: 21px;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.candleClassicContainer {
  position: absolute;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/CandleClassic/CandleClassic.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;AACJ","sourcesContent":[".candleClassicColumn {\n    height: 100%;\n    width: 21px;\n    position: relative;\n    display: flex;\n    justify-content: center;\n    z-index: 1;\n}\n\n.candleClassicContainer {\n    position: absolute;\n    z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
