import { CLUSTER_SCALE } from "../Modules/Candles/constants";

export const convertPriceValue = (priceValue: number) =>
    Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 1
    }).format(priceValue);


// TODO: Following URL's need to be loaded from /config.json, to be able to change them in production on the fly

// Main backend URL
export const MAIN_BACKEND_URL: string = "https://back.markets-platform.com"

// Datasette backend URL
export const DATA_SETTE_URL: string = "https://ds.tp.produktor.io"

// API endpoints
export const API_ENDPOINTS = {
    GRAFANA_PAST_CANDLES: `SELECT 
        open_time,
	    open_trade_id,
	    close_trade_id,
	    open_price,
	    high_price,
	    low_price,
	    close_price,
	    volume_sum
        FROM
        create_past_candles_frame_time_mv(
        FALSE:: BOOLEAN,
        {{secondsCount}}:: INTEGER,
        {{insrumentId}}:: SMALLINT,
        '{{date}}':: TIMESTAMPTZ,
        {{candlesCount}}:: INTEGER
    )`
}