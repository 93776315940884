import {
    Routes,
    Route,
} from "react-router-dom";

import TradingPage from "../Modules/Trading/Page";
import {MainChart} from "../Modules/Demo/MainChart";
import { TechAnalysis } from "../Modules/TechAnalysis/TechAnalysis";
import {StoryBook} from "../Pages/StoryBook";
import { Login } from "../Pages/Authorization/Login";
import { Registration } from "../Pages/Authorization/Registartion";
import { ForgotPassword } from "../Pages/Authorization/ForgotPassword";
import { ResetPassword } from "../Pages/Authorization/ResetPassword";

export const pathHome = '';
export const pathTrading = '/trading';
export const pathAnalytics = '/analytics';
export const pathMarketRePlay = '/marketreplay';
export const pathDemo = '/demo';
export const pathTechAnalysis = '/techanalysis'

export const trading = '/trading';
export const statistic = 'statistic';
export const personalArea = '/personal-area';

export const candlesPathHome = '/candles';
export const chartPathHome = '/chart';

export const orderBook = '/orderBook';
export const indicators = '/indicators';
export const tapePrints = '/tapePrints';
export const correlationCharts = '/correlationCharts';
export const riskManagement = '/riskManagement';
export const storyBookPath = '/story-book';
export const signUpPath = '/sign-up';
export const loginPath = '/login';
export const registrationPath = '/registration'
export const passwordForgotPath = '/passwordForgot'
export const passwordResetPath = '/passwordReset'

export const createRoutes = () => {

    return (
        <Routes>
            <Route path={pathHome} element={<MainChart />} /> 
            <Route path={pathDemo} element={<TradingPage />} /> 
            <Route path={trading} element={<>trading</>} />
            <Route path={statistic} element={<>statistic</>} />
            <Route path={personalArea} element={<>personal area</>} />
            
            <Route path={pathTechAnalysis} element={<TechAnalysis />} />
            <Route path={pathTrading} element={pathTrading} />
            <Route path={pathAnalytics} element={<>pathAnalytics</>} />
            <Route path={pathMarketRePlay} element={<>pathMarketRePlay</>} />
            <Route path={orderBook} element={<>orderBook</>} />
            <Route path={indicators} element={<>indicators</>} />
            <Route path={tapePrints} element={<>tapePrints</>} />
            <Route path={correlationCharts} element={<>correlationCharts</>} />
            <Route path={riskManagement} element={<>riskManagement</>} />
            <Route path={storyBookPath} element={<StoryBook />} />
            <Route path={loginPath} element={<Login />} />
            <Route path={registrationPath} element={<Registration />} />
            <Route path={passwordForgotPath} element={<ForgotPassword />} />
            <Route path={passwordResetPath} element={<ResetPassword />} />

        </Routes>
    )
}
