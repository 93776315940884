// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg1 {
  background: linear-gradient(180deg, #FDE725 0%, rgba(253, 231, 37, 0) 100%);
}

.bg2 {
  background: linear-gradient(180deg, #440D54 0%, rgba(68, 13, 84, 0) 100%);
}

.bg3 {
  background: linear-gradient(180deg, #482576 0%, rgba(72, 37, 118, 0) 100%);
}

.bg4 {
  background: linear-gradient(180deg, #22908C 0%, rgba(34, 144, 140, 0) 100%);
}

.bg5 {
  background: linear-gradient(180deg, #414487 0%, rgba(65, 68, 135, 0) 100%);
}

.bg6 {
  background: linear-gradient(180deg, #440D54 0%, rgba(68, 13, 84, 0) 100%);
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/CandleClassic/CandleClassicColumnBackground/CandleClassicColumnBackground.scss"],"names":[],"mappings":"AAGA;EACI,2EAAA;AAFJ;;AAKA;EACI,yEAAA;AAFJ;;AAKA;EACI,0EAAA;AAFJ;;AAKA;EACI,2EAAA;AAFJ;;AAKA;EACI,0EAAA;AAFJ;;AAKA;EACI,yEAAA;AAFJ","sourcesContent":["@import \"src/Common/Styles/colors\";\n@import \"src/Common/Styles/metrics\";\n\n.bg1 {\n    background: linear-gradient(180deg, #FDE725 0%, rgba(253, 231, 37, 0) 100%);\n}\n\n.bg2 {\n    background: linear-gradient(180deg, #440D54 0%, rgba(68, 13, 84, 0) 100%);\n}\n\n.bg3 {\n    background: linear-gradient(180deg, #482576 0%, rgba(72, 37, 118, 0) 100%);\n}\n\n.bg4 {\n    background: linear-gradient(180deg, #22908C 0%, rgba(34, 144, 140, 0) 100%);\n}\n\n.bg5 {\n    background: linear-gradient(180deg, #414487 0%, rgba(65, 68, 135, 0) 100%);\n}\n\n.bg6 {\n    background: linear-gradient(180deg, #440D54 0%, rgba(68, 13, 84, 0) 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
