import React, {useEffect, useState} from "react";
import useDeepCompareEffect from 'use-deep-compare-effect'
import cn from "classnames";

import {TCandleCurrentType, TCandleHistoryType} from "../../../../Modules/Trading/models";
import {
    calcCandlesTopPosition,
    calcHighLowPriceDifference,
    calcMaxHighPrice,
    calcMinLowPrice,
    calcPricePixelRate
} from "../../../../Modules/Trading/utils";
import useDragScroll from "../../../../Hooks/useDragScroll";
import {HistoricalColumns} from "../../../../Modules/Trading/Components/HistoricalColumns/HistoricalColumns";
import {
    CANDLE_CLASSIC_COLUMN_WIDTH,
    CLUSTER_COLUMN_WIDTH
} from "../../../../Modules/Trading/consts";
import {EChartTypes} from "../../enums";
import {ChartColumnProxy} from "../../../../Common/Components/ChartColumnProxy/ChartColumnProxy";

import "./Terminal.scss";

/**
 * Терминал с графиками.
 * @prop {ICandleBarData[]} data Входные данные для отрисовки свечей графика.
 */
interface IProps {
    history: TCandleHistoryType;
    currentCandle: TCandleCurrentType;
}

export const Terminal: React.FC<IProps> = (props: IProps) => {
    const {history, currentCandle} = props;

    const [graphAreaHeight, setGraphAreaHeight] = useState<number>(0)
    const [maxHighPrice, setMaxHighPrice] = useState<number>(0);
    const [minLowPrice, setMinLowPrice] = useState<number>(0);
    const [highLowPriceDifference, setHighLowPriceDifference] = useState<number>(0)

    const [chartType, setChartType] = useState<EChartTypes>(EChartTypes.CLUSTER)

    const {scrollRef, isMouseDown, handleMouseDown, handleMouseUp, handleMouseMove, scrollLeft} = useDragScroll()

    useEffect(() => {
        scrollRef.current && setGraphAreaHeight(scrollRef.current.offsetHeight)
    }, [])

    useDeepCompareEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
        }
    }, [history])

    useEffect(() => {
        const columnWidth = chartType === EChartTypes.CANDLE_CLASSIC
            ? CANDLE_CLASSIC_COLUMN_WIDTH : CLUSTER_COLUMN_WIDTH;
        const farLeftColumn = Math.floor(scrollLeft / columnWidth);
        setMaxHighPrice(calcMaxHighPrice([...history, currentCandle].slice(farLeftColumn).map(candle => candle.high)))
        setMinLowPrice(calcMinLowPrice([...history, currentCandle].slice(farLeftColumn).map(candle => candle.low)))
    }, [scrollLeft])

    useEffect(() => {
        setHighLowPriceDifference(calcHighLowPriceDifference(maxHighPrice, minLowPrice))
    }, [maxHighPrice, minLowPrice])

    const renderContent = () => {
        const pricePixelRate = calcPricePixelRate(highLowPriceDifference, graphAreaHeight)
        const topPosition = calcCandlesTopPosition(pricePixelRate, maxHighPrice, currentCandle.high)
        return (
            <div
                className="columnsArea"
                ref={scrollRef}
                onMouseUp={handleMouseUp}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
            >
                <HistoricalColumns
                    chartType={chartType}
                    historyData={history}
                    maxHighPrice={maxHighPrice}
                    pricePixelRate={pricePixelRate}
                />
                <ChartColumnProxy
                    chartType={chartType}
                    chartItemData={currentCandle}
                    pricePixelRate={pricePixelRate}
                    topPosition={topPosition}
                />
                <div className="farRightMargin"/>
            </div>
        )
    }

    const terminalContainerClassNames = cn({
        terminalContainer: true,
        grabbing: isMouseDown,
    })

    return (
        <div className={terminalContainerClassNames}>
            {renderContent()}
            <div className="subGraphAreaBackground" />
        </div>
    )
}

Terminal.displayName = 'Terminal'
