// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cluster-container {
  width: 210px;
  padding-right: 9px;
  display: flex;
  border-left: 1px solid #4E4E4E;
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/Cluster/Cluster.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;AACJ","sourcesContent":[".cluster-container {\n    width: 210px;\n    padding-right: 9px;\n    display: flex;\n    border-left: 1px solid #4E4E4E;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
